<div class="row">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numWidth [min]="minWidth" [max]="maxWidth" [value]="currentGlassWidth" [delay]="200"
			[disabled]="isWidthChangeDisabled">
		</app-numeric-textbox> mm &nbsp;
		<kendo-buttongroup *ngIf="current.type == 'Glass'" selection="single" kendoTooltip>
			<button kendoButton [selected]="current.resizeMode=='Left'" [toggleable]="true"
				[svgIcon]="projectService.svgIcons.arrowLeftIcon" (selectedChange)="setResizeMode('Left')"
				[disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()"
				i18n-title="Glass|Tooltip:Width mode left" title="Set width change mode - resize on left"></button>
			<button kendoButton [selected]="current.resizeMode=='Both'" [toggleable]="true"
				[svgIcon]="projectService.svgIcons.colResizeIcon" (selectedChange)="setResizeMode('Both')"
				[disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()"
				i18n-title="Glass|Tooltip:Width mode both"
				title="Set width change mode - resize on left and right"></button>
			<button kendoButton [selected]="current.resizeMode=='Right'" [toggleable]="true"
				[svgIcon]="projectService.svgIcons.arrowRightIcon" (selectedChange)="setResizeMode('Right')"
				[disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()"
				i18n-title="Glass|Tooltip:Width mode right" title="Set width change mode - resize on right"></button>
		</kendo-buttongroup>
	</div>

</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Height">Height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [value]="currentGlassHeight" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row" *ngIf="current.type === 'RoofWindow' || current.type === 'RoofGutter'">
	<div class="col col-4"><span i18n="Common|Distance">Distance</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numDis [min]="minDistance" [max]="maxDistance" [value]="distance"
			[disabled]="projectService.readOnly"></app-numeric-textbox> mm from rear profile
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocX [value]="locationX" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="locationY" [disabled]="true"
		(afterValueChanged)="onAfterLocationYValueChange($event)"></app-numeric-textbox> mm
	</div>
</div>

<div *ngIf="showParameter() && current.type != 'RoofVentilator' && current.type != 'RoofGutter'">
	<div class="row">
		<div class="col col-4"><span i18n="Common|Weight">Weight</span>:</div>
		<div class="col col-8">
			<app-numeric-textbox [value]="current.weight" [disabled]="true" [decimals]="2"
				[format]="'n2'"></app-numeric-textbox> kg
		</div>
	</div>
	<div class="row" *ngIf="current.type == 'GlassPart' || current.type == 'GlassPartDiamond'">
		<div class="col col-4"><span i18n="Common|Surface area">Surface area</span>:</div>
		<div class="col col-8">
			<app-numeric-textbox [value]="current.surfaceArea  / 1000000" [disabled]="true" [decimals]="2"
				[format]="'n2'"></app-numeric-textbox> m<sup>2</sup>
		</div>
	</div>
</div>


<div class="row" *ngIf="current.type != 'RoofVentilator' && current.type != 'RoofGutter'">
	<div class="col col-4"><span i18n="Common|Glass type">Glass type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="getGlassesTypeData()" textField="name" valueField="id"
			[(ngModel)]="currentDef" [valuePrimitive]="true" (valueChange)="typeChanged()"
			[disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()"></kendo-dropdownlist>
	</div>
</div>

<card-cuttingonsite [current]="current" [disabled]="checkFreezingElements()"></card-cuttingonsite>

<div class="row">
	<div class="col col-4">
		<span i18n="Common|Delete">Delete</span>:
	</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteGlass()" [disabled]="projectService.readOnly || checkFreezingElements()" class="medium"><span i18n="Common|Delete">Delete</span></button>
	</div>
</div>

<div *ngIf="showParameter()">
	<div class="row" *ngIf="current.locked; else unlocked">
		<div class="col col-4">
			<span class="k-icon k-i-lock"></span>
		</div>
		<div class="col col-8">
			<button kendoButton [svgIcon]="projectService.svgIcons.unlockIcon" (click)="setLock(false)" [disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()" class="medium"><span i18n="Common|Unlock">Unlock</span></button>
		</div>
	</div>

	<ng-template #unlocked>
		<div class="row">
			<div class="col col-4">
				<span class="k-icon k-i-unlock"></span>
			</div>
			<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.lockIcon" [svgIcon]="projectService.svgIcons.lockIcon" (click)="setLock(true)" [disabled]="projectService.readOnly || toolBSrvc.getCurrTool() || checkFreezingElements()" class="medium"><span i18n="Common|Lock">Lock</span></button>
			</div>
		</div>
	</ng-template>

	<div class="row" *ngIf="checkFreezingElements()">
		<div class="col col-4">
			<span class="k-icon k-i-x-circle"></span>
			Frozen
		</div>
		<div class="col col-8">
			<button kendoButton [svgIcon]="projectService.svgIcons.checkCircleIcon" (click)="allowChange()"
				class="medium" disabled="projectService.readOnly" ><span i18n="Common|Unfreeze">Unfreeze</span></button>
		</div>
	</div>
</div>

<app-confirm-dialog [dialogOpened]="openDialog" [dialogMessage]="dialogMessage"
	(dialogAction)="onDialogAction($event)"></app-confirm-dialog>

<div *ngIf="projectService.showDebugInfo && current.type == 'Glass'"
	class="testMode">
	<div class="row">
		<div class="col col-4">Width:</div>
		<div class="col col-8">{{ currentGlassWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Viewport:</div>
		<div class="col col-8">{{ currentGlassViewWidth | number:'1.2-2' :currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Depth:</div>
		<div class="col col-8">{{ current.length | number:'1.2-2' :currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{minWidth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{maxWidth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Weight (1m<sup>2</sup>):</div>
		<div class="col col-8">{{current?.stat?.weight}} kg</div>
	</div>
	<div class="row">
		<div class="col col-4">Max weight:</div>
		<div class="col col-8">{{current.stat?.maxWeight | number:'1.2-2' :currLng}}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max depth:</div>
		<div class="col col-8">{{ current.stat?.maxDepth | number:'1.2-2' :currLng}} mm</div>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo && (current.type == 'GlassPart' || current.type == 'GlassPartDiamond')" class="testMode">
	<div class="row">
		<div class="col col-4">Max surface:</div>
		<div class="col col-8">{{current.maxM2 / 1000000 | number:'1.2-2' :currLng}} m<sup>2</sup></div>
	</div>
</div>

