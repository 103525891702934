<div *ngIf="this.canShowExport">

	<div kendoTooltip *ngIf="!crmService.isCrmPresent">
		<div [attr.title]="exportButtonLabel">
			<button kendoButton #anchor style="margin: 5px;" class="k-button" (click)="togglePopup()" [svgIcon]="projectService.svgIcons.barcodeOutlineIcon"
				[disabled]="toolBSrvc.getCurrTool() || !projectService.isValid || projectService.draftMode || (crmService.isCrmPresent && !projectService.readOnly)">
			</button>
		</div>
	</div>

	<kendo-dialog
		[title]="dialogTitle"
		*ngIf="isPopupShown"
		(close)="action(false)"
		[width]="850"
		[maxHeight]="1250">
		
	
		<form class="k-form" (ngSubmit)="onSubmit()" #f="ngForm">
			<ul class="k-radio-list">
				<!-- full construction -->
				<li class="k-radio-item" style="font-size: 16px">
					<label class="k-radio-label radio-elems">
						<input
							disabled="exportInProgress"
							type="radio"
							name="exportType"
							kendoRadioButton
							value="fullConstruction"
							[(ngModel)]="exportType"
							(click)="onExportType('fullConstruction')"
						/>
						1. <span i18n="BOM|'Full construction' label">Full construction</span>
					</label>
				</li>

				<!-- additions only -->
				<li class="k-radio-item" style="font-size: 16px">
					<label class="k-radio-label radio-elems" [style]="{ color: getInvalidColor('additionsOnly') }">
						<input
							disabled="exportInProgress"
							type="radio"
							name="exportType"
							kendoRadioButton
							value="additionsOnly"
							[(ngModel)]="exportType"
							(click)="onExportType('additionsOnly')"
							[style]="{ borderColor: getInvalidColor('additionsOnly'), backgroundColor: getInvalidColor('additionsOnly') }"
						/>
						2. <span i18n="BOM|'Additions only' label">Additions only</span>
					</label>
					<kendo-formerror *ngIf="additionsOnly"><span i18n="BOM|Validation message">Error: You should choose at least one sub-option.</span></kendo-formerror>
				</li>

				<ul class="row" ngModelGroup="additionsOnly" style="font-size: 14px; margin: 0; padding: 0;">
					<div class="col col-1"></div>
					<li class="col col-2 list-item">
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('additionsOnly') }">
							<input kendoCheckBox type="checkbox" name="Left" [ngModel]="false" [required]="additionsOnly" disabled="exportInProgress"
							(click)="onCheckboxElem($event, 'Left', 'additionsOnly', 'individual'); addToAdditions('Left')" />
							&nbsp;<span i18n="BOM|'Left side' label">Left side</span>
						</label>
					</li>
					
					<li class="col col-2 list-item">
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('additionsOnly') }">
							<input kendoCheckBox type="checkbox" name="Right" [ngModel]="false" disabled="exportInProgress"
							(click)="onCheckboxElem($event, 'Right', 'additionsOnly', 'individual'); addToAdditions('Right')" [required]="additionsOnly" />
							&nbsp;<span i18n="BOM|'Right side' label">Right side</span>
						</label>
					</li>
					
					<li class="col col-2 list-item">
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('additionsOnly') }">
							<input kendoCheckBox type="checkbox" name="Top" [ngModel]="false" disabled="exportInProgress"
							(click)="onCheckboxElem($event, 'Top', 'additionsOnly', 'individual'); addToAdditions('Top')" [required]="additionsOnly" />
							&nbsp;<span i18n="BOM|'Roof' label">Roof</span>
						</label>
					</li>
					
					<li class="col col-2 list-item">
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('additionsOnly') }">
							<input kendoCheckBox type="checkbox" name="Front" [ngModel]="false" disabled="exportInProgress"
							(click)="onCheckboxElem($event, 'Front', 'additionsOnly', 'individual'); addToAdditions('Front')" [required]="additionsOnly" />
							&nbsp;<span i18n="BOM|'Front' label">Front</span>
						</label>
					</li>

					<li class="col col-2 list-item">
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('additionsOnly') }">
							<input kendoCheckBox type="checkbox" name="Back" [ngModel]="false" disabled="exportInProgress"
							(click)="onCheckboxElem($event, 'Back', 'additionsOnly', 'individual'); addToAdditions('Back')" [required]="additionsOnly" />
							&nbsp;<span i18n="BOM|'Rear' label">Rear</span>
						</label>
					</li>
				</ul>

				<!-- individual -->
				<li class="k-radio-item" style="font-size: 16px">
					<label class="k-radio-label radio-elems" [style]="{ color: getInvalidColor('individual') }">
						<input
							disabled="exportInProgress"
							kendoRadioButton
							type="radio"
							name="exportType"
							value="individual"
							[(ngModel)]="exportType"
							(click)="onExportType('individual')"								
							[style]="{ borderColor: getInvalidColor('individual'), backgroundColor: getInvalidColor('individual') }"
						/>
						3. <span i18n="BOM|'Individual' label">Individual</span>
					</label>
					<kendo-formerror *ngIf="individual"><span i18n="BOM|Validation message">Error: You should choose at least one sub-option.</span></kendo-formerror>
				</li>
			</ul>

			<kendo-grid [data]="individualElementsForPopup" [sortable]="true">
				<kendo-grid-column [width]="40">
					<ng-template kendoGridCellTemplate let-elem>
						<label class="k-checkbox-label" [style]="{ color: getInvalidColor('individual') }">
							<input kendoCheckBox type="checkbox" [ngModel]="false" [name]="elem.id"
							(click)="onCheckboxElem($event, elem.id, 'individual', 'additionsOnly')" [required]="individual" disabled="exportInProgress"/>
						</label>
				</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field="name" i18n-title="BOM|Header:Element" title="Element" [width]="90"> </kendo-grid-column>
				<kendo-grid-column field="side" i18n-title="BOM|Header:Side" title="Side" [width]="80"> </kendo-grid-column>
				<kendo-grid-column field="count" title="PCE" [width]="60"> </kendo-grid-column>
				<kendo-grid-column field="height" i18n-title="BOM|Header:Height" title="Height" [width]="80"> </kendo-grid-column>
				<kendo-grid-column field="width" i18n-title="BOM|Header:Width" title="Width" [width]="80"> </kendo-grid-column>
				<kendo-grid-column field="depth" i18n-title="BOM|Header:Depth" title="Depth" [width]="80"> </kendo-grid-column>
				<kendo-grid-column field="type" i18n-title="BOM|Header:Type" title="Type"> </kendo-grid-column>
			</kendo-grid>
		</form>
	

		<kendo-dialog-actions *ngIf="!exportInProgress">
			<button kendoButton (click)="action(false)" i18n="Dialogs|'Cancel' button">Cancel</button>
			<button kendoButton (click)="action(true)" primary="true" [disabled]="isExportBtmDisabled" i18n="Dialogs|'Confirm' button">Confirm</button>
		</kendo-dialog-actions>

		<app-wait [show]="exportInProgress" message=""></app-wait>
	</kendo-dialog>

	<kendo-dialog *ngIf="!exportInProgress && isStatusDialogOpened" [width]="300" style="text-align: center">
		<div class="row">
			<div class="col-9">
				<h6 [ngClass]="exportSuccess ? 'success' : 'error'">{{ statusDialogMessage }}</h6>
			</div>
			<div class="col-3" *ngIf="statusDialogMessage">
				<kendo-svg-icon [icon]="getIcon()" [ngClass]="exportSuccess ? 'success-icon' : 'error-icon'"></kendo-svg-icon>
			</div>
		</div>
		
		<kendo-dialog-actions>
			<button kendoButton (click)="closePopup()" primary="true" i18n="Dialogs|'Confirm' button">Ok</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>