import { DimensionLineParams } from "../../constants/constants";
import { ElementSide } from "../../interfaces/IElementSide";
import { ILine } from "../../interfaces/ILine.interface";

export class DimensionLine {

  strokeWidth: number = 1;

  public get start(): ILine {
    if (this.isVertical) {

      var x1d = this.getLevel1();
      var x2d = this.getLevel2();

      switch (this.side) {
        case ElementSide.Left:
          return {
            x1: this.x1 - x1d,
            x2: this.x1 + x2d,
            y1: this.y1,
            y2: this.y1
          }
        case ElementSide.Right:
          return {
            x1: this.x1 - x2d,
            x2: this.x1 + x1d,
            y1: this.y2,
            y2: this.y2
          }
        default:
          return { x1: 0, x2: 0, y1: 0, y2: 0 };
      }

    }

    var y1d = this.getLevel1();
    var y2d = this.getLevel2();

    switch(this.side) {
      case ElementSide.Top:
        return {
          x1: this.x1,
          x2: this.x1,
          y1: this.y1 - y1d,
          y2: this.y2 + y2d
        }
      case ElementSide.Bottom:
        return {
          x1: this.x1,
          x2: this.x1,
          y1: this.y1 - y2d,
          y2: this.y2 + y1d
        }
      default:
        return { x1: 0, x2: 0, y1: 0, y2: 0 };
    }
  }

  public get end(): ILine {
    if (this.isVertical) {
      var x1d = this.getLevel1();
      var x2d = this.getLevel2();

      switch (this.side) {
        case ElementSide.Left:
          return {
            x1: this.x1 - x1d,
            x2: this.x1 + x2d,
            y1: this.y2,
            y2: this.y2
          }
        case ElementSide.Right:
          return {
            x1: this.x1 - x2d,
            x2: this.x1 + x1d,
            y1: this.y1,
            y2: this.y1
          }
        default:
          return { x1: 0, x2: 0, y1: 0, y2: 0 };
      }

    }

    var y1d = this.getLevel1();
    var y2d = this.getLevel2();

    switch (this.side) {
      case ElementSide.Top:
        return {
          x1: this.x2,
          x2: this.x2,
          y1: this.y1 - y1d,
          y2: this.y2 + y2d
        }
      case ElementSide.Bottom:
        return {
          x1: this.x2,
          x2: this.x2,
          y1: this.y1 - y2d,
          y2: this.y2 + y1d
        }
      default:
        return { x1: 0, x2: 0, y1: 0, y2: 0 };
    }
  }

  constructor(
    public x1: number,
    public y1: number,
    public x2: number,
    public y2: number,
    public level: number,
    private side: ElementSide,
    private helper: boolean = false) {
  }

  private get isVertical() {
    return this.x1 == this.x2;
  }

  private getLevel1() {
    switch (this.level) {
      case DimensionLineParams.INNER_DIMENSION:
        return 3;
      case DimensionLineParams.FIRST_LEVEL_DIMENSION:
        return 7;
      default:
        return 10;
    }
  }

  private getLevel2() {
    switch (this.level) {
      case DimensionLineParams.INNER_DIMENSION:
        return 3;
      default:
        return this.level;
    }
  }

}