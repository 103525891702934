export enum AreaType {
	None = "None",
	Left = "Left",
	Roof = "Roof",
	Right = "Right",
	Front = "Front",
	Rear = "Rear"
}

export const CollectionName = new Map<AreaType, string>([
	[AreaType.Front, "frontElements"],
	[AreaType.Left, "leftSideElements"],
	[AreaType.Right, "rightSideElements"],
	[AreaType.Roof, "roofElements"],
	[AreaType.Rear, "rearElements"],
]);

export const RectName = new Map<AreaType, string>([
	[AreaType.Front, "rectOnFront"],
	[AreaType.Left, "rectOnLeft"],
	[AreaType.Right, "rectOnRight"],
	[AreaType.Roof, "rectOnRoof"],
	[AreaType.Rear, "rectOnRear"],
]);

export const LocationArea = new Map<string, AreaType>([
	["FRONT", AreaType.Front],
	["LEFT", AreaType.Left],
	["RIGHT", AreaType.Right],
	["TOP", AreaType.Roof],
	["REAR", AreaType.Rear],
]);

export const AreaLocation = new Map<AreaType, string>([
	[AreaType.Front, "FRONT"],
	[AreaType.Left, "LEFT"],
	[AreaType.Right, "RIGHT"],
	[AreaType.Roof, "TOP"],
	[AreaType.Rear, "REAR"],
]);
