import { VerticalElement } from "../vertical-element";
import { LayerLevel } from '../../interfaces/LayerLevel';
import { GlassWallParameter } from "../wizard/wizard-parameter.model";
import { FrameProfile } from "../profiles/frame.model";
import { MuntinProfile } from "../profiles/muntin.model";
import { ElementSide } from "../../interfaces/IElementSide";
import { ElementType } from "../ToolboxModel";
import { SideBaseService } from "../../services/areas/side-base.service";
import { GlassWallDiamond } from "./glassWallDiamond.model";
import { GlassWall } from "./glassWall.model";
import { IAreaService } from "../../interfaces/IAreaService";
import { ProjectService } from "../../services/project.service";

export abstract class GlassWallBase extends VerticalElement {

	public def: GlassWallParameter;

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}
	get rectOnFront() {
		return this.rect;
	}

	get rectOnLeft() {
		return this.rect;
	}

	get rectOnRight() {
		return this.rect;
	}

	get maxM2() {
		const isMaxHeight = this.height == this.maxHeight;
		return (this.hanging && !isMaxHeight ? this.def.maxM2hang : this.def.maxM2) * 1000000; // meters2 to milimeters2
	}

	get defMaxWidth(): number {
		return (this.def.maxWidth ?? 0) == 0 ? this.projectService.template.maxWidth : this.def.maxWidth;
	}
	get defMaxHeight(): number {
		return (this.def.maxHeight ?? 0) == 0 ? this.projectService.template.maxBackHeight : this.def.maxHeight;
	}
	get defMinWidth(): number {
		return this.def.minWidth;
	}
	get defMinHeight(): number {
		return this.def.minHeight;
	}

	public muntins: MuntinProfile[];
	public minMuntinsCount: number;

	private _hang: boolean = false;
	public get hanging() {
		return this._hang;
	}
	public set hanging(v: boolean) {
		this._hang = v;
		this.refresh();
	}

	public get canHang() {
		return this.def.maxM2 != this.def.maxM2hang && this.height < this.maxHeight;
	}

	public validate(width: number, height: number) {
		return true;
	}

	protected refresh() {
		var bottNeib = this.getNeib(ElementSide.Bottom, true);
		if (this.hanging && bottNeib) {
			this._hang = false;
			if (this.type == ElementType.GlassWallDiamond) {
				(this.projectService.CurrentAreaService as SideBaseService).createMuntins((this as unknown as GlassWallDiamond));
			} else {
				this.projectService.createMuntins((this as unknown as GlassWall));
			}
		}
		this.projectService.manageFrameAfterGlassChange(this);
	}

	public get connectorId() {
		return this.def.connectorId;
	}

	public get configId() {
		return this.def.id;
	}
	public set configId(v: string) {
		switch(this.type) {
			case ElementType.GlassWall:
			case ElementType.GlassPart:
				this.def = this.projectService.template.wallGlasses.find(w => w.id == v);
				break;
			case ElementType.GlassWallDiamond:
			case ElementType.GlassPartDiamond:
				this.def = this.projectService.template.diamondGlasses.find(w => w.id == v);
				break;
		}
	}

	public leftFrame: FrameProfile;
	public rightFrame: FrameProfile;
	public topFrame: FrameProfile;
	public bottomFrame: FrameProfile;

	constructor(projectService: ProjectService, sideService: IAreaService, hanging: boolean) {
		super(projectService, sideService);
		this._hang =hanging;
	}

}
