import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { IRect } from "../../interfaces/IRect";
import { ISize } from '../wizard/wizard-parameter.model';
import { Common } from '../common';
import { LayerLevel } from "../../interfaces/LayerLevel";

export class FrameProfile extends Profile {

	public id: string;
	public rect: IRect;

	get type() {
		return ElementType.Frame;
	}

	get name(): string {
		return $localize`:Common|Frame:Frame`;
	}

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	get rectOnFront() {
		return this.rect;
	}

	get rectOnLeft() {
		return this.rect;
	}

	get rectOnRight() {
		return this.rect;
	}

	get rectOnRear() {
		return this.rect;
	}

	get leftX(): number {
		return this.rect.x
	}
	get rightX(): number {
		return this.rect.x + this.rect.w;
	}
	get topY(): number {
		return this.rect.y;
	}
	get bottomY(): number {
		return this.rect.y + this.rect.h;
	}

	get visible() {
		switch(this.orientation) {
			case Orientation.Horizontal:
				return this.rect.h > 0;
			case Orientation.Vertical:
				return this.rect.w > 0;
		}
		return false;
	}

	rotation: string;
	
	constructor(
		x: number,
		y: number,
		length: number,
		public size: ISize,
		public color: string,
		public orientation: Orientation,
		configId: string
	) {

		super(Orientation.Vertical);
		this.id = "Frame_" + uuidv4();

		this.depth = this.size.depth;
		this.width = this.size.width;
		this.height = this.size.height;
		
		this.length = length;
		this.configId = configId;

		const w = this.orientation === Orientation.Vertical ? size.width : this.length;
		const h = this.orientation === Orientation.Vertical ? this.length : size.width;

		this.rect = {
			x: Common.round(x),
			y: Common.round(y),
			w: Math.round(w * SvgParams.SCALE),
			h: Math.round(h * SvgParams.SCALE)
		};
	}

}
