import { SvgParams } from "../../constants/constants";
import { AreaType } from "../../interfaces/IAreaType";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { GlassWallBase } from "../glasses/glassWall-base.model";
import { GlassWall } from "../glasses/glassWall.model";
import { FrameProfile } from "../profiles/frame.model";
import { MuntinProfile } from "../profiles/muntin.model";
import { ElementType, Orientation } from "../ToolboxModel";
import { ElementStoreModel } from "./element-store.model";
import { FrameStoreModel } from "./frame-store.model";
import { GlassWallStoreModel } from "./glasswall-store.model";
import { GlassWallDiamondStoreModel } from "./glasswalldiamond-store.model";

export class MuntinStoreProfile extends ElementStoreModel {
	rect: IRect;
	orientation: Orientation;
	length: number;

	leftPartId: string;
	rightPartId: string;

	constructor(f: MuntinProfile) {
		super(f.id, f.configId);

		this.rect = f.rect;
		this.orientation = f.orientation;
		this.length = f.getLength();

		this.leftPartId = f.leftPart.id;
		this.rightPartId = f.rightPart.id;
	}
}


export class GlassWallsStoreModelBase extends ElementStoreModel {

	topFrame: FrameStoreModel;
	bottomFrame: FrameStoreModel;
	leftFrame: FrameStoreModel;
	rightFrame: FrameStoreModel;

	muntins: MuntinStoreProfile[] = [];
	minMuntinsCount: number;
	hanging: boolean;
	area: AreaType;

	type: ElementType;

	constructor(g: GlassWallBase) {
		super(g.id, g.configId);

		this.area = g.area;
		this.type = g instanceof GlassWall ? ElementType.GlassWall: ElementType.GlassWallDiamond;
		this.minMuntinsCount = g.minMuntinsCount;
		this.hanging = g.hanging;
	}

	protected storeFrames(g: GlassWallBase) {
		this.setFrame("bottomFrame", g);
		this.setFrame("topFrame", g);
		this.setFrame("leftFrame", g);
		this.setFrame("rightFrame", g);
	}

	protected storeMuntins(g: GlassWallBase) {
		g.muntins.forEach(p => {
			this.muntins.push(new MuntinStoreProfile(p));
		});
	}

	protected static restoreFrames(wallStore: GlassWallStoreModel | GlassWallDiamondStoreModel, wall: GlassWallBase, projectService: ProjectService, currentAreaElements: any = null) {
		this.restoreFrame(wallStore, "leftFrame", wall, projectService, currentAreaElements);
		this.restoreFrame(wallStore, "rightFrame", wall, projectService, currentAreaElements);
		this.restoreFrame(wallStore, "topFrame", wall, projectService, currentAreaElements);
		this.restoreFrame(wallStore, "bottomFrame", wall, projectService, currentAreaElements);
	}

	private static restoreFrame(wallStore: GlassWallStoreModel | GlassWallDiamondStoreModel, frameString: string, wall: GlassWallBase, projectService: ProjectService, currentAreaElements: any) {
		var frame: FrameStoreModel = wallStore[frameString];
		if (frame) {
			var newFrame = FrameStoreModel.restore(frame, projectService);
			wall[frameString] = newFrame;
			if (currentAreaElements) {
				currentAreaElements[ElementType.Frame].push(newFrame);
			}
		}
	}

	protected static restoreMuntins(g: GlassWallStoreModel | GlassWallDiamondStoreModel, wall: GlassWallBase) {
		wall.minMuntinsCount = g.minMuntinsCount;
		g.muntins.forEach((m: MuntinStoreProfile) => {
			var mnt = new MuntinProfile(m.rect.x, m.rect.y, m.length / SvgParams.SCALE, Orientation.Vertical, wall.id);
			mnt.id = m.id;
			mnt.rect = m.rect;
			wall.muntins.push(mnt);
		});
	}

	private setFrame(frameString: string, wall: any) {
		var frame: FrameProfile = wall[frameString];
		if (frame) {
			this[frameString] = new FrameStoreModel(frame);
		}
	}

}