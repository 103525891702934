import { GlassWallDiamond } from './glassWallDiamond.model';
import { ElementType } from '../ToolboxModel';
import { v4 as uuidv4 } from 'uuid';
import { ProjectService } from '../../services/project.service';
import { IPoint } from '../../interfaces/IPoint';
import { AreaType } from '../../interfaces/IAreaType';
import { SvgParams } from '../../constants/constants';
import { Common } from '../common';

export class GlassPartDiamond extends GlassWallDiamond {

	get type() {
		return ElementType.GlassPartDiamond
	}

	get cuttingable(): boolean {
		return false;
	}

	get surfaceArea() {
		return Common.calculateDiamondSurface(this.width, this.lowerHeight, this.height);
	}

	public id: string;

	constructor(
		public master: GlassWallDiamond,
		project: ProjectService,
		points: IPoint[],
		areaType: AreaType,
	) {
		super(project, master.def, points, areaType, null);

		this.id = uuidv4();
		this._drop = master.drop;
		this.hanging = master.hanging;
	}

	public static getLeftHeight(points: IPoint[]) {
		return (points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - points[SvgParams.LEFT_TOP_POINT_NUM].y) / SvgParams.SCALE;
	}

	public static getRightHeight(points: IPoint[]) {
		return (points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - points[SvgParams.RIGHT_TOP_POINT_NUM].y) / SvgParams.SCALE;
	}

	public static getWidthCorrected(points: IPoint[], leftMuntin: boolean, rightMuntn: boolean) {
		var length = (points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) / SvgParams.SCALE;;
		if (leftMuntin) {
			length -= 34; // 68 / 2;
		} else {
			length -= 50;
		}
		if (rightMuntn) {
			length -= 34; // 68 / 2;
		}
		else {
			length -= 50;
		}

		return length;
	}

	protected override refresh(): void {
		// empty method	- no need to process base class code
	}

}