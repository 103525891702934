import { Injectable, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { MarquisesParams, SvgParams } from '../../constants/constants';
import { Glass } from '../../models/glasses/glass.model';
import { MarquiseTop } from '../../models/marquises/marquise-top.model';
import { ProjectService } from '../project.service';
import { ElementType } from '../../models/ToolboxModel';
import { BarProfile } from '../../models/profiles/bar.model';
import { ElementSide } from '../../interfaces/IElementSide';
import { IMarquiseInfo } from '../../interfaces/IMarquiseInfo.interface';
import { Common } from '../../models/common';
import { SideFinishProfile } from '../../models/profiles/side-finish.model';
import { FrontProfile } from '../../models/profiles/front.model';
import { WallProfile } from '../../models/profiles/wall.model';

@Injectable({
	providedIn: 'root'
})
export class ChosenMarquiseService {	

	chosenMarquiseSubj: Subject<MarquiseTop[]> = new Subject<MarquiseTop[]>();

	private chosenMarquise: MarquiseTop = null;
	private currentMarquiseInfo: IMarquiseInfo;
	public widthToERP: number;

	constructor(private projectService: ProjectService) { }

	getChosenMarquise(): MarquiseTop {
		return this.chosenMarquise;
	}

	setChosenMarquise(marquises: MarquiseTop[] | null): void {
		this.chosenMarquiseSubj.next(marquises)
		this.chosenMarquise = marquises ? marquises[0] : null;
		if (!this.chosenMarquise) {
			return;
		}
		this.widthToERP = this.chosenMarquise.getERPWidth();
	}

	getRawWidth() {
		return this.chosenMarquise.getRawWidth();
	}
	getWidth() {
		return this.chosenMarquise.getWidth();
	}
	setWidth(length: number) {
		// read only
	}
	setRailsForMarquise(){
		this.chosenMarquise.setRailsForMarquise();
	}

	getEngineId(): string {
		return this.chosenMarquise.engine.configId;
	}
	setEngineId(id: string) {
		const newEngine = this.currentMarquiseInfo.engines.find(e => e.id === id);
		this.chosenMarquise.engine.setNewEngineParameters(newEngine);
	}

	getHandlerName(): string {
		return this.chosenMarquise.handler.name;
	}

	setDepth(length: number) {
		this.chosenMarquise.setErpDepth(length);
		this.reDraw(this.getYLocation());
	}

	getXLocation(): number {
		return (this.chosenMarquise.lineOnRoof.x2 - SvgParams.START_X) / SvgParams.SCALE;
	}
	setXLocation(location: number) {
		// read only
	}

	getYLocation(): number {
		return (this.chosenMarquise.lineOnRoof.y2 - SvgParams.START_Y) / SvgParams.SCALE;
	}
	setYLocation(location: number) {
		const diff = this.chosenMarquise.lineOnRoof.y2 - (SvgParams.START_Y + location * SvgParams.SCALE);

		this.chosenMarquise.lineOnLeft.x1 -= diff;
		this.chosenMarquise.lineOnLeft.x2 -= diff;
		this.chosenMarquise.lineOnLeft.x3 -= diff;
		this.chosenMarquise.lineOnLeft.x4 -= diff;

		this.chosenMarquise.lineOnRight.x1 += diff;
		this.chosenMarquise.lineOnRight.x2 += diff;
		this.chosenMarquise.lineOnRight.x3 += diff;
		this.chosenMarquise.lineOnRight.x4 += diff;

		this.reDraw(location);
		this.setEngineYLocation();
	}

	private reDraw(locationY: number) {
		this.chosenMarquise = ChosenMarquiseService.setCoordinates(this.chosenMarquise, locationY, this.projectService);
		this.setNewCoverAndExtension();
	}

	public static setCoordinates(marq: MarquiseTop, location: number, prjSrv: ProjectService) {
		const depth = marq.getErpDepth();

		const corrBack = marq.isExpandedBack ? 0 : marq.correctionBack;
		
		marq.lineOnRoof.y2 = SvgParams.START_Y + location * SvgParams.SCALE;
		if (prjSrv.template.isStandalone && (marq.type == ElementType.MarquiseTopBottom || marq.type == ElementType.MarquiseTopBottomMouseover)) {
			const rea = prjSrv.profileService.roofElements[ElementType.WallProfile][0] as WallProfile;
			const y2 = rea.rectOnRoof.y + rea.rectOnRoof.h;
			if (marq.lineOnRoof.y2 < y2) {
				marq.lineOnRoof.y2 = y2;
			}
		}

		marq.lineOnRoof.y3 = marq.lineOnRoof.y2;
		marq.lineOnRoof.y1 = marq.lineOnRoof.y2 + depth * SvgParams.SCALE;
		marq.lineOnRoof.y4 = marq.lineOnRoof.y1;

		// const w = (location + depth) * SvgParams.SCALE - corrBack;
		const w = depth * SvgParams.SCALE - corrBack;


		const sfl = prjSrv.profileService.leftSideElements[ElementType.SideFinish][0] as SideFinishProfile;
		var yl = sfl.rectOnLeft.y - MarquisesParams.TOP_HEIGHT;
		const sfr = prjSrv.profileService.rightSideElements[ElementType.SideFinish][0] as SideFinishProfile;
		var yr = sfr.rectOnRight.y - MarquisesParams.TOP_HEIGHT;
		const fro = prjSrv.profileService.frontElements[ElementType.Front][0] as FrontProfile;
		var yf = fro.rectOnFront.y - MarquisesParams.TOP_HEIGHT;

		if (marq.type == ElementType.MarquiseTopBottom) {
			const sd = sfl.height * SvgParams.SCALE + MarquisesParams.TOP_HEIGHT;
			yl += sd;
			yr += sd;
			yf += sd;
		}

		const r = (location * Math.tan(Common.toRadians(prjSrv.template.dropAngle))) * SvgParams.SCALE;

		marq.lineOnRight.x1 = SvgParams.START_X + (prjSrv.template.depth - depth) * SvgParams.SCALE + corrBack - location * SvgParams.SCALE;
		marq.lineOnRight.x2 = marq.lineOnRight.x1;

		marq.rectOnLeft = { x: marq.lineOnLeft.x1, y: yl + r, w: w, h: MarquisesParams.TOP_HEIGHT };
		marq.anchorLeft = { x: marq.rectOnLeft.x, y: yl + r + MarquisesParams.TOP_HEIGHT };
		marq.rotationLeft = sfl.rotation;

		marq.rectOnRight = { x: marq.lineOnRight.x1, y: yr + r, w: w, h: MarquisesParams.TOP_HEIGHT };
		marq.anchorRight = { x: marq.rectOnRight.x + w, y: yr + r + MarquisesParams.TOP_HEIGHT };
		marq.rotationRight = sfr.rotation;

		this.setRectOnFront(marq, prjSrv);
		return marq;
	}

	private static setRectOnFront(marq: MarquiseTop, prjSrv: ProjectService) {
		const fro = prjSrv.profileService.frontElements[ElementType.Front][0] as FrontProfile;
		var yf = 0;

		if (marq.type == ElementType.MarquiseTopBottom) {
			yf = fro.rectOnFront.y + fro.rectOnFront.h;
		} else {
			yf = fro.rectOnFront.y - MarquisesParams.TOP_HEIGHT;
		}

		marq.rectOnFront = { x: marq.lineOnRoof.x1, y: yf, w: marq.lineOnRoof.x3 - marq.lineOnRoof.x1, h: MarquisesParams.TOP_HEIGHT };
	}

	private setNewCoverAndExtension() {
		const depthLength = this.chosenMarquise.getErpDepth();
		this.chosenMarquise.setCover(depthLength);
		this.chosenMarquise.setExtension(0);

		const endYPointOfMarquise = this.chosenMarquise.lineOnRoof.y1;
		const endYPointOfProject = this.projectService.template.depth * SvgParams.SCALE + SvgParams.START_Y;
		if (endYPointOfMarquise > endYPointOfProject) {
			const newExtension = (endYPointOfMarquise - endYPointOfProject) / SvgParams.SCALE;
			this.chosenMarquise.setExtension(newExtension);
			const newCover = depthLength - this.chosenMarquise.getExtension();
			this.chosenMarquise.setCover(newCover);
		}
	}

	changePosition(side: ElementSide) {
		const sside = side.toString().toLowerCase();
		const newFirstGlass: Glass = this.chosenMarquise.firstGlass[sside + "Bar"][sside + "Glass"];
		const newLastGlass: Glass = this.chosenMarquise.lastGlass[sside + "Bar"][sside + "Glass"];

		const corrLeft = ChosenMarquiseService.getCorrection(this.chosenMarquise, ElementSide.Left, newFirstGlass);
		const corrRight = ChosenMarquiseService.getCorrection(this.chosenMarquise, ElementSide.Right, newLastGlass);
		const leftX = ChosenMarquiseService.getXPointsOnRoof(this.chosenMarquise.type, newFirstGlass, ElementSide.Left, this.chosenMarquise.isExpandedLeft) + corrLeft;
		const rightX = ChosenMarquiseService.getXPointsOnRoof(this.chosenMarquise.type, newLastGlass, ElementSide.Right, this.chosenMarquise.isExpandedRight) - corrRight;
		this.chosenMarquise.lineOnRoof.x1 = leftX;
		this.chosenMarquise.lineOnRoof.x2 = leftX;
		this.chosenMarquise.lineOnRoof.x3 = rightX;
		this.chosenMarquise.lineOnRoof.x4 = rightX;
		this.chosenMarquise.leftBar = newFirstGlass.leftBar;
		this.chosenMarquise.rightBar = newLastGlass.rightBar;
		this.setEngineXLocation(this.chosenMarquise.engine.location);

		this.chosenMarquise.lineOnFront.x1 = leftX;
		this.chosenMarquise.lineOnFront.x2 = leftX;
		this.chosenMarquise.lineOnFront.x3 = rightX;
		this.chosenMarquise.lineOnFront.x4 = rightX;

		this.adjustExpansion(side);
		this.projectService.setMarquiseHandlers(this.chosenMarquise.type);

		ChosenMarquiseService.setRectOnFront(this.chosenMarquise, this.projectService);
	}

	changeWidth(side: ElementSide) {
		const sside = side.toString().toLocaleLowerCase();
		const newLastGlass: Glass = this.chosenMarquise.lastGlass[sside + "Bar"][sside + "Glass"];

		const corrRight = ChosenMarquiseService.getCorrection(this.chosenMarquise, ElementSide.Right, newLastGlass);
		const rightX = ChosenMarquiseService.getXPointsOnRoof(this.chosenMarquise.type, newLastGlass, ElementSide.Right, this.chosenMarquise.isExpandedRight) - corrRight;
		this.chosenMarquise.lineOnRoof.x3 = rightX;
		this.chosenMarquise.lineOnRoof.x4 = rightX;
		this.setEngineXLocation(this.chosenMarquise.engine.location);

		this.chosenMarquise.lineOnFront.x3 = rightX;
		this.chosenMarquise.lineOnFront.x4 = rightX;

		this.chosenMarquise.rightBar = newLastGlass.rightBar;

		this.adjustExpansion(side);
		this.projectService.setMarquiseHandlers(this.chosenMarquise.type);
		this.widthToERP = this.chosenMarquise.getERPWidth();
		ChosenMarquiseService.setRectOnFront(this.chosenMarquise, this.projectService);

	}

	public adjustExpansion(side: ElementSide) {
		if (this.chosenMarquise.getWidth() > this.chosenMarquise.marquiseInfo.maxWidth) {
			this.expand(side, false, this.chosenMarquise);
			if (this.chosenMarquise.getWidth() > this.chosenMarquise.marquiseInfo.maxWidth) {
				const oppositeSite = side == ElementSide.Left ? ElementSide.Right : ElementSide.Left;
				this.expand(oppositeSite, false, this.chosenMarquise);
			}
			this.chosenMarquise.calculateMarkers();
		} else {
			var neib = this.chosenMarquise.getMarquiseOnSide(side, this.projectService.profileService.roofElements);
			if (neib && this.chosenMarquise['isExpanded' + side]) {
				this.expand(side, false, this.chosenMarquise);
				this.expand(side == ElementSide.Left ? ElementSide.Right : ElementSide.Left, false, neib);
			} else {
				this.chosenMarquise.calculateMarkers();
			}
		}
		ChosenMarquiseService.setRectOnFront(this.chosenMarquise, this.projectService);
	}

	expandSide(side: ElementSide, expand: boolean) {
		this.expand(side, expand, this.chosenMarquise);
		if (this.chosenMarquise.type == ElementType.MarquiseTopBottom) {
			return;
		}
		var neib = this.chosenMarquise.getMarquiseOnSide(side, this.projectService.profileService.roofElements);
		if (neib) {
			this.expand(side == ElementSide.Left ? ElementSide.Right : ElementSide.Left, false, neib);
		}
	}

	private expand(side: ElementSide, expand: boolean, marq: MarquiseTop) {
		var corr = 0;
		if (side == ElementSide.Left) {

			if (expand) {
			} else {
				corr = marq.firstGlass.leftBar.type == ElementType.SideFinish ? marq.correctionExt : marq.correctionInt;
			}

			marq.isExpandedLeft = expand;
			const leftX = ChosenMarquiseService.getXPointsOnRoof(marq.type, marq.firstGlass, ElementSide.Left, marq.isExpandedLeft) + corr;
			marq.lineOnRoof.x1 = leftX;
			marq.lineOnRoof.x2 = leftX;
			this.setEngineXLocation(marq.engine.location);

			marq.lineOnFront.x1 = leftX;
			marq.lineOnFront.x2 = leftX;

		} else if (side == ElementSide.Right) {

			if (expand) {
			} else {
				corr = marq.lastGlass.rightBar.type == ElementType.SideFinish ? marq.correctionExt : marq.correctionInt;
			}

			marq.isExpandedRight = expand;
			const rightX = ChosenMarquiseService.getXPointsOnRoof(marq.type, marq.lastGlass, ElementSide.Right, marq.isExpandedRight) - corr;
			marq.lineOnRoof.x3 = rightX;
			marq.lineOnRoof.x4 = rightX;
			this.setEngineXLocation(marq.engine.location);

			marq.lineOnFront.x3 = rightX;
			marq.lineOnFront.x4 = rightX;
		}
		this.chosenMarquise.calculateMarkers();		
		ChosenMarquiseService.setRectOnFront(this.chosenMarquise, this.projectService);
	}

	relocateEngine(location: ElementSide) {
		if (location == this.chosenMarquise.engine.location) {
			return;
		}
		this.setEngineXLocation(location);

		this.chosenMarquise.engine.location = location;
	}

	setEngineXLocation(location: ElementSide) {
		if (location == ElementSide.Left) {
			this.chosenMarquise.engine.rect.x = this.chosenMarquise.lineOnRoof.x2;
		} else {
			this.chosenMarquise.engine.rect.x = this.chosenMarquise.lineOnRoof.x3 - this.chosenMarquise.engine.rect.w;
		}
	}

	setEngineYLocation() {
		this.chosenMarquise.engine.rect.y = this.chosenMarquise.lineOnRoof.y2;
	}

	public static getXPointsOnRoof(type: ElementType, glass: Glass, side: ElementSide, expanded: boolean): number {
		const bar: BarProfile = glass[side.toLowerCase() + 'Bar'];
		if (bar.type == ElementType.SideFinish) {
			if (type == ElementType.MarquiseTopUp) {
				return bar.leftGlass == null ? bar.lineOnRoof.x1 - 3 : bar.lineOnRoof.x1 + 3;
			} else {
				return bar.leftGlass == null ? bar.lineOnRoof.x1 - (expanded ? 3 : 0) : bar.lineOnRoof.x1 + (expanded ? 3 : 0);
			}
		} else {
			const multipl = (side == ElementSide.Left ? 1 : -1);
			const w = (side == ElementSide.Left ? 0 : bar.rectOnRoof.w);
			if (type == ElementType.MarquiseTopUp) {
				return bar.rectOnRoof.x + w + (expanded ? 0 : multipl * bar.rectOnRoof.w / 2);
			} else {
				return bar.rectOnRoof.x + w + multipl * bar.rectOnRoof.w / 2;
			}
		}		
	}

	public static getCorrection(marq: MarquiseTop, side: ElementSide, newGlass: Glass = null) {
		const osBar = side.toLowerCase() + 'Bar';
		var bar: BarProfile = newGlass == null ? marq[osBar] : newGlass[osBar];
		if (marq['isExpanded' + side]) {
			return 0;
		}
		if (marq.type == ElementType.MarquiseTopUp && bar.type == ElementType.SideFinish) {
			return 0;
		}
		return (bar.type == ElementType.SideFinish ? marq.correctionExt : marq.correctionInt);
	}

	public expandBack(status: boolean) {
		var y = status ? 0 : this.chosenMarquise.correctionBack / SvgParams.SCALE;
		this.chosenMarquise.isExpandedBack = status;
		this.setYLocation(y);
	}	

	public getBackExpansion() {
		return this.chosenMarquise.isExpandedBack ? 0 : this.chosenMarquise.correctionBack / SvgParams.SCALE;
	}

}
