<div class="row" style="padding-bottom: 10px;">
	<div class="col col-4"><span i18n="Common|Marquise type">Marquise type</span>:</div>
	<div class="col col-8">
		{{currMarqInfo.name}}
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numWidth [value]="currMarq.widthCorrected" [disabled]="true"
			[max]="currMarq.maxWidth" [min]="currMarq.marquiseInfo.minWidth" ></app-numeric-textbox>&nbsp;mm&nbsp;			
		<div kendoTooltip>
			<span [attr.title]="changeWidthTooltip('Left')">
				<button kendoButton (click)="changeWidthBtnClick('Left')"
					[disabled]="projectService.readOnly || isChangeWidthBtnDisable('Left')"
					[svgIcon]="projectService.svgIcons.caretAltLeftIcon"></button>
			</span>
			<span [attr.title]="changeWidthTooltip('Right')">
				<button kendoButton (click)="changeWidthBtnClick('Right')"
					[disabled]="projectService.readOnly || isChangeWidthBtnDisable('Right')"
					[svgIcon]="projectService.svgIcons.caretAltRightIcon"></button>
			</span>
		</div>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Height">Height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [value]="currMarq.height" [decimals]="0" [min]="currMarq.marquiseInfo.minHeight" [max]="currMarq.maxHeight"
			[disabled]="projectService.readOnly"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numLocX [value]="currMarq.locationX" [disabled]="true"
			[min]="currMarq.minLocationX" [max]="currMarq.maxLocationX"></app-numeric-textbox>&nbsp;mm&nbsp;
		<span kendoTooltip [attr.title]="changePositionTooltip('Left')">
			<button kendoButton (click)="positionClick('Left')"
				[disabled]="projectService.readOnly || changePositionDisabled('Left')"
				[svgIcon]="projectService.svgIcons.rewindIcon"></button>
		</span>
		<span kendoTooltip [attr.title]="changePositionTooltip('Right')" >
			<button kendoButton (click)="positionClick('Right')"
				[disabled]="projectService.readOnly || changePositionDisabled('Right')"
				[svgIcon]="projectService.svgIcons.forwardIcon"></button>
		</span>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="currMarq.locationY" [disabled]="projectService.readOnly"
			[min]="currMarq.minLocationY" [max]="currMarq.maxLocationY"></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Distance">Distance</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numDis [min]="0" [max]="currMarq.maxHeight - currMarq.marquiseInfo.minHeight - currMarq.handler.heightCorrection" [value]="currMarq.distance"
			[disabled]="projectService.readOnly"></app-numeric-textbox>&nbsp;mm <span i18n="Common|Distance description label">from gutter profile</span>
	</div>
</div>

<div class="row">
	<div class="col col-4">
		<span i18n="Marquise|Montage position">Montage position</span>:
	</div>
	<div class="col col-8">
		<kendo-buttongroup selection="single" kendoTooltip>
			<button kendoButton [svgIcon]="projectService.svgIcons.bringForwardIcon" [toggleable]="true" [selected]="currMarq.montage == 'Inside'"
				[disabled]="!isMontagePossible('Inside')" (click)="changeMontage('Inside')"
				i18n-title="Marquise|Tooltip:Montage inside" title="Montage inside the constuction"></button>
			<button kendoButton [svgIcon]="projectService.svgIcons.bringToBackIcon" [toggleable]="true" [selected]="currMarq.montage == 'Middle'"
				[disabled]="!isMontagePossible('Middle')" (click)="changeMontage('Middle')"
				i18n-title="Marquise|Tooltip:Montage middle" title="Montage between columns"></button>
			<button kendoButton [svgIcon]="projectService.svgIcons.bringBackwardIcon" [toggleable]="true" [selected]="currMarq.montage == 'Outside'"
				[disabled]="!isMontagePossible('Outside')" (click)="changeMontage('Outside')"
				i18n-title="Marquise|Tooltip:Montage outside" title="Montage outside the constuction"></button>
		</kendo-buttongroup>
	</div>

</div>


<div class="row">
	<div class="col col-4"><span i18n="Marquise|Engine type">Engine type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="currMarqInfo.engines" textField="name" valueField="id"
			[(ngModel)]="engineId" [valuePrimitive]="true" [disabled]="projectService.readOnly">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Marquise|Engine location">Engine location</span>:</div>
	<div class="col col-8">
		<kendo-buttongroup selection="single" [disabled]="projectService.readOnly" kendoTooltip>
			<button kendoButton [selected]="currMarq.engine.location == 'Left'" [toggleable]="true" [svgIcon]="projectService.svgIcons.loginIcon" style="transform: rotate(180deg);"
				(selectedChange)="relocateEngine('Left')" i18n-title="Marquise|Tooltip:Move engine to the left" title="Move engine to the left"></button>
			<button kendoButton [selected]="currMarq.engine.location == 'Right'" [toggleable]="true" [svgIcon]="projectService.svgIcons.loginIcon"
				(selectedChange)="relocateEngine('Right')" i18n-title="Marquise|Tooltip:Move engine to the right" title="Move engine to the right"></button>
		</kendo-buttongroup>
	</div>
</div>

<app-color-palette [colorsPalette]="currMarqInfo.colors" [element]="currMarq"></app-color-palette>

<div class="row">
	<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly" i18n="Common|Delete">Delete</button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Montage:</div>
		<div class="col col-8">{{ currMarq.montage }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Handler type:</div>
		<div class="col col-8">{{ currMarq.handler?.name }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{ currMarq.marquiseInfo.minWidth }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{ currMarq.maxWidth }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ currMarq.marquiseInfo.minHeight }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ currMarq.maxHeight }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Min X location </div>
		<div class="col col-8">{{ currMarq.minLocationX }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max X location </div>
		<div class="col col-8">{{ currMarq.maxLocationX }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Min Y location </div>
		<div class="col col-8">{{ currMarq.minLocationY }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max Y location </div>
		<div class="col col-8">{{ currMarq.maxLocationY }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max distance </div>
		<div class="col col-8">{{ currMarq.maxHeight - currMarq.marquiseInfo.minHeight - currMarq.handler.heightCorrection }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Channels:</div>
		<div class="col col-8">{{currMarqInfo.channels}}</div>
	</div>
</div>
