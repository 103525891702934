<div class="row">
	<div class="col col-4"><span i18n="Common|Quantity">Quantity</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #quantNum [value]="current.quantity" [min]="current.minQuantity" [max]="current.maxQuantity" [step]="1" [disabled]="projectService.readOnly"></app-numeric-textbox>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
	<div class="col col-8">
		<span [attr.title]="current.isRequired ? 'Option is required' : ''">
			<button class="col" kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly || current.isRequired || !current.freeHand" i18n="Common|Delete">Delete</button>
		</span>
	</div>
</div>

<card-common-properties *ngIf="showRemote || showComment" #common></card-common-properties>