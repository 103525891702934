<div>
	<kendo-dialog [title]="dialogTitle" *ngIf="isDialogOpened" (close)="close(false)" [width]="850" [maxHeight]="700">

		<div class="row">
			<div class="col-6" style="padding-top: 20px;" *ngFor="let led of getPatterns()">
				<img src="{{led.graphUrl}}" (click)="select(led)" alt="image Patterns"
					[ngClass]="[current?.patternId == led.patternId ? 'highlight' : '']"/>
			</div>
			<div class="col-12" *ngIf="patterns?.length == 0" style="padding: 100px;text-align: center;">
				<h1 i18n="LEDs|No pattern message">No patterns found</h1>
			</div>
		</div>

		<kendo-dialog-actions>
			<button kendoButton (click)="close(false)" i18n="Dialogs|'Cancel' button">Cancel</button>
			<button kendoButton (click)="close(true)" primary="true" [disabled]="!current"
				i18n="Dialogs|'Confirm' button">Confirm</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>

