import { MarquisesParams, SvgParams, Tags } from '../../constants/constants';
import { AreaType } from '../../interfaces/IAreaType';
import { Montage } from '../../interfaces/IElementSide';
import { IRect } from '../../interfaces/IRect';
import { ProfileService } from '../../services/profile.service';
import { Common } from '../common';
import { GlassWall } from '../glasses/glassWall.model';
import { FrameProfile } from '../profiles/frame.model';
import { Profile } from '../profiles/profile.model';
import { ElementType, Orientation } from '../ToolboxModel';
import { MarquiseVertical } from './marquise-vertical.model';

export class MarquiseRight extends MarquiseVertical {

	override get rect(): IRect {
		return this.rectOnRight;
	}

	public get isFront(): boolean {
		return false;
	}

	protected get initialMontage(): Montage {
		return Montage.Outside;
	}

	protected getTop(): number {
		return this.topFrame.rectOnLeft.y;
	}
	protected getHeight(): number {
		var h = Common.round(this.projectService.template.backHeight) * SvgParams.SCALE;
		var top1 = this.getTop() - SvgParams.START_Y;
		var top2 = this.areaPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y - SvgParams.START_Y - h;
		h -= top1;
		h += top2;

		return h;
	}

	protected createFrameInternal(orient: Orientation): FrameProfile {
		return MarquiseRight.createFrame(this.projectService, this.marquiseInfo, orient);
	}


	protected getLeftProfileY() {
		return this.projectService.getProfileY(this.leftProfile, AreaType.Right);
	}
	protected getRightProfileY() {
		return this.projectService.getProfileY(this.rightProfile, AreaType.Right);
	}

	public isMontageAllowed(p: Montage): boolean {
		const marqs = this.projectService.profileService.getMarquisesVertical().filter(m => m.id != this.id);
		switch (p) {
			case Montage.Outside:
				var cl = marqs.find((m: MarquiseVertical) => (m.leftProfile?.id == this.leftProfile?.id || m.rightProfile?.id == this.rightProfile?.id) && m.montage == Montage.Outside);
				if (cl) {
					return false;
				}
				break;


			case Montage.Inside:
				var cl = marqs.find((m: MarquiseVertical) => (m.leftProfile?.id == this.rightProfile?.id || m.rightProfile?.id == this.leftProfile?.id) && m.montage == Montage.Inside);
				if (cl) {
					return false;
				}
				break;

			case Montage.Middle:
				const x1 = this.leftProfile.rectOnRight.x;
				const x2 = this.rightProfile.rectOnRight.x;

				const verts = this.projectService.profileService.getVerticals(AreaType.Right);
				const mv = verts.find(v => v.leftX >= x1 && v.rightX <= x2 && v.id != this.id);
				return mv == null;
		}

		return true;
	}

	protected getMasterProfile(): Profile {
		return this.leftProfile;
	}

	protected adjustMontage() {
		const h = this.rectOnRight.h;
		const depth = this.marquiseInfo.size.depth * SvgParams.SCALE;

		const x = MarquiseVertical.getX1(this.leftProfile, this.area, this.montage);
		const x2 = MarquiseVertical.getX2(this.rightProfile, this.area, this.montage);

		const w = x2 - x;

		var lp: Profile = this.leftProfile;
		if (!lp.rectOnRoof) {
			const cols = this.getColumns();
			lp = cols.find(c => c.rectOnRight.x + c.rectOnRight.w - MarquisesParams.COLUMN_HANDLER_POINT * SvgParams.SCALE <= SvgParams.START_X + this.locationX * SvgParams.SCALE);
		}

		const roofY = SvgParams.START_Y + (this.projectService.template.depth - this.locationX - this.width) * SvgParams.SCALE

		switch (this.montage) {
			case Montage.Inside:
				this.rectOnFront = {
					x: lp.rectOnRoof.x - depth,
					y: this.rectOnRight.y,
					w: depth,
					h: h
				}
				this.rectOnRoof = {
					x: this.rectOnFront.x,
					y: roofY,
					w: depth,
					h: (this.width - this.locationX) * SvgParams.SCALE
				}
				break;
			case Montage.Middle:
				this.rectOnFront = null;
				this.rectOnRoof = null;
				break;
			case Montage.Outside:
				this.rectOnFront = {
					x: lp.rectOnRoof.x + lp.rectOnRoof.w,
					y: this.rectOnRight.y,
					w: depth,
					h: h
				}
				this.rectOnRoof = {
					x: this.rectOnFront.x,
					y: roofY,
					w: depth,
					h: w
				}
				break;
		}

		this.rectOnLeft = null;
		this.rectOnRight = {
			x: x,
			y: this.rectOnRight.y,
			w: w,
			h: h
		};


		if (this.topFrame) {
			const left = this.leftProfile.rectOnRight;
			const lx1 = left.x + left.w;
			const lx2 = this.rightProfile.rectOnRight.x;

			this.topFrame.rectOnRight.x = left.x + left.w;
			this.topFrame.rectOnRight.y = this.rectOnRight.y;
			this.topFrame.rectOnRight.w = lx2 - lx1;
		}

		if (this.engine.rect) {
			this.relocateEngine(this.engine.location);
		}

		this.calculateMarkers(this.rectOnRight);

	}

}