<kendo-card class="minor" style="width: 100%" *ngIf="projectService.useStatics">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12">
				<span i18n="Statics|Snow zone description">Deflections for snow zone</span> {{ projectService.template.snowZone }}
			</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Bar">Bar</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="projectService.template.deflections?.barDeflection" [disabled]="true"
					[decimals]="2" [format]="'n2'"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Front">Front</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="projectService.template.deflections?.frontDeflection" [disabled]="true"
					[decimals]="2" [format]="'n2'"></app-numeric-textbox> mm
			</div>
		</div>
	</kendo-card-body>
</kendo-card>

<kendo-card class="minor" style="width: 100%">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12">
				<span i18n="Statics|No snow description">No snow deflections</span>
			</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Bar">Bar</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="projectService.template.deflections?.snowZeroBarDeflection" [disabled]="true"
					[decimals]="2" [format]="'n2'"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Front">Front</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="projectService.template.deflections?.snowZeroFrontDeflection" [disabled]="true"
					[decimals]="2" [format]="'n2'"></app-numeric-textbox> mm
			</div>
		</div>
	</kendo-card-body>
</kendo-card>



