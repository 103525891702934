import { SvgParams } from "../../constants/constants";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { Glass } from "../glasses/glass.model";
import { GlassPart } from "../glasses/glassPart.model";
import { ISize } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";
import { VentilatorStoreModel } from "./ventilator-store.model";

export class GlassStoreModel extends ElementStoreModel {

	topBarId: string;
	bottomBarId: string;
	leftBarId: string;
	rightBarId: string;
	rect: IRect;
	length: number;
	size: ISize;
	ventilator: VentilatorStoreModel;

	constructor(g: Glass | GlassPart) {
		super(g.id, g.configId);

		this.topBarId = g.topBar?.id;
		this.bottomBarId = g.bottomBar?.id;
		this.leftBarId = g.leftBar?.id;
		this.rightBarId = g.rightBar?.id;
		this.length = g.getLength();
		this.rect = g.rect;
		if (g.ventilator) {
			this.ventilator = new VentilatorStoreModel(g.ventilator);
		}

		var d = this.length;
		if (!d) {
			d = g.rect.h / SvgParams.SCALE;
		}

		if (g instanceof Glass) {
			this.size = { width: Math.round(g.rect.w / SvgParams.SCALE), depth: Math.round(d), height: undefined };
		}
	}

	public static restore(g: GlassStoreModel, projectService: ProjectService) {
		let glassInfo = projectService.template.glasses.find(t => t.id === g.configId);
		var glass = new Glass(projectService.template, glassInfo, g.rect.x, g.rect.y, g.rect.w, g.rect.h, g.length, glassInfo.statics.paddingTop, glassInfo.statics.paddingBottom);
		glass.id = g.id;
		glass.rect = g.rect;

		if (g.ventilator) {
			glass.ventilator = VentilatorStoreModel.restore(g.ventilator, projectService);
			glass.ventilator.bottomGlass = glass;
		}
		return glass;
	}
}
