import { AreaType } from "../../interfaces/IAreaType";
import { ProjectService } from "../../services/project.service";
import { ElementType, ToolboxItem } from "../ToolboxModel";
import { ColumnProfile } from "../profiles/column.model";
import { WallGutterProfile } from "../profiles/wall-gutter.model";
import { WallProfile } from "../profiles/wall.model";
import { ElementStoreModel } from "./element-store.model";
import { WallGutterStoreModel } from "./wallgutter-store.model";


export class RearStoreModel extends ElementStoreModel {
	length: number;
	columns: string[];
	leftRearId: string;
	rightRearId: string;
	wallGutters: WallGutterStoreModel[];

	constructor(c: WallProfile) {
		super(c.id, c.configId, c.tag, c.cuttingOnSite);

		this.length = Math.round(c.getLength());
		this.columns = [];
		c.columns.forEach((c: ColumnProfile) => {
			this.columns.push(c.id);
		});
		this.leftRearId = c.leftRear?.id;
		this.rightRearId = c.rightRear?.id;

		this.storeLocationAndSize(c);

		this.wallGutters = [];
		if (c.wallGutters) {
			c.wallGutters.forEach(g => {
				this.wallGutters.push(new WallGutterStoreModel(g));
			});
		}

	}

	public static restore(r: RearStoreModel, projectService: ProjectService) {
		var fp = new WallProfile(AreaType.Roof, r.rectOnRoof.x, r.rectOnRoof.y, projectService.template, r.length, AreaType.Roof, projectService.template.rearId);
		fp.id = r.id;
		fp.tag = r.tag;
		fp.cuttingOnSite = r.cuttingOnSite;
		ElementStoreModel.restoreLocationAndSize(r, fp);

		if (r.wallGutters) {
			r.wallGutters.forEach(g => {
				const info = projectService.template.wallGutters.find(w => w.id == g.configId);
				var wg = new WallGutterProfile(g.rectOnRoof.x, g.rectOnRoof.y, fp, info);
				wg.id = g.id;
				fp.wallGutters.push(wg);
				projectService.profileService.roofElements[ElementType.WallGutter].push(wg);
			});
		}

		return fp;
	}
}
