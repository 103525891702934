import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs/numerictextbox/numerictextbox.component';

@Component({
	selector: 'app-numeric-textbox',
	templateUrl: './num-tbx.component.html'
})
export class NumTbxComponent implements OnInit {
	@ViewChild('num', { static: false }) num: NumericTextBoxComponent;

	@Input() disabled: boolean;
	@Input() min: number;
	@Input() max: number;
	@Input() step: number = 100;
	@Input() decimals: number = 0;
	@Input() format: string = "'n0'";
	@Input() style: string = "width:100px";
	@Input() delay: number = 0;

	@Input() value: number;
	@Output() valueChanged = new EventEmitter<number>();

	constructor() { }

	ngOnInit(): void {
	}

	onValueChange() {
		if (this.num.value > this.max) {
			this.num.value = this.max;
			this.num.focus();
			return;
		}
		if (this.num.value < this.min) {
			this.num.value = this.min;
			this.num.focus();
			return;
		}
		if (this.value != this.num.value) {
			this.valueChanged.emit(this.num.value);
		}
	}

	public onAfterValueChange(): void {
		if (this.delay > 0) {
			setTimeout(() => {
				this.onValueChange();
			}, this.delay);
		} else {
			this.onValueChange();
		}
	}

	rebind(v: number, focus: boolean = true) {
		if (this.num.value != v) {
			this.num.value = v;
			if (focus) {
				this.num.focus();
			}
		}
	}


}
