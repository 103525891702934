import { Component, Input } from '@angular/core';
import { SvgParams, Tags } from 'src/app/_core/constants/constants';
import { ICircle } from 'src/app/_core/interfaces/ICircle';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { IRect } from 'src/app/_core/interfaces/IRect';
import { ColumnProfile } from 'src/app/_core/models/profiles/column.model';


@Component({
	selector: 'g[app-outflow]',
	templateUrl: './outflow.component.html'
})
export class OutflowComponent {

	out: ICircle;
	cover: boolean;

	@Input() public item: ColumnProfile;	
	@Input() public maxy: number;

	constructor() { }

	public getRects() {
		if (!this.item.outflow) {
			this.out = null;
			this.cover = false;
			return [];
		}
		var rects: IRect[] = [];
		
		var x: number = 0;

		if (this.item.tag == Tags.LEFT_COLUMN_TAG) {
			x = this.item.rectOnFront.x - 35;
			if (this.item.foundation && this.item.foundation.size) {
				x -= (this.item.foundation.size.width / 2) * SvgParams.SCALE;
			} else {
				x -= 5;
			}
		} else {
			x = this.item.rectOnFront.x + this.item.rectOnFront.w + 5;
			if (this.item.foundation && this.item.foundation.size) {
				x += (this.item.foundation.size.width / 2) * SvgParams.SCALE;
			} else {
				x += 5;
			}
		}
		
		var y: number = this.maxy + 20;
		rects.push({ x, y, h: 10, w: 10 }); // left
		rects.push({ x: x + 10, y: y - 10, h: 10, w: 10 }); // top
		rects.push({ x: x + 20, y, h: 10, w: 10 }); // right
		rects.push({ x: x + 10, y: y + 10, h: 10, w: 10 }); // bottom

		switch (this.item.outflow) {
			case ElementSide.Top:
				this.out = { x: x + 15, y: y - 5, r: 4 };
				break;
			case ElementSide.Left:
				this.out = { x: x + 5, y: y + 5, r: 4 };
				break;
			case ElementSide.Right:
				this.out = { x: x + 25, y: y + 5, r: 4 };
				break;
			case ElementSide.Bottom:
				this.out = { x: x + 15, y: y + 15, r: 4 };
				break;
		}
		this.cover = this.item.outflowCover;		
		return rects;
	}

}
