import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { AreaType, RectName } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from "../../template";
import { ISize } from "src/app/_core/models/wizard/wizard-parameter.model";
import { ColumnProfile } from "./column.model";
import { GlassResizeMode } from "../../interfaces/IGlassResizeMode";
import { GutterProfile } from "./gutter.model";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class FrontProfile extends Profile {

  get type() {
    return ElementType.Front
  }

  get name(): string {
    return $localize`:Common|Front:Front`;
  }

  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  public gutterSize: ISize;
  public isShiftedDepthAllow: boolean;
  public gutter: GutterProfile;
  public minLength: number;
  public maxLength: number;

  public columns: ColumnProfile[];

  public leftFront: FrontProfile;
  public rightFront: FrontProfile;
  
  private _elongLeft: number;
  private _elongRight: number;

  public get elong() {
    return this._elongLeft + this._elongRight;
  }

  public get elongLeft() {
    return this._elongLeft;
  }

  public get elongRight() {
    return this._elongRight;
  }

  private _weight: number;
  get weight(){
    return this._weight;
  }

  public getLength(): number {
		return Math.round(this.length)
	}

	public setLength(value: number): void {
    if (value < 0) {
      return;
    }
		this.length = Math.round(value);
    var val = value * SvgParams.SCALE;

    RectName.forEach((k, v) => {
      if (this[k]?.w) {
        this[k].w = val;
      }
    });
	}

  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    template: ProjectTemplate,
    length: number,
    area: AreaType,
    configId: string,
    elongLeft: number,
    elongRight: number
  ) {

    const front = template.fronts.find(f => f.id == configId);
    super(Orientation.Horizontal, front.size, front.color);

    this.id = this.type + "_" + uuidv4();
    this.configId = configId;

    this._elongLeft = elongLeft;
    this._elongRight = elongRight;
    this._weight = front.weight;
    this.length = length + this.elong;

    this.gutterSize = front.gutterSize;
    this.isShiftedDepthAllow = front.isShiftedDepthAllow;
    this.minLength = front.minLength;
    this.maxLength = front.maxLength;
    this.columns = [];
    this._elongLeft = elongLeft;
    this._elongRight = elongRight;


    this.init(area, currentArea, x, y);
  }

  protected init(area: AreaType, currentArea: AreaType, x: number, y: number) {
    if (area === AreaType.None) {
      area = currentArea;
    }
    x -= this._elongLeft * SvgParams.SCALE;

    switch (area) {
      case AreaType.Roof:
        this.rectOnRoof = {
          x: x,
          y: y,
          w: Math.round((this.getLength()) * SvgParams.SCALE),
          h: Math.round(this.depth * SvgParams.SCALE),
        };
        break;
      case AreaType.Front:
        this.rectOnFront = {
          x: x,
          y: y,
          w: Math.round((this.getLength()) * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE),
        };
        break;
      case AreaType.Left:
        this.rectOnLeft = {
          x: x,
          y: y,
          w: Math.round(this.depth * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE)
        };
        break;
      case AreaType.Right:
        this.rectOnRight = {
          x: x,
          y: y,
          w: Math.round(this.depth * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE)
        };
        break;
    }

  }

  public setElong(value: number, side: GlassResizeMode): void {
    const l = this.getLength();
    switch(side) {
      case GlassResizeMode.Left:
        this._elongLeft = Math.max(value - this._elongRight, 0);
        break;
      case GlassResizeMode.Right:
        this._elongRight = Math.max(value - this._elongLeft, 0);
        break;
      default:
        return;
    }
  }


}
