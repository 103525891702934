import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { ISideLayout } from 'src/app/_core/interfaces/ISideLayout';
import { ToolboxItemType, ToolboxTypeElement } from 'src/app/_core/models/ToolboxModel';
import layouts from 'src/app/_core/models/_side-layouts.json';

@Component({
  selector: 'app-side-layout-dialog',
  templateUrl: './side-layout.component.html',
  styleUrls: ['./side-layout.component.css'],
})
export class SideLayoutComponent {
  
  @Input()
  set isDialogOpened(v: boolean) {
    if(v && !this._isDialogOpened) {
      this.layout = null;
      this.secondSide = false;
    }
    this._isDialogOpened = v;
  }
  get isDialogOpened() {
    return this._isDialogOpened;
  }

  @Input() area: AreaType;
  @Input() toolboxItemType: ToolboxItemType;
  @Output() selected = new EventEmitter<{ layout: ISideLayout, secondSide: boolean }>();

  layout: ISideLayout;
  secondSide: boolean;
  dialogTitle = $localize`:Side layout popup|Title:Choose side layout`;
  private _isDialogOpened: boolean;
  
  constructor() {}

  getLayouts() {
    var lays = layouts as ISideLayout[];
    if (this.toolboxItemType == ToolboxItemType.Elements) {
      return lays.filter(l => l.common == true);
    }
    const t = ToolboxTypeElement.get(this.toolboxItemType);
    return lays.filter(l => l.top == t || l.middle == t || l.bottom == t || l.left == t || l.right == t);
  }

  getImage(lv: ISideLayout) {
    return `assets/images/side-layouts/${this.area.toString().toLowerCase()}/${lv.id}.png`;
  }

  close(ok: boolean) {
    if (ok) {
      this.selected.emit({ layout: this.layout, secondSide: this.secondSide });
    } else {
      this.selected.emit(null);
    }
    this.isDialogOpened = false;
  }

  select(sl: ISideLayout) {
    this.layout = sl;
  }

}
