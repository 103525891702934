import { SvgParams } from '../constants/constants';
import { IAreaService } from '../interfaces/IAreaService';
import { AreaType, RectName } from '../interfaces/IAreaType';
import { IElement } from '../interfaces/IElement';
import { ElementSide } from '../interfaces/IElementSide';
import { IPoint } from '../interfaces/IPoint';
import { IPointer } from '../interfaces/IPointer';
import { IRect } from '../interfaces/IRect';
import { LayerLevel } from '../interfaces/LayerLevel';
import { LeftSideService } from '../services/areas/left-side.service';
import { RightSideService } from '../services/areas/right-side.service';
import { StraightService } from '../services/areas/straight.service';
import { ProfileService } from '../services/profile.service';
import { ProjectService } from '../services/project.service';
import { ElementType } from './ToolboxModel';
import { Common } from './common';
import { FrameProfile } from './profiles/frame.model';
import { Profile } from './profiles/profile.model';

export abstract class VerticalElement extends Profile implements IElement {

	abstract get type(): ElementType;
	abstract get rectOnFront(): IRect;
	abstract get rectOnLeft(): IRect;
	abstract get rectOnRight(): IRect;
	abstract get layer(): LayerLevel;
	abstract get leftX(): number;
	abstract get rightX(): number;
	abstract get topY(): number;
	abstract get bottomY(): number;
	abstract get defMaxWidth(): number;
	abstract get defMaxHeight(): number;
	abstract get defMinWidth(): number;
	abstract get defMinHeight(): number;
	
	protected refresh() {}

	get currentSideService(): IAreaService {
		return this.sideService;
	}

	get points(): IPoint[] {
		return Common.toPoints(this.rect);
	}

	private _rect: IRect;
	get rect(): IRect {
		return this._rect
	}
	set rect(v: IRect) {
		this._rect = v;
	}

	public get height() {
		return Math.round(this.rect.h / SvgParams.SCALE);
	}

	public set height(value: number) {
		this.rect.h = Math.round(value * SvgParams.SCALE);
	}

	public get width() {
		return Common.round(this.rect.w / SvgParams.SCALE);
	}

	public set width(value: number) {
		this.rect.w = Common.round(value * SvgParams.SCALE);
	}

	get locationX(): number {
		return Math.round((this.rect.x - SvgParams.START_X) / SvgParams.SCALE);
	}

	get locationY(): number {
		return Math.round((this.rect.y - SvgParams.START_Y) / SvgParams.SCALE);
	}

	isOnBottom(maxy: number): boolean {
		if (this.bottomNeib != null) {
			return false;
		}
		const currY = Math.round(this.bottomY);
		return Math.round(maxy) <= Math.round(currY);
	}

	get maxHeight(): number {
		const bottSpace = Common.round((this.getMaxBottom() - Common.round(this.bottomY)) / SvgParams.SCALE);
		const mh = Math.min(Common.round(this.height + bottSpace), this.defMaxHeight);
		return mh;
	}

	get maxWidth(): number {
		if (this.locationX == this.maxLocationX) {
			return this.width;
		}
		const rightSpace = this.leftNeib ? (this.leftX - this.leftNeib.rightX) / SvgParams.SCALE : 0;
		// console.debug('maxWidth', this.width, this.maxLocationX, this.minLocationX, rightSpace);
		return this.width + this.maxLocationX - this.locationX - rightSpace;
	}

	get minLocationX(): number {
		if (this.leftNeib) {
			var x = this.leftNeib.rightX;
			return (x - SvgParams.START_X) / SvgParams.SCALE;
		}
		var leftX = this.getVerticalPoints().leftX;
		return (leftX - SvgParams.START_X) / SvgParams.SCALE
	}

	get maxLocationX(): number {
		let rightX: number;
		if (this.rightNeib) {
			rightX = this.rightNeib.leftX;
		} else {
			rightX = this.getVerticalPoints().rightX;
		}

		rightX = (rightX - SvgParams.START_X) / SvgParams.SCALE;
		const maxWidth = Math.abs(rightX - this.locationX);
		var maxl = this.locationX + (maxWidth - this.width);
		return maxl;
	}

	get minLocationY(): number {
		var y = 0;
		if (this.topNeib) {
			y = this.topNeib.bottomY;
		} else {
			y = this.getCornerY();
		}
		return Math.round((y - SvgParams.START_Y) / SvgParams.SCALE);
	}

	get maxLocationY(): number {
		var y = this.getMaxBottom();
		const space = ((y - this.bottomY) / SvgParams.SCALE);
		const ty = (this.topY - SvgParams.START_Y) / SvgParams.SCALE;
		return Math.round(ty + space);
	}

	private getMaxBottom() {
		var y = 0;
		if (this.bottomNeib) {
			y = this.bottomNeib.topY;
		} else {
			y = this.projectService.CurrentAreaService?.getWorkAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y ?? SvgParams.START_Y;
		}

		return Common.round(y);
	}
	private getSideService() {
		return this.sideService ? this.sideService : this.projectService.CurrentAreaService;

	}
	private getCornerY() {
		switch (this.area) {
			case AreaType.Left:
				return (this.getSideService() as LeftSideService).getFrontCornerY(this.projectService.profileService.leftSideElements);
			case AreaType.Right:
				return (this.getSideService() as RightSideService).getFrontCornerY(this.projectService.profileService.rightSideElements);
			case AreaType.Front:
			case AreaType.Rear:
					return (this.getSideService() as StraightService).getTopProfileBottomY();
			default:
				var p = this.getSideService()?.getShapeAreaPoints();
				return Math.max(p[SvgParams.LEFT_TOP_POINT_NUM].y, p[SvgParams.RIGHT_TOP_POINT_NUM].y) ?? SvgParams.START_Y;
		}
	}

	protected getVerticalPoints(): { leftX: number, rightX: number, topY: number, bottomY: number } {
		var pts;
		const sp = this.points;		
		const ptr: IPointer = { offsetX: sp[SvgParams.RIGHT_TOP_POINT_NUM].x - 1, offsetY: sp[SvgParams.RIGHT_TOP_POINT_NUM].y + 1 }
		if (this.area == AreaType.Left || this.area == AreaType.Right) {
			var elems = this.area == AreaType.Left ? this.projectService.profileService.leftSideElements : this.projectService.profileService.rightSideElements;
			const points = this.getSideService().getGWDStartPoints(elems);
			pts = {
				leftX: points[SvgParams.LEFT_BOTTOM_POINT_NUM].x,
				rightX: points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x,
				topY: Math.min(points[SvgParams.LEFT_TOP_POINT_NUM].y, points[SvgParams.RIGHT_TOP_POINT_NUM].y),
				bottomY: points[SvgParams.LEFT_BOTTOM_POINT_NUM].y
			};
		} else {
			const cols = this.projectService.getSpace(ptr, this);
			pts = {
				leftX: cols.getLeftX(this.area), 
				rightX: cols.getRightX(this.area),
				topY: cols.getTopY(this.area),
				bottomY: cols.getBottomY(this.area, this.sideService)
			};
		}
		return pts;
	}
	public refreshSurround(profileService: ProfileService) {
		this.bottomNeib = profileService.findVerticalNeib(this, ElementSide.Bottom);
		this.topNeib = profileService.findVerticalNeib(this, ElementSide.Top);
		this.leftNeib = profileService.findVerticalNeib(this, ElementSide.Left);
		this.rightNeib = profileService.findVerticalNeib(this, ElementSide.Right);
		this.refresh();
	}
	public adjustSurround() {
		if (this.topNeib) {
			this.topNeib.refreshSurround(this.projectService.profileService);
		}
		if (this.bottomNeib) {
			this.bottomNeib.refreshSurround(this.projectService.profileService);
		}
		if (this.leftNeib) {
			this.leftNeib.refreshSurround(this.projectService.profileService);
		}
		if (this.rightNeib) {
			this.rightNeib.refreshSurround(this.projectService.profileService);
		}
	}
	public adjustHeight(diff: number) {
		const sn = RectName.get(this.area);
		if (this.leftProfile?.type == ElementType.Frame && this.leftProfile[sn].w > 0) {
			this.leftProfile[sn].h += diff * SvgParams.SCALE;
		}
		if (this.rightProfile?.type == ElementType.Frame && this.rightProfile[sn].w > 0) {
			this.rightProfile[sn].h += diff * SvgParams.SCALE;
		}
	}

	leftProfile: Profile;
	rightProfile: Profile;
	topProfile: FrameProfile;
	bottomProfile: FrameProfile;
	area: AreaType;

	protected bottomNeib: VerticalElement;
	protected topNeib: VerticalElement;
	protected leftNeib: VerticalElement;
	protected rightNeib: VerticalElement;

	public getNeib(side: ElementSide, near: boolean = false): VerticalElement {
		const neibString = side.toLowerCase() + 'Neib';
		const neib = this[neibString] as VerticalElement;

		if (neib && near) {
			const distance = 5;
			switch (side) {
				case ElementSide.Top:
					if (!(neib.bottomY + distance >= this.topY)) {
						return null;
					}
					break;
				case ElementSide.Bottom:
					if (!(neib.topY - distance <= this.bottomY)) {
						return null;
					}
					break;
				case ElementSide.Left:
					if (!(neib.rightX + distance <= this.leftX)) {
						return null;
					}
					break;
				case ElementSide.Right:
					if (!(neib.leftX + distance >= this.rightX)) {
						return null;
					}
					break;
			}
		}

		return neib;
	}

	get frames(): Profile[] {
		return VerticalElement.getFrames(this.area, this.leftProfile, this.rightProfile, this.topProfile, this.bottomProfile);
	}

	static getFrames(area: AreaType, leftProfile: Profile, rightProfile: Profile, topProfile: FrameProfile, bottomProfile: FrameProfile) {
		var fr: Profile[] = [];
		const sn = RectName.get(area);
		if (topProfile?.rect.h > 0) {
			fr.push(topProfile);
		}
		if (bottomProfile?.rect.h > 0) {
			fr.push(bottomProfile);
		}
		if (rightProfile?.type == ElementType.Frame && rightProfile[sn].w > 0) {
			fr.push(rightProfile as FrameProfile);
		}
		if (leftProfile?.type == ElementType.Frame && leftProfile[sn].w > 0) {
			fr.push(leftProfile as FrameProfile);
		}
		return fr;
	}

	constructor(protected projectService: ProjectService, protected sideService: IAreaService = null) {
		super();
	}
}

export class VirtualVertical extends VerticalElement {
	get defMinWidth(): number {
		return 0;
	}
	get defMinHeight(): number {
		return 0;
	}
	get defMaxWidth(): number {
		return 10000;
	}
	get defMaxHeight(): number {
		return 10000;
	}	
	get points(): IPoint[] {
		throw new Error('Method not implemented.');
	}
	get name(): string {
		return "";
	}
	get type(): ElementType {
		return ElementType.None;
	}
	get rect(): IRect {
		return {
			x: this.leftX,
			w: 2,
			y: this.topY,
			h: 2
		};
	}
	get rectOnFront(): IRect {
		return this.rect;
	}
	get rectOnLeft(): IRect {
		return this.rect;
	}
	get rectOnRight(): IRect {
		return this.rect;
	}
	get layer(): LayerLevel {
		return null;
	}
	get leftX(): number {
		return this.ptr.offsetX - 1;
	}
	get rightX(): number {
		return this.ptr.offsetX + 1;
	}
	get topY(): number {
		return this.ptr.offsetY - 1;
	}
	get bottomY(): number {
		return this.ptr.offsetY + 1;
	}
	 
	constructor(projectService: ProjectService, private ptr: IPointer, area: AreaType) {
		super(projectService);
		this.id = "";
		this.configId = "";
		this.area = area;
	}

}