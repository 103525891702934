import { DoorParams, SvgParams } from "../../constants/constants";
import { AreaLocation, AreaType } from "../../interfaces/IAreaType";
import { DoorType, IDoorInfo } from "../../interfaces/IDoorInfo";
import { IRect } from "../../interfaces/IRect";
import { ElementType, Orientation } from "../ToolboxModel";
import { Common } from "../common";
import { FrameProfile } from "../profiles/frame.model";
import { DoorStoreModel } from "../project-store/door-store.model";
import { Door } from "./door.model";
import variants from './_lock-variants.json';
import { ILockVariant } from "../../interfaces/ILockVariant";
import { VerticalCreator } from "../vertical-creator";
import { ProjectService } from "../../services/project.service";
import { Space } from "../space";
import { IAreaService } from "../../interfaces/IAreaService";
import { ElementSide } from "../../interfaces/IElementSide";
import { VirtualVertical } from "../vertical-element";

export class DoorCreator extends VerticalCreator {

	public static fromInstance(projectService: ProjectService, door: Door, sideService: IAreaService) {
		return new DoorCreator(projectService, new Space(door.leftProfile, door.rightProfile, door.topProfile, door.bottomProfile), door.area, sideService);
	}

	protected get maxWidthValidMsg(): string {
		return $localize`:Door|Validation message:Width of the new door is greater than maximum allowable!`;
	}
	protected get maxHeightValidMsg(): string {
		return $localize`:Door|Validation message:Height of the new door is greater than maximum allowable!`;
	}
	protected get minWidthValidMsg(): string {
		return $localize`:Door|Validation message:Width of the new door is less than minimum allowable!`;
	}
	protected get minHeightValidMsg(): string {
		return $localize`:Door|Validation message:Height of the new door is less than minimum allowable!`;
	}

	public getLockVariants(def: IDoorInfo): ILockVariant[] {
		var lv = variants as ILockVariant[];

		lv.forEach(l => {
			if (lv.filter(v => v.id == l.id).length > 1) {
				console.debug("Lock variant duplicate", l.id);
			}
		});

		return variants.filter(l => l.doorType == def.doorType);
	}

	private calculateGlassWidth(type: DoorType, systemWidth: number, wings: number) {
		let gw: number;
		if (type == DoorType.SingleSide) {
			gw = ((systemWidth - DoorParams.CLEARANCE - (2 * DoorParams.EXTERNAL_PADD) + ((wings - 1) * DoorParams.INTERNAL_PADD)) / wings) - DoorParams.GUMM_DIFF;
		} else {
			gw = ((systemWidth - DoorParams.CLEARANCE - (2 * DoorParams.EXTERNAL_PADD) - DoorParams.SIDE_PADD + ((wings - 2) * DoorParams.INTERNAL_PADD)) / wings) - DoorParams.GUMM_DIFF;
		}

		return Common.round(gw);
	}

	private calculateGlassHeight(systemHeight: number) {
		return Common.round(systemHeight - DoorParams.TOP_PADD * SvgParams.SCALE - DoorParams.BOTTOM_PADD * SvgParams.SCALE);
	}

	public getVariants(current: Door) {
		var variants = [];
		this.projectService.template.doors.forEach(d => {
			if (Common.isInLocation(d.locations, this.projectService.currentArea)) {
				if (this.checkVariant(d, current.width, false)) {
					variants.push(d);
				}
			}
		});
		return variants;
	}
	
	public checkVariant(def: IDoorInfo, width: number, checkGlasses: boolean) {
		if (width < def.minWidth || width > def.maxWidth) {
			return false;
		}

		if (!checkGlasses) {
			return true;
		}

		const g = def.glasses[0];
		var part = this.calculateGlassWidth(def.doorType, width, def.wings);
		if (part >= g.minWidth && part <= g.maxWidth && this.validateWidth(def) == "") {
			return true;
		}

		return false;
	}

	public calculateVariant(area: AreaType, dtype: DoorType, width: number = null) {
		if (!width) {
			width = this.getWidth()
		}
		const location = AreaLocation.get(area);
		const candidates: IDoorInfo[] = this.projectService.template.doors.filter(d => d.doorType == dtype && d.locations.find(l => l == location) != null);
		let def: IDoorInfo;
		candidates.every(c => {
			if (this.checkVariant(c, width, true)) {
				def = c;
			}
			return !def;
		});

		if (!def) {
			candidates.every(c => {
				if (this.checkVariant(c, width, false)) {
					def = c;
				}
				return !def;
			});

		}

		return def;
	}

	private createWings(r: IRect, def: IDoorInfo) {
		var wings: IRect[] = [];

		const gw = this.calculateGlassWidth(def.doorType, r.w, def.wings) * SvgParams.SCALE;
		const gh = this.calculateGlassHeight(r.h);
		const gy = Common.round(r.y + DoorParams.TOP_PADD * SvgParams.SCALE);


		const gchain = gw * def.wings - ((DoorParams.INTERNAL_PADD * SvgParams.SCALE) * (def.wings - 1));
		var gx = Common.round(r.x + (r.w * SvgParams.SCALE - gchain) / 2);
		for (var i = 0; i < def.wings; i++) {
			const g = def.glasses[0];
			wings.push({ x: gx, y: gy, w: gw, h: gh });
			gx += Common.round(gw - DoorParams.INTERNAL_PADD * SvgParams.SCALE);
		}
		
		return wings;
	}
	
	public createDoor(def: IDoorInfo, pattern: Door | DoorStoreModel = null, startPosY: number = null) {

		if (this.topProfile.configId == "") {
			this.topProfile.configId = def.connectorId;
		}
		if (this.bottomProfile.configId == "") {
			this.bottomProfile.configId = def.connectorId;
		}

		let rect: IRect;
		if (pattern) {
			rect = pattern.rect;
			if (pattern instanceof Door) {
				this.leftProfile = pattern.leftProfile;
				this.rightProfile = pattern.rightProfile;
				this.topProfile.rect.x = pattern.topProfile.rect.x;
				this.topProfile.rect.w = pattern.topProfile.rect.w;
				this.bottomProfile.rect.x = pattern.bottomProfile.rect.x;
				this.bottomProfile.rect.w = pattern.bottomProfile.rect.w;
			} else {
				this.topProfile.rect.x = pattern.topFrame.rect.x;
				this.topProfile.rect.w = pattern.topFrame.rect.w;
				if (pattern.bottomFrame?.rect) {
					this.bottomProfile.rect.x = pattern.bottomFrame.rect.x;
					this.bottomProfile.rect.w = pattern.bottomFrame.rect.w;	
				}
			}
		} else {
			const x = this.getX1();
			const w = this.getWidth();

			this.topProfile.rect.x = x;
			this.topProfile.rect.w = w * SvgParams.SCALE;

			this.bottomProfile.rect.x = x;
			this.bottomProfile.rect.w = w * SvgParams.SCALE;

			var y = Common.round(this.getY(startPosY));
			var h = Common.round(this.getHeight(startPosY));

			if (h > def.maxHeight * SvgParams.SCALE) {
				const diff = h - def.maxHeight * SvgParams.SCALE;
				h = def.maxHeight * SvgParams.SCALE;

				const vv = new VirtualVertical(this.projectService, { offsetX: x + 1, offsetY: y +1}, this.area);
				vv.refreshSurround(this.projectService.profileService);
				if (!vv.getNeib(ElementSide.Bottom)) {
					this.bottomProfile.rect.y = this.projectService.CurrentAreaService.getWorkAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y
				}
				y = this.bottomProfile.rect.y - h;
			}
			y = Common.round(y, 1);
			rect = {
				x,
				y,
				w: w * SvgParams.SCALE,
				h: Common.round(h)
			};
			this.bottomProfile.rect.h = Math.round(this.bottomProfile.rect.h);
		}

		var wings = this.createWings({ x: rect.x, y: rect.y, w: rect.w / SvgParams.SCALE, h: rect.h }, def);

		var cols = new Space(this.leftProfile, this.rightProfile, this.topProfile, this.bottomProfile);
		var d = new Door(this.projectService, def, this.area, cols, rect, wings, this.sideService);

		var defGlass = def.glasses.find(g => g.isDefault);
		if (!defGlass) {
			defGlass = def.glasses[0];
		}
		d.glassId = defGlass.id;

		if (pattern) {
			let pattDef: IDoorInfo;
			let exts: string[];
			if (pattern instanceof Door) {
				pattDef = pattern.def;
				exts = pattern.getExtras();
				if (d.def.doorType == pattern.def.doorType) {
					d.lockVariant = pattern.lockVariant;
				}
				if (this.rightProfile.type == ElementType.Frame) {
					d.rightProfile = pattern.rightProfile;
				}
			} else {
				pattDef = this.projectService.template.doors.find(p => p.id == pattern.configId);
				exts = pattern.extras;

				if (pattern.lockVariant) {
					d.lockVariant = this.getLockVariants(pattDef)?.find(l => l.id == pattern.lockVariant);
				}
				d.setLocks();
			}
			d.setExpansion(pattern.isExpandedTop, pattern.isExpandedBottom);
			if (d.def.glasses.find(l => l.id == pattern.glassId)) {
				d.glassId = pattern.glassId;
			}

			exts.forEach(e => {
				if (d.def.doorExtras.find(i => i == e)) {
					d.addExtra(e);
				}
			});

		} else {
			if (!this.projectService.profileService.findVerticalNeib(d, ElementSide.Bottom) == null) {
				d.isExpandedBottom = true;
			}
		}

		if (!pattern) {
			d.calcTopFrame();
			d.calcBottomFrame();
		}
		return d;
	}

	private recreate(d: Door) {
		d.wings = this.createWings({ x: d.rect.x, y: d.rect.y, w: d.rect.w / SvgParams.SCALE, h: d.rect.h }, d.def);
		d.setLocks();
	}

	public setHeight(d: Door, v: number, emit: boolean = true) {
		if (d.height == v) {
			return;
		}

		if (v > d.maxHeight) {
			v = d.maxHeight;
		}
		if (d.height == d.maxHeight) {
			d.addExtra(d.def.foundationId);
		}

		d.rect.h = v * SvgParams.SCALE;
		d.calcBottomFrame();

		this.recreate(d);
		d.adjustSurround();
		if (emit) {
			this.projectService.emitChange(); 
		}
	}

	public setWidth(d: Door, v: number, emit: boolean = true) {
		const frameDef = this.projectService.template.hiddenExtras.find(h => h.id == d.def.connectorId);
		if (v == d.maxWidth) {
			if (d.rightProfile?.type == ElementType.Frame) {
				(d.rightProfile as FrameProfile).rect.w = 0;
				(d.rightProfile as FrameProfile).rect.x = d.rect.x;
			}
		} else {
			if (d.rightProfile.type == ElementType.Column) {
				var rightFrame = new FrameProfile(SvgParams.START_X + v * SvgParams.SCALE, d.rect.y, d.height, frameDef.size, frameDef.color, Orientation.Vertical, d.def.connectorId);
				d.rightProfile = rightFrame;
				this.calcSideFrame(d, d.rightProfile as FrameProfile);
			} else {
				var rf = d.rightProfile as FrameProfile;
				rf.rect.x = d.leftX + v * SvgParams.SCALE; // - frameDef.size.width * SvgParams.SCALE;
				rf.rect.w = frameDef.size.width * SvgParams.SCALE;
				this.calcSideFrame(d, rf);
			}
		}

		d.rect.w = v * SvgParams.SCALE;
		this.recreate(d);
		d.adjustSurround();
		if (emit) {
			this.projectService.emitChange(); 
		}
	}

	public setLocationX(d: Door, v: number, emit: boolean = true) {
		const frameDef = this.projectService.template.hiddenExtras.find(h => h.id == d.def.connectorId);
		if (v == d.minLocationX) {
			if (d.leftProfile?.type == ElementType.Frame) {
				(d.leftProfile as FrameProfile).rect.w = 0;
				(d.leftProfile as FrameProfile).rect.x = d.rect.x;
			}
		} else {
			if (d.leftProfile.type == ElementType.Column) {
				var leftFrame = new FrameProfile(SvgParams.START_X + v * SvgParams.SCALE, d.rect.y, d.height, frameDef.size, frameDef.color, Orientation.Vertical, d.def.connectorId);
				d.leftProfile = leftFrame;
				this.calcSideFrame(d, d.leftProfile as FrameProfile);
			} else {
				var lf = d.leftProfile as FrameProfile;
				lf.rect.x = SvgParams.START_X + v * SvgParams.SCALE - frameDef.size.width * SvgParams.SCALE;
				lf.rect.w = frameDef.size.width * SvgParams.SCALE;
				this.calcSideFrame(d, lf);
			}
		}
		d.rect.x = SvgParams.START_X + v * SvgParams.SCALE;

		var rf = d.rightProfile as FrameProfile;
		rf.rect.x = d.rect.x + d.width * SvgParams.SCALE; // - frameDef.size.width * SvgParams.SCALE;
		this.calcSideFrame(d, rf);

		this.recreate(d);
		d.adjustSurround();
		if (emit) {
			this.projectService.emitChange(); 
		}
	}

	public setLocationY(d: Door, v: number, emit: boolean = true) {
		if(d.locationY == v) {
			return;
		}
		if (v > d.maxLocationY || v < d.minLocationY) {
			return;
		}

		d.rect.y = (v * SvgParams.SCALE) + SvgParams.START_Y;
		d.calcTopFrame();
		d.calcBottomFrame();
		if (d.rightProfile?.type == ElementType.Frame) {
			this.calcSideFrame(d, d.rightProfile as FrameProfile);
		}
		if (d.leftProfile?.type == ElementType.Frame) {
			this.calcSideFrame(d, d.leftProfile as FrameProfile);
		}
		this.recreate(d);
		d.adjustSurround();
		if (emit) {
			this.projectService.emitChange(); 
		}
	}

	private calcSideFrame(d: Door, f: FrameProfile) {
		const low = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - SvgParams.START_Y;

		f.rect.y = d.rect.y;
		if (d.isExpandedTop) {
			f.rect.y += DoorParams.EXPANSION_TOP * SvgParams.SCALE;
		}

		f.rect.h = low - (f.rect.y - SvgParams.START_Y);
		
	}
}
