import { GlassPart } from "./glassPart.model";

export class GlassWallElemsToPrint {

	constructor(
		public GlassWall: any[] = [],
		public GlassWallDiamond: any[] = [],
		public GlassPart: GlassPart[] = [],
		public GlassPartDiamond: any[] = [],
		public Muntin: any[] = [],
	) {
	}
}
