import { AreaType } from "../../interfaces/IAreaType";
import { ElementSide } from "../../interfaces/IElementSide";
import { ProjectService } from "../../services/project.service";
import { ColumnProfile } from "../profiles/column.model";
import { ElementStoreModel } from "./element-store.model";

export class ColumnStoreModel extends ElementStoreModel {
	length: number;
	installation: string;
	isRear: boolean;
	isFront: boolean;
	connector: boolean;
	leftProfileId: string;
	rightProfileId: string;
	outflow: ElementSide;
	extras: string[];

	constructor(c: ColumnProfile) {
		super(c.id, c.configId, c.tag, c.cuttingOnSite);

		this.length = Math.round(c.getLength());
		this.installation = c.foundation?.id;
		this.isFront = c.isFront;
		this.isRear = c.isRear;
		this.connector = c.connector;
		this.leftProfileId = c.leftProfile?.id;
		this.rightProfileId = c.rightProfile?.id;
		this.outflow = c.outflow;
		this.extras = c.getExtras();

		this.storeLocationAndSize(c);
	}

	public static restore(c: ColumnStoreModel, projectService: ProjectService) {
		var col = new ColumnProfile(AreaType.Roof, c.rectOnRoof.x, c.rectOnRoof.y, c.length, AreaType.Roof, c.configId, projectService.template);
		col.id = c.id;

		if (c.installation != null) {
			if (c.installation == "OnHolders") {
				col.foundation = projectService.template.getOnHoldersFoundation(c.configId);
			} else if (c.installation == "InGround") {
				col.foundation = projectService.template.getDefaultFoundationForColumn(c.configId);
			} else {				
				col.foundation = projectService.template.hiddenExtras.find(f => f.id == c.installation);
			}
		}

		if (col.foundation == null) {
			col.foundation = projectService.template.getDefaultFoundationForColumn(c.configId);
		}
	
		col.tag = c.tag;
		col.isFront = c.isFront
		col.isRear = c.isRear;
		col.connector = c.connector;
		col.cuttingOnSite = c.cuttingOnSite;
		col.isWinkel = projectService.template.columns.find(t => t.id == c.configId).size.height > 0;
		if (c.outflow == null || c.outflow == "") {
			col.outflow = ElementSide.None;
		} else {
			col.outflow = c.outflow;
		}
		if (c.extras) {
			c.extras.forEach(e => { col.addExtra(e); });
		}
		ElementStoreModel.restoreLocationAndSize(c, col);

		return col;
	}

}
