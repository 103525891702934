import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { AreaType } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from "../../template";
import { ColumnProfile } from "./column.model";
import { Common } from "../common";
import { WallGutterProfile } from "./wall-gutter.model";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class WallProfile extends Profile {

  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  get type() {
    return ElementType.WallProfile;
  }

  get name(): string {
    return $localize`:Common|Rear:Rear`;
  }

  private _weight: number;
  get weight(){
    return this._weight;
  }

  public columns: ColumnProfile[];
  public minLength: number;
  public maxLength: number;

  public leftRear: WallProfile;
  public rightRear: WallProfile;

  public reinforcementHeight: number;

  public wallGutters: WallGutterProfile[];

  public setLength(value: number): void {
    if (value < 0) {
      return;
    }
    super.setLength(value);
    this.initRect(AreaType.Roof, this.rectOnRoof.x, this.rectOnRoof.y, value * SvgParams.SCALE);
  }

  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    template: ProjectTemplate,
    length: number,
    area: AreaType,
    configId: string
  ) {

    const wall = template.rears.find(f => f.id == configId);

    super(Orientation.Horizontal, wall.size, wall.color);

    this.reinforcementHeight = wall.reinforcementHeight;
    
    this.id = this.type + "_" + uuidv4();
    this.configId = configId;
    this.columns = [];
    this.wallGutters = [];
    this.length = length;
    this._weight = wall.weight;

    this.minLength = wall.minLength;
    this.maxLength = wall.maxLength;


    if (area === AreaType.None) {
      area = currentArea;
    }

    this.initRect(area, x, y, this.getLength() * SvgParams.SCALE)

  }

  private initRect(area: AreaType, x: number, y: number, l: number) {
    switch (area) {
      case AreaType.Rear:
        this.rectOnRear = {
          x: x,
          y: y,
          w: Common.round(l),
          h: Common.round(this.height * SvgParams.SCALE),
        };
        break;
      case AreaType.Roof:
        this.rectOnRoof = {
          x: x,
          y: y,
          w: Common.round(l),
          h: Common.round(this.depth * SvgParams.SCALE),
        };
        break;
      case AreaType.Front:
        this.rectOnFront = {
          x: x,
          y: y,
          w: Common.round(l),
          h: Common.round(this.height * SvgParams.SCALE),
        };
        break;
      case AreaType.Left:
        this.rectOnLeft = {
          x: x,
          y: y,
          w: Common.round(this.depth * SvgParams.SCALE),
          h: Common.round(this.height * SvgParams.SCALE)
        };
        break;
      case AreaType.Right:
        this.rectOnRight = {
          x: x,
          y: y,
          w: Common.round(this.depth * SvgParams.SCALE),
          h: Common.round(this.height * SvgParams.SCALE)
        };
        break;
    }
  }
}
