<div *ngIf="showComments || showRemote">

	<!-- <div class="row" *ngIf="item.type == 'Column'">
		<div class="col col-4"><span i18n="Common|Column type">Column type</span>:</div>
		<div class="col col-8">
			<kendo-dropdownlist style="width: 100%" [data]="getColumns(current)"
				textField="name" valueField="id" [(ngModel)]="columnConfigId" [valuePrimitive]="true"
				[disabled]="projectService.readOnly || checkVerticalElements()">
			</kendo-dropdownlist>
		</div>
	</div> -->
	<div *ngIf="showComments">
		<div class="row">
			<!-- Kommentare -->
			<div class="col col-12"><span i18n="Common|Comment">Comment</span>:</div>
		</div>
		<div class="row">
			<div class="col col-12">
				<kendo-textarea [rows]="6" resizable="vertical" [disabled]="projectService.readOnly" [maxlength]="300"
					[(ngModel)]="comment"></kendo-textarea>
			</div>
		</div>
		<div class="row">
			<div class="col col-4">
				<button style="width: 100%;" kendoButton (click)="saveComment()" primary="true"
					[disabled]="projectService.readOnly || (comment && comment == current.comment)"
					(blur)="blurComment()"
					i18n="Common|'Save' button">Save</button>
			</div>
		</div>
	</div>

	<br>

	<div class="row" *ngIf="showRemote" style="margin-top: 10px;">
		<div class="col col-4" style="margin-top: 5px;"><span i18n="Common|Remote">Remote</span>:</div>
		<div class="col col-8">
			<kendo-dropdownlist style="width: 90%" [data]="variants" textField="name" valueField="id"
				[listHeight]="400" [itemDisabled]="variantsDisabled" [valuePrimitive]="true"
				[(ngModel)]="remoteId">
			</kendo-dropdownlist>
			<button kendoButton style="width: 10%" *ngIf="projectService.remote" (click)="isConfirmDialogOpened = true" [svgIcon]="projectService.svgIcons.xIcon"></button>
			<button kendoButton style="width: 10%" *ngIf="!projectService.remote" (click)="addRemote()" [svgIcon]="projectService.svgIcons.plusIcon"></button>
		</div>
	</div>

	<app-confirm-dialog
		[dialogOpened]="isConfirmDialogOpened"
		[dialogMessage]="confirmDialogMessage"
		(dialogAction)="onConfirmDialogAction($event)">
	</app-confirm-dialog>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Channels:</div>
		<div class="col col-8">{{ channels }}</div>
	</div>
</div>
