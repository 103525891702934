<div class="row">
	<div class="col col-4"><span i18n="Wall|Wall type">Wall type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="getWallTypeData()"
			textField="name" valueField="id" [(ngModel)]="currentConfigId" [valuePrimitive]="true"
			[disabled]="projectService.readOnly">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row">
	<div class="col col-4">
		<span i18n="Wall|Panel layout">Panel layout</span>:
	</div>
	<div class="col col-8">
		<kendo-buttongroup selection="single" kendoTooltip>
			<button kendoButton [svgIcon]="projectService.svgIcons.alignLeftIcon" [toggleable]="true"
				[selected]="current.leftovers == 'Left'" (click)="changeLeftovers('Left')"
				i18n-title="Wall|Panel layout left:To the left" title="To the left"
				[attr.style]="current.def.vertical ? '': 'transform: rotate(90deg);'">
			</button>
			<button kendoButton [svgIcon]="projectService.svgIcons.alignJustifyIcon" [toggleable]="true"
				[selected]="current.leftovers == 'LeftRight'" (click)="changeLeftovers('LeftRight')"
				i18n-title="Wall|Panel layout justify:Justify" title="Justify"
				[attr.style]="current.def.vertical ? '': 'transform: rotate(90deg);'">
			</button>
			<button kendoButton [svgIcon]="projectService.svgIcons.alignRightIcon" [toggleable]="true"
				[selected]="current.leftovers == 'Right'" (click)="changeLeftovers('Right')"
				i18n-title="Wall|Panel layout right:To the right" title="To the right"
				[attr.style]="current.def.vertical ? '': 'transform: rotate(90deg);'">
			</button>
		</kendo-buttongroup><br>
		<span *ngFor="let l of current.getLayout()">
			{{ l.count }} * {{ l.size | number:'1.2-2' : currLng }} mm;
		</span>
	</div>
</div>

<div class="row" style="padding-top: 10px;">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numWidth [value]="current.width" [disabled]="projectService.readOnly || dimChangeDisabled"
			[max]="current.maxWidth" [min]="current.def.minWidth">
		</app-numeric-textbox>&nbsp;mm			
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Height">Height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [value]="current.height" [disabled]="projectService.readOnly"
			[min]="current.minHeight" [max]="current.maxHeight"></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numLocX [value]="current.locationX" [disabled]="projectService.readOnly || dimChangeDisabled"
			[min]="minLocX" [max]="maxLocX"></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="current.locationY" [disabled]="projectService.readOnly || current.shape.isDiamond"
			[min]="minLocY" [max]="maxLocY"></app-numeric-textbox>&nbsp;mm
	</div>
</div>


<div class="row">
	<div class="col col-4"><span i18n="Common|Surface area">Surface area</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="current.surfaceArea" [disabled]="true" [decimals]="2"
			[format]="'n2'"></app-numeric-textbox> m<sup>2</sup>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly"
			i18n="Common|Delete">Delete</button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{ current.def.minWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{ current.maxWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min loc. X:</div>
		<div class="col col-8">{{ current.minLocationX | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max loc. X:</div>
		<div class="col col-8">{{ current.maxLocationX | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ current.minHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ current.maxHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min loc. Y:</div>
		<div class="col col-8">{{ current.minLocationY | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max loc. Y:</div>
		<div class="col col-8">{{ current.maxLocationY | number:'1.2-2' : currLng }} mm</div>
	</div>
</div>