import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MarquiseVertical } from 'src/app/_core/models/marquises/marquise-vertical.model';
import { IAreaCanvas } from 'src/app/_core/interfaces/IAreaService';
import { ToolboxItemType } from 'src/app/_core/models/ToolboxModel';
import { ToolboxItemService } from 'src/app/_core/services/toolbox-item.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { Montage } from 'src/app/_core/interfaces/IElementSide';
import { StylingService } from 'src/app/_core/services/styling.service';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';

@Component({
	selector: 'g[app-marquise-vert]',
	templateUrl: './marquise-vertical.component.html',
	styleUrls: ['./marquise-vertical.component.css']
})
export class MarquiseVerticalComponent {
	@Input() selectedItems: any[];
	@Input() areaService: IAreaCanvas;
	@Input() nonSelectable: boolean;
	@Input() montage: Montage;
	@Input() printMode: boolean;
	@Output() selectMarquiseEvent = new EventEmitter<MarquiseVertical>();

	constructor(private toolBSrvc: ToolboxItemService,
		private projectService: ProjectService,
		public styling: StylingService) {
	}

	selectMarquise(ptr: MouseEvent, mq: MarquiseVertical) {
		const tool = this.toolBSrvc.getCurrTool();
		switch (tool?.type) {
			case ToolboxItemType.Door:
				if (mq.montage == Montage.Middle) {
					const message = $localize`:Door|Validation message:Cannot place a door on a marquise!`;
					this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
				} else {
					this.selectMarquiseEvent.emit(mq);
				}
				break;
			case ToolboxItemType.GlassWall:
				if (mq.montage == Montage.Middle) {
					const message = $localize`:Glass wall|Validation message:Cannot place glass wall on a marquise!`;
					this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
				} else {
					this.projectService.createGlassWall(ptr, tool);
					this.projectService.recalculateDimensions();
					this.projectService.emitChange();
				}
				break;
			default:
				this.selectMarquiseEvent.emit(mq);		
		}
		this.toolBSrvc.setCurrTool(null);
	}

	getMarquises() {
		const mqs = this.projectService.profileService.getMarquisesVertical()
			.filter((m: MarquiseVertical) => m[this.areaService.rectOnSide] && (!this.montage || m.montage == this.montage))
			.sort((m1: MarquiseVertical, m2: MarquiseVertical) => m1.area == this.areaService.areaType ? 1 : -1);
		
		return mqs.filter((m: MarquiseVertical) => 
			(this.styling.showLayer(LayerLevel.Inside) && m.montage == Montage.Inside) ||
			(this.styling.showLayer(LayerLevel.Middle) && m.montage == Montage.Middle) ||
			(this.styling.showLayer(LayerLevel.Outside) && m.montage == Montage.Outside)
		);
	}
}
