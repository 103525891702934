<div>
	<kendo-dialog
		[title]="dialogTitle"
		*ngIf="isDialogOpened"
		(close)="close(false)"
		[width]="850"
		[maxHeight]="1100">
		
		<kendo-expansionpanel *ngFor="let v of getVariants(); index as i" [expanded]="v == doorVariant" (action)="onPanelAction(i)">
			<ng-template kendoExpansionPanelTitleDirective>
				<div class="header-content">
					<div class="row">
						<div class="col-8">
							<img [attr.class]="'door-' + v.doorType" [attr.src]="getDoorImage(v)" alt="Door variant"
								[ngClass]="[current == v ? 'highlight' : '']" />
						</div>
						<div [attr.class]="'col-4 desc-' + v.doorType">
							<span>{{ v.name }}</span>
						</div>
					</div>
				</div>
			</ng-template>
			<div class="row variants-header">
				<div class="col-12">
					<span i18n="Door|Door variant:Please select handles and locks">Please select handles and locks</span>
				</div>
			</div>
			<div class="row variants">
				<div class="col-6 img" *ngFor="let lv of getLockVariants(v, 1)">
					<img class="lock" [attr.src]="getLockImage(v, lv)" (click)="select(v, lv)" alt="Lock variant"
						[ngClass]="[doorVariant == v && lockVariant == lv ? 'highlight' : '']" />
				</div>
			</div>
			<div class="row variants">
				<div class="col-6 img" *ngFor="let lv of getLockVariants(v, 2)">
					<img class="lock" [attr.src]="getLockImage(v, lv)" (click)="select(v, lv)" alt="Lock variant"
						[ngClass]="[doorVariant == v && lockVariant == lv ? 'highlight' : '']" />
				</div>
			</div>
		</kendo-expansionpanel>

		<kendo-dialog-actions>
			<button kendoButton (click)="close(false)" i18n="Dialogs|'Cancel' button">Cancel</button>
            <button kendoButton (click)="close(true)" primary="true" i18n="Dialogs|'Confirm' button" [disabled]="lockVariant == null">Confirm</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>
