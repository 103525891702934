import { WallGutterProfile } from "../profiles/wall-gutter.model";
import { ElementStoreModel } from "./element-store.model";

export class WallGutterStoreModel extends ElementStoreModel {
	rearId: string;

	constructor(c: WallGutterProfile) {
		super(c.id, c.configId, c.tag, c.cuttingOnSite);
		this.rearId = c.rear.id;
		this.storeLocationAndSize(c);
	}
}