import { ICircle } from '../../interfaces/ICircle';
import { IRect } from '../../interfaces/IRect';
import { ElementType } from '../ToolboxModel';
import { WizardParameter, RoofWindowParameter } from '../wizard/wizard-parameter.model';
import { RoofWindow } from './roofWindow.model';

export class RoofGutter extends RoofWindow {
	
	get type() {
		return  ElementType.RoofGutter;
	};

	get name() {
		return $localize`:Common|Roof gutter:Roof gutter`;
	};
	
	get circle(): ICircle {
		return { r: Math.round((this.rect.h / 4)) , x: this.rect.x + this.rect.w / 2, y: this.rect.y + this.rect.w / 2 };
	}

	constructor(x: number, y: number, w: number, rwParams: RoofWindowParameter, connector: WizardParameter) {
		super(x, y, w, rwParams, connector);

	}

}
