import { SvgParams } from "../constants/constants";
import { IAreaService } from "../interfaces/IAreaService";
import { AreaType, LocationArea } from "../interfaces/IAreaType";
import { IElement } from "../interfaces/IElement";
import { IPoint } from "../interfaces/IPoint";
import { IRect } from "../interfaces/IRect";
import { ProjectService } from "../services/project.service";
import { ElementType } from "./ToolboxModel";
import { MarquiseTop } from "./marquises/marquise-top.model";

export class Common {

	static isInLocation(locations: string[], currentArea: AreaType) {
		var found = false;
		locations.every(l => {
			const area = LocationArea.get(l);
			if (area == currentArea) {
				found = true;
			}
			return !found;
		});
		return found;
	}	
	static round(arg: number, decimals: number = 2) {
		var factor = 1;
		for(var i = 0; i < decimals; i++) {
			factor *= 10;
		}
		return Math.round(arg * factor) / factor;
	}

	private static ctg(x: number) {
		return 1 / Math.tan(x);
	}

	static calculateDepth(roofDepth: number, angle: number) {
		const b = Common.round(roofDepth * Math.tan(this.toRadians(angle)));
		return Math.sqrt(Math.pow(roofDepth, 2) + Math.pow(b, 2));	
	}

	static calculateFlat(depth: number, angle: number) {
		return Common.round(depth * Math.cos(Common.toRadians(angle)));
	}

	static getYOnTriangle(height: number, xPos: number, angle: number): number {
		const aside = Math.round(xPos * Math.tan(this.toRadians(angle)));
		return height - aside;
	}

	static calculateDrop(y1: number, y2: number, x1: number, x2: number): number {
		const a = Math.abs(x2 - x1);
		const b = Math.abs(y2 - y1);

		const tan = b / a;

		return (180 * tan) / Math.PI;
	}

	static calculateDroppedY(w: number, drop: number): number {
		return Common.round(Math.tan(Common.toRadians(drop)) * w);
	}

	static calculateDroppedWidth(width: number, drop: number): number {		
		return Common.round(width / Math.cos(Common.toRadians(drop)));
	}

	static calculateDroppedX(height: number, drop: number): number {
		return Common.round(Common.ctg(Common.toRadians(drop)) * height);
	}

	static calculatePartSurface(width: number, height: number, drop: number, padding: number): number {
		const y = Common.calculateDroppedY(width, drop);
		const h2 = height - y;
		return Common.calculateDiamondSurface(width, height, h2, padding);
	}

	static calculateDiamondSurface(width: number, height1: number, height2: number, padding: number = 0): number {
		const p = (padding * 2) / SvgParams.SCALE;
		const h1 = height1 - p;
		const h2 = height2 - p;
		const w = width - p;
		return Common.round((h1 + h2) * (w / 2));
	}

	static calculateGlassWallSurface(width: number, height: number, padding: number = 0): number {
		const p = (padding * 2) / SvgParams.SCALE;
		const h = height - p;
		const w = width - p;
		return Common.round(h * w);
	}

	static toRadians(angle: number) {
		return (Math.PI / 180) * angle;
	}

	private static _between(x: number, r: IRect, exact: boolean = true) {
		if (exact) {
			return x >= r.x && x <= r.x + r.w;
		} else {
			return x > r.x && x < r.x + r.w;
		}
	}

	static isConflict(r1: IRect, r2: IRect, exact: boolean = true) {
		if (this._between (r1.x, r2, exact)) {
			return true;
		}
		if (this._between(r1.x + r1.w, r2, exact)) {
			return true;
		}
		if (this._between(r2.x, r1, exact)) {
			return true;
		}
		if (this._between(r2.x + r2.w, r1, exact)) {
			return true;
		}

		return false;
	}

	static toPoints(rect: IRect): IPoint[] {
		if (!rect) {
			return null;
		}
		return [
			{ x: rect.x, y: rect.y },
			{ x: rect.x + rect.w, y: rect.y },
			{ x: rect.x + rect.w, y: rect.y + rect.h },
			{ x: rect.x, y: rect.y + rect.h },
		];		
	}

	static getServices(services: IAreaService[]): Map<AreaType, IAreaService> {
		var srvs = new Map<AreaType, IAreaService>();
		
		services.forEach(s => {
			srvs.set(s.areaType, s);
		})

		return srvs;
	}

	static channelsCount(projectService: ProjectService, element: IElement) {
		if (element == null) {
			return 0;
		}

		switch (element.type) {
			case ElementType.MarquiseTopBottom:
			case ElementType.MarquiseTopUp:
			case ElementType.MarquiseVertical:
				const m = element as MarquiseTop;
				return m.marquiseInfo.channels;
			}

		if (element.type == ElementType.Led) {
			return projectService.ledPattern.channels;
		}

		var chn = element["channels"] as number;
		if (chn != null) {
			return chn;
		}

		return 0;
	}
}
