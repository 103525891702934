import { Injectable } from '@angular/core';
import { Tags, SvgParams, ROOF_WINDOW_DISTANCE } from 'src/app/_core/constants/constants';
import { ProjectService } from 'src/app/_core/services/project.service';
import { Orientation, ElementType } from 'src/app/_core/models/ToolboxModel';
import { Profile } from '../models/profiles/profile.model';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { ProfileService } from './profile.service';
import { ColumnProfile } from '../models/profiles/column.model';
import { AreaType, CollectionName, RectName } from 'src/app/_core/interfaces/IAreaType';
import { BarProfile } from '../models/profiles/bar.model';
import { FrontProfile } from '../models/profiles/front.model';
import { MuntinProfile } from '../models/profiles/muntin.model';
import { SideBaseService } from './areas/side-base.service';
import { RoofWindow } from '../models/glasses/roofWindow.model';
import { ProfileConnector } from '../models/profiles/connector.model';
import { ExtraOptionService } from './extra-option.service';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { FrontService } from './areas/front.service';
import { Common } from '../models/common';
import { IRect } from '../interfaces/IRect';
import { WallMuntinProfile } from '../models/walls/wall-muntin.model';
import { WallGutterProfile } from '../models/profiles/wall-gutter.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
	private projectService: ProjectService,
	private profileService: ProfileService,
	private frontService: FrontService,
	private extraService: ExtraOptionService) { }


	public getLocation(profile: Profile, area: AreaType = AreaType.None) : number {

		let currArRect: string;
		if (!area || area == AreaType.None) {
			currArRect = this.projectService.getCurrentAreaRect();
		} else {
			currArRect = 'rectOn' + area;
		}

	  switch (profile.type) {
		  case ElementType.Frame:
		  case ElementType.Muntin:
			  const p = profile as MuntinProfile;
			  return Math.round((p.rect.x - SvgParams.START_X) / SvgParams.SCALE);
			case ElementType.SideFinish:
					return profile["lineOnRoof"] == null ? 0 : Math.round((profile["lineOnRoof"].x1 - 3 - SvgParams.START_X) / SvgParams.SCALE); // why - 3 is needed?
			case ElementType.Bar:
			case ElementType.ProfileConnector:
				if (profile.orientation == Orientation.Vertical) {
					return profile[currArRect] == null ? 0 : Math.round((profile[currArRect].x - SvgParams.START_X) / SvgParams.SCALE);
				} else {
					return profile[currArRect] == null ? 0 : Math.round((profile[currArRect].y - SvgParams.START_Y) / SvgParams.SCALE);
				}
			default:
				return profile[currArRect] == null ? 0 : Math.round((profile[currArRect].x - SvgParams.START_X) / SvgParams.SCALE);
		}
	}

	public setLocation(profile: Profile, value: number, area: AreaType = AreaType.None, check: boolean) {

		switch (profile.type) {
			case ElementType.Bar:
			case ElementType.ProfileConnector:
				if (profile.orientation == Orientation.Vertical) {
					this.setVerticalLocation(profile as BarProfile, value);
				} else {
					this.setHorizontalLocation(profile as ProfileConnector, value);
				}
				break;
			case ElementType.Frame:
				if (profile instanceof WallMuntinProfile) {
					profile.setLocation(value);
				}
				break;
			case ElementType.Muntin:
				this.adjustMuntins(profile as MuntinProfile, value);
				break;
			case ElementType.Column:
				const wgprev = profile.rectOnRoof.x;

				if (this.projectService.currentArea == AreaType.Rear) {
					value = this.projectService.template.width - value;
				}

				var newRect: IRect = {
					x: SvgParams.START_X + value * SvgParams.SCALE,
					y: profile.rectOnRoof.y,
					w: profile.rectOnRoof.w,
					h: profile.rectOnRoof.h
				}

				const wg: WallGutterProfile = this.profileService.roofElements[ElementType.WallGutter].find((c: WallGutterProfile) => Common.isConflict(c.rectOnRoof, newRect, false));
				if (wg) {
					if (wgprev > newRect.x) {
						if (wg.rectOnRoof.x - newRect.w >= SvgParams.START_X) {
							newRect.x = wg.rectOnRoof.x - profile.rectOnRoof.w;
						}
					} else {
						const r = ((wg.rectOnRoof.x - SvgParams.START_X) + wg.rectOnRoof.w) / SvgParams.SCALE;
						if (r >= this.projectService.template.width) {
							newRect.x = wg.rectOnRoof.x - newRect.w;
						} else {
							newRect.x = wg.rectOnRoof.x + wg.rectOnRoof.w;
						}
					}
				}

				value = (newRect.x - SvgParams.START_X) / SvgParams.SCALE;
				if (this.projectService.currentArea == AreaType.Rear) {
					value = this.projectService.template.width - value - newRect.w / SvgParams.SCALE;
				}

				if (check)
					this.setColumnXLocationWithCheck(profile as ColumnProfile, value, area);
				else
					this.setColumnXLocation(profile as ColumnProfile, value, area);
				break;
			case ElementType.WallGutter:
				const prev = profile.rectOnRoof.x;
				profile.rectOnRoof.x = SvgParams.START_X + value * SvgParams.SCALE;
				const col: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((c: ColumnProfile) => c.isRear && Common.isConflict(c.rectOnRoof, profile.rectOnRoof, false));
				if (col) {
					if (prev > profile.rectOnRoof.x) {
						if (col.rectOnRoof.x - profile.rectOnRoof.w >= SvgParams.START_X) {
							profile.rectOnRoof.x = col.rectOnRoof.x - profile.rectOnRoof.w;
						} else {
							profile.rectOnRoof.x = col.rectOnRoof.x + col.rectOnRoof.w;
						}
					} else {
						const r = ((col.rectOnRoof.x - SvgParams.START_X) + col.rectOnRoof.w) / SvgParams.SCALE;
						if (r >= this.projectService.template.width) {
							profile.rectOnRoof.x = col.rectOnRoof.x - profile.rectOnRoof.w;
						} else {
							profile.rectOnRoof.x = col.rectOnRoof.x + col.rectOnRoof.w;
						}
					}
				}
				break;
		}
	}

	private adjustMuntins(profile: MuntinProfile | WallMuntinProfile, value) {
		if (profile instanceof WallMuntinProfile) {
			profile.leftPart.calculatePanels
		} else {
			switch (profile.leftPart.type) {
				case ElementType.GlassPart:
					this.projectService.adjustMuntins(profile as MuntinProfile, value)
					break;

				case ElementType.GlassPartDiamond:
					(this.projectService.CurrentAreaService as SideBaseService).adjustMuntins(profile as MuntinProfile, value)
					break;
			}
		}
	}

	private adjustFront(profile: ColumnProfile, widthDiff: number, xDiff: number, currArRect: string, currentFront: string, setLength: boolean) {
		if (profile[currentFront] && profile[currentFront][currArRect]) {
			if (setLength) {
				const l = profile[currentFront].getLength();
				profile[currentFront].setLength(l + (widthDiff / SvgParams.SCALE));
			}

			profile[currentFront][currArRect].w = profile[currentFront].getLength() * SvgParams.SCALE;
			profile[currentFront][currArRect].x += xDiff;
		}
	}

	private setColumnXLocation(profile: ColumnProfile, value: number, area: AreaType = AreaType.None) {
		let currArRect: string;
		let collName: string;
		if (!area || area == AreaType.None) {
			currArRect = this.projectService.getCurrentAreaRect();
			collName = CollectionName.get(this.projectService.currentArea);
		} else {
			currArRect = RectName.get(area);
			collName = CollectionName.get(area);
		}
		var prev = profile[currArRect].x;
		var newRect: IRect = { x: SvgParams.START_X + value * SvgParams.SCALE, y: profile[currArRect].y, w: profile[currArRect].w, h: profile[currArRect].h };
		var diff = (newRect.x - prev);

		const cols = this.profileService.getSortedColumns(collName, profile.isRear ? AreaType.Rear : AreaType.Front);
		const currCol = cols.findIndex(c => c.id == profile.id);
		const checkCol = cols[diff > 0 ? currCol + 1: currCol - 1];

		if (checkCol && Common.isConflict(newRect, checkCol[currArRect])) {
			this.projectService.errorSubject.next($localize`:Column|Validation message:Column can't be moved`);
			return;
		}

		profile[currArRect].x = SvgParams.START_X + value * SvgParams.SCALE;

		if (this.projectService.currentArea === AreaType.Roof && profile.rectOnFront) {
			profile.rectOnFront.x = SvgParams.START_X + value * SvgParams.SCALE;
		}
		else if (this.projectService.currentArea === AreaType.Front && profile.rectOnRoof) {
			profile.rectOnRoof.x = SvgParams.START_X + value * SvgParams.SCALE;
		}
		else if (this.projectService.currentArea === AreaType.Rear && profile.rectOnRoof) {
			var fromRight = profile.rectOnRear.x - SvgParams.START_X;
			var x = SvgParams.START_X + this.projectService.template.width * SvgParams.SCALE - fromRight - profile.rectOnRoof.w;
			profile.rectOnRoof.x = x;
		}
		
		this.profileService.getMarquisesVertical().forEach((m: MarquiseVertical) => {
			m.adjust();
		});

		if (!profile.connector) {
			return;
		}

		this.adjustFront(profile, diff, 0, "rectOnRoof", "leftProfile", true);
		this.adjustFront(profile, diff, 0, "rectOnFront", "leftProfile", false);
		this.adjustFront(profile, diff, 0, "rectOnRear", "leftProfile", false);
		this.adjustFront(profile, -diff, diff, "rectOnRoof", "rightProfile", true);
		this.adjustFront(profile, -diff, diff, "rectOnFront", "rightProfile", false);
		this.adjustFront(profile, -diff, diff, "rectOnRear", "rightProfile", false);

	}

	private setColumnXLocationWithCheck(profile: ColumnProfile, value: number, area: AreaType = AreaType.None) {
		this.projectService.checkStatics(profile, value).subscribe(res => {
			if (res.ok) {
				if (profile.isFront && res.deflections.frontDeflection && this.profileService.roofElements[ElementType.Column].filter(c => c.isFront).length == 2) {
					this.projectService.template.deflections.frontDeflection = res.deflections.frontDeflection;
					this.projectService.template.deflections.snowZeroFrontDeflection = res.deflections.snowZeroFrontDeflection
				}
				this.setColumnXLocation(profile, value, area);
				this.projectService.recalculateDimensions();
			} else {
				this.projectService.errorSubject.next($localize`:Column|Validation message:Column can't be moved`);
			}
		});

	}

	public tuneColumnsLocation(isRear: boolean) {
		var orderedCols = this.profileService.getSortedColumns(CollectionName.get(AreaType.Roof), isRear ? AreaType.Rear : AreaType.Front);
		if (isRear) {
			orderedCols.reverse();
		}

		for (var i = orderedCols.length - 2; i >= 0; i--) {
			var current: Profile = orderedCols[i];
			const next: Profile = orderedCols[i + 1];

			if (current.rectOnRoof.x + current.rectOnRoof.w > next.rectOnRoof.x) {
				var c = this.profileService.roofElements[ElementType.Column].find(c => c.id == current.id);
				c.rectOnRoof.x = next.rectOnRoof.x - current.rectOnRoof.w;
				c.rectOnFront.x = c.rectOnRoof.x;
			}
		}

		this.profileService.getMarquisesVertical().forEach((m: MarquiseVertical) => {
			m.adjust();
		});
	}

	public getColumnYLocation(column: ColumnProfile) {
		const currArRect = this.projectService.getCurrentAreaRect();
		if (column[currArRect] == null) {
			return 0;
		}

		return (column[currArRect].y - SvgParams.START_Y) / SvgParams.SCALE;
	}

	public setColumnYLocation(column: ColumnProfile, value: number) {
		const colD = Math.round(column.depth * SvgParams.SCALE);
		const front = this.projectService.template.getFrontSize().height;
		const rh = this.projectService.template.getRearSize().height;
		var swHeight = this.projectService.template.getBarSize().height;

		column.rectOnRoof.y -= value;

		const xOnSide = column.rectOnRoof.y;
		const endColumnPositionDepthOnSide = (xOnSide + colD - SvgParams.START_X) / SvgParams.SCALE;
		const heightOfColumnPosition = this.projectService.getXPositionHeight(endColumnPositionDepthOnSide);
		const yOnSide = SvgParams.START_Y + (this.projectService.template.backHeight - heightOfColumnPosition + front + swHeight) * SvgParams.SCALE;

		column.setLength(heightOfColumnPosition + rh - front - swHeight);

		if (column.tag == Tags.LEFT_COLUMN_TAG) {
			this.projectService.setRectForColumnOnSide(column, xOnSide, front, endColumnPositionDepthOnSide, swHeight, 'rectOnLeft');
		} else if (column.tag == Tags.RIGHT_COLUMN_TAG) {
			const xOnSide1 = SvgParams.START_X + (this.projectService.template.depth * SvgParams.SCALE) - (column.rectOnRoof.y - SvgParams.START_Y) - colD;
			const endColumnPositionDepthOnSide1 = this.projectService.template.depth - (xOnSide1 - SvgParams.START_X) / SvgParams.SCALE;
			this.projectService.setRectForColumnOnSide(column, xOnSide1, front, endColumnPositionDepthOnSide1, swHeight, 'rectOnRight');
		} else {
		}

		column.rectOnFront.y = yOnSide;
		column.rectOnFront.h = Math.round(column.getLength() * SvgParams.SCALE);
	}

	public setFrontYLocation(front: FrontProfile, value: number) {
		const colD = Math.round(front.depth * SvgParams.SCALE);
		var swHeight = this.projectService.template.getBarSize().height;

		front.rectOnRoof.y -= value;
		front.rectOnFront.y -= value;

		let endColumnPositionDepthOnSide : number;

		const xOnSide1 = front.rectOnRoof.y;
		endColumnPositionDepthOnSide = (xOnSide1 + colD - SvgParams.START_X) / SvgParams.SCALE;
		if (endColumnPositionDepthOnSide == this.projectService.template.depth) {
			swHeight = 0;
		}
		this.projectService.setRectForFrontOnSide(front, xOnSide1, endColumnPositionDepthOnSide, swHeight, 'rectOnLeft');


		const xOnSide = SvgParams.START_X + (this.projectService.template.depth * SvgParams.SCALE) - (front.rectOnRoof.y - SvgParams.START_Y) - colD;
		endColumnPositionDepthOnSide = this.projectService.template.depth - (xOnSide - SvgParams.START_X) / SvgParams.SCALE;
		if (endColumnPositionDepthOnSide == this.projectService.template.depth) {
			swHeight = 0;
		}
		this.projectService.setRectForFrontOnSide(front, xOnSide, endColumnPositionDepthOnSide, swHeight, 'rectOnRight');

		front.rectOnFront.y = front.rectOnLeft.y;

		// this.frontService.addElementsAfterDisplayedRoof(this.profileService.roofElements);
	}

	private setHorLocWithGlass(bar: BarProfile, d: number) {
		const currArRect = this.projectService.getCurrentAreaRect();
		const barW = bar.getBarsDefaultWidth(this.projectService.template);

		bar[currArRect].y -= d;

		var tops = bar.getGlasses(ElementSide.Top);
		if (tops.length > 0) {
			tops.forEach(t => {
				t.rect.h -= d;
			});
		}

		var botts = bar.getGlasses(ElementSide.Bottom);
		if (botts.length > 0) {
			botts.forEach(b => {
				b.rect.y -= d
				b.rect.h += d
			});
		}
	}

	private setHorizontalLocation(profile: BarProfile, value: number) {
		const currArRect = this.projectService.getCurrentAreaRect();

		const oldLoc = (profile[currArRect].y - SvgParams.START_Y) / SvgParams.SCALE;
		var diff = oldLoc - value;
		if (diff == 0) {
			return false;
		}

		const d = diff * SvgParams.SCALE;

		var group = this.projectService.grouper.getGroupFor(profile.id);
		if (group == null) {
			this.setHorLocWithGlass(profile, d);
		} else {
			var bars = this.projectService.getCurrentAreaElements()[profile.type];
			group.forEach(g => {
				let bar: BarProfile = bars.find(b => b.id == g.id);
				this.setHorLocWithGlass(bar, d);
			});
		}

		this.extraService.addElevator();
		return true;
	}

	private setVerticalLocation(profile: ProfileConnector, value: number) {
		const currArRect = this.projectService.getCurrentAreaRect();

		const oldLoc = (profile[currArRect].x - SvgParams.START_X) / SvgParams.SCALE;
		var diff = oldLoc - value;
		if (diff == 0) {
			return false;
		}

		const d = diff * SvgParams.SCALE;
		if (d > 0 && !this.projectService.canMoveLeft(profile, -d)) {
			return false;
		}
		if (d < 0 && !this.projectService.canMoveRight(profile, -d)) {
			return false;
		}

		profile[currArRect].x -= d;
		if (this.projectService.currentArea === AreaType.Roof) {
			profile.rectOnFront.x -= d;
		}

		var adjustRight = true;
		if (d < 0)
			adjustRight = !this.projectService.deleteRightBar(profile);
		if (adjustRight)
			this.projectService.adjustRight(profile.leftGlass, diff);

		var adjustLeft = true;
		if (d > 0)
			adjustLeft = !this.projectService.deleteLeftBar(profile);
		if (adjustLeft)
			this.projectService.adjustLeft(profile.rightGlass, -diff);

		this.projectService.arrangeLeds();
		this.projectService.addBarsConnectors();
		return true;
	}

	public adjustRoofwindow(roofWindow: RoofWindow) {
		const rd = this.projectService.template.getDefaultRear().size.depth * SvgParams.SCALE;
		const frontD = this.projectService.template.getFrontSize().depth;
		const lowestGlassPoint = SvgParams.START_Y + (this.projectService.template.depth - frontD) * SvgParams.SCALE;

		if (roofWindow.rect.y < SvgParams.START_Y + rd + ROOF_WINDOW_DISTANCE) {
			roofWindow.rect.y = SvgParams.START_Y + rd;
			roofWindow.setBottomConnector();
		}
		else
			if (roofWindow.rect.y + roofWindow.height * SvgParams.SCALE > lowestGlassPoint - ROOF_WINDOW_DISTANCE) {
				roofWindow.rect.y = lowestGlassPoint - roofWindow.height * SvgParams.SCALE;
				roofWindow.setTopConnector();
			}
		else
			roofWindow.setBothConnectors();
	}
}
