import { SvgParams } from "../../constants/constants";
import { IAreaService } from "../../interfaces/IAreaService";
import { AreaType } from "../../interfaces/IAreaType";
import { DoorType } from "../../interfaces/IDoorInfo";
import { AnySide } from "../../interfaces/IElementSide";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { ElementType } from "../ToolboxModel";
import { DoorCreator } from "../doors/door-creator";
import { Door } from "../doors/door.model";
import { FrameProfile } from "../profiles/frame.model";
import { Profile } from "../profiles/profile.model";
import { Space } from "../space";
import { ISize } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";
import { FrameStoreModel } from "./frame-store.model";

export class DoorStoreModel extends ElementStoreModel {

	size: ISize;
	
	rect: IRect;
	glassId: string;
	lockId: string;
	extras: string[];
	wings: IRect[];
	area: AreaType;
	lockSide: AnySide;	
	doorType: DoorType;
	lockVariant: string;	

	topFrame: FrameStoreModel;
	bottomFrame: FrameStoreModel;
	leftProfile: FrameStoreModel;
	rightProfile: FrameStoreModel;
	isExpandedBottom: boolean;
	isExpandedTop: boolean;

	startHeight: number;
	startPosition: number;
	start: IRect;

	constructor(d: Door) {
		super(d.id, d.def.id);

		this.rect = d.rect;
		this.glassId = d.glassId;
		this.lockVariant = d.lockVariant.id;
		this.extras = d.getExtras();
		this.wings = d.wings;
		this.area = d.area;

		this.isExpandedBottom = d.isExpandedBottom;
		this.isExpandedTop = d.isExpandedTop;

		switch (d.area) {
			case AreaType.Front:
			case AreaType.Rear:
					this.size = { width: Math.round(d.rect.w / SvgParams.SCALE), height: Math.round(d.rect.h / SvgParams.SCALE), depth: undefined };
				break;
			case AreaType.Left:
			case AreaType.Right:
				this.size = { width: undefined, height: Math.round(d.rect.h / SvgParams.SCALE), depth: Math.round(d.rect.w / SvgParams.SCALE) };
				break;
		}

		this.topFrame = new FrameStoreModel(d.topProfile);
		this.bottomFrame = new FrameStoreModel(d.bottomProfile);

		if (d.leftProfile.type == ElementType.Frame) { // fake frame profile
			this.leftProfile = new FrameStoreModel(d.leftProfile as FrameProfile);
		} else { // column profile
			this.leftProfile = new FrameStoreModel(null);
			this.leftProfile.id = d.leftProfile.id;
			this.leftProfile.configId = d.leftProfile.configId;
		}

		if (d.rightProfile.type == ElementType.Frame) { // fake frame profile
			this.rightProfile = new FrameStoreModel(d.rightProfile as FrameProfile);
		} else { // column profile
			this.rightProfile = new FrameStoreModel(null);
			this.rightProfile.id = d.rightProfile.id;
			this.rightProfile.configId = d.rightProfile.configId;
		}

	}

	public static restore(d: DoorStoreModel, projectService: ProjectService, srv: IAreaService) {
		let topFrame: FrameProfile;
		
		if (d.topFrame != null) {
			topFrame = FrameStoreModel.restore(d.topFrame, projectService);
		}

		let bottomFrame: FrameProfile;

		if (d.bottomFrame != null) {
			bottomFrame = FrameStoreModel.restore(d.bottomFrame, projectService);
		} else {
			bottomFrame = FrameStoreModel.restore(d.topFrame, projectService);
			bottomFrame.rect.h = 0;
			bottomFrame.rect.y = d.rect.y + d.rect.h;
		}

		let leftFrame: Profile;
		let rightFrame: Profile;

		if (d.leftProfile.rect) { // Frame profile			
			leftFrame = FrameStoreModel.restore(d.leftProfile, projectService);
		} else { // column
			leftFrame = projectService.profileService.roofElements[ElementType.Column].find(c => c.id == d.leftProfile.id);
		}

		if (d.rightProfile.rect) { // Frame profile
			rightFrame = FrameStoreModel.restore(d.rightProfile, projectService);
		} else { // column
			rightFrame = projectService.profileService.roofElements[ElementType.Column].find(c => c.id == d.rightProfile.id);
		}

		var def = projectService.template.doors.find(r => r.id == d.configId);
		var creator = new DoorCreator(projectService, new Space(leftFrame, rightFrame, topFrame, bottomFrame), d.area, srv);
		
		if (!d.start && d.startHeight && d.startPosition) {
			d.start = { x: d.rect.x, y: d.startPosition, w: d.rect.w, h: d.startHeight };
		}
		if (!d.lockVariant && d.lockId && d.lockSide != AnySide.None) {
			var handle = projectService.template.hiddenExtras.find(h => h.id == d.lockId);
			var eg = "G";
			if (handle && handle.size.width > 60) {
				eg = "E";
			}
			var lvid = d.doorType == DoorType.SingleSide ? "S_" : "D_";
			switch(d.lockSide) {
				case AnySide.Left:
					lvid += eg + "NNNN";
					break;
				case AnySide.Right:
					lvid += "NNNN" + eg;
					break;
				case AnySide.Middle:
					lvid += "NNSNN";
					break;
				case AnySide.LeftRight:
					lvid += eg + "NNN" + eg;
					break;
			}
			d.lockVariant = lvid;
		}

		var door = creator.createDoor(def, d);
		projectService.profileService.addVertical(door, false);

	}

}