import { EventEmitter, Injectable } from '@angular/core';
import { IApiError, ICfgError } from '../interfaces/IErrors';
import { ProjectService } from './project.service';

@Injectable({providedIn: 'root'})
export class NotifyService {

	public apiException: EventEmitter<IApiError> = new EventEmitter<IApiError>();
	public cfgException: EventEmitter<ICfgError> = new EventEmitter<ICfgError>();

	constructor(public projectService: ProjectService) {}

}
