import { Injectable } from "@angular/core";
import { User } from "../models/user";

@Injectable({
	providedIn: 'root'
})
export class TokenStorage {
	public get token(): string | null {
		return window.localStorage.getItem("token");
	}

	public set token(value: string) {
		window.localStorage.setItem("token", value);
	}

	public get loginStarted(): boolean {
		return window.sessionStorage.getItem("loginStarted") == "true";
	}

	public set loginStarted(value: boolean) {
		window.sessionStorage.setItem("loginStarted", value ? "true" : "false");
	}

	public get user(): User | null {
		var name = window.sessionStorage.getItem("user:name");
		if (name) {
			let user = new User();
			user.name = name;
			user.eMail = window.sessionStorage.getItem("user:eMail");
			user.hasAccessToERPExport = window.sessionStorage.getItem("user:hasAccessToERPExport") == "true";
			user.canDisableStatics = window.sessionStorage.getItem("user:canDisableStatics") == "true";
			return user;
		} else {
			return null;
		}
	}

	public set user(user: User) {
		if (user) {
			window.sessionStorage.setItem("user:name", user.name);
			window.sessionStorage.setItem("user:eMail", user.eMail);
			window.sessionStorage.setItem("user:hasAccessToERPExport", user.hasAccessToERPExport ? "true" : "false");
			window.sessionStorage.setItem("user:canDisableStatics", user.canDisableStatics ? "true" : "false");
		} else {
			window.sessionStorage.removeItem("user:name");
			window.sessionStorage.removeItem("user:eMail");
			window.sessionStorage.removeItem("user:hasAccessToERPExport");
			window.sessionStorage.removeItem("user:canDisableStatics");
		}
	}

	public reset(): void {
		window.localStorage.clear();
		window.sessionStorage.clear();
	}
}
