import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { AreaType } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from '../../template';
import { BarProfile } from "./bar.model";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class ProfileConnector extends BarProfile {

  get type() {
    return ElementType.ProfileConnector;
  }

  get name(): string {
    return $localize`:Common|Connector:Profile connector`;
  }

  get layer(): LayerLevel {
    return LayerLevel.Middle;
  }


  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    template: ProjectTemplate,
    length: number,
    orientation: Orientation,
    area: AreaType,
    configId: string
  ) {
    super(currentArea, x, y, template, length, orientation, area, configId, ElementType.ProfileConnector);
    this.length = length;
  }


}
