<kendo-card class="minor" style="width: 100%">
	<kendo-card-body>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Height">Height - back</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox #numBack [value]="projectBackHeight"
					[min]="projectService.template.minBackHeight" [max]="projectService.template.maxBackHeight"
					[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Height - front">Height - front</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox #numFront [value]="projectService.template.frontHeight"
				[min]="projectService.template.minBackHeight - frontDiff" [max]="projectService.template.maxBackHeight - frontDiff"
				[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Width">Width</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox #numWidth [value]="projectWidth" [min]="projectService.template.minWidth"
					[max]="projectService.template.maxWidth"
					[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Depth">Depth</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox #numDepth [value]="projectDepth" [min]="projectService.template.minDepth"
					[max]="projectService.template.maxDepth"
					[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Project details|Drop angle">Drop angle</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox #numDrop [value]="projectDropAngle" [min]="projectService.template.minDropAngle"
					[max]="projectService.template.maxDropAngle" [step]="0.1" [decimals]="1" [format]="'n1'"
					[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox>&nbsp;&deg;
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Project details|Height (back - front)">Back - front</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="frontDiff" [disabled]="true"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Snow zone">Snow zone</span>:
			</div>
			<div class="col col-8">
				<kendo-dropdownlist [data]="snowZones" style="width:70px" [disabled]="true" [(ngModel)]="projectService.template.snowZone"></kendo-dropdownlist>
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span i18n="Common|Wind zone">Wind zone</span>:
			</div>
			<div class="col col-8">
				<kendo-dropdownlist [data]="windZones" style="width:70px" [disabled]="true" [(ngModel)]="projectService.template.windZone"></kendo-dropdownlist>
			</div>
		</div>

		<!-- <div class="row">
			<div class="col col-4"><span i18n="Common|Left elongment">Left elongment</span>:</div>
			<div class="col col-8">
				<app-numeric-textbox #numElongLeft [value]="projectElongLeft" [disabled]="projectService.getFreezeStatusOfProjProp()" [min]="0" [max]="6999"></app-numeric-textbox> mm
			</div>
		</div>

		<div class="row">
			<div class="col col-4"><span i18n="Common|Right elongment">Right elongment</span>:</div>
			<div class="col col-8">
				<app-numeric-textbox #numElongRight [value]="projectElongRight" [disabled]="projectService.getFreezeStatusOfProjProp()" [min]="0" [max]="6999"></app-numeric-textbox> mm
			</div>
		</div>
		-->
		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Form sides' label">Form sides</span>:</div>
			<div class="col col-8">
				<kendo-dropdownlist style="width: 100%" [data]="projectService.template.formSides" textField="name"
					valueField="id" [listHeight]="400" [(ngModel)]="formSideId" [valuePrimitive]="true"
					[disabled]="projectService.getFreezeStatusOfProjProp()">
				</kendo-dropdownlist>
			</div>
		</div>

		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Fastening type' label">Fastening type</span>:</div>
			<div class="col col-8">
				<kendo-dropdownlist style="width: 100%" [data]="projectService.template.fastenings" textField="name" valueField="id"
					[listHeight]="400"
					[(ngModel)]="fasteningId" [valuePrimitive]="true" [disabled]="projectService.getFreezeStatusOfProjProp()">
				</kendo-dropdownlist>
			</div>
		</div>

		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Rear type' label">Rear type</span>:</div>
			<div class="col col-8">
				<kendo-dropdownlist style="width: 100%" [data]="projectService.template.rears" textField="name" valueField="id"
					[listHeight]="400"
					[(ngModel)]="rearId" [valuePrimitive]="true" [disabled]="projectService.getFreezeStatusOfProjProp()">
				</kendo-dropdownlist>
			</div>
		</div>

		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Front type' label">Front type</span>:</div>
			<div class="col col-8">
				<kendo-dropdownlist style="width: 100%" [data]="projectService.template.fronts" textField="name" valueField="id"
					[listHeight]="400"
					[(ngModel)]="frontId" [valuePrimitive]="true" [disabled]="projectService.getFreezeStatusOfProjProp()">
				</kendo-dropdownlist>
			</div>
		</div>
		
		<div class="row" *ngIf="canDisableStatics">
			<div class="col col-4">
				<span>Use statics</span>:
			</div>
			<div class="col col-8">
				<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px; margin: 5px" [(ngModel)]="useStatics"
				[disabled]="projectService.getFreezeStatusOfProjProp()"/>
			</div>
		</div>

		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Bar type' label">Bar type</span>:</div>
			<div class="col col-2" style="padding-right: 1px;">
				<app-numeric-textbox #numBars [value]="projectBarsCount" [min]="projectService.template.staticBarsCount"
					[max]="projectService.template.staticBarsCount * 10" [step]="1" [style]="'width:100%; min-width: 70px'"
					[disabled]="projectService.getFreezeStatusOfProjProp()"></app-numeric-textbox>
			</div>
			<div class="col col-6" style="padding-left: 1px;">
				<table style="width: 100%;">
					<tr>
						<td>
						</td>
						<td style="width: 100%;"><kendo-dropdownlist style="width: 100%;" [data]="projectService.template.bars" textField="name" valueField="id" [(ngModel)]="barId"
							[valuePrimitive]="true" [disabled]="'true'">
						</kendo-dropdownlist></td>
					</tr>
				</table>
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<span>Weight</span>:
			</div>
			<div class="col col-8">
				<app-numeric-textbox [value]="projectService.projectWeight" [disabled]="true"></app-numeric-textbox> kg
			</div>
		</div>

		<app-color-palette [colorsPalette]="projectService.template.colors"></app-color-palette>

		<!-- freeze / unfreeze btn -->

		<div class="row">
			<div class="col col-4"><span i18n="Project details|'Justify' label">Justify</span>:</div>
			<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.justifyContentAroundIcon"
					(click)="justifyBars()" class="medium" [disabled]="projectService.readOnly">
					<span i18n="Project details|'Justify bars' label">Bars</span>
				</button>
				<button kendoButton [svgIcon]="projectService.svgIcons.justifyContentAroundIcon"
					(click)="justifyColumns()" class="medium" [disabled]="projectService.readOnly">
					<span i18n="Project details|'Justify columns' label">Columns</span>
				</button>
			</div>
		</div>

		<div class="row" *ngIf="projectService.getFreezeStatusOfProjProp(); else freeze">
			<div class="col col-4">
				<span [svgIcon]="projectService.svgIcons.xCircleIcon" i18n="Common|Frozen">Frozen</span>
			</div>
			<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.checkCircleIcon" (click)="setFreeze(false)" class="medium" [disabled]="projectService.readOnly" i18n="Common|Unfreeze">Unfreeze</button>
			</div>
		</div>

		<ng-template #freeze>
			<div class="row">
				<div class="col col-4">
					<span [svgIcon]="projectService.svgIcons.checkCircleIcon" i18n="Common|Unfrozen">Unfrozen</span>
				</div>
				<div class="col col-8">
					<button kendoButton [svgIcon]="projectService.svgIcons.xCircleIcon" (click)="setFreeze(true)" class="medium" i18n="Common|Freeze">Freeze</button>
				</div>
			</div>
		</ng-template>

	</kendo-card-body>
</kendo-card>

<kendo-card class="minor" style="width: 100%">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12" i18n="Common|Layers">Layers</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" disabled="true" checked="checked"/>
			&nbsp;<span i18n="Common|Construction layer">Construction</span><br>
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [(ngModel)]="layerInside" (change)="changeLayer()" />
			&nbsp;<span i18n="Common|Inside layer">Inside layer</span><br>
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [(ngModel)]="layerMiddle" (change)="changeLayer()" />
			&nbsp;<span i18n="Common|Middle layer">Middle layer</span><br>
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [(ngModel)]="layerOutside" (change)="changeLayer()" />
			&nbsp;<span i18n="Common|Outside layer">Outside layer</span><br>
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [(ngModel)]="layerDimensions" (change)="changeDimensions()" />
			&nbsp;<span i18n="Toolbar|'Dimensions' button tooltip">Dimensions</span>
	</kendo-card-body>
</kendo-card>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Channels:</div>
		<div class="col col-8">{{ this.projectService.channelsTotal }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Remote:</div>
		<div class="col col-8">{{ this.projectService.remote?.name }}</div>
	</div>
</div>

<h5 class="proj-valid" *ngIf="projectService.errors?.length == 0" i18n="Common|Project is valid">Project is valid</h5>

<kendo-card class="minor" style="width: 100%" *ngIf="projectService.errors?.length > 0">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12 proj-notvalid" i18n="Common|Project validation">Project validation</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<ul>
			<li *ngFor="let d of projectService.errors">{{ d }}</li>
		</ul>
	</kendo-card-body>
</kendo-card>

<kendo-card class="minor" style="width: 100%" *ngIf="projectService.warnings?.length > 0">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12 warning">Warnings</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<ul>
			<li *ngFor="let d of projectService.warnings">{{ d }}</li>
		</ul>
	</kendo-card-body>
</kendo-card>

<app-wait [show]="showLoading" [message]="messageLoading"></app-wait>

<!-- dialogs -->

<app-confirm-dialog
	[dialogOpened]="isConfirmDialogOpened"
	[dialogMessage]="confirmDialogMessage"
	(dialogAction)="onConfirmDialogAction($event)">
</app-confirm-dialog>

