/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
	auth: {
		clientId: '6941e1b9-34ad-4989-ab11-1ceec247a185', // This is the ONLY mandatory field that you need to supply.
		authority: 'https://login.microsoftonline.com/4c782445-792e-46ed-8bd3-3b2ec246b2d3',
		redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
		postLogoutRedirectUri: '/logout', // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback(logLevel: LogLevel, message: string) {
			},
			logLevel: LogLevel.Verbose,
			piiLoggingEnabled: false
		}
	}
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
	scopes: ["openid", "profile"],
	loginHint: "example@domain.net"
};

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication(msalConfig);
}
