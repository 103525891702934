import { Injectable } from "@angular/core";
import { AreaType } from "../../interfaces/IAreaType";
import { Tags, SvgParams, GLASS_PART_PADDING } from "../../constants/constants";
import { SideBaseService } from "./side-base.service";
import { ElementType, Orientation, ToolboxItem } from '../../models/ToolboxModel';
import { ColumnProfile } from '../../models/profiles/column.model';
import { WallProfile } from "../../models/profiles/wall.model";
import { Workarea } from "src/app/_core/models/workarea";
import { ProjectService } from "../project.service";
import { SideFinishProfile } from "../../models/profiles/side-finish.model";
import { Profile } from "../../models/profiles/profile.model";
import { FrontProfile } from "../../models/profiles/front.model";
import { IPoint } from '../../interfaces/IPoint';
import { Common } from "../../models/common";
import { IRect } from "../../interfaces/IRect";
import { MarquiseVertical } from "../../models/marquises/marquise-vertical.model";
import { MarquiseRight } from "../../models/marquises/marquise-right.model";
import { ElementSide } from "../../interfaces/IElementSide";
import { IConnectable } from "../../interfaces/IConnectable";
import { FrameProfile } from "../../models/profiles/frame.model";
import { IPointer } from "../../interfaces/IPointer";
import { GlassWallBase } from "../../models/glasses/glassWall-base.model";

@Injectable({
	providedIn: 'root'
})
export class RightSideService extends SideBaseService {

	protected previousTemplateNo: string;

	protected get hightestTopPointNum(): number {
		return SvgParams.RIGHT_TOP_POINT_NUM;
	}

	protected get lowestTopPointNum(): number {
		return SvgParams.LEFT_TOP_POINT_NUM;
	}

	public get areaType(): AreaType {
		return AreaType.Right;
	}
	public get rectOnSide(): string {
		return 'rectOnRight';
	}
	public get pathOnSide(): string {
		return 'pathOnRight';
	}
	public get anchorOnSide(): string {
		return 'anchorRight';
	}
	public get rotationOnSide(): string {
		return 'rotationRight';
	}

	protected addElementsAfterDisplayedRightSide(rightSideElements: any) {
		throw new Error("Method not implemented.");
	}

	leftElement: Profile | GlassWallBase;
	rightElement: Profile | GlassWallBase;
	bottomElement: Profile | GlassWallBase;

	constructor(protected projectService: ProjectService) {
		super(projectService.profileService, projectService);
	}

	// Adding elements, if it was displayed some other side area before displaying the right side area

	public addElementsAfterDisplayedRoof(roofElements: any): void {
		var front : FrontProfile = roofElements[ElementType.Front].find(f => f.tag == Tags.RIGHT_FRONT_TAG || f.tag == Tags.ANY_FRONT_TAG);
		if (!front) {
			return;
		}
		this.saveElementWithSetRect(front, front.height);

		const sideFinH = this.projectService.template.getBarSize().height;

		if (front.gutter) {
			var gutter: FrontProfile = roofElements[ElementType.Gutter].find(f => f.id == front.gutter.id);
			this.saveElementWithSetRect(gutter, front.gutterSize.height);
		}


		var rightRear : WallProfile = this.profileService.rightSideElements[ElementType.WallProfile].find(f => f.tag == Tags.RIGHT_REAR_TAG || f.tag == Tags.ANY_REAR_TAG);
		var wall: WallProfile = roofElements[ElementType.WallProfile].find(f => f.tag == Tags.RIGHT_REAR_TAG || f.tag == Tags.ANY_REAR_TAG);
		if (rightRear || wall) {
			this.saveWallWithSetRect(wall);
			if (!rightRear) {
				rightRear = wall;
				this.profileService.rightSideElements[ElementType.WallProfile].push(wall);
			} else {
				this.saveWallWithSetRect(rightRear);
			}

			roofElements[ElementType.Column].forEach((col: ColumnProfile) => {
				let x: number;
				if (col.isRear) {
					x = this.depth * SvgParams.SCALE + SvgParams.START_X - (col.rectOnRoof.y - SvgParams.START_Y) - Math.round(col.depth * SvgParams.SCALE);
				} else {
					x = front.rectOnRight.x + front.rectOnRight.w - col.depth * SvgParams.SCALE;
				}
				switch (col.tag) {
					case Tags.RIGHT_COLUMN_TAG:
						this.saveColWithSetRect(col, x, front.height);
						break;
					case Tags.RIGHT_REAR_COLUMN_TAG:
						this.saveColWithSetRect(col, x, rightRear.height);
						break;
				}
			});

			roofElements[ElementType.SideFinish].filter(s => s.tag == Tags.RIGHT_SIDEFINISH_TAG).forEach((sideFin: SideFinishProfile) => {
				const r = this.projectService.template.getDefaultRear();
				const x = this.shapePoints[SvgParams.RIGHT_TOP_POINT_NUM].x - Common.round(rightRear.depth * SvgParams.SCALE);
				const y = this.shapePoints[SvgParams.RIGHT_TOP_POINT_NUM].y + Common.round(r.size.height * SvgParams.SCALE) - Common.round(r.reinforcementHeight * SvgParams.SCALE);
				this.saveSideFinWithSetRect(sideFin, x, y);
			});
		}
	}

	private saveSideFinWithSetRect(sideFin: SideFinishProfile, x2: number, y2: number): void {
		if (!this.projectService.template.rearReinforcementDepth) { // template not loaded yet
			return;
		}

		const sideFinH = this.projectService.template.getBarSize().height;
		const l = Math.round(this.projectService.template.barLength * SvgParams.SCALE);
		const h = Math.round(sideFinH * SvgParams.SCALE);
		const x = x2 + Common.round(this.projectService.template.rearReinforcementDepth * SvgParams.SCALE) - l;

		const rect: IRect = { x: Common.round(x), y: Common.round(y2 - h), w: Common.round(l), h: Common.round(h) };
		sideFin.anchor = { x: rect.x + rect.w, y: rect.y + rect.h };
		sideFin.rotation = -this.projectService.template.dropAngle;
		sideFin[this.rectOnSide] = rect;

		this.profileService.rightSideElements[ElementType.SideFinish].push(sideFin);
	}

	// Methods for setting right side rectangles to generated or existed objects of elements and saving them to profileService

	protected saveWallWithSetRect(wall: WallProfile): void {
		const rear = this.projectService.template.getRearSize();

		wall.rectOnRight = {
			x: this.shapePoints[1].x - Math.round(rear.depth * SvgParams.SCALE),
			y: this.shapePoints[1].y,
			w: Math.round(rear.depth * SvgParams.SCALE),
			h: Math.round(rear.height * SvgParams.SCALE)
		};
	}

	protected saveElementWithSetRect(element: Profile, height: number): void {

		var y = this.shapePoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y - this.projectService.template.columnLength * SvgParams.SCALE - height * SvgParams.SCALE;

		element.rectOnRight = {
			x: this.shapePoints[0].x,
			y: y,
			w: Common.round(element.depth * SvgParams.SCALE),
			h: Common.round(height * SvgParams.SCALE)
		};
		if (this.profileService.rightSideElements[element.type].find(e => e.id == element.id) == null) {
			this.profileService.rightSideElements[element.type].push(element);
		}
	}

	//////////////////////////////////

	public changeArea() {
		if (this.profileService.rightSideElements !== null) {
			this.profileService.rightSideElements = null;
			this.profileService.rightSideElementsInit();
			this.showArea();
			this.applyTemplate(this._template);
		}
	}

	public getFrontCornerY(currentAreaElements?: any) {
		if (currentAreaElements == null) {
			currentAreaElements = this.projectService.getCurrentAreaElements();
		}

		var sideFinish: SideFinishProfile = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.RIGHT_SIDEFINISH_TAG);
		if (!sideFinish) {
			currentAreaElements = this.profileService.rightSideElements;
			sideFinish = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.RIGHT_SIDEFINISH_TAG);
		}

		var rightFront: FrontProfile = currentAreaElements[ElementType.Front].find((c: FrontProfile) => c.tag == Tags.RIGHT_FRONT_TAG);
		var lty = 0;
		if (!rightFront) {
			rightFront = currentAreaElements[ElementType.Front][0];
		}
		if (rightFront.gutter) {
			lty = rightFront.rectOnRight.y;
		} else {
			const svg = (document.getElementById(AreaType.Right + '_' + sideFinish.id) as unknown) as SVGGraphicsElement;
			if (svg) { // more trustworthy, but not always available
				const clRect = svg.getBoundingClientRect();
				var z = localStorage.getItem("zoom");
				if (!z)
					z = "1";
				var zoom = parseFloat(z);

				lty = sideFinish.rectOnRight.y + clRect.height / zoom;
			} else {
				lty = sideFinish.rectOnRight.y + Common.calculateDroppedY(sideFinish.rectOnRight.w, this.projectService.template.dropAngle) + sideFinish.rectOnRight.h
					- this.projectService.template.dropAngle * 0.1; // stupid factor for correction - experimental value
			}

			
		}

		return lty;
	}

	public getGWDStartPoints(currentAreaElements?: any): IPoint[] {
		if (currentAreaElements == null) {
			currentAreaElements = this.projectService.getCurrentAreaElements();
		}

		var sideFinish: SideFinishProfile = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.RIGHT_SIDEFINISH_TAG);
		if (!sideFinish) {
			currentAreaElements = this.profileService.rightSideElements;
			sideFinish = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.RIGHT_SIDEFINISH_TAG);
		}
		const rightColumn = currentAreaElements[ElementType.Column].find((c: ColumnProfile) => c.tag == Tags.RIGHT_COLUMN_TAG);
		const rightRearColumn = currentAreaElements[ElementType.Column].find((c: ColumnProfile) => c.tag == Tags.RIGHT_REAR_COLUMN_TAG);


		const lt = SvgParams.LEFT_TOP_POINT_NUM;
		const rt = SvgParams.RIGHT_TOP_POINT_NUM;
		const lb = SvgParams.LEFT_BOTTOM_POINT_NUM;
		const rb = SvgParams.RIGHT_BOTTOM_POINT_NUM;

		var lty = this.getFrontCornerY(currentAreaElements);
		var rty = this.getSideFinishYCorrected(sideFinish, sideFinish.rectOnRight.y + sideFinish.height * SvgParams.SCALE);

		const x1 = rightColumn.rectOnRight.x + rightColumn.rectOnRight.w;
		const x2 = rightRearColumn ? rightRearColumn.rectOnRight.x : this.shapePoints[rt].x;

		const points: IPoint[] = [];
		points.push({
			x: x1,
			y: lty,
		});
		points.push({
			x: x2,
			y: rty,
		});
		points.push({
			x: x2,
			y: this.shapePoints[rb].y,
		});
		points.push({
			x: x1,
			y: this.shapePoints[lb].y,
		});

		return points;
	}

	getMinHeightOfGWD(points: IPoint[]): number {
		const rightHeight = points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - points[SvgParams.RIGHT_TOP_POINT_NUM].y;
		const leftHeight = points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - points[SvgParams.LEFT_TOP_POINT_NUM].y;
		const res = Math.round((rightHeight - leftHeight) / SvgParams.SCALE);
		return Common.round(res + (GLASS_PART_PADDING * 2 + 1) / SvgParams.SCALE);
	}

	public calculatePoints() {

		this.shapePoints = [];
		this.workAreas = [];

		this._svg = "";

		const depth = this.projectService.realDepth;
		const depthDiff = (depth - this.projectService.template.depth) * SvgParams.SCALE;
		const rh = this.projectService.template.getRearSize().height;
		var ac = depth * Math.tan(Common.toRadians(this.projectService.template.dropAngle));

		const bh = this.backHeight;
		const fh = this.frontHeight;
		const df = Common.round(ac * SvgParams.SCALE);
		const y2 = SvgParams.START_Y + Common.round((bh + rh) * SvgParams.SCALE);

		this.shapePoints.push({ x: SvgParams.START_X - depthDiff, y: SvgParams.START_Y + df});
		this.shapePoints.push({ x: SvgParams.START_X - depthDiff + Common.round(depth * SvgParams.SCALE), y: SvgParams.START_Y });
		this.shapePoints.push({ x: SvgParams.START_X - depthDiff + Common.round(depth * SvgParams.SCALE), y: y2 });
		this.shapePoints.push({ x: SvgParams.START_X - depthDiff, y: y2 });

		const de = this._template.getFrontColumnSize().depth;
		const colD = Common.round(de * SvgParams.SCALE);

		var p = new Workarea();

		p.points.push({ x: this.shapePoints[0].x + colD, y: this.shapePoints[0].y });
		p.points.push({ x: this.shapePoints[1].x, y: this.shapePoints[0].y });
		p.points.push({ x: this.shapePoints[2].x, y: this.shapePoints[2].y });
		p.points.push({ x: this.shapePoints[3].x + colD, y: this.shapePoints[3].y });

		p.width = depth - de;
		p.height = fh;

		this.workAreas.push(p)
	}

	protected getSideCols(ptr: IPointer): { leftProfile: Profile, rightProfile: Profile } {
		var leftProfile: Profile = this.profileService.rightSideElements[ElementType.Column].find(c => c.tag == Tags.LEFT_REAR_COLUMN_TAG);
		var rightProfile: Profile = this.profileService.rightSideElements[ElementType.Column].find(c => c.tag == Tags.LEFT_FRONT_TAG);

		return { leftProfile, rightProfile }
	}

	public createMarquise(ptr: IPointer, currentTool: ToolboxItem) {
		const info = this.projectService.template.marquises.find(m => m.id == currentTool.id);
		var cols = this.projectService.getSpace(ptr);

		if (!cols.rightProfile) {
			cols.rightProfile = this.createFrame(this.projectService, info, Orientation.Vertical);
		}
		var topFrame = this.createFrame(this.projectService, info, Orientation.Horizontal);
		const message = MarquiseVertical.validate(info, cols.leftProfile, cols.rightProfile, AreaType.Right, null, this.profileService);
		if (message != "") {
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return;
		}

		var mq = new MarquiseRight(this.projectService, info, cols.leftProfile, cols.rightProfile, topFrame, AreaType.Right, this.getWorkAreaPoints());
		mq.relocateEngine(ElementSide.Right);
		mq.setHandler(this.projectService.template.frontId);
		this.projectService.profileService.addVertical(mq);

		this.projectService.freezeProjProp();
		this.projectService.emitChange();
		this.projectService.calculateRemote();
	}

	public createFrame(projectService: ProjectService, info: IConnectable, orient: Orientation) {
		const cnt = projectService.template.hiddenExtras.find(profile => profile.id == info.connectorId);
		const rear = projectService.profileService.rightSideElements[ElementType.WallProfile].find(c => c.tag == Tags.RIGHT_REAR_TAG || c.tag == Tags.ANY_REAR_TAG);
		const d = cnt.size.depth * SvgParams.SCALE;
		const y = orient == Orientation.Vertical ? rear.rectOnRight.y + rear.rectOnRight.h : rear.rectOnRight.y;
		const w = orient == Orientation.Vertical ? projectService.template.backHeight : projectService.template.depth;
		return new FrameProfile(rear.rectOnRight.x + rear.rectOnRight.w - d, y, w, cnt.size, cnt.color, orient, info.connectorId);
	}

}
