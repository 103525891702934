<div class="row">
	<div class="col col-4"><span i18n="Common|Length">Length</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="current.getLength()" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4">
		<span i18n="Common|Location x">Location x</span>:
	</div>

	<div class="col col-8">
		<app-numeric-textbox [value]="currentProfileLocation" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<card-cuttingonsite [current]="current" [disabled]="checkFreezingElements()"></card-cuttingonsite>

<div class="row" *ngIf="checkFreezingElements()">
	<div class="col col-4">
		<span class="k-icon k-i-x-circle"></span>
		Frozen:
	</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.checkCircleIcon" (click)="openDialog(unfreezingMess)" [disabled]="projectService.readOnly"><span i18n="Common|Unfreeze">Unfreeze</span></button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Tag:</div>
		<div class="col col-8">{{ current.tag }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Id:</div>
		<div class="col col-8">{{ current.id }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Columns:</div>
		<div class="col col-8">
			<p *ngFor="let c of current.columns">
				{{ c.tag}}: {{ c.id }}
			</p>
		</div>
	</div>
</div>


<app-confirm-dialog [dialogOpened]="dialogOpened" [dialogMessage]="dialogMessage" (dialogAction)="onDialogAction($event)"></app-confirm-dialog>
