<svg:g app-customer-plate *ngIf="projectService.info != null && printMode && channel != 'CRM'" [area]="'Roof'"></svg:g>

<!-- Main roof shape -->
<svg:path *ngIf="!printMode" [attr.d]="areaService.getSvg()" stroke="#282a30" stroke-width="0.2" fill="#d9ecf5"
    fill-opacity="0.4" stroke-dasharray="4,4" (click)="onShapeDrop($event)"
    [class]="styling.iconClass('Shape', 'Roof')" (mouseover)='mouseOverGlass($event)' (mouseout)='mouseOutGlass()'>
</svg:path>

<svg:g *ngIf="!printElements">
    <svg:text *ngFor="let m of projectService.markers" text-anchor="middle" [attr.x]="m.loc.x" [attr.y]="m.loc.y"
        class="element-marker">
        {{ m.text }}
    </svg:text>
</svg:g>

<!-- Marquise-top-bottom -->
<svg:g *ngIf="!printMode || printElements">
    <svg:g *ngFor="let m of projectService.profileService.roofElements['MarquiseTopBottom']">
        <svg:path 
            [class]="getClasses(m,'Roof')" (click)="selectMarquise(m)" [attr.d]="m.getDForPath('Roof')"
            [attr.fill]="styling.getMarquiseBackColor(m)">
        </svg:path>
        <svg:rect [class]="getClasses(m,'Roof', '', true)" [attr.x]="m.engine.rect.x" [attr.y]="m.engine.rect.y" [attr.width]="m.engine.rect.w"
            [attr.height]="m.engine.rect.h" [attr.fill]="m.engine.color">
        </svg:rect>
        <svg:g *ngFor="let r of m.rails">
            <svg:rect [class]="getClasses(m,'Roof', '', true)" (click)="selectMarquise(m)"
            [attr.x]="r.x" [attr.y]="r.y" [attr.width]="r.w" [attr.height]="r.h" stroke="black" stroke-width="0.2" fill="None"></svg:rect>
        </svg:g>
        <text text-anchor="middle" [class]="getClasses(m,'Roof', '', true)">
            <tspan [attr.x]="m.textOnMarquize.x" [attr.y]="m.textOnMarquize.y">{{ m.textOnMarquize.text }}</tspan>
        </text>
    </svg:g>
</svg:g>

<!-- Glasses -->
<svg:g *ngIf="styling.showLayer(1)">
    <svg:g *ngFor="let g of projectService.profileService.roofElements['Glass']; let i = index">
        <svg:rect [attr.x]="g.rect.x" [attr.y]="g.rect.y"
            [attr.width]="g.rect.w" [attr.height]="g.rect.h" [class]="getClasses(g,'Roof', 'glass', false)"
            (click)="selectGlass(g, $event)" (mouseover)='mouseOverGlass($event)' (mouseout)='mouseOutGlass()' [attr.fill]="styling.getGlassBackColor(g)">
        </svg:rect>
        <svg:text *ngIf="!printElements && !styling.hideOnLayer(g)" [attr.text-anchor]="g.marker.anchor" [attr.x]="g.marker.loc.x" [attr.y]="g.marker.loc.y" class="element-marker">
            {{ g.marker.text }}{{ i+1 }}
        </svg:text>
    </svg:g>
</svg:g>
<!-- ! glassId, leftBar and rightBar attributes only for debug purpose. To be removed. -->

<!-- Roof Window-->
<svg:g *ngFor="let rw of projectService.profileService.roofElements['RoofWindow']">
    <svg:rect
        [attr.x]="rw.rect.x" [attr.y]="rw.rect.y" [attr.width]="rw.rect.w" [attr.height]="rw.rect.h"
        [class]="getClasses(rw,'Roof', 'roof-window-frame')"
        (click)="selectGlass(rw, $event)"
        [attr.fill]="rw.color"
    >
    </svg:rect>

    <svg:rect *ngIf="rw.connectorTop.visible" [attr.x]="rw.connectorTop.rect.x" [attr.y]="rw.connectorTop.rect.y"
        [attr.width]="rw.connectorTop.rect.w" [attr.height]="rw.connectorTop.rect.h"
        class="profile" [attr.fill]="styling.getBackColor(rw.connectorTop.color)">
    </svg:rect>

    <svg:rect *ngIf="rw.connectorBottom.visible" [attr.x]="rw.connectorBottom.rect.x" [attr.y]="rw.connectorBottom.rect.y"
        [attr.width]="rw.connectorBottom.rect.w" [attr.height]="rw.connectorBottom.rect.h"
        class="profile" [attr.stroke]="projectService.frameColor" [attr.fill]="styling.getBackColor(rw.connectorBottom.color)">
    </svg:rect>

</svg:g>

<!-- Roof gutter -->
<svg:g *ngFor="let rw of projectService.profileService.roofElements['RoofGutter']">
    <svg:rect [attr.x]="rw.rect.x" [attr.y]="rw.rect.y" [attr.width]="rw.rect.w" [attr.height]="rw.rect.h"
        [class]="getClasses(rw,'Roof')"
        (click)="selectGlass(rw, $event)"
        [attr.fill]="rw.color">
    </svg:rect>

    <circle [attr.cx]="rw.circle.x" [attr.cy]="rw.circle.y" [attr.r]="rw.circle.r" (click)="selectGlass(rw, $event)" fill="#e8940cDD"
        [class]="getClasses(rw,'Roof')">
    </circle>

    <svg:rect *ngIf="rw.connectorTop.visible" [attr.x]="rw.connectorTop.rect.x" [attr.y]="rw.connectorTop.rect.y"
        [attr.width]="rw.connectorTop.rect.w" [attr.height]="rw.connectorTop.rect.h" class="profile"
        [attr.stroke]="projectService.frameColor"
        [attr.fill]="styling.getBackColor(rw.connectorTop.color)">
    </svg:rect>

    <svg:rect *ngIf="rw.connectorBottom.visible" [attr.x]="rw.connectorBottom.rect.x" [attr.y]="rw.connectorBottom.rect.y"
        [attr.width]="rw.connectorBottom.rect.w" [attr.height]="rw.connectorBottom.rect.h" class="profile"
        [attr.stroke]="projectService.frameColor"
        [attr.fill]="styling.getBackColor(rw.connectorBottom.color)">
    </svg:rect>

</svg:g>

<!-- Roof ventilator -->
<svg:g *ngFor="let rw of projectService.profileService.roofElements['RoofVentilator']">
    <svg:rect [attr.x]="rw.rect.x" [attr.y]="rw.rect.y" [attr.width]="rw.rect.w" [attr.height]="rw.rect.h"
        [class]="getClasses(rw,'Roof', 'roof-window-frame')"
        (click)="selectGlass(rw, $event)"
        [attr.fill]="rw.color">
    </svg:rect>

    <svg:rect [attr.x]="rw.connectorBottom.rect.x" [attr.y]="rw.connectorBottom.rect.y"
        [attr.width]="rw.connectorBottom.rect.w" [attr.height]="rw.connectorBottom.rect.h" class="profile"
        [attr.stroke]="projectService.frameColor"
        [attr.fill]="styling.getBackColor(rw.connectorBottom.color)">
    </svg:rect>

</svg:g>

<!-- Profiles: Bar -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['Bar']"
    [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y" [attr.width]="l.rectOnRoof.w" [attr.height]="l.rectOnRoof.h"
    [attr.stroke]="projectService.frameColor"
    [class]="getClasses(l,'Roof', 'profile')"
    (click)="selectProfile(l, $event)"
    [attr.fill]="styling.getBackColor(l.color)">
</svg:rect>

<!-- Profiles: Connector -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['ProfileConnector']" [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y"
    [attr.width]="l.rectOnRoof.w" [attr.height]="l.rectOnRoof.h"
    [attr.stroke]="projectService.frameColor"
    [class]="getClasses(l,'Roof', 'profile')"
    (click)="selectProfile(l, $event)" [attr.fill]="styling.getBackColor(l.color)">
</svg:rect>

<!-- Profiles: SideFinish -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['SideFinish']" [attr.x]="l.lineOnRoof.x2 - l.lineOnRoof.w / 2"
    [attr.y]="l.lineOnRoof.y1" [attr.width]="l.lineOnRoof.w" [attr.height]="l.lineOnRoof.y2 - l.lineOnRoof.y1"
    [attr.stroke]="projectService.frameColor"
    [class]="getClasses(l,'Roof', 'profile')"
    (click)="selectProfile(l, $event);"
    [attr.fill]="styling.getBackColor(l.color)">
</svg:rect>


<!-- Profiles: WallProfile -->
<svg:g *ngFor="let l of projectService.profileService.roofElements['WallProfile']">
    <svg:rect [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y" [attr.width]="l.rectOnRoof.w" [attr.height]="l.rectOnRoof.h"
        [class]="getClasses(l,'Roof', 'profile')"
        (click)="selectProfile(l, $event)" [attr.fill]="styling.getBackColor(l.color)"
        [attr.stroke]="projectService.frameColor">
    </svg:rect>
    <svg:rect *ngFor="let r of l.wallGutters" [attr.x]="r.rectOnRoof.x" [attr.y]="r.rectOnRoof.y" [attr.width]="r.rectOnRoof.w" [attr.height]="r.rectOnRoof.h"
        [class]="getClasses(r,'Roof', 'profile')"
        (click)="selectProfile(r, $event)" [attr.fill]="l.color"
        [attr.stroke]="projectService.frameColor">
    </svg:rect>
</svg:g>

<!-- Profiles: Gutter -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['Gutter']" [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y"
    [attr.width]="l.rectOnRoof.w" [attr.height]="l.rectOnRoof.h"
    [class]="getClasses(l,'Roof', 'profile')"
    (click)="selectProfile(l, $event);" [attr.fill]="styling.getBackColor(l.color)"
    [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Front -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['Front']" [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y"
    [attr.width]="l.rectOnRoof.w" [attr.height]="l.rectOnRoof.h"
    [class]="getClasses(l,'Roof', 'profile')"
    (click)="selectProfile(l, $event);" [attr.fill]="styling.getBackColor(l.color)"
    [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Circle -->
<svg:circle *ngFor="let l of projectService.profileService.roofElements['LED']"
    [attr.cx]="l.circle.x" [attr.cy]="l.circle.y" [attr.r]="l.circle.r"
    (click)="selectProfile(l, $event)"				
    [class]="getClasses(l,'Roof')"
    [attr.fill]="l.color">
    {{l.CircleNumber}}
</svg:circle>
<!-- [ngClass]="[iconClass('LED','Roof'), SelectedLeds === true ? 'highlight' : '', selectedItems.indexOf(l) >= 0 ? 'chosen-led' : '']" -->

<!-- Profiles: Led Stripes -->
<svg:rect *ngFor="let l of projectService.profileService.roofElements['LEDStripe']"
            [attr.x]="l.rect.x"
            [attr.y]="l.rect.y"
            [attr.width]="l.rect.w"
            [attr.height]="l.rect.h"
            [class]="getClasses(l,'Roof')"
            (click)="selectProfile(l, $event)"
            [attr.fill]="l.color">
</svg:rect>

<svg:g app-marquise-vert *ngIf="!printMode" [areaService]="areaService"
    [selectedItems]="selectedItems" [nonSelectable]="true" [printMode]="printMode">
</svg:g>

<!-- Profiles: Column -->
<svg:g *ngIf="styling.showLayer(0)">
    <svg:g *ngFor="let l of projectService.profileService.getSortedColumns('roofElements', 'None'); let i = index">
        <svg:rect [attr.x]="l.rectOnRoof.x" [attr.y]="l.rectOnRoof.y" [attr.width]="l.rectOnRoof.w"
            [attr.height]="l.rectOnRoof.h" class="column" (click)="selectProfile(l, $event)"
            [class]="getClasses(l,'Roof', 'profile')"
            [attr.stroke]="projectService.frameColor"
            [attr.fill]="styling.getBackColor()">
        </svg:rect>
        <circle *ngIf="l.outflow && l.outflow != 'None'"
            [class]="getClasses(l,'Roof', 'profile', true)"
            [attr.cx]="l.rectOnRoof.x + l.rectOnRoof.w / 2" [attr.cy]="l.rectOnRoof.y + l.rectOnRoof.h / 2" [attr.r]="l.rectOnRoof.h * 0.3" 
            [attr.stroke]="projectService.frameColor" [attr.fill]="l.outflowCover ? 'lightblue' : 'white'"
            (click)="selectProfile(l, $event)">
        </circle>
        <svg:text *ngIf="!printElements" [attr.text-anchor]="l.marker.anchor" class="element-marker" [attr.x]="l.marker.loc.x"
            [attr.y]="l.marker.loc.y">
            {{ l.marker.text }}{{ i+1 }}
        </svg:text>
        <svg:rect *ngIf="l.connector" class="column-marker" [attr.x]="l.roofMarker.x" [attr.y]="l.roofMarker.y"
            [attr.width]="l.roofMarker.w" [attr.height]="l.roofMarker.h">
        </svg:rect>
    </svg:g>
</svg:g>

<svg:g *ngIf="!printMode || printElements">

    <!-- Marquise-top-up -->
    <svg:g *ngFor="let m of projectService.profileService.roofElements['MarquiseTopUp']">
        <svg:path
            [class]="getClasses(m,'Roof', 'marquise')"
            (click)="selectMarquise(m)" [attr.d]="m.getDForPath('Roof')" [attr.fill]="styling.getMarquiseBackColor(m)">
        </svg:path>
        <svg:rect [class]="getClasses(m,'Roof', '', true)" [attr.x]="m.engine.rect.x" [attr.y]="m.engine.rect.y" [attr.width]="m.engine.rect.w"
            [attr.height]="m.engine.rect.h" [attr.fill]="m.engine.color">
        </svg:rect>
        <svg:g *ngFor="let r of m.rails">
            <svg:rect [class]="getClasses(m,'Roof', '', true)" (click)="selectMarquise(m)"
            [attr.x]="r.x" [attr.y]="r.y" [attr.width]="r.w" [attr.height]="r.h" stroke="black" stroke-width="0.2" fill="None"></svg:rect>
        </svg:g>
        <text text-anchor="middle" [class]="getClasses(m,'Roof', '', true)">
            <tspan [attr.x]="m.textOnMarquize.x" [attr.y]="m.textOnMarquize.y">{{ m.textOnMarquize.text }}</tspan>
        </text>
    </svg:g>

    <!-- Marquise-top-up-mouseover -->
    <svg:path *ngFor="let m of projectService.profileService.roofElements['MarquiseTopUpMouseover']" class="marquise-top-up-mouseover"
        [attr.d]="m.getDForPath('Roof')">
    </svg:path>

    <!-- Marquise-top-bottom-mouseover -->
    <svg:path *ngFor="let m of projectService.profileService.roofElements['MarquiseTopBottomMouseover']" class="marquise-top-bottom-mouseover"
        [attr.d]="m.getDForPath('Roof')">
    </svg:path>

</svg:g>

<!-- Dimensions -->
<svg:g *ngFor="let d of projectService.profileService.roofElements['Dimension']">
    <svg:g app-dimension [item]="d" [printMode]="printMode"></svg:g>
</svg:g>

<!-- id attribute only for debug purpose. To be removed. -->        