import { Injectable } from '@angular/core';
import { AreaType, CollectionName, RectName } from 'src/app/_core/interfaces/IAreaType';
import { Tags, SvgParams } from 'src/app/_core/constants/constants';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { ColumnParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { ColumnProfile } from '../models/profiles/column.model';
import { Profile } from '../models/profiles/profile.model';
import { SideFinishProfile } from '../models/profiles/side-finish.model';
import { ProjectTemplate } from '../template';
import { Common } from '../models/common';
import { ElementSide, Montage } from '../interfaces/IElementSide';
import { FrontProfile } from '../models/profiles/front.model';
import { WallProfile } from '../models/profiles/wall.model';
import { ExtraOption } from '../models/extra-option.model';
import { GlassWallDiamond } from '../models/glasses/glassWallDiamond.model';
import { GlassWall } from '../models/glasses/glassWall.model';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { IComment } from '../interfaces/IElement';
import { VerticalElement } from '../models/vertical-element';
import { BehaviorSubject } from 'rxjs';
import { Glass } from '../models/glasses/glass.model';
import { MarquiseTop } from '../models/marquises/marquise-top.model';
import { LayerLevel } from '../interfaces/LayerLevel';
import { IRect } from '../interfaces/IRect';
import { Space } from '../models/space';
import { ProjectService } from './project.service';
import { IAreaService } from '../interfaces/IAreaService';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	roofElements = null;
	frontElements = null;
	rearElements = null;
	leftSideElements = null;
	rightSideElements = null;
	extraElements: ExtraOption[] = [];
	comments: IComment[] = [];
	private verticalElements: VerticalElement[] = [];

	public tooltipTitle:string;

	// public static verticalElements = [ElementType.MarquiseVertical, ElementType.Door, ElementType.Wall, ElementType.GlassWallDiamond];
	public commentsChanged: BehaviorSubject<IComment> = new BehaviorSubject<IComment>(null);

	constructor() {
	}

	public getVerticals(area: AreaType = AreaType.None, type: ElementType = ElementType.None) {
		var elems: VerticalElement[] = []
		if (area != null && area != AreaType.None) {
			elems = this.verticalElements.filter(d => d.area == area);
		} else {
			elems = this.verticalElements;
		}
		if (type != ElementType.None) {
			elems = elems.filter(v => v.type == type);
		}

		return elems;
	}

	public getDoors(area: AreaType = AreaType.None) {
		return this.getVerticals(area, ElementType.Door);
	}

	public getWalls(area: AreaType = AreaType.None) {
		return this.getVerticals(area, ElementType.Wall);
	}

	public getMarquisesVertical(area: AreaType = AreaType.None) {
		return this.getVerticals(area, ElementType.MarquiseVertical);
	}

	public getGetDiamonds(area: AreaType = AreaType.None) {
		return this.getVerticals(area, ElementType.GlassWallDiamond);
	}
	
	public getGetGlassWalls(area: AreaType = AreaType.None) {
		return this.getVerticals(area, ElementType.GlassWall);
	}

	public removeVerticals(area: AreaType = null) {
		if (area == null) {
			this.verticalElements = [];	
		}
		this.verticalElements = this.verticalElements.filter(v => v.area != area);
	}

	addVertical(element: VerticalElement, refresh: boolean = true) {
		this.verticalElements.push(element);
		if (refresh) {
			element.refreshSurround(this);
			element.adjustSurround();
		}
		if(element.type == ElementType.MarquiseVertical){
			this.reverseMarquise();
		}
	}

	deleteVertical(element: VerticalElement) {
		var neibs: VerticalElement[] = [
			element.getNeib(ElementSide.Left),
			element.getNeib(ElementSide.Right),
			element.getNeib(ElementSide.Top),
			element.getNeib(ElementSide.Bottom),
		]
		this.verticalElements = this.verticalElements.filter(e => e.id != element.id);

		neibs.forEach(n => {
			if (n) {
				n.refreshSurround(this);
			}
		});
	}

	public moveColumnConnector(profile: ColumnProfile, side: ElementSide) {

		let left: FrontProfile | WallProfile = profile.leftProfile;
		let right: FrontProfile | WallProfile = profile.rightProfile;
		let target: ColumnProfile;
		let newLeftLength : number;
		let newRightLength : number;

		switch (side) {
			case ElementSide.Left:
				target = left.columns[left.columns.findIndex(c => c && c.id == profile.id) - 1];
				left.columns = left.columns.filter(c => c && c.id != profile.id);
				right.columns = [target, ...right.columns.filter(c => c)];

				var diff = profile.rectOnRoof.x / SvgParams.SCALE - target.rectOnRoof.x / SvgParams.SCALE;
				newLeftLength = left.getLength() - diff;
				newRightLength = right.getLength() + diff;

				break;
			case ElementSide.Right:
				target = right.columns[1];
				if (target == null) {
					return;
				}
				right.columns = right.columns.filter(c => c && c.id != profile.id);
				left.columns.push(target);

				var diff = (target.rectOnRoof.x - profile.rectOnRoof.x) / SvgParams.SCALE;
				newLeftLength = left.getLength() + diff;
				newRightLength = right.getLength() - diff;

				break;
			default:
		}


		left.setLength(newLeftLength);
		left.rectOnRoof.w = newLeftLength * SvgParams.SCALE;
		if (!profile.isRear) {
			left.rectOnFront.w = left.rectOnRoof.w;
		}

		right.setLength(newRightLength);
		right.rectOnRoof.w = newRightLength * SvgParams.SCALE;
		if (!profile.isRear) {
			right.rectOnFront.w = right.rectOnRoof.w;
		}

		var x = target.rectOnRoof.x + target.rectOnRoof.w / 2;
		right.rectOnRoof.x = x;
		if (!profile.isRear) {
			right.rectOnFront.x = x;
		}

		profile.connector = false;
		target.connector = true;
		target.leftProfile = left;
		target.rightProfile = right;
	}

	public removeColumnConnector(profile: ColumnProfile) {

		let left: FrontProfile | WallProfile = profile.leftProfile;
		let right: FrontProfile | WallProfile = profile.rightProfile;
		let newLength = left.length + right.length;
		left.columns = left.columns.concat(right.columns);
		left.columns = [...new Set(left.columns.map(item => item))];

		left.setLength(newLength);

		profile.connector = false;
		profile.leftProfile = null;
		profile.rightProfile = null;
		if (profile.isFront) {
			this.roofElements[ElementType.Front] = this.roofElements[ElementType.Front].filter(r => r.id != right.id);
			this.frontElements[ElementType.Front] = this.frontElements[ElementType.Front].filter(r => r.id != right.id);	
		} else {
			this.roofElements[ElementType.WallProfile] = this.roofElements[ElementType.WallProfile].filter(r => r.id != right.id);
			this.rearElements[ElementType.WallProfile] = this.rearElements[ElementType.WallProfile].filter(r => r.id != right.id);
		}

		this.setTags(profile.isFront);
	}

	private setTags(isFront: boolean) {
		var collection = isFront ? this.roofElements[ElementType.Front] : this.roofElements[ElementType.WallProfile];
		if (isFront) {
			if (collection.length == 1) {
				collection[0].tag = Tags.ANY_FRONT_TAG;
			} else if (collection.length == 2) {
				collection[0].tag = Tags.LEFT_FRONT_TAG;
				collection[1].tag = Tags.RIGHT_FRONT_TAG;
			}
	
		} else {
			if (collection.length == 1) {
				collection[0].tag = Tags.ANY_REAR_TAG;
			} else if (collection.length == 2) {
				collection[0].tag = Tags.LEFT_REAR_TAG;
				collection[1].tag = Tags.RIGHT_REAR_TAG;
			}
		}
	}

	public addColumnConnector(column: ColumnProfile, projectService: ProjectService) {
		if (column.isFront) {
			this.addFrontColumnConnector(column, projectService);
		} else {
			this.addRearColumnConnector(column, projectService);
		}
	}

	private addRearColumnConnector(column: ColumnProfile, projectService: ProjectService) {
		
		var prevColumn = this.getNextColumn(AreaType.Rear, ElementSide.Left, column);

		var left = this.roofElements[ElementType.WallProfile].find((f: WallProfile) => f.columns.find(c => c.id == column.id))
		var columnMiddlePoint = column.rectOnRoof.x + column.rectOnRoof.w / 2;

		var leftNewLength = (columnMiddlePoint - left.rectOnRoof.x) / SvgParams.SCALE;
		var rightLength = left.getLength() - leftNewLength;

		left.setLength(leftNewLength);

		var right = new WallProfile(projectService.currentArea, left.rectOnRoof.x + leftNewLength * SvgParams.SCALE, left.rectOnRoof.y, projectService.template, rightLength, AreaType.Roof, left.configId);
		right.tag = Tags.ANY_REAR_TAG;
		right.setLength(rightLength);

		column.leftProfile = left;
		column.rightProfile = right;
		column.connector = true;

		if (left.tag == Tags.ANY_REAR_TAG && this.roofElements[ElementType.Front].length == 1) {
			left.tag = Tags.LEFT_REAR_TAG;
			right.tag = Tags.RIGHT_REAR_TAG;
		} else if ((columnMiddlePoint - SvgParams.START_X) / SvgParams.SCALE + rightLength >= projectService.template.width) {
			right.tag = Tags.RIGHT_REAR_TAG;
		}

		this.roofElements[ElementType.WallProfile].push(right);	

		right.columns.push(column);
		left.columns.forEach(c=> {
			if (c.rectOnRear.x < column.rectOnRear.x) {
				right.columns.push(c);
				left.columns = left.columns.filter(c2 => c2.id != c.id);
			}
		});

		if (prevColumn?.connector) {
			prevColumn.leftProfile = right;
		}

		this.setTags(false);

	}

	private addFrontColumnConnector(column: ColumnProfile, projectService: ProjectService) {

		var nextColumn = this.getNextColumn(AreaType.Front, ElementSide.Right, column);

		var left = this.roofElements[ElementType.Front].find((f: FrontProfile) => f.columns.find(c => c.id == column.id));
		var columnMiddlePoint = column.rectOnRoof.x + column.rectOnRoof.w / 2;

		var leftNewLength = (columnMiddlePoint - left.rectOnRoof.x) / SvgParams.SCALE;
		var rightLength = left.getLength() - leftNewLength;
		left.setLength(leftNewLength);

		var right = new FrontProfile(projectService.currentArea, left.rectOnRoof.x + leftNewLength * SvgParams.SCALE, left.rectOnRoof.y, projectService.template, rightLength, AreaType.Roof, left.configId, 0, 0);		
		right.tag = Tags.ANY_FRONT_TAG;
		right.setLength(rightLength);

		column.leftProfile = left;
		column.rightProfile = right;
		column.connector = true;

		if (left.tag == Tags.ANY_FRONT_TAG && this.roofElements[ElementType.Front].length == 1) {
			left.tag = Tags.LEFT_FRONT_TAG;
			right.tag = Tags.RIGHT_FRONT_TAG;
		} else if ((columnMiddlePoint - SvgParams.START_X) / SvgParams.SCALE + rightLength >= projectService.template.width) {
			right.tag = Tags.RIGHT_FRONT_TAG;
		}
		this.roofElements[ElementType.Front].push(right);

		right.columns.push(column);
		left.columns.forEach(c=> {
			if (c.rectOnRoof.x > column.rectOnRoof.x) {
				right.columns.push(c);
				left.columns = left.columns.filter(c2 => c2.id != c.id);
			}
		});

		if (nextColumn?.connector) {
			nextColumn.leftProfile = right;
		}

		this.setTags(true);

	}

	private _elementsInit(areaElements: any): void {
		for (const type in ElementType) {
			areaElements[ElementType[type]] = [];
		}
	}

	public elementsInit() {
		this.roofElementsInit();
		this.frontElementsInit();
		this.rearElementsInit();
		this.leftSideElementsInit();
		this.rightSideElementsInit();
	}

	roofElementsInit(): void {
		this.roofElements = {};
		this._elementsInit(this.roofElements);
	}

	frontElementsInit(): void {
		this.frontElements = {};
		this._elementsInit(this.frontElements);
	}

	rearElementsInit(): void {
		this.rearElements = {};
		this._elementsInit(this.rearElements);
	}

	leftSideElementsInit(): void {
		this.leftSideElements = {};
		this._elementsInit(this.leftSideElements);
	}

	rightSideElementsInit(): void {
		this.rightSideElements = {};
		this._elementsInit(this.rightSideElements);
	}

	public clearAutoColumns(collectionName: string) {
		this[collectionName][ElementType.Column] = this[collectionName][ElementType.Column].filter((elem: Profile) => {
			return !elem.tag.startsWith('autoCol');
		});
	}

	createOrUpdateProfileElement(template: ProjectTemplate, collectionName: string, rectName: string, tag: string, elementType: ElementType,
		x: number, y: number, h: number, currentArea: AreaType, area: AreaType, configId: string,
		x2?: number, y2?: number): Profile {

		let elem: Profile = this[collectionName][elementType].find((elem: Profile) => {
			return elem.tag === tag
		});

		if (elem) {
			this.changeCoordsInNecessaryElems(elem, rectName, elementType, x, y, h, x2, y2);
		} else {
			elem = this.createNecessaryElems(template, elementType, x, y, h, currentArea, area, configId, x2, y2);
			elem.tag = tag;
			this[collectionName][elementType].push(elem);
		}

		return elem;
	}

	private changeCoordsInNecessaryElems(elem: Profile, rectName: string, elementType: ElementType,
		x: number, y: number, h: number, x2?: number, y2?: number): void {

		if (elementType === ElementType.SideFinish) {
			elem[rectName].x1 = Common.round(x);
			elem[rectName].y1 = Common.round(y);
			elem[rectName].x2 = Common.round(x2);
			elem[rectName].y2 = Common.round(y2);
			elem.setLength(h);
		} else {
			elem[rectName].x = Common.round(x);
			elem[rectName].y = Common.round(y);
		}
	}

	private createNecessaryElems(template: ProjectTemplate, elementType: ElementType, x: number, y: number, h: number,
		currentArea: AreaType, area: AreaType, configId: string, x2?: number, y2?: number): Profile {

		if (elementType === ElementType.Column) {
			return new ColumnProfile(currentArea, x, y, h, area, configId, template);
		} else if (elementType === ElementType.SideFinish) {
			return new SideFinishProfile(currentArea, x, y, x2, y2, h, area, template);
		}
	}

	public getCornerColumns(isRear: boolean = false) {
		if (!this.frontElements || !this.frontElements[ElementType.Column]) {
			return null;
		}
		return this.frontElements[ElementType.Column].filter((elem: ColumnProfile) => elem.isCorner && elem.isRear == isRear);
	}

	public getCornerColumn(area: AreaType) : ColumnProfile {
		if (!this.frontElements || !this.frontElements[ElementType.Column]) {
			return null;
		}
		const t = area == AreaType.Left ? Tags.LEFT_COLUMN_TAG : Tags.RIGHT_COLUMN_TAG;
		return this.frontElements[ElementType.Column].find(c => c.tag == t);
	}

	public getDependentColumns(columns: ColumnParameter[]) {
		var cols: ColumnProfile[] = this.roofElements[ElementType.Column].filter((elem: ColumnProfile) => {
			const def = columns.find(c => c.id == elem.configId);
			return !def.independent;
		});
		return cols;
	}

	public getFrontOnSide(collection: any, sideTag: string): Profile {
		var p: FrontProfile = collection[ElementType.Front].find((f: FrontProfile) => f.tag === sideTag);
		if (p == null) {
			p = collection[ElementType.Front].find((f: FrontProfile) => f.tag === Tags.ANY_FRONT_TAG);
		}
		return p;
	}

	public getRearOnSide(collection: any, sideTag: string): Profile {
		var p: WallProfile = collection[ElementType.WallProfile].find((f: WallProfile) => f.tag === sideTag);
		if (p == null) {
			p = collection[ElementType.WallProfile].find((f: WallProfile) => f.tag === Tags.ANY_REAR_TAG);
		}
		return p;
	}

	public getSortedColumns(collection: string, area: AreaType) {
		let frontColumns: ColumnProfile[] = [];
		let rearColumns: ColumnProfile[] = [];
		
		if (area == AreaType.Front || area == AreaType.None) {
			frontColumns = this[collection][ElementType.Column].filter(c => !c.isRear).sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRoof.x > c2.rectOnRoof.x ? 1 : -1);
		}

		if (area == AreaType.Rear || area == AreaType.None) {
			rearColumns = this[collection][ElementType.Column].filter(c => c.isRear).sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRear?.x > c2.rectOnRear?.x ? 1 : -1);
		}

		if (area == AreaType.Roof) {
			const coll = CollectionName.get(AreaType.Rear);
			if (coll != collection) { // get rear columns from roof elements collection
				rearColumns = this[collection][ElementType.Column].filter(c => c.isRear).sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRoof.x > c2.rectOnRoof.x ? 1 : -1);
			}
		}

		if (area == AreaType.Left) {
			frontColumns = this[collection][ElementType.Column].sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnLeft.x > c2.rectOnLeft.x ? 1 : -1);
		}
		if (area == AreaType.Right) {
			frontColumns = this[collection][ElementType.Column].sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRight.x > c2.rectOnRight.x ? 1 : -1);
		}

		return frontColumns.concat(rearColumns);
	}

	public getColumn(area: AreaType, side: ElementSide, x: number) {
		const coll = CollectionName.get(area);
		const sn = RectName.get(area);
		const columns = this.getSortedColumns(coll, area);
		let col: ColumnProfile;
		if (side == ElementSide.Left) {
			columns.forEach(c => {
				if (c[sn].x + c[sn].w <= x) {
					col = c;
				}
			})
		} else {
			for(var i = columns.length - 1; i>=0; i--) {
				var c = columns[i];
				if (c[sn].x >= x) {
					col = c;
				}
			}
		}
		return col;
	}

	public getNextColumn(area: AreaType, side: ElementSide, column: Profile) {
		const coll = CollectionName.get(area);
		const sn = RectName.get(area);
		const columns = this.getSortedColumns(coll, area);

		if (side == ElementSide.Right) {
			for(var i = 0; i <= columns.length - 1; i++) {
				var c = columns[i];
				if (c.id == column.id) {
					return i < columns.length - 1 ? columns[i+1] : null;
				}
			}
		} else {
			for(var i = columns.length - 1; i>=0; i--) {
				var c = columns[i];
				if (c.id == column.id) {
					return i > 0 ? columns[i-1] : null;
				}
			}
		}
		return null;
	}


	public checkElementsOnSide(area: AreaType, left: ColumnProfile = null, right: ColumnProfile = null) {
		const side = RectName.get(area);
		const collection = CollectionName.get(area);

		if (this[collection][ElementType.GlassWall].find((g: GlassWall) => (left == null && right == null) || (g[side].x > left[side].x && g[side].x < right[side].x + right[side].w))) {
			return true;
		}
		if (this.getMarquisesVertical(area).find((g: MarquiseVertical) => (left == null && right == null) || (g[side].x > left[side].x && g[side].x < right[side].x))) {
			return true;
		}
		const l = SvgParams.LEFT_BOTTOM_POINT_NUM;
		if (this[collection][ElementType.GlassWallDiamond].find((g: GlassWallDiamond) => (left == null && right == null) || (g.points[l].x > left[side].x && g.points[l].x < right[side].x + right[side].w))) {
			return true;
		}

		return false;

	}

	public isAnyElement(area: AreaType, types: ElementType[]) {
		var exists = false;
		const collectionName = CollectionName.get(area);
		types.every(t => {
			if (this[collectionName][t].length > 0) {
				exists = true;
				return false;
			}
			return true;
		});
		if (exists) {
			return true;
		}

		this.verticalElements.every(v => {
			if (v.area == area) {
				exists = true;
				return false;
			}
			return true;
		});

		return exists;
	}

	justifyColumns(width: number, isFront: boolean) {
		var cols = this.getSortedColumns(CollectionName.get(AreaType.Roof), isFront ? AreaType.Front : AreaType.Rear);
		if (cols.length == 0) {
			return;
		}
		const between = (((width - (cols[0].rectOnRoof.w / SvgParams.SCALE)) / (cols.length - 1)) * SvgParams.SCALE);
		
		var columns: ColumnProfile[] = isFront ? cols : cols.reverse();

		var startX = SvgParams.START_X;
		columns.forEach(c => {
			c.rectOnRoof.x = startX;
			if (isFront) {
				c.rectOnFront.x = startX;
			}
			startX += between;
		});

		const t = isFront ? ElementType.Front : ElementType.WallProfile;
		const fronts = this.roofElements[t];
		if (fronts.length < 2) {
			return;
		}

		for (var f = 0; f <= fronts.length - 1; f++) {
			var front: FrontProfile | WallProfile = fronts[f];
			var connCol = front.columns[front.columns.length - 1];
			if (!connCol.connector) {
				continue;
			}

			const connMiddle = connCol.rectOnRoof.x + connCol.rectOnRoof.w / 2;
			var leftWidth = Math.abs(connMiddle - connCol.leftProfile.rectOnRoof.x);

			connCol.rightProfile.rectOnRoof.x = connMiddle;
			connCol.leftProfile.rectOnRoof.w = leftWidth;
			connCol.leftProfile.setLength(leftWidth / SvgParams.SCALE);

			if (connCol.rightProfile.tag == Tags.RIGHT_FRONT_TAG || connCol.rightProfile.tag == Tags.RIGHT_REAR_TAG) {
				const rtg = isFront ? Tags.RIGHT_COLUMN_TAG : Tags.RIGHT_REAR_COLUMN_TAG;
				var maxcol = connCol.rightProfile.columns.find(c => c.tag == rtg);
				var maxx = maxcol.rectOnRoof.x + maxcol.rectOnRoof.w;
				connCol.rightProfile.rectOnRoof.w = Math.abs(maxx - connCol.rightProfile.rectOnRoof.x);
				connCol.rightProfile.setLength(connCol.rightProfile.rectOnRoof.w / SvgParams.SCALE);
			}

			if (isFront) {
				connCol.leftProfile.rectOnFront.w = connCol.leftProfile.rectOnRoof.w;
				connCol.rightProfile.rectOnFront.w = connCol.rightProfile.rectOnRoof.w;
			}
			if (!isFront) {
				connCol.leftProfile.rectOnRear.w = connCol.leftProfile.rectOnRoof.w;
				connCol.rightProfile.rectOnRear.w = connCol.rightProfile.rectOnRoof.w;
			}
		}
 	}

	public checkVerticalsExist(types: ElementType[], areaType: AreaType, x1: number, x2: number) {
		var elem: VerticalElement;
		const rc = RectName.get(areaType);
		const r: IRect = {
			x: x1,
			w: x2 - x1,
			y: 0,
			h: 0
		}
		types.every(t => {
			var elems = t == ElementType.GlassWall ?
				this[CollectionName.get(areaType)][t].filter((d: VerticalElement) => d.area == areaType)
				:
				this.verticalElements.filter((d: VerticalElement) => d.area == areaType)
				;
			
			elem = elems.find((d: VerticalElement) => d instanceof GlassWallDiamond ? Common.isConflict(d.getRect(), r) : Common.isConflict(d[rc], r));

			return elem == null;
		});
		return elem;
	}

	public checkVerticalExists(type: ElementType, areaType: AreaType, cols: Space) {
		var elems = this.roofElements[type];
		var elem = elems.find((d: VerticalElement) => d.area == areaType && (d.leftProfile?.id == cols.leftProfile?.id || d.rightProfile?.id == cols.rightProfile?.id));
		return elem;
	}

	public findVerticalsOnBottom(maxy: number) {
		var verts: VerticalElement[] = [];
		for (let area in AreaType) {
			if (area != AreaType.None) {
				var vs = this.verticalElements.filter(v => v.area == area && v.isOnBottom(maxy));
				verts = verts.concat(vs);
			}
		}
		return verts;
	}

	public isVerticalNearColumn(col: ColumnProfile) {
		var found = false;
		RectName.forEach((k , v) => {
			if (col[k] && this.isVerticalNearColumnOnArea(col, v)) {
				found = true;
			}
		});
		return found;
	}

	private isVerticalNearColumnOnArea(col: ColumnProfile, area: AreaType) {
		var verts = this.getVerticals(area);
		const rc = RectName.get(area);		
		const cl = col[rc].x;
		const cr = col[rc].x + col[rc].w;

		var vert = verts.find(v => {
			if (v.leftProfile?.id == col.id) {
				return true;
			}
			if (v.rightProfile?.id == col.id) {
				return true;
			}

			if (v.leftX >= cr && v.leftX - 5 < cr) { // column on left, vertical on right
				return true;
			}
			if (v.rightX <= cl && v.rightX + 5 > cl) { // column on right, vertical on left
				return true;
			}

			return false;
		});
		return vert != null;
	}

	public findVerticalNeib(d: VerticalElement, s: ElementSide): VerticalElement {
		if (s == ElementSide.Left && d.leftProfile instanceof ColumnProfile) {
			return null;
		}

		if (s == ElementSide.Right && d.rightProfile instanceof ColumnProfile) {
			return null;
		}

		var elements: VerticalElement[] = [];
		
		this.verticalElements.forEach(m => {
			if (s == ElementSide.Bottom && m.type == ElementType.GlassWallDiamond) {
				return;
			}
			if (m.area == d.area && m.id != d.id) {
				elements.push(m);
			}
		});

		if (elements.length == 0) {
			return null
		}
		let ret: VerticalElement;
		const leftCol = this.getColumn(d.area, ElementSide.Left, d.leftX);
		const rightCol = this.getColumn(d.area, ElementSide.Right, d.rightX);
		var sameSpace = d.area == AreaType.Left || d.area == AreaType.Right
			? elements 
			: elements.filter(v => this.getColumn(v.area, ElementSide.Left, v.leftX) == leftCol && this.getColumn(v.area, ElementSide.Right, v.rightX) == rightCol);

		switch (s) {
			case ElementSide.Bottom:
				sameSpace = sameSpace
					.filter(v => Math.round(v.topY) >= Math.round(d.bottomY))
					.sort((v1: VerticalElement, v2: VerticalElement) => v1.topY > v2.topY ? 1 : -1);
				ret = sameSpace.find(v => Common.isConflict(d.rect, v.rect));
				break;
			case ElementSide.Top:
				sameSpace = sameSpace
					.filter(v => Math.round(v.bottomY) <= Math.round(d.topY))
					.sort((v1: VerticalElement, v2: VerticalElement) => v1.bottomY < v2.bottomY ? 1 : -1);
				ret = sameSpace.find(v => Common.isConflict(d.rect, v.rect));
				break;
			case ElementSide.Left:
				var sameSpace = sameSpace.sort((v1: VerticalElement, v2: VerticalElement) => v1.leftX < v2.leftX ? 1 : -1);
				ret = sameSpace.find(v => v.rightX <= d.leftX);
				break;
			case ElementSide.Right:
				var sameSpace = sameSpace.sort((v1: VerticalElement, v2: VerticalElement) => v1.leftX > v2.leftX ? 1 : -1);
				ret = sameSpace.find(v => v.leftX >= d.rightX);
				break;
		}

		return ret;
	}

	public setComments(comments: IComment[]) {
		comments.forEach(c => {
			this.commentsChanged.next(c);
			var ec = this.comments.find(m => m.configId == c.configId);
			if (ec) {
				ec.comment = c.comment;
			} else {
				this.comments.push(c);
			}
		});
	}

	private findMarquiseOnGlass(glass: Glass, type: ElementType) {
		return this.roofElements[type].find((m: MarquiseTop) => m.firstGlass.rect.x <= glass.rect.x && m.lastGlass.rect.x >= glass.rect.x);
	}

	findMarquiseBelowGlass(glass: Glass) {
		return this.findMarquiseOnGlass(glass, ElementType.MarquiseTopBottom);
	}

	findMarquiseOverGlass(glass: Glass) {
		return this.findMarquiseOnGlass(glass, ElementType.MarquiseTopUp);
	}

	isAnyInside(area: AreaType) {
		return this.getMarquisesVertical(area).find((v: VerticalElement) => v.layer == LayerLevel.Inside) != null;
	}

	public reverseMarquise(){
		return; // Zaślepione w wersji 1.34. Do odkomentowania i kontynualcji w wersji 1.35
		
		const marqs = this.getMarquisesVertical() as MarquiseVertical[];
		if (marqs.length == 0) {
			return;
		}

		var outsides = marqs.filter(m => m.montage == Montage.Outside);
		var insides = marqs.filter(m => m.montage == Montage.Inside || m.montage == Montage.Middle);

		if (insides.length >= outsides.length) {
			insides.forEach(m => m.isReversed = false);
			outsides.forEach(m => m.isReversed = true);
		} else {
			insides.forEach(m => m.isReversed = true);
			outsides.forEach(m => m.isReversed = false);
		}
	}

}
