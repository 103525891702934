import { Tags } from "./constants/constants";
import { BarParameter, FrontParameter, WizardParameter, ColumnParameter, GlassParameter, GlassWallParameter, RearParameter, FormSideParameter, RoofWindowParameter } from "./models/wizard/wizard-parameter.model";
import { ColumnProfile } from "./models/profiles/column.model";
import { Color, ColorOfPalette } from './models/colors/color.model';
import { IMarquiseInfo } from "./interfaces/IMarquiseInfo.interface";
import { Common } from "./models/common";
import { IDoorInfo } from "./interfaces/IDoorInfo";
import { Profile } from "./models/profiles/profile.model";
import { IWallInfo } from "./interfaces/IWallInfo";
import { ElementType } from "./models/ToolboxModel";
import { IGlassSize } from "./interfaces/IStatics";

export interface IDeflections {
	barDeflection: number;
	frontDeflection: number;
	snowZeroBarDeflection: number;
	snowZeroFrontDeflection: number;
}
export class ProjectTemplate {

	public toCalculate: boolean;
	public created: boolean;
	public productId: string;
	public brandName: string;
	public productName: string;
	public productPhoto: string;
	public version: string;
	public isDraft: boolean;
	public errors: string[];

	public dropAngle: number;
	public windZone: string;

	private _snowZone: string;
	public get snowZone() {
		if (this.useStatics) {
			return this._snowZone;
		} else {
			return "0";
		}
	}
	public set snowZone(v: string) {
		this._snowZone = v;
	}

	public width: number;
	public depth: number;
	public foundationId: string;
	public useStatics: boolean;

	public backHeight: number;
	public barsCount: number;
	public staticBarsCount: number;

	public barLength: number;
	public glassLength: number;
	public deckelLength: number;
	public columnLength: number;
	public frontHeight: number;
	public rearReinforcementDepth: number;

	public formTops: WizardParameter[] = [];
	public formTopId: string;
	public glassId: string;

	public minWidth: number;
	public maxWidth: number;
	public minBackHeight: number;
	public maxBackHeight: number;
	public minDepth: number;
	public maxDepth: number;
	public minDropAngle: number;
	public maxDropAngle: number;

	public fastenings: WizardParameter[] = [];
	public fasteningId: string;

	public deflections: IDeflections;

	public fronts: FrontParameter[] = [];
	private _frontId: string;
	public get frontId() {
		return this._frontId;
	};
	public set frontId(v: string) {
		this._frontId = v;
		const front = this.fronts.find(c => c.id == v);
		this.glasses.forEach(g => {
			g.statics.paddingBottom = Math.abs(front.glassLengthCorrection);
			g.statics.paddingTop = 0;
		});
	};

	public bars: BarParameter[] = [];
	public barId: string;

	public columns: ColumnParameter[] = [];
	public frontColumnId: string;

	public rearColumnId: string;

	public rears: RearParameter[] = [];
	public rearId: string;
	
	public formSides: FormSideParameter[] = [];
	public formSideId: string;

	public colors: Color[];
	public paletteColors: ColorOfPalette[];

	public glasses: GlassParameter[] = [];
	public wallGlasses: GlassWallParameter[] = [];
	public diamondGlasses: GlassWallParameter[] = [];
	public hiddenExtras: WizardParameter[] = [];
	public extras: WizardParameter[] = [];
	public wallGutters: WizardParameter[] = [];
	public roofWindows: RoofWindowParameter[] = [];
	public roofAddons: RoofWindowParameter[] = [];
	public marquises: IMarquiseInfo[];
	public doors: IDoorInfo[];
	public walls: IWallInfo[];
	public remotes: WizardParameter[] = [];

	public connectorDef: WizardParameter;
	public hydros: WizardParameter[];

	public elongLeft: number = 0;
	public elongRight: number = 0;

	private _isStandAlone: boolean = undefined;
	public get isStandalone() {
		if (this._isStandAlone === undefined && this.formSides && this.formSides.length > 0) {
			this._isStandAlone = this.formSides.find(s => s.id == this.formSideId)?.isStandalone
		}
		return this._isStandAlone;
	}
	public setStandAlone(value: boolean) {
		this._isStandAlone = value;
	}

	public get surface(): number {
		return Common.round(this.width * this.depth);
	}
	constructor(fromCRM: boolean) {
		this.toCalculate = !fromCRM;
		this.useStatics = true;
	}

	public getFrontColumnSize() {
		return this.columns.find(c => c.id == this.frontColumnId).size;
	}

	public getRearColumnSize() {
		return this.columns.find(c => c.id == this.rearColumnId).size;
	}

	public getFrontSize() {
		return this.fronts.find(c => c.id == this.frontId).size;
	}

	public getBarSize() {
		return this.bars.find(c => c.id == this.barId).size;
	}

	public getRearSize() {
		return this.rears.find(c => c.id == this.rearId).size;
	}

	public getColumnsForProfile(col: ColumnProfile) {
		return col.isFront ? this.getColumnsForFront(col) : this.getColumnsForRear(col)
	}
	public getColumnsForFront(col: ColumnProfile) {
		const external = col.tag == Tags.LEFT_COLUMN_TAG || col.tag == Tags.RIGHT_COLUMN_TAG;
		const front = this.getDefaultFront();
		return this.columns.filter(c => front.columns.find(fc => fc.id == c.id && c.external && external || c.internal && !external));
	}

	public getColumnsForRear(col: ColumnProfile) {
		const external = col.tag == Tags.LEFT_REAR_COLUMN_TAG || col.tag == Tags.RIGHT_REAR_COLUMN_TAG;
		const rear = this.getDefaultRear();
		return this.columns.filter(c => rear.columns.find(fc => fc.id == c.id && c.external && external || c.internal && !external));
	}

	public getDefaultFront() {
		return this.fronts.find(f => f.id == this.frontId);
	}

	public getDefaultRear() {
		return this.rears.find(f => f.id == this.rearId);
	}
	
	public getDefaultGlass() {
		return this.glasses.find(f => f.id == this.glassId);
	}

	public getDefaultBar() {
		return this.bars.find(f => f.id == this.barId);
	}

	public getDefaultFoundationForColumn(configId: string) {
		var founDef: WizardParameter = null;
		const colDef = this.columns.find(c => c.id == configId);
		if (colDef.extras.length == 0) {
			return null;
		}
		if (this.foundationId && colDef.extras.find(e => e.extraId == this.foundationId)) {
			return this.hiddenExtras.find(f => f.id == this.foundationId);
		}
		colDef.extras.forEach(e => {
			var extra = this.hiddenExtras.find(f => f.id == e.extraId);
			if (extra.isDefault) {
				founDef = extra;
			}
		});
		if (founDef == null) {
			founDef = this.hiddenExtras.find(f => f.id == colDef.extras[0].extraId)
		}
		return founDef;
	}

	public getHydroIsolations() {
		this.hydros = [];
		this.columns.forEach(colDef => {
			colDef.extras.forEach(e => {
				var extra = this.hiddenExtras.find(f => f.id == e.extraId);
				extra.extras.forEach(ie => {
					var iextra = this.extras.find(f => f.id == ie.extraId);
					if (iextra?.size && iextra.size?.height > 0 && !this.hydros.find(h => h.id == iextra.id)) {
						this.hydros.push(iextra);
					}
				});

			});
		});
	}

	public getOnHoldersFoundation(configId: string) {
		var founDef: WizardParameter = null;
		const colDef = this.columns.find(c => c.id == configId);
		if (colDef.extras.length == 0) {
			return null;
		}
		colDef.extras.forEach(e => {
			var extra = this.hiddenExtras.find(f => f.id == e.extraId);
			if (extra.size == null || extra.size.height < 0) {
				founDef = extra;
			}
		});
		return founDef;
	}

	public getDefaultFoundation() {
		if (!this.foundationId) {
			this.foundationId = this.getDefaultFoundationForColumn(this.frontColumnId)?.id;
		}
		return this.hiddenExtras.find(f => f.id == this.foundationId);
	}

	public hasFoundation(configId: string, foundationId: string) {
		const colDef = this.columns.find(c => c.id == configId);
		return colDef.extras.find(e => e.extraId == foundationId);
	}

	private getGlassSize(configId: string): IGlassSize {
		const g = this.glasses.find(f => f.id == configId);
		var gs = g.statics.glassSizes.find(s => s.snowZone == this.snowZone) ?? g.statics.glassSizes[0];
		if (!gs) {
			gs = { snowZone: "", minWidth: 34, maxWidth: 850 };
		}
		return gs;
	}

	public maxGlassWidth(configId: string) {
		return this.getGlassSize(configId).maxWidth;
	}

	public minGlassWidth(configId: string) {
		return this.getGlassSize(configId).minWidth;
	}

}
