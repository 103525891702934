import { IAreaService } from "../../interfaces/IAreaService";
import { AreaType } from "../../interfaces/IAreaType";
import { AnySide } from "../../interfaces/IElementSide";
import { IPoint } from "../../interfaces/IPoint";
import { ProjectService } from "../../services/project.service";
import { ElementType } from "../ToolboxModel";
import { FrameProfile } from "../profiles/frame.model";
import { Profile } from "../profiles/profile.model";
import { Space } from "../space";
import { WallCreator } from "../walls/wall-creator";
import { Wall } from "../walls/wall.model";
import { ISize } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";
import { FrameStoreModel } from "./frame-store.model";

export class WallStoreModel extends ElementStoreModel {

	size: ISize;	
	shape: IPoint[];
	area: AreaType;

	topProfile: FrameStoreModel;
	leftProfile: FrameStoreModel;
	rightProfile: FrameStoreModel;
	bottomProfile: FrameStoreModel;

	leftovers: AnySide;
	muntins: FrameStoreModel[];

	constructor(d: Wall) {
		super(d.id, d.def.id);

		this.area = d.area;
		this.shape = d.shape.getPoints();
		this.leftovers = d.leftovers;

		switch (d.area) {
			case AreaType.Front:
			case AreaType.Rear:
					this.size = { width: Math.round(d.width), height: Math.round(d.height), depth: undefined };
				break;
			case AreaType.Left:
			case AreaType.Right:
				this.size = { width: undefined, height: Math.round(d.height), depth: Math.round(d.width) };
				break;
		}

		this.topProfile = new FrameStoreModel(d.topProfile);
		this.bottomProfile = new FrameStoreModel(d.bottomProfile);

		if (d.leftProfile.type == ElementType.Frame) { // fake frame profile
			this.leftProfile = new FrameStoreModel(d.leftProfile as FrameProfile);
		} else { // column profile
			this.leftProfile = new FrameStoreModel(null);
			this.leftProfile.id = d.leftProfile.id;
			this.leftProfile.configId = d.leftProfile.configId;
		}

		if (d.rightProfile.type == ElementType.Frame) { // fake frame profile
			this.rightProfile = new FrameStoreModel(d.rightProfile as FrameProfile);
		} else { // column profile
			this.rightProfile = new FrameStoreModel(null);
			this.rightProfile.id = d.rightProfile.id;
			this.rightProfile.configId = d.rightProfile.configId;
		}
		
		this.muntins = [];
		d.muntins.forEach(m => {
			this.muntins.push(new FrameStoreModel(m));
		});
	}

	public static restore(d: WallStoreModel, projectService: ProjectService, srv: IAreaService) {
		var def = projectService.template.walls.find(r => r.id == d.configId);

		var topProfile = FrameStoreModel.restore(d.topProfile, projectService, def.frame.id);
		var bottomProfile = FrameStoreModel.restore(d.bottomProfile, projectService, def.frame.id);
		let leftProfile: Profile;
		let rightProfile: Profile;

		if (d.leftProfile.rect) { // Frame profile			
			leftProfile = FrameStoreModel.restore(d.leftProfile, projectService, def.frame.id);
		} else { // column
			leftProfile = projectService.profileService.roofElements[ElementType.Column].find(c => c.id == d.leftProfile.id);
		}

		if (d.rightProfile.rect) { // Frame profile
			rightProfile = FrameStoreModel.restore(d.rightProfile, projectService, def.frame.id);
		} else { // column
			rightProfile = projectService.profileService.roofElements[ElementType.Column].find(c => c.id == d.rightProfile.id);
		}


		var creator = new WallCreator(projectService, new Space(leftProfile, rightProfile, topProfile, bottomProfile), d.area, srv);

		var wall = creator.createWall(def, d);

		projectService.profileService.addVertical(wall, false);
		wall.leftovers = d.leftovers;
		wall.height = wall.height;
		wall.locationX = wall.locationX;
		if (!wall.shape.isDiamond) {
			wall.locationY = wall.locationY;
		}
		wall.width = wall.width;
	}

}