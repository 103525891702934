<div>

  <kendo-dialog (close)="apiError = null" [minWidth]="350" [width]="550" *ngIf="apiError">

    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        <span class="k-icon k-i-exclamation-circle"></span> Application error
      </div>
    </kendo-dialog-titlebar>

    <p style="margin-top: 10px; text-align: justify;">
      <span i18n="Dialogs|Message">Message</span>: {{ apiError.message }}
    </p>
    <div *ngIf="showDetails">
      <p>
        <span i18n="Dialogs|Module">Module</span>: {{ apiError.source }}
      </p>
      <p>
        <span i18n="Dialogs|HTTP status">HTTP status</span>: {{ apiError.httpCode }}
      </p>
    </div>
    <p>
      <span i18n="Dialogs|Exception Id">Exception Id</span>: {{ apiError.exceptionId }}
    </p>

    <kendo-dialog-actions>
      <button kendoButton (click)="apiError = null" primary="true" i18n="Dialogs|'Close' button">Close</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog (close)="cfgError = null" [minWidth]="350" [width]="550" *ngIf="cfgError">

        <kendo-dialog-titlebar>
          <div style="font-size: 18px;">
            <span class="k-icon k-i-exclamation-circle"></span>&nbsp;
              <span *ngIf="cfgError.clear" i18n="Dialogs|'System error' title">System error</span>
              <span *ngIf="!cfgError.clear" i18n="Dialogs|'System information' title">System information</span>
          </div>
        </kendo-dialog-titlebar>

    <p style="margin-top: 14px; text-align: justify;">
      {{ cfgError.message }}
    </p>

    <kendo-dialog-actions> <!-- [layout]="'normal'" -->
      <button kendoButton (click)="cfgErrorClose()" primary="true" i18n="Dialogs|'Close' button">Close</button>
    </kendo-dialog-actions>
  </kendo-dialog>

</div>