import { ElementType } from '../ToolboxModel';
import { IRect } from '../../interfaces/IRect';
import { IMarquiseEngine } from '../../interfaces/IMarquiseEngine.interface';
import { SvgParams } from '../../constants/constants';
import { IMarquiseInfo } from '../../interfaces/IMarquiseInfo.interface';
import { v4 as uuidv4 } from 'uuid';
import { Marquise } from './marquise.model';
import { ElementSide } from '../../interfaces/IElementSide';
import { MarquiseVertical } from './marquise-vertical.model';

export class MarquisesEngine {
	public id = `MarquisesEngine_${uuidv4()}`;

	public color: string;
	public configId: string;
	public name: string;
	public size: { width: number, height: number, depth: number };
	public rect: IRect;
	public location: ElementSide = ElementSide.Left;

	constructor(public master: Marquise | MarquiseVertical) {}

	setNewEngineParameters(newEngine: IMarquiseEngine) {
		this.configId = newEngine.id;
		this.color = newEngine.color;
		this.name = newEngine.name;
		this.size = newEngine.size;
		this.rect = {
			x: this.rect.x,
			y: this.rect.y,
			w: this.size.width * SvgParams.SCALE,
			h: this.size.depth * SvgParams.SCALE,
		}
	}

	setDefaultEngineParams(currentMarquiseInfo: IMarquiseInfo) {
		const defEngine = currentMarquiseInfo.engines.find(e => e.isDefault);
		this.configId = defEngine.id;
		this.color = defEngine.color;
		this.name = defEngine.name;
		this.size = defEngine.size;
	}

}
