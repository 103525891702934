<div class="row" *ngIf="current.cuttingable">
	<div class="col col-4">
		<span i18n="Cutting on site|Title">Cutting on site</span>:
	</div>
	<div class="col col-8">
		<input type="checkbox" size="none" style="width: 20px; height: 20px;" kendoCheckBox [(ngModel)]="cuttingOnSite" [disabled]="projectService.readOnly || disabled"/>
		&nbsp;
		<button kendoButton (click)="openDialog()" [disabled]="projectService.readOnly || disabled"><span i18n="Cutting on site|'Apply for others' button">Apply for others</span></button>
	</div>
</div>
<app-confirm-dialog [dialogOpened]="dialogOpened" [dialogMessage]="dialogMessage" (dialogAction)="onDialogAction($event)"></app-confirm-dialog>