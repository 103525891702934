import { SvgParams } from "../../constants/constants";
import { IWallInfo } from "../../interfaces/IWallInfo";
import { WallStoreModel } from "../project-store/wall-store.model";
import { Wall } from "./wall.model";
import { VerticalCreator } from "../vertical-creator";
import { Shape } from "../shape";
import { ProjectService } from "../../services/project.service";
import { Space } from "../space";
import { IAreaService } from "../../interfaces/IAreaService";

export class WallCreator extends VerticalCreator {

	public static fromInstance(projectService: ProjectService, sideService: IAreaService, wall: Wall) {
		return new WallCreator(projectService, new Space(wall.leftProfile, wall.rightProfile), wall.area, sideService);
	}

	protected get maxWidthValidMsg(): string {
		return $localize`:Wall|Validation message:Width of the new wall is greater than maximum allowable!`;
	}
	protected get maxHeightValidMsg(): string {
		return $localize`:Wall|Validation message:Height of the new wall is greater than minimum allowable!`;
	}
	protected get minWidthValidMsg(): string {
		return $localize`:Wall|Validation message:Width of the new wall is less than minimum allowable!`;
	}
	protected get minHeightValidMsg(): string {
		return $localize`:Wall|Validation message:Height of the new wall is less than minimum allowable!`;
	}
	
	public createDiamondWall(def: IWallInfo, diamond: Shape, pattern: WallStoreModel | Wall = null) {
		var d = new Wall(def, this.projectService, this.sideService, this.area, this.leftProfile, this.rightProfile, diamond);
		return d;
	}

	public createWall(def: IWallInfo, pattern: WallStoreModel | Wall = null, startPosY: number = null) {

		const x = this.getX1();
		const w = this.getWidth();
		const da = this.projectService.template.dropAngle;

		let shape: Shape;
		if (pattern) {
			shape = pattern instanceof Wall ? pattern.shape : new Shape(pattern.shape, da);
		} else {
			const y = this.getY(startPosY);
			const h = this.getHeight(startPosY);

			shape = Shape.fromRect({
				x,
				y,
				w: w * SvgParams.SCALE,
				h
			}, da);

			if (this.bottomProfile?.rect?.h) {
				this.bottomProfile.rect.h = Math.round(this.bottomProfile.rect.h);
			}
		}

		var d = new Wall(def, this.projectService, this.sideService, this.area, this.leftProfile, this.rightProfile, shape);
		return d;
	}

	public setHeight(d: Wall, v: number) {
		d.height = v;
		d.adjustSurround();
		this.projectService.emitChange(); 
	}

	public setWidth(d: Wall, v: number) {
		d.width = v;
		d.adjustSurround();
		this.projectService.emitChange();
	}

	public setLocationX(d: Wall, v: number) {
		d.locationX = v;
		d.adjustSurround();
		this.projectService.emitChange();
	}

	public setLocationY(d: Wall, v: number) {
		d.locationY = v;
		d.adjustSurround();
		this.projectService.emitChange();
	}
}
