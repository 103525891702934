import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { AreaType } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from '../../template';
import { GlassBunch } from "src/app/_core/models/glasses/glassBunch";
import { ElementSide } from "src/app/_core/interfaces/IElementSide";
import { Glass } from "../glasses/glass.model";
import { Common } from "../common";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class BarProfile extends Profile {

  get name(): string {
    return $localize`:Common|Bar:Bar`;
  }

  get type() {
    return ElementType.Bar;
  }
  
  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  get rightX() {
    return this.rectOnRoof.x + this.rectOnRoof.w;
  }

  get leftX() {
    return this.rectOnRoof.x;
  }

  get weight(){
    return this._weight;
  }

  getSVGLength() {
    return this.rectOnRoof.h / SvgParams.SCALE;
  }

  private glasses: GlassBunch;
  private _weight: number;

  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    template: ProjectTemplate,
    length: number,
    orientation: Orientation,
    area: AreaType,
    configId: string,
    type: ElementType = ElementType.Bar
  ) {

    let profDef;
    if (type == ElementType.Bar) {
      profDef = template.bars.find(b => b.id == configId);
    } else {
      profDef = template.connectorDef;
    }

    super(orientation, profDef.size, profDef.color);

    this.id = this.type + "_" + uuidv4();
    this.configId = configId;
    this._weight = profDef.weight;

    this.length = template.barLength;;
    this.initPosition = area;
    this.glasses = new GlassBunch();

    if (area === AreaType.None) {
      area = currentArea;
    }

    const w = this.orientation === Orientation.Vertical ? this.width : length;
    const h = this.orientation === Orientation.Vertical ? length : this.width;

    this['rectOn' + area] = {
      x: Common.round(x),
      y: Common.round(y),
      w: Math.round(w * SvgParams.SCALE),
      h: Math.round(h * SvgParams.SCALE)
    };
  }

  private firstOrDefault(collection: Glass[]) {
    return collection && collection.length > 0 ? collection[0] : null;
  }

  // To be refactored - taking into account many glasses on on side.

  public getGlasses(side: ElementSide): Glass[] {
    return this.glasses.getGlasses(side);
  }

  public addGlass(side: ElementSide, glass: Glass) {
    return this.glasses.assignGlass(side, glass);
  }

  public removeGlass(side: ElementSide, glass: Glass) {
    return this.glasses.removeGlass(side, glass);
  }

  get rightGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Right));
  }
  set rightGlass(glass: Glass) {
    this.glasses.clearOnSide(ElementSide.Right);
    this.glasses.assignGlass(ElementSide.Right, glass);
  }

  get leftGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Left));
  }
  set leftGlass(glass: Glass) {
    this.glasses.clearOnSide(ElementSide.Left);
    this.glasses.assignGlass(ElementSide.Left, glass);
  }

  get topGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Top));
  }
  set topGlass(glass: Glass) {
    this.glasses.assignGlass(ElementSide.Top, glass);
  }

  get bottomGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Bottom));
  }
  set bottomGlass(glass: Glass) {
    this.glasses.assignGlass(ElementSide.Bottom, glass);
  }

}
