//#region Imports

import { EventEmitter, Injectable } from '@angular/core';
import { SvgParams, Tags } from '../constants/constants';
import { Glass } from '../models/glasses/glass.model';
import { GlassWall } from '../models/glasses/glassWall.model';
import { GlassWallDiamond } from '../models/glasses/glassWallDiamond.model';
import { MarquiseTop } from '../models/marquises/marquise-top.model';
import { BarProfile } from '../models/profiles/bar.model';
import { ColumnProfile } from '../models/profiles/column.model';
import { FrontProfile } from '../models/profiles/front.model';
import { SideFinishProfile } from '../models/profiles/side-finish.model';
import { WallProfile } from '../models/profiles/wall.model';
import { BarStoreModel } from '../models/project-store/bar-store.model';
import { ColumnStoreModel } from '../models/project-store/column-store.model';
import { GlassWallDiamondStoreModel } from '../models/project-store/glasswalldiamond-store.model';
import { ElementStoreModel } from '../models/project-store/element-store.model';
import { FrontStoreModel } from '../models/project-store/front-store.model';
import { GlassStoreModel } from '../models/project-store/glass-store.model';
import { GlassWallStoreModel } from '../models/project-store/glasswall-store.model';
import { MarquiseStoreModel } from '../models/project-store/marquise-store.model';
import { ProjectStoreModel } from '../models/project-store/project-store.model';
import { RearStoreModel } from '../models/project-store/rear-store.model';
import { SideFinishStoreModel } from '../models/project-store/sidefinish-store.model';
import { ElementType } from '../models/ToolboxModel';
import { ProjectTemplate } from '../template';
import { ApiService } from './api.service';
import { LeftSideService } from './areas/left-side.service';
import { RightSideService } from './areas/right-side.service';
import { RoofService } from './areas/roof.service';
import { ProfileService } from './profile.service';
import { ProjectService } from './project.service';
import { RoofWindowStoreModel } from '../models/project-store/roofwindow-store.model';
import { RoofWindow } from '../models/glasses/roofWindow.model';
import { LedSpotStoreModel } from '../models/project-store/ledspot-store.model';
import { LedStripeStoreModel } from '../models/project-store/ledstripe-store.model';
import { LedStripe } from '../models/profiles/led-stripe.model';
import { ExtrasStoreModel } from '../models/project-store/extras-store.model';
import { ExtraOption } from '../models/extra-option.model';
import { ProfileConnector } from '../models/profiles/connector.model';
import { ApiConfigCodes, IMigrationInput, IMigrationItem, IMigrationResult, IOption } from '../interfaces/IStorageCommon';
import { Color } from '../models/colors/color.model';
import { NotifyService } from './notify.service';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { GutterStoreModel } from '../models/project-store/gutter-store.model';
import { GutterProfile } from '../models/profiles/gutter.model';
import { StateService } from './state.service';
import { GlassWallsStoreModelBase } from '../models/project-store/glasswalllbase-store-model';
import { DoorStoreModel } from '../models/project-store/door-store.model';
import { Door } from '../models/doors/door.model';
import { AreaType } from '../interfaces/IAreaType';
import { IPoint } from '../interfaces/IPoint';
import { ILockVariant } from "../interfaces/ILockVariant";
import variants from '../models/doors/_lock-variants.json';
import { LockType } from '../interfaces/IDoorInfo';
import { WallStoreModel } from '../models/project-store/wall-store.model';
import { Wall } from '../models/walls/wall.model';
import { FrontService } from './areas/front.service';
import { RearService } from './areas/rear.service';
import { BehaviorSubject } from 'rxjs';


//#endregion

@Injectable({
	providedIn: 'root',
})
export class StorageService {

	public migrationErrors: EventEmitter<IMigrationResult> = new EventEmitter<IMigrationResult>();
	public projectRestored: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private profileService: ProfileService,
		private projectService: ProjectService,
		private roofService: RoofService,
		private frontService: FrontService,
		private rearService: RearService,
		private leftService: LeftSideService,
		private rightService: RightSideService,
		private api: ApiService,
		private notif: NotifyService,
		private stateService: StateService) {}

	public storeProject(): ProjectStoreModel {

		if (this.profileService.roofElements[ElementType.Front].length == 0) {
			return;
		}
		
		var stor = new ProjectStoreModel(this.projectService);

		stor.extras = this.collectExtras();

		stor.glasses = this.collectGlasses();
		stor.bars = this.collectBars();
		stor.connectors = this.collectConnectors();
		stor.sideFins = this.collectSideFinishes();
		stor.columns = this.collectColumns();
		stor.fronts = this.collectFronts();
		stor.gutters = this.collectGutters();
		stor.rears = this.collectRears();
		stor.marquises = this.collectMarquises();
		stor.doors = this.collectDoors()
		stor.walls = this.collectWalls()
		stor.glassWalls = this.collectGlassWalls();
		stor.glassWallDiamonds = this.collectGlassWallDiamonds();
		stor.roofWindows = this.collectRoofWindows(ElementType.RoofWindow);
		stor.roofGutters = this.collectRoofWindows(ElementType.RoofGutter);
		if (this.projectService.ledPattern) {
			stor.ledSpot = new LedSpotStoreModel(this.projectService.ledPattern, this.profileService.roofElements[ElementType.Led])
		}
		stor.ledStripes = this.collectLedStripes();

		this.setGlassOptions(stor.options, stor.glasses);
		this.setOptions(stor.options, stor.bars);
		this.setOptions(stor.options, stor.connectors);
		this.setOptions(stor.options, stor.sideFins);
		this.setColumnOptions(stor.options, stor.extras, stor.columns);

		// https://trello.com/c/cziuCtQX
		// this.setOptions(stor.options, stor.fronts);
		this.setOption(stor.options, stor.fronts[0].configId, 1);

		this.setRearOptions(stor.options, stor.rears);
		this.setGlassWallOptions(stor.options, stor.glassWalls);
		this.setGlassWallOptions(stor.options, stor.glassWallDiamonds);
		this.setOptions(stor.options, stor.roofWindows);
		this.setOptions(stor.options, stor.roofGutters);
		this.setDoorsOptions(stor.options, stor.extras, stor.doors);
		this.setWallsOptions(stor.options, stor.walls);
		this.setMarquisesOptions(stor.options, stor.marquises);
		this.setLedStripesOptions(stor.options, stor.ledStripes);
		this.setExtrasOptions(stor.options, stor.extras);		

		if (stor.ledSpot?.patternId) {
			this.setOption(stor.options, stor.ledSpot.configId, 1, stor.ledSpot?.patternId);
		}
		this.setOption(stor.options, stor.properties.formSideConfigId, 1);
		this.setOption(stor.options, stor.properties.fasteningConfigId, 1);
		this.setOption(stor.options, stor.properties.formTopConfigId, 1);

		const c = this.projectService.selectedColor as Color;
		if (c && c.type == "COLOR") {
			this.setOption(stor.options, c.id, 1);
		} else {
			const cl = this.projectService.template.colors.find(l => l.paletteId == c.paletteId);
			this.setOption(stor.options, cl.id, 1, c.id);
		}
		if (stor.properties.remoteId) {
			this.setOption(stor.options, stor.properties.remoteId, 1);
		}

		return stor;
	}

	//#region storeProject functions

	private setOption(options: IOption[], configId: string, cnt: number = 1, linkedDataId = undefined) {
		var o = options.find(p => p.configId == configId);
		if (o) {
			o.count += cnt;
		} else {
			options.push({ configId: configId, count: cnt, linkedDataId: linkedDataId });
		}
	}

	private setOptions(options: IOption[], collection: ElementStoreModel[]) {
		collection.forEach(c => {
			this.setOption(options, c.configId);
		});
	}

	private setGlassWallOptions(options: IOption[], collection: GlassWallsStoreModelBase[]) {
		collection.forEach((c: GlassWallsStoreModelBase) => {
			this.setOption(options, c.configId);
			let frames = ['topFrame', 'bottomFrame', 'leftFrame', 'rightFrame'];
			frames.forEach(f => {
				if (c[f]) {
					this.setOption(options, c[f]["configId"]);
				}
			});
		});
	}

	private setColumnOptions(options: IOption[], extras: ExtrasStoreModel[], collection: ColumnStoreModel[]) {
		collection.forEach((c: ColumnStoreModel) => {
			this.setOption(options, c.configId);
			if (c.installation) {
				this.setOption(options, c.installation);
			}
			if (c.outflow) {
				const col: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((col: ColumnProfile) => col.id == c.id);
				this.setOption(options, col.outflowId);
			}
			c.extras.forEach(e => {
				this.setOption(extras, e);
			});
		});
	}

	private setRearOptions(options: IOption[], collection: RearStoreModel[]) {		
		var cnt = 1;
		collection.forEach(c => {
			this.setOption(options, c.configId, cnt);
			cnt = 0; // https://trello.com/c/cziuCtQX
			c.wallGutters.forEach(w => {
				this.setOption(options, w.configId);
			});
		});
	}

	private setGlassOptions(options: IOption[], collection: GlassStoreModel[]) {
		collection.forEach(c => {
			this.setOption(options, c.configId);
			if (c.ventilator) {
				this.setOption(options, c.ventilator.configId);
			}
		});
	}

	private setWallsOptions(options: IOption[], collection: WallStoreModel[]) {
		this.setOptions(options, collection);
		collection.forEach(c => {
			if (c.topProfile != null) { //} && c.topProfile.rect.h > 0) {
				this.setOption(options, c.topProfile.configId);
			}
			if (c.bottomProfile != null) { //} && c.bottomProfile.rect.h > 0) {
				this.setOption(options, c.bottomProfile.configId);
			}
			if (c.leftProfile != null && c.leftProfile.rect) { //} && c.leftProfile.rect?.w > 0) {
				this.setOption(options, c.leftProfile.configId);
			}
			if (c.rightProfile != null && c.rightProfile.rect) { //} && c.rightProfile.rect?.w > 0) {
				this.setOption(options, c.rightProfile.configId);
			}
		});
	}

	private setMarquisesOptions(options: IOption[], collection: MarquiseStoreModel[]) {
		this.setOptions(options, collection);
		collection.forEach(c => {
			this.setOption(options, c.handlerId);
			this.setOption(options, c.engine.configId);
		});
	}

	private setLockOption(options: IOption[], doorConfigId: string, type: LockType) {
		if (!type) {
			return;
		}
		const door = this.projectService.template.doors.find(d => d.id == doorConfigId);
		const lock = door.locks.find(l => l.lockType == type);
		this.setOption(options, lock.id);
	}

	private setDoorsOptions(options: IOption[], extras: ExtrasStoreModel[], collection: DoorStoreModel[]) {

		var lv = variants as ILockVariant[];

		this.setOptions(options, collection);
		collection.forEach(c => {
			this.setOption(options, c.glassId);
			var dv = lv.find(v => v.id == c.lockVariant);
			this.setLockOption(options, c.configId, dv.leftExternal);
			this.setLockOption(options, c.configId, dv.leftInternal);
			this.setLockOption(options, c.configId, dv.middle);
			this.setLockOption(options, c.configId, dv.rightInternal);
			this.setLockOption(options, c.configId, dv.rightExternal);

			c.extras.forEach(e => {
				if (this.projectService.template.doors.find(d => d.foundationId == e)) {
					var cnt = (c.size.width ?? c.size.depth) / 1000; // milimeters -> meters
					if (cnt > Math.floor(cnt)) {
						cnt = Math.floor(cnt + 1);
					} 
					this.setOption(options, e, cnt);
				} else {
					this.setOption(extras, e);
				}
			});
			if (c.topFrame != null) {
				this.setOption(options, c.topFrame.configId);
			}
			if (c.leftProfile != null && c.leftProfile.rect && c.leftProfile.rect?.w > 0) {
				this.setOption(options, c.leftProfile.configId);
			}
			if (c.rightProfile != null && c.rightProfile.rect && c.rightProfile.rect?.w > 0) {
				this.setOption(options, c.rightProfile.configId);
			}
		});
	}

	private setLedStripesOptions(options: IOption[], collection: LedStripeStoreModel[]) {
		const pce = [...new Set(collection.map(item => item.stripeId))].length;
		if (pce == 0) {
			return;
		}
		this.setOption(options, collection[0].configId, pce);
	}

	private setExtrasOptions(options: IOption[], collection: ExtrasStoreModel[]) {
		collection.forEach(c => {
			this.setOption(options, c.configId, c.count);
		});
	}


	private collectGlasses() {
		var gls: GlassStoreModel[] = [];
		this.profileService.roofElements[ElementType.Glass].forEach((g: Glass) => {
			gls.push(new GlassStoreModel(g));
		});
		return gls;
	}

	private collectBars() {
		var bars: BarStoreModel[] = [];
		this.profileService.roofElements[ElementType.Bar].forEach((g: BarProfile) => {
			bars.push(new BarStoreModel(g));
		});
		return bars;
	}

	private collectConnectors() {
		var bars: BarStoreModel[] = [];
		this.profileService.roofElements[ElementType.ProfileConnector].forEach((g: ProfileConnector) => {
			bars.push(new BarStoreModel(g));
		});
		return bars;
	}

	private collectExtras() {
		var ex: ExtrasStoreModel[] = [];
		this.profileService.extraElements.forEach((g: ExtraOption) => {
			ex.push(new ExtrasStoreModel(g));
		});
		return ex;
	}

	private collectSideFinishes() {
		var sfs: SideFinishStoreModel[] = [];
		this.profileService.roofElements[ElementType.SideFinish].forEach((g: SideFinishProfile) => {
			sfs.push(new SideFinishStoreModel(g));
		});
		return sfs;
	}

	private collectColumns() {
		var cols: ColumnStoreModel[] = [];
		this.profileService.roofElements[ElementType.Column].forEach((c: ColumnProfile) => {
			cols.push(new ColumnStoreModel(c));
		});
		return cols;
	}

	private collectFronts() {
		var fronts: FrontStoreModel[] = [];
		this.profileService.roofElements[ElementType.Front].forEach((c: FrontProfile) => {
			fronts.push(new FrontStoreModel(c));
		});
		return fronts;
	}

	private collectGutters() {
		var gutters: GutterStoreModel[] = [];
		this.profileService.roofElements[ElementType.Gutter].forEach((c: GutterProfile) => {
			gutters.push(new GutterStoreModel(c));
		});
		return gutters;
	}

	private collectRears() {
		var rears: RearStoreModel[] = [];
		this.profileService.roofElements[ElementType.WallProfile].forEach((c: WallProfile) => {
			rears.push(new RearStoreModel(c));
		});
		return rears;
	}

	private collectMarquises() {
		var marq: MarquiseStoreModel[] = [];
		this.profileService.roofElements[ElementType.MarquiseTopUp].forEach((m: MarquiseTop) => {
			marq.push(new MarquiseStoreModel(m, this.projectService));
		});
		this.profileService.roofElements[ElementType.MarquiseTopBottom].forEach((m: MarquiseTop) => {
			marq.push(new MarquiseStoreModel(m, this.projectService));
		});
		this.profileService.getMarquisesVertical().forEach((m: MarquiseVertical) => {
			marq.push(new MarquiseStoreModel(m, this.projectService));
		});
		return marq;
	}

	private collectDoors() {
		var doors: DoorStoreModel[] = [];
		this.profileService.getDoors().forEach((d: Door) => {
			doors.push(new DoorStoreModel(d));
		});
		return doors;
	}

	private collectWalls() {
		var walls: WallStoreModel[] = [];
		this.profileService.getWalls().forEach((d: Wall) => {
			walls.push(new WallStoreModel(d));
		});
		return walls;
	}

	private collectGlassWalls() {
		var walls: GlassWallStoreModel[] = [];
		this.profileService.getGetGlassWalls().forEach((w: GlassWall) => {
			walls.push(new GlassWallStoreModel(w, this.projectService));
		});
		return walls;
	}

	private collectGlassWallDiamonds() {
		var walls: GlassWallDiamondStoreModel[] = [];
		this.profileService.getGetDiamonds().forEach((w: GlassWallDiamond) => {
			walls.push(new GlassWallDiamondStoreModel(w, this.projectService));
		});
		return walls;
	}

	private collectRoofWindows(type: ElementType) {
		var windows: RoofWindowStoreModel[] = [];
		this.profileService.roofElements[type].forEach((m: RoofWindow) => {
			windows.push(new RoofWindowStoreModel(m));
		});
		return windows;
	}

	private collectLedStripes() {
		var stripes: LedStripeStoreModel[] = [];
		this.profileService.roofElements[ElementType.LedStripe].forEach((m: LedStripe) => {
			stripes.push(new LedStripeStoreModel(m));
		});
		return stripes;
	}
	

	//#endregion

	public restore(model: ProjectStoreModel, images: boolean, emitChanges: boolean = false) {

		ProjectStoreModel.initProject(model, this.api, this.projectService, this.profileService).subscribe(l => {
			this.projectService.template.columnLength = l;
	
			this.roofService.calculatePoints();
			this.frontService.calculatePoints();
			this.rearService.calculatePoints();
			this.leftService.calculatePoints();
			this.rightService.calculatePoints();
	
			model.bars.forEach((b: BarStoreModel) => {
				const bar = BarStoreModel.restore(b, this.projectService);
				this.profileService.roofElements[ElementType.Bar].push(bar);
				this.profileService.frontElements[ElementType.Bar].push(bar);
			});
	
			if (model.connectors) {
				model.connectors.forEach((b: BarStoreModel) => {
					const bar = BarStoreModel.restore(b, this.projectService);
					this.profileService.roofElements[ElementType.ProfileConnector].push(bar);
				});
			}
	
			model.sideFins.forEach((sf: SideFinishStoreModel) => {
				const sideFin = SideFinishStoreModel.restore(sf, this.projectService);
	
				this.profileService.roofElements[ElementType.SideFinish].push(sideFin);
				this.profileService.frontElements[ElementType.SideFinish].push(sideFin);
				if (sf.tag == Tags.LEFT_SIDEFINISH_TAG) {
					this.profileService.leftSideElements[ElementType.SideFinish].push(sideFin);
				} else {
					this.profileService.rightSideElements[ElementType.SideFinish].push(sideFin);
				}
			});
	
	
			model.columns.forEach((c: ColumnStoreModel) => {
				const col = ColumnStoreModel.restore(c, this.projectService);
				this.profileService.roofElements[ElementType.Column].push(col);
				if (!c.isRear) {
					this.profileService.frontElements[ElementType.Column].push(col);
				}
				if (col.tag == Tags.LEFT_COLUMN_TAG || col.tag == Tags.LEFT_REAR_COLUMN_TAG) {
					this.profileService.leftSideElements[ElementType.Column].push(col);
				} else if (col.tag == Tags.RIGHT_COLUMN_TAG || col.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
					this.profileService.rightSideElements[ElementType.Column].push(col);
				}
			});
	
			model.fronts.forEach((f: FrontStoreModel) => {
				var fp = FrontStoreModel.restore(f, this.projectService);
				this.profileService.frontElements[ElementType.Front].push(fp);
				this.setFrontProperties(fp, f, ElementType.Front, Tags.LEFT_FRONT_TAG, Tags.RIGHT_FRONT_TAG, Tags.ANY_FRONT_TAG, model.fronts);
			});
	
			model.gutters.forEach((f: GutterStoreModel) => {
				var fp = GutterStoreModel.restore(f, this.projectService);
				this.setGutterProperties(fp, f);
			});
	
			model.rears.forEach((f: RearStoreModel) => {
				var fp = RearStoreModel.restore(f, this.projectService);
				this.setFrontProperties(fp, f, ElementType.WallProfile, Tags.LEFT_REAR_TAG, Tags.RIGHT_REAR_TAG, Tags.ANY_REAR_TAG, model.rears);
			});
	
			model.glasses.forEach((g: GlassStoreModel) => {
				var glass = GlassStoreModel.restore(g, this.projectService);
				this.assignBars(glass, g);
				this.profileService.roofElements[ElementType.Glass].push(glass);
			});
	
			model.glassWalls.forEach((s: GlassWallStoreModel) => {
				GlassWallStoreModel.restore(s, this.projectService, this.getAreaService(s.area));
			});
	
			model.glassWallDiamonds.forEach((s: GlassWallDiamondStoreModel) => {
				GlassWallDiamondStoreModel.restore(s, this.projectService, this.getAreaService(s.area));
			});
	
			model.bars.forEach((b: BarStoreModel) => {
				var bar = this.profileService.roofElements[ElementType.Bar].find(f => f.id == b.id);
				this.assignGlasses(bar, b);
			});
	
			if (model.connectors) {
				model.connectors.forEach((b: BarStoreModel) => {
					var bar = this.profileService.roofElements[ElementType.ProfileConnector].find(f => f.id == b.id);
					this.assignGlasses(bar, b);
				});
			}
	
			model.sideFins.forEach((s: SideFinishStoreModel) => {
				var sf = this.profileService.roofElements[ElementType.SideFinish].find(f => f.id == s.id);
				this.assignGlasses(sf, s);
			});
	
			model.marquises.forEach((m: MarquiseStoreModel) => {
				let points: IPoint[];
				switch (m.area) {
					case AreaType.Front:
						points = this.frontService.getWorkAreaPoints();
						break;
					case AreaType.Left:
						points = this.leftService.getWorkAreaPoints();
						break;
					case AreaType.Right:
						points = this.rightService.getWorkAreaPoints();
						break;
					case AreaType.Roof:
						points = this.roofService.getWorkAreaPoints();
						break;
				}
				MarquiseStoreModel.restore(m, this.projectService, points);
			});
	
			model.doors?.forEach((d: DoorStoreModel) => {
				DoorStoreModel.restore(d, this.projectService, this.getAreaService(d.area));
			});
	
			model.walls?.forEach((d: WallStoreModel) => {
				WallStoreModel.restore(d, this.projectService, this.getAreaService(d.area));
			});
	
			model.roofWindows.forEach((r: RoofWindowStoreModel) => {
				var window = RoofWindowStoreModel.restore(r, ElementType.RoofWindow, this.projectService);
				this.assignRoofWindowGlasses(window, r);
			});
	
			if (model.roofGutters) {
				model.roofGutters.forEach((r: RoofWindowStoreModel) => {
					var window = RoofWindowStoreModel.restore(r, ElementType.RoofGutter, this.projectService);
					this.assignRoofWindowGlasses(window, r);
				});
			}
	
			LedSpotStoreModel.restore(model.ledSpot, this.projectService, this.api);
	
			model.ledStripes.forEach((l: LedStripeStoreModel) => {
				LedStripeStoreModel.restore(l, this.projectService);
			});
	
			model.extras.forEach((e: ExtrasStoreModel) => {
				ExtrasStoreModel.restore(e, this.projectService);
			});
	
			model.columns.filter(c => c.connector).forEach((c: ColumnStoreModel) => {
				var column: ColumnProfile = this.profileService.roofElements[ElementType.Column].find(f => f.id == c.id);
				const profileType = c.isRear ? ElementType.WallProfile : ElementType.Front;
				if (c.leftProfileId) {
					column.leftProfile = this.profileService.roofElements[profileType].find(f => f.id == c.leftProfileId);
				}
				if (c.rightProfileId) {
					column.rightProfile = this.profileService.roofElements[profileType].find(f => f.id == c.rightProfileId);
				}
			});

			this.api.getProductParameters(model.properties.productId, model.properties.brandName).subscribe(product => {
				this.projectService.template.maxBackHeight = product.maxBackHeight;
				this.projectService.template.minBackHeight = product.minBackHeight;
				this.projectService.template.maxDepth = product.maxDepth;
				this.projectService.template.minDepth = product.minDepth;
				this.projectService.template.maxDropAngle = product.maxDropAngle;
				this.projectService.template.minDropAngle = product.minDropAngle;
				this.projectService.template.maxWidth = product.maxWidth;
				this.projectService.template.minWidth = product.minWidth;
				this.projectService.template.version = product.version;
				this.projectService.template.isDraft = product.isDraft;
				this.projectService.template.errors = product.errors;
				this.projectService.template.productName = product.name;
				this.projectService.template.productPhoto = product.photo;
			});

			this.projectService.template.created = true;
			this.projectService.template.barsCount = this.profileService.roofElements[ElementType.Bar].length;
			this.projectService.projectReady.next(true);
			if (images) {
				this.projectService.printProjectSubj.next({ requestId: undefined, sideType: undefined });
			}
			if (emitChanges) {
				this.projectService.elementsChanged.next(true);
			}

			this.projectService.calculateWeight();
			this.projectRestored.next(true);

			// TODO: odkomentować w celu dokończenia zadania https://trello.com/c/6409wjdd/766-bug-qa-132-maksymalne-przybli%C5%BCenie-projektu-powoduje-ze-nie-wida%C4%87-lewych-linii-wymiarowych-dla-widoku-z-g%C3%B3ry-oraz-z-boku-lewa-st
			// var leftRear: WallProfile = this.profileService.leftSideElements[ElementType.WallProfile].find(f => f.tag == Tags.LEFT_REAR_TAG || f.tag == Tags.ANY_REAR_TAG);
			// SvgParams.START_X = leftRear.rectOnRoof.x;
			// SvgParams.START_Y = leftRear.rectOnRoof.y;

			this.roofService.calculatePoints();
			this.frontService.calculatePoints();
			this.rearService.calculatePoints();
		})
		this.projectRestored.next(true);
	}

	private getAreaService(area: AreaType) {
		return this.projectService.getAreaService(area, this.leftService, this.rightService, this.frontService, this.roofService, this.rearService);
	}

	private _restore(model: ProjectStoreModel, images: boolean) {
		var pr = new ProjectTemplate(true);
		pr.productId = model.properties.productId;
		pr.backHeight = model.properties.height;
		pr.glassId = model.properties.glassConfigId
		pr.depth = model.properties.depth;
		pr.dropAngle = model.properties.drop;
		pr.fasteningId = model.properties.fasteningConfigId;
		pr.formSideId = model.properties.formSideConfigId;
		pr.width = model.properties.width;
		pr.snowZone = model.properties.snowZone;
		pr.windZone = model.properties.windZone;
		pr.foundationId = model.properties.foundationId;
		pr.staticBarsCount = model.properties.staticBarsCount;

		pr.setStandAlone(model.properties.standAlone)

		this.projectService.templateChangedSubject.subscribe(e => {
			if (!e) {
				return;
			}
			if (this.zonesChanged(model)) {
				this.projectService.showInfoDialogSubj.next($localize`:Dialogs|Zones change detection:Snow or wind zone change detected. Project has been recalculated.`);
				this.projectService.selectedColor = this.projectService.template.colors.find(p => p.id == model.properties.colorConfigId);
				this.projectService.resetZones();
				this.roofService.applyTemplate("", "");
			} else {
				this.restore(model, images);
			}

		});
		this.projectService.setupTemplate(pr);
	}

	private zonesChanged(model: ProjectStoreModel) {
		if ((this.projectService.snowZoneUrl && model.properties.snowZone != this.projectService.snowZoneUrl)
				|| (this.projectService.windZoneUrl && model.properties.windZone != this.projectService.windZoneUrl)) {
			return true;
		}
		return false;
	}

	public restoreProject(model: ProjectStoreModel, force: boolean, images: boolean) {		
		if (!model.properties.oldProductId || force) {
			this._restore(model, images);
			if (model.properties.oldProductId && force) {
				this.projectService.readOnly = true;
			}
			this.stateService.save(model);
			return;
		}

		var items: IMigrationItem[] = [];
		this.addMigrationItems(items, model.glasses, ApiConfigCodes.ROOF);
		this.addMigrationItems(items, model.bars, ApiConfigCodes.PROFILE_ROOF_BAR);
		this.addMigrationItems(items, model.fronts, ApiConfigCodes.PROFILE_ROOF_FRONT);
		this.addMigrationItems(items, model.rears, ApiConfigCodes.PROFILE_ROOF_REAR);


		var mig: IMigrationInput = {
			currentProductId: model.properties.productId,
			oldProductId: model.properties.oldProductId,
			items: items
		};

		this.api.checkMigration(mig).subscribe(mr => {
			if (!mr.errors) {		
				this._restore(model, images);
			} else {
				this.migrationErrors.emit(mr);
			}
		});
	}

	private addMigrationItems(items: IMigrationItem[], elements: ElementStoreModel[], code: string) {
		elements.forEach(g => {			
			if (g.oldConfigId && !items.find(i => i.oldId == g.oldConfigId && i.currentId == g.configId)) {
				items.push({ type: code, currentId: g.configId, oldId: g.oldConfigId });
			}
		});
	}

	//#region restoreProject functions

	private setFrontProperties(profile: FrontProfile | WallProfile, model: FrontStoreModel | RearStoreModel, type: ElementType, leftTag: string, rightTag: string, anyTag: string, collection: ElementStoreModel[]) {
		this.profileService.roofElements[type].push(profile);
		
		if (profile instanceof WallProfile) {
			this.profileService.rearElements[type].push(profile);
		}

		switch (profile.tag) {
			case leftTag:
				this.profileService.leftSideElements[type].push(profile);
				break;
			case rightTag:
				this.profileService.rightSideElements[type].push(profile);
				break;
			default:
				if (!collection.find(c => c.tag == leftTag)) {
					this.profileService.leftSideElements[type].push(profile);
				}
				if (!collection.find(c => c.tag == rightTag)) {
					this.profileService.rightSideElements[type].push(profile);
				} 
				break;
		}

		profile.columns = this.getColumnsCollection(model);
		if (profile instanceof FrontProfile) {			
			profile.columns.forEach(c => {
				if (c) {
					c.assignFront(profile);
				}
			});
		}
	}

	private setGutterProperties(profile: GutterProfile, model: GutterStoreModel) {
		this.profileService.roofElements[ElementType.Gutter].push(profile);
		this.profileService.frontElements[ElementType.Gutter].push(profile);

		const front = this.profileService.roofElements[ElementType.Front].find(f => f.id == model.frontId);

		if (front.tag == Tags.LEFT_COLUMN_TAG || front.tag == Tags.ANY_FRONT_TAG) {
			this.profileService.leftSideElements[ElementType.Gutter].push(profile);
		}
		if (front.tag == Tags.RIGHT_COLUMN_TAG || front.tag == Tags.ANY_FRONT_TAG) {
			this.profileService.rightSideElements[ElementType.Gutter].push(profile);
		}

		if (front.gutterId) {
			front.gutter = profile;
		}

	}

	private assignRoofWindowGlasses(window: RoofWindow, model: RoofWindowStoreModel) {
		const glasses = this.profileService.roofElements[ElementType.Glass];

		this.assignGlass(window, model.topGlassId, "topGlass", glasses);
		this.assignGlass(window, model.bottomGlassId, "bottomGlass", glasses);
	}

	private assignGlasses(bar: BarProfile, model: BarStoreModel | SideFinishStoreModel) {
		const glasses = this.profileService.roofElements[ElementType.Glass];

		this.assignGlass(bar, model.leftGlassId, "leftGlass", glasses);
		this.assignGlass(bar, model.rightGlassId, "rightGlass", glasses);
		this.assignGlass(bar, model.topGlassId, "topGlass", glasses);
		this.assignGlass(bar, model.bottomGlassId, "bottomGlass", glasses);
	}

	private assignGlass(element: any, glassId: string, property: string, glasses: Glass[]) {
		if (glassId) {
			const glass = glasses.find(e => e.id == glassId);
			element[property] = glass;
		}
	}

	private assignBars(glass: Glass, model: GlassStoreModel) {
		const bars = this.profileService.roofElements[ElementType.Bar];
		const conns = this.profileService.roofElements[ElementType.ProfileConnector];
		const fins = this.profileService.roofElements[ElementType.SideFinish];

		this.assignVertBar(glass, model.leftBarId, "leftBar", bars, fins);
		this.assignVertBar(glass, model.rightBarId, "rightBar", bars, fins);

		this.assignHorBar(glass, model.topBarId, "topBar", conns);
		this.assignHorBar(glass, model.bottomBarId, "bottomBar", conns);
	}

	private assignVertBar(glass: Glass, barId: string, property: string, bars: any[], fins: any[]) {
		if (barId) {
			let bar: BarProfile;
			bar = bars.find(e => e.id == barId);
			if (!bar) {
				bar = fins.find(e => e.id == barId);
			}
			glass[property] = bar;
		}
	}

	private assignHorBar(glass: Glass, barId: string, property: string, connectors: any[]) {
		if (barId) {
			let bar: ProfileConnector;
			bar = connectors.find(e => e.id == barId);
			glass[property] = bar;
		}
	}

	private getColumnsCollection(model: FrontStoreModel | RearStoreModel) {
		var collection: ColumnProfile[] = [];
		model.columns.forEach((c: string) => {
			var col = this.profileService.roofElements[ElementType.Column].find(f => f.id == c);
			if (col) {
				collection.push(col);
			}
		});
		return collection;
	}

	//#endregion

}
