import { Component, Input, ViewChild } from '@angular/core';
import { CardType, IPropertyCard } from 'src/app/_core/interfaces/IPropertyCard';
import { ProjectService } from 'src/app/_core/services/project.service';
import { Door } from "src/app/_core/models/doors/door.model";
import { ICheckable } from 'src/app/_core/interfaces/ICheckable';
import { DoorCreator } from 'src/app/_core/models/doors/door-creator';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { ILockVariant } from 'src/app/_core/interfaces/ILockVariant';
import { IDoorInfo } from 'src/app/_core/interfaces/IDoorInfo';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { FrontService } from 'src/app/_core/services/areas/front.service';
import { LeftSideService } from 'src/app/_core/services/areas/left-side.service';
import { RightSideService } from 'src/app/_core/services/areas/right-side.service';
import { RearService } from 'src/app/_core/services/areas/rear.service';

@Component({
	selector: 'card-door',
	templateUrl: './card-door.component.html',
	styleUrls: ['../card.component.css']
})
export class CardDoorComponent implements IPropertyCard {
	@ViewChild('numHeight', { static: true }) numHeight: NumTbxComponent;
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: true }) numLocY: NumTbxComponent;

	type: CardType = CardType.Door;
	currentLockId: string;
	current: Door;
	extras: ICheckable[];
	variants: IDoorInfo[];

	private creator: DoorCreator;
	showVariants: boolean = false;

	@Input() public set items(items: any[] | null) {
		if (items !== null) {
			this.initItem(items[0]);			
		} else {
			return;
		}

		if (this.numHeight.valueChanged.observers.length == 0) {
			this.numHeight.valueChanged.subscribe(v => {
				this.creator.setHeight(this.current, v);
				this.numHeight.rebind(Math.round(this.current.height));
				const fund = this.current.getExtras().find(c => c == this.current.def.foundationId);
				if (fund) {
					this.extras.find(ex => ex.id == fund).checked = true;
				}

			});
		}
		if (this.numWidth.valueChanged.observers.length == 0) {
			this.numWidth.valueChanged.subscribe(v => {
				this.creator.setWidth(this.current, v);
				this.numWidth.rebind(Math.round(this.current.width));
				this.initVariants();
			});
		}
		if (this.numLocX.valueChanged.observers.length == 0) {
			this.numLocX.valueChanged.subscribe(v => {
				this.creator.setLocationX(this.current, v);
				this.numLocX.rebind(Math.round(this.current.locationX));
			});
		}

		if (this.numLocY.valueChanged.observers.length == 0) {
			this.numLocY.valueChanged.subscribe(v => {
				this.creator.setLocationY(this.current, v);
				this.numLocY.rebind(Math.round(this.current.locationY));
			});
		}

	}

	get maxHeight() {
		return Math.round(this.current.maxHeight);
	}
	get currentVariant() {
		return this.current.def.id;
	}
	set currentVariant(v: string) {
		const def = this.projectService.template.doors.find(d => d.id == v);
		var newDoor = this.creator.createDoor(def, this.current);

		this.projectService.deleteElement(this.current);
		this.projectService.profileService.addVertical(newDoor);
		this.initItem(newDoor);
	}

	constructor(public projectService: ProjectService,
		private frontService: FrontService,
		private leftService: LeftSideService,
		private rightService: RightSideService,
		private rearService: RearService) {}

	private initItem(item: Door) {
		this.current = item;
		this.extras = [];

		const hidext = this.projectService.template.extras;

		item.def.doorExtras.forEach(e => {
			const he = hidext.find(h => h.id == e);
			const checked = this.current.getExtras().find(c => c == e) != null;
			this.extras.push({ id: e, name: he.name, photo: he.thumbnail, checked });
		});

		this.creator = DoorCreator.fromInstance(this.projectService, this.current, this.projectService.CurrentAreaService);
		this.initVariants();
		item.refreshSurround(this.projectService.profileService);
	}

	private initVariants() {
		this.variants = this.creator.getVariants(this.current);
	}

	public lockVariantChange(event: { door: IDoorInfo, lock: ILockVariant }) {
		if (event?.lock) {
			this.currentVariant = event.door.id;
			this.current.lockVariant = event.lock;
			this.projectService.emitChange();
		}
		this.showVariants = false;
	}

	selectExtra(item: ICheckable) {
		item.checked = !item.checked;
		if (item.checked === true) {
			this.current.addExtra(item.id);
		} else {
			this.current.removeExtra(item.id);
		}
		this.projectService.emitChange();
	}

	deleteElement() {
		this.projectService.deleteElement(this.current);
	}


	get currLng(): string {
		return window.document.documentElement.lang;
	}

	get bottomExpandDisabled() {
		const neib = this.projectService.profileService.findVerticalNeib(this.current, ElementSide.Bottom);
		return neib != null;
	}
	expandBottom() {
		this.current.isExpandedBottom = !this.current.isExpandedBottom;
		this.projectService.emitChange();
	}
	expandTop() {
		this.current.isExpandedTop = !this.current.isExpandedTop;
		this.projectService.emitChange();
	}

	public applyDialogOpened: boolean = false;
	public applyDialogMessage: string;

	openApplyDialog(): void {
		this.applyDialogOpened = true;
		this.applyDialogMessage = $localize`:Messages|Apply door height to others confirmation:Do you really want to apply this height to all doors?`;
	}

	onApplyDialogAction(info: {status: boolean, message: string}) {
		this.applyDialogOpened = false;

		if (!info.status) return;

		if (this.projectService.applyDoorHeight(this.current, this.frontService, this.leftService, this.rightService, this.rearService)) {
			this.projectService.emitChange();
		}
	}

}
