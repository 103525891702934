import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { AreaType } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from "../../template";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class GutterProfile extends Profile {

  get type() {
    return ElementType.Gutter
  }

  get name(): string {
    return $localize`:Common|Gutter:Gutter`;
  }

  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  public frontId: string;

  public override get cuttingable(): boolean {
    return false;
  }

  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    template: ProjectTemplate,
    length: number,
    area: AreaType,
    frontConfigId: string
  ) {

    const front = template.fronts.find(f => f.id == frontConfigId);
    super(Orientation.Horizontal, front.gutterSize, front.color);

    this.id = this.type + "_" + uuidv4();
    this.configId = frontConfigId;

    this.length = length;

    if (area === AreaType.None) {
			area = currentArea;
		}

    switch (area) {
      case AreaType.Roof:
        this.rectOnRoof = {
          x: x,
          y: y,
          w: Math.round(this.getLength() * SvgParams.SCALE),
          h: Math.round(this.depth * SvgParams.SCALE),
        };
        break;
      case AreaType.Front:
        this.rectOnFront = {
          x: x,
          y: y,
          w: Math.round(this.getLength() * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE),
        };
        break;
      case AreaType.Left:
        this.rectOnLeft = {
          x: x,
          y: y,
          w: Math.round(this.depth * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE)
        };
        break;
      case AreaType.Right:
        this.rectOnRight = {
          x: x,
          y: y,
          w: Math.round(this.depth * SvgParams.SCALE),
          h: Math.round(this.height * SvgParams.SCALE)
        };
        break;
    }
  }
}
