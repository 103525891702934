import { Component, Input } from '@angular/core';
import { CUSTOMER_PLATE_HEIGHT, CUSTOMER_PLATE_WIDTH, SvgParams } from 'src/app/_core/constants/constants';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { IRect } from 'src/app/_core/interfaces/IRect';
import { FrontService } from 'src/app/_core/services/areas/front.service';
import { LeftSideService } from 'src/app/_core/services/areas/left-side.service';
import { RearService } from 'src/app/_core/services/areas/rear.service';
import { RightSideService } from 'src/app/_core/services/areas/right-side.service';
import { RoofService } from 'src/app/_core/services/areas/roof.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { ILine } from 'src/app/_core/interfaces/ILine';

@Component({
	selector: 'g[app-customer-plate]',
	templateUrl: './customer-plate.component.html',
	styleUrls: ['./customer-plate.component.css']
})
export class CustomerPlateComponent {
	private _areaType: AreaType;
	private _labels: ILine[] = [];
	private _values: ILine[] = [];
	private interLine: number = 16;

	public logoSrc: string;
	public rect: IRect = { x: SvgParams.START_X, y: SvgParams.START_Y - 100, w: 400, h: 120};

	@Input() public set area(v: string) {
		this._areaType = AreaType[v];
		this._labels = [];
		this._values = [];
		this.getLabels();
		this.getValues();
	}

	constructor(
		public projectService: ProjectService,
		private roofService: RoofService,
		private rightSideService: RightSideService,
		private leftSideService: LeftSideService,
		private frontService: FrontService,
		private rearService: RearService) { }

	private calcCoordinates() {
		if (!this._areaType || this._areaType == AreaType.None) {
			this._areaType = AreaType.Roof;
		}
		var x: number;
		var y: number;

		const w = CUSTOMER_PLATE_WIDTH;
		const h = CUSTOMER_PLATE_HEIGHT;
		const srv = this.projectService.getAreaService(this._areaType, this.leftSideService, this.rightSideService, this.frontService, this.roofService, this.rearService);
		const p = this.projectService.calculateViewPort(this._areaType, srv);
		
		this.rect = { x: p.x + p.w - w - 1, y: p.y + p.h - h - 1, h, w };
	}

	public getLabels() {
		if (this._labels.length == 0 && this.projectService.info != null && this.projectService.template.width != null) {
			this.calcCoordinates();
			var y = this.rect.y + this.interLine;
			const x1 = this.rect.x + 130;
			this._labels.push({ x: x1, y, text: $localize`:Legend|'Order code' caption:Order code` });

			y += this.interLine;
			this._labels.push({ x: x1, y, text: $localize`:Legend|'Customer name' caption:Customer name` });

			y += this.interLine;
			this._labels.push({ x: x1, y, text: $localize`:Legend|'Address' caption:Address` });
		}

		return this._labels;
	}

	public getValues() {
		if (this._values.length == 0 && this.projectService.info != null && this.projectService.template.width != null) {
			this.calcCoordinates();
			var y = this.rect.y + this.interLine;
			const x1 = this.rect.x + 20;
			const x2 = x1 + 130;
			this._values.push({ x: x2, y, text: this.projectService.info.orderNumber });

			y += this.interLine;
			this._values.push({ x: x2, y, text: this.projectService.info.customerName });

			y += this.interLine;
			this._values.push({ x: x2, y, text: this.projectService.info.customerAddresLine1 });

			if (this.projectService.info.customerAddresLine2) {
				y += this.interLine;
				this._values.push({ x: x2, y, text: this.projectService.info.customerAddresLine2 });
			}

		}

		return this._values;
	}
}
