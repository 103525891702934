<svg:g *ngIf="styling.showLayer(1)">

<svg:g *ngFor="let g of getGlassWalls()">
	<!-- Glass Wall -->
	<svg:rect [attr.x]="g.rect.x" [attr.y]="g.rect.y" [attr.width]="g.rect.w" [attr.height]="g.rect.h"
		[attr.class]="styling.getClasses(g, areaType, selectedItems.indexOf(g) >= 0, 'glass glass-walls')"
		(click)="selectGlass(g, $event)" [attr.glassId]="g.id"
		[attr.fill]="projectService.getBackColor()"
		[attr.stroke]="projectService.frameColor">
	</svg:rect>
	<!-- Glass Wall Parts -->
	<svg:rect *ngFor="let p of g.parts" [attr.x]="p.rect.x" [attr.y]="p.rect.y" [attr.width]="p.rect.w" [attr.height]="p.rect.h"
		[attr.class]="styling.getClasses(p, areaType, selectedItems.indexOf(p) >= 0, 'glass')"
		(click)="selectGlass(p, $event)"
		[attr.fill]="styling.getGlassBackColor(p)"
		[attr.stroke]="projectService.frameColor">
	</svg:rect>
	<!-- Profiles: Muntin -->
	<svg:rect *ngFor="let l of g.muntins"
		[attr.x]="l.rect.x" [attr.y]="l.rect.y" [attr.width]="l.rect.w" [attr.height]="l.rect.h"
		[attr.fill]="projectService.getBackColor()"
		[attr.stroke]="projectService.frameColor"
		[attr.class]="styling.getClasses(l, areaType, selectedItems.indexOf(l) >= 0)"
		(click)="selectProfile(l, $event)">
	</svg:rect>

	<!-- Profiles: Frame -->
	<svg:rect *ngFor="let f of g.frames" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w"
		[attr.height]="f.rect.h" class="profile" [attr.stroke]="projectService.frameColor"
		[attr.fill]="projectService.getBackColor(f.color)"
		(click)="selectGlass(g, $event)">
	</svg:rect>
</svg:g>

<svg:g *ngFor="let g of getDiamonds()">
	<!-- Glass Wall Diamond -->
	<svg:path [attr.d]="g.path" class="glass glass-walls"
		[attr.fill]="projectService.getBackColor()"
		[attr.stroke]="projectService.frameColor"
		[attr.class]="styling.getClasses(g, areaType, selectedItems.indexOf(g) >= 0, 'glass glass-walls')"
		(click)="selectGlass(g, $event)" [attr.glassId]="g.id">
	</svg:path>

	<!-- Glass Diamond Parts -->
	<svg:path *ngFor="let p of g.parts" [attr.d]="p.path" class="glass"
		[attr.fill]="styling.getGlassBackColor(p)"
		[attr.stroke]="projectService.frameColor"
		[attr.class]="styling.getClasses(p, areaType, selectedItems.indexOf(p) >= 0, 'glass')"
		(click)="selectGlass(p, $event)" [attr.glassId]="p.id">
	</svg:path>

	<!-- Profiles: Muntin -->
	<svg:rect *ngFor="let l of g.muntins" [attr.x]="l.rect.x" [attr.y]="l.rect.y" [attr.width]="l.rect.w"
		[attr.height]="l.rect.h" [attr.fill]="projectService.getBackColor()" [attr.stroke]="projectService.frameColor"
		[class]="styling.iconClass('Muntin',areaType)" (click)="selectProfile(l, $event)">
	</svg:rect>

	<!-- Profiles: Frame -->
	<svg:rect *ngFor="let f of g.frames" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w"
		[attr.height]="f.rect.h" class="profile" [attr.stroke]="projectService.frameColor"
		[attr.fill]="projectService.getBackColor(f.color)"
		(click)="selectGlass(g, $event)">
	</svg:rect>
</svg:g>



</svg:g>