import { Injectable } from '@angular/core';
import { IElement } from '../interfaces/IElement';
import { LayerLevel } from '../interfaces/LayerLevel';
import { ElementType, ToolboxItem, ToolboxItemType } from '../models/ToolboxModel';
import { ProjectService } from './project.service';
import { Marquise } from '../models/marquises/marquise.model';
import { Glass } from '../models/glasses/glass.model';
import { GlassWall } from '../models/glasses/glassWall.model';
import { GlassPart } from '../models/glasses/glassPart.model';
import { GlassPartDiamond } from '../models/glasses/glassDiamondPart';
import { ProfileService } from './profile.service';
import { GlassWallDiamond } from '../models/glasses/glassWallDiamond.model';
import { Door } from '../models/doors/door.model';
import { GraphicsService } from './graphics-service';
import { ToolboxItemService } from './toolbox-item.service';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { AreaType } from '../interfaces/IAreaType';
import { IMarquise } from '../interfaces/IMarquise';

@Injectable({
	providedIn: 'root'
})
export class StylingService {

	private selectedLayers: LayerLevel[] = [LayerLevel.Construction, LayerLevel.Inside, LayerLevel.Middle, LayerLevel.Outside];
	private currentTool: ToolboxItem;
	
	constructor(
		private profileService: ProfileService,
		private projectService: ProjectService,
		private graphicsService: GraphicsService,
		private toolBSrvc: ToolboxItemService) {

		projectService.layersChanged.subscribe(l => {
			if (!l) {
				return;
			}
			this.selectedLayers = l;
		});

		this.toolBSrvc.currentTool$.subscribe((ct: ToolboxItem) => {
			this.currentTool = ct;
		}); 

	}

	public getGlassesColors(): any[] {
		if (!this.profileService.roofElements) {
			return null;
		}
		var rawColors = [];

		this.profileService.getGetGlassWalls().forEach((g: GlassWall) => {
			rawColors.push(g.color);
			g.parts.forEach((p: GlassPart) => {
				rawColors.push(p.color);
			});
		});
		this.profileService.getGetDiamonds().forEach((g: GlassWallDiamond) => {
			rawColors.push(g.color);
			g.parts.forEach((p: GlassPartDiamond) => {
				rawColors.push(p.color);
			});
		});
		this.profileService.roofElements[ElementType.Glass].forEach((d: Glass) => {
			rawColors.push(d.color);
		});
		this.profileService.getDoors().forEach((d: Door) => {
			rawColors.push(d.glassColor);
		});

		var colors = [];
		rawColors.forEach((col: string) => {
			const id = 'color_' + col.substring(1);
			if (colors.find(c => c.id == id)) {
				return;
			}
			const newColor = col.substring(0, 3) + 'F0FF' + col.substring(7);
			colors.push({ id: id, color1: newColor, color2: col });
		});
		return colors;
	}

	public getBackColor(color: string) {
		return this.projectService.getBackColor();
	}

	public getMarquiseFabrics() {
		if (!this.profileService.roofElements) {
			return null;
		}
		var fabrics = [];
		var types = [ElementType.MarquiseTopBottom, ElementType.MarquiseTopUp];
		types.forEach(t => {
			this.profileService.roofElements[t].forEach((m: IMarquise) => {
				this.getFabric(m, fabrics);
			});
		});
		this.profileService.getMarquisesVertical().forEach(m => {
			this.getFabric((m as unknown as IMarquise), fabrics);
		});
		return fabrics;
	}

	private getFabric(m: IMarquise, fabrics: any[]) {
		if (m.fabric) {
			const pid = 'fabric' + m.fabric.id.replace('-', '');
			var fab = fabrics.find(f => f.id == pid);
			if (!fab) {
				fabrics.push({ id: pid, url: "" });
				this.graphicsService.getBackgroud(m.id, m.fabric);
			}
		}
	}

	public getGlassBackColor(g: Glass) {
		return `url(#color_${g.color.substring(1)})`;
	}

	public getMarquiseBackColor(mq: Marquise | MarquiseVertical) {
		if (!mq.fabric) {
			return "#EBB6B64C";
		}
		return `url(#fabric${mq.fabric.id.replace('-', '')})`;
	}

	public hideOnLayer(element: IElement) {
		return this.selectedLayers.find(l => l == element.layer) == null;
	}

	public showLayer(layer: LayerLevel) {
		return this.selectedLayers.find(l => l == layer) != null;
	}

	public showOverLayer(element: IElement, opacCorr: number) {
		if (element.type == ElementType.Glass) {
			return 1;
		}
		switch(element.layer) {
			case LayerLevel.Construction:
			case LayerLevel.Inside:
				return 1;
			case LayerLevel.Middle:
				if (!this.profileService.isAnyInside(this.projectService.currentArea)) {
					return 1;
				}
				if (opacCorr > 0) {
					return opacCorr;
				}
				var f = 0;
				switch (element.type) {
					case ElementType.Door:
						f = 0.1;
						break;
					case ElementType.GlassWall:
					case ElementType.GlassWallDiamond:
						f = 0.6;
						break;
					case ElementType.GlassPart:
					case ElementType.GlassPartDiamond:
						f = 0.3;
						break;
				}
				return ((this.selectedLayers.find(l => l == LayerLevel.Inside) != null) ? 0.9 : 1) - f;
			case LayerLevel.Outside:
				switch (this.selectedLayers.length) {
					case 4:
						return 0.8;
					case 3:
						return 0.9;
					default:
						return 1;					
				}
				break;
		}
	}

	public visibleLayer(l: LayerLevel) {
		return this.selectedLayers.find(lr => lr == l) != null;
	}

	public getClasses(item: IElement, areaType: string, selected: boolean, classes: string = "", noHighLight: boolean = false, opacCorr: number = 0) {
		if (classes == "" && item.type != ElementType.Shape) {
			classes = 'profile';
		} else if (classes == 'profile' && this.currentTool != null) {
			classes = '';
		}
		
		var hidden = false;
		if (this.hideOnLayer(item)) {
			hidden = true;
			if (areaType == AreaType.Roof && item.type == ElementType.Glass) {
				classes = "";
			}
			classes += " " + (item.type == ElementType.MarquiseTopUp ? "invisible" : "hidden") + "OnLayer";
			if (this.currentTool && item.type == ElementType.Glass) {
				const m = this.projectService.profileService.findMarquiseBelowGlass(item as Glass);
				if (m) {
					classes += "Hand";
				}
			}
		} else {
			classes += " " + this.iconClass(item.type, areaType);
		}
		if (!hidden) {
			if (!noHighLight && selected) {
				classes += " highlight";
			} else {
				var opacity = this.showOverLayer(item, opacCorr);
				if (opacity != 1) {
					opacity = Math.round(opacity * 10);
					classes += ` showOverLayer-${opacity}`;
				}
			}
			if ((item as Glass).locked) {
				classes += " locked";
			}
		}

		return classes;
	}

	public iconClass(type: ElementType, areaType: string) {
		return this.toolBSrvc.iconClass(type, areaType);
	}
}
