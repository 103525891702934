import { Pipe, PipeTransform } from '@angular/core';
import {ColorOfPalette} from '../models/colors/color.model';

@Pipe({
  name: 'searchColor'
})
export class SearchColorPipe implements PipeTransform {

  transform(colorsGroup: ColorOfPalette[], searchedColor: string): unknown {
		if (!colorsGroup) {
			return [];
		}
		if (!searchedColor) {
			return colorsGroup;
		}

		return colorsGroup.filter(color => color.name.toLowerCase().includes(searchedColor.toLowerCase()));
  }

}
