import { Component, Input, ViewChild } from '@angular/core';
import { CardType, IPropertyCard } from '../../_core/interfaces/IPropertyCard';
import { ProjectService } from '../../_core/services/project.service';
import { RoofService } from '../../_core/services/areas/roof.service';
import { ElementType } from '../../_core/models/ToolboxModel';
import { ProfileService } from '../../_core/services/profile.service';
import { WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { Marquise } from 'src/app/_core/models/marquises/marquise.model';
import { MarquisesParams, SvgParams } from '../../_core/constants/constants';
import { ChoosenProfileService } from '../../_core/services/chosen-elements/chosen-profile.service';
import { Glass } from 'src/app/_core/models/glasses/glass.model';
import { MarquiseTop } from '../../_core/models/marquises/marquise-top.model';
import { ChosenMarquiseService } from '../../_core/services/chosen-elements/chosen-marquise.service';
import { IMarquiseInfo } from '../../_core/interfaces/IMarquiseInfo.interface';
import { ElementSide } from '../../_core/interfaces/IElementSide';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { MarquiseCreator } from 'src/app/_core/models/marquises/marquise-creator';

@Component({
	selector: 'card-marquise',
	templateUrl: './card-marquise.component.html',
	styleUrls: ['../card.component.css']
})
export class CardMarquiseComponent implements IPropertyCard {
	@ViewChild('numDepth', { static: true }) numDepth: NumTbxComponent;
	@ViewChild('numLocY', { static: true }) numLocY: NumTbxComponent;

	public type: CardType = CardType.Marquise;
	public currMarq: MarquiseTop;
	public currMarqInfo: IMarquiseInfo;

	public isExpandedLeft = false;
	public isExpandedRight = false;

	public minYLocation: number;
	public maxYLocation: number;

	public minWidth: number;
	public maxWidth: number;
	public minCover: number;
	public maxExtension: number;
	public minDepth: number;
	public maxDepth: number;
	public maxDepthStatics: number;

	public profileTypes: WizardParameter[];
	private futureMarquiseWidth: number;

	constructor(
		public projectService: ProjectService,
		private roofService: RoofService,
		private profileService: ProfileService,
		private choosenProfileService: ChoosenProfileService,
		private chosenMarquiseService: ChosenMarquiseService,
	) { }

	@Input() public set items(items: any[]) {
		this.currMarq = items[0];

		this.currMarqInfo = this.currMarq.marquiseInfo;

		this.maxWidth = this.currMarqInfo.maxWidth;
		this.minWidth = this.currMarqInfo.minWidth;
		var md = this.getMaxDepth();
		this.maxDepth = this.projectService.adjustMaxMarquiseDepth(this.currMarq, md);
		this.minDepth = this.currMarqInfo.minDepth;
		this.maxDepthStatics = this.currMarqInfo.maxDepth;
		this.maxExtension = this.currMarqInfo.maxExtension;
		this.minCover = this.currMarqInfo.minCover;
		this.minYLocation = this.getMinYLocation();
		this.maxYLocation = this.getMaxYLocation();

		if (this.numDepth.valueChanged.observers.length == 0) {
			this.numDepth.valueChanged.subscribe(v => {
				this.currentMarquiseDepth = v;
			});
		}

		if (this.numLocY.valueChanged.observers.length == 0) {
			this.numLocY.valueChanged.subscribe(v => {
				this.currentMarquiseYLocation = v;
			});
		}
	}
	get rawWidth(): number {
		return this.chosenMarquiseService.getRawWidth();
	}
	get currentMarquiseWidth(): number {
		return this.chosenMarquiseService.getWidth();
	}
	set currentMarquiseWidth(length: number) {
		// read only
	}

	get currentMarquiseDepth(): number {
		return this.currMarq.getErpDepth();
	}
	set currentMarquiseDepth(length: number) {
		if (length < this.minDepth) {
			length = this.minDepth;
		} else if (length > this.maxDepth) {
			length = this.maxDepth;
		}
		this.chosenMarquiseService.setDepth(length);
		var md = this.getMaxDepth();
		this.maxDepth = this.projectService.adjustMaxMarquiseDepth(this.currMarq, md);
		this.minYLocation = this.getMinYLocation();
		this.maxYLocation = this.getMaxYLocation();
		this.numDepth.rebind(this.currentMarquiseDepth);
		this.chosenMarquiseService.setRailsForMarquise();
		this.projectService.emitChange();
	}

	get currentMarquiseExtension(): number {
		return this.currMarq.getExtension();
	}
	set currentMarquiseExtension(length: number) {
		// read only
	}

	get currentMarquiseXLocation(): number {
		return this.chosenMarquiseService.getXLocation();
	}
	set currentMarquiseXLocation(length: number) {
		this.chosenMarquiseService.setRailsForMarquise();
		// read only
	}

	get currentMarquiseYLocation(): number {
		return Math.round(this.chosenMarquiseService.getYLocation());
	}
	set currentMarquiseYLocation(location: number) {
		this.chosenMarquiseService.setYLocation(location);
		var md = this.getMaxDepth();
		this.maxDepth = this.projectService.adjustMaxMarquiseDepth(this.currMarq, md);
		this.maxYLocation = this.getMaxYLocation();
		if (location > this.minYLocation) {
			this.currMarq.isExpandedBack = false;
		}
		this.numLocY.rebind(this.currentMarquiseYLocation);
		this.chosenMarquiseService.setRailsForMarquise();
		this.projectService.emitChange();
	}

	get engineId(): string {
		return this.chosenMarquiseService.getEngineId();
	}
	set engineId(id: string) {
		this.chosenMarquiseService.setEngineId(id);
		this.projectService.emitChange();
	}

	get handlerName(): string {
		return this.chosenMarquiseService.getHandlerName();
	}

	private getMaxDepth() {
		if (this.currMarq.type === ElementType.MarquiseTopUp) {
			if (this.isDepthOfMarquiseLessThanSumOfItsMaxExtensionAndMinCover()) {
				return this.projectService.template.depth - this.currMarqInfo.minCover;
			}
			if (this.maxAllowableDepthOfMarqTopUp() < this.currMarqInfo.maxDepth) {
				return this.maxAllowableDepthOfMarqTopUp();
			}
			return this.currMarqInfo.maxDepth;
		} else if (this.currMarq.type === ElementType.MarquiseTopBottom) {
			const front = this.profileService.roofElements[ElementType.Front][0];
			return Math.round((front.rectOnRoof.y - this.currMarq.lineOnRoof.y2) / SvgParams.SCALE);
		}
	}

	private isDepthOfMarquiseLessThanSumOfItsMaxExtensionAndMinCover(): boolean {
		return this.currMarq.getErpDepth() < this.currMarqInfo.maxExtension + this.currMarqInfo.minCover
	}

	private maxAllowableDepthOfMarqTopUp(): number {
		return this.projectService.template.depth - this.chosenMarquiseService.getYLocation() + this.currMarqInfo.maxExtension
	}

	private getMinYLocation(): number {
		if (this.currMarq.type === ElementType.MarquiseTopUp) {
			return 0;
		} else if (this.currMarq.type === ElementType.MarquiseTopBottom) {
			if (this.projectService.template.isStandalone) {
				const rear = this.profileService.roofElements[ElementType.WallProfile][0];
				return Math.round(((rear.rectOnRoof.y + rear.rectOnRoof.h) - SvgParams.START_X) / SvgParams.SCALE);
			} else {
				if (this.maxYLocation == 0) {
					return 0;
				}
				return this.projectService.template.getDefaultRear().marquiseInsertionLength;
			}
		}
	}

	private getMaxYLocation(): number {
		const guttD = this.projectService.template.getFrontSize().depth;

		if (this.currMarq.type === ElementType.MarquiseTopUp) {
			if (this.currMarq.getErpDepth() < this.currMarqInfo.maxExtension + this.currMarqInfo.minCover) {
				return Math.round(this.projectService.template.depth - this.currMarqInfo.minCover);
			}
			return this.projectService.template.depth - this.currMarq.getErpDepth() + this.currMarqInfo.maxExtension;

		} else if (this.currMarq.type === ElementType.MarquiseTopBottom) {
			return this.currentMarquiseYLocation + this.maxDepth - this.currentMarquiseDepth;
		}
	}

	onPositionBtnClick(side: string): void {
		this.chosenMarquiseService.changePosition(ElementSide[side]);
		this.chosenMarquiseService.setRailsForMarquise();
		this.projectService.emitChange();
	}

	onChangeWidthBtnClick(side: string) {
		this.chosenMarquiseService.changeWidth(ElementSide[side]);
		this.chosenMarquiseService.setRailsForMarquise();
		this.projectService.emitChange();
	}

	expandSide(side: string, status: boolean) {
		this.chosenMarquiseService.expandSide(ElementSide[side], status);
		this.chosenMarquiseService.setRailsForMarquise();
		this.projectService.emitChange();
	}

	expandBack(status: boolean) {
		if (status != this.currMarq.isExpandedBack) {
			this.chosenMarquiseService.expandBack(status);
			const md = this.getMaxDepth();
			this.maxDepth = this.projectService.adjustMaxMarquiseDepth(this.currMarq, md);
			if (this.maxDepth < this.currentMarquiseDepth) {
				this.chosenMarquiseService.setDepth(this.maxDepth);
				this.numDepth.rebind(this.maxDepth);
			}
			this.chosenMarquiseService.setRailsForMarquise();
			this.projectService.emitChange();
		}
	}

	relocateEngine(location: string) {
		if (this.currMarq.engine.location != location) {
			this.chosenMarquiseService.relocateEngine(ElementSide[location]);
			this.projectService.emitChange();
		}
	}

	// checking

	isChangePositionBtnDisable(side: string): boolean {
		let hasNext: boolean;
		let external: boolean;
		switch (ElementSide[side]) {
			case ElementSide.Left:
				external = this.getIsLeftSideOfMarquiseOnLeftEdgeOfRoof(this.currMarq.firstGlass, ElementSide[side]);
				hasNext = this.currMarq.getMarquiseOnSide(ElementSide.Left, this.profileService.roofElements) != null;
				break;
			case ElementSide.Right:
				external = this.getIsRightSideOfMarquiseOnRightEdgeOfRoof(this.currMarq.lastGlass, ElementSide[side]);
				hasNext = this.currMarq.getMarquiseOnSide(ElementSide.Right, this.profileService.roofElements) != null;
				break;
		}

		return external || hasNext;
	}

	isChangeWidthBtnDisable(side: string): boolean {
		const eside = ElementSide[side];
		if (this.getIsRightSideOfMarquiseOnRightEdgeOfRoof(this.currMarq.lastGlass, eside)) return true;

		this.futureMarquiseWidth = this.setFutureMarquiseWidth(this.currMarq.lastGlass, eside);
		const isfutureMarquiseWidthTooShort = this.futureMarquiseWidth < this.minWidth;
		const isfutureMarquiseWidthTooLong = this.futureMarquiseWidth > this.maxWidth;

		const marquiseOnRightSide = eside == ElementSide.Left ? false : this.currMarq.getMarquiseOnSide(ElementSide.Right, this.profileService.roofElements) != null;
		// const isExpandedRight = this.getIsExpandedRight(this.lastGlassUnderMarquise, side);

		return (
			isfutureMarquiseWidthTooShort ||
			isfutureMarquiseWidthTooLong ||
			marquiseOnRightSide
			// || isExpandedRight
		);
	}

	private setFutureMarquiseWidth(lastGlassUnderMarquise: Glass, side: ElementSide) {
		const sside = side.toString().toLocaleLowerCase();
		const newLastGlass: Glass = lastGlassUnderMarquise[sside + "Bar"][sside + "Glass"];

		var w = MarquiseCreator.calculatePoints(this.projectService, this.currMarq.type, this.currMarq.firstGlass, newLastGlass).width;
		return w;;
	}

	private getRoofRightPointX(marquiseType: ElementType, lastGlass: Glass) {
		switch(marquiseType) {
			case ElementType.MarquiseTopUp:
				return lastGlass.isLast
					? lastGlass.rightBar.lineOnRoof.x1 + lastGlass.rightBar.lineOnRoof.w / 2
					: lastGlass.rect.x + lastGlass.rect.w;
			case ElementType.MarquiseTopBottom:
				return lastGlass.rect.x + lastGlass.rect.w - lastGlass.stat.paddingRight * SvgParams.SCALE;
		}
		return null;
	}

	isExpandBtnDisable(side: string): boolean {
		const isExpanded = this.currMarq['isExpanded' + side];
		if (this.currMarq.type == ElementType.MarquiseTopBottom && !isExpanded && this.rawWidth + MarquisesParams.BOTTOM_CORRECTION_OUT + 30 > this.maxWidth) {
			return true;
		}

				
		const sSide = side.toLowerCase();
		const oppositeSite = side == ElementSide.Left ? ElementSide.Right.toLowerCase() : ElementSide.Left.toLowerCase();
		if (this.currMarq.type == ElementType.MarquiseTopUp && this.currMarq[sSide + 'Bar'].type == ElementType.SideFinish) {
			return true;
		}

		if (isExpanded || this.currMarq.type == ElementType.MarquiseTopBottom) {
			return false;
		}

		return this.profileService.roofElements[this.currMarq.type].find((m: MarquiseTop) => {
			if (m[oppositeSite + 'Bar'] == this.currMarq[sSide + 'Bar']) {
				return true;
			}
		});
	}

	isChangeExtensionBtnDisable(): boolean {
		const isAllowToChangeExtension = this.currMarq.lineOnRoof.y1 < this.roofService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		return isAllowToChangeExtension;
	}

	// tooltips

	changePositionTooltip(side: string): string {
		const eside = ElementSide[side];
		const isLeftSideOfMarquiseOnLeftEdgeOfRoof = this.getIsLeftSideOfMarquiseOnLeftEdgeOfRoof(this.currMarq.firstGlass, eside);
		const isRightSideOfMarquiseOnRightEdgeOfRoof = this.getIsRightSideOfMarquiseOnRightEdgeOfRoof(this.currMarq.lastGlass, eside);

		const marquiseOnLeftSide = this.currMarq.getMarquiseOnSide(ElementSide.Left, this.profileService.roofElements) != null;
		const marquiseOnRightSide = this.currMarq.getMarquiseOnSide(ElementSide.Right, this.profileService.roofElements) != null;

		const isExpandedLeft = this.getIsExpandedLeft(this.currMarq.firstGlass, eside);
		const isExpandedRight = this.getIsExpandedRight(this.currMarq.lastGlass, eside);


		if (this.isChangePositionBtnDisable(side)) {
			if (isLeftSideOfMarquiseOnLeftEdgeOfRoof || isRightSideOfMarquiseOnRightEdgeOfRoof) {
				return $localize`:Marquise|Validation tooltip:Cannot put marquise beyond the roof!`;
			} else if (marquiseOnLeftSide || marquiseOnRightSide || isExpandedLeft || isExpandedRight) {
				return $localize`:Marquise|Validation tooltip:Cannot put marquise over another marquise!`;
			}
		}

		return eside == ElementSide.Left ? $localize`:Marquise|Tooltip:Move left` : $localize`:Marquise|Tooltip:Move right`;

	}

	changeWidthTooltip(side: string): string {
		const eside = ElementSide[side];
		if (!this.isChangeWidthBtnDisable(side)) {
			return eside == ElementSide.Left ? $localize`:Marquise|Tooltip:Decrease width` : $localize`:Marquise|Tooltip:Increase width`;
		}

		const marquiseOnRightSide = this.currMarq.getMarquiseOnSide(ElementSide.Right, this.profileService.roofElements) != null;
		const isExpandedRight = this.getIsExpandedRight(this.currMarq.lastGlass, eside);


		if (eside == ElementSide.Right) {
			if (this.currMarq.lastGlass.isLast) {
				return $localize`:Marquise|Validation tooltip:You can't make marquise wider then roof!`;
			} else if (marquiseOnRightSide || isExpandedRight) {
				return $localize`:Marquise|Validation tooltip:Cannot put marquise over another marquise!`;
			} else {
				// return $localize`The new width of the marquise (${this.futureMarquiseWidth}mm) is more then its maximum allowable width (${this.maxWidth}mm)!`;
				return $localize`:Marquise|Validation tooltip:The new width of the marquise is greater then it's maximum allowable width!`;
			}
		} else if (eside == ElementSide.Left) {
			// return $localize`The new width of the marquise (${this.futureMarquiseWidth}mm) is less than its minimum allowable width (${this.minWidth}mm)!`;
			return $localize`:Marquise|Validation tooltip:The new width of the marquise is less than it's minimum allowable width!`;
		}
	}

	expandTooltip(side: string) {
		if (this.isExpandBtnDisable(ElementSide[side])) {
			return $localize`:Marquise|Validation tooltip:Cannot expand marquise over another marquise!`;
		}
	}

	// common functions

	private getIsLeftSideOfMarquiseOnLeftEdgeOfRoof(firstGlassUnderMarquise: Glass, side: ElementSide) {
		return firstGlassUnderMarquise.isFirst && side == ElementSide.Left
	}

	private getIsRightSideOfMarquiseOnRightEdgeOfRoof(lastGlassUnderMarquise: Glass, side: ElementSide) {
		return lastGlassUnderMarquise.isLast && side == ElementSide.Right;
	}

	private getIsExpandedLeft(firstGlassUnderMarquise: Glass, side: ElementSide) {
		const isMarquiseOnLeftAfterOneGlassExpanded = this.profileService.roofElements[this.currMarq.type].find((m: Marquise) => {
			if (!firstGlassUnderMarquise.isFirst && side == ElementSide.Left) {
				return m.lineOnRoof.x3 === firstGlassUnderMarquise.leftBar.leftGlass.leftBar.rectOnRoof?.x + firstGlassUnderMarquise.leftBar.leftGlass.leftBar.rectOnRoof?.w;
			}
		});

		return side == ElementSide.Left && this.currMarq.isExpandedLeft && isMarquiseOnLeftAfterOneGlassExpanded;
	}

	private getIsExpandedRight(lastGlassUnderMarquise: Glass, side: ElementSide) {
		const isMarquiseOnRightAfterOneGlassExpanded = this.profileService.roofElements[this.currMarq.type].find((m: Marquise) => {
			if (!lastGlassUnderMarquise.isLast && side == ElementSide.Right) {
				return  m.lineOnRoof.x1 === lastGlassUnderMarquise.rightBar.rightGlass.rightBar.rectOnRoof?.x;
			}
		});

		return side == ElementSide.Right && this.currMarq.isExpandedRight && isMarquiseOnRightAfterOneGlassExpanded;
	}

	deleteElement() {
		this.choosenProfileService.setChosenProfile(null);
		this.projectService.deleteElement(this.currMarq);
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}

}
