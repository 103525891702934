import { Injectable } from '@angular/core';
import { ElementType } from '../models/ToolboxModel';
import { GlassWallElemsToPrint } from '../models/glasses/glassWallElemsToPrint.model';
import { IDimensionsGroup } from '../interfaces/IDimensionsGroup';
import { Tags, SvgParams, DimensionLineParams, GLASS_PART_PADDING } from '../constants/constants';
import { GlassPartDiamond } from '../models/glasses/glassDiamondPart';
import { Common } from '../models/common';
import { DimensionLine } from '../models/dimensions/dimension-line.model';
import { DimensionText } from '../models/dimensions/dimension-text.model';
import { GlassWallDiamond } from '../models/glasses/glassWallDiamond.model';
import { IGlassSpec } from '../interfaces/specs/IGlassSpec';
import { WallProfile } from '../models/profiles/wall.model';
import { Glass } from '../models/glasses/glass.model';
import { ColumnProfile } from '../models/profiles/column.model';
import { FrontProfile } from '../models/profiles/front.model';
import { ProfileService } from './profile.service';
import { ProjectService } from './project.service';
import { LED } from '../models/profiles/leds.model';
import { ElementSide } from '../interfaces/IElementSide';
import { AreaType, CollectionName, RectName } from '../interfaces/IAreaType';
import { SideBaseService } from './areas/side-base.service';
import { RightSideService } from './areas/right-side.service';
import { LeftSideService } from './areas/left-side.service';
import { MarquiseTop } from '../models/marquises/marquise-top.model';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { Door } from '../models/doors/door.model';
import { IRect } from '../interfaces/IRect';
import { VerticalElement } from '../models/vertical-element';
import { Profile } from '../models/profiles/profile.model';

@Injectable({
	providedIn: 'root'
})
export class DimensionsService {

	private leftFrontHeightIx: number = -1;
	private leftColHeightIx: number = -1;
	private rightFrontHeightIx: number = -1;
	private rightColHeightIx: number = -1;

	private _showArea: boolean = true;
	private _showElements: boolean = true;

	private foundHeight: number;

	constructor(
		private profileService: ProfileService,
		private projectService: ProjectService,
		private rightSideService: RightSideService,
		private leftSideService: LeftSideService,
	) { }

	public displayDimensions(showArea: boolean = true, showElements: boolean = false) {
		if (this._showArea != showArea || this._showElements != showElements) {
			this.projectService.clearCollections(ElementType.Dimension);
		}
		this._showArea = showArea;
		this._showElements = showElements;

		if (this.profileService.roofElements[ElementType.Dimension].length === 0) {

			this.displayDimensionsOnRoof();

			const frontH = this.projectService.template.getFrontSize().height;
			const front = this.profileService.getFrontOnSide(this.profileService.frontElements, Tags.LEFT_FRONT_TAG);
			this.displayDimensionsOnStraight(AreaType.Front, frontH, front, Tags.LEFT_COLUMN_TAG);

			if (this.projectService.template.isStandalone) {
				const rearH = this.projectService.template.getRearSize().height;
				const rear = this.profileService.getRearOnSide(this.profileService.rearElements, Tags.LEFT_REAR_TAG);
				this.displayDimensionsOnStraight(AreaType.Rear, rearH, rear, Tags.LEFT_REAR_COLUMN_TAG);
			}

			this.displayDimensionsOnLeftSide();
			this.displayDimensionsOnRightSide();
		}
	}

	//#region Glass wall diamond

	public getDimensionsForSpecificGlassWallDiamond(area: AreaType, glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const dimensionsForSpecificGlassWallDiamond = [];
		this.getWidthDimensionsForSpecificGlassWallDiamond(area, glassWallElements).forEach(e => dimensionsForSpecificGlassWallDiamond.push(e));
		this.getHeightDimensionsForSpecificGlassWallDiamond(area, glassWallElements).forEach(e => dimensionsForSpecificGlassWallDiamond.push(e));
		this.getWidthDimensionsForWholeGlassWallDiamond(area, glassWallElements).forEach(e => dimensionsForSpecificGlassWallDiamond.push(e));

		return dimensionsForSpecificGlassWallDiamond;
	}

	private getWidthDimensionsForWholeGlassWallDiamond(area: AreaType, glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const widthDimens = [];
		glassWallElements.GlassWallDiamond.filter((v: VerticalElement) => v.area == area).forEach((e: GlassPartDiamond) => {
			const x1 = e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x;
			const y1 = e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y + 40;
			const x2 = x1 - Common.round(e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x);
			const y2 = y1;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, ElementSide.Bottom);

			const x = x2 + (x1 - x2) / 2;
			const y = y1 + 20;
			const length = Common.round((e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGoup: IDimensionsGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			widthDimens.push(newDimensionGoup);
		})

		return widthDimens;
	}

	private getWidthDimensionsForSpecificGlassWallDiamond(area: AreaType, glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const padding = GLASS_PART_PADDING;
		const widthDimens = [];
		glassWallElements.GlassPartDiamond.filter((v: VerticalElement) => v.area == area).forEach((e: GlassPartDiamond) => {
			var w = GlassPartDiamond.getWidthCorrected(e.points, false, true) + GLASS_PART_PADDING / SvgParams.SCALE * 2 + 18 * 2;

			const x1 = e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x + padding;
			const y1 = e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y + padding + DimensionLineParams.FIRST_LEVEL_DIMENSION;
			const x2 = x1 - Common.round(e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) - padding * 2;
			const y2 = y1;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Bottom);

			const x = x2 + (x1 - x2) / 2;
			const y = y1 + 20;
			const length = Common.round((e.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - e.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x + padding * 2) / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGoup: IDimensionsGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			widthDimens.push(newDimensionGoup);
		})

		return widthDimens;
	}

	private getHeightDimensionsForSpecificGlassWallDiamond(area: AreaType, glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const padding = GLASS_PART_PADDING;
		const dimens = [];

		glassWallElements.GlassWallDiamond.filter((v: VerticalElement) => v.area == area).forEach((gWD: GlassWallDiamond) => {
			let sideService: SideBaseService;
			if (gWD.points[SvgParams.LEFT_TOP_POINT_NUM].y > gWD.points[SvgParams.RIGHT_TOP_POINT_NUM].y) {
				sideService = this.rightSideService;
			} else {
				sideService = this.leftSideService;
			}
	
			// left edge of glass wall

			const lx1 = gWD.points[SvgParams.LEFT_TOP_POINT_NUM].x - 8;
			const ly1 = gWD.points[SvgParams.LEFT_TOP_POINT_NUM].y;
			const lx2 = lx1;
			const ly2 = gWD.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y;

			const lx = lx1 - 5;
			const ly = ly1 + (ly2 - ly1) / 2;
			const leftHeight = Common.round((ly2 - ly1) / SvgParams.SCALE);

			// right edge of glass wall

			const rx1 = gWD.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x + padding + 12;
			const ry1 = gWD.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y;
			const ry2 = gWD.points[SvgParams.RIGHT_TOP_POINT_NUM].y;

			const rx = rx1 + 15
			const ry = ry2 + (ry1 - ry2) / 2;
			const rightHeight = Common.round((ry1 - ry2) / SvgParams.SCALE);

			dimens.push(this.createDimension(lx1, lx1, ly2, ly1, DimensionLineParams.SECOND_LEVEL_DIMENSION, lx, ly, leftHeight, true, ElementSide.Left));
			dimens.push(this.createDimension(rx1, rx1, ry2, ry1, DimensionLineParams.FIRST_LEVEL_DIMENSION, rx, ry, rightHeight, true, ElementSide.Right));
		})

		return dimens;
	}


	//#endregion

	//#region Glass wall

	public getDimensionsForSpecificGlassWall(glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const dimensionsForSpecificGlassWall = [];
		this.getWidthDimensionsForSpecificGlassWall(glassWallElements).forEach(e => dimensionsForSpecificGlassWall.push(e));
		this.getHeightDimensionsForSpecificGlassWall(glassWallElements).forEach(e => dimensionsForSpecificGlassWall.push(e));
		this.getWidthDimensionForWholeGlassWall(glassWallElements).forEach(e => dimensionsForSpecificGlassWall.push(e));

		return dimensionsForSpecificGlassWall;
	}

	private getWidthDimensionsForSpecificGlassWall(glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const padding = GLASS_PART_PADDING * 2;
		const widthDimens = [];
		glassWallElements.GlassPart.forEach(e => {
			if (e.master.parts.length == 1) {
				return;
			}
			const x1 = e.rect.x - GLASS_PART_PADDING + e.rect.w + padding;
			const y1 = e.rect.y + e.rect.h + 15;
			const x2 = e.rect.x - GLASS_PART_PADDING;
			const y2 = y1;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Bottom);

			const x = x2 + (x1 - x2) / 2;
			const y = y1 + 15;
			const length = Common.round((e.rect.w + padding) / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGoup: IDimensionsGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			widthDimens.push(newDimensionGoup);
		})

		return widthDimens;
	}

	private getWidthDimensionForWholeGlassWall(glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const widthDimens = [];
		glassWallElements.GlassWall.forEach(e => {
			const x1 = e.rect.x + e.rect.w;
			const y1 = e.rect.y + e.rect.h + 40;
			const x2 = e.rect.x;
			const y2 = y1;

			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, ElementSide.Bottom);

			const x = x2 + (x1 - x2) / 2;
			const y = y1 + 20;
			const length = Common.round((e.rect.w) / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGoup: IDimensionsGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			widthDimens.push(newDimensionGoup);
		})

		return widthDimens;
	}

	private getHeightDimensionsForSpecificGlassWall(glassWallElements: GlassWallElemsToPrint): IDimensionsGroup[] {
		const dimens = [];
		glassWallElements.GlassWall.forEach(e => {
			const x1 = e.rect.x - 10;
			const y1 = e.rect.y;
			const x2 = x1;
			const y2 = e.rect.y + e.rect.h;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Left);

			const x = x1 - 5;
			const y = y1 + (y2 - y1) / 2;
			const length = Common.round(e.rect.h / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

			const newDimensionGoup: IDimensionsGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			dimens.push(newDimensionGoup);
		})

		return dimens;
	}

	//#endregion

	//#region Marquise | Door


	public getDimensionsForWing(wing: IRect): IDimensionsGroup[] {
		var dims: IDimensionsGroup[] = [];

		const w = wing.w / SvgParams.SCALE;

		var wt: DimensionText = new DimensionText(wing.x + wing.w / 2, wing.y + wing.h - 5, w, false, true);
		wt.textAnchor = "middle";
		// wt.parathenses = true;
		const wd = {
			text: wt,
			line: null
		};
		dims.push(wd);

		const h = wing.h / SvgParams.SCALE;

		var ht: DimensionText = new DimensionText(wing.x + 12, wing.y + wing.h - 15, h, true, true);
		ht.textAnchor = "start";
		// ht.parathenses = true;
		const hd = {
			text: ht,
			line: null
		};
		dims.push(hd);

		return dims;
	}

	public getDimensionsForVertical(elements: MarquiseVertical[] | Door[]): IDimensionsGroup[] {
		var dims: IDimensionsGroup[] = [];
		elements.forEach(m => {
			dims.push(this.getWidthDimensionsForVertical(m));
			dims.push(this.getHeightDimensionsForVertical(m));
			if (m instanceof Door) {
				this.getDimensionsForWing(m.wings[0]).forEach(d => { dims.push(d) });
			}
		});

		return dims;
	}

	private getWidthDimensionsForVertical(e: MarquiseVertical | Door): IDimensionsGroup {
		const rect = e instanceof MarquiseVertical ? 'rectOn' + e.area : 'rect';
		const hs = e instanceof MarquiseVertical ? 10 : 40;
		const x1 = e[rect].x;
		const y1 = e[rect].y + e[rect].h + hs;
		const x2 = e[rect].x + e[rect].w;
		const y2 = y1;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.INNER_DIMENSION, ElementSide.Bottom);

		const x = x1 + (x2 - x1) / 2;
		const y = y1 + 15;
		const length = Common.round((x2 - x1) / SvgParams.SCALE);

		const newDimensionText: DimensionText = new DimensionText(x, y, length, false);
		const dimgr: IDimensionsGroup = { line: newDimensionLine, text: newDimensionText };

		return dimgr;
	}

	private getHeightDimensionsForVertical(e: MarquiseVertical | Door): IDimensionsGroup {
		const rect = e instanceof MarquiseVertical ? 'rectOn' + e.area : 'rect';
		const xs = e instanceof MarquiseVertical ? 10 : 30;
		const x1 = e[rect].x - xs;
		const y1 = e[rect].y;
		const x2 = x1;
		const y2 = e[rect].y + e[rect].h;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Left);

		const x = x1 - 5;
		const y = y1 + (y2 - y1) / 2;
		const length = Common.round(e[rect].h / SvgParams.SCALE);
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGoup: IDimensionsGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};


		return newDimensionGoup;
	}

	public getDimensionsForMarquisesTop(marquises: MarquiseTop[]): IDimensionsGroup[] {
		var dims: IDimensionsGroup[] = [];
		marquises.forEach(m => {
			dims.push(this.getWidthDimensionsForMarquiseTop(m));
			const d = this.getHeightDimensionsForMarquise(m);
			if (d != null) {
				dims.push(d);
			}
		});

		return dims;
	}

	private getWidthDimensionsForMarquiseTop(e: MarquiseTop): IDimensionsGroup {
		const x1 = e.lineOnRoof.x1
		const y1 = e.lineOnRoof.y4 + 35;
		const x2 = e.lineOnRoof.x3;
		const y2 = y1;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Bottom);

		const x = x1 + (x2 - x1) / 2;
		const y = y1 + 15;
		const length = Common.round((x2 - x1) / SvgParams.SCALE);

		const newDimensionText: DimensionText = new DimensionText(x, y, length, false);
		const dimgr: IDimensionsGroup = { line: newDimensionLine, text: newDimensionText };

		return dimgr;
	}

	private getHeightDimensionsForMarquise(e: MarquiseTop): IDimensionsGroup {
		const x1 = e.lineOnRoof.x1 - 25
		const y1 = e.lineOnRoof.y2;
		const x2 = x1;
		const y2 = e.lineOnRoof.y4;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, ElementSide.Left);

		const x = x1 - 5;
		const y = y1 + (y2 - y1) / 2;
		const length = Common.round(e.getUIDepth());
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGoup: IDimensionsGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		return newDimensionGoup;
	}

	//#endregion

	//#region Glass

	public getDimensionsForSpecificGlass(specificGlass: IGlassSpec): IDimensionsGroup[] {
		const dimensionsForSpecificGlass = [];
		dimensionsForSpecificGlass.push(this.getWidthDimensionsForSpecificGlass(specificGlass));
		dimensionsForSpecificGlass.push(this.getHeightDimensionsForSpecificGlass(specificGlass));

		return dimensionsForSpecificGlass;
	}

	private getWidthDimensionsForSpecificGlass(specificGlass: IGlassSpec): IDimensionsGroup {
		const x1 = SvgParams.START_X;
		const y1 = SvgParams.START_Y - 10;
		const x2 = SvgParams.START_X + specificGlass.width * SvgParams.SCALE;
		const y2 = SvgParams.START_Y - 10;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Top);

		const x = SvgParams.START_X + specificGlass.width * SvgParams.SCALE / 2;
		const y = SvgParams.START_Y - 20;
		const length = Common.round(specificGlass.width);
		const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

		const newDimensionGoup: IDimensionsGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		return newDimensionGoup
	}

	private getHeightDimensionsForSpecificGlass(specificGlass: IGlassSpec): IDimensionsGroup {
		const x1 = SvgParams.START_X - 10;
		const y1 = SvgParams.START_Y + specificGlass.height * SvgParams.SCALE;
		const x2 = SvgParams.START_X - 10;
		const y2 = SvgParams.START_Y;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Left);

		const x = SvgParams.START_X - 20;
		const y = y2 + (y1 - y2) / 2;
		// const length = specificGlass.height;
		const length = Common.round(specificGlass.height / Math.cos(Common.toRadians(this.projectService.template.dropAngle)));
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGoup: IDimensionsGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		return newDimensionGoup
	}

	//#endregion

	//#region Roof

	private displayDimensionsOnRoof() {
		if (this._showArea) {
			this.displayDimensionOfGlassWidthOnRoof();
			this.displayDimensionBetweenColumnsOnRoof();
			if (this.projectService.template.isStandalone) {
				this.displayDimensionBetweenRearColumns(AreaType.Roof);
			} else {
				this.displayDimensionOfRearLength();
			}
			this.displayDimensionOfRoofWidth();

			// https://trello.com/c/zCMLUAXC
			this.displayDimensionOfProjDepthTillColumnExt(); // Linia 1 (obowiazkowa) Do zew. krawedzi slupa		
			this.displayDimensionOfProjDepthTillColumnInt(); // Linia 2(obowiazkowa) Do wew.krawedzi slupa
			this.displayDimensionOfProjDepthTillRinne(); // Linia 3 (opcjonalna, jezeli rynna jest cofnieta) Do zew. krawedzi rynny

			this.displayDimensionBetweenLedSpot();
		}

		if (this._showElements) {
			this.getDimensionsForMarquisesTop(this.profileService.roofElements[ElementType.MarquiseTopUp]).forEach(d => {
				this.profileService.roofElements[ElementType.Dimension].push(d);
			})
			this.getDimensionsForMarquisesTop(this.profileService.roofElements[ElementType.MarquiseTopBottom]).forEach(d => {
				this.profileService.roofElements[ElementType.Dimension].push(d);
			})
		}
	}

	private displayDimensionOfRoofWidth() {
		if (!(this.projectService.template.isStandalone || this.profileService.roofElements[ElementType.WallProfile].length > 1)) {
			return;
		}
		const rear = this.profileService.roofElements[ElementType.WallProfile][0];
		const distance = this.projectService.template.isStandalone ? DimensionLineParams.FIFTH_LEVEL_DIMENSION : DimensionLineParams.FOURTH_LEVEL_DIMENSION + 10;
		const x1 = SvgParams.START_X;
		const y1 = rear.rectOnRoof.y - distance;
		const x2 = SvgParams.START_X + this.projectService.template.width * SvgParams.SCALE;
		const y2 = y1;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, distance, ElementSide.Top);

		const x = SvgParams.START_X + (x2 - x1) / 2;
		const y = y1 - 5;
		const newDimensionText: DimensionText = new DimensionText(x, y, this.projectService.template.width, false);

		const newDimensionGoup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		this.profileService.roofElements[ElementType.Dimension].push(newDimensionGoup);
	}

	private displayDimensionOfRearLength() {
		this.profileService.roofElements[ElementType.WallProfile].forEach((rear: WallProfile) => {
			const x1 = rear.rectOnRoof.x;
			const y1 = rear.rectOnRoof.y - DimensionLineParams.SECOND_LEVEL_DIMENSION;
			const x2 = rear.rectOnRoof.x + rear.rectOnRoof.w;
			const y2 = rear.rectOnRoof.y - DimensionLineParams.SECOND_LEVEL_DIMENSION;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, ElementSide.Top);

			const x = rear.rectOnRoof.x + rear.rectOnRoof.w / 2;
			const y = rear.rectOnRoof.y - 30;
			const length = Common.round(rear.rectOnRoof.w / SvgParams.SCALE);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGoup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			this.profileService.roofElements[ElementType.Dimension].push(newDimensionGoup);
		});
	}

	private displayDimensionOfGlassWidthOnRoof() {
		const wallD = this.projectService.template.getRearSize().depth;

		this.profileService.roofElements[ElementType.Glass].forEach((glass: Glass) => {
			const x1 = glass.rect.x + glass.stat.paddingLeft * SvgParams.SCALE;
			const y1 = SvgParams.START_Y + wallD * SvgParams.SCALE + 30;
			const x2 = glass.rect.x + glass.rect.w - glass.stat.paddingRight * SvgParams.SCALE;
			const y2 = SvgParams.START_Y + wallD * SvgParams.SCALE + 30;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.INNER_DIMENSION, ElementSide.Top);

			const x = glass.rect.x + (glass.rect.w - glass.stat.paddingRight * SvgParams.SCALE) / 2;
			const y = SvgParams.START_Y + wallD * SvgParams.SCALE + 20;
			const length = Math.round(glass.rect.w / SvgParams.SCALE - glass.stat.paddingLeft - glass.stat.paddingRight);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false, true);
			const newDimensionGoup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			this.profileService.roofElements[ElementType.Dimension].push(newDimensionGoup);
		});
	}

	private displayDimensionBetweenRearColumns(side: AreaType) {
		var cols = this.profileService.getSortedColumns(CollectionName.get(AreaType.Roof), AreaType.Rear).filter(c => c.isRear).reverse();
		if (cols.length == 0) {
			return;
		}
		const lastC = cols[0];
		const firstC = cols[cols.length - 1];
		const by = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_TOP_POINT_NUM].y;

		this.displayDimensionBetweenExtremeColumns(firstC, lastC, by - 10, AreaType.Roof);
		if (cols.length == 2) {
			return;
		}

		const y1 = by - 25;
		const y2 = y1;
		const y = by - 30;

		let nc: ColumnProfile;
		if (firstC.rectOnRoof.x > SvgParams.START_X) {
			nc = new ColumnProfile(side, SvgParams.START_X - firstC.rectOnRoof.w / 2, firstC.rectOnRoof.y, firstC.length, side, firstC.configId, this.projectService.template);
			cols.splice(0, 0, nc);
		}

		const wi = this.projectService.template.width * SvgParams.SCALE;
		if (lastC.rectOnRoof.x + lastC.rectOnRoof.w < wi + SvgParams.START_X) {
			nc = new ColumnProfile(side, SvgParams.START_X + wi - lastC.rectOnRoof.w / 2, lastC.rectOnRoof.y, lastC.length, side, lastC.configId, this.projectService.template);
			cols.push(nc);
		}

		cols.forEach((c: ColumnProfile, index: number) => {
			if (index < cols.length - 1) {

				const nextC = cols[++index];

				const x1 = nextC.rectOnRoof.x + nextC.rectOnRoof.w / 2;;
				const x2 = c.rectOnRoof.x + c.rectOnRoof.w / 2;
				const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, ElementSide.Top, false);

				const x = x2 + (x1 - x2) / 2;
				const length = Math.abs(Common.round((x1 - x2) / SvgParams.SCALE));
				const newDimensionText: DimensionText = new DimensionText(x, y, length, false, true);

				const newDimensionGoup = {
					line: newDimensionLine,
					text: newDimensionText,
				};
				this.profileService.roofElements[ElementType.Dimension].push(newDimensionGoup);
			}
		});
	}

	private displayDimensionBetweenColumnsOnRoof() {
		const frontYPointOfFrontColumns = this.getYPointOfColumns(AreaType.Roof);
		var cols = this.profileService.getSortedColumns(CollectionName.get(AreaType.Roof), AreaType.Front);
		const firstC = cols[0];
		const lastC = cols[cols.length - 1];
		const by = 5;
		const rn = RectName.get(AreaType.Roof);

		if (cols.length > 2) {
			this.displayDimensionBetweenExtremeColumns(firstC, lastC, frontYPointOfFrontColumns, AreaType.Roof);
		}

		const y1 = frontYPointOfFrontColumns + by;
		const y2 = frontYPointOfFrontColumns + by;
		const y = frontYPointOfFrontColumns + by + 15;

		let nc: ColumnProfile;
		if (firstC.rectOnRoof.x > SvgParams.START_X) {
			nc = new ColumnProfile(AreaType.Roof, SvgParams.START_X - firstC.rectOnRoof.w / 2, firstC.rectOnRoof.y, firstC.length, AreaType.Roof, firstC.configId, this.projectService.template);
			cols.splice(0, 0, nc);
		}

		const wi = this.projectService.template.width * SvgParams.SCALE;
		if (lastC.rectOnRoof.x + lastC.rectOnRoof.w < wi + SvgParams.START_X) {
			nc = new ColumnProfile(AreaType.Roof, SvgParams.START_X + wi - lastC.rectOnRoof.w / 2, lastC.rectOnRoof.y, lastC.length, AreaType.Roof, lastC.configId, this.projectService.template);
			cols.push(nc);
		}

		cols.forEach((c: ColumnProfile, index: number) => {
			if (index < cols.length - 1) {
				const nextC = cols[++index];

				const x1 = nextC[rn].x + nextC[rn].w / 2;
				const x2 = c[rn].x + c[rn].w / 2;

				const x = x2 + (x1 - x2) / 2;
				const length = Common.round((x1 - x2) / SvgParams.SCALE);

				this.createAndStoreDimension(this.profileService[CollectionName.get(AreaType.Roof)], x1, x2, y1, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, x, y, length, "", ElementSide.Bottom, false);
			}
		});
	}

	private getYPointOfColumns(area: AreaType) {
		const ySetOfColumnsFronts = new Set<number>();
		const s = RectName.get(area);
		this.profileService.roofElements[ElementType.Column].forEach((c: ColumnProfile) => {
			if (area == AreaType.Roof) {
				ySetOfColumnsFronts.add(c.marker.loc.y);
			} else {
				if (c[s] != null) {
					ySetOfColumnsFronts.add(c[s].y + c[s].h);
				}
			}
		})

		return Array.from(ySetOfColumnsFronts).sort((y1: number, y2: number) => y1 < y2 ? 1 : -1)[0];
	}

	private getFilteredFrontColumnsByX(frontYPointOfFrontColumns: number, side: AreaType) {
		const s = 'rectOn' + side;
		const sideName = side == AreaType.Roof ? "roof" : "front";
		const allFrontColumns = this.profileService[sideName + 'Elements'][ElementType.Column].filter((c: ColumnProfile) => {
			if (side == AreaType.Roof) {
				return c.marker.loc.y === frontYPointOfFrontColumns;
			} else {
				return c[s].y + c[s].h === frontYPointOfFrontColumns
			}
		})

		return allFrontColumns.sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRoof.x > c2.rectOnRoof.x ? 1 : -1);
	}

	private displayDimensionBetweenExtremeColumns(firstC: ColumnProfile, lastC: ColumnProfile, yPointOfColumns: number, area: AreaType) {
		const s = RectName.get(area);
		var by = area == AreaType.Roof ? (firstC.isFront ? 40 : -35) : 60 + this.foundHeight * SvgParams.SCALE;
		const x1 = lastC[s].x + lastC[s].w / 2;
		const y1 = yPointOfColumns + by;
		const x2 = firstC[s].x + firstC[s].w / 2;
		const y2 = y1;
		var side = firstC.isFront ? ElementSide.Bottom : ElementSide.Top;
		if (area == AreaType.Rear) {
			side = ElementSide.Bottom;
		}
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.THIRD_LEVEL_DIMENSION, side);

		const x = x2 + (x1 - x2) / 2;
		var y = yPointOfColumns + by;
		if (area == AreaType.Roof || area == AreaType.Front) {
			y += firstC.isFront ? 15 : -5;
		} else {
			y += 15;
		}
		const length = Math.abs(Common.round((x1 - x2) / SvgParams.SCALE));
		const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

		const newDimensionGoup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		this.profileService[CollectionName.get(area)][ElementType.Dimension].push(newDimensionGoup);
	}

	private displayDimensionOfProjDepthTillColumnInt() {
		const lefCol: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((c: ColumnProfile) => c.tag === Tags.LEFT_COLUMN_TAG || c.tag == Tags.ANY_FRONT_TAG)

		const x1 = SvgParams.START_X - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		const y1 = lefCol.rectOnRoof.y;
		const x2 = SvgParams.START_X - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		const y2 = SvgParams.START_Y;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Left);

		const x = SvgParams.START_X - 20;
		const y = y2 + (y1 - y2) / 2;
		const length = Common.round((y1 - y2) / SvgParams.SCALE);
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true, true);

		const newDimensionGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		this.profileService.roofElements[ElementType.Dimension].push(newDimensionGroup);
	}

	private displayDimensionOfProjDepthTillColumnExt() {
		const front: FrontProfile = this.profileService.roofElements[ElementType.Front].find((c: FrontProfile) => c.tag === Tags.LEFT_FRONT_TAG || c.tag == Tags.ANY_FRONT_TAG);
		const lefCol: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((c: ColumnProfile) => c.tag === Tags.LEFT_COLUMN_TAG || c.tag == Tags.ANY_FRONT_TAG)

		const x1 = SvgParams.START_X - DimensionLineParams.FOURTH_LEVEL_DIMENSION;
		const y1 = lefCol.rectOnRoof.y + lefCol.rectOnRoof.h;
		const x2 = SvgParams.START_X - DimensionLineParams.FOURTH_LEVEL_DIMENSION;
		const y2 = SvgParams.START_Y;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FOURTH_LEVEL_DIMENSION, ElementSide.Left);

		const x = SvgParams.START_X - 50;
		const y = y2 + (y1 - y2) / 2;
		const length = Common.round((y1 - y2) / SvgParams.SCALE);
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true, !!front.gutter);

		const newDimensionGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		this.profileService.roofElements[ElementType.Dimension].push(newDimensionGroup);
	}

	private displayDimensionOfProjDepthTillRinne() {
		const front: FrontProfile = this.profileService.roofElements[ElementType.Front].find((c: FrontProfile) => c.tag === Tags.LEFT_FRONT_TAG || c.tag == Tags.ANY_FRONT_TAG);
		if (!front.gutter) {
			return;
		}

		const x1 = SvgParams.START_X - DimensionLineParams.FIFTH_LEVEL_DIMENSION;
		const y1 = front.gutter.rectOnRoof.y + front.gutter.rectOnRoof.h;
		const x2 = SvgParams.START_X - DimensionLineParams.FIFTH_LEVEL_DIMENSION;
		const y2 = SvgParams.START_Y;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIFTH_LEVEL_DIMENSION, ElementSide.Left);

		const x = SvgParams.START_X - 80;
		const y = y2 + (y1 - y2) / 2;
		const length = Common.round((y1 - y2) / SvgParams.SCALE);
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		this.profileService.roofElements[ElementType.Dimension].push(newDimensionGroup);
	}

	//#region LEDs

	private displayDimensionBetweenLedSpot() {
		const allLeds = this.profileService.roofElements[ElementType.Led];

		if (!allLeds) {
			return
		}

		var barleds: Map<string, LED[]> = new Map<string, LED[]>();
		allLeds.forEach((led: LED) => {
			var key = led.circle.x.toString();
			if (barleds[key] == null) {
				barleds[key] = [];
			}
			barleds[key].push(led);
		});

		const rear = this.profileService.roofElements[ElementType.WallProfile][0];
		const front = this.profileService.roofElements[ElementType.Front][0];
		const rearLocation = rear.rectOnRoof.y + rear.rectOnRoof.h;
		const frontLocation = front.rectOnRoof.y;

		Object.keys(barleds).map(key => {
			const leds: LED[] = barleds[key];
			var first = leds[0];
			const last = leds[leds.length - 1];
			this.createDimensionForLedSpot(first.circle.x, first.circle.y, rearLocation);

			if (first != last) { // more than 1 led on a bar
				barleds[key].forEach((led: LED) => {
					this.createDimensionForLedSpot(led.circle.x, led.circle.y, first.circle.y);
					first = led;
				});
			}

			this.createDimensionForLedSpot(last.circle.x, frontLocation, last.circle.y);
		});

	}

	private createDimensionForLedSpot(xValue: number, y1Value: number, y2Value: number) {
		const y1 = y1Value;
		const y2 = y2Value;

		const x = xValue - 5;
		const y = y2 + (y1 - y2) / 2;
		const length = Common.round((y1 - y2) / SvgParams.SCALE);
		if (length == 0) {
			return;
		}
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true, true);

		const newDimensionGroup = {
			line: null,
			text: newDimensionText,
		};

		this.profileService.roofElements[ElementType.Dimension].push(newDimensionGroup);
	}

	//#endregion

	//#endregion

	//#region Front

	private displayDimensionsOnStraight(area: AreaType, frontH: number, profile: Profile, lcTag: string) {


		this.foundHeight = 0;
		this.profileService.frontElements[ElementType.Column].forEach((c: ColumnProfile) => {
			if (c.foundation && c.foundation.size) {
				this.foundHeight = Math.max(this.foundHeight, c.foundation.size.height);
			}
		});

		if (this._showArea) {
			this.displayDimensionOfProfileHeight(area, frontH, profile);
			this.displayDimensionOfColumnLength(area, lcTag);
			this.displayDimensionOfColumnWidth(area);
			this.displayDimensionOfGlassWidth(area);
			this.displayDimensionBetweenColumns(area);

			// if (!this._showElements) {
			// 	this.profileService.getDoors(area).forEach((d: Door) => {
			// 		this.getDimensionsForWing(d.wings[0]).forEach(d => {
			// 			this.profileService.frontElements[ElementType.Dimension].push(d);
			// 		});
			// 	});
			// }
		}

		if (this._showElements) {
			this.displayVerticalsOnSide(area);
		}

	}

	private displayDimensionOfProfileHeight(area: AreaType, frontH: number, profile: Profile) {
		var elems = this.profileService[CollectionName.get(area)];
		const rn = RectName.get(area);

		const y1 = profile[rn].y + frontH * SvgParams.SCALE;
		const y2 = profile[rn].y;

		const x = profile[rn].x - 20;
		const y = y2 + (y1 - y2) / 2;
		const length = frontH;
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true, true);

		const newDimensionGroup = {
			line: null,
			text: newDimensionText,
		};

		elems[ElementType.Dimension].push(newDimensionGroup);
	}

	private displayDimensionOfColumnLength(area: AreaType, tag: string) {
		var columns: ColumnProfile[] = [];
		this.profileService.roofElements[ElementType.Column].forEach(c => {
		 	if ((c.isFront && area == AreaType.Front) || (c.isRear && area == AreaType.Rear)) {
		 		columns.push(c);
		 	}
		})
		
		let diffColumns = [...new Set(columns.map(item => Math.round(item.length)))].length;
		let diffFoundations = [...new Set(columns.map(item => item.foundation?.size.height))].length;

		if (diffColumns == 1 && diffFoundations == 1) {
			if (area == AreaType.Rear) {
				this._displayColumnDimension(columns[columns.length - 1], area);
			} else {
				this._displayColumnDimension(columns[0], area);
			}
			
		} else {
			columns.forEach((c: ColumnProfile) => {
				this._displayColumnDimension(c, area);
			});
		}
	}

	private _displayColumnDimension(c: ColumnProfile, area: AreaType){
		const rn = RectName.get(area);
		const x1 = c[rn].x - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		const y1 = c[rn].y + c[rn].h;
		const x2 = c[rn].x - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		const y2 = c[rn].y;
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, c[rn].side);

		const x = c[rn].x - 20;
		const y = y2 + (y1 - y2) / 2;
		const length = Common.round(c.getLength());
		const newDimensionText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};
		this.profileService[CollectionName.get(area)][ElementType.Dimension].push(newDimensionGroup);

		if (c.foundation) {
			this.displayDimensionOfFoundation(c, area);
		}
	}

	private displayDimensionOfFoundation(column: ColumnProfile, area: AreaType){
		const h = column.getHolder(area)[0];

		var x1 = h.rect.x - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		if (area == AreaType.Left && column.tag == Tags.LEFT_COLUMN_TAG) {
			x1 = h.rect.x + h.rect.w + DimensionLineParams.FIRST_LEVEL_DIMENSION
		}
		else if (area == AreaType.Right && column.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
			x1 = h.rect.x + h.rect.w + DimensionLineParams.FIRST_LEVEL_DIMENSION
		}
		const x2 = x1;

		const y1 = h.rect.y + h.rect.h;
		const y2 = h.rect.y;

		var side = ElementSide.Left;
		if ((area == AreaType.Right && column.isRear) || (area == AreaType.Left && column.isFront) ||
		(area == AreaType.Rear && column.tag == Tags.LEFT_REAR_COLUMN_TAG) || (area == AreaType.Front && column.tag == Tags.RIGHT_COLUMN_TAG)) {
			side = ElementSide.Right;
		}

		const dLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, side);
		const x = h.rect.x - 20;
		const y = y2 + (y1 - y2) / 2;
		const length = column.foundation.size.height;
		const dText: DimensionText = new DimensionText(x, y, length, true);

		const newDimensionGroup = {
			line: dLine,
			text: dText,
		};

		this.profileService[CollectionName.get(area)][ElementType.Dimension].push(newDimensionGroup);
	}

	private displayDimensionOfColumnWidth(area: AreaType) {
		const rn = RectName.get(area);
		this.profileService.roofElements[ElementType.Column].filter((c: ColumnProfile) => c[rn] != null).forEach(c => {
			const x1 = c[rn].x + c[rn].w;
			const x2 = c[rn].x;
			const y = c[rn].y + c[rn].h + 30;

			const x = x2 + (x1 - x2) / 2;
			const length = Common.round(c.width);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false, true);

			const newDimensionGroup = {
				line: null,
				text: newDimensionText,
			};

			this.profileService[CollectionName.get(area)][ElementType.Dimension].push(newDimensionGroup);
		});
	}

	private displayDimensionOfGlassWidth(area: AreaType) {

		var diff = 0;
		if (area == AreaType.Front) {
			diff = (this.projectService.template.backHeight - this.projectService.template.frontHeight) * SvgParams.SCALE;
		}		

		var elems = this.profileService[CollectionName.get(area)];

		const y1 = SvgParams.START_Y + diff  - DimensionLineParams.FIRST_LEVEL_DIMENSION;
		const y2 = y1;
		const y = SvgParams.START_Y + diff - 15;

		this.profileService.roofElements[ElementType.Glass].forEach((glass: Glass) => {
			const x1 = glass.rect.x + glass.stat.paddingLeft * SvgParams.SCALE;
			const x2 = glass.rect.x + glass.rect.w - glass.stat.paddingRight * SvgParams.SCALE;
			const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, ElementSide.Top);

			const x = glass.rect.x + (glass.rect.w - glass.stat.paddingRight * SvgParams.SCALE) / 2;
			const length = Common.round(glass.rect.w / SvgParams.SCALE - glass.stat.paddingLeft - glass.stat.paddingRight);
			const newDimensionText: DimensionText = new DimensionText(x, y, length, false);

			const newDimensionGroup = {
				line: newDimensionLine,
				text: newDimensionText,
			};

			elems[ElementType.Dimension].push(newDimensionGroup);
		});
	}

	private displayDimensionBetweenColumns(area: AreaType) {
		const yPointOfColumns = this.getYPointOfColumns(area);
		var cols = this.profileService.getSortedColumns(CollectionName.get(AreaType.Roof), area);
		const firstC = cols[0];
		const lastC = cols[cols.length - 1];
		const by = 25 + this.foundHeight * SvgParams.SCALE;
		const rn = RectName.get(area);

		if (cols.length > 2) {
			this.displayDimensionBetweenExtremeColumns(firstC, lastC, yPointOfColumns, area);
		}

		const y1 = yPointOfColumns + by;
		const y2 = yPointOfColumns + by;
		const y = yPointOfColumns + by + 15;

		let nc: ColumnProfile;
		if (firstC[rn].x > SvgParams.START_X) {
			nc = new ColumnProfile(area, SvgParams.START_X - firstC.rectOnRoof.w / 2, firstC.rectOnRoof.y, firstC.length, area, firstC.configId, this.projectService.template);
			cols.splice(0, 0, nc);
		}

		const wi = this.projectService.template.width * SvgParams.SCALE;
		if (lastC[rn].x + lastC[rn].w < wi + SvgParams.START_X) {
			nc = new ColumnProfile(area, SvgParams.START_X + wi - lastC.rectOnRoof.w / 2, lastC.rectOnRoof.y, lastC.length, area, lastC.configId, this.projectService.template);
			cols.push(nc);
		}

		cols.forEach((c: ColumnProfile, index: number) => {
			if (index < cols.length - 1) {
				const nextC = cols[++index];

				const x1 = nextC[rn].x + nextC[rn].w / 2;
				const x2 = c[rn].x + c[rn].w / 2;

				const x = x2 + (x1 - x2) / 2;
				const length = Math.abs(Common.round((x1 - x2) / SvgParams.SCALE));

				this.createAndStoreDimension(this.profileService[CollectionName.get(area)], x1, x2, y1, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, x, y, length, "", ElementSide.Bottom, false);
			}
		});
	}


	//#endregion

	private displayVerticalsOnSide(area: AreaType) {
		const collection = CollectionName.get(area);
		this.getDimensionsForSpecificGlassWallDiamond(area, this.profileService.roofElements).forEach(e => {
			this.profileService[collection][ElementType.Dimension].push(e)
		});
		this.getDimensionsForSpecificGlassWall(this.profileService[collection]).forEach(e => {
			this.profileService[collection][ElementType.Dimension].push(e)
		});
		[ElementType.MarquiseVertical, ElementType.Door, ElementType.Wall].forEach(t => {
			this.getDimensionsForVertical(this.profileService.roofElements[t].filter((f: VerticalElement) => f.area == area)).forEach(d => {
				this.profileService[collection][ElementType.Dimension].push(d);
			});
		});
	}

	//#region Left

	private displayDimensionsOnLeftSide() {
		if (this._showArea) {
			this.displayDimensionOfDetphOnLeft();
			this.displayDimensionOfColumnLengthOnLeft();
			this.displayDimensionOfBackHeightOnLeft();
			this.displayDimensionOfFrontHeightOnLeft();

			if (!this._showElements) {
				this.profileService.getDoors(AreaType.Left).forEach((d: Door) => {
					this.getDimensionsForWing(d.wings[0]).forEach(d => {
						this.profileService.leftSideElements[ElementType.Dimension].push(d);
					});
				});
			}
		}
		if (this._showElements) {
			this.displayVerticalsOnSide(AreaType.Left);
		}
		this.removeDuplicate(this.profileService.leftSideElements, this.leftColHeightIx, this.leftFrontHeightIx);
	};

	private displayDimensionOfColumnLengthOnLeft() {
		this.profileService.leftSideElements[ElementType.Column].forEach((c: ColumnProfile) => {
			const x1 = c.rectOnLeft.x - (c.isFront ? 10 : 20);
			const y2 = c.rectOnLeft.y;
			const y1 = c.rectOnLeft.y + c.rectOnLeft.h;

			const x = x1 - 10;
			const y = y1 + (y2 - y1) / 2;
			const length = Common.round(c.getLength());
			const ix = this.createAndStoreDimension(this.profileService.leftSideElements, x1, x1, y1, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, x, y, length, true, ElementSide.Left);
			if (c.tag == Tags.LEFT_COLUMN_TAG) {
				this.leftColHeightIx = ix;
			}

			if (c.foundation) {
				this.displayDimensionOfFoundation(c, AreaType.Left);
			}
		});
	}

	private displayDimensionOfDetphOnLeft() {
		const wallExists = this.profileService.leftSideElements[ElementType.GlassWall].length > 0 || this.profileService.leftSideElements[ElementType.GlassWallDiamond].length > 0;
		const rear = this.profileService.leftSideElements[ElementType.WallProfile][0];
		const col: ColumnProfile = this.profileService.leftSideElements[ElementType.Column].find(c => c.tag == Tags.LEFT_COLUMN_TAG);

		const x1 = rear.rectOnLeft.x;
		const x2 = col.rectOnLeft.x + col.rectOnLeft.w;
		const y1 = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y + (wallExists ? DimensionLineParams.FIFTH_LEVEL_DIMENSION : DimensionLineParams.THIRD_LEVEL_DIMENSION);

		const x = SvgParams.START_X + (x2 - x1) / 2;
		const y = y1 + DimensionLineParams.THIRD_LEVEL_DIMENSION;
		const length = Common.round(x2 - x1) / SvgParams.SCALE;
		const level = wallExists ? DimensionLineParams.FIFTH_LEVEL_DIMENSION : DimensionLineParams.THIRD_LEVEL_DIMENSION;

		this.createAndStoreDimension(this.profileService.leftSideElements, x2, x1, y1, y1, level, x, y, length, false, ElementSide.Bottom);
	}

	private displayDimensionOfBackHeightOnLeft() {
		const wallH = this.projectService.template.getRearSize().height;

		const lvl = (this.projectService.template.isStandalone ? DimensionLineParams.FOURTH_LEVEL_DIMENSION : DimensionLineParams.SECOND_LEVEL_DIMENSION) + 10;
		const x1 = SvgParams.START_X - lvl;
		const y1 = SvgParams.START_Y + (wallH + this.projectService.template.backHeight) * SvgParams.SCALE;
		const y2 = SvgParams.START_Y + wallH * SvgParams.SCALE;

		const x = x1 - 15;
		const y = y1 + (y2 - y1) / 2;
		const length = Common.round(y1 - y2) / SvgParams.SCALE;
		this.createAndStoreDimension(this.profileService.leftSideElements, x1, x1, y1, y2, lvl, x, y, length, true, ElementSide.Left);

		const diff = this.projectService.template.backHeight - this.projectService.template.frontHeight;
		var heightDiffText: DimensionText = new DimensionText(x, y2, diff, true, true);
		heightDiffText.textAnchor = "end";
		heightDiffText.parathenses = true;
		heightDiffText.drop = this.projectService.template.dropAngle;
		const heightDiff = {
			text: heightDiffText,
		};

		this.profileService.leftSideElements[ElementType.Dimension].push(heightDiff);

	}

	private displayDimensionOfFrontHeightOnLeft() {
		const depth = this.projectService.template.depth;
		const wallH = this.projectService.template.getRearSize().height;
		const frontHeight = this.projectService.template.frontHeight;

		const x1 = SvgParams.START_X + depth * SvgParams.SCALE + DimensionLineParams.SECOND_LEVEL_DIMENSION;
		const y2 = SvgParams.START_Y + (wallH + this.projectService.template.backHeight) * SvgParams.SCALE;
		const y1 = y2 - frontHeight * SvgParams.SCALE;

		const x = x1 + 15;
		const y = y1 + (y2 - y1) / 2;
		const length = Math.round(Common.round(y2 - y1) / SvgParams.SCALE);
		this.leftFrontHeightIx = this.createAndStoreDimension(this.profileService.leftSideElements, x1, x1, y1, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, x, y, length, true, ElementSide.Right);
	}

	//#endregion

	//#region Right

	private displayDimensionsOnRightSide() {
		if (this._showArea) {
			this.displayDimensionOfDetphOnRight();
			this.displayDimensionOfColumnLengthOnRight();
			this.displayDimensionOfBackHeightOnRight();
			this.displayDimensionOfFrontHeightOnRight();

			if (!this._showElements) {
				this.profileService.getDoors(AreaType.Right).forEach((d: Door) => {
					this.getDimensionsForWing(d.wings[0]).forEach(d => {
						this.profileService.rightSideElements[ElementType.Dimension].push(d);
					});
				});
			}

		}
		if (this._showElements) {
			this.displayVerticalsOnSide(AreaType.Right);
		}
		this.removeDuplicate(this.profileService.rightSideElements, this.rightColHeightIx, this.rightFrontHeightIx);
	};

	private displayDimensionOfDetphOnRight() {
		const wallExists = this.profileService.rightSideElements[ElementType.GlassWall].length > 0 || this.profileService.rightSideElements[ElementType.GlassWallDiamond].length > 0;
		const rear = this.profileService.rightSideElements[ElementType.WallProfile][0];
		const col: ColumnProfile = this.profileService.rightSideElements[ElementType.Column].find(c => c.tag == Tags.RIGHT_COLUMN_TAG);

		const x1 = col.rectOnRight.x;
		const x2 = rear.rectOnRight.x + rear.rectOnRight.w;
		const y1 = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y + (wallExists ? DimensionLineParams.FIFTH_LEVEL_DIMENSION : DimensionLineParams.THIRD_LEVEL_DIMENSION);

		const level = wallExists ? DimensionLineParams.FIFTH_LEVEL_DIMENSION : DimensionLineParams.THIRD_LEVEL_DIMENSION;

		const x = SvgParams.START_X + (x2 - x1) / 2;
		const y = y1 + DimensionLineParams.THIRD_LEVEL_DIMENSION;
		const length = Common.round(x2 - x1) / SvgParams.SCALE;
		this.createAndStoreDimension(this.profileService.rightSideElements, x2, x1, y1, y1, level, x, y, length, false, ElementSide.Bottom);
	}

	private displayDimensionOfColumnLengthOnRight() {
		this.profileService.rightSideElements[ElementType.Column].forEach((c: ColumnProfile) => {
			const x1 = c.rectOnRight.x + c.rectOnRight.w + 10;
			const y2 = c.rectOnRight.y + c.rectOnRight.h;
			const y1 = c.rectOnRight.y;

			const x = c.rectOnRight.x + c.rectOnRight.w + 25;
			const y = y2 + (y1 - y2) / 2;
			const length = Common.round(c.getLength());

			const ix = this.createAndStoreDimension(this.profileService.rightSideElements, x1, x1, y1, y2, DimensionLineParams.FIRST_LEVEL_DIMENSION, x, y, length, true, ElementSide.Right);
			if (c.tag == Tags.RIGHT_COLUMN_TAG) {
				this.rightColHeightIx = ix;
			}

			if (c.foundation) {
				this.displayDimensionOfFoundation(c, AreaType.Right);
			}
		});
	}

	private displayDimensionOfBackHeightOnRight() {
		const wallH = this.projectService.template.getRearSize().height;
		const depth = this.projectService.template.depth;
		const backHeight = this.projectService.template.backHeight;

		const lvl = (this.projectService.template.isStandalone ? DimensionLineParams.THIRD_LEVEL_DIMENSION : DimensionLineParams.FIRST_LEVEL_DIMENSION) + 10;
		const x1 = SvgParams.START_X + depth * SvgParams.SCALE + lvl;
		const y1 = SvgParams.START_Y + wallH * SvgParams.SCALE;
		const y2 = SvgParams.START_Y + (wallH + backHeight) * SvgParams.SCALE;

		const x = x1 + 25;
		const y = y1 + (y2 - y1) / 2;
		const length = Common.round(y2 - y1) / SvgParams.SCALE;

		this.createAndStoreDimension(this.profileService.rightSideElements, x1, x1, y1, y2, lvl, x, y, length, true, ElementSide.Right);

		const diff = this.projectService.template.backHeight - this.projectService.template.frontHeight;
		var heightDiffText: DimensionText = new DimensionText(x, y1, diff, true, true);
		heightDiffText.textAnchor = "end";
		heightDiffText.parathenses = true;
		heightDiffText.drop = this.projectService.template.dropAngle;
		const heightDiff = {
			text: heightDiffText,
		};

		this.profileService.rightSideElements[ElementType.Dimension].push(heightDiff);

	}

	private displayDimensionOfFrontHeightOnRight() {
		const wallH = this.projectService.template.getRearSize().height;
		const frontHeight = this.projectService.template.frontHeight;

		const x1 = SvgParams.START_X - DimensionLineParams.SECOND_LEVEL_DIMENSION;
		const y1 = SvgParams.START_Y + (wallH + this.projectService.template.backHeight) * SvgParams.SCALE;
		const y2 = y1 - frontHeight * SvgParams.SCALE;

		const x = x1 - 15;
		const y = y2 + (y1 - y2) / 2;
		const length = Math.round(Common.round(y1 - y2) / SvgParams.SCALE);
		this.rightFrontHeightIx = this.createAndStoreDimension(this.profileService.rightSideElements, x1, x1, y1, y2, DimensionLineParams.SECOND_LEVEL_DIMENSION, x, y, length, true, ElementSide.Left);
	}

	//#endregion

	private createAndStoreDimension(collection: any, x1, x2, y1, y2, level, tx, ty, length, transform, side: ElementSide, helper: boolean = false) {
		const newDimensionGroup = this.createDimension(x1, x2, y1, y2, level, tx, ty, length, transform, side, helper);
		collection[ElementType.Dimension].push(newDimensionGroup);
		return collection[ElementType.Dimension].length - 1; // Index of last element
	}

	private createDimension(x1, x2, y1, y2, level, tx, ty, length, transform, side: ElementSide, helper: boolean = false) {
		const newDimensionLine: DimensionLine = new DimensionLine(x1, y1, x2, y2, level, side, helper);
		const newDimensionText: DimensionText = new DimensionText(tx, ty, length, transform, helper);

		const newDimensionGroup = {
			line: newDimensionLine,
			text: newDimensionText,
		};

		return newDimensionGroup;
	}

	private removeDuplicate(collection: any, colIx: number, frontIx: number) {
		if (colIx >= 0 && frontIx >= 0) {
			const cDim: IDimensionsGroup = collection[ElementType.Dimension][colIx];
			const fDim: IDimensionsGroup = collection[ElementType.Dimension][frontIx];

			if (cDim && fDim && cDim.text.w == fDim.text.w) {
				collection[ElementType.Dimension].splice(colIx, 1);
			}
		}
	}
}
