import { IStatics } from "../../interfaces/IStatics";
import { ProjectService } from "../../services/project.service";
import { v4 as uuidv4 } from 'uuid';
import { IPoint } from "../../interfaces/IPoint";
import { ElementType } from '../ToolboxModel';
import { GLASS_PART_PADDING, SvgParams } from '../../constants/constants';
import { Common } from '../common';
import { AreaType } from '../../interfaces/IAreaType';
import { GlassPartDiamond } from "./glassDiamondPart";
import { VerticalElement } from "../vertical-element";
import { Profile } from "../profiles/profile.model";
import { IRect } from "../../interfaces/IRect";
import { GlassWallParameter } from "../wizard/wizard-parameter.model";
import { IAreaService } from "../../interfaces/IAreaService";
import { GlassWallBase } from "./glassWall-base.model";
import { Shape } from "../shape";

export class GlassWallDiamond extends GlassWallBase {
 
	get frames(): Profile[] {
		return VerticalElement.getFrames(this.area, this.leftFrame, this.rightFrame, null, this.bottomFrame);
	}
	get type() {
		return ElementType.GlassWallDiamond;
	}	
	get name() {
		return $localize`:Common|Glass wall diamond:Glass wall diamond`;
	}
	get rect(): IRect {
		const p = this.points;
		var y = Math.min(p[SvgParams.LEFT_TOP_POINT_NUM].y, p[SvgParams.RIGHT_TOP_POINT_NUM].y);
		return {
			x: p[SvgParams.LEFT_TOP_POINT_NUM].x,
			y: y,
			w: p[SvgParams.RIGHT_TOP_POINT_NUM].x - p[SvgParams.LEFT_TOP_POINT_NUM].x,
			h: p[SvgParams.LEFT_BOTTOM_POINT_NUM].y - y
		};
	}
	cuttingOnSite: boolean;
	get cuttingable(): boolean {
		return true;
	}

	get muntinsRequired() {
		const surfaceArea = Common.calculateDiamondSurface(this.width, this.lowerHeight, this.height, GLASS_PART_PADDING);
		return surfaceArea > this.maxM2;
	}

	get surfaceArea() {
		return (this.lowerHeight + this.height) * this.width / 2 / 1000000; // milimeters2 to meters2
	}
	get maxHeight(): number {
		var by = 0;
		if (this.bottomNeib) {
			by = this.bottomNeib.topY;
		} else {
			const p = this.projectService.CurrentAreaService?.getShapeAreaPoints();
			by = p[SvgParams.LEFT_BOTTOM_POINT_NUM]?.y ?? SvgParams.START_Y;
		}
		var y = this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y;
		return this.height + Math.round((by - y) / SvgParams.SCALE);
	}


	public id: string;
	public stat: IStatics;
	public color: string;

	public parts: GlassPartDiamond[];

	public set points(p: IPoint[]) {
		this._points = p;
		this.path = this.calculatePath();
		this._drop = this.calculateDrop();
	}
	public get points(): IPoint[] {
		return this._points;
	}
	private _points: IPoint[];
	public path: string;

	protected _drop: number;
	public get drop() {
		return this._drop;
	}

	public get locationY() {
		const p = this.area == AreaType.Left ? SvgParams.LEFT_TOP_POINT_NUM : SvgParams.RIGHT_TOP_POINT_NUM;
		return Math.round((this.points[p].y - SvgParams.START_X) / SvgParams.SCALE);
	}

	public get locationX() {
		return (this.points[SvgParams.LEFT_TOP_POINT_NUM].x - SvgParams.START_X) / SvgParams.SCALE;
	}

	public get width() {
		return Common.round((this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) / SvgParams.SCALE);
	}

	public setWidth(value: number, y: number) {
		const newWidth = Common.round(value * SvgParams.SCALE);

		this.points[SvgParams.RIGHT_TOP_POINT_NUM].y = y;
		this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x = this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x + newWidth;
		this.points[SvgParams.RIGHT_TOP_POINT_NUM].x = this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x;

		this.path = this.calculatePath();
	}

	public get frontHeight() {
		return Math.round(new Shape(this.points, this._drop).lessHeight / SvgParams.SCALE);
	}
	public get height() {
		return Math.round(new Shape(this.points, this._drop).height / SvgParams.SCALE);
	}
	public set height(value: number) {
		const newHeight = Common.round(value * SvgParams.SCALE);

		const areaTopPoint = this.getAreaTopPoint();

		this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y = this.points[areaTopPoint].y + newHeight;
		this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y = this.points[areaTopPoint].y + newHeight;

		this.path = this.calculatePath();
	}

	public get lowerHeight() {
		switch (this.area) {
			case AreaType.Left:
				return Common.round((this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - this.points[SvgParams.RIGHT_TOP_POINT_NUM].y) / SvgParams.SCALE);

			case AreaType.Right:
				return Common.round((this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - this.points[SvgParams.LEFT_TOP_POINT_NUM].y) / SvgParams.SCALE);
		}
	}

	private getAreaTopPoint() {
		switch (this.area) {
			case AreaType.Left:
				return SvgParams.LEFT_TOP_POINT_NUM;

			case AreaType.Right:
				return SvgParams.RIGHT_TOP_POINT_NUM;
		}
	}

	public getRect(): IRect {
		return {
			x: this.points[SvgParams.LEFT_TOP_POINT_NUM].x,
			w: this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x,
			y: 0,
			h: 0
		}
	}

	constructor(
		project: ProjectService,
		def: GlassWallParameter,
		points: IPoint[],
		area: AreaType,
		sideService: IAreaService,
		hanging: boolean = false
	) {
		super(project, sideService, hanging);
		this.area = area;
		this.def = def;
		this.color = this.def.color;
		
		this.id = `${this.type}_` + uuidv4();
		this.points = points;

		this.parts = [];
		this.muntins = [];
	}
	private calculateDrop() : number {
		return this.projectService.template.dropAngle;
		// return Common.calculateDrop(this._points[1].y, this._points[0].y, this._points[1].x, this._points[0].x);
	}
	public calculatePath() {
		return ProjectService.getPath(this._points);
	}
	get leftX(): number {
		return this.leftFrame?.leftX ?? this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x;
	}
	get rightX(): number {
		return this.rightFrame?.rightX ?? this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x;
	}
	get topY(): number {
		return Math.min(this.points[SvgParams.LEFT_TOP_POINT_NUM].y, this.points[SvgParams.RIGHT_TOP_POINT_NUM].y);
	}
	get bottomY(): number {
		return this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
	}
}
