import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AreaType, RectName } from '../_core/interfaces/IAreaType';
import { ProjectService } from '../_core/services/project.service';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { SplitterPaneComponent } from '@progress/kendo-angular-layout';
import { RoofService } from '../_core/services/areas/roof.service';
import { RightSideService } from '../_core/services/areas/right-side.service';
import { LeftSideService } from '../_core/services/areas/left-side.service';
import { FrontService } from '../_core/services/areas/front.service';
import { ProfileService } from '../_core/services/profile.service';
import { WizardDialog } from '../dialogs/wizard/wizard.dialog';
import { ChosenGlassService } from '../_core/services/chosen-elements/chosen-glass.service';
import { ChoosenProfileService } from '../_core/services/chosen-elements/chosen-profile.service';
import { AreasComponent } from './areas/areas.component';
import { ToolboxItemService } from '../_core/services/toolbox-item.service';
import { LEDsTemplateDialog } from '../dialogs/leds-template/leds-template.dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ApiService } from '../_core/services/api.service';
import { SNOW_ZONES, SvgParams, WIND_ZONES } from '../_core/constants/constants';
import { ChosenMarquiseService } from '../_core/services/chosen-elements/chosen-marquise.service';
import { ChosenExtraOptionService } from '../_core/services/chosen-elements/chosen-extra-option.service';
import { ExtraOption } from '../_core/models/extra-option.model';
import { StorageService } from '../_core/services/storage.service';
import { CrmService, ICrmObserver } from '../_core/services/crm.service';
import { ProjectStoreModel } from '../_core/models/project-store/project-store.model';
import { environment } from 'src/environments/environment';
import { StateService } from '../_core/services/state.service';
import { IMigrationResult } from '../_core/interfaces/IStorageCommon';
import { NotifyService } from '../_core/services/notify.service';
import { ProjectTemplate } from '../_core/template';
import { IProjectInfo } from '../_core/interfaces/IProjectInfo';
import { RearService } from '../_core/services/areas/rear.service';
import { IAreaService } from '../_core/interfaces/IAreaService';
import { Common } from '../_core/models/common';
import { ElementType } from '../_core/models/ToolboxModel';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, ICrmObserver {
	@ViewChild(AreasComponent, { static: true }) areas: AreasComponent;
	@ViewChild(ToolboxComponent, { static: true }) toolbox: ToolboxComponent;
	@ViewChild('topSplit', { static: true }) topSplit: SplitterPaneComponent;
	@ViewChild(WizardDialog, { static: true }) wizard: WizardDialog;
	@ViewChild(LEDsTemplateDialog, { static: false }) ledsTemplate: LEDsTemplateDialog;
	@ViewChild('mainSvg', { read: ViewContainerRef }) mainSvg: ViewContainerRef;

	setSelectedItems: any[] = [];
	sysInfo$ = this.api.getSysInfo();

	currentElement: string = "";
	currentZoom: number = 1;

	currentPrice: number;
	crmPresent: boolean;
	showVersionDetails: boolean;
	migrationResult: IMigrationResult;

	env: string = environment.name;
	
	public get customerName(): string {
		if (this.projectService.info?.customerName) {
			return this.projectService.info.customerName;
		} else if (this.projectService.info?.orderNumber) {
			return this.projectService.info.orderNumber;
		} else {
			return this.projectService.orderCode;
		}
	}

	private _designData: ProjectStoreModel;

	private services: Map<AreaType, IAreaService>;

	constructor(
		private profileService: ProfileService,
		protected projectService: ProjectService,
		private roofService: RoofService,
		private rightSideService: RightSideService,
		private leftSideService: LeftSideService,
		private frontService: FrontService,
		private rearService: RearService,
		private chosenGlassService: ChosenGlassService,
		private chosenProfileService: ChoosenProfileService,
		private chosenMarquiseService: ChosenMarquiseService,
		private chosenExtraOptionService: ChosenExtraOptionService,
		public toolBSrvc: ToolboxItemService,
		private notificationService: NotificationService,
		private api: ApiService,
		private storageService: StorageService,
		private stateService: StateService,
		private crmService: CrmService,
		private notif: NotifyService
	) { 
		this.services = Common.getServices(
			[this.frontService, this.leftSideService, this.rightSideService, this.roofService, this.rearService]);

			// this.storageService.projectRestored.subscribe(e => {
			// 	if (e) {
			// 		this.projectService.emitChange();
			// 	}
			// })
		// this.storageService.projectRestored.subscribe(e => {
		// 	if (e) {
		// 		var model = this.storageService.storeProject();
		// 		if (this.crmService.isCrmPresent) {
		// 			this.currentPrice = null;
		// 			this.crmService.designChanged(model);
		// 		}
		// 	}
		// })
	
	}

	modelLoaded(designData: ProjectStoreModel, info: IProjectInfo, images: boolean): void {
		if (info == null) {
			info = {
				customerName: "",
				customerAddresLine1: "",
				customerAddresLine2: "",
				orderNumber: this.projectService.orderCode
			};
		}
		this.projectService.info = info;

		if (designData != null && designData.properties != null) {
			this._designData = designData;
			this.storageService.restoreProject(designData, false, images);
			this.crmService.sendInitialized();

			// Uncomment to simulate erp export without CRM
			// this.projectService.erpExportMode = true;
		} else {

			const prId = this.projectService.productId;
			const bName = this.projectService.brandName;
			var t = new ProjectTemplate(false);
			t.productId = prId;
			t.brandName = bName;

			if (this.projectService.snowZone && SNOW_ZONES.indexOf(this.projectService.snowZone) < 0) {
				this.notif.cfgException.emit(
					{
						message: "Unknown snow zone. Cannot create new project.",
						clear: true
					});
				return;
			}

			if (this.projectService.windZone && WIND_ZONES.indexOf(this.projectService.windZone) < 0) {
				this.notif.cfgException.emit(
					{
						message: "Unknown wind zone. Cannot create new project.",
						clear: true
					});
				return;
			}

			this.api.getProductParameters(prId, bName).subscribe(pars => {
				t.productName = pars.name;
				t.width = pars.defaultWidth;
				t.backHeight = pars.defaultBackHeight;
				t.depth = pars.defaultDepth;
				t.dropAngle = pars.defaultDropAngle;
				t.minBackHeight = pars.minBackHeight;
				t.maxBackHeight = pars.maxBackHeight;
				t.minDropAngle = pars.minDropAngle;
				t.maxDropAngle = pars.maxDropAngle;
				t.minDepth = pars.minDepth;
				t.maxDepth = pars.maxDepth;
				t.minWidth = pars.minWidth;
				t.maxWidth = pars.maxWidth;
				t.version = pars.version;
				t.isDraft = pars.isDraft;
				t.errors = pars.errors;
				this.projectService.setupTemplate(t);
				this.crmService.sendInitialized();
			});


		}
	}
	priceChanged(value: number): void {
		this.currentPrice = value;		
		this.crmPresent = true;
	}

	ngOnInit(): void {
		this.crmService.attach(this);

		this.projectService.projectReady.subscribe(p => {
			if (p) {
				this.areas.selected = AreaType.Roof;
				this.onAreaClick(AreaType.Roof);

				var mp = document.getElementById("mainPane");
				if (mp) {
					try {
						const scrLe = localStorage.getItem('scrollLeft');
						const scrTo = localStorage.getItem('scrollTop');
						mp.scrollLeft = scrLe ? parseInt(scrLe) : SvgParams.STARTUP_X;
						mp.scrollTop = scrTo ? parseInt(scrTo) : SvgParams.STARTUP_Y;

						mp.addEventListener("scroll", function ($event) {
							localStorage.setItem("scrollLeft", $event.target['scrollLeft']);
							localStorage.setItem("scrollTop", $event.target['scrollTop']);
						});
					} catch {
						// Absolutely not important feature
					}
				}
			}
		});

		this.projectService.showTemporaryMessageSubj.subscribe(msgObj => {
			this.showTemporaryMessage(msgObj.message, msgObj.hideAfter, msgObj.style, msgObj.vertical ?? "bottom");
		})
		this.chosenGlassService.chosenGlassSubj.subscribe((profiles: any[]) => {
			this.setElementDescription(profiles);
		})
		this.chosenProfileService.chosenProfileSubj.subscribe((profiles: any[]) => {
			this.setElementDescription(profiles);
		})
		this.chosenMarquiseService.chosenMarquiseSubj.subscribe((profiles: any[]) => {
			this.setElementDescription(profiles);
		})
		this.chosenExtraOptionService.choseOptionSubj.subscribe((profile: ExtraOption) => {
			this.currentElement = profile?.name;
		})
		this.projectService.elementsChanged.subscribe(c => {
			if (this.projectService.readOnly) {
				return;
			}
			this.sendToCRM(c);
		});
		this.storageService.migrationErrors.subscribe(m => {
			this.migrationResult = m;
		});
		this.notif.cfgException.subscribe(m => {
			if (m.clear) {
				this.wizard.hide();
			}
		});
		this.projectService.showInfoDialogSubj.subscribe(m => {
			this.infoMessage = m;
			this.infoOpened = true;
		})
		
		this.toolBSrvc.setCurrTool(null);
		this.wizard.show();
	}

	ngOnDestroy(): void {
		this.crmService.detach(this);
	}


	private sendToCRM(c: boolean) {
		if (!c) {
			return;
		}
		var model = this.storageService.storeProject();
		this.stateService.save(model);
		if (this.crmService.isCrmPresent) {
			this.currentPrice = null;
			this.crmService.designChanged(model);
		}
	}

	private setElementDescription(profiles: any[]) {
		if (!profiles || profiles.length < 1) {
			this.currentElement = "";
		} else {
			this.currentElement = profiles[0].name;
		}
	}
	private showTemporaryMessage(message: string, hideAfter: number, style: 'error' | 'info' | 'none' | 'success' | 'warning', vertical) {
		this.notificationService.show({
			appendTo: this.mainSvg,
			content: message,
			hideAfter: hideAfter,
			position: { horizontal: "right", vertical },
			animation: { type: "slide", duration: 400 },
			type: { style: style, icon: true },
		});
	}

	onAreaClick(areaType: AreaType): void {
		this.projectService.CurrentAreaService = this.services.get(areaType);
		this.projectService.currentArea = areaType;

		this.toolbox.getItems(areaType);
		this.closeElementCard(areaType);
		this.loadArea();
	}

	onZoomClick(scale: number) {
		this.currentZoom = scale;
	}

	onResetClick() {
		this.currentZoom = 1;
		var mp = document.getElementById("mainPane");
		mp.scrollLeft = SvgParams.STARTUP_X;
		mp.scrollTop = SvgParams.STARTUP_Y;
		localStorage.setItem('panelLeft-size', "25%");
		localStorage.setItem('panelRight-size', "25%");
		localStorage.setItem('panelLeft-expanded', "true");
		localStorage.setItem('panelRight-expanded', "true");
	}

	onUndoClick(rewind: boolean) {
		var area = this.stateService.undo(this.storageService, rewind);
		if (area == this.projectService.currentArea) {
			this.toolBSrvc.onBgClick();
		} else {
			this.onAreaClick(area);		
		}
	}

	onRedoClick() {
		var area = this.stateService.redo(this.storageService);
		if (area == this.projectService.currentArea) {
			this.toolBSrvc.onBgClick();
		} else {
			this.onAreaClick(area);
		}
	}

	get undoDisabled() {
		return this.stateService.undoDisabled;
	}

	get redoDisabled() {
		return this.stateService.redoDisabled;
	}

	private closeElementCard(areaType: AreaType): void {
		const chosenProfile = this.chosenProfileService.getChosenProfile();
		if (!chosenProfile) {
			return;
		}
		const rc = RectName.get(areaType);
		const prof = chosenProfile[0];

		if (prof[rc] == null || prof[rc] === prof["rect"] || prof.type == ElementType.Column) {
			this.chosenProfileService.setChosenProfile(null);
			this.setSelectedItems = []
		}
	}

	loadArea(): void {
		this.services.forEach(s => {
			s.showArea();
		})
	}

	forceOpen() {
		this.storageService.restoreProject(this._designData, true, false);
	}

	onPanelChange(panelName, $event) {
		localStorage.setItem(panelName + '-expanded', $event.items[0].expanded);
	}

	isPanelExpanded(panelName) {
		if ((panelName == "panelLeft" || panelName == "panelRight") && !environment.localhost) {
			return true;
		}
		var exp = localStorage.getItem(panelName + '-expanded');
		var ret = exp == null ? true : (exp == 'true');
		return ret;
	}

	onSplitterExpand(splitterName, $event: boolean) {
		localStorage.setItem(splitterName + '-expanded', $event === true ? 'false' : 'true');
	}

	onSplitterResize(splitterName, $event: boolean) {
		localStorage.setItem(splitterName + '-size', $event.toString());
	}

	splitterSize(splitterName) {
		if ((splitterName == "panelLeft" || splitterName == "panelRight") && !environment.localhost) {
			return "25%";
		}
		return localStorage.getItem(splitterName + '-size') ?? "25%";
	}

	public infoOpened: boolean = false;
	public infoMessage: string;

	onInfoClosed() {
		this.infoOpened = false;
	}

}
