import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/_core/services/project.service';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.css']
})
export class WaitComponent implements OnInit {

  constructor(public projectService: ProjectService) { 
  }

  ngOnInit(): void {
  }
  @Input() show:boolean = true;
  @Input() message:string = $localize`:Messages|'Please wait' message:Please wait`;

}
