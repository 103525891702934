import { RoofWindow } from './roofWindow.model';
import { Common } from '../common';
import { v4 as uuidv4 } from 'uuid';
import { IRect } from '../../interfaces/IRect';
import { ElementSide } from '../../interfaces/IElementSide';

export class RoofWindowConnector {
	id: string;

	get color(): string {
		return this.master.connectorColor;
	}

	get rect(): IRect {
		if (!this.visible) {
			return undefined;
		}
		if (this.location == ElementSide.Top) {
			return {
				x: Common.round(this.master.rect.x),
				y: Common.round(this.master.rect.y - this.master.connectorDepth),
				w: Common.round(this.master.rect.w),
				h: this.master.connectorDepth
			};
		} else {
			return {
				x: Common.round(this.master.rect.x),
				y: Common.round(this.master.rect.y + this.master.rect.h),
				w: Common.round(this.master.rect.w),
				h: this.master.connectorDepth
			};
		}
	}

	public visible: boolean;

	constructor(public master: RoofWindow, private location: ElementSide) {
		this.id = uuidv4();
	}
}
