import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { LedPattern } from 'src/app/_core/models/leds-patterns';
import { ApiService } from 'src/app/_core/services/api.service';
import { RoofService } from 'src/app/_core/services/areas/roof.service';
import { ProjectService } from 'src/app/_core/services/project.service';

@Component({
	selector: 'app-led-templates-dialog',
	templateUrl: './leds-template.dialog.html',
	styleUrls: ['./leds-template.dialog.css']
})
export class LEDsTemplateDialog {


	@Input() set isDialogOpened(v: boolean) {
		if (v && !this.patterns) {
			this.getLeds(this.projectService.productId, this.projectService.brandName);
		}
		this._isDialogOpened = v;
	}
	get isDialogOpened() {
		return this._isDialogOpened;
	}

	@Output() selected = new EventEmitter<LedPattern>();
	@Input() set item(v: LedPattern) {
		this.current = v;
	}
	patterns: LedPattern[];
	current: LedPattern;
	dialogTitle = $localize`:LEDs|Change LEDs pattern:Change LEDs pattern`;
	_isDialogOpened: boolean;

	constructor(
		private api: ApiService,
		private roofService: RoofService,
		private projectService: ProjectService) { 	
	}

	select(variant: LedPattern) {
		this.current = variant;
	}

	close(ok: boolean) {
		if (ok) {
			this.selected.emit(this.current);
		} else {
			this.selected.emit(null);
		}
		this.isDialogOpened = false;
	}

	getLeds(productId: string, brandName: string) {
		this.api.getLedsPatterns(productId, brandName).subscribe(result => {
			if (result.patterns) {
				this.patterns = result.patterns.sort((p1: LedPattern, p2: LedPattern) =>
					LedPattern.getBarsCountWithLeds(p1.pattern) > LedPattern.getBarsCountWithLeds(p2.pattern) ? 1 : -1);
			}
		});
	}

	getPatterns() {
		return this.patterns?.filter(p => p.barsCount == this.roofService.barCount() + 2);
	}

}
