import { IStatics } from "../../interfaces/IStatics";
import { ToolboxItemType } from '../../models/ToolboxModel';

export interface ISize {
	width: number;
	height: number;
	depth: number;
}
export interface IExtra {
	extraId: string,
	count: number
}

export interface ISizeRestricted {
	minHeight: number;
	minWidth: number;
	maxHeight: number;
	maxWidth: number;
}
export class WizardParameter implements ISizeRestricted {
	id: string;
	name: string;
	isDefault:boolean;
	isRequired: boolean;
	photo: string;
	thumbnail: string;
	photoDescription: string;
	size: ISize;
	color: string;
	minQuantity: number;
	maxQuantity: number;
	extras: IExtra[];
	elevatorId: string;
	type: ToolboxItemType;

	minHeight: number;
	minWidth: number;
	maxHeight: number;
	maxWidth: number;
	
	channels: number;

	weight: number;
}

export class FrontColumn {
	id: string;
	isDefault: boolean;
	shiftedWidthMax: number;
	shiftedWidthMin: number;
}
export class FrontParameter extends WizardParameter {
	columns: FrontColumn[];
	maxLength: number;
	minLength: number;
	gutterSize: ISize;
	isShiftedDepthAllow: boolean;
	glassLengthCorrection: number;
	reinforcementHeight: number;
}

export class FormSideParameter extends WizardParameter {
	isStandalone: boolean;
}

export class RearParameter extends WizardParameter {
	columns: FrontColumn[];
	standalone: boolean;
	wall: boolean;
	maxLength: number;
	minLength: number;
	reinforcementHeight: number;
	marquiseInsertionLength: number;
	marquiseTopBottomCorrection: number;
}


export class BarParameter extends WizardParameter {
	shiftedDepthMax: number;
	shiftedDepthMin: number;
}

export class ColumnParameter extends WizardParameter {
	internal: boolean;
	external: boolean;
	independent: boolean;
	outflowId: string;
}

export class GlassParameter extends WizardParameter {
	name: string;
	maxM2: number;
	connectorId: string;
	minWidth: number;
	statics: IStatics;
}

export class GlassWallParameter extends WizardParameter {
	name: string;
	maxM2: number;
	maxM2hang: number;
	connectorId: string;
	glassComponentId: string;
	minWidth: number;
	minHeight: number;
	locations: string[];
}

export class RoofWindowParameter extends WizardParameter {
	minWidth: number;
	maxWidth: number;
	depth: number;
	padding: number;
}
