import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ExportState, IPrintoutFile } from "../models/boms/bom.model";
import { ProjectStoreModel } from "../models/project-store/project-store.model";
import model from '../models/project-store/_model.json';
import { ProjectService } from "./project.service";
import { IProjectInfo } from "../interfaces/IProjectInfo";
import { IComment } from "../interfaces/IElement";

export interface ICrmObserver {
	modelLoaded(designData: ProjectStoreModel, projectInfo: IProjectInfo, getImages: boolean): void;
	priceChanged(priceValue: number): void;
}

@Injectable({
	providedIn: 'root'
})
export class CrmService {

	//#region observers
	private observers: ICrmObserver[] = []

	public attach(observer: ICrmObserver): void {
		if (this.observers.includes(observer)) {
			return;
		}
		this.observers.push(observer);
	}

	public detach(observer: ICrmObserver): void {
		const observerIndex = this.observers.indexOf(observer);
		if (observerIndex === -1) {
			return;
		}
		this.observers.splice(observerIndex, 1);
	}
	//#endregion

	private crmPresent: boolean = false;
	private crmOrigin: string = "*";
	private affinityId: string = null;

	public get isCrmPresent() {
		if (environment.localhost && this.affinityId) {
			return true;
		} else {
			return this.crmPresent;
		}
	}
	
	constructor(private projectService: ProjectService) {
	}

	public init(affinityId: string): void {
		if (this.affinityId)
			return;
		
		this.affinityId = affinityId;

		window.addEventListener("message", (event) => {
			if (event.data?.affinityId !== this.affinityId) {
				return;
			}

			const msg = event.data?.msg;
			if (!msg) {
				return;
			}

			console.info(event.data);
			switch(msg) {
				case "COMMENTS":
					this.projectService.profileService.setComments(event.data.comments);
					break;
				case "INIT":
				case "GET_IMAGES":
					this.crmOrigin = event.origin;
					this.crmPresent = true;

					//load the designer data model
					this.observers.forEach(observer => {
						observer.modelLoaded(event.data.designData, event.data.projectInfo, msg == "GET_IMAGES");
						observer.priceChanged(event.data.priceValue);
					});
					break;

				case "PRICE":
					//update the price
					this.observers.forEach(observer => {
						observer.priceChanged(event.data.priceValue);
					});
					break;

				case "SAVE-REQ":
					this.projectService.printProjectSubj.next({ requestId: undefined, sideType: undefined });
					break;

				case "ERP-EXPORT":
					this.projectService.erpExportMode = true;
					break;

			}
		});

		window.addEventListener("beforeunload", (event) => {
			this.postMsg({ msg: "CLOSE_TAB", affinityId: this.affinityId });
		});

		this.postMsg({ msg: "READY", affinityId: this.affinityId });

		this.testRestore();

	}

	public commentChanged(comment: IComment): void {
		if (this.projectService.readOnly) {
			return;
		}
		var cmt = this.projectService.profileService.comments.find(c => c.configId == comment.configId);
		if (cmt) {
			cmt.comment = comment.comment;
		} else {
			this.projectService.profileService.comments.push(comment);
		}
		if (this.isCrmPresent) {
			this.postMsg({ msg: "COMMENTS", affinityId: this.affinityId, comments: [comment] });
		}
	}

	public designChanged(designData: ProjectStoreModel): void {
		if (this.projectService.readOnly) {
			return;
		}
		if (this.isCrmPresent && designData) {
			this.postMsg({ msg: "DATA", affinityId: this.affinityId, designData: designData });
		}
	}

	public exportSave(exportData: IPrintoutFile[]): void {
		if (this.isCrmPresent) {
			this.postMsg({ msg: "SAVE-RES", affinityId: this.affinityId, exportData: exportData });
		}
	}

	public sendCloseRequest(exportState: ExportState = ExportState.None) {
		if (this.isCrmPresent) {
			this.postMsg({ msg: "CLOSE_APP", affinityId: this.affinityId, exportState });
		}
	}
	public sendInitialized() {
		this.postMsg({ msg: "INITIALIZED", affinityId: this.affinityId });
	}

	private postMsg(msg: any) {
		console.info(msg);
		if (window.opener) {
			window.opener.postMessage(msg, this.crmOrigin);
		}
	}

	private testRestore() {
		if (environment.localhost) {
			window.setTimeout(() => {
				this.observers.forEach(observer => {
					var m = this.affinityId == "0" ? null : model;
					var i: IProjectInfo = this.affinityId == "0" ? null :
						{
							customerName: "Abdul Majeed Alani Alshmeet",
							customerAddresLine1: "Wienkensfeld, 17",
							customerAddresLine2: "44581, Castrop Rauxel, NRW",
							orderNumber: "AT-23030240"
						};
					observer.modelLoaded(m, i, false);
				});
			}, 100);

			window.setTimeout(() => {
				this.observers.forEach(observer => {
					observer.priceChanged(123);
				});
			}, 50);
		}

	}

}
