import { IDeflections } from "../template";

export interface IOption {
	configId: string; // id kolor option
	count: number;
	linkedDataId?: string; // id koloru
}

export interface IMainStoreModel {
	productId: string;
	brandName: string;
	oldProductId?: string;
	orderCode: string;
	drop: number;
	snowZone: string;
	windZone: string;
	height: number;
	width: number;
	depth: number;
	frontHeight: number;
	glassConfigId: string;
	barConfigId: string;
	formSideConfigId: string;
	formTopConfigId: string;
	frontConfigId: string;
	fasteningConfigId: string;
	rearConfigId: string;
	foundationId: string;
	standAlone: boolean;
	staticBarsCount: number;
	colorConfigId: string;
	statics: IDeflections;
	errors: string[];
	remoteId: string;
	userRemote: boolean;
}

export interface IMigrationItem {
	type: string;
	currentId: string;
	oldId: string;
}

export interface IMigrationInput {
	currentProductId: string;
	oldProductId: string;
	items: IMigrationItem[];
}

export interface IMigrationResultItem extends IMigrationItem {
	name: string;
	staticProperties: string;
	drawingProperties: string;
	otherProperties: string;
}

export interface IMigrationResult {
	errors: boolean;
	warnings: boolean;
	items: IMigrationResultItem[];
}

export enum ApiConfigCodes {
	PROFILE_ROOF_BAR = "PROFILE_ROOF_BAR",
	PROFILE_ROOF_FRONT = "PROFILE_ROOF_FRONT",
	PROFILE_ROOF_REAR = "PROFILE_ROOF_REAR",
	ROOF = "ROOF"
}