import { Component } from '@angular/core';
import { SvgAreaBaseComponent } from '../svg-area-base/svg-area-base.component';

@Component({
	selector: 'g[app-svg-rear]',
	templateUrl: './svg-rear.component.html',
	styleUrls: ['./svg-rear.component.css']
})
export class SvgRearComponent extends SvgAreaBaseComponent {
}
