import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICheckable } from 'src/app/_core/interfaces/ICheckable';
import { IComment, IElement } from 'src/app/_core/interfaces/IElement';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { Common } from 'src/app/_core/models/common';
import { MarquiseTop } from 'src/app/_core/models/marquises/marquise-top.model';
import { ColumnProfile } from 'src/app/_core/models/profiles/column.model';
import { CrmService } from 'src/app/_core/services/crm.service';
import { ProjectService } from 'src/app/_core/services/project.service';

@Component({
	selector: 'card-common-properties',
	templateUrl: './common-properties.component.html',
	styleUrls: ['./../../card/card.component.css']
})
export class CommonPropertiesComponent {

	@Input() set items(v: IElement[]) {
		this._comment = "";
		if(v == null || v.length == 0) {
			this.current = null;
		} else {
			this.setItem(v[0]);
		}
	}

	variants: ICheckable[];

	get showRemote() {
		return this.projectService?.channelsTotal > 0 && this.channels > 0;
	}

	get showComments() {
		return this.crmService.isCrmPresent;
	}

	get remoteId() {
		if (this.projectService.userRemote == true && this.projectService.remote == null) {
			return null;
		}
		return this.projectService.remote.id;
	}

	set remoteId(remoteId: string) {
		this.projectService.userRemote = true;
		this.projectService.remote = this.projectService.template.remotes.find(r => r.id == remoteId);
		this.projectService.emitChange();
	}

	setItem(v: IElement) {
		this._comment = "";
		if (v == null) {
			this.current = null;
			return;
		}
		
		this.item = v;
		this.setComment();

		switch(v.type){
			case ElementType.Column:
				const col = this.item as ColumnProfile;
				this._columnConfigId = col.configId;
				break;
			default:
				break;
			}
		this.channels = Common.channelsCount(this.projectService, v as IElement);

		if (this.channels > 0) {
			this.projectService.calculateRemote();
			
			this.variants = [];
			this.projectService.template.remotes.forEach(r => {
				const disabled = (r.channels < this.projectService.channelsTotal);
				this.variants.push({ id: r.id, name: r.name, photo: "", disabled: disabled});
			});
		}
	}

	public variantsDisabled(itemArgs: { dataItem: ICheckable; index: number }) {
		return itemArgs.dataItem.disabled;
	}

	private setComment() {
		const cid = this.item.configId;
		this.current = this.projectService.profileService.comments.find(c => c.configId == cid);
		if (this.current == null) {
			this.current = { configId: cid, comment: "" };
		}
		this._comment = this.current.comment;
	}

	@Output() columnTypeChanged = new EventEmitter<string>();

	current: IComment;
	item: IElement;
	channels: number;

	isConfirmDialogOpened: boolean = false;
	confirmDialogMessage: string = $localize`:Messages|Remote confirmation:Remote will be deleted. Are you sure?`;

	private _comment: string;
	get comment(): string {
		return this._comment;
	}
	set comment(v: string) {
		this._comment = v;
	}

	private _columnConfigId: string;
	get columnConfigId(): string {
		return this._columnConfigId;
	}
	set columnConfigId(v: string)  {
		this._columnConfigId = v;
		this.columnTypeChanged.emit(v);
		this.items = [{ configId: v, type: ElementType.Column, id: this.item.id, layer: LayerLevel.Construction }];
	}

	constructor(public projectService: ProjectService,
		private crmService: CrmService) {
			this.projectService.profileService.commentsChanged.subscribe(c => {
				if (!c || !this.current) {
					return;
				}
				if (this.current.configId == c.configId) {
					this.current.comment = c.comment;
				}
			})
		}

	saveComment() {
		this.current.comment = this._comment;
		this.crmService.commentChanged(this.current);
	}

	blurComment() {
		// console.debug('blurComment', this.current.configId, this.comment);
	}

	getColumns() {
		const column = this.projectService.profileService.roofElements[ElementType.Column].find(c => c.id == this.item.id);
		return this.projectService.template.getColumnsForFront(column);
	}

	checkVerticalElements() {
		let column: ColumnProfile; // = this.projectService.profileService.roofElements[ElementType.Column].find(c => c.id == this.item.id);
		return this.projectService.checkVerticalElements(column);
	}

	deleteRemote() {
		this.isConfirmDialogOpened = true;
		this.projectService.userRemote = true;
		this.projectService.remote = null;
		this.projectService.emitChange();
	}

	addRemote() {
		this.projectService.userRemote = false;
		this.projectService.calculateRemote();
	}

	onConfirmDialogAction(status: any) {
		if (status.status) {
			this.deleteRemote();
		}
		this.isConfirmDialogOpened = false;
	}
}
