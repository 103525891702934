import { IMarquiseSpec } from '../../interfaces/specs/IMarquiseSpec';
import { IFrontDetails } from '../../interfaces/specs/IFrontSpec';
import { IColumnDetails } from '../../interfaces/specs/IColumnSpec';
import { IBarDetails } from '../../interfaces/specs/IBarSpec';
import { IGlassSpec, IGlassWallDiamondSpec, IGlassWallSpec } from '../../interfaces/specs/IGlassSpec';
import { IRoofWindowSpec } from '../../interfaces/specs/IRoofWindowSpec';
import { ICommonSpec } from '../../interfaces/specs/ICommonSpec';
import { ElementType } from '../ToolboxModel';
import { IDoorSpec } from '../../interfaces/specs/IDoorSpec';
import { IWallSpec } from '../../interfaces/specs/IWallSpec';

export enum PrintoutType {
	None = 'None',
	Front = "Front",
	Rear = "Rear",
	Left = "Left",
	Right = "Right",
	Top = "Top",
	Elements = "Elements"
}

export enum ExportState {
	None,
	Cancel,
	Success,
	Error
}

export interface IPrintoutFile {
	type: PrintoutType,
	content: string,
}

export interface IPrintoutEventArgs {
	requestId: string;
	sideType: PrintoutType;
	elementType?: ElementType;
	elementId?: string;
}
export class BomRoofBarsTypes {

	constructor(
		public externals: IBarDetails[] = [],
		public internals: IBarDetails[] = [],
	) {
	}
}

export class BomRoofElements {

	constructor(
		public glasses: IGlassSpec[] = [],
		public bars: BomRoofBarsTypes = new BomRoofBarsTypes(),
		public connectors: IBarDetails[] = [],
		public columns: IColumnDetails[] = [],
		public rears: IFrontDetails[] = [],
		public fronts: IFrontDetails[] = [],
		public marquises: IMarquiseSpec[] = [],
		public roofWindows: IRoofWindowSpec[] = [],
		public roofGutters: IRoofWindowSpec[] = [],
		public ventilators: ICommonSpec[] = [],
		public wallGutters: ICommonSpec[] = [],
		public leds: ICommonSpec[] = [],
		public ledPatternId: string = null,
	) {
	}
}

export class BomSideElements {

	constructor(
		public wallGlasses: IGlassWallSpec[] = [],
		public extras: ICommonSpec[] = [],
		public diamondGlasses: IGlassWallDiamondSpec[] = [],
		public marquises: IMarquiseSpec[] = [],
		public doors: IDoorSpec[] = [],
		public walls: IWallSpec[] = []
	) {
	}
}

export class BomElements {

	constructor(
		public roof: BomRoofElements = new BomRoofElements(),
		public rear: BomSideElements = new BomSideElements(),
		public front: BomSideElements = new BomSideElements(),
		public left: BomSideElements = new BomSideElements(),
		public right: BomSideElements = new BomSideElements(),
	) {
	}
}
