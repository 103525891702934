import { GlassResizeMode } from "src/app/_core/interfaces/IGlassResizeMode";
import { IRect } from "src/app/_core/interfaces/IRect";
import { IStatics } from "src/app/_core/interfaces/IStatics";
import { KILONEWTONS, SvgParams } from "src/app/_core/constants/constants";
import { BarProfile } from '../profiles/bar.model';
import { v4 as uuidv4 } from 'uuid';
import { ElementType, ToolboxItemType } from "src/app/_core/models/ToolboxModel";
import { Common } from "src/app/_core/models/common";
import { Profile } from "../profiles/profile.model";
import { GlassParameter, GlassWallParameter } from "../wizard/wizard-parameter.model";
import { ICuttingable } from "../../interfaces/ICuttingable";
import { AnchorType, IMarker } from "../../interfaces/IMarker";
import { ProfileConnector } from "../profiles/connector.model";
import { RoofVentilator } from "./roofVentilator.model";
import { RoofService } from "../../services/areas/roof.service";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { IElement } from "../../interfaces/IElement";
import { ProjectTemplate } from "../../template";

export class Glass implements ICuttingable, IElement {

	public id: string;
	public configId: string;
	public color: string;
	public rect: IRect;
	public stat: IStatics;

	public leftBar: BarProfile;
	public rightBar: BarProfile;
	public topBar: ProfileConnector;
	public bottomBar: ProfileConnector;

	public ventilator: RoofVentilator;

	public locked: boolean;
	public resizeMode: GlassResizeMode;

	public get type() {
		return ElementType.Glass;
	}
	public get layer(): LayerLevel {
		return LayerLevel.Middle 
	}
	public get topY() {
		return this.rect.y;
	}
	public get bottomY() {
		return this.rect.y + this.rect.h;
	}

	public get name(): string {
		return $localize`:Common|Glass:Glass`;
	}

	public get cuttingable(): boolean {
		return false;
	}

	public get marker(): IMarker {
		const x = this.rect.x + this.rect.w / 2;
		const y = this.rect.y + this.rect.h / 2 + 5;

		return { 
			text: "GF",
			anchor: AnchorType.middle,
			loc: { x, y }
		};
	}

	private _minWidth: number;
	public get minWidth(): number {
		return this._minWidth;
	}
	private _maxWidth: number;
	public get maxWidth(): number {
		return this._maxWidth;
	}



	cuttingOnSite: boolean = false;

	constructor(
		template: ProjectTemplate,
		def: GlassParameter | GlassWallParameter,
		x: number,
		y: number,
		w: number,
		h: number,
		public length: number,
		paddingTop?: number,
		paddingBottom?: number
	) {

		this.locked = false;
		this.id = "Glass_" + uuidv4();

		var defId = "";
		if (def.type == ToolboxItemType.Glass) {
			this.stat = (def as GlassParameter).statics;
			defId = def.id;
		} else {
			const dgw = def as GlassWallParameter;
			defId = dgw.glassComponentId;
			this.stat = template.glasses.find(g => g.id == defId).statics;
			
		}
		this.configId = def.id;
		this.color = def.color;
		this.width = w;
		this.height = h;

		if (!paddingTop) {
			paddingTop = this.stat.paddingTop;
		}
		if (!paddingBottom) {
			paddingBottom = this.stat.paddingBottom;
		}

		this.rect = {
			x: Common.round(x - this.stat.paddingLeft * SvgParams.SCALE),
			y: Common.round(y - this.stat.paddingTop * SvgParams.SCALE),
			w: Common.round((w + this.stat.paddingLeft + this.stat.paddingRight) * SvgParams.SCALE),
			h: Common.round((h + paddingTop + paddingBottom) * SvgParams.SCALE)
		};
		this._minWidth = template.minGlassWidth(defId);
		this._maxWidth = template.maxGlassWidth(defId);
	}

	get surfaceArea() {
		return Common.round(this.width * this.height);
	}

	public get width() {
		return this.rect ? Common.round(this.rect.w / SvgParams.SCALE) : 0;
	}

	public set width(value: number) {
		if (!this.rect) {
			return;
		}

		if (this.type !== ElementType.GlassWall && !this.validate(value, this.height)) {
			return;
		}

		const newWidth = Common.round(value * SvgParams.SCALE);
		this.rect.w = newWidth;
	}

	public set height(value: number) {
		if (!this.rect) {
			return;
		}

		const newHeight = Common.round(value * SvgParams.SCALE);
		this.rect.h = newHeight;
	}

	public get height() {
		return this.rect ? Common.round(this.rect.h / SvgParams.SCALE) : 0;
	}

	public get shouldBeDivided() {
		return this.weight > this.stat.maxWeight || this.height > this.stat.maxDepth;
	}
	public getLength() {
		return this.length;
	}

	public getHeight(roofService: RoofService): number {
		if (this.bottomBar || this.topBar || roofService.checkRoofWindowOnGlass(this) || this.ventilator) {
			return this.rect.h / SvgParams.SCALE;
		} else {
			return this.length;
		}
	}


	public validate(width: number, height: number) {
		const w = width * SvgParams.SCALE;
		const h = height * SvgParams.SCALE;
		var diffW = Math.abs(this.rect.w - w);
		var diffH = Math.abs(this.rect.h - h);

		if (diffW == 0 && diffH == 0) {
			return false;
		}

		if (width > this.maxWidth) {
			return false;
		}

		// if (width > s.maxWidth || width < s.minWidth) {
		// 	return false;
		// }

		// const sqr = width * height;
		// const weight = sqr * s.weight;

		return true;
	}

	public set weight(v: number) { }

	public get weight() {
		const sqr = this.width / 1000 * this.height / 1000;
		return sqr * this.stat.weight;
	}

	public get isFirst() {
		return this.leftBar == null || this.leftBar.locked || this.leftBar.type == ElementType.SideFinish
			|| this.leftBar.leftGlass == null;
	}

	public get isLast() {
		return this.rightBar == null || this.rightBar.locked || this.rightBar.type == ElementType.SideFinish
			|| this.rightBar.rightGlass == null;
	}

	public get workingHeight() {
		return this.rect.h - (this.stat.paddingTop - this.stat.paddingBottom) * SvgParams.SCALE;
	}

	private adjustVerticalBar(bar: Profile, barW: number, diff: number, currentAreaRect: string) {
		if (bar == null) {
			return;
		}
		const padding = (barW - this.stat.paddingBottom) * SvgParams.SCALE;
		const by = bar[currentAreaRect].y + bar[currentAreaRect].h;
		const gy = this.rect.y + this.rect.h + padding;

		if (by - gy == diff) {
			bar[currentAreaRect].h -= diff;
		}

		if (bar[currentAreaRect].y - diff == this.rect.y - padding) {
			bar[currentAreaRect].y -= diff
			bar[currentAreaRect].h += diff
		}

	}

	public adjustBars(diff: number, barW: number, currentAreaRect: string) {
		this.adjustVerticalBar(this.leftBar, barW, diff, currentAreaRect);
		this.adjustVerticalBar(this.rightBar, barW, diff, currentAreaRect);
	}

}
