<div class="row">
	<div class="col col-4"><span i18n="Door|Variant">Door variant</span>:</div>
	<div class="col col-8">
		{{current.def.name}}
		<br>
		<button kendoButton (click)="showVariants=true" [disabled]="projectService.readOnly" i18n="Common|Change"
			[svgIcon]="projectService.svgIcons.pencilIcon">Change</button>
	</div>
</div>

<div class="row" style="padding-top: 10px;">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numWidth [value]="current.width" [disabled]="true"
			[max]="current.maxWidth" [min]="current.def.minWidth"></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Height">Height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [value]="current.height" [min]="current.def.minHeight"
			[max]="maxHeight" [disabled]="current.def.minHeight == current.maxHeight || projectService.readOnly"></app-numeric-textbox>&nbsp;mm&nbsp;	
			<button kendoButton (click)="openApplyDialog()" [disabled]="projectService.readOnly"><span i18n="Door|'Apply for others' button">Apply for others</span></button>
	
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox #numLocX [value]="current.locationX" [disabled]="true"
			[min]="current.minLocationX" [max]="current.maxLocationX"
			></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="current.locationY" [disabled]="current.maxLocationY == current.minLocationY || projectService.readOnly || current.minLocationY == current.maxLocationY"
			[min]="current.minLocationY" [max]="current.maxLocationY"
			></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Door|Wing width">Wing width</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox [value]="current.wingWidth" [disabled]="true"></app-numeric-textbox>&nbsp;mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Door|Wing height">Wing height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="current.wingHeight" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Glass type">Glass type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="current.def.glasses" textField="name" valueField="id"
			[(ngModel)]="current.glassId" [valuePrimitive]="true" (valueChange)="projectService.emitChange()"
			[disabled]="projectService.readOnly"></kendo-dropdownlist>
	</div>
</div>

<div class="row">
	<div class="col col-4">
		<span i18n="Door|Expand">Expand</span>:
	</div>
	<div class="col col-8">
		<kendo-buttongroup selection="multi" kendoTooltip>
			<button kendoButton [selected]="current.isExpandedTop === true" [toggleable]="true"
				[svgIcon]="projectService.svgIcons.uploadIcon"
				[disabled]="projectService.readOnly"
				(selectedChange)="expandTop()"
				i18n-title="Door|Tooltip:Expand up" title="Expand up">
			</button>
			<button kendoButton [selected]="current.isExpandedBottom === true" [toggleable]="true"
				[svgIcon]="projectService.svgIcons.uploadIcon" [disabled]="projectService.readOnly || bottomExpandDisabled"
				style="transform: rotate(180deg);" (selectedChange)="expandBottom()"
				i18n-title="Door|Tooltip:Expand down" title="Expand down">
			</button>
		</kendo-buttongroup>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Door|Additions">Additions</span>:</div>
	<div class="col col-8">
		<kendo-listview #listView [data]="extras" containerClass="k-d-flex k-flex-col k-flex-nowrap">
			<ng-template kendoListViewItemTemplate let-dataItem="dataItem">
				<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [(ngModel)]="dataItem.checked" (click)="selectExtra(dataItem)"
					[disabled]="projectService.readOnly"/>
				&nbsp;{{ dataItem.name }}<br>
			</ng-template>
		</kendo-listview>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly"
			i18n="Common|Delete">Delete</button>
	</div>
</div>

<app-door-variants-dialog [isDialogOpened]="showVariants" [item]="current" [doorVariants]="variants" (selected)="lockVariantChange($event)"></app-door-variants-dialog>
<app-confirm-dialog [dialogOpened]="applyDialogOpened" [dialogMessage]="applyDialogMessage" (dialogAction)="onApplyDialogAction($event)"></app-confirm-dialog>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row header">
		<div class="col col-12">Construction parameters</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{ current.def.minWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{ current.def.maxWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ current.def.minHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ current.def.maxHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row header">
		<div class="col col-12">Current item parameters</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{ current.def.minWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{ current.maxWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ current.def.minHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ current.maxHeight | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min loc. X:</div>
		<div class="col col-8">{{ current.minLocationX | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max loc. X:</div>
		<div class="col col-8">{{ current.maxLocationX | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min loc. Y:</div>
		<div class="col col-8">{{ current.minLocationY | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max loc. Y:</div>
		<div class="col col-8">{{ current.maxLocationY | number:'1.2-2' : currLng }} mm</div>
	</div>
</div>