import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

  @Input() dialogOpened: boolean = false;
  @Input() dialogMessage: string;
  @Output() dialogAction = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  public action(status: boolean) {
    this.dialogAction.next({status, message: this.dialogMessage})
  }
}
