import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMigrationResult } from 'src/app/_core/interfaces/IStorageCommon';

@Component({
  selector: 'app-migration-report-dialog',
  templateUrl: './migration-report-dialog.component.html',
  styleUrls: ['./migration-report-dialog.component.css']
})
export class MigrationReportDialogComponent implements OnInit {

  @Input() migrationResult: IMigrationResult;
  @Output() forceOpen = new EventEmitter()

  details: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  public open() {
    this.migrationResult = null;
    this.forceOpen.emit(true);
  }

  public close() {
    this.migrationResult = null;
    window.close();
  }
}
