<kendo-splitter orientation="vertical" id="temporary-alert-zone" style="max-height: 100vh">
	<kendo-splitter-pane>
		<kendo-splitter [ngClass]="[toolBSrvc.iconClass('None','None')]">

			<kendo-splitter-pane [collapsible]="true" [size]="splitterSize('panelLeft')" class="left-splitter-pane" (collapsedChange)="onSplitterExpand('panelLeft', $event)" (sizeChange)="onSplitterResize('panelLeft', $event)"
				[collapsed]="!isPanelExpanded('panelLeft')">
				<div>
					<kendo-card style="width: 100%; padding-bottom: 0px;">
						<!-- does not work in css -->
						<kendo-card-header>
							<div class="row">
								<div class="col col-2"><img src="../../assets/images/logo.png" alt="logo" class="logo"></div>
								<div class="col col-10 projInfo">
									<span>{{ customerName }}</span>
								</div>
							</div>													
						</kendo-card-header>
					</kendo-card>

					<app-toolbox></app-toolbox>
				</div>

				<div *ngIf="sysInfo$ | async as sysInfo" class="version">
					Designer <b>v. {{ sysInfo.version }}</b>
					<br>
					<span *ngIf="env">Environment <b>{{ env }}</b><br></span>					
					Configuration <b (click)="showVersionDetails = !showVersionDetails"
						[ngClass]="{'span-click': true, 'span-errors': projectService.template.errors?.length > 0}"
						>v. {{ projectService.template.version }} {{ projectService.template.isDraft ? 'draft': '' }}</b>

					<span class="md5" *ngIf="showVersionDetails">
						<br>ID: {{ projectService.template.productId }}
					</span>
					<div class="config-errors" *ngIf="showVersionDetails && projectService.template.errors?.length > 0">
						<br>
						Errors:
						<ul>
							<li *ngFor="let d of projectService.template.errors">{{ d }}</li>
						</ul>
					</div>

					<div *ngIf="projectService.isSupervisor">
						<span *ngIf="!projectService.showDebugInfo" (click)="projectService.showDebugInfo = true" class="span-click">Show technical details</span>
						<span *ngIf="projectService.showDebugInfo" (click)="projectService.showDebugInfo = false" class="span-click">Hide technical details</span>
					</div>
				</div>
			</kendo-splitter-pane>

			<kendo-splitter-pane class="center-splitter-pane">
				<kendo-splitter orientation="vertical" style="width: 100%;" #mainSvg>
					<kendo-splitter-pane size="125px" [scrollable]="false" [collapsible]="true" [resizable]="true" #topSplit>
						<app-areas (areaClick)="onAreaClick($event)" (zoomClick)="onZoomClick($event)"
							(undoClick)="onUndoClick($event)" (redoClick)="onRedoClick()" (resetClick)="onResetClick()" (rewindClick)="onUndoClick($event)"
							[priceValue]="currentPrice" [showPrice]="crmPresent" [undoDisabled]="undoDisabled" [redoDisabled]="redoDisabled"></app-areas>
					</kendo-splitter-pane>

					<kendo-splitter-pane [scrollable]="true" id="mainPane">
						<app-svg [setSelectedItems]="setSelectedItems" [zoom]="currentZoom"></app-svg>
					</kendo-splitter-pane>

					<kendo-splitter-pane size="200px" [scrollable]="true" [collapsible]="true" [resizable]="true">
						<app-extras-options></app-extras-options>
					</kendo-splitter-pane>

				</kendo-splitter>
			</kendo-splitter-pane>

			<kendo-splitter-pane [collapsible]="true" [size]="splitterSize('panelRight')" [scrollable]="true" (collapsedChange)="onSplitterExpand('panelRight', $event)" (sizeChange)="onSplitterResize('panelRight', $event)"
				[collapsed]="!isPanelExpanded('panelRight')">
				<kendo-card style="width: 100%">
					<!-- does not work in css -->
					<kendo-card-header>
						<div kendoTooltip [tooltipTemplate]="photo" filter="span">
							<span class="prop-desc">{{ projectService.template.productName }}</span>
						</div>
						<ng-template #photo>
							<img *ngIf="projectService.template.productPhoto" [src]="projectService.template.productPhoto" style="max-width: 200px;" />
						</ng-template>
					</kendo-card-header>
					<kendo-card-body>
						<app-project-details></app-project-details>
					</kendo-card-body>
				</kendo-card>

				<kendo-panelbar #panelStatics (stateChange)="onPanelChange('panelStatics', $event)">
					<kendo-panelbar-item i18n-title="Cards|Statics card title" title="Statics" [selected]="true" [expanded]="isPanelExpanded('panelStatics')"
						*ngIf="projectService.template.deflections">
						<ng-template kendoPanelBarContent>
							<kendo-card style="width: 100%">
								<kendo-card-body>
									<app-statics></app-statics>
								</kendo-card-body>
							</kendo-card>
						</ng-template>
					</kendo-panelbar-item>
				</kendo-panelbar>

				<kendo-card style="width: 100%; padding-top: 20px; padding-bottom: 0px;">
					<!-- does not work in css -->
					<kendo-card-header>
						<span i18n="Cards|Element properties card title">Element properties</span> <span class="prop-desc" *ngIf="currentElement">: {{ currentElement }}</span>
					</kendo-card-header>
					<kendo-card-body>
						<app-card></app-card>
					</kendo-card-body>
				</kendo-card>

				<kendo-panelbar #panelCutting (stateChange)="onPanelChange('panelCutting', $event)">
					<kendo-panelbar-item i18n-title="Cards|Cutting on site card title" title="Cutting on site" [selected]="true" [expanded]="isPanelExpanded('panelCutting')">
						<ng-template kendoPanelBarContent>
							<kendo-card style="width: 100%">
								<!-- does not work in css -->
								<!-- <kendo-card-body style="max-height: 200px; overflow: auto;"> -->
								<kendo-card-body>
									<app-specification [mode]="'CuttingOnSite'"></app-specification>
								</kendo-card-body>
							</kendo-card>
						</ng-template>
					</kendo-panelbar-item>
				</kendo-panelbar>

				<kendo-panelbar *ngIf="false">
					<kendo-panelbar-item i18n-title="Cards|Specification card title" title="Specification" [selected]="true" [expanded]="false">
						<ng-template kendoPanelBarContent>
							<kendo-card style="width: 100%">
								<!-- does not work in css -->
								<!-- <kendo-card-body style="max-height: 200px; overflow: auto;"> -->
								<kendo-card-body>
									<app-specification></app-specification>
								</kendo-card-body>
							</kendo-card>
						</ng-template>
					</kendo-panelbar-item>
				</kendo-panelbar>

			</kendo-splitter-pane>

		</kendo-splitter>

	</kendo-splitter-pane>
</kendo-splitter>

<app-wizard></app-wizard>

<app-migration-report-dialog [migrationResult]="migrationResult" (forceOpen)="forceOpen()">
</app-migration-report-dialog>

<app-error-dialog></app-error-dialog>

<app-info-dialog [dialogOpened]="infoOpened" [dialogMessage]="infoMessage"
	(dialogClosed)="onInfoClosed()"></app-info-dialog>
