import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';
import { IDoorInfo } from 'src/app/_core/interfaces/IDoorInfo';
import { ILockVariant } from 'src/app/_core/interfaces/ILockVariant';
import variants from 'src/app/_core/models/doors/_lock-variants.json';
import { Door } from 'src/app/_core/models/doors/door.model';

@Component({
  selector: 'app-door-variants-dialog',
  templateUrl: './door-variants.component.html',
  styleUrls: ['./door-variants.component.css'],
})
export class DoorVariantsComponent {
  @ViewChildren(ExpansionPanelComponent) panels: QueryList<ExpansionPanelComponent>;
  @Output() selected = new EventEmitter<{ door: IDoorInfo, lock: ILockVariant }>();
  @Input() isDialogOpened: boolean;
  @Input() doorVariants: IDoorInfo[];
  @Input() set item(v: Door){
    this.lockVariant = v?.lockVariant;
    this.doorVariant = v?.def;
  }

  lockVariant: ILockVariant;
  doorVariant: IDoorInfo;
  dialogTitle = $localize`:Door|Door variant:Door variant`;
  
  constructor() {}

  select(door: IDoorInfo, lock: ILockVariant) {
    this.doorVariant = door;
    this.lockVariant = lock;
  }

  getLockImage(door: IDoorInfo, lock: ILockVariant) {
    return `assets/images/doors/${door.rails}_${door.wings}/${lock.id}.png`;
  }

  getDoorImage(door: IDoorInfo) {
    if (door == null) {
      return null;
    }
    return `assets/images/doors/${door.rails}_${door.wings}.png`;
  }

  close(ok: boolean) {
    if (ok) {
      this.selected.emit({ door: this.doorVariant, lock: this.lockVariant })
    } else {
      this.selected.emit(null);
    }
    this.isDialogOpened = false;
  }

  getVariants() {
    return this.doorVariants;
  }

  getLockVariants(v: IDoorInfo, o: number) {
    return variants
      .filter(l => l.doorType == v.doorType && (o == 1 ? l.order < 10 : l.order >= 10))
      .sort((l1, l2) => { return l1.order > l2.order ? 1 : -1 });
  }

  public onPanelAction(index: number): void {
    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });
  }

}
