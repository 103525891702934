import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IAreaService } from "src/app/_core/interfaces/IAreaService";
import { ToolboxItem, ToolboxItemType } from "src/app/_core/models/ToolboxModel";
import { PrintChannel } from "../svg.component";
import { ISelectGlass } from "src/app/_core/interfaces/outputEvents/ISelectGlass.interface";
import { Profile } from "src/app/_core/models/profiles/profile.model";
import { MarquiseTop } from "src/app/_core/models/marquises/marquise-top.model";
import { Door } from "src/app/_core/models/doors/door.model";
import { Wall } from "src/app/_core/models/walls/wall.model";
import { MarquiseVertical } from "src/app/_core/models/marquises/marquise-vertical.model";
import { FrameProfile } from "src/app/_core/models/profiles/frame.model";
import { ToolboxItemService } from "src/app/_core/services/toolbox-item.service";
import { ProjectService } from "src/app/_core/services/project.service";
import { Glass } from "src/app/_core/models/glasses/glass.model";
import { GlassWall } from "src/app/_core/models/glasses/glassWall.model";
import { GlassPart } from "src/app/_core/models/glasses/glassPart.model";
import { Marquise } from "src/app/_core/models/marquises/marquise.model";
import { StylingService } from "src/app/_core/services/styling.service";
import { IElement } from "src/app/_core/interfaces/IElement";
import { IPointer } from "src/app/_core/interfaces/IPointer";
import { ISelectProfile } from "src/app/_core/interfaces/outputEvents/ISelectProfile.interface";

@Component({
	selector: 'g[app-svg-area-base]',
	templateUrl: './svg-area-base.component.html',
	styleUrls: ['./svg-area-base.component.css']
})
export class SvgAreaBaseComponent {

	@Input() areaService: IAreaService;
	@Input() currentTool: ToolboxItem;
	@Input() selectedItems: any[];
	@Input() printMode: boolean;
	@Input() printElements: boolean;
	@Input() channel: PrintChannel;

	@Output() selectGlassEvent = new EventEmitter<ISelectGlass>();
	@Output() selectProfileEvent = new EventEmitter<ISelectProfile>();
	@Output() selectMarquiseEvent = new EventEmitter<MarquiseTop | MarquiseVertical>();
	@Output() selectDoorEvent = new EventEmitter<Door>();
	@Output() selectWallEvent = new EventEmitter<Wall>();
	@Output() selectWallMuntinEvent = new EventEmitter<FrameProfile>();
	@Output() shapeDropEvent = new EventEmitter<IPointer>();

	constructor(
		protected toolboxService: ToolboxItemService,
		protected projectService: ProjectService,
		protected styling: StylingService)
	{}

	selectGlassWall(event: ISelectGlass) {
		this.selectGlass(event.glass, event.pointerEvent);
	}

	selectGlass(glass: Glass | GlassWall | GlassPart, pointerEvent: PointerEvent) {
		if (this.currentTool?.type == ToolboxItemType.MarquiseVertical) {
			this.areaService.onDrop(pointerEvent, this.currentTool);
			this.toolboxService.setCurrTool(null);
			return;
		}
		this.selectGlassEvent.emit({ glass, pointerEvent })
	}

	selectProfile(profile: Profile, pointer: MouseEvent) {
		this.selectProfileEvent.emit({ profile, pointer});
	}

	selectProfileInner(event: ISelectProfile) {
		this.selectProfileEvent.emit(event);
	}

	selectMarquise(marquise: MarquiseTop | MarquiseVertical) {
		const allowed = [ToolboxItemType.Glass, ToolboxItemType.GlassWallDiamond, ToolboxItemType.Door, ToolboxItemType.Wall, ToolboxItemType.MarquiseVertical];
		if (this.currentTool != null && marquise instanceof MarquiseVertical && allowed.find(a => a == this.currentTool?.type)) {
			const sn = this.areaService.rectOnSide;
			this.areaService.onDrop({ offsetX: marquise[sn].x + 100, offsetY: marquise[sn].y + 100 }, this.currentTool);
			return;
		}
		this.selectMarquiseEvent.emit(marquise)
	}

	iconClass(itemType: string, areaType: string): string {
		const ic = this.toolboxService.iconClass(itemType, areaType);
		return ic;
	}

	getClasses(item: IElement, areaType: string, classes: string = "", noHighLight: boolean = false) {
		var selected = this.selectedItems.indexOf(item) >= 0;
		return this.styling.getClasses(item, areaType, selected, classes, noHighLight);
	}


	getBackColor(color: string) {
		return this.projectService.getBackColor();
	}

	getMarquiseBackColor(mq: Marquise) {
		if (mq.fabric) {
			return `url(#fabric${mq.fabric.id.replace('-', '')})`;
		} else {
			return "#202EC933";
		}
	}

	selectDoor(d: Door) {
		if (this.currentTool?.type == ToolboxItemType.MarquiseVertical) {
			this.areaService.onDrop({ offsetX: d.rect.x + 100, offsetY: d.rect.y + 100 }, this.currentTool);
			return
		}
		this.selectDoorEvent.emit(d);
	}

	selectWall(w: Wall) {
		this.selectWallEvent.emit(w);
	}

	selectWallMuntin(m: FrameProfile) {
		this.selectWallMuntinEvent.emit(m);
	}

	onShapeDrop(event: IPointer) {
		this.shapeDropEvent.emit(event);
	}

}
