<svg:g *ngFor="let m of getMarquises()">
	<g *ngIf="m.area == areaService.areaType && montage=='Outside'">
		<rect *ngFor="let f of m.frames" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w"
			[attr.height]="f.rect.h"
			[attr.class]="styling.getClasses(m, areaService.areaType, selectedItems.indexOf(m) >= 0)"
			[attr.fill]="styling.getMarquiseBackColor(m)" [attr.stroke]="projectService.frameColor">
		</rect>
	</g>
	<rect [attr.x]="m[areaService.rectOnSide].x" [attr.y]="m[areaService.rectOnSide].y"
		[attr.width]="m[areaService.rectOnSide].w" [attr.height]="m[areaService.rectOnSide].h"
		[attr.class]="styling.getClasses(m, areaService.areaType, selectedItems.indexOf(m) >= 0)"
		(click)="selectMarquise($event, m)" [attr.fill]="styling.getMarquiseBackColor(m)"
		[attr.stroke]="projectService.frameColor">
	</rect>
	<g *ngIf="m.area == areaService.areaType">
		<rect [attr.x]="m.engine.rect.x" [attr.y]="m.engine.rect.y" [attr.width]="m.engine.rect.w"
			[attr.height]="m.engine.rect.h" [attr.fill]="m.engine.color"
			[attr.class]="styling.getClasses(m, areaService.areaType, false)">
		</rect>
	</g>
	<image *ngIf="m.isReversed && m.area == areaService.areaType" href="/assets/images/arrowRotate.png" [attr.x]="m.engine.rect.x" [attr.y]="m.engine.rect.y"
	[attr.width]="m.engine.rect.w" [attr.height]="m.engine.rect.h"/>
	<g *ngIf="m.area == areaService.areaType && montage!='Outside'">
		<rect *ngFor="let f of m.frames" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w"
			[attr.height]="f.rect.h"
			[attr.class]="styling.getClasses(m, areaService.areaType, selectedItems.indexOf(m) >= 0)"
			[attr.fill]="styling.getMarquiseBackColor(m)" [attr.stroke]="projectService.frameColor">
		</rect>
	</g>
	<g *ngIf="m.area == areaService.areaType">
		<line *ngFor="let k of m.markers" [attr.x1]="k.x1" [attr.y1]="k.y1" [attr.x2]="k.x2" [attr.y2]="k.y2"
			class="marquise-vert-marker"></line>
	</g>
</svg:g>
