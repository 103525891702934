import { Component } from '@angular/core';
import { ProjectService } from 'src/app/_core/services/project.service';

@Component({
	selector: 'app-statics',
	templateUrl: './statics.component.html',
	styleUrls: ['./statics.component.css']
})
export class StaticsComponent {
	
	constructor(public projectService: ProjectService) {

	}
}
