export class LedPattern {
	configId: string;
	patternId: string;
	barsCount: number;
	thumbnail: string;
	graphUrl: string;
	pattern : Array<number[]>;
	channels: number;

	public static getBarsCountWithLeds(pattern: Array<number[]>): number {
		var b = 0;
		pattern.forEach(pt => {
			if (pt.find(p => p > 0)) {
				b++;
			}
		});
		return b;
	}
}

export class LedPatterns {
	patterns: LedPattern[];
}
