import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './main/logout/logout.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
	{ path: '', component: MainComponent, canActivate: [MsalGuard] },
	{ path: 'error', component: MainComponent },
	{ path: 'state', component: MainComponent },
	{ path: 'code', component: MainComponent },
	{ path: 'logout', component: LogoutComponent },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
	useHash: true,
	  initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }