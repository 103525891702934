import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotifyService } from "./_core/services/notify.service";
import { IApiError, IError } from "./_core/interfaces/IErrors";

@Injectable({
	providedIn: 'root',
}) 
export class GlobalErrorHandler implements ErrorHandler {
	handleError(error: IError) {
		if (error.message) {			
			if (
				error.message.includes('NG0100: ExpressionChangedAfterItHasBeenCheckedError') ||
				error.message.includes("You provided 'undefined' where a stream was expected") ||
				error.message.includes("A silent sign-in request was sent but no user is signed in")) {
					return;
			}
		}		

		// console.debug('GlobalErrorHandler', error?.constructor?.name);
		// if (error instanceof HttpErrorResponse) {
		// 	console.error('GlobalErrorHandler HttpErrorResponse', error);
		// } else {
			console.error(error);
		// }
	}
}

@Injectable({
	providedIn: 'root'
})
export class HttpLoadingInterceptor implements HttpInterceptor {
	
	constructor(private notif: NotifyService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
		// .pipe(
		// 		finalize(() => {
		// }))
		.pipe(catchError((error: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
			if (error && error.status != 200 && error.url.includes('api/project/excel')) {
				this.notif.projectService.showTemporaryMessageSubj.next(
					{
						message: $localize`:BOM|Export to excel error message:Export to excel error occured`, hideAfter: 7000, style: 'error'
					}
				);
				return;
			}

			const inner = error.error;
			if (inner && inner["exceptionId"]) {
				let msg: IApiError = JSON.parse(inner["message"]);
				msg.exceptionId = inner["exceptionId"];
				// console.debug(inner);
				this.notif.apiException.emit(msg);
			}
			return of();
		}));
	}
}
