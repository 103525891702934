import { IOption } from "../../interfaces/IStorageCommon";
import { ProjectService } from "../../services/project.service";
import { ElementType } from "../ToolboxModel";
import { Door } from "../doors/door.model";
import { ExtraOption } from "../extra-option.model";
import { ColumnProfile } from "../profiles/column.model";


export class ExtrasStoreModel implements IOption {

	configId: string;
	count: number;
	comment: string;

	constructor(o: ExtraOption) {
		this.configId = o.configId;
		this.count = o.quantity;
	}

	public static restore(c: ExtrasStoreModel, projectService: ProjectService) {
		if (projectService.profileService.getDoors().find((d: Door) => d.def.doorExtras.indexOf(c.configId) >= 0 )) {
			return;
		}

		if (projectService.profileService.roofElements[ElementType.Column].find((col: ColumnProfile) => col.getExtras().find(x => x == c.configId) != null)) {
			return;
		}
		
		const def = projectService.template.extras.find(e => e.id == c.configId);
		var option = new ExtraOption(def, c.count);
		projectService.profileService.extraElements.push(option);
	}

}
