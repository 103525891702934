import { Injectable } from '@angular/core';
import { StraightService } from './straight.service';
import { Common } from '../../models/common';
import { SvgParams } from '../../constants/constants';
import { AreaType, RectName } from '../../interfaces/IAreaType';
import { ElementType, Orientation } from '../../models/ToolboxModel';
import { ColumnProfile } from '../../models/profiles/column.model';
import { SideFinishProfile } from '../../models/profiles/side-finish.model';
import { BarProfile } from '../../models/profiles/bar.model';
import { GutterProfile } from '../../models/profiles/gutter.model';
import { FrontProfile } from '../../models/profiles/front.model';
import { FrameProfile } from '../../models/profiles/frame.model';

@Injectable({
  providedIn: 'root'
})
export class FrontService extends StraightService {

  	public get areaType(): AreaType {
		return AreaType.Front;
	}

  	public get rectOnSide(): string {
    	return RectName.get(AreaType.Front);
	}

	showArea() {
		if (this.profileService.frontElements === null) {
			this.profileService.frontElementsInit();
			this.calculatePoints();
			if (this.profileService.roofElements !== null) {
				this.addElementsAfterDisplayedRoof(this.profileService.roofElements);
			}
		}
	}

  	changeArea() {
		this.profileService.frontElements = null;
		this.showArea();
	}


  	public calculatePoints() {

	  const guttHeight = this.projectService.template.getFrontSize().height;
	  const sideWidth = this.projectService.template.getBarSize().width;

		this._workAreaPoints = [];
		this._points = [];
		this._svg = "";

		if (this.width <= 0 || this.depth <= 0) {
			return;
		}

		const bh = this.projectService.template.backHeight;
		const fh = Common.round(this.projectService.template.frontHeight);
		const df = Common.round((bh - fh) * SvgParams.SCALE);
		const gh = Common.round(guttHeight * SvgParams.SCALE);

		const rh = Common.round(this.projectService.template.getRearSize().height);
		const y2 = Common.round(SvgParams.START_Y + (bh + rh) * SvgParams.SCALE);

		this._points.push({ x: SvgParams.START_X, y: SvgParams.START_Y + df});
		this._points.push({ x: Common.round(SvgParams.START_X + this.width * SvgParams.SCALE), y: SvgParams.START_Y + df });
		this._points.push({ x: Common.round(SvgParams.START_X + this.width * SvgParams.SCALE), y: y2 });
		this._points.push({ x: SvgParams.START_X, y: y2});

		const sw = sideWidth * SvgParams.SCALE;

		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.LEFT_TOP_POINT_NUM].x + sw), y: Common.round(this._points[SvgParams.LEFT_TOP_POINT_NUM].y + gh) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.RIGHT_TOP_POINT_NUM].x - sw), y: Common.round(this._points[SvgParams.RIGHT_TOP_POINT_NUM].y + gh) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x + sw), y: Common.round(this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].x - sw), y: Common.round(this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y) });

	}

	public addElementsAfterDisplayedRoof(roofElements): void {
		const template = this.projectService.template;
		const bar = template.getDefaultBar();
		const rear = template.getDefaultRear();
		const guttH = template.getFrontSize().height;

		this.profileService.frontElements[ElementType.Front] = [];
		roofElements[ElementType.Front].forEach((front: FrontProfile) => {
			front.height = guttH;
			front.width = template.getFrontSize().width;
			var x = Common.round(front.rectOnRoof.x)
			var y = this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - Common.round(Math.round(this.projectService.template.columnLength) * SvgParams.SCALE) - front.height * SvgParams.SCALE;
			this.saveProfileWithSetRect(front, x, y);
		});

		roofElements[ElementType.Gutter].forEach((gutter: GutterProfile) => {
			var x = Common.round(gutter.rectOnRoof.x)
			var y = this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - Common.round(Math.round(this.projectService.template.columnLength) * SvgParams.SCALE) - gutter.height * SvgParams.SCALE;
			this.saveProfileWithSetRect(gutter, x, y);
		});

		var frontTY = roofElements[ElementType.Front][0].rectOnFront.y;
		const frontBY = frontTY + roofElements[ElementType.Front][0].rectOnFront.h;
		roofElements[ElementType.Column].filter((col: ColumnProfile) => col.isFront).forEach((col: ColumnProfile) => {
			this.saveColWithSetRect(col, col.rectOnRoof.x, frontBY);
		});

		const barSize = template.getBarSize();

		frontTY = this._points[SvgParams.LEFT_TOP_POINT_NUM].y + Common.round((rear.size.height - rear.reinforcementHeight - barSize.height) * SvgParams.SCALE);
		roofElements[ElementType.Bar].forEach((b: BarProfile) => {
			const barW = b.getBarsDefaultWidth(template);
			const barH = b.getBarsDefaultHeight(template);
			if (b.orientation === Orientation.Vertical) {
				b.rectOnFront = {
					x: Common.round(b.rectOnRoof.x),
					y: frontTY,
					w: Common.round(barW * SvgParams.SCALE),
					h: Common.round(barH * SvgParams.SCALE)
				};
				if (!this.profileService.frontElements[ElementType.Bar].find(d => d.id == b.id)) {
					this.profileService.frontElements[ElementType.Bar].push(b);
				}
			}
		});

		roofElements[ElementType.SideFinish].forEach((sideFin: SideFinishProfile) => {
			const sideFinH = sideFin.getBarsDefaultHeight(template);

			sideFin.lineOnFront = {
				x1: Common.round(sideFin.lineOnRoof.x1),
				y1: frontTY,
				x2: Common.round(sideFin.lineOnRoof.x1),
				y2: frontTY + sideFinH * SvgParams.SCALE,
				w: Common.round(sideFin.lineOnRoof.w),
			};
			if (!this.profileService.frontElements[ElementType.SideFinish].find(el => el.id == sideFin.id)) {
				this.profileService.frontElements[ElementType.SideFinish].push(sideFin);
			}
		});
	}
  
	getTopProfileBottomY() {
		const fronts = this.profileService.frontElements[ElementType.Front];
		if (fronts?.length > 0) {
			const front = fronts[0];
			return Common.round(front.rectOnFront.y + front.rectOnFront.h);
		}
		return this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y;
	}

	public createTopProfile(): FrameProfile {
		const tempConnector = this.projectService.template.doors[0].connectorId;
		const front = this.profileService.frontElements[ElementType.Front][0] as FrontProfile;
		return new FrameProfile(0, front.rectOnFront.y + front.rectOnFront.h, 0, { width: 0, height: 0, depth: 0 }, "", Orientation.Horizontal, tempConnector);
	}

}
