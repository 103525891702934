import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { AreaType } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from "../../template";
import { Glass } from "../glasses/glass.model";
import { IPoint } from "../../interfaces/IPoint";
import { ElementSide } from "../../interfaces/IElementSide";
import { GlassBunch } from "../glasses/glassBunch";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class SideFinishProfile extends Profile {

  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  get type() {
    return ElementType.SideFinish;
  }

  get name(): string {
    return $localize`:Common|Bar:Bar`;
  }

  get rightX() {
    return this.lineOnRoof.x1 + this.lineOnRoof.w / 2;
  }
  get leftX() {
    return this.lineOnRoof.x1 - this.lineOnRoof.w / 2;
  }

  private _weight: number;
  get weight(){
    return this._weight;
  }

  public anchor: IPoint;
  public rotation: number;

  private glasses: GlassBunch;

  private firstOrDefault(collection: Glass[]) {
    return collection && collection.length > 0 ? collection[0] : null;
  }

  // TODO: To refactor: change to GlassBucket class
  public getGlasses(side: ElementSide): Glass[] {
    return this.glasses.getGlasses(side);
  }

  public addGlass(side: ElementSide, glass: Glass) {
    return this.glasses.assignGlass(side, glass);
  }

  public removeGlass(side: ElementSide, glass: Glass) {
    return this.glasses.removeGlass(side, glass);
  }

  get rightGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Right));
  }
  set rightGlass(glass: Glass) {
    this.glasses.clearOnSide(ElementSide.Right);
    this.glasses.assignGlass(ElementSide.Right, glass);
  }

  get leftGlass(): Glass {
    return this.firstOrDefault(this.glasses.getGlasses(ElementSide.Left));
  }
  set leftGlass(glass: Glass) {
    this.glasses.clearOnSide(ElementSide.Left);
    this.glasses.assignGlass(ElementSide.Left, glass);
  }

	constructor(
    currentArea: AreaType,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    length: number,
    area: AreaType,
    private template: ProjectTemplate
  ) {

		const bar = template.bars.find(b => b.id === template.barId);

		super(Orientation.Vertical, bar.size, bar.color);

    this.id = this.type + "_" + uuidv4();
    this.configId = template.barId;
    this.glasses = new GlassBunch();
    this.length = length;
    this._weight = bar.weight;

    if (area === AreaType.None) {
			area = currentArea;
		}

    if (area === AreaType.Roof) {
      this.lineOnRoof = {
				x1: x1,
				y1: y1,
        w: Math.round(this.template.getBarSize().width * SvgParams.SCALE),
				x2: x2,
				y2: y2,
			};
    }
  }
}
