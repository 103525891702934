import {Component, Input, OnInit} from '@angular/core';
import {ProjectService} from '../../_core/services/project.service';
import {ProfileService} from '../../_core/services/profile.service';
import {ProjectSpecification, SpecMode} from '../../_core/models/project-specification.model';
import {AreaType} from '../../_core/interfaces/IAreaType';
import { RoofService } from 'src/app/_core/services/areas/roof.service';

@Component({
	selector: 'app-specification',
	templateUrl: './spec.component.html',
	styleUrls: ['./spec.component.css']
})
export class SpecComponent implements OnInit{
	projectSpecification: ProjectSpecification;

	private _mode: SpecMode = SpecMode.Spec;
	@Input() set mode(m: SpecMode) {
		this._mode = m;
	}
	get mode() {
		return this._mode;
	}

	constructor(
		private project: ProjectService,
		private profileService: ProfileService,
		private roofService: RoofService
	) {
	}

	ngOnInit() {
		this.projectSpecification = new ProjectSpecification(this.roofService, this.profileService, AreaType.None, this.mode);

		this.project.elementsChanged.subscribe(a => {
			if (!a) {
				return;
			}
			this.projectSpecification.setup();
		})
	}

	getType(catId: string, elements: string) {
		var e = this.project.template[elements].find(c => c.id == catId);
		return e ? e.name : "-";
	}

	checkElementsForCutting(): boolean {
		return this.projectSpecification.bars.length > 0
			|| this.projectSpecification.columns.length > 0
			|| this.projectSpecification.rears.length > 0
			|| this.projectSpecification.fronts.length > 0
			|| this.projectSpecification.glassWalls.length > 0
			|| this.projectSpecification.glassWallDiamonds.length > 0
	}
}
