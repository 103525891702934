import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Color, ColorOfPalette } from '../../_core/models/colors/color.model';
import { ProjectService } from '../../_core/services/project.service';
import { DropDownTreeComponent } from '@progress/kendo-angular-dropdowns';
import { IMarquise } from 'src/app/_core/interfaces/IMarquise';
import { GraphicsService } from 'src/app/_core/services/graphics-service';

@Component({
	selector: 'app-color-palette',
	templateUrl: './color-palette.component.html',
	styleUrls: ['./color-palette.component.css']
})
export class ColorPaletteComponent implements OnInit {

	public dialogOpened: boolean = false;
	public dialogMessage: string;	
	public darkness: string;

	public infoOpened: boolean = false;
	public infoMessage: string;

	_dropDownTree: DropDownTreeComponent;
	@ViewChild('dropDownTree', { static: false }) set dropDownTree(ddt: DropDownTreeComponent) {
		this._dropDownTree = ddt;

		this.assignPreviousValueToDropDownTree()
	};

	@Input() set colorsPalette(colors: Color[]) {
		this.colors = colors;
	}

	_element: IMarquise;
	applyForOthersPossible: boolean;
	@Input() set element(e: IMarquise) {
		this._element = e;

		this.applyForOthersPossible = e.fabric != null;
		this.dropDownTreeColorsValue = this._element.fabric;
		this.dropDownTreeColorsDataItem = this.dropDownTreeColorsValue;
		this.previousDropDownTreeColorValue = this.dropDownTreeColorsValue;

		this.assignPreviousValueToDropDownTree();
	};
	@Input() disabled = false;

	colors: Color[];

	// add-color region

	isColorsGroupDialogOpened: boolean = false;
	previousDropDownTreeColorValue: any;
	dropDownTreeColorsValue: Color | ColorOfPalette;
	dropDownTreeColorsDataItem: Color | ColorOfPalette;
	dropDownTreeExpandedNodePaletteId: string;

	valueIdOfPaletteForDialog: string;
	previousValueIdOfPaletteForDialog: string;
	dialogColorsGroup: ColorOfPalette[];
	dialogColorsGroupTitle: string;
	resetSelectedColorInDialog: boolean = false;

	// end region add-color

	constructor(
		public projectService: ProjectService,
		private graphicsService: GraphicsService) { }

	ngOnInit(): void {
		if (this._element) {
			return;
		}
		this.projectService.templateChangedSubject.subscribe(template => {			
			if (!this.projectService.selectedColor) {
				this.projectService.selectedColor = template.colors.find(color => color.isDefault);
			}
			setTimeout(() => {
				this.graphicsService.getBrightness(this.projectService.selectedColor);
			}, 0);

			this.dropDownTreeColorsValue = this.projectService.selectedColor;
			this.dropDownTreeColorsDataItem = this.dropDownTreeColorsValue;
			this.previousDropDownTreeColorValue = this.dropDownTreeColorsValue;
		});

		this.projectService.colorChanged.subscribe(c => {
			this.dropDownTreeColorsValue = c;
			this.dropDownTreeColorsDataItem = this.dropDownTreeColorsValue;
			this.previousDropDownTreeColorValue = this.dropDownTreeColorsValue;
			
			this.graphicsService.getBrightness(this.projectService.selectedColor);
		});

	}

	saveColor(color) {
		this._element
			? (this._element.fabric = color)
			: this.projectService.selectedColor = color;
		this.applyForOthersPossible = this._element?.fabric != null;
		this.projectService.emitChange();

		if (this._element) {
			this.graphicsService.getBackgroud(this._element.id, color);
		} else {
			this.graphicsService.getBrightness(this.projectService.selectedColor);
		}

	}

	dropDownTreeValueChange(value: any) {
		if (!value) {
			this.assignDefaultValueToDropDownTree();
			this._dropDownTree?.toggle(false);
			this.resetSelectedColorInDialog = true;
		} else if (value.paletteId) {
			this.assignPreviousValueToDropDownTree();
		} else if (value.name && !value.type) {
			this.valueIdOfPaletteForDialog = value.id;
			this.assignPreviousValueToDropDownTree();
			const palId = this.dropDownTreeExpandedNodePaletteId ?? this._element.marquiseInfo.paletteId;
			const colorsGroup = this.projectService.template.paletteColors.filter(p => p.paletteId == palId && p.groupId == value.id);
			this.openColorsGroupDialog(value, colorsGroup);
		} else {
			this.previousDropDownTreeColorValue = value;
			this._dropDownTree?.toggle(false);

			this.saveColor(value);
		}
	}

	openPopup(value) {
		this.valueIdOfPaletteForDialog = value.id;
		this.assignPreviousValueToDropDownTree();
		const palId = this.dropDownTreeExpandedNodePaletteId ?? this._element.marquiseInfo.paletteId;
		const colorsGroup = this.projectService.template.paletteColors.filter(p => p.paletteId == palId && p.groupId == value.id);
		this.openColorsGroupDialog(value, colorsGroup);
	}

	openColorsGroupDialog(value, colorsGroup) {
		this.dialogColorsGroupTitle = value.name;
		this.dialogColorsGroup = colorsGroup;
		this.isColorsGroupDialogOpened = true;
	}

	onColorsGroupDialogAction(selectedColor) {
		if (selectedColor) {
			this.previousDropDownTreeColorValue = selectedColor;
			this.previousValueIdOfPaletteForDialog = this.valueIdOfPaletteForDialog;

			this.saveColor(selectedColor);
		}
		this.assignPreviousValueToDropDownTree();

		this.isColorsGroupDialogOpened = false;
		this._dropDownTree?.toggle(false);
	}

	private assignPreviousValueToDropDownTree() {
		setTimeout(() => {
			if (this._dropDownTree && this.previousDropDownTreeColorValue) {
				this._dropDownTree.value = this.previousDropDownTreeColorValue;
				this._dropDownTree.text = this.previousDropDownTreeColorValue.name || this.previousDropDownTreeColorValue.description;
				let selectedKey = this.getSelectedKey();
				this._dropDownTree.selectedKeys = [selectedKey];
			}
		}, 0)		
	}

	private getSelectedKey() {
		if (this.previousDropDownTreeColorValue.color && !this.previousDropDownTreeColorValue.photo ||
				this.previousDropDownTreeColorValue.image) {
			return this.previousValueIdOfPaletteForDialog;
		} else {
			return this.previousDropDownTreeColorValue.id;
		}
	}

	assignDefaultValueToDropDownTree() {
		const defaultColor = this.colors.find(color => color.isDefault);
		this.previousDropDownTreeColorValue = defaultColor ?? {};

		this.saveColor(defaultColor);

		this.assignPreviousValueToDropDownTree();
	}

	nodeDropDownTreeExpand(event) {
		this.dropDownTreeExpandedNodePaletteId = event.dataItem.paletteId;
	}

	getBackgroundColorForDropDownTreeColorIcon() {
		const image = this.previousDropDownTreeColorValue.image;
		const photo = this.previousDropDownTreeColorValue.photo;
		const color = this.previousDropDownTreeColorValue.color;

		return photo || image ? '' : `#${color}`;
	}

	getBackgroundImageForDropDownTreeColorIcon() {
		const image = this.previousDropDownTreeColorValue.image;
		const photo = this.previousDropDownTreeColorValue.photo;

		return image ? `url(${image})` : `url(${photo})`;
	}

	openDialog(): void {
		this.dialogOpened = true;
		this.dialogMessage = $localize`:Messages|Apply fabric to other confirmation:Do you really want to apply selected fabric to all marquises?`;
	}

	onDialogAction(info: { status: boolean, message: string }) {
		this.dialogOpened = false;

		if (!info.status) return;

		var palOk = true;
		const palId = this._element.marquiseInfo.paletteId;
		this.projectService.profileService.roofElements[this._element.type].forEach((e: IMarquise) => {			
			if (e.marquiseInfo.paletteId == palId) {
				e.fabric = this._element.fabric;
			} else {
				palOk = false;
			}
		});
		this.projectService.emitChange();
		
		if (!palOk) {
			this.infoMessage = "Some marquises couldn't be changed due to different fabric palette";
			this.infoOpened = true;
		}
	}

	onInfoClosed() {
		this.infoOpened = false;
	}

}
