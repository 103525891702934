import { Profile } from "./profile.model";
import { Orientation, ElementType, ToolboxItem } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { WizardParameter } from '../wizard/wizard-parameter.model';
import { Common } from '../common';
import { WallProfile } from "./wall.model";
import { LayerLevel } from "../../interfaces/LayerLevel";

export class WallGutterProfile extends Profile {

	get type() {
		return ElementType.WallGutter;
	}

	public get cuttingable(): boolean {
		return false;
	}

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}


	get name(): string {
		return $localize`:Common|Wall gutter:Wall gutter`;
	}		

	constructor(
		x: number,
		y: number,
		public rear: WallProfile,
		info: WizardParameter) {

		super(Orientation.Vertical, info.size, info.color);
		this.id = "WallGutter_" + uuidv4();
		this.configId = info.id;
		this.length = info.size.width;

		this.rectOnRoof = {
			x: Common.round(x),
			y: Common.round(y),
			w: Math.round(this.width * SvgParams.SCALE),
			h: Math.round(this.depth * SvgParams.SCALE)
		};
	}

}
