import { Common } from '../common';
import { SvgParams } from '../../constants/constants';
import { IRect } from '../../interfaces/IRect';
import { v4 as uuidv4 } from 'uuid';
import { ElementType } from '../ToolboxModel';
import { Glass } from './glass.model';
import { RoofWindowConnector } from './roofWindowConnector.model';
import { ElementSide } from '../../interfaces/IElementSide';
import { WizardParameter, RoofWindowParameter } from '../wizard/wizard-parameter.model';
import { IElement } from '../../interfaces/IElement';
import { LayerLevel } from '../../interfaces/LayerLevel';

export class RoofWindow implements IElement {
	
	get type() {
		return  ElementType.RoofWindow;
	};

	get name() {
		return $localize`:Common|Roof window:Roof window`;
	};
	
	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	id: string;
	configId: string;
	color: string;
	connectorTop = new RoofWindowConnector(this, ElementSide.Top);
	connectorBottom = new RoofWindowConnector(this, ElementSide.Bottom);
	connectorColor: string;
	connectorDepth: number;
	connectorConfigId: string;
	padding: number;

	topGlass: Glass;
	bottomGlass: Glass;

	rect: IRect;

	private _windowChannels: number;
	private _weight: number;

	get width() {
		return this.rect ? Common.round(this.rect.w / SvgParams.SCALE) : 0;
	}

	get height() {
		return this.rect ? Common.round(this.rect.h / SvgParams.SCALE) : 0;
	}

	get maxYPoint() {
		return this.connectorBottom.visible ? this.connectorBottom.rect.y + this.connectorBottom.rect.h : this.rect.y + this.rect.h;
	}

	get minYPoint() {
		return this.connectorTop.visible ? this.connectorTop.rect.y : this.rect.y;
	}

	get rightX() {
		return this.rect.x + this.rect.w;
	}

	get leftX() {
		return this.rect.x;
	}

	get channels(){
		return this._windowChannels;
	}

	get weight(){
		return this._weight;
	}

	constructor(x: number, y: number, w: number, rwParams: RoofWindowParameter, connector: WizardParameter) {
		this.id = uuidv4();
		this.configId = rwParams.id;
		this.color = rwParams.color;
		this.connectorColor = connector.color;
		this.connectorDepth = connector.size.depth * SvgParams.SCALE;
		this.connectorConfigId = connector.id;
		this.padding = rwParams.padding;
		this._windowChannels = rwParams.channels;
		this._weight = rwParams.weight;

		this.rect = {
			x: Common.round(x),
			y: Common.round(y),
			w: Common.round(w * SvgParams.SCALE),
			h: Common.round(rwParams.depth * SvgParams.SCALE)
		};
	}

	setTopConnector() {
		this.connectorTop.visible = true;
		this.connectorBottom.visible = false;
	}

	setBottomConnector() {
		this.connectorTop.visible = false;
		this.connectorBottom.visible = true;
	}

	setBothConnectors() {
		this.connectorTop.visible = true;
		this.connectorBottom.visible = true;
	}

	calculateTopGlassHeight() {
		return Math.round(this.topGlass?.height ?? 0);
	}

	calculateBottomGlassHeight() {
		return Math.round(this.bottomGlass?.height ?? 0);
	}
}
