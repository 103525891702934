<!-- Main rear shape -->
<svg:path *ngIf="!printMode" [attr.d]="areaService.getSvg()" stroke="#282a30" stroke-width="0.2" fill="#d9ecf5"
    fill-opacity="0.4" stroke-dasharray="4,4"
    [class]="styling.iconClass('Shape', 'Rear')"
    (click)="onShapeDrop($event);">
</svg:path>

<!-- Marquise-top-bottom -->			
<svg:g *ngIf="(!printMode || printElements) && styling.showLayer(0)">
    <svg:rect *ngFor="let m of projectService.profileService.rearElements['MarquiseTopBottom']" [attr.x]="m.rectOnRear.x"
        [attr.y]="m.rectOnRear.y" [attr.width]="m.rectOnRear.w"
        [attr.height]="m.rectOnRear.h"
        [attr.fill]="styling.getMarquiseBackColor(m)"
        [attr.stroke]="projectService.frameColor"
        [class]="getClasses(m, 'Rear', 'marquise top-bottom')"
        (click)="selectMarquise(m)">
    </svg:rect>
</svg:g>

<!-- Profiles: Column - holders only -->
<svg:g *ngFor="let l of projectService.profileService.rearElements['Column']">
    <svg:rect *ngFor="let h of l.getHolder('Rear', projectService.profileService.getSortedColumns('roofElements', isRear))"
        [attr.x]="h.rect.x" [attr.y]="h.rect.y" [attr.width]="h.rect.w" [attr.height]="h.rect.h"
        [attr.stroke]="h.stroke" [attr.stroke-width]="h.strokeWidth" fill="none">
    </svg:rect>
</svg:g>

<svg:g *ngIf="!printMode || printElements">

    <!-- Vertical marquises -->
    <svg:g *ngIf="styling.showLayer(0)" app-marquise-vert [areaService]="areaService"
        [selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)" [montage]="'Inside'" [printMode]="printMode">
    </svg:g>

    <!-- Wall -->
    <svg:g app-wall [area]="'Rear'" [selectedItems]="selectedItems"
        (selectWallEvent)="selectWall($event)" (selectWallMuntinEvent)="selectWallMuntin($event)" [printMode]="printMode">
    </svg:g>

    <!-- Doors -->
    <svg:g app-door [area]="'Rear'" [selectedItems]="selectedItems"
        (selectDoorEvent)="selectDoor($event)" [printMode]="printMode">
    </svg:g>

    <!-- Vertical marquises -->
    <svg:g app-marquise-vert [areaService]="areaService"
        [selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)" [montage]="'Middle'" [printMode]="printMode">
    </svg:g>

</svg:g>

<!-- Profiles: WallProfile -->
<svg:rect *ngFor="let l of projectService.profileService.rearElements['WallProfile']" [attr.x]="l.rectOnRear.x" [attr.y]="l.rectOnRear.y"
    [attr.width]="l.rectOnRear.w" [attr.height]="l.rectOnRear.h"
    [class]="getClasses(l,'Rear', 'profile')"
    (click)="selectProfile(l, $event)"
    [attr.fill]="styling.getBackColor(l.color)"
    [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Column without holders -->
<svg:g app-column [areaService]="areaService" [selectedItems]="selectedItems" [printMode]="printMode" [printElements]="printElements" (selectProfileEvent)="selectProfileInner($event)">
</svg:g>

<svg:g *ngIf="!printMode && styling.showLayer(2)">
    <!-- Marquise-top-up -->
    <svg:g *ngFor="let m of projectService.profileService.rearElements['MarquiseTopUp']">
        <svg:rect *ngFor="let m of projectService.profileService.roofElements['MarquiseTopUp']"
            [attr.x]="m.rectOnRear.x" [attr.y]="m.rectOnRear.y" [attr.width]="m.rectOnRear.w" [attr.height]="m.rectOnRear.h"
            [attr.fill]="styling.getMarquiseBackColor(m)" [attr.stroke]="projectService.frameColor"
            [class]="getClasses(m, 'Rear', 'marquise top-up')"
            (click)="selectMarquise(m)">
        </svg:rect>
        <line *ngFor="let k of m.markers" [attr.x1]="k.x1" [attr.y1]="k.y1" [attr.x2]="k.x2" [attr.y2]="k.y2" class="marquise-marker"></line>
    </svg:g>
</svg:g>

<!-- All for Glasses-Wall -->
<svg:g app-glass-wall *ngIf="!printMode || printElements"
        [elements]="projectService.profileService.rearElements"
        [areaType]="'Rear'"
        [selectedItems]="selectedItems"
        [printMode]="printMode"
        (selectGlassWallEvent)="selectGlassWall($event)"
        (selectProfileEvent)="selectProfile($event)"
>
</svg:g>

<!-- Vertical marquises -->
<svg:g app-marquise-vert *ngIf="styling.showLayer(2) && (!printMode || printElements)" [areaService]="areaService"
    [selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)" [montage]="'Outside'" [printMode]="printMode">
</svg:g>

<!-- Dimensions -->
<svg:g *ngFor="let d of projectService.profileService.rearElements['Dimension']">
    <svg:g app-dimension [item]="d" [printMode]="printMode"></svg:g>
</svg:g>

<svg:g app-customer-plate *ngIf="projectService.info != null && printMode && channel != 'CRM'" [area]="'Rear'"></svg:g>