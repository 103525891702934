<div *ngIf="mode === 'CuttingOnSite' && !checkElementsForCutting()" style="text-align: center;"
	i18n="Cutting on site|Message">No elements for cutting on site</div>

<div class="element" *ngIf="projectSpecification.glasses.length > 0">
	<div class="title" i18n="Cutting on site|'Glasses' label">Glasses</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let glass of projectSpecification.glasses" >
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Width">Width</span>:</td>
					<td>{{ glass.width }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Height">Height</span>:</td>
					<td>{{ glass.height }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(glass.configId, 'glasses') }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Count">Count</span>:</td>
					<td>{{ glass.count }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.bars.length > 0">
	<div class="title" i18n="Cutting on site|'Bars' label">Bars</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let bar of projectSpecification.bars">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Length">Length</span>:</td>
					<td>{{ bar.length }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Count">Count</span>:</td>
					<td>{{ bar.count }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.columns.length > 0">
	<div class="title" i18n="Cutting on site|'Columns' label">Columns</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let column of projectSpecification.columns">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Height">Height</span>:</td>
					<td>{{ column.length }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(column.configId, 'columns') }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Count">Count</span>:</td>
					<td>{{ column.count }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.rears.length > 0">
	<div class="title" i18n="Cutting on site|'Rears' label">Rears</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let rear of projectSpecification.rears">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Width">Width</span>:</td>
					<td>{{ rear.length }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(rear.configId, 'rears') }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Count">Count</span>:</td>
					<td>{{ rear.count }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.fronts.length > 0">
	<div class="title" i18n="Cutting on site|'Fronts' label">Fronts</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let rear of projectSpecification.fronts">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Width">Width</span>:</td>
					<td>{{ rear.length }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(rear.configId, 'fronts') }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Count">Count</span>:</td>
					<td>{{ rear.count }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.glassWalls.length > 0">
	<div class="title" i18n="Cutting on site|'Glass walls' label">Glass walls</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let glass of projectSpecification.glassWalls">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Width">Width</span>:</td>
					<td>{{ glass.width }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Height">Height</span>:</td>
					<td>{{ glass.height }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(glass.configId, 'wallGlasses') }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<div class="element" *ngIf="projectSpecification.glassWallDiamonds.length > 0">
	<div class="title" i18n="Cutting on site|'Glass wall diamonds' label">Glass wall diamonds</div>
	<div class="row" style="padding-bottom: 10px;" *ngFor="let glass of projectSpecification.glassWallDiamonds">
		<div class="col col-12">
			<table style="width: 100%">
				<tr>
					<td style="width: 100px;"><span i18n="Common|Width">Width</span>:</td>
					<td>{{ glass.width }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Height">Height</span>:</td>
					<td>{{ glass.height }}</td>
				</tr>
				<tr>
					<td><span i18n="Common|Type">Type</span>:</td>
					<td>{{ getType(glass.configId, 'diamondGlasses') }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>