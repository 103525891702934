import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { ToolboxItemService } from 'src/app/_core/services/toolbox-item.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { AreaType, CollectionName, RectName } from 'src/app/_core/interfaces/IAreaType';
import { StylingService } from 'src/app/_core/services/styling.service';
import { IAreaService } from 'src/app/_core/interfaces/IAreaService';
import { Profile } from 'src/app/_core/models/profiles/profile.model';
import { IPointer } from 'src/app/_core/interfaces/IPointer';
import { ISelectProfile } from 'src/app/_core/interfaces/outputEvents/ISelectProfile.interface';
import { SvgParams } from 'src/app/_core/constants/constants';

@Component({
	selector: 'g[app-column]',
	templateUrl: './column.component.html',
	styleUrls: ['./column.component.css']
})
export class ColumnComponent {
	@Input() selectedItems: any[];
	@Input() set areaService(v: IAreaService) {
		this.rect = RectName.get(AreaType[v.areaType]);
		this.area = v.areaType;
	}
	@Input() printMode: boolean;
	@Input() printElements: boolean;
	@Output() selectProfileEvent = new EventEmitter<ISelectProfile>();

	private rect: string;
	private area: AreaType;

	constructor(private toolBSrvc: ToolboxItemService,
		private projectService: ProjectService,
		public styling: StylingService) {
	}

	get maxy() {
		return this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
	}

	getColumns() {
		const coll = CollectionName.get(this.area);
		var columns = this.projectService.profileService[coll][ElementType.Column];
		return columns;
	}

	iconClass(itemType: string, areaType: string): string {
		return this.toolBSrvc.iconClass(itemType, areaType);
	}

	selectProfile(profile: Profile, pointer: MouseEvent) {
		this.selectProfileEvent.emit({ profile, pointer });
	}

}
