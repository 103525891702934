<div>
	<kendo-dialog
		[title]="dialogTitle"
		*ngIf="isDialogOpened"
		(close)="close(false)"
		[width]="850"
		[maxHeight]="1100">
		
		<div class="row">
			<div class="col-6 img" *ngFor="let lv of getLayouts()">
				<img class="side" [attr.src]="getImage(lv)" (click)="select(lv)" [ngClass]="{'highlight': layout?.id == lv.id }"/>
			</div>
		</div>

		<hr>
		
		<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;" [disabled]="layout == null" [(ngModel)]="secondSide" />&nbsp;
			<span i18n="Side layout popup|Apply also on the other side">Apply also on the other side</span>

		<kendo-dialog-actions>
			<button kendoButton (click)="close(false)" i18n="Dialogs|'Cancel' button">Cancel</button>
            <button kendoButton (click)="close(true)" primary="true" i18n="Dialogs|'Confirm' button" [disabled]="layout == null">Confirm</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>
