import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent {

  @Input() dialogOpened: boolean;
  @Input() dialogMessage: string;
  @Output() dialogClosed = new EventEmitter()

  constructor() { }

  public close() {
    this.dialogClosed.emit();
  }
}
