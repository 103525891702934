import { Component, OnInit } from '@angular/core';
import { ToolboxGroup, ToolboxItem, ToolboxItemType } from '../../_core/models/ToolboxModel';
import { ApiService } from '../../_core/services/api.service';
import { ProjectService } from '../../_core/services/project.service';
import { RoofService } from '../../_core/services/areas/roof.service';
import { AreaType } from '../../_core/interfaces/IAreaType';
import { ToolboxItemService } from '../../_core/services/toolbox-item.service';

@Component({
	selector: 'app-toolbox',
	templateUrl: './toolbox.component.html',
	styleUrls: ['./toolbox.component.css']
})
export class ToolboxComponent implements OnInit {
	model: ToolboxGroup[];
	showLoading: boolean = true;
	messageLoading: string = $localize`:Messages|'Please wait' message:Please wait`;

	constructor(
		private api: ApiService,
		private project: ProjectService,
		public roofService: RoofService,
		public toolBSrvc: ToolboxItemService,
	) {
		this.project.projectReady.subscribe(item => {
			setTimeout(() => {
				this.showLoading = false
			}, 1000);
		}, error => {
			this.showLoading = false
		})
	}

  public baseIconUrl;
  
  public iconUrl(imageName: string): string {
    return this.baseIconUrl + imageName + ".png";
  }

	ngOnInit() {
		this.getItems(AreaType.None);
	}

	setCurrentTool(tool: ToolboxItem) {
		this.toolBSrvc.setCurrTool(tool);
	}

	getItems(area: AreaType) {
		if (area == AreaType.None) {
			return;
		}
		this.api.getToolbox(this.project.productId, this.project.brandName, area).subscribe((m: any) => {
			this.model = m.groups;
			if (area == AreaType.Roof) {
				const ilum = this.model.find(m => m.type == ToolboxItemType.LedSpot);
				if (ilum) {
					this.project.ledSpotName = ilum.items.find(i => i.type == ToolboxItemType.LedSpot)?.name;
					this.project.ledStripeName = ilum.items.find(i => i.type == ToolboxItemType.LedStripBar)?.name
				}
			}
		})
	}
}
