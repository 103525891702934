import { SvgParams } from "../constants/constants";
import { IAreaService } from "../interfaces/IAreaService";
import { AreaType } from "../interfaces/IAreaType";
import { ProjectService } from "../services/project.service";
import { FrameProfile } from "./profiles/frame.model";
import { Profile } from "./profiles/profile.model";

export class Space {
	get startPosY(): number {
		if (this.topProfile) {
			return this.topProfile.rect.y
		}
		return null;
	}

	public getLeftX(area: AreaType): number {
		return this.leftProfile ? this.leftProfile.getRect(area).x + this.leftProfile.getRect(area).w : SvgParams.START_X;
	}

	public getRightX(area: AreaType): number {
		if (this.rightProfile) {
			return this.rightProfile.getRect(area).x;
		}
		return this.projectService.CurrentAreaService.getWorkAreaPoints()[SvgParams.RIGHT_TOP_POINT_NUM].x;
	}
	
	public getTopY(area: AreaType): number {
		return this.topProfile ? this.topProfile.getRect(area).y : SvgParams.START_Y;
	}

	public getBottomY(area: AreaType, sideService: IAreaService = null): number {
		if (this.bottomProfile) {
			return this.bottomProfile.getRect(area).y + this.bottomProfile.getRect(area).h;
		}
		var srv = sideService ?? this.projectService.CurrentAreaService;
		return srv.getWorkAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
	}

	constructor(
		public leftProfile: Profile = null,
		public rightProfile: Profile = null,
		public topProfile: FrameProfile = null,
		public bottomProfile: FrameProfile = null,
		private projectService: ProjectService = null) {}
	
}
