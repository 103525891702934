import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../_core/services/project.service';
import { ProfileService } from '../../_core/services/profile.service';
import { ProjectSpecification } from '../../_core/models/project-specification.model';
import { AreaType } from '../../_core/interfaces/IAreaType';
import { ApiService } from '../../_core/services/api.service';
import { v4 as uuidv4 } from 'uuid';
import { BomElements, BomRoofBarsTypes, BomRoofElements, BomSideElements, ExportState, IPrintoutEventArgs, IPrintoutFile, PrintoutType } from '../../_core/models/boms/bom.model';
import { ElementType } from '../../_core/models/ToolboxModel';
import { IBarDetails } from '../../_core/interfaces/specs/IBarSpec';
import { IColumnDetails } from '../../_core/interfaces/specs/IColumnSpec';
import { IFrontDetails } from '../../_core/interfaces/specs/IFrontSpec';
import { ColumnProfile } from '../../_core/models/profiles/column.model';
import { GUID_EMPTY, SvgParams, Tags, COLUMNS_AVALIABLE } from '../../_core/constants/constants';
import { LocationService } from '../../_core/services/location.service';
import { Marquise } from '../../_core/models/marquises/marquise.model';
import { IMarquiseSpec } from '../../_core/interfaces/specs/IMarquiseSpec';
import { ToolboxItemService } from '../../_core/services/toolbox-item.service';
import { NgForm } from '@angular/forms';
import { IGlassSpec, IGlassWallDiamondSpec, IGlassWallSpec } from '../../_core/interfaces/specs/IGlassSpec';
import { SideFinishProfile } from '../../_core/models/profiles/side-finish.model';
import { BarProfile } from '../../_core/models/profiles/bar.model';
import { FrontProfile } from '../../_core/models/profiles/front.model';
import { WallProfile } from '../../_core/models/profiles/wall.model';
import { IRoofWindowSpec } from 'src/app/_core/interfaces/specs/IRoofWindowSpec';
import { RoofWindow } from 'src/app/_core/models/glasses/roofWindow.model';
import { Glass } from 'src/app/_core/models/glasses/glass.model';
import { ICommonSpec } from 'src/app/_core/interfaces/specs/ICommonSpec';
import { environment } from 'src/environments/environment';
import { ProfileConnector } from 'src/app/_core/models/profiles/connector.model';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { RoofService } from 'src/app/_core/services/areas/roof.service';
import { AuthService } from 'src/app/_core/services/auth.service';
import { CrmService } from 'src/app/_core/services/crm.service';

export enum PopupExportType {
	None = "",
	fullConstruction = 'fullConstruction',
	additionsOnly = 'additionsOnly',
	individual = 'individual',
}

enum BomElementsName {
	glasses = 'glasses',
	bars = 'bars',
	columns = 'columns',
	rears = 'rears',
	fronts = 'fronts',
	marquises = 'marquises',
	ledSpots = 'ledSpots',
	ledStripes = 'ledStripes',
	roofWindows = 'roofWindows',
	roofGutters = 'roofGutters',
	wallGlasses = 'wallGlasses',
	diamondGlasses = 'diamondGlasses',
	extras = 'extras',
	ventilators = 'ventilators',
	wallGutters = 'wallGutters',
	doors = 'doors',
	walls = 'walls',
}

const elementsTransl = new Map<BomElementsName, string>([
	[BomElementsName.glasses, $localize`:BOM|'Glasses' label:Glasses`],
	[BomElementsName.bars, $localize`:BOM|'Bars' label:Bars`],
	[BomElementsName.columns, $localize`:BOM|'Columns' label:Columns`],
	[BomElementsName.rears, $localize`:BOM|'Rears' label:Rears`],
	[BomElementsName.fronts, $localize`:BOM|'Fronts' label:Fronts`],
	[BomElementsName.marquises, $localize`:BOM|'Marquises' label:Marquises`],
	[BomElementsName.ledSpots, $localize`:BOM|'LED spots' label:LED spots`],
	[BomElementsName.ledStripes, $localize`:BOM|'LED stripes' label:LED stripes`],
	[BomElementsName.wallGlasses, $localize`:BOM|Wall glasses:Wall glasses`],
	[BomElementsName.diamondGlasses, $localize`:BOM|Diamond glasses:Diamond glasses`],
	[BomElementsName.extras, $localize`:BOM|'Extras' label:Extras`],
	[BomElementsName.ventilators, $localize`:BOM|'Ventilators' label:Ventilators`],
	[BomElementsName.wallGutters, $localize`:BOM|'Wall gutters' label:Wall gutters`],
	[BomElementsName.roofWindows, $localize`:BOM|'Roof windows' label:Roof windows`],
	[BomElementsName.roofGutters, $localize`:BOM|'Roof gutters' label:Roof gutters`],
	[BomElementsName.doors, $localize`:BOM|Door:Door`],
	[BomElementsName.walls, $localize`:BOM|Wall:Wall`],
]);

const areasTransl = new Map<AreaType, string>([
	[AreaType.Roof, $localize`:BOM|'Roof' label:Roof`],
	[AreaType.Left, $localize`:BOM|'Left side' label:Left`],
	[AreaType.Right, $localize`:BOM|'Right side' label:Right`],
	[AreaType.Front, $localize`:BOM|'Front' label:Front`],
	[AreaType.Rear, $localize`:BOM|'Rear' label:Back`],
]);

// :BOM|'Left side' label:Left side

@Component({
	selector: 'app-bom',
	templateUrl: './bom.component.html',
	styleUrls: ['./bom.component.css']
})
export class BomComponent implements OnInit, OnDestroy {
	@ViewChild('f') exportForm: NgForm;
	@ViewChild("anchor") public anchor: ElementRef;
	@ViewChild("popup", { read: ElementRef }) public popup: ElementRef;
	@ViewChild("confirmDialog", { read: ElementRef, static: false }) public confirmDialog: ElementRef;

	private exportType: PopupExportType = PopupExportType.fullConstruction;

	public individualElementsForPopup = [];
	private chosenAdditionsOnly = {};

	private roofElements = {};
	private rearElements = {};
	private frontElements = {};
	private leftElements = {};
	private rightElements = {};
	private extras: ICommonSpec[] = [];
	private expectedImages: number = 0;
	private additions: PrintoutType[] = [];

	private boms: any = {};

	private images = [];

	isPopupShown: boolean = false;

	isStatusDialogOpened: boolean = false;
	statusDialogMessage: string = "";
	exportSuccess: boolean;
	exportInProgress: boolean;

	additionsOnly = false;
	individual = false;
	isExportBtmDisabled = false;

	get exportButtonLabel(): string {
		if (this.projectService.draftMode) {
			return $localize`:Toolbar|'Export BOM' button disabled tooltip:Export to ERP is disabled in test mode`;
		}
		else if (this.projectService.isValid) {
			if((this.projectService.readOnly && this.crmService.isCrmPresent) || !this.crmService.isCrmPresent){
				return $localize`:Toolbar|'Export BOM' button tooltip:Export to ERP`;
			} else {
				return $localize`:Toolbar|'Export BOM' button tooltip:Project is editable, export is disabled`;
			}
		} else {
			return $localize`:Toolbar|'Export BOM' button disabled tooltip:Project is not valid, export is disabled`;
		}
	}

	dialogTitle = $localize`:BOM|'Export BOM' header:Export to ERP`;

	public get canShowExport(): boolean {
		return this.authService.user?.hasAccessToERPExport ?? false;
	}

	constructor(
		private profileService: ProfileService,
		public projectService: ProjectService,
		private locationService: LocationService,
		public toolBSrvc: ToolboxItemService,
		private api: ApiService,
		private roofService: RoofService,
		private authService: AuthService,
		public crmService: CrmService) {
	}

	projectSpecification = new ProjectSpecification(this.roofService, this.profileService);

	ngOnInit(): void {
		this.projectService.printoutReady.subscribe((printout: IPrintoutFile) => {
			this.checkImagesAndSendBOM(printout);
		});
	}

	ngOnDestroy(): void {
	}

	private checkImagesAndSendBOM(printout: IPrintoutFile) {
		if (printout != null && printout.type != PrintoutType.None) {
			this.images.push({ PrintoutType: PrintoutType[printout.type], Id: printout.content });
		}

		if (this.expectedImages == 0 || this.images.length === this.expectedImages) {
			this.sendBomToApi();
		}
	}

	getInvalidColor(parentType: string): string {
		return this[parentType] ?  '#ff6358' : '';
	}

	onExportType(exportType: string) {
		this.exportType = PopupExportType[exportType];

		this.additionsOnly = false;
		this.individual = false;
		this.isExportBtmDisabled = false;

		this.setFormValues(PopupExportType.additionsOnly, false);
		this.setFormValues(PopupExportType.individual, false);

		if (exportType !== PopupExportType.fullConstruction) {
			this.setFormValues(exportType, true);
		}
	}

	onCheckboxElem(event, checkboxElemId: string, parentType: string, siblingParentType: string) {
		this.exportType = PopupExportType[parentType];

		this.additionsOnly = false;
		this.individual = false;
		this.isExportBtmDisabled = false;

		this.setFormValues(PopupExportType[siblingParentType], false);

		const isAllUnchecked = Object.keys(this.exportForm.form.value[parentType])
			.map(controlName => {
				this.exportForm.form.get(parentType).get(controlName).markAsDirty({onlySelf:true});
				return controlName;
			})
			.filter(v => v !== checkboxElemId)
			.every(v => this.exportForm.form.value[parentType][v] === false)

		if (isAllUnchecked && !event.target.checked) {
			this[parentType] = true;
			this.isExportBtmDisabled = true;
		}
	}

	setFormValues(popupExportType, booleanValue: boolean) {
		const formValueData = {...this.exportForm.form.value[PopupExportType[popupExportType]]};

		Object.keys(formValueData).forEach(valKey => formValueData[valKey] = booleanValue);
		this.exportForm.form.patchValue({
			[PopupExportType[popupExportType]]: formValueData,
		})
	}

	getIndividualElements() {
		this.extras = [];
		this.roofElements = this.collectRoof();
		this.rearElements = this.collect(AreaType.Rear);
		this.frontElements = this.collect(AreaType.Front);
		this.leftElements = this.collect(AreaType.Left);
		this.rightElements = this.collect(AreaType.Right);
		this.profileService.extraElements.forEach(e => {
			this.extras.push( { configId: e.configId, count: e.quantity, length: 0, id: ""});
		});
		this.profileService.roofElements[ElementType.Column].forEach((c: ColumnProfile) => {
			if (c.foundation) {
				this.addOrUpdateExtra(c.foundation.id);
				c.getExtras().forEach(e => {
					this.addOrUpdateExtra(e);
				});
			}
		});
	}

	private addOrUpdateExtra(eid: string) {
		var extra = this.extras.find(e => e.configId == eid);
		if (extra) {
			extra.count++;
		} else {
			this.extras.push({ configId: eid, count: 1, length: 0, id: "" });
		}
	}

	getType(configId: string, elements: string) {
		if (elements === "extras") {
			elements = "hiddenExtras";
		} else if (elements == "ventilators" || elements == "roofGutters") {
			elements = 'roofWindows';
		}
		const e = this.projectService.template[elements].find(c => c.id == configId);
		return e ? e.name : "-";
	}

	collect(area: AreaType) {
		const spec = new ProjectSpecification(this.roofService, this.profileService, area);
		spec.setup();

		const bom = new BomSideElements(
			spec.glassWalls,
			spec.extras,
			spec.glassWallDiamonds,
			spec.marquises,
			spec.doors,
			spec.walls
		)

		const bomElemsForForm = {...bom};
		this.collectIndividualElements(bomElemsForForm, area);

		return bom;
	}

	collectIndividualElements(bomElemsForForm, area: AreaType) {
		for (let elementsKey in bomElemsForForm) {
			for (let elem of bomElemsForForm[elementsKey]) {
				const elemForPopup = {...elem};

				let type;
				switch (elementsKey) {
					case BomElementsName.ledSpots:
					case BomElementsName.ledStripes:
						type = "";
						break;
					case BomElementsName.extras:
						continue;
					default:
						type = this.getType(elemForPopup.configId, elementsKey);
						break;
				}

				switch (elementsKey) {
					case BomElementsName.doors:
					case BomElementsName.walls:
					case BomElementsName.diamondGlasses:
						if (area == AreaType.Left || area == AreaType.Right) {
							elemForPopup.depth = elemForPopup.width;
							elemForPopup.width = null;
						}
						break;
					case BomElementsName.ledStripes:
						elemForPopup.height = null;
						elemForPopup.length = null;
						break;
					case BomElementsName.roofWindows:
						elemForPopup.type = type;
						break;
					case BomElementsName.marquises:
						if(elemForPopup.type == ElementType.MarquiseVertical){
							if(area == AreaType.Front){
								elemForPopup.height = elemForPopup.depth;
								elemForPopup.depth = null;
							} else {
								elemForPopup.height = elemForPopup.depth;
								elemForPopup.depth = elemForPopup.width;
								elemForPopup.width = null;
							}
						}
						elemForPopup.type = type;
						break;
				}

				if(elemForPopup.count == null){
					elemForPopup.count = 1;
				}

				if(elemForPopup.height == null && elemForPopup.length){
					elemForPopup.height = elemForPopup.length;
				}

				elemForPopup.name = elementsTransl.get(elementsKey as BomElementsName);
				elemForPopup.side = areasTransl.get(area);

				this.individualElementsForPopup.push(elemForPopup);
			}
		}
	}

	collectRoof() {
		const spec = new ProjectSpecification(this.roofService, this.profileService, AreaType.Roof);
		spec.setup();

		const externals: IBarDetails[] = [];
		this.profileService.roofElements[ElementType.SideFinish].forEach((s: SideFinishProfile) => {
			externals.push({ configId: s.configId, width: Math.round(s.getLength()), location: this.locationService.getLocation(s, AreaType.Roof), cuttingOnSite: s.cuttingOnSite });
		});

		const internals: IBarDetails[] = [];
		this.profileService.roofElements[ElementType.Bar].forEach((s: BarProfile) => {
			internals.push({ configId: s.configId, width: Math.round(s.getLength()), location: this.locationService.getLocation(s, AreaType.Roof), cuttingOnSite: s.cuttingOnSite });
		});

		const columns: IColumnDetails[] = [];
		this.profileService.roofElements[ElementType.Column].forEach((s: ColumnProfile) => {
			let h = Math.round(s.getHeight());
			let eh = COLUMNS_AVALIABLE.find(c => c >= h);
			columns.push({
				configId: s.configId,
				height: eh,
				location: this.locationService.getLocation(s, AreaType.Roof),
				isRear: s.isRear,
				cuttingOnSite: s.cuttingOnSite,
				outflow: s.outflow != ElementSide.None 
			 });
		});

		const fronts: IFrontDetails[] = [];
		this.profileService.roofElements[ElementType.Front].forEach((s: FrontProfile) => {
			fronts.push({
				configId: s.configId,
				width: s.getLength(),
				location: this.locationService.getLocation(s, AreaType.Roof),
				cuttingOnSite: s.cuttingOnSite,
				left: s.tag == Tags.LEFT_FRONT_TAG,
				right: s.tag == Tags.RIGHT_FRONT_TAG
			});
		});

		const rears: IFrontDetails[] = [];
		this.profileService.roofElements[ElementType.WallProfile].forEach((s: WallProfile) => {
			var r: IFrontDetails = {
				configId: s.configId,
				width: s.getLength(),
				location: this.locationService.getLocation(s, AreaType.Roof),
				cuttingOnSite: s.cuttingOnSite,
				left: s.tag == Tags.LEFT_REAR_TAG,
				right: s.tag == Tags.RIGHT_REAR_TAG
			};
			if(s.wallGutters?.length > 0) {
				r.wallGutters = [];
				s.wallGutters.forEach(sq => {
					r.wallGutters.push(sq.configId);
				});
			}

			rears.push(r);
		});


		const rear = this.projectService.template.getDefaultRear();
		const marquiseTopBottomCorrection: number = rear.marquiseTopBottomCorrection;
		const marquises: IMarquiseSpec[] = []
		const marquiseTypes = [ElementType.MarquiseTopUp, ElementType.MarquiseTopBottom];
		marquiseTypes.forEach(t => {
			this.profileService.roofElements[t].forEach(m => {
				const m2 = m as Marquise;
				const color = m2.fabric ? (m2.fabric["name"] ? m2.fabric["name"] : m2.fabric["description"]) : null;
				const ln = m.getMarquiseOnSide(ElementSide.Left, this.profileService.roofElements);
				const rn = m.getMarquiseOnSide(ElementSide.Right, this.profileService.roofElements);
				marquises.push({
					id: uuidv4(),
					type: m2.type,
					width: m2.getERPWidth(),
					depth: m2.getErpDepth(),
					configId: m2.configId,
					color: color?.trim(),
					engineId: m2.engine.configId,
					handlerId: m2.handler.id,
					leftMarqId: ln?.id,
					rightMarqId: rn?.id,
					isReserved: false
				});
			});
		});

		var connectors: IBarDetails[] = [];
		var roofWindows: IRoofWindowSpec[] = [];
		var roofGutters: IRoofWindowSpec[] = [];

		this.profileService.roofElements[ElementType.ProfileConnector].forEach((s: ProfileConnector) => {
			connectors.push({ configId: s.configId, width: Math.round(s.length), location: Math.round(this.locationService.getLocation(s, AreaType.Roof)), cuttingOnSite: s.cuttingOnSite });
		});

		const types = [ElementType.RoofWindow, ElementType.RoofGutter];
		types.forEach(t => {
			this.profileService.roofElements[t].forEach((m: RoofWindow) => {				

				const rw: IRoofWindowSpec = {
					configId: m.configId,
					width: Math.round(m.width),
					location: Math.round((m.rect.y - SvgParams.START_Y) / SvgParams.SCALE),
					bottomGlass: this.findSpecByGlass(spec.glasses, m.bottomGlass),
					topGlass: this.findSpecByGlass(spec.glasses, m.topGlass)
				};
				if (t == ElementType.RoofWindow) {
					roofWindows.push(rw);
				} else {
					roofGutters.push(rw);
				}
				if (m.connectorBottom.visible) {
					connectors.push({ configId: m.connectorConfigId, width: Math.round(m.bottomGlass.width), location: Math.round(m.connectorBottom.rect.y), cuttingOnSite: false });
				}
				if (m.connectorTop.visible) {
					connectors.push({ configId: m.connectorConfigId, width: Math.round(m.topGlass.width), location: Math.round(m.connectorTop.rect.y), cuttingOnSite: false });
				}

			});
		});

		const leds = this.profileService.roofElements[ElementType.LedStripe];
		const stripesCount = [...new Set(leds.map(item => item.stripId))].length;
		var leXport: ICommonSpec[] = [];
		if (stripesCount > 0) {
			const _l = leds[0];
			leXport.push({ configId: _l.configId, count: stripesCount, length: 0, id: leds[0].id, note: `${leds.length} x ${_l.length}` });
		}

		const bom = new BomRoofElements(
			spec.glasses,
			new BomRoofBarsTypes(externals, internals),
			connectors,
			columns,
			rears,
			fronts,
			marquises,
			roofWindows,
			roofGutters,
			spec.vents,
			spec.wallGuts,
			leXport,
			this.projectService.ledPattern?.patternId
		);

		var glView: IGlassSpec[] = [];

		spec.glasses.forEach((g: IGlassSpec) => {
			var gv = glView.find(v => v.configId == g.configId && v.height == g.height && v.width == g.width && g.areaType == v.areaType);
			if (gv) {				
				gv.count += g.count;
			} else {
				glView.push(g);
			}
		});

		var bomElemsForForm = {
			glasses: [...glView],
			bars: [...spec.bars],
			columns: [...spec.columns],
			rears: [...spec.rears],
			fronts: [...spec.fronts],
			marquises: [...bom.marquises],
			roofWindows: [...bom.roofWindows],
			roofGutters: [...bom.roofGutters],
			ventilators: [...spec.vents],
			wallGutters: [...spec.wallGuts],
			ledStripes: [...bom.leds],
			ledSpots: this.projectService.ledPattern ? [{ id: bom.ledPatternId }] : []
		};

		this.collectIndividualElements(bomElemsForForm, AreaType.Roof);

		return bom;
	}

	private findSpecByGlass(glasses: IGlassSpec[], glass: Glass): IGlassSpec {
		if (glass == null) {
			return null;
		}
		return glasses.find(l => l.configId == glass.configId && Math.round(l.width) == Math.round(glass.width) && Math.round(l.height) == Math.round(glass.height));
	}

	collectRear() {
		const spec = new ProjectSpecification(this.roofService, this.profileService, AreaType.Roof);
		spec.setup();

		// const bom = new BomRearElements(columns)
		// return bom;

		// https://trello.com/c/zVCDYVQD - Rear columns should be added to ROOF construction
		// For now no more elements on rear

		return null;
	}

	collectBomToSend(boms: any) {
		const t = this.projectService.template;
		// const boms: any = this.boms;

		const main = {
			id: this.projectService.productId,
			snowZone: t.snowZone,
			windZone: t.windZone,
			orderCode: this.projectService.orderCode,
			color: this.projectService.selectedColor.name, // ?? this.projectService.selectedColor.description,
			isStandalone: this.projectService.template.isStandalone,
			sizes: {
				width: t.width,
				depth: t.depth,
				backHeight: t.backHeight,
				frontHeight: Math.trunc(this.projectService.template.frontHeight),
				angle: this.projectService.template.dropAngle
			},
			customerName: this.projectService.info?.customerName
		}

		const toSend = {
			boms,
			main,
			images: this.images,
			extras: this.extras
		};

		return toSend;
	}

	sendBomToApi() {
		const toSend = this.collectBomToSend(this.boms);
		const emptyImage = this.images.find(i => i.Id == GUID_EMPTY);

		if (emptyImage) {
			setTimeout(() => {
				this.statusDialogMessage =  $localize`:BOM|Image export error message:An error has occurred while exporting an image to ERP. Image type: ` + emptyImage.PrintoutType;
			}, 0);
			this.exportSuccess = false;
			if (!environment.localhost) {
				return;
			}
		}

		this.api.sendBom(this.projectService.productId, toSend, this.projectService.brandName).subscribe(e => {
			if (e == GUID_EMPTY) {
				setTimeout(() => {
					this.statusDialogMessage = $localize`:BOM|Export error message:An error has occurred while exporting to ERP. Please try again.`;
				}, 0);
				this.exportSuccess = false;
			} else {
				setTimeout(() => {
					this.statusDialogMessage = $localize`:BOM|Export success message:Data export to ERP was successful`;
				}, 0);
				this.exportSuccess = true;
			}
		});
		this.exportInProgress = false;
	}

	sendBom() {
		this.images = [];
		const requestId = uuidv4();
		this.expectedImages = 0;
		this.projectSpecification.setup();

		switch (this.exportType) {
			case PopupExportType.fullConstruction:
				
				const typesToPrint = [ElementType.GlassWall, ElementType.GlassWallDiamond, ElementType.MarquiseVertical, ElementType.Door];
				const le = this.profileService.isAnyElement(AreaType.Left, typesToPrint) ? 1 : 0;
				const re = this.profileService.isAnyElement(AreaType.Right, typesToPrint) ? 1 : 0;
				const te = this.profileService.isAnyElement(AreaType.Roof, [ElementType.MarquiseTopBottom, ElementType.MarquiseTopUp]) ? 1 : 0;
				const fe = this.profileService.isAnyElement(AreaType.Front, typesToPrint) ? 1 : 0;
				const be = this.profileService.isAnyElement(AreaType.Rear, typesToPrint) ? 1 : 0;

				const areasCount = 4; // left, right, roof, front
				this.expectedImages = areasCount + le + re + te + fe + be;
				if (this.projectService.template.isStandalone) {
					this.expectedImages++; // rear
				}
				this.projectService.printProjectSubj.next({ requestId, sideType: undefined });
				break;

			case PopupExportType.additionsOnly:
				const additions = [];
				Object.keys(this.exportForm.form.value[this.exportType]).forEach(key => {
					if (this.exportForm.form.value[this.exportType][key]) {
						additions.push(key)
					}
				})
				additions.forEach((sideType: PrintoutType) => this.projectService.printProjectSubj.next({ requestId, sideType }))

				let chosenValues = [];
				Object.keys(this.chosenAdditionsOnly).forEach(key => {
					if (!this.chosenAdditionsOnly[key]) return;

					key = key === PrintoutType.Top ? AreaType.Roof : (PrintoutType.Rear ? AreaType.Rear : key);

					this.projectSpecification.glasses.forEach((g: IGlassSpec) => {
						if (g.areaType === key) chosenValues.push(key)
					})
					this.projectSpecification.glassWalls.forEach((g: IGlassWallSpec) => {
						if (g.areaType === key) chosenValues.push(key)
					})
					this.projectSpecification.glassWallDiamonds.forEach((g: IGlassWallDiamondSpec) => {
						if (g.areaType === key) chosenValues.push(key)
					})

					chosenValues.push(key)
				})
				this.expectedImages = chosenValues.length;

				break;

			case PopupExportType.individual:
				const elements: IPrintoutEventArgs[] = [];
				Object.keys(this.exportForm.form.value[this.exportType]).forEach(key => {
					if (this.exportForm.form.value[this.exportType][key]) {
						var diamond: IGlassWallDiamondSpec = this.boms.left.diamondGlasses.find(g => g.id == key);
						if (diamond) {
							elements.push({ requestId, sideType: PrintoutType.Left, elementType: ElementType.GlassWallDiamond, elementId: key });
						} else {
							diamond = this.boms.right.diamondGlasses.find(g => g.id == key);
							if (diamond) {
								elements.push({ requestId, sideType: PrintoutType.Right, elementType: ElementType.GlassWallDiamond, elementId: key });
							}
						}

						// if (diamond) {
						// 	this.expectedImages += diamond.parts.length;
						// }

						this.projectSpecification.glassWalls.forEach((g: IGlassWallSpec) => {
							elements.push({ requestId, sideType: PrintoutType[g.areaType], elementType: ElementType.GlassWall, elementId: key });
						})

					}
				});
				this.expectedImages = elements.length;
				elements.forEach((e: IPrintoutEventArgs) => this.projectService.printProjectSubj.next(e));
				if (elements.length == 0) {
					this.projectService.printoutReady.next({ type: PrintoutType.None, content: PrintoutType.None });
				}
				break;
		}
	}

	onConfirmDialogAction(status: any) {
		this.additions = [];

		if (status) {
			this.boms = this.getBoms();
			this.sendBom();
		}
	}

	onSubmit() {
		this.chosenAdditionsOnly = {...this.exportForm.form.value[PopupExportType.additionsOnly]};
		this.isStatusDialogOpened = true;
	}

	addToAdditions(p: string){
		var isP = this.additions.find(f => f == PrintoutType[p]);
		if(isP){
			this.additions = this.additions.filter((e, i) => e != PrintoutType[p]); 
		} else {
			this.additions.push(PrintoutType[p]);
		}
	}

	public getBoms(type: PopupExportType = PopupExportType.None) {
		const boms: any = new BomElements();

		if (type == PopupExportType.None) {
			type = this.exportType;
		}

		switch (this.exportType) {
			case PopupExportType.fullConstruction:
				boms.roof = this.roofElements;
				boms.rear = this.rearElements;
				boms.front = this.frontElements;
				boms.left = this.leftElements;
				boms.right = this.rightElements;
				break;

			case PopupExportType.additionsOnly:
				Object.keys(this.exportForm.form.value[this.exportType]).forEach(key => {
					var a = this.additions.find(t => t == PrintoutType[key]);
					if (a) {
						switch (key) {
							case PrintoutType.Top:
								boms.roof = this.roofElements;
								break;

							case PrintoutType.Left:
								boms.left = this.leftElements;
								break;

							case PrintoutType.Right:
								boms.right = this.rightElements;
								break;

							case PrintoutType.Front:
								boms.front = this.frontElements;
								break;

							case PrintoutType.Rear:
								if (this.projectService.template.isStandalone) {
									boms.rear = this.rearElements;
								}
								break;
	
						}
					}
				})
				break;

			case PopupExportType.individual:
				Object.keys(this.exportForm.form.value[this.exportType]).forEach(key => {
					const formOptionElementValue = this.exportForm.form.value[this.exportType][key];
					if (formOptionElementValue) {
						const elemForBom = {...this.individualElementsForPopup.find(e => e.id === key)};

						const bomsSideName = elemForBom.side.toLowerCase();
						const elemName = elemForBom.name.trim();

						var nameParts = elemName.split(' ');
						var ename = elemName;
						if (nameParts.length > 1) {
							ename = nameParts[0] + nameParts[1].substr(0, 1).toUpperCase() + nameParts[1].substr(1);
						}

						switch (ename) {
							case BomElementsName.bars:
								this[bomsSideName + 'Elements'][elemName].externals
									.filter(e => e.width === elemForBom.length)
									.forEach(element => boms[bomsSideName][elemName].externals.push(element));
								this[bomsSideName + 'Elements'][elemName].internals
									.filter(e => e.width === elemForBom.length)
									.forEach(element => boms[bomsSideName][elemName].internals.push(element));
								break;

							case BomElementsName.ledSpots:
								boms[bomsSideName].ledPatternId = this[bomsSideName + 'Elements'].ledPatternId;
								break;

							case BomElementsName.ledStripes:
								this[bomsSideName + 'Elements']["leds"]
									.forEach(element => boms[bomsSideName]["leds"].push(element));
								break;

							case BomElementsName.columns:
								if (elemForBom.isRear) {
									this.rearElements[elemName]
										.filter(e => e.height === elemForBom.length)
										.forEach(element => boms.rear[elemName].push(element))
								}
							default:

								this[bomsSideName + 'Elements'][ename]
									.filter(e => {
										if (elemName === BomElementsName.marquises) {
											return (e.width === elemForBom.width && e.depth === elemForBom.depth)
										} else {
											return e.width === elemForBom.length
												|| e.height === elemForBom.length
												|| (e.width === elemForBom.width && e.height === elemForBom.height)
										}
									})
									.forEach(element => boms[bomsSideName][ename].push(element));
								break;
						}
					}
				})

				break;
		}

		return boms;
	}

	public togglePopup(show?: boolean): void {
		const s = show !== undefined ? show : !this.isPopupShown;
		this.isPopupShown = s;

		if (this.isPopupShown) {
			this.individualElementsForPopup = [];
			this.exportType = PopupExportType.fullConstruction;
			this.getIndividualElements();

			if (!this.projectService.isValid) {
				this.statusDialogMessage = $localize`:Toolbar|'Export BOM' button disabled tooltip:Project is not valid, export is disabled`;
				this.exportSuccess = false;
				this.exportInProgress = false;
				this.isStatusDialogOpened = true;
			}
	
		}
	}

	private contains(target: EventTarget): boolean {
		return (
			this.anchor.nativeElement.contains(target) ||
			(this.popup ? this.popup.nativeElement.contains(target) : false) ||
			(this.confirmDialog ? this.confirmDialog.nativeElement.contains(target) : false)
		);
	}

	public action(status: boolean) {
		if (status) {
			this.onSubmit();
			this.onConfirmDialogAction(true);
			this.statusDialogMessage = "";
			this.exportInProgress = true;
		} else {
			this.togglePopup(false);
			this.crmService.sendCloseRequest(ExportState.Cancel);
		}
	}

	public closePopup() {
		this.isStatusDialogOpened = false;
		this.togglePopup(false);
		this.crmService.sendCloseRequest(this.exportSuccess ? ExportState.Success : ExportState.Error);
	}

	getIcon() {
		if (this.exportInProgress) {
			return;
		}
		if (this.exportSuccess) {
			return this.projectService.svgIcons.checkOutlineIcon;
		}
		return this.projectService.svgIcons.xIcon;
	}
}
