export enum ToolboxItemType {
	None = "NONE",
	RoofWindow = "ROOF_WINDOW",
	Glass = "ROOFING",
	GlassWall = "GLASS_WALL",
	GlassWallDiamond = "GLASS_WALL_DIAMOND",
	MarquiseTopUp = "MARQUISE_TOP_UP",
	MarquiseTopBottom = "MARQUISE_TOP_BOTTOM",
	MarquiseVertical = "MARQUISE_SIDE",
	LedSpot = "LED_SPOT",
	LedStripBar = "LED_STRIP_BAR",
	Connector = "PROFILE_ROOF_CONNECTOR",
	Column = "PROFILE_COLUMN",
	Extra = "EXTRA",
	WallGutter = "PROFILE_ROOF_REAR_GUTTER",
	RoofVentilator = "ROOF_VENTILATOR",
	RoofGutter = "ROOF_GUTTER",
	Door = "DOOR",
	Wall = "WALL",
	Elements = "ELEMENTS"
}

export enum ElementIconType {
	General = "general_style",
	None = "icon_none",
	Column = "icon_column",
	Glass = "icon_glass",
	Marquise = "icon_marquise",
	LedSpot = "icon_led_spot",
	LedStripBar = "icon_led_strip",
	Connector = "Connector",
	Extras = "icon_extras",
	IconBarV = "icon_barV",
	IconBarH = "icon_barH",
	WallGutter = "icon_wallgutter",
	RoofGutter = "icon_roofgutter",
	Wall = "icon_wall",
	AddElements = "icon_add_elements",
}

export enum ElementType {
	None = "None",
	Grid = "Grid",
	Shape = "Shape",
	WallProfile = "WallProfile",
	Bar = "Bar",
	Gutter = "Gutter",
	Front = "Front",
	SideFinish = "SideFinish",
	Column = "Column",
	RoofWindow = "RoofWindow",
	Glass = "Glass",
	GlassWall = "GlassWall",
	GlassWallDiamond = "GlassWallDiamond",
	GlassPart = "GlassPart",
	GlassPartDiamond = "GlassPartDiamond",
	Led = "LED",
	LedStripe = "LEDStripe",
	MarquiseTopUp = "MarquiseTopUp",
	MarquiseTopUpMouseover = "MarquiseTopUpMouseover",
	MarquiseTopBottom = "MarquiseTopBottom",
	MarquiseTopBottomMouseover = "MarquiseTopBottomMouseover",
	MarquiseVertical = "MarquiseVertical",
	Dimension = "Dimension",
	Muntin = "Muntin",
	Frame = "Frame",
	WallGutter = "WallGutter",
	ExtraOption = "ExtraOption",
	ProfileConnector = "ProfileConnector",
	RoofVentilator = "RoofVentilator",
	RoofGutter = "RoofGutter",
	Door = "Door",
	Wall = "Wall"
}

export enum Orientation {
	None = "None",
	Horizontal = "Horizontal",
	Vertical = "Vertical"
}

export class ToolboxGroup {
	title : string;
	type: ToolboxItemType;
	items: ToolboxItem[];
}

export class ToolboxItem {
	id: string
	name: string;
	order: number;
	photo: string;
	photoDescription: string;
	thumbnail: string;
	type: ToolboxItemType;
	parameterName: string;
	color: string;

	size: {width: number, height: number, depth: number}
}

// export interface ToolboxItemProfile extends ToolboxItem {
// 	elementType: ElementType;
// 	orientation: Orientation;
// }

export interface Statics {
	maxWidth: number;
	minWidth: number;
	weight: number;
	paddingLeft: number;
	paddingRight: number;
	paddingTop: number;
	paddingBottom: number;
}

export interface ToolboxItemGlass extends ToolboxItem {
	statics: Statics;
}

export const ToolboxTypeElement = new Map<ToolboxItemType, ElementType>([
	[ToolboxItemType.GlassWall, ElementType.GlassWall],
	[ToolboxItemType.GlassWallDiamond, ElementType.GlassWallDiamond],
	[ToolboxItemType.Door, ElementType.Door],
	[ToolboxItemType.Wall, ElementType.Wall]
]);
