import { SvgParams } from "../../constants/constants";
import { ElementSide } from "../../interfaces/IElementSide";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { RoofVentilator } from "../glasses/roofVentilator.model";
import { RoofWindowConnector } from "../glasses/roofWindowConnector.model";
import { ElementType } from "../ToolboxModel";
import { ISize } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";

export class VentilatorStoreModel extends ElementStoreModel {

	rectOnRoof: IRect;
	size: ISize;

	constructor(c: RoofVentilator) {
		super(c.id, c.configId);

		this.rectOnRoof = c.rect;
		this.size = { width: Math.round(c.rect.w / SvgParams.SCALE), depth: Math.round(c.rect.h / SvgParams.SCALE), height: undefined };
	}

	public static restore(c: VentilatorStoreModel, projectService: ProjectService) {
		const info = projectService.template.roofAddons.find(e => e.id === c.configId);
		var nrw = new RoofVentilator(c.rectOnRoof.x, c.rectOnRoof.y, c.rectOnRoof.w, info, projectService.template.connectorDef);
		nrw.id = c.id;
		nrw.rect = c.rectOnRoof;

		nrw.connectorBottom = new RoofWindowConnector(nrw, ElementSide.Bottom);
		nrw.connectorBottom.visible = true;

		projectService.profileService.roofElements[ElementType.RoofVentilator].push(nrw);

		return nrw;
	}

}
