import { v4 as uuidv4 } from 'uuid';
import { Common } from '../common';
import { ElementType } from '../ToolboxModel';
import { AreaType } from '../../interfaces/IAreaType';
import { GlassParameter, GlassWallParameter } from '../wizard/wizard-parameter.model';
import { GlassPart } from './glassPart.model';
import { Profile } from '../profiles/profile.model';
import { VerticalElement } from "../vertical-element";
import { SvgParams } from '../../constants/constants';
import { ProjectService } from '../../services/project.service';
import { IAreaService } from '../../interfaces/IAreaService';
import { GlassWallBase } from './glassWall-base.model';

export class GlassWall extends GlassWallBase {
	get topY(): number {
		return this.rect.y;
	}
	get bottomY(): number {
		return this.rect.y + this.rect.h;
	}
	get leftX(): number {
		return this.rect.x;
	}
	get rightX(): number {
		return this.rect.x + this.rect.w;
	}
	get type() {
		return ElementType.GlassWall;
	}
	get name() {
		return $localize`:Common|Glass wall:Glass wall`;
	}
	get cuttingable(): boolean {
		return this.parts.length > 0; // Cuttingable only "main" wall
	}

	get frames(): Profile[] {
		return VerticalElement.getFrames(this.area, this.leftFrame, this.rightFrame, this.topFrame, this.bottomFrame);
	}
	get muntinsRequired() {
		return this.width * this.height > this.maxM2;
	}

	get minHeight() {
		return this.def.minHeight;
	}	

	public parts: GlassPart[];

	public get height() {
		return Common.round(this.rect.h / SvgParams.SCALE);
	}

	public set height(value: number) {
		this.rect.h = Common.round(value * SvgParams.SCALE);
	}

	constructor(
		protected projectService: ProjectService,
		sideService: IAreaService,
		def: GlassWallParameter,
		public glassDef: GlassParameter,
		x: number,
		y: number,
		w: number,
		h: number,
		areaType: AreaType,
		hanging: boolean = false
	) {
		// super(template, glassDef, x, y, w, h, 0);
		super(projectService, sideService, hanging);

		this.id = "GlassWall_" + uuidv4();
		this.rect = {
			x: Common.round(x),
			y: Common.round(y),
			w: Common.round(w),
			h: Common.round(h)
		};
		this.parts = [];
		this.muntins = [];
		this.area = areaType;
		this.width = Common.round(this.rect.w / SvgParams.SCALE);
		this.height = Common.round(this.rect.h / SvgParams.SCALE);
		this.def = def;
	}
}
