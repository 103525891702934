<div class="row">
	<div class="col col-4"><span i18n="LEDs|Pattern">Pattern</span>:</div>
	<div class="col col-8">
		<button kendoButton (click)="showPatterns = true" [disabled]="projectService.readOnly" i18n="Common|Change" [svgIcon]="projectService.svgIcons.pencilIcon">Change</button>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox [value]="locationX" [disabled]="true"></app-numeric-textbox>&nbsp;mm&nbsp;
		<div kendoTooltip>
			<span [attr.title]="changeLocationXTooltip('Left')">
				<button kendoButton (click)="onLocationXBtnClick('Left')" [disabled]="projectService.readOnly || isChangeLocationXBtnDisable('Left')" [svgIcon]="projectService.svgIcons.rewindIcon"></button>
			</span>
				<span [attr.title]="changeLocationXTooltip('Right')">
				<button kendoButton (click)="onLocationXBtnClick('Right')" [disabled]="projectService.readOnly || isChangeLocationXBtnDisable('Right')" [svgIcon]="projectService.svgIcons.forwardIcon"></button>
			</span>
		</div>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Distance top">Distance top</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numTop [value]="distanceTop" [min]="current.circle.r" [max]="maxDistance" [disabled]="projectService.readOnly"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Distance bottom">Distance bottom</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numBot [value]="distanceBottom" [min]="current.circle.r" [max]="maxDistance" [disabled]="projectService.readOnly"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
		<div class="col col-4"><span i18n="Common|Delete all">Delete all</span></div>
		<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteLed()" [disabled]="projectService.readOnly" i18n="Common|Delete">Delete</button>
		</div>
</div>

<app-led-templates-dialog [isDialogOpened]="showPatterns" #patternDialog
	(selected)="ledPatternChange($event)"></app-led-templates-dialog>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Location Y:</div>
		<div class="col col-8">{{ locationY }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Distance top:</div>
		<div class="col col-8">{{ distanceTop }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Distance bottom:</div>
		<div class="col col-8">{{ distanceBottom }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max distance:</div>
		<div class="col col-8">{{ maxDistance }} mm</div>
	</div>
</div>