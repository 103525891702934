import { Injectable } from '@angular/core';
import { IPoint } from 'src/app/_core/interfaces/IPoint';
import { ElementType, ToolboxItem, Orientation, ToolboxItemType } from 'src/app/_core/models/ToolboxModel';
import { ProjectService } from '../project.service';
import { Glass } from '../../models/glasses/glass.model';
import { ProfileService } from '../profile.service';
import { AreaType, CollectionName, RectName } from 'src/app/_core/interfaces/IAreaType';
import { GUID_DOOR_SINGLE, SvgParams } from '../../constants/constants';
import { Profile } from '../../models/profiles/profile.model';
import { Common } from '../../models/common';
import { MarquiseVertical } from '../../models/marquises/marquise-vertical.model';
import { MarquiseFront } from '../../models/marquises/marquise-front.model';
import { IAreaCanvas, IAreaService } from '../../interfaces/IAreaService';
import { Montage } from '../../interfaces/IElementSide';
import { GlassWall } from '../../models/glasses/glassWall.model';
import { DoorCreator } from '../../models/doors/door-creator';
import { DoorType } from '../../interfaces/IDoorInfo';
import { Door } from '../../models/doors/door.model';
import { Subject } from 'rxjs';
import { FrameProfile } from '../../models/profiles/frame.model';
import { IPointer } from '../../interfaces/IPointer';
import { GlassWallBase } from '../../models/glasses/glassWall-base.model';
import { ColumnProfile } from '../../models/profiles/column.model';
import { MarquiseRear } from '../../models/marquises/marquise-rear.model';

@Injectable({
  providedIn: 'root'
})
export abstract class StraightService implements IAreaCanvas, IAreaService {

	public abstract calculatePoints();
	public abstract get rectOnSide(): string
	public abstract get areaType(): AreaType;
	public abstract showArea();
	public abstract changeArea();
	public abstract getTopProfileBottomY(): number;
	protected abstract createTopProfile(): FrameProfile;

  	public glasses: Glass[] = [];
	public profiles: any[] = [];
	protected _points: IPoint[] = [];
	protected _workAreaPoints: IPoint[] = [];
    protected _svg: string;

	protected get width() {
		return this.projectService.template.width;
	}
	protected get depth() {
		return this.projectService.template.depth;
	}
	public doorCreation = new Subject<Door>();

	constructor(protected profileService: ProfileService,
		protected projectService: ProjectService
	) {}
	
	getGWDStartPoints(currentAreaElements?: any): IPoint[] {
		throw new Error('Method not implemented.');
	}

	manageFrameAfterGlassChange(glass: GlassWallBase) {
		throw new Error('Method not implemented.');
	}
	createMuntins(glass: GlassWallBase, muntinsCount: number) {
		throw new Error('Method not implemented.');
	}

	protected saveProfileWithSetRect(element: Profile, x: number, y: number): void {

		element[this.rectOnSide] = {
			x: x,
			y: y,
			w: Common.round(element.getLength() * SvgParams.SCALE),
			h: Common.round(element.height * SvgParams.SCALE),
		};
		const coll = CollectionName.get(this.areaType);
		if (this.profileService[coll][element.type].find(f => f.id == element.id) == null) {
			this.profileService[coll][element.type].push(element);
		}
	}

	protected saveColWithSetRect(col: ColumnProfile, x: number, y: number): void {
		col[this.rectOnSide] = {
			x: Common.round(x),
			y: Common.round(y),
			w: Common.round(col.width * SvgParams.SCALE),
			h: Common.round(Math.round(col.getLength()) * SvgParams.SCALE)
		};
		const coll = CollectionName.get(this.areaType);
		if (!this.profileService[coll][ElementType.Column].find(c => c.id == col.id)) {
			this.profileService[coll][ElementType.Column].push(col);
		}
	}

	getSvg(): string {

		if (this._svg != "") {
			return this._svg;
		}
		this._svg = ProjectService.getPath(this._points);

		return this._svg;
	}

	getShapeAreaPoints() {
		return this._points;
	}

	getWorkAreaPoints() {
		return this._workAreaPoints;
	}

	onDrop(ptr: IPointer, currentTool: ToolboxItem) {
		switch (currentTool.type) {
			case ToolboxItemType.MarquiseVertical:
				this.createMarquise(ptr, currentTool);
				break;
			case ToolboxItemType.Door:
				this.createDoor(ptr, currentTool);
				break;
			case ToolboxItemType.Wall:
				this.createWall(ptr, currentTool);
				break;
		}
	}

	public createMarquise(ptr: IPointer, currentTool: ToolboxItem) {
		// Markizy z tyłu decyzją Szczepana są nieobosługiwane.
		// Samo odblokowanie jest niewystarczające, konieczne jest ustawienie handlerów na rearze.
		if (this.areaType == AreaType.Rear) {
			return;
		}
		
		const cols = this.projectService.getSpace(ptr);
		var mrq = this.projectService.profileService.checkVerticalExists(ElementType.MarquiseVertical, this.areaType, cols) as MarquiseVertical;
		if (mrq != null && mrq.montage == Montage.Middle) {
			return;
		}

		const info = this.projectService.template.marquises.find(m => m.id == currentTool.id);
		const message = MarquiseVertical.validate(info, cols.leftProfile, cols.rightProfile, this.areaType, null, this.profileService);
		if (message != "") {
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });		
			return;
		}

		var mq: MarquiseVertical;
		if (this.areaType == AreaType.Front) {
			mq = new MarquiseFront(this.projectService, info, cols.leftProfile, cols.rightProfile, null, this.areaType, this.getWorkAreaPoints());
		} else {
			mq = new MarquiseRear(this.projectService, info, cols.leftProfile, cols.rightProfile, null, this.areaType, this.getWorkAreaPoints());
		}
		if (mrq != null) {
			if (mrq.montage == Montage.Inside) {
				mq.montage = Montage.Outside;
			} else {
				mq.montage = Montage.Inside;
			}
		} else  {
			if (!mq.isMontageAllowed(mq.montage)) {
				if (mq.isMontageAllowed(Montage.Middle)) {
					mq.montage = Montage.Middle;
				} else {
					mq.montage = Montage.Outside;
				}
			}
		}
		mq.setHandler(this.projectService.template.frontId);
		this.projectService.profileService.addVertical(mq);
		this.projectService.calculateRemote();

		this.projectService.adjustBottomMarquise(mq);
		this.projectService.freezeProjProp();
		this.projectService.emitChange();
	}

	public createDoor(ptr: IPointer, currentTool: ToolboxItem) {
		const tempConnector = this.projectService.template.doors[0].connectorId;
		var cols = this.projectService.getSpace(ptr);
		
		if (!cols.topProfile) {
			cols.topProfile = this.createTopProfile();
		}
		if (!cols.bottomProfile) {
			var by = this.getWorkAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
			cols.bottomProfile = new FrameProfile(0, by, 0, { width: 0, height: 0, depth: 0 }, "", Orientation.Horizontal, tempConnector);
		}

		if (this.areaType != AreaType.Rear) {
			cols = this.projectService.tuneVerticalToFront(this.areaType, cols, cols.getTopY(this.areaType));
		}

		if (this.projectService.profileService.checkVerticalExists(ElementType.Door, this.areaType, cols) != null) {
			return;
		}

		var dtype = currentTool.id == GUID_DOOR_SINGLE ? DoorType.SingleSide : DoorType.DoubleSide;
		var creator = new DoorCreator(this.projectService, cols, this.areaType, this);

		var y = cols.topProfile.rect.y;

		var sel = this.projectService.calculateDoorVariant(creator, dtype, y);

		if (sel) {
			var d = creator.createDoor(sel, null, y);
			this.doorCreation.next(d);
		}
	}

	public createWall(ptr: IPointer, currentTool: ToolboxItem) {
		const cols = this.projectService.getSpace(ptr);

		const gw = this.projectService.profileService.frontElements[ElementType.GlassWall].find((g: GlassWall) => 
			g.rectOnFront.x >= cols.leftProfile.rectOnFront.x + cols.leftProfile.rectOnFront.w && g.rectOnFront.x + g.rectOnFront.w <= cols.rightProfile.rectOnFront.x
		)
		if (gw && gw.width < Math.round(gw.maxWidth)) {
			this.projectService.showTemporaryMessageSubj.next({ message: $localize`:Common|Message:Not supported`, hideAfter: 2000, style: "error" });
			return;
		}

		this.projectService.createWall(this, cols, currentTool);
	}

}
