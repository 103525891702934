import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { Tags, SvgParams } from "src/app/_core/constants/constants";
import { AreaType, RectName } from "src/app/_core/interfaces/IAreaType";
import { ProjectTemplate } from "../../template";
import { IRect } from "src/app/_core/interfaces/IRect";
import { Common } from "src/app/_core/models/common";
import { FrontProfile } from "./front.model";
import { WallProfile } from "./wall.model";
import { AnchorType, IMarker } from "../../interfaces/IMarker";
import { ElementSide } from "../../interfaces/IElementSide";
import { ColumnParameter, WizardParameter } from "../wizard/wizard-parameter.model";
import { LayerLevel } from "../../interfaces/LayerLevel";

interface IColumnFoundation {
  rect: IRect;
  strokeWidth: number;
	stroke: string;
}
export class ColumnProfile extends Profile {

  public outflow: ElementSide = ElementSide.None;
  public connector: boolean = false;
  public leftProfile: FrontProfile | WallProfile;
  public rightProfile: FrontProfile | WallProfile;

  public foundation: WizardParameter;
  private extras: string[];
  private _maxHeight: number;
  private _minHeight: number;
  private _weight: number;

  get type() {
    return ElementType.Column
  }

  get name(): string {
    return $localize`:Common|Column:Column`;
  }

  get layer(): LayerLevel {
    return LayerLevel.Construction;
  }

  get deletable() {
    return !(this.tag == Tags.LEFT_COLUMN_TAG || this.tag == Tags.RIGHT_COLUMN_TAG || this.frozen || this.connector);
  }

  get maxHeight(){
    return Common.round(this._maxHeight);
  }

  get minHeight(){
    return Common.round(this._minHeight);
  }

  public get weight(){
    return this._weight;
  }

  private _isRear: boolean;
  public set isRear (value: boolean) {
    this._isRear = value;
  }
  public get isRear() {
    return this._isRear;
  }

  private _isFront: boolean;
  public set isFront(value: boolean) {
    this._isFront = value;
  }
  public get isFront() {
    return this._isFront;
  }

  private get rectName() {
    return this.isRear ? RectName.get(AreaType.Rear) : RectName.get(AreaType.Front);
  }

  public get roofMarker() : IRect {
    if (!this.connector) {
      return null;
    }
    return { x: this.rectOnRoof.x + this.rectOnRoof.w / 2 - 1, y: this.rectOnRoof.y, h: this.rectOnRoof.h, w: 2 };
  }

  public get frontMarker(): IRect {
    if (!this.connector) {
      return null;
    }
    const rn = this.isRear ? RectName.get(AreaType.Rear) : RectName.get(AreaType.Front);
    return { x: this[rn].x + this[rn].w / 2 - 1, y: this[rn].y - this[rn].w, h: this[rn].w, w: 2 };
  }

  public get marker(): IMarker {
    let a: AnchorType;
    let x: number;
    var y: number;
    
    if (this.isRear) {
      y = this.rectOnRoof.y - 10;
    } else {
      y = this.frontY + 20;
    }

    if (this.tag == Tags.LEFT_COLUMN_TAG || this.tag == Tags.LEFT_REAR_COLUMN_TAG) {
      a = AnchorType.start;
      x = this.rectOnRoof.x;
    } else if (this.tag == Tags.RIGHT_COLUMN_TAG || this.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
      a = AnchorType.end;
      x = this.rectOnRoof.x + this.rectOnRoof.w;
    } else {
      a = AnchorType.middle;
      x = this.rectOnRoof.x + this.rectOnRoof.w / 2;
    }

    return {
      text: "S",
      anchor: a,
      loc: { x, y }
    };

  }

  private frontY: number;
  public assignFront(profile: Profile) {
    if (profile.type == ElementType.Front) {
      const front = profile as FrontProfile;
      this.frontY = front.gutter ? front.gutter.rectOnRoof.y + front.gutter.rectOnRoof.h : front.rectOnRoof.y + front.rectOnRoof.h;
    }
  }

  public outflowId: string;
  public outflowCoverId: string;
  public get canHaveOutflow(): boolean {
    return this.isFront && this.outflowId != null && this.outflowId != '';
  }

  public get outflowCover(): boolean {
    return this.extras.find(e => e == this.outflowCoverId) != null;
  }

  public get isCorner() {
    return this.isCornerLeft || this.isCornerRight;
  }

  public get isCornerLeft() {
    return this.tag == Tags.LEFT_COLUMN_TAG || this.tag == Tags.LEFT_REAR_COLUMN_TAG;
  }

  public get isCornerRight() {
    return this.tag == Tags.RIGHT_COLUMN_TAG || this.tag == Tags.RIGHT_REAR_COLUMN_TAG;
  }

  public isWinkel: boolean;

  public getWinkelPath(area: string) {
    const side = RectName.get(AreaType[area]);

    const x1: number = this.tag == Tags.LEFT_COLUMN_TAG ? this[side].x : this[side].x + this[side].w;
    const y1: number = this[side].y;
    const x2: number = this.tag == Tags.LEFT_COLUMN_TAG ? this[side].x + this[side].w : this[side].x;
    const y2: number = this[side].y + this[side].h;

    var path = "M";
    path += x1 + "," + y1 + " L";
    path += x1 + "," + y2 + " L";
    path += x2 + "," + y1 + " L";
    path += x1 + "," + y1 + " Z";
        
    return path;
  }

  constructor(
    currentArea: AreaType,
    x: number,
    y: number,
    length: number,
    area: AreaType,
    configId: string,
    private template: ProjectTemplate) {

    var col = template.columns.find(c => c.id == configId);
    
    super(Orientation.Vertical, col.size, col.color);

    this._weight = col.weight;    
    this.outflowId = col.outflowId;
    const outfExtra = template.extras.find(e => e.id == col.outflowId);
    if (outfExtra?.extras?.length > 0) {
      this.outflowCoverId = outfExtra.extras[0].extraId;
    }

    this.id = this.type + "_" + uuidv4();
    this.configId = configId;

    this.width = col.size.width;
    this.depth = col.size.depth;
    this.extras = [];
    this.length = Common.round(length);
    this._maxHeight = col.maxHeight;
    this._minHeight = col.minHeight;

    if (area === AreaType.None) {
			area = currentArea;
		}

    switch(area) {
      case AreaType.Roof:
        this.rectOnRoof = {
          x: Common.round(x),
          y: Common.round(y),
          w: Common.round(this.width * SvgParams.SCALE),
          h: Common.round(this.depth * SvgParams.SCALE),
        };
        break;
      case AreaType.Front:
        this.rectOnFront = {
          x: Common.round(x),
          y: Common.round(y),
          w: Common.round(this.width * SvgParams.SCALE),
          h: Common.round(this.getLength() * SvgParams.SCALE)
        };
        break;
      case AreaType.Left:
        this.rectOnLeft = {
          x: Common.round(x),
          y: Common.round(y),
          w: Common.round(this.depth * SvgParams.SCALE),
          h: Common.round(this.getLength() * SvgParams.SCALE)
        };
        break;
      case AreaType.Right:
        this.rectOnRight = {
          x: Common.round(x),
          y: Common.round(y),
          w: Common.round(this.depth * SvgParams.SCALE),
          h: Common.round(this.getLength() * SvgParams.SCALE)
        };
        break;
      case AreaType.Rear:
          this.rectOnRear = {
            x: Common.round(x),
            y: Common.round(y),
            w: Common.round(this.width * SvgParams.SCALE),
            h: Common.round(this.getLength() * SvgParams.SCALE)
          };
          break;
      }
  }

  removeExtra(id: string) {
    this.extras = this.extras.filter(e => e != id);
    if (this.hydro && this.hydro.id == id) {
      this.hydro = null;
    }
  }
  addExtra(id: string) {
    this.extras.push(id);
    this.hydro = this.template.hydros.find(h => h.id == id);
  }
  clearExtras() {
    this.extras = [];
    this.hydro = null;
  }
  getExtras() {
    return this.extras;
  }

  getHolderLeft() {
    const rn = this.rectName;
    if (this.foundation?.size == null || this[rn]) {
      return 0;
    }
    const r: IRect = this[rn];
    const w = this.foundation.size.width * SvgParams.SCALE;
    const middle = r.x + (r.w / 2);
    const left = (middle - w / 2);
    return left;
  }

  getHolderRight() {
    const rn = this.rectName;
    if (this.foundation?.size == null || this[rn]) {
      return 0;
    }
    const w = this.foundation.size.width * SvgParams.SCALE;
    return this.getHolderLeft() + w;
  }

  getHolderHeight() {
    const rn = this.rectName;
    if (this.foundation?.size == null || this[rn]) {
      return 0;
    }
    return this.foundation.size.height * SvgParams.SCALE;
  }

  getHolderWidth() {
    const rn = this.rectName;
    if (this.foundation?.size == null || this[rn]) {
      return 0;
    }
    return this.foundation.size.width * SvgParams.SCALE;
  }


  private getNegativeHolder(rect: string): IColumnFoundation[] {
    var holders: IColumnFoundation[] = [];

    const h = this.foundation.size.height * SvgParams.SCALE;
    const length = this.getLength() * SvgParams.SCALE;
    var top = this[rect].y + this[rect].h - h;
    var hh = 0;
    if (this.hydro) {
      hh = this.hydro.size.height * SvgParams.SCALE;
    }
    
    holders.push({
      rect: { x: this[rect].x - 1.5, y: top, w: this[rect].w + 3, h: h },
      strokeWidth: 3,
      stroke: this.foundation.color
    });

    if (this.hydro) {
      const ph = 4;
      const pw = this[rect].w + 12;
      const middle = this[rect].x + (this[rect].w / 2);
      top = this[rect].y + length - ph;

      holders.push({
        rect: { x: middle - 2, y: this[rect].y + this[rect].h, w: 4, h: hh - ph },
        strokeWidth: 0.5,
        stroke: "gray"
      });

      holders.push({
        rect: { x: middle - pw / 2, y: top + hh, w: pw, h: ph },
        strokeWidth: 0.5,
        stroke: "gray"
      });
    }

    return holders;

    // const h = this.foundation.size.height * SvgParams.SCALE;
    // var top = r.y + r.h + h;
    // const rect = { x: r.x - 1, y: top, w: r.w + 1, h: -h };
    // return {
    //     rect: rect,
    //     strokeWidth: 2,
    //     stroke: this.foundation.color
    // };

  }

  public hydro: WizardParameter;


  getHolder(area: AreaType, columns: ColumnProfile[] = []): IColumnFoundation[] {

    if (!this.foundation?.size) {
      return null;
    }

    var sr = RectName.get(area);
    var r: IRect = this[sr];

    let rc: ColumnProfile;
    let lc: ColumnProfile;
    switch (area) {
      case AreaType.Front:
      case AreaType.Rear:
        if (columns.length > 0) {
          var curridx = columns.findIndex(c => c.id == this.id);
          if (curridx < columns.length - 1) {
            rc = columns[curridx + 1];
          }
          if (curridx > 0) {
            lc = columns[curridx - 1];
          }
        }
        break;
    }

    if (!r) {
      return null;
    }

    if (this.foundationOnConcrete()) {
      return this.getNegativeHolder(sr);
    }

    var holders: IColumnFoundation[] = [];
    var h = this.foundation.size.height * SvgParams.SCALE;
    var w = this.foundation.size.width * SvgParams.SCALE;
    const middle = r.x + (r.w / 2);
    var left = (middle - w / 2);
    const top = r.y + r.h;

    const ph = 4;
    const pw = r.w + 12;

    //#region "multi foundation"
    var isMulti = false;
    var isEmpty = false;
    if (lc && lc.getHolderRight() >= left) {
      isEmpty = true;
    }

    if (rc && area == (AreaType.Front || area == AreaType.Rear)) {
      var rcl = rc.getHolderLeft();
      if (rcl > 0 && rcl < left + w) {
        isMulti = true;
      }
    }
    //#endregion

    if (!isEmpty) {

      if (isMulti) {

        h = Math.max(h, rc.getHolderHeight());
        const rn = this.rectName;
        const rcMiddle = rc[rn].x + (rc[rn].w / 2);
        const rcRight = rcMiddle + (w / 2);
        w = rcRight - left;
      }
      holders.push({
        rect: { x: left, y: top, w: w, h: h },
        strokeWidth: 1.5,
        stroke: "lightgray"
      });
    }

    holders.push({
      rect: { x: r.x + 1, y: top, w: r.w - 2, h: h - ph},
      strokeWidth: 0.5,
      stroke: "gray"
    });

    holders.push({
      rect: { x: middle - pw / 2, y: top + h - ph, w: pw, h: ph },
      strokeWidth: 0.5,
      stroke: "gray"
    });

    return holders;

  }

  foundationOnConcrete(){
    return this.foundation.size.depth < 0 || this.foundation.size.width < 0;
  }

  getHeight() {
    let h = this.getLength();
    if (this.foundation) {
      if (!this.foundationOnConcrete()) {
        h += this.foundation.size.height;
      }
    }
    return Common.round(h);
  }

  setHeight(height: number){
    if(!this.foundationOnConcrete()) {
      height -= this.foundation.size.height;
    }
    this.setLength(height);

    RectName.forEach((k, v) => {
      if (this[k] && v != AreaType.Roof) {
        this[k].h = height * SvgParams.SCALE;
      }
    });
  }

  changeType(coldef: ColumnParameter) {
		this.width = coldef.size.width;
		this.outflowId = coldef.outflowId;
		this._weight = coldef.weight;

  }

}
