import { IElement } from '../interfaces/IElement';
import { LayerLevel } from '../interfaces/LayerLevel';
import {ElementType} from './ToolboxModel';
import {v4 as uuidv4} from 'uuid';
import { WizardParameter } from './wizard/wizard-parameter.model';

export class ExtraOption implements IElement {

	public get type() {
		return ElementType.ExtraOption;
	}

	public id: string;
	public configId: string;
	public name: string;
	public thumbnail: string;
	public isRequired: boolean;		
	public minQuantity: number;
	public maxQuantity: number;
	public quantity: number;
	public channels: number;

	private _profiles: string[] = [];
	private _freeHand: boolean;
	public get freeHand() {
		return this._freeHand;
	}

	constructor(item: WizardParameter, quantity: number = 1 ) {
		
		this.id = `${this.type}_${uuidv4()}`;
		this._freeHand = true;
		this.configId = item.id;
		this.name = item.name;
		this.thumbnail = item.thumbnail;
		this.isRequired = item.isRequired;
		this.minQuantity = item.minQuantity;
		this.maxQuantity = item.maxQuantity;
		this.channels = item.channels;
		this.quantity = quantity;
	}
	get layer(): LayerLevel {
		return LayerLevel.Construction;
	}

	public bindProfile(id: string) {
		this._profiles.push(id);
		this._freeHand = false;
	}

	public unbindProfile(id: string) {
		this._profiles = this._profiles.filter(p => p != id);
	}

	public bindedTo(id: string) {
		return this._profiles.find(p => p == id);
	}

	public get profilesCount() {
		return this._profiles.length;
	}

}
