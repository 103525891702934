export enum CardType {
	None = "None",
	Glass = "Glass",
	GlassWall = "GlassWall",
	Profile = "Profile",
	Column = "Column",
	Marquise = "Marquise",
	MarquiseVertical = "MarquiseVertical",
	Led = "Led",
	LedStripe = "LedStripe",
	ExtraOption = "ExtraOption",
	Front = "Front",
	Door = "Door",
	Wall = "Wall"
}

export interface IPropertyCard {
	type: CardType;
	items: any[];
}
