import { Component, OnInit } from '@angular/core';
import { IApiError, ICfgError } from 'src/app/_core/interfaces/IErrors';
import { NotifyService } from 'src/app/_core/services/notify.service';
import { ProjectService } from 'src/app/_core/services/project.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  apiError: IApiError;
  cfgError: ICfgError;
  showDetails: boolean = true;

  constructor(
    private notif: NotifyService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.notif.apiException.subscribe((error: IApiError) => {
      this.apiError = error;

      if (error.httpCode == 404 && error.source == "CRM") {
        this.showDetails = false;
        this.apiError.message = "Error in communication with CRM (404)";
      }
    });

    this.notif.cfgException.subscribe((error: ICfgError) => {
      this.cfgError = error;
    });
  }

  cfgErrorClose() {
    if (this.cfgError.clear) {
      this.projectService.clearAll();
    }
    this.cfgError = null;

  }

}
