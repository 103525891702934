<!-- Main side shape -->
<svg:path [attr.d]="areaService.getSvg()" stroke="#282a30" stroke-width="0.2" fill="#d9ecf5" fill-opacity="0.4"
	stroke-dasharray="4,4" [ngClass]="iconClass('Shape', areaService.areaType)"
	(click)="onSideDrop($event); currentTool = null" *ngIf="!printMode">
</svg:path>

<!-- Vertical marquises -->
<svg:g app-marquise-vert *ngIf="!printMode || printElements" [elements]="projectService.profileService.roofElements"
	[areaService]="areaService" [selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)"
	[montage]="'Inside'" [printMode]="printMode">
</svg:g>

<!-- Doors -->
<svg:g app-door *ngIf="!printMode || printElements" [elements]="projectService.profileService.roofElements"
	[area]="areaService.areaType" [selectedItems]="selectedItems" [printMode]="printMode" (selectDoorEvent)="selectDoor($event)">
</svg:g>

<svg:g *ngIf="!printMode || printElements">
	<!-- Walls -->
	<svg:g app-wall [elements]="projectService.profileService.roofElements" [area]="areaService.areaType"
		[selectedItems]="selectedItems" (selectWallEvent)="selectWall($event)"
		(selectWallMuntinEvent)="selectWallMuntin($event)" [printMode]="printMode">
	</svg:g>

	<!-- All for Glasses-Wall -->
	<svg:g app-glass-wall [elements]="elements" [areaType]="areaService.areaType" [selectedItems]="selectedItems"
		[printMode]="printMode" (selectGlassWallEvent)="selectGlassWall($event)"
		(selectProfileEvent)="selectProfile($event)">
	</svg:g>

</svg:g>

<!-- Profiles: SideFinish -->
<svg:rect *ngFor="let l of elements['SideFinish']"
	[attr.x]="l[areaService.rectOnSide].x" [attr.y]="l[areaService.rectOnSide].y"
	[attr.width]="l[areaService.rectOnSide].w" [attr.height]="l[areaService.rectOnSide].h"
	[attr.transform]="'rotate(' + l.rotation + ', ' + l.anchor.x + ',' + l.anchor.y + ')'"
	class="profile"
	[attr.fill]="getBackColor(l.color)" [attr.stroke]="projectService.frameColor"
	[ngClass]="[iconClass('SideFinish', areaService.areaType), selectedItems.indexOf(l) >= 0 ? 'highlight' : '']"
	(click)="selectProfile(l, $event)"
	[attr.id]="areaService.areaType + '_' + l.id">
</svg:rect>

<!-- Profiles: WallProfile -->
<svg:rect *ngFor="let l of elements['WallProfile']"
	[attr.x]="l[areaService.rectOnSide].x" [attr.y]="l[areaService.rectOnSide].y"
	[attr.width]="l[areaService.rectOnSide].w" [attr.height]="l[areaService.rectOnSide].h"
	[ngClass]="[iconClass('WallProfile', areaService.areaType), selectedItems.indexOf(l) >= 0 ? 'highlight' : '']" [attr.fill]="getBackColor(l.color)"
	(click)="selectProfile(l, $event)"
	class="profile" [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Front -->
<svg:rect *ngFor="let l of elements['Front']" [attr.x]="l[areaService.rectOnSide].x" [attr.y]="l[areaService.rectOnSide].y"
	[attr.width]="l[areaService.rectOnSide].w" [attr.height]="l[areaService.rectOnSide].h"
	[ngClass]="[iconClass('Front', areaService.areaType), selectedItems.indexOf(l) >= 0 ? 'highlight' : '']" [attr.fill]="getBackColor(l.color)"
	(click)="selectProfile(l, $event)"
	class="profile" [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Gutter -->
<svg:rect *ngFor="let l of elements['Gutter']" [attr.x]="l[areaService.rectOnSide].x" [attr.y]="l[areaService.rectOnSide].y"
	[attr.width]="l[areaService.rectOnSide].w" [attr.height]="l[areaService.rectOnSide].h"
	[ngClass]="[iconClass('Front', areaService.areaType), selectedItems.indexOf(l) >= 0 ? 'highlight' : '']" [attr.fill]="getBackColor(l.color)"
	(click)="selectProfile(l, $event)"
	class="profile" [attr.stroke]="projectService.frameColor">
</svg:rect>

<!-- Profiles: Column -->
<svg:g *ngFor="let l of elements['Column']">
	<!-- Profiles: Column holders -->
	<svg:rect *ngFor="let h of l.getHolder(areaService.areaType)" [attr.x]="h.rect.x" [attr.y]="h.rect.y"
		[attr.width]="h.rect.w" [attr.height]="h.rect.h" [attr.stroke]="h.stroke" [attr.stroke-width]="h.strokeWidth"
		fill="none">
	</svg:rect>
	<svg:rect [attr.x]="l[areaService.rectOnSide].x" [attr.y]="l[areaService.rectOnSide].y"
		[attr.width]="l[areaService.rectOnSide].w" [attr.height]="l[areaService.rectOnSide].h" class="profile"
		[ngClass]="[iconClass('Column', areaService.areaType), selectedItems.indexOf(l) >= 0 ? 'highlight' : '', l.locked ? 'locked' : '']"
		(click)="selectProfile(l, $event)" [attr.fill]="getBackColor(l.color)" [attr.stroke]="projectService.frameColor">
	</svg:rect>
</svg:g>

<!-- Marquise-top-up -->
<svg:g *ngIf="!printMode">
	<svg:rect *ngFor="let m of elements['MarquiseTopUp']" [attr.x]="m[areaService.rectOnSide].x"
		[attr.y]="m[areaService.rectOnSide].y" [attr.width]="m[areaService.rectOnSide].w"
		[attr.height]="m[areaService.rectOnSide].h"
		[attr.transform]="'rotate(' + m[areaService.rotationOnSide] + ', ' + m[areaService.anchorOnSide].x + ',' + m[areaService.anchorOnSide].y + ')'"
		[attr.fill]="getMarquiseBackColor(m)" [attr.stroke]="projectService.frameColor"
		[ngClass]="{'marquise top-up': true, 'highlight': selectedItems.indexOf(m) >= 0}" (click)="selectMarquise(m)">
	</svg:rect>

	<!-- Marquise-top-bottom -->
	<svg:rect *ngFor="let m of elements['MarquiseTopBottom']" [attr.x]="m[areaService.rectOnSide].x"
		[attr.y]="m[areaService.rectOnSide].y" [attr.width]="m[areaService.rectOnSide].w"
		[attr.height]="m[areaService.rectOnSide].h"
		[attr.transform]="'rotate(' + m[areaService.rotationOnSide] + ', ' + m[areaService.anchorOnSide].x + ',' + m[areaService.anchorOnSide].y + ')'"
		[attr.fill]="getMarquiseBackColor(m)" [attr.stroke]="projectService.frameColor"
		[ngClass]="{'marquise top-bottom': true, 'highlight': selectedItems.indexOf(m) >= 0}" (click)="selectMarquise(m)">
	</svg:rect>
</svg:g>

<svg:g *ngIf="!printMode || printElements">

	<!-- Vertical marquises -->
	<svg:g app-marquise-vert [elements]="projectService.profileService.roofElements" [areaService]="areaService"
		[selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)" [montage]="'Middle'"
		[printMode]="printMode">
	</svg:g>

	<!-- Vertical marquises -->
	<svg:g app-marquise-vert [elements]="projectService.profileService.roofElements" [areaService]="areaService"
		[selectedItems]="selectedItems" (selectMarquiseEvent)="selectMarquise($event)" [montage]="'Outside'"
		[printMode]="printMode">
	</svg:g>

</svg:g>

<!-- Dimensions -->
<svg:g *ngFor="let d of elements['Dimension']">
	<g app-dimension [item]="d" [printMode]="printMode"></g>
</svg:g>

<svg:g app-customer-plate *ngIf="projectService.info != null && printMode && channel != 'CRM'" [area]="areaService.areaType"></svg:g>
