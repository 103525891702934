  <kendo-dialog *ngIf="dialogOpened"
    i18n-title="Dialogs|'Please confirm' title" title="Please confirm"
    (close)="action(false)"
    [minWidth]="250" [width]="450">

    <p style="margin: 30px; text-align: center;">
      {{ dialogMessage }}
    </p>
    
    <kendo-dialog-actions>
      <button kendoButton (click)="action(false)" i18n="Dialogs|'No' button">No</button>
      <button kendoButton (click)="action(true)" primary="true" i18n="Dialogs|'Yes' button">Yes</button>
    </kendo-dialog-actions>
  </kendo-dialog>

