import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import 'rxjs/add/observable/of';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenStorage } from './token.storage';

@Injectable({
	providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private tokenStorage: TokenStorage,
		@Inject('BASE_URL') private baseUrl: string
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return of(this.tokenStorage.token)
			.pipe(mergeMap(token => this.processRequestWithToken(token, req, next)))
			.pipe(tap(x => { }, error => {
				if (error instanceof HttpErrorResponse) {
					var httpError = error as HttpErrorResponse;
					if (httpError.status == 401) {
						this.authService.login();
					}
				}
			}));
	}

	// Checks if there is an access_token available in the authorize service
	// and adds it to the request in case it's targeted at the same origin as the
	// single page application.
	private processRequestWithToken(token: string | null, req: HttpRequest<any>, next: HttpHandler) {
		if (!!token && this.isSameOriginUrl(req)) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}

		return next.handle(req);
	}

	private isSameOriginUrl(req: any) {
		// It's an absolute url with the same origin.
		if (req.url.startsWith(`${window.location.origin}/`)) {
			return true;
		}

		// It's a protocol relative url with the same origin.
		// For example: //www.example.com/api/Products
		if (req.url.startsWith(`//${window.location.host}/`)) {
			return true;
		}

		// take into account also the baseUrl (primarly this is important for debugging)
		if (req.url.startsWith(this.baseUrl)) {
			return true;
		}

		// It's a relative url like /api/Products
		if (/^\/[^\/].*/.test(req.url)) {
			return true;
		}

		// It's an absolute or protocol relative url that
		// doesn't have the same origin.
		return false;
	}
}
