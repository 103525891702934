import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExtraOption } from '../../_core/models/extra-option.model';
import { ProjectService } from '../../_core/services/project.service';
import { ChosenExtraOptionService } from '../../_core/services/chosen-elements/chosen-extra-option.service';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { CommonPropertiesComponent } from 'src/app/_shared/common-properties/common-properties.component';
import { CrmService } from 'src/app/_core/services/crm.service';

@Component({
	selector: 'card-extra-option',
	templateUrl: './card-extra-option.component.html',
	styleUrls: ['../card.component.css']
})
export class CardExtraOptionComponent implements OnInit {
	@ViewChild('quantNum', { static: true }) quantNum: NumTbxComponent;
	@ViewChild('common', { static: false }) common: CommonPropertiesComponent;

	current: ExtraOption
	get showComment(){
		return this.crmService.isCrmPresent;
	}
	get showRemote(){
		return this.projectService.channelsTotal > 0 && this.projectService.channelsTotal != null;
	}

	@Input() set items(items: ExtraOption[]) {
		this.current = items[0];
		if (this.showComment) {
			setTimeout(() => {
				this.common.setItem(this.current);
			}, 10);
		}
	}
	get items() {
		return [this.current];
	}

	constructor(
		public projectService: ProjectService,
		private chosenExtraOptionService: ChosenExtraOptionService,
		private crmService: CrmService) { 
		}

	ngOnInit(): void {
		if (this.quantNum.valueChanged.observers.length == 0) {
			this.quantNum.valueChanged.subscribe(v => {
				this.current.quantity = v;
				this.projectService.emitChange();
			});
		}
	}

	deleteElement() {
		this.projectService.deleteElement(this.current);
		this.chosenExtraOptionService.setChosenOption(null);

	}

}
