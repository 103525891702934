import { ElementType, Orientation } from 'src/app/_core/models/ToolboxModel';
import { v4 as uuidv4 } from 'uuid';
import { MarquisesParams, SvgParams, Tags } from '../../constants/constants';
import { AreaType, CollectionName, RectName } from '../../interfaces/IAreaType';
import { ElementSide, Montage } from '../../interfaces/IElementSide';
import { IMarquise } from '../../interfaces/IMarquise';
import { IMarquiseHandler } from '../../interfaces/IMarquiseHandler.interface';
import { IMarquiseInfo } from '../../interfaces/IMarquiseInfo.interface';
import { IRect } from '../../interfaces/IRect';
import { ProfileService } from '../../services/profile.service';
import { ProjectService } from '../../services/project.service';
import { Color, ColorOfPalette } from '../colors/color.model';
import { FrameProfile } from '../profiles/frame.model';
import { Profile } from '../profiles/profile.model';
import { MarquisesEngine } from './marquises-engine.model';
import { ILine } from '../../interfaces/ILine.interface';
import { ColumnProfile } from '../profiles/column.model';
import { IPoint } from '../../interfaces/IPoint';
import { VerticalElement } from "../vertical-element";
import { LayerLevel } from '../../interfaces/LayerLevel';

export abstract class MarquiseVertical extends VerticalElement implements IMarquise {

	public id: string;
	public handler: IMarquiseHandler;
	public paletteId: string;
	public fabric: Color | ColorOfPalette;
	public engine = new MarquisesEngine(this);
	private _frames: Profile[];
	get frames(): Profile[] {
		return this._frames;
	}

	public rectOnRoof: IRect;
	public rectOnFront: IRect;
	public rectOnLeft: IRect;
	public rectOnRight: IRect;
	public isReversed: boolean;

	protected abstract getTop(): number;
	protected abstract getHeight(): number;
	protected abstract adjustMontage();
	protected abstract getMasterProfile(): Profile;
	protected abstract get initialMontage(): Montage;
	protected abstract createFrameInternal(orient: Orientation): FrameProfile;
	protected abstract getLeftProfileY(): number;
	protected abstract getRightProfileY(): number;

	public abstract isMontageAllowed(p: Montage): boolean;
	public abstract get isFront(): boolean;

	public static createFrame(projectService: ProjectService, info: IMarquiseInfo, orient: Orientation): FrameProfile {
		const cnt = projectService.template.hiddenExtras.find(profile => profile.id == info.connectorId);
		const front = projectService.profileService.frontElements[ElementType.Front][0];
		const col = projectService.profileService.frontElements[ElementType.Column][0];
		const y = orient == Orientation.Vertical ? col.rectOnFront.y : front.rectOnFront.y + front.rectOnFront.h;
		const w = orient == Orientation.Vertical ? col.rectOnFront.h / SvgParams.SCALE : projectService.template.width;
		var frame = new FrameProfile(front.rectOnFront.x, y, w, cnt.size, cnt.color, orient, info.connectorId);
		// frame.rectOnRoof = { x: front.rectOnRoof.x, y: front.rectOnRoof.y, w: cnt.size.width, h: cnt.size.depth };
		return frame;
	};

	private _markers: ILine[] = [];
	private currentArea: string;
	private frontId: string;
	protected minFrontY: number;
	private _montage: Montage;

	get type(): ElementType {
		return ElementType.MarquiseVertical;
	};

	get configId() {
		return this.marquiseInfo.id;
	}

	get name(): string {
		return $localize`:Common|Marquise:Marquise`;
	};

	public get channels(): number {
		return this.marquiseInfo.channels;
	}
	
	get layer(): LayerLevel {
		switch (this.montage) {
			case Montage.Inside:
				return LayerLevel.Inside;
			case Montage.Middle:
				return LayerLevel.Middle;
			case Montage.Outside:
				return LayerLevel.Outside;
		}
	}

	get leftX(): number {
		return this[this.currentArea].x;
	}
	get rightX(): number {
		return this[this.currentArea].x + this[this.currentArea].w;
	}
	get topY(): number {
		return this[this.currentArea].y;
	}
	get bottomY(): number {
		return this[this.currentArea].y + this[this.currentArea].h;
	}
	get defMaxWidth(): number {
		return this.marquiseInfo.maxWidth;
	}
	get defMaxHeight(): number {
		return this.marquiseInfo.maxHeight;
	}
	get defMinWidth(): number {
		return this.marquiseInfo.minWidth;
	}
	get defMinHeight(): number {
		return this.marquiseInfo.minHeight;
	}

	get widthCorrected(): number {
		return this.width - MarquisesParams.WIDTH_CORRECTION;
	}
	set widthCorrected(v: number) {
		this.width = v + MarquisesParams.WIDTH_CORRECTION;
	}

	get width(): number {
		return this[this.currentArea].w / SvgParams.SCALE;
	}

	set width(v: number) {
		v += MarquisesParams.WIDTH_CORRECTION;
		var newMaxX = this[this.currentArea].x + v * SvgParams.SCALE;
		var oldMaxX = this[this.currentArea].x + this[this.currentArea].w;

		if (this.rightProfile.type == ElementType.Column) {
			if (v > this.maxWidth - MarquisesParams.COLUMN_HANDLER_POINT) {
				v = this.maxWidth - MarquisesParams.COLUMN_HANDLER_POINT;
				newMaxX = this[this.currentArea].x + v * SvgParams.SCALE;
			}
			this.rightProfile = this.createFrameInternal(Orientation.Vertical);
			this.frames.push(this.rightProfile);
		} else {
			var currentMaxX = newMaxX - MarquisesParams.COLUMN_HANDLER_POINT * SvgParams.SCALE;
			const cols = this.getColumns();
			const col = cols.find(c => c[this.currentArea].x >= oldMaxX && c[this.currentArea].x <= currentMaxX);
			if (col) {
				this._frames = this.frames.filter(f => f.id != this.rightProfile.id);
				this.rightProfile = col;
			}
		}

		if (this.rightProfile.type == ElementType.Frame) {
			newMaxX -= this[this.currentArea].x;
			this.rightProfile[this.currentArea].x = this[this.currentArea].x + newMaxX - this.rightProfile.depth * SvgParams.SCALE;
		}
		this.adjustFrames();
		this.adjustMontage();
	}

	get maxWidth() {
		if (this.rightProfile.type == ElementType.Column) {
			return this.width;
		}

		var currentMaxX = this[this.currentArea].x + this[this.currentArea].w - MarquisesParams.COLUMN_HANDLER_POINT;
		const cols = this.getColumns();
		var col: Profile = cols.find(c => c[this.currentArea].x > currentMaxX);
		if (!col) {
			return this.projectService.template.depth - this.locationX;
		}
		return (col[this.currentArea].x - this[this.currentArea].x) / SvgParams.SCALE + MarquiseVertical.getHandlerPoint(col) - MarquisesParams.WIDTH_CORRECTION;
	}

	get height(): number {
		return this[this.currentArea].h / SvgParams.SCALE;
	}
	set height(v: number) {
		this[this.currentArea].h = v * SvgParams.SCALE;
		this.adjustFrames();
	}

	get locationX(): number {
		return (this[this.currentArea].x - SvgParams.START_X) / SvgParams.SCALE;
	}
	set locationX(v: number) {
		// const diff = v - (this[this.currentArea].x - SvgParams.START_X) / SvgParams.SCALE;
		var newLocX = SvgParams.START_X + (v * SvgParams.SCALE);

		if (this.leftProfile.type == ElementType.Column) {
			if (v < this.minLocationX + MarquisesParams.COLUMN_HANDLER_POINT) {
				v = (this.leftProfile[this.currentArea].x + this.leftProfile[this.currentArea].w - SvgParams.START_X) / SvgParams.SCALE;
				newLocX = SvgParams.START_X + (v * SvgParams.SCALE);
			}
			this.leftProfile = this.createFrameInternal(Orientation.Vertical);
			this.frames.push(this.leftProfile);
		} else {
			const cols = this.getColumns();
			const col = cols.find(c => c[this.currentArea].x < newLocX && c[this.currentArea].x + c[this.currentArea].w > newLocX);
			if (col) {
				this._frames = this.frames.filter(f => f.id != this.leftProfile.id);
				this.leftProfile = col;
			} else { // No column on the left - left side case
			}
		}

		if (this.leftProfile.type == ElementType.Frame) {
			this.leftProfile[this.currentArea].x = newLocX;
		}
		this.adjustFrames();
		this.adjustMontage();
	}

	get minLocationX() {
		let col: Profile;

		if (this.leftProfile.type == ElementType.Column) {
			col = this.leftProfile;
		} else {
			const cols = this.getColumns();
			col = cols.find(c => c[this.currentArea].x + c[this.currentArea].w <= (this.locationX * SvgParams.SCALE) + SvgParams.START_X);
			if (!col) { // No column on the left, left side case
				return 0;
			}
		}

		return (col[this.currentArea].x + col[this.currentArea].w - SvgParams.START_X) / SvgParams.SCALE - MarquiseVertical.getHandlerPoint(col);
	}
	get maxLocationX() {
		const m1 = this.marquiseInfo.minWidth;
		const m2 = (this.rightProfile[this.currentArea].x - SvgParams.START_X) / SvgParams.SCALE;
		return m2 - m1 + MarquiseVertical.getHandlerPoint(this.rightProfile);
	}

	get locationY(): number {
		return (this[this.currentArea].y - SvgParams.START_Y) / SvgParams.SCALE;
	}
	set locationY(v: number) {
		if (v > this.minFrontY && !this.topFrame) {
			this.topFrame = this.createFrameInternal(Orientation.Horizontal);
			this.frames.push(this.topFrame);
		}
		if (v < this.minFrontY) {
			if (this.topFrame) {
				this._frames = this.frames.filter(f => f.id != this.topFrame.id);
				this.topFrame = null;
			}
		} else {
			this.topFrame[this.currentArea].y = v * SvgParams.SCALE + SvgParams.START_Y;
		}
		const diff = v - this.locationY;
		this[this.currentArea].y = v * SvgParams.SCALE + SvgParams.START_Y;
		this[this.currentArea].h -= diff * SvgParams.SCALE;
		this.adjustFrames();
		this.adjustMontage();
	}

	get minLocationY(): number {
		return this.minFrontY - this.handler.heightCorrection;
	}
	get maxLocationY(): number {
		return 2000;
	}

	get distance(): number {
		return Math.max(this.locationY - this.minFrontY, 0);
	}
	set distance(v: number) {		
		if (v == 0) {
			v = -this.handler.heightCorrection;
		}
		this.locationY = this.minFrontY + v;
	}
	
	public get markers(): ILine[] {
		return this._markers;
	}

	get montage() {
		if (this._montage == null) {
			this._montage = this.initialMontage;
		}
		return this._montage;
	}
	set montage(value: Montage) {		
		if (this.handler) {
			const oldCorr = this.handler.heightCorrection * SvgParams.SCALE;

			this._montage = value;
			this.handler = this.getHandler();
			const corr = this.handler.heightCorrection * SvgParams.SCALE;

			if (corr != oldCorr) {
				const diff = oldCorr - corr
				this[this.currentArea].y += diff;
				this[this.currentArea].h -= diff;
			}
		} else {
			this._montage = value;
			this.handler = this.getHandler();
		}
		this.refreshSurround(this.projectService.profileService);
		this.adjustMontage();
	}

	public refreshSurround(profileService: ProfileService) {
		if (this.montage == Montage.Middle) {
			super.refreshSurround(profileService);
		} else {
			this.bottomNeib = null;
			this.topNeib = null;
			this.leftNeib = null;
			this.rightNeib = null;
		}
	}

	private adjustFrames() {
		if (this.leftProfile.type == ElementType.Frame) {
			const y1 = this.getLeftProfileY();
			const y2 = this.areaPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
			var h = y2 - y1;
			h = Math.min(h, this.maxHeight * SvgParams.SCALE)
			this.leftProfile[this.currentArea].y = y1;
			this.leftProfile[this.currentArea].h = h;
		}
		if (this.rightProfile.type == ElementType.Frame) {
			const y1 = this.getRightProfileY();
			const y2 = this.areaPoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y;
			var h = y2 - y1;
			h = Math.min(h, this.maxHeight * SvgParams.SCALE)
			this.rightProfile[this.currentArea].y = y1;
			this.rightProfile[this.currentArea].h = h;
		}

		if (this.topFrame) {
			const x1 = MarquiseVertical.getX1(this.leftProfile, this.area, this.montage);
			const x2 = MarquiseVertical.getX2(this.rightProfile, this.area, this.montage);

			this.topFrame[this.currentArea].x = x1;
			this.topFrame[this.currentArea].w = x2 - x1;

		}
	}

	public setHandler(frontId: string) {
		this.handler = this.getHandler();
		var hnd = this.marquiseInfo.handlers.find(h => h.montage == this.montage && h.frontId == frontId);;
		if (!hnd) {
			hnd = this.marquiseInfo.handlers.find(h => h.montage == this.montage && h.frontId == null);
		}
		this.handler = hnd;
	}

	public relocateEngine(side: ElementSide) {
		if (side == ElementSide.Left) {
			this.engine.rect.x = this[this.currentArea].x;
		} else {
			this.engine.rect.x = this[this.currentArea].x + this[this.currentArea].w - this.engine.rect.w;
		}
		this.engine.rect.y = this[this.currentArea].y;
		this.engine.location = side;
	}

	public adjust() {
		this._markers = [];

		const x1 = MarquiseVertical.getX1(this.leftProfile, this.area, this.montage);
		const x2 = MarquiseVertical.getX2(this.rightProfile, this.area, this.montage);

		var w = x2 - x1;
		if (w / SvgParams.SCALE > this.marquiseInfo.maxWidth || w / SvgParams.SCALE < this.marquiseInfo.minWidth) {
			return false;
		}

		const corr = this.getHandler().heightCorrection * SvgParams.SCALE;
		const y = this.getTop() - corr;
		var h = this.getHeight() + corr;

		this[this.currentArea] = { x: x1, w, y, h };
		this.adjustMontage();

		return true;
	}

	public expand(side: ElementSide) {
		const cn = RectName.get(this.area);
		var x = this.rightX;
		if (this.rightProfile.type == ElementType.Column) {
			x = this.rightProfile[cn].x;
			if (side == ElementSide.Right) {
				x += this.rightProfile[cn].w;
			}
		}
		var r = this.projectService.profileService.getColumn(this.area, side, x);
		if (r != null && r.id != this.leftProfile.id) {
			if (this.rightProfile.type == ElementType.Frame) {
				this._frames = this._frames.filter(f => f.id != this.rightProfile.id);
			}
			this.rightProfile = r;
			this.adjust();
			return true;
		}

		return false;
	}

	public move(side: ElementSide) {
		var lp = this.projectService.profileService.getNextColumn(this.area, side, this.leftProfile);
		var rp = this.projectService.profileService.getNextColumn(this.area, side, this.rightProfile);
		if (lp && rp) {
			this.leftProfile = lp;
			this.rightProfile = rp;
			this.adjust();
			return true;
		}

		return false;
	}

	public refresh() {
		this.adjustMontage();
	}

	constructor(
		protected projectService: ProjectService,
		public marquiseInfo: IMarquiseInfo,
		public leftProfile: Profile,
		public rightProfile: Profile,
		public topFrame: FrameProfile = null,
		public area: AreaType = AreaType.Front,
		protected areaPoints: IPoint[],
		rectOnRoof: IRect = null,
		rectOnLeft: IRect = null,
		rectOnRight: IRect = null,
		rectOnFront: IRect = null,
		rectOnRear: IRect = null) {
		
		super(projectService);
		this.currentArea = RectName.get(this.area);
		this.frontId = projectService.template.frontId;
		this.id = "MarquiseVertical_" + uuidv4();

		this.paletteId = marquiseInfo.paletteId;
		(this.fabric as Color | {}) = null;
		this._frames = [];

		this.engine.setDefaultEngineParams(marquiseInfo);
		if (area == AreaType.Rear) {
			const r = projectService.profileService.rearElements[ElementType.WallProfile][0];
			this.minFrontY = Math.round(((r.rectOnRear.y + r.rectOnRear.h) - SvgParams.START_Y) / SvgParams.SCALE);
		} else {
			const f = projectService.profileService.frontElements[ElementType.Front][0];
			this.minFrontY = Math.round(((f.rectOnFront.y + f.rectOnFront.h) - SvgParams.START_Y) / SvgParams.SCALE);
		}

		this.rectOnLeft = rectOnLeft;
		this.rectOnFront = rectOnFront;
		this.rectOnRight = rectOnRight;
		this.rectOnRoof = rectOnRoof;
		this.rectOnRear = rectOnRear;

		if (this.topFrame) {
			const mfy = SvgParams.START_Y + this.minFrontY * SvgParams.SCALE;
			if (this.topFrame[this.currentArea].y < mfy) {
				this.topFrame[this.currentArea].y = mfy;
			}
			this.frames.push(this.topFrame);
		}

		if (!this[this.currentArea]) {
			this.adjust();
		}

		this.engine.rect = {
			x: this[this.currentArea].x,
			y: this[this.currentArea].y,
			w: this.engine.size.width * SvgParams.SCALE,
			h: this.engine.size.depth * SvgParams.SCALE,
		};

		this.engine.id = this.marquiseInfo.engines.find(e => e.isDefault === true)?.id;

		if (leftProfile.type == ElementType.Frame) {
			this.frames.push(leftProfile);
		}

		if (rightProfile.type == ElementType.Frame) {
			this.frames.push(rightProfile);
		}

		this.isReversed = false;
	}

	protected getHandler() {
		var handler = this.marquiseInfo.handlers.find(l => l.montage == this.montage && l.frontId == this.frontId);
		if (!handler) {
			handler = this.marquiseInfo.handlers.find(l => l.montage == this.montage && l.frontId == null);
		}
		return handler;
	}

	protected getColumns(): ColumnProfile[] {
		const colName = CollectionName.get(this.area);
		return this.projectService.profileService.getSortedColumns(colName, this.area);
	}

	protected static getX1(leftProfile: Profile, area: AreaType, montage: Montage) {
		const a = 'rectOn' + area;
		var d: number;
		if (montage == Montage.Middle) {
			d = 0;
		} else {
			d = this.getHandlerPoint(leftProfile);
		}
		 
		return leftProfile[a].x + leftProfile[a].w - d * SvgParams.SCALE;
	}

	protected static getX2(rightProfile: Profile, area: string, montage: Montage) {
		const a = 'rectOn' + area;
		var d: number;
		if (montage == Montage.Middle) {
			d = 0;
		} else {
			d = rightProfile.type == ElementType.Column ? MarquisesParams.COLUMN_HANDLER_POINT : rightProfile.depth;
		}
		return rightProfile[a].x + d * SvgParams.SCALE;
	}

	protected calculateMarkers(size: IRect) {
		this._markers = [];
		return;

		// To be continued in v. 1.17

		// const seam = (this.marquiseInfo.seam ?? 2000) * SvgParams.SCALE;
		// var prevSeam = 0;
		// var w = size.w;
		// while (w > seam) {
		// 	const x = size.x + (prevSeam + seam);
		// 	this._markers.push({ x1: x, y1: size.y, x2: x, y2: size.y + size.h });
		// 	prevSeam += seam;
		// 	w -= seam;
		// }

		// prevSeam = 0;
		// var h = size.h;
		// while (h > seam) {
		// 	const y1 = size.y + (prevSeam + seam);
		// 	this._markers.push({ x1: size.x, y1: y1, x2: size.x + size.w, y2: y1 });
		// 	prevSeam += seam;
		// 	h -= seam;
		// }
	}

	protected static getHandlerPoint(p: Profile) {
		return p.type == ElementType.Column ? MarquisesParams.COLUMN_HANDLER_POINT : p.depth;
	}

	public static validate(info: IMarquiseInfo, leftProfile: Profile, rightProfile: Profile, area: AreaType, montage: Montage = null, profileService: ProfileService = null) {
		if (montage == null) {
			switch(area) {
				case AreaType.Front:
					montage = Montage.Inside;
					break;
				default:
					montage = Montage.Outside;
					break;
			}
		}

		var cl = profileService.getMarquisesVertical().find((m: MarquiseVertical) =>
			(m.leftProfile?.id == leftProfile?.id || m.rightProfile?.id == rightProfile?.id)
			&& (m.montage == montage || m.montage == Montage.Middle));
		if (cl) {
			return $localize`:Marquise|Validation message:Cannot place a marquise on another one!`;
		}

		if ((leftProfile?.type == ElementType.Column && (leftProfile as ColumnProfile).isWinkel)
			|| (rightProfile?.type == ElementType.Column && (rightProfile as ColumnProfile).isWinkel)) {
			return $localize`:Marquise|Validation message:Cannot mount marquise to a winkel!`;
		}
		
		const x1 = MarquiseVertical.getX1(leftProfile, area, montage);
		const x2 = MarquiseVertical.getX2(rightProfile, area, montage);
		const w = (x2 - x1) / SvgParams.SCALE;

		if (w + MarquisesParams.WIDTH_CORRECTION > info.maxWidth) {
			return $localize`:Marquise|Validation message:The width of the new marquise is greater than maximum allowable!`;
		}

		if (w < info.minWidth - MarquisesParams.WIDTH_CORRECTION) {
			return $localize`:Marquise|Validation message:The width of the new marquise is less than minimum allowable!`;
		}

		if (profileService == null) {
			return "";
		}

		// const marquises = profileService.getMarquisesVertical(area);

		// if (marquises.find((m: MarquiseVertical) => m.leftProfile.id == leftProfile.id) ||
		// 	marquises.find((m: MarquiseVertical) => m.rightProfile.id == rightProfile.id)) {
		// 	return $localize`:Marquise|Validation message:There is already a marquise in this place!`;
		// 	}

		return "";
	}
}