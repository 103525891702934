import { IAreaService } from "../../interfaces/IAreaService";
import { AreaType } from "../../interfaces/IAreaType";
import { IPoint } from "../../interfaces/IPoint";
import { ProjectService } from "../../services/project.service";
import { GlassPartDiamond } from "../glasses/glassDiamondPart";
import { GlassWallDiamond } from "../glasses/glassWallDiamond.model";
import { MuntinProfile } from "../profiles/muntin.model";
import { ISize, WizardParameter } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";
import { GlassWallsStoreModelBase } from "./glasswalllbase-store-model";


export class GlassPartStoreModel extends ElementStoreModel {

	points: IPoint[];

	constructor(g: GlassPartDiamond) {
		super(g.id, g.configId);

		this.points = g.points;
	}
}

export class GlassWallDiamondStoreModel extends GlassWallsStoreModelBase {

	parts: GlassPartStoreModel[] = [];
	points: IPoint[];
	size: ISize;

	constructor(g: GlassWallDiamond, projectService: ProjectService) {
		super(g);

		this.points = g.points;
		this.size = { depth: Math.round(g.width), height: Math.round(g.height), width: undefined };

		this.storeFrames(g);
		this.storeMuntins(g);

		g.parts.forEach(p => {
			this.parts.push(new GlassPartStoreModel(p));
		});

	}

	public static restore(g: GlassWallDiamondStoreModel, projectService: ProjectService, srv: IAreaService) {

		const def = projectService.template.diamondGlasses.find(d => d.id === g.configId);
		var wall: GlassWallDiamond = new GlassWallDiamond(projectService, def, g.points, g.area, srv, g.hanging);
		projectService.profileService.addVertical(wall, false);

		const wallDef = projectService.template.diamondGlasses.find(g => g.id == wall.configId);

		g.parts.forEach((p: GlassPartStoreModel) => {
			let part = new GlassPartDiamond(wall, projectService, p.points, g.area);
			part.id = p.id;
			wall.parts.push(part);
		});

		const extra: WizardParameter = projectService.template.hiddenExtras.find(profile => profile.id == wallDef.connectorId)
		this.restoreFrames(g, wall, projectService);
		this.restoreMuntins(g, wall);

		this.assingParts(wall, g);		
		return wall;

	}

	protected static assingParts(wall: GlassWallDiamond, g: GlassWallDiamondStoreModel) {
		g.muntins.forEach(m => {
			const leftPart = wall.parts.find(p => p.id == m.leftPartId);
			const rightPart = wall.parts.find(p => p.id == m.rightPartId);

			var munt: MuntinProfile = wall.muntins.find(e => e.id == m.id);
			munt.leftPart = leftPart;
			munt.rightPart = rightPart;
		});
	}

}
