<div class="row">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numWidth [min]="minWidth" [max]="maxWidth" [value]="currentGlassWidth" [delay]="200"
			[disabled]="isWidthChangeDisabled">
		</app-numeric-textbox> mm &nbsp;
	</div>

</div>

<div *ngIf="current.type == 'GlassWallDiamond'">
	<div class="row">
		<div class="col col-4"><span i18n="Common|Height - back">Height - back</span>:</div>
		<div class="col col-8">
			<app-numeric-textbox #numBackHeight [min]="backMinHeight" [max]="current.maxHeight" [value]="backHeight" [disabled]="isHeightChangeDisabled">
			</app-numeric-textbox> mm
		</div>
	</div>

	<div class="row">
		<div class="col col-4"><span i18n="Common|Height - front">Height - front</span>:</div>
		<div class="col col-8">
			<app-numeric-textbox #numFrontHeight [min]="current.defMinHeight" [max]="current.maxHeight - (backHeight - frontHeight)" [value]="frontHeight" [disabled]="isHeightChangeDisabled">
			</app-numeric-textbox> mm
		</div>
	</div>
</div>

<div class="row" *ngIf="current.type == 'GlassWall'">
	<div class="col col-4"><span i18n="Common|Height">Height</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [min]="current.defMinHeight" [max]="current.maxHeight" [value]="currentGlassHeight" [disabled]="isHeightChangeDisabled">
		</app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocX [value]="locationX" [min]="current.minLocationX" [max]="current.maxLocationX"
			[disabled]="isLocationsChangeDisabled"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="locationY" [min]="current.minLocationY" [max]="current.maxLocationY"
			[disabled]="projectService.readOnly || current.minLocationY == current.maxLocationY || isLocationsChangeDisabled"></app-numeric-textbox>
		mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Muntins count">Muntins count</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numMunt [value]="muntinsCount" [min]="current.minMuntinsCount"
			[max]="(current.minMuntinsCount + 1) * 10" [step]="1" [disabled]="projectService.readOnly"></app-numeric-textbox>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Glass type">Glass type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="getGlassesTypeData()" textField="name" valueField="id"
			[(ngModel)]="currentDef" [valuePrimitive]="true" (valueChange)="typeChanged()"
			[disabled]="projectService.readOnly || toolBSrvc.getCurrTool()"></kendo-dropdownlist>
	</div>
</div>

<div *ngIf="current.canHang">

	<div class="row" *ngIf="current.hanging; else unhanging">
		<div class="col col-4">
		</div>
		<div class="col col-8">
			<button kendoButton (click)="hang(false)" [disabled]="projectService.readOnly" class="large"><span i18n="Common|Add bottom frame">Add bottom frame</span></button>
		</div>
	</div>

	<ng-template #unhanging>
		<div class="row">
			<div class="col col-4">
			</div>
			<div class="col col-8">
				<button kendoButton (click)="hang(true)" [disabled]="projectService.readOnly" class="large"><span i18n="Common|Delete bottom frame">Delete bottom frame</span></button>
			</div>
		</div>
	</ng-template>

</div>


<card-cuttingonsite [current]="current"></card-cuttingonsite>

<div class="row">
	<div class="col col-4">
		<span i18n="Common|Delete">Delete</span>:
	</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="delete()" [disabled]="projectService.readOnly" class="medium"><span i18n="Common|Delete">Delete</span></button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo && current.type != 'GlassWallDiamond' && current.type != 'GlassWall'"
	class="testMode">
	<div class="row">
		<div class="col col-4">Width:</div>
		<div class="col col-8">{{ currentGlassWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Viewport:</div>
		<div class="col col-8">{{ currentGlassViewWidth | number:'1.2-2' :currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Depth:</div>
		<div class="col col-8">{{ current.length | number:'1.2-2' :currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{minWidth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{maxWidth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Weight (1m<sup>2</sup>):</div>
		<div class="col col-8">{{current?.stat?.weight}} kg</div>
	</div>
	<div class="row">
		<div class="col col-4">Max weight:</div>
		<div class="col col-8">{{current.stat?.maxWeight | number:'1.2-2' :currLng}}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max depth:</div>
		<div class="col col-8">{{ current.stat?.maxDepth | number:'1.2-2' :currLng}} mm</div>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo && (current.type == 'GlassWall' || current.type == 'GlassWallDiamond')"
	class="testMode">
	<div class="row">
		<div class="col col-4">Min location x:</div>
		<div class="col col-8">{{ current.minLocationX }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max location x:</div>
		<div class="col col-8">{{ current.maxLocationX }} mm</div>
	</div>
	<div class="row" *ngIf="current.type == 'GlassWall'">
		<div class="col col-4">Min location y:</div>
		<div class="col col-8">{{ current.minLocationY }} mm</div>
	</div>
	<div class="row" *ngIf="current.type == 'GlassWall'">
		<div class="col col-4">Max location y:</div>
		<div class="col col-8">{{ current.maxLocationY }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ backMinHeight }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ current.maxHeight }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{ current.minWidth }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{ current.maxWidth }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min. muntins:</div>
		<div class="col col-8">{{ current.minMuntinsCount }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Hang:</div>
		<div class="col col-8">{{ current.hanging }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Max. surface:</div>
		<div class="col col-8">{{ current.maxM2 / 1000000 | number:'1.2-2' :currLng }} m<sup>2</sup></div>
	</div>
</div>