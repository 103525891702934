import { AreaType } from "../../interfaces/IAreaType";
import { ProjectService } from "../../services/project.service";
import { GutterProfile } from "../profiles/gutter.model";
import { ElementStoreModel } from "./element-store.model";

export class GutterStoreModel extends ElementStoreModel {
	frontId: string;
	length: number;
	visible: boolean;

	constructor(c: GutterProfile) {
		super(c.id, c.configId, c.tag, c.cuttingOnSite);

		this.length = c.getLength();
		this.frontId = c.frontId;
		// this.visible = c.visible;
		this.storeLocationAndSize(c);
	}

	public static restore(f: GutterStoreModel, projectService: ProjectService) {
		var fp = new GutterProfile(AreaType.Roof, f.rectOnRoof.x, f.rectOnRoof.y, projectService.template, f.length, AreaType.Roof, projectService.template.frontId);
		fp.id = f.id;
		fp.tag = f.tag;
		fp.cuttingOnSite = f.cuttingOnSite;
		fp.frontId = f.frontId;
		// fp.visible = f.visible;
		ElementStoreModel.restoreLocationAndSize(f, fp);

		return fp;
	}
}
