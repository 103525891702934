<div class="container" (click)="onBgClick()" (mouseover)="isHover = true" (mouseout)="isHover = false" [ngClass]="changeIcon() ? 'icon_extras' : ''">
	<div class="extras-card" style="position: relative;"
			 *ngFor="let option of profileService.extraElements"
			 (click)="choseOption(option)">
		<div class="thumbnail"
				 style="margin: auto;"
				 [style.backgroundImage]="'url(' + option.thumbnail + ')'"
				 [ngClass]="chosenOption && chosenOption.id === option.id ? 'highlight' : ''"
		></div>
		<p style="width: 100px; text-align: center;">{{option.name}}</p>
		<p style="position: absolute; top: 60px; left: 15px;">
			x{{option.quantity}}
		</p>
	</div>
</div>
