import { Component, Input } from '@angular/core';
import { ICuttingable } from '../../_core/interfaces/ICuttingable';
import { ElementType } from '../../_core/models/ToolboxModel';
import { ProfileService } from '../../_core/services/profile.service';
import { ProjectService } from '../../_core/services/project.service';

@Component({
	selector: 'card-cuttingonsite',
	templateUrl: './cuttingonsite.component.html',
	styleUrls: ['./../../card/card.component.css']
})
export class CuttingOnSiteComponent {
	@Input() current: ICuttingable;
	@Input() disabled: boolean;

	public dialogOpened: boolean = false;
	public dialogMessage: string;

	get cuttingOnSite() {
		return this.current.cuttingOnSite;
	}

	set cuttingOnSite(value) {
		this.current.cuttingOnSite = value;
		this.projectService.emitChange();
	}

	constructor(public projectService: ProjectService, private profileService: ProfileService) { }

	applyingCuttingForOtherElems() {
		const currentType = this.current.type;

		this.applyingCuttingForAllElementsOnAllSides(currentType);

		if (currentType === ElementType.Bar) {
			this.applyingCuttingForAllElementsOnAllSides(ElementType.SideFinish);
		} else if (currentType === ElementType.SideFinish) {
			this.applyingCuttingForAllElementsOnAllSides(ElementType.Bar);
		}

		this.projectService.emitChange();
	}

	private applyingCuttingForAllElementsOnAllSides(currentType: string) {
		this.profileService.roofElements[currentType].forEach(e => e.cuttingOnSite = this.current.cuttingOnSite);
		this.profileService.frontElements[currentType].forEach(e => e.cuttingOnSite = this.current.cuttingOnSite);
		this.profileService.leftSideElements[currentType].forEach(e => e.cuttingOnSite = this.current.cuttingOnSite);
		this.profileService.rightSideElements[currentType].forEach(e => e.cuttingOnSite = this.current.cuttingOnSite);
	}

	openDialog(): void {
		this.dialogOpened = true;
		this.dialogMessage = $localize`:Messages|'Apply cutting on site to other' confirmation:Do you really want to apply "cutting on site" for all items with the same type?`;
	}

	onDialogAction(info: {status: boolean, message: string}) {
		this.dialogOpened = false;

		if (!info.status) return;

		this.applyingCuttingForOtherElems();
	}


}
