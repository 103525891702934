import { Injectable } from "@angular/core";
import { ColorImgParams } from "../constants/constants";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { ICurrentColor } from "../models/colors/color.model";


@Injectable({
	providedIn: 'root',
}) 
export class GraphicsService {
	public imageChanged: BehaviorSubject<ICurrentColor> = new BehaviorSubject<ICurrentColor>(null);

	public static getColorAvg(red: number, green: number, blue: number) {
		var avg = Math.floor((red + green + blue) / 3);
		return avg;
	}

	public static isColorDark(red: number, green: number, blue: number) {
		var avg = this.getColorAvg(red, green, blue);
		return this.isDark(avg);
	}

	public static isDark(colorAvg: number) {
		return colorAvg < ColorImgParams.TRESHOLD;
	}

	public getBrightness(color: any) {
		if (!color) {
			return;
		}
		var brightness = -1;
		var darkness = "";

		var imgUrl = color["image"] ?? color["photo"];
		if (!imgUrl) {
			var colcol: string = color["color"];
			if (!colcol) {
				return;
			}
			if (colcol[0] == "#") {
				colcol = colcol.substring(1);
			}
			var r = '0x' + colcol.substring(0, 2);
			var g = '0x' + colcol.substring(2, 4);
			var b = '0x' + colcol.substring(4, 6);

			brightness = GraphicsService.getColorAvg(+r, +g, +b);
			darkness = GraphicsService.isDark(brightness) ? "dark" : "light";

			this.imageChanged.next({
				elementId: null,
				color,
				rgbColor: colcol,
				brightness,
				darkness,
				imageUrl: "",
				imageBase64: ""
			});

			return;
		}

		var img = new Image();
		img.crossOrigin = "anonymous";

		var that = this;
		img.onload = function () {
			var canvas = document.createElement("canvas");
			canvas.width = ColorImgParams.WIDHT;
			canvas.height = ColorImgParams.HEIGHT;

			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);

			var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			var data = imageData.data;

			var colorSum = 0;
			for (var x = 0, len = data.length; x < len; x += 4) {
				colorSum += GraphicsService.getColorAvg(data[x], data[x + 1], data[x + 2]);
			}

			var base64String = canvas.toDataURL();
			brightness = Math.floor(colorSum / (ColorImgParams.WIDHT * ColorImgParams.HEIGHT));
			darkness = GraphicsService.isDark(brightness) ? "dark" : "light";

			that.imageChanged.next( {
				elementId: null,
				color,
				rgbColor: "",
				brightness,
				darkness,
				imageUrl: imgUrl,
				imageBase64: base64String
			});
		}
		img.src = imgUrl;
	}

	public getBackgroud(elementId: string, color: any, width: number = null, height: number = null) {
		if (!color) {
			return;
		}

		var imgUrl = color["image"] ?? color["photo"];
		if (!imgUrl) {
			return;
		}

		if (width == null) {
			width = ColorImgParams.FABRIC_WIDHT;
		}

		if (height == null) {
			height = ColorImgParams.FABRIC_HEIGHT;
		}

		var img = new Image();
		img.crossOrigin = "anonymous";

		var that = this;

		img.onload = function () {
			var canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;

			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);

			var base64String = canvas.toDataURL();

			that.imageChanged.next({
				elementId: elementId,
				color,
				rgbColor: "",
				brightness: -1,
				darkness: "",
				imageUrl: imgUrl,
				imageBase64: base64String
			});
		}
		img.src = imgUrl;
	}
}