import { AreaType } from "../../interfaces/IAreaType";
import { ProjectService } from "../../services/project.service";
import { BarProfile } from "../profiles/bar.model";
import { ProfileConnector } from "../profiles/connector.model";
import { ElementType, Orientation } from "../ToolboxModel";
import { ElementStoreModel } from "./element-store.model";

export class BarStoreModel extends ElementStoreModel {
	topGlassId: string;
	bottomGlassId: string;
	leftGlassId: string;
	rightGlassId: string;
	length: number;
	type: ElementType;
	orientation: Orientation;

	constructor(e: BarProfile) {
		super(e.id, e.configId, e.tag, e.cuttingOnSite);

		this.topGlassId = e.topGlass?.id;
		this.bottomGlassId = e.bottomGlass?.id;
		this.leftGlassId = e.leftGlass?.id;
		this.rightGlassId = e.rightGlass?.id;
		this.type = e.type;
		this.orientation = e.orientation;
		this.length = e.length;
		this.storeLocationAndSize(e);
	}

	public static restore(b: BarStoreModel, projectService: ProjectService) {
		let bar: BarProfile;
		if (b.type == ElementType.ProfileConnector) {
			bar = new ProfileConnector(AreaType.Roof, b.rectOnRoof.x, b.rectOnRoof.y, projectService.template, this.length, Orientation.Vertical, AreaType.Roof, b.configId);
		} else {
			bar = new BarProfile(AreaType.Roof, b.rectOnRoof.x, b.rectOnRoof.y, projectService.template, this.length, Orientation.Vertical, AreaType.Roof, b.configId);
		}
		bar.id = b.id;
		bar.cuttingOnSite = b.cuttingOnSite;
		bar.length = b.length;
		bar.orientation = b.orientation;
		
		ElementStoreModel.restoreLocationAndSize(b, bar);
		return bar;
	}
}
