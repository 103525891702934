<svg:g *ngFor="let m of getDoors()">
	<rect [attr.x]="m.rect.x" [attr.y]="m.rect.y" [attr.width]="m.rect.w" [attr.height]="m.rect.h"
		[attr.class]="styling.getClasses(m, area, selectedItems.indexOf(m) >= 0, 'door', false, 0.7)"
		(click)="selectDoor(m)"
		[attr.stroke]="projectService.frameColor" [attr.fill]="projectService.getBackColor()">
	</rect>
	<rect *ngIf="m.foundation" stroke="lightgray" stroke-width="2" fill="none"
		[attr.x]="m.rect.x" [attr.y]="m.rect.y + m.rect.h" [attr.width]="m.rect.w" [attr.height]="10">
	</rect>
	<rect *ngFor="let g of m.wings" [attr.x]="g.x" [attr.y]="g.y" [attr.width]="g.w" [attr.height]="g.h" class="glass"		
		(click)="selectDoor(m)"
		[attr.class]="styling.getClasses(m, area, false, 'door', false, 0.8)"
		[attr.fill]="getGlassBackColor(m.glassColor)">
	</rect>
	<rect *ngFor="let f of m.frames" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w" [attr.height]="f.rect.h"
		[attr.class]="styling.getClasses(m, area, false, 'door', false, 0.8)"
		[attr.fill]="projectService.getBackColor()" [attr.stroke]="projectService.frameColor">
	</rect>
	<rect *ngFor="let l of m.locks" fill="gray"
		[attr.x]="l.x" [attr.y]="l.y" [attr.width]="l.w" [attr.height]="l.h"></rect>
	<line *ngFor="let l of m.arrows" stroke="gray" stroke-width="1"
		[attr.x1]="l.x1" [attr.y1]="l.y1" [attr.x2]="l.x2" [attr.y2]="l.y2" 
		marker-start="url(#downArrow)">
	</line>
	<line *ngFor="let l of m.dArrows" stroke="gray" stroke-width="1"
		[attr.x1]="l.line.x1" [attr.y1]="l.line.y1" [attr.x2]="l.line.x2" [attr.y2]="l.line.y2"
		[attr.marker-start]="l.lockSide == 'Right' || l.lockSide == 'LeftRight' ? 'url(#downArrow)' : ''"
		[attr.marker-end]="l.lockSide == 'Left' || l.lockSide == 'LeftRight' ? 'url(#upArrow)': ''">
	</line>
	<line *ngFor="let l of m.crosses" stroke="gray"
		stroke-width="1" [attr.x1]="l.x1" [attr.y1]="l.y1" [attr.x2]="l.x2" [attr.y2]="l.y2">
	</line>

</svg:g>
