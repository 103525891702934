<svg:g *ngFor="let l of getColumns()">
    <svg:rect *ngIf="!l.isWinkel" [attr.x]="l[rect].x" [attr.y]="l[rect].y" [attr.width]="l[rect].w"
        [attr.height]="l[rect].h" (click)="selectProfile(l, $event)"
        [class]="styling.getClasses(l, area, selectedItems.indexOf(l) >= 0, 'profile')"
        [attr.fill]="styling.getBackColor(l.color)"
        [attr.stroke]="projectService.frameColor">
    </svg:rect>
    <svg:path *ngIf="l.isWinkel" [attr.d]="l.getWinkelPath(area)"
        (click)="selectProfile(l, $event)"
        [class]="styling.getClasses(l, area, selectedItems.indexOf(l) >= 0, 'profile')"
        [attr.fill]="styling.getBackColor(l.color)" [attr.stroke]="projectService.frameColor">
    </svg:path>
    <svg:g app-outflow [item]="l" [maxy]="maxy" *ngIf="!printElements"></svg:g>
    <!-- <svg:rect *ngIf="l.connector" class="column-marker"
        [attr.x]="l.frontMarker.x" [attr.y]="l.frontMarker.y" [attr.width]="l.frontMarker.w" [attr.height]="l.frontMarker.h">
    </svg:rect> -->
</svg:g>
