import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SideBaseService } from 'src/app/_core/services/areas/side-base.service';
import { ElementIconType, ElementType, ToolboxItem, ToolboxTypeElement } from 'src/app/_core/models/ToolboxModel';
import { Glass } from 'src/app/_core/models/glasses/glass.model';
import { Profile } from 'src/app/_core/models/profiles/profile.model';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { ISelectGlass } from '../../../_core/interfaces/outputEvents/ISelectGlass.interface';
import { ToolboxItemService } from '../../../_core/services/toolbox-item.service';
import { ToolboxItemType } from '../../../_core/models/ToolboxModel';
import { IPointer } from 'src/app/_core/interfaces/IPointer';
import { SvgAreaBaseComponent } from '../svg-area-base/svg-area-base.component';

@Component({
	selector: 'g[app-svg-side]',
	templateUrl: './svg-side.component.html',
	styleUrls: ['./svg-side.component.css']
})
export class SvgSideComponent extends SvgAreaBaseComponent {
	private _elements: any[];

	@Input() set elements(p: any[]) {
		this._elements = p;
	}
	get elements() {
		return this._elements;
	}
	@Output() openLayoutEvent = new EventEmitter<boolean>(false);

	onSideDrop(pointerEvent: IPointer) {
		var addElements = this.toolboxService.iconClass(ElementType.Shape, this.areaService.areaType) == ElementIconType.AddElements;
		if (this.currentTool == null && !addElements) {
			return;
		}

		if ((addElements || ToolboxTypeElement.get(this.currentTool.type) != null) && this.projectService.profileService.getVerticals(this.areaService.areaType).length == 0) {
			this.openLayoutEvent.emit(true);
			return;
		}

		switch (this.currentTool.type) {
			case ToolboxItemType.GlassWall:
				this.projectService.createGlassWall(pointerEvent, this.currentTool);
				break;
			default:
				this.areaService.onDrop(pointerEvent, this.currentTool);
				break;
		}

		this.toolboxService.setCurrTool(null);
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

}
