import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExtraOption } from '../../models/extra-option.model';

@Injectable({
  providedIn: 'root'
})
export class ChosenExtraOptionService {

	public choseOptionSubj: Subject<ExtraOption> = new Subject<ExtraOption>();

	private chosenOption: ExtraOption = null;

	constructor() { }

	public getChosenOption(): ExtraOption {
		return this.chosenOption
	}

	public setChosenOption(option: ExtraOption | null): void {
		this.choseOptionSubj.next(option)

		this.chosenOption = option;
	}
}
