<div class="row" #areasContainer>
	<div class="col-6">
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="600" height="150">
			<defs>
				<linearGradient id="areaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop offset="0%" style="stop-color: #d9ecf5" />
					<stop offset="100%" style="stop-color: #628ea3" />
				</linearGradient>
			</defs>

			<g *ngIf="projectService.template.isStandalone">
				<rect x="70" y="30" [attr.width]="projectWidth" height="55" [class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
				(click)="toolBSrvc.getCurrTool() ? '' : clicked('Rear')"
				[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Rear' ? 'highlight' : '']"></rect>

				<path d="M 40 10 L 80 50 L 80 110 L 40 85 Z"
					[class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
					(click)="toolBSrvc.getCurrTool() ? '' : clicked('Left')"
					[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Left' ? 'highlight' : '']"></path>
			</g>

			<path *ngIf="!projectService.template.isStandalone" d="M 60 10 L 100 50 L 100 110 L 60 85 Z"
				[class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
				(click)="toolBSrvc.getCurrTool() ? '' : clicked('Left')"
				[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Left' ? 'highlight' : '']"></path>

			<path [attr.d]="rightPath"
				[class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
				(click)="toolBSrvc.getCurrTool() ? '' : clicked('Right')"
				[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Right' ? 'highlight' : '']"></path>

			<path [attr.d]="roofPath"
				[class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
				(click)="toolBSrvc.getCurrTool() ? '' : clicked('Roof')"
				[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Roof' ? 'highlight' : '']"></path>

			<rect x="125" y="60" [attr.width]="projectWidth" height="55" [class]="toolBSrvc.getCurrTool() ? 'area-prohibited' : 'area'"
				(click)="toolBSrvc.getCurrTool() ? '' : clicked('Front')"
				[ngClass]="[toolBSrvc.iconClass('None','None'), selected == 'Front' ? 'highlight' : '']"></rect>

			<line *ngFor="let b of barLines" style="stroke-width: 3"
			[attr.x1]="b.x1" [attr.x2]="b.x2" [attr.y1]="b.y1" [attr.y2]="b.y2"
			(click)="selectedBar(b)" [ngClass]="[b.selected ? 'barHighlight' : 'barLine']"></line>
		</svg>
	</div>

	<div class="col-6">

		<div class="row">

			<div class="col-12 executive-btns-wrapper">

				<div class="price" *ngIf="showPrice">
					<span *ngIf="priceValue; else noPrice">{{ priceValue | number:'1.2-2': currLng }} €</span>
					<ng-template #noPrice><span>---</span></ng-template>
				</div>
				<div kendoTooltip>
					<div [attr.title]="zoomInLabel">
						<button kendoButton class="k-button executive-btns" (click)="onZoom(1)"
							[disabled]="zoomInDisabled()" [svgIcon]="projectService.svgIcons.zoomInIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip>
					<div [attr.title]="zoomResetLabel">
						<button kendoButton class="k-button executive-btns" (click)="onZoom(0)"
							[svgIcon]="projectService.svgIcons.zoomActualSizeIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip>
					<div [attr.title]="zoomOutLabel">
						<button kendoButton class="k-button executive-btns" (click)="onZoom(-1)"
							[disabled]="zoomOutDisabled()" [svgIcon]="projectService.svgIcons.zoomOutIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip *ngIf="!projectService.readOnly">
					<div [attr.title]="rewindLabel">
						<button kendoButton class="k-button executive-btns" (click)="onRewind()"
							[disabled]="undoDisabled || toolBSrvc.getCurrTool()"
							[svgIcon]="projectService.svgIcons.rewindIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip *ngIf="!projectService.readOnly">
					<div [attr.title]="undoLabel">
						<button kendoButton class="k-button executive-btns" (click)="onUndo()"
							[disabled]="undoDisabled || toolBSrvc.getCurrTool()"
							[svgIcon]="projectService.svgIcons.undoIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip *ngIf="!projectService.readOnly">
					<div [attr.title]="redoLabel">
						<button kendoButton class="k-button executive-btns" (click)="onRedo()"
							[disabled]="redoDisabled || toolBSrvc.getCurrTool()"
							[svgIcon]="projectService.svgIcons.redoIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip>
					<div [attr.title]="printLabel">
						<button kendoButton class="k-button executive-btns" (click)="onPrint()"
							[disabled]="toolBSrvc.getCurrTool()" [svgIcon]="projectService.svgIcons.printIcon">
						</button>
					</div>
				</div>

				<div kendoTooltip>
					<div [attr.title]="exportLabel">
						<button kendoButton class="k-button executive-btns" (click)="onExcel()"
							[disabled]="!projectService.isValid || (this.crmService.isCrmPresent && !this.projectService.readOnly)" [svgIcon]="projectService.svgIcons.fileExcelIcon">
						</button>
					</div>
				</div>

				<app-bom #bom></app-bom>

				<div kendoTooltop>
					<div [attr.title]="userName">
						<kendo-dropdownbutton class="executive-btns k-rounded-md user-btn" [data]="userMenuItems"
							[svgIcon]="projectService.svgIcons.userIcon">
						</kendo-dropdownbutton>
					</div>
				</div>

				<div *ngIf="showPrice || this.projectService.readOnly">
					<div kendoTooltip>
						<div [attr.title]="closeLabel">
							<button kendoButton class="k-button executive-btns" (click)="onClose()"
								[disabled]="!priceValue" [svgIcon]="projectService.svgIcons.xIcon">
							</button>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="row" *ngIf="projectService.readOnly">
			<div class="col-12 executive-btns-wrapper">
				<span class="prop-read-only" i18n="Toolbar|'Read-only mode' label">Read-only mode</span>
			</div>
		</div>
		<div class="row" *ngIf="projectService.draftMode">
			<div class="col-12 executive-btns-wrapper">
				<span class="prop-draft" i18n="Toolbar|'Test modetest' label">TEST MODE</span>
			</div>
		</div>

	</div>

</div>