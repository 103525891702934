<div>
	<kendo-dialog
		[title]="colorsGroupTitle"
		*ngIf="isDialogOpened"
		(close)="action(false)"
		[width]="600"
		[maxHeight]="600"
		(click)="resetSelectedColor = true">
		
		<div *ngIf="colorsGroup" class="colors-container">
			<kendo-textbox
				style="width: 100%; margin: 0 0 16px;"
				placeholder="Search by name"
				[clearButton]="true"
				[(ngModel)]="searchName"
			>
			</kendo-textbox>

			<div *ngFor="let color of colorsGroup | searchColor:searchedColorName">
				<div
					class="color"
					[style.backgroundColor]="'#' + color.color"
					[style.backgroundImage]="'url(' + color.image + ')'"
					[ngClass]="selectedColor && color.id === selectedColor.id ? 'highlightColor' : ''"
					(click)="onColorClick(color); $event.stopPropagation()"
					>
				</div>
				<p class="description">{{ color.name }}</p>
			</div>

			<div *ngIf="(colorsGroup | searchColor:searchedColorName).length === 0">
				No colors match the criteria
			</div>
		</div>

		<kendo-dialog-actions>
			<button kendoButton (click)="action(false)" i18n="Dialogs|'Cancel' button">Cancel</button>
			<button kendoButton (click)="action(true)" primary="true" [disabled]="!selectedColor" i18n="Dialogs|'Confirm' button">Confirm</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>
