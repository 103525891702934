import {Profile} from './profile.model';
import {ElementType} from '../ToolboxModel';
import {IRect} from '../../interfaces/IRect';
import {Common} from '../common';
import {v4 as uuidv4} from 'uuid';
import { BarProfile } from './bar.model';
import { LayerLevel } from '../../interfaces/LayerLevel';

export class LedStripe extends Profile  {
	rect: IRect;
	public channels: number;

	get type(): ElementType {
		return ElementType.LedStripe;
	}

	get name(): string {
		return $localize`:Common|LED stripe:LED stripe`;
	}

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	public get waste() {
		return this._waste;
	} 

	public get bar() {
		return this._bar;
	} 

	public get stripeId() {
		return this._stripeId;
	}

	public set bar(v: BarProfile) {
		this._bar = v;
		this.setRect();
	} 

	constructor(
		_configId: string,
		private _bar: BarProfile,
		private _waste: number,
		private _stripeId: string
	) {
		super();
		this.id = this.type + "_" + uuidv4();
		this.color = 'yellow';
		this.configId = _configId;
		this.channels = 1;
		this.setRect();
	}

	private setRect() {
		const w = this._bar.rectOnRoof.w / 3;
		const x = this._bar.rectOnRoof.x + this._bar.rectOnRoof.w / 2 - w / 2

		this.rect = {
			x: Common.round(x),
			y: Common.round(this._bar.rectOnRoof.y),
			w: w,
			h: this._bar.rectOnRoof.h,
		};

		this.setLength(this._bar.getLength());
	}
}
