<div class="row" *ngIf="current.type != 'Connector' && current.type != 'RinneWand'">
	<div class="col col-4"><span i18n="Common|Length">Length</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="current.length" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row" *ngIf="current.type == 'Bar' || current.type == 'SideFinish'">
	<div class="col col-4"><span i18n="Common|Cover length">Cover length</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="projectService.template.deckelLength" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4">
		<span *ngIf="current.orientation == 'Horizontal'"><span i18n="Common|Location y">Location y</span>:</span>
		<span *ngIf="current.orientation == 'Vertical'"><span i18n="Common|Location x">Location x</span>:</span>
	</div>

	<div class="col col-8">
		<app-numeric-textbox #numLoc [value]="currentProfileLocation" [disabled]="projectService.readOnly || (current.type != 'ProfileConnector' && (changeLocationDisabled() || checkFreezingElements()))"
			[min]="minXLocation" [max]="maxXLocation" [delay]="200"
		></app-numeric-textbox> mm
	</div>
</div>

<div *ngIf="current.type == 'Bar' || current.type == 'ProfileConnector' || current.type == 'WallGutter'">
	<div class="row" *ngIf="current.locked; else unlocked">
		<div class="col col-4">
			<span class="k-icon k-i-lock"></span>
		</div>
		<div class="col col-8">
			<button kendoButton [svgIcon]="projectService.svgIcons.unlockIcon" (click)="onLock()" [disabled]="checkFreezingElements()">Unlock</button>
		</div>
	</div>

	<ng-template #unlocked>
		<div class="row">
			<div class="col col-4">
				<span class="k-icon k-i-unlock"></span>
			</div>
			<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.lockIcon" (click)="onLock()" [disabled]="checkFreezingElements()">Lock</button>
			</div>
		</div>
	</ng-template>

	<div class="row">
		<div class="col col-4">
			<span i18n="Common|Delete">Delete</span>:
		</div>
		<div class="col col-8">
			<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly || (current.type != 'ProfileConnector' && checkFreezingElements())" i18n="Common|Delete">Delete</button>
		</div>
	</div>
</div>

<card-cuttingonsite [current]="current" [disabled]="checkFreezingElements()"></card-cuttingonsite>

<div class="row" *ngIf="checkFreezingElements()">
	<div class="col col-4">
		<span class="k-icon k-i-x-circle"></span>
		Frozen:
	</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.xCircleIcon" (click)="openDialog(unfreezingMess)" [disabled]="projectService.readOnly" i18n="Common|Unfreeze">Unfreeze</button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div *ngIf="current.type == 'WallProfile'">
		<div class="row">
			<div class="col col-4">Tag:</div>
			<div class="col col-8">{{ current.tag }}</div>
		</div>
		<div class="row">
			<div class="col col-4">Id:</div>
			<div class="col col-8">{{ current.id }}</div>
		</div>
		<div class="row">
			<div class="col col-4">Columns:</div>
			<div class="col col-8">
				<p *ngFor="let c of current.columns">
					{{ c.tag}}: {{ c.id }}
				</p>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="current.type != 'WallProfile'">
		<div class="col col-4">Min location:</div>
		<div class="col col-8">{{ minXLocation }}</div>
	</div>
	<div class="row" *ngIf="current.type != 'WallProfile'">
		<div class="col col-4">Max location:</div>
		<div class="col col-8">{{ maxXLocation }}</div>
	</div>
</div>

<app-confirm-dialog [dialogOpened]="dialogOpened" [dialogMessage]="dialogMessage" (dialogAction)="onDialogAction($event)"></app-confirm-dialog>
