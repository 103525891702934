import { Component } from '@angular/core';
import { SvgAreaBaseComponent } from '../svg-area-base/svg-area-base.component';

@Component({
	selector: 'g[app-svg-front]',
	templateUrl: './svg-front.component.html',
	styleUrls: ['./svg-front.component.css']
})
export class SvgFrontComponent extends SvgAreaBaseComponent {
}
