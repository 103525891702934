import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {ColorOfPalette} from '../../_core/models/colors/color.model';

@Component({
  selector: 'app-colors-group-dialog',
  templateUrl: './colors-group-dialog.component.html',
  styleUrls: ['./colors-group-dialog.component.css']
})
export class ColorsGroupDialogComponent implements OnInit {

	private _opened: boolean = false;
	@Input() set isDialogOpened(v: boolean) {
		this._opened = v;
	}
	get isDialogOpened(): boolean {
		return this._opened;
	}

	@Input() colorsGroup: ColorOfPalette[];
	@Input() colorsGroupTitle: string;
	@Input() set resetSelectedColor(status) {
		if (status) {
			this.selectedColor = null;
		}
	};
	@Output() dialogAction = new EventEmitter();

	@Input() selectedColor: ColorOfPalette;

	typedColorName: string = '';
	searchedColorName: string = '';
	get searchName(): string {
		return this.typedColorName;
	};
	set searchName(value: string) {
		this.typedColorName = value;
		setTimeout(() => {
			this.searchedColorName = this.typedColorName;
		}, 1000)
	};

	constructor() { }

	ngOnInit(): void {
	}

	onColorClick(color) {
		this.selectedColor = color;
	}

	public action(status: boolean) {
		if (status) {
			this.dialogAction.next(this.selectedColor)
		} else {
			this.dialogAction.next()
		}
		this.typedColorName = '';
		this.searchedColorName = '';
		this.selectedColor = null;
	}
}
