import { Component, Input, ViewChild } from '@angular/core';
import { CardType, IPropertyCard } from 'src/app/_core/interfaces/IPropertyCard';
import { ProjectService } from 'src/app/_core/services/project.service';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { Wall } from 'src/app/_core/models/walls/wall.model';
import { WallCreator } from 'src/app/_core/models/walls/wall-creator';
import { AnySide, ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { LeftSideService } from 'src/app/_core/services/areas/left-side.service';
import { RightSideService } from 'src/app/_core/services/areas/right-side.service';
import { Common } from 'src/app/_core/models/common';

@Component({
	selector: 'card-wall',
	templateUrl: './card-wall.component.html',
	styleUrls: ['../card.component.css']
})
export class CardWallComponent implements IPropertyCard {
	@ViewChild('numHeight', { static: true }) numHeight: NumTbxComponent;
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: true }) numLocY: NumTbxComponent;

	type: CardType = CardType.Wall;
	current: Wall;

	private creator: WallCreator;

	@Input() public set items(items: any[] | null) {
		if (items !== null) {
			this.initItem(items[0]);			
		} else {
			return;
		}

		if (this.numHeight.valueChanged.observers.length == 0) {
			this.numHeight.valueChanged.subscribe(v => {
				this.creator.setHeight(this.current, v);
				this.numHeight.rebind(Math.round(this.current.height));
			});
		}
		if (this.numWidth.valueChanged.observers.length == 0) {
			this.numWidth.valueChanged.subscribe(v => {
				this.creator.setWidth(this.current, v);
				this.numWidth.rebind(Math.round(this.current.width));
			});
		}
		if (this.numLocX.valueChanged.observers.length == 0) {
			this.numLocX.valueChanged.subscribe(v => {
				this.creator.setLocationX(this.current, v);
				this.numLocX.rebind(Math.round(this.current.locationX));
			});
		}
		if (this.numLocY.valueChanged.observers.length == 0) {
			this.numLocY.valueChanged.subscribe(v => {
				this.creator.setLocationY(this.current, v);
				this.numLocY.rebind(Math.round(this.current.locationY));
			});
		}
	}

	get maxLocY() {
		return Math.round(this.current.maxLocationY);
	}

	get minLocY() {
		return Math.round(this.current.minLocationY);
	}

	get maxLocX() {
		return Math.round(this.current.maxLocationX);
	}

	get minLocX() {
		return Math.round(this.current.minLocationX);
	}

	get currentConfigId() {
		return this.current.def.id;
	}
	set currentConfigId(v: string) {
		const def = this.projectService.template.walls.find(d => d.id == v);
		this.current.changeDef(def);
		this.projectService.emitChange();
	}


	constructor(public projectService: ProjectService) {}

	private initItem(item: Wall) {
		this.current = item;
		this.creator = WallCreator.fromInstance(this.projectService, this.projectService.CurrentAreaService, this.current);
		this.current.refreshSurround(this.projectService.profileService);
	}

	deleteElement() {
		this.projectService.deleteElement(this.current);
	}


	get currLng(): string {
		return window.document.documentElement.lang;
	}

	changeLeftovers(lo: string) {
		this.current.leftovers = AnySide[lo];
		this.projectService.emitChange();
	}

	get dimChangeDisabled() {
		// if (this.current.shape.isDiamond) {
		// 	return true;
		// }
		var neib = this.projectService.profileService.findVerticalNeib(this.current, ElementSide.Bottom);
		return neib != null;
	}

	getWallTypeData() {
		return this.projectService.template.walls.filter(w =>
			Common.isInLocation(w.locations, this.projectService.currentArea)
		);

	}

}
