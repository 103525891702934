import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AreaType } from '../../_core/interfaces/IAreaType';
import { ToolboxItemService } from '../../_core/services/toolbox-item.service';
import { ProjectService } from '../../_core/services/project.service';
import { AuthService } from 'src/app/_core/services/auth.service';
import { SvgParams, SvgZoomParams, Tags } from 'src/app/_core/constants/constants';
import { CrmService } from 'src/app/_core/services/crm.service';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/_core/models/common';
import { BomComponent, PopupExportType } from '../bom/bom.component';
import { ApiService } from 'src/app/_core/services/api.service';
import { saveAs } from '@progress/kendo-file-saver';
import { DatePipe } from '@angular/common';
import { IPoint } from 'src/app/_core/interfaces/IPoint';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { ILine } from 'src/app/_core/interfaces/ILine.interface';
import { IBarLine } from 'src/app/_core/interfaces/IBarLine';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';

@Component({
	selector: 'app-areas',
	templateUrl: './areas.component.html',
	styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {
	@ViewChild('bom') bom: BomComponent;
	@Output() areaClick = new EventEmitter<AreaType>();
	@Output() zoomClick = new EventEmitter<number>();
	@Output() undoClick: EventEmitter<boolean> = new EventEmitter();
	@Output() redoClick: EventEmitter<any> = new EventEmitter();
	@Output() resetClick: EventEmitter<any> = new EventEmitter();
	@Output() barSelection: EventEmitter<any> = new EventEmitter();
	@Input() selected: AreaType;
	@Input() priceValue: number;
	@Input() showPrice: boolean;
	@Input() undoDisabled: boolean;
	@Input() redoDisabled: boolean;

	isShowDimensions: boolean = false;
	currentZoom: number = SvgZoomParams.INIT;

	zoomInLabel: string = $localize`:Toolbar|'Zoom in' button tooltip:Zoom in`;
	zoomOutLabel: string = $localize`:Toolbar|'Zoom out' button tooltip:Zoom out`;
	zoomResetLabel: string = $localize`:Toolbar|'Reset layout' button tooltip:Reset layout`;
	undoLabel: string = $localize`:Toolbar|'Undo' button tooltip:Undo`;
	redoLabel: string = $localize`:Toolbar|'Redo' button tooltip:Redo`;
	rewindLabel: string = $localize`:Toolbar|'Rewind' button tooltip:Rewind`;
	printLabel: string = $localize`:Toolbar|'Print project' button tooltip:Print project`;
	closeLabel: string = $localize`:Toolbar|'Close Designer' button tooltip:Close Designer`;
	scale: number = 0.02;
	rightCoordinates: IPoint[] = [];
	roofCoordinates: IPoint[] = [];
	barLines: IBarLine[] = [];
	projectWidth: number;

	get exportLabel(): string {
		if (this.projectService.isValid) {
			if((this.projectService.readOnly && this.crmService.isCrmPresent) || !this.crmService.isCrmPresent){
				return $localize`:Toolbar|'Export to excel' button tooltip:Export to Excel`;
			} else {
				return $localize`:Toolbar|'Export to excel' button tooltip:Project is editable, export is disabled`;
			}
		} else {
			return $localize`:Toolbar|'Export to excel' button disabled tooltip:Project is not valid, export is disabled`;
		}
	}

	public get userName(): string {
		return this.authService.user?.name ?? $localize`:Toolbar|'not logged in' menu item:not logged in`;
	}

	public get userMenuItems(): any {
		return [
			{ text: this.userName },
			{
				text: $localize`:Toolbar|'Log out' menu item:Log out`,
				click: () => { this.logout(); },
			},
		]
	}

	constructor(
		public toolBSrvc: ToolboxItemService,
		public projectService: ProjectService,
		private authService: AuthService,
		public crmService: CrmService,
		private api: ApiService) { }
	 
	ngOnInit(): void {
		var z = SvgZoomParams.INIT;
		var s = localStorage.getItem("zoom");
		if (s) {
			z = parseFloat(s);
		}
		this.currentZoom = z;
		this.zoomClick.emit(z);

		this.projectService.elementsChanged.subscribe(p => {
			if (p) {
				this.calculatePaths();
			}
		});
		this.projectService.projectReady.subscribe(p => {
			if (p) {
				this.calculatePaths();

				if (this.projectService.erpExportMode) {
					this.bom.togglePopup(true);
				}
			}
		});
		
	}

	calculatePaths(){
		var width = 120; //this.projectService.template.width * this.scale;

		this.rightCoordinates = [];
		this.rightCoordinates.push(
			{ x: 120 + width, y: 10 },
			{ x: 120 + width, y: 85},
			{ x: 165 + width, y: 110},
			{ x: 165 + width, y: 50}
		);

		this.projectWidth = 120; //this.projectService.template.width * this.scale;

		this.roofCoordinates = [];
		this.roofCoordinates.push(
			{ x: 85, y: 10 },
			{ x: 85 + width, y: 10},
			{ x: 125 + width, y: 50},
			{ x: 125, y: 50}
		);
		
		this.barLines = [];

		return;

		var barSpace = width / (this.projectService.profileService.roofElements[ElementType.Bar].length + 1);
		var index = 0;
		
		// left side finish
		this.barLines.push({
			x1: 85 + index * barSpace,
			y1: 10,
			x2: 125 + index * barSpace,
			y2: 50,
			barId: this.projectService.profileService.roofElements[ElementType.SideFinish].find(s => s.tag == Tags.LEFT_SIDEFINISH_TAG).id,
			selected: true,
			side: ElementSide.Left
		});
		index++;

		// middle bars
		this.projectService.profileService.roofElements[ElementType.Bar].forEach(b => {
			var s = ElementSide.Right;
			if(index <= this.projectService.profileService.roofElements[ElementType.Bar].length / 2){
				s = ElementSide.Left;
			}
			this.barLines.push({
				x1: 85 + index * barSpace,
				y1: 10,
				x2: 125 + index * barSpace,
				y2: 50,
				barId: b.id,
				selected: false,
				side: s
			});
			index++;
		});

		// right side finish
		this.barLines.push({
			x1: 85 + index * barSpace,
			y1: 10,
			x2: 125 + index * barSpace,
			y2: 50,
			barId: this.projectService.profileService.roofElements[ElementType.SideFinish].find(s => s.tag == Tags.RIGHT_SIDEFINISH_TAG).id,
			selected: true,
			side: ElementSide.Right
		});
	}

	get roofPath(){
		return ProjectService.getPath(this.roofCoordinates);
	}

	get rightPath(){
		return ProjectService.getPath(this.rightCoordinates);
	}

	clicked(type: string) {
		this.selected = AreaType[type];
		this.areaClick.emit(AreaType[type]);
	}

	logout() {
		this.authService.logout();
	}

	onPrint(){
		this.projectService.printProjectSubj.next();
	}

	onExcel() {
		this.bom.getIndividualElements();
		const boms = this.bom.getBoms(PopupExportType.fullConstruction);
		const toSend = this.bom.collectBomToSend(boms);
		this.api.getExcel(this.projectService.productId, this.projectService.brandName, toSend).subscribe(
			(data: Blob) => {
				const oi = this.projectService.info?.orderNumber ?? this.projectService.orderCode;
				const cn = this.projectService.info?.customerName;
				const dt: DatePipe = new DatePipe('en');
				const fd = dt.transform(new Date(), 'dd.MM.YYYY');
				
				const fn = cn ? `${oi} - ${cn} - ${fd}.xlsx` : `${oi} - ${fd}.xlsx`;

				this.projectService.showTemporaryMessageSubj.next(
					{
						message: $localize`:BOM|Export to Excel success message:Export to Excel done`, hideAfter: 5000, style: 'success'
					}
				);
				saveAs(data, fn);
			})
	}

	onZoom(factor: number) {
		if (factor == 0) {
			this.currentZoom = 1;
			localStorage.setItem("zoom", "1");
			this.resetClick.emit();
		} else {
			this.currentZoom += factor * SvgZoomParams.STEP;
			localStorage.setItem("zoom", Common.round(this.currentZoom).toString());
			this.zoomClick.emit(this.currentZoom);
		}
	}

	currentZoomPerc(factor: number) {
		var tempZoom = this.currentZoom + factor * SvgZoomParams.STEP;
		return Math.round(100 + ((tempZoom - 1) * 100)).toString();
	}

	zoomInDisabled() {
		return this.currentZoom >= SvgZoomParams.MAX;
	}

	zoomOutDisabled() {
		return this.currentZoom <= SvgZoomParams.MIN;
	}

	onClose() {
		this.crmService.sendCloseRequest();
	}

	onUndo() {
		this.undoClick.emit(false);
	}

	onRedo() {
		this.redoClick.emit();
	}

	onRewind(){
		this.undoClick.emit(true);
	}

	get isLocalhost() {
		return environment.localhost;
	}
	get currLng(): string {
		return window.document.documentElement.lang;
	}

	selectedBar(bar: IBarLine){
		bar.selected = true;
		this.barLines.forEach(b => {
			if(b.side == bar.side && b.barId != bar.barId){
				b.selected = false;
			}
		});

		this.barSelection.emit(bar);
	}

}
