import {Injectable} from '@angular/core';
import {GlassResizeMode} from 'src/app/_core/interfaces/IGlassResizeMode';
import {SvgParams} from 'src/app/_core/constants/constants';
import {Glass} from '../../models/glasses/glass.model';
import {ProjectService} from '../project.service';
import {LocationService} from '../location.service';
import {Subject} from 'rxjs';
import {ElementType} from 'src/app/_core/models/ToolboxModel';
import {GlassWall} from '../../models/glasses/glassWall.model';
import {GlassPart} from '../../models/glasses/glassPart.model';
import {GlassWallDiamond} from '../../models/glasses/glassWallDiamond.model';
import {LeftSideService} from '../areas/left-side.service';
import {RoofWindow} from '../../models/glasses/roofWindow.model';
import { RoofService } from '../areas/roof.service';
import { GlassWallService } from '../glass-walls/glass-wall.service';
import { SideBaseService } from '../areas/side-base.service';
import { AreaType } from '../../interfaces/IAreaType';
import { GlassWallDiamondService } from '../glass-walls/glass-wall-diamond.service';
import { GlassPartDiamond } from '../../models/glasses/glassDiamondPart';

@Injectable({
	providedIn: 'root'
})
export class ChosenGlassService {
	public isWidthDifferentSubj: Subject<boolean> = new Subject<boolean>();
	public isHeightDifferentSubj: Subject<boolean> = new Subject<boolean>();
	public chosenGlassSubj: Subject<Glass[] | GlassWall[] | GlassPart[] | null> = new Subject<Glass[]>();

	private chosenGlass: any[] = null;
	
	constructor(
		public projectService: ProjectService,
		public leftSideService: LeftSideService,
		public locationService: LocationService,
		private roofService: RoofService,
		private diamondService: GlassWallDiamondService,
		private wallService: GlassWallService
	) { }

	public getChosenGlass(): Glass[] {
		return this.chosenGlass;
	}
	public setChosenGlass(glasses: Glass[] | GlassWall[] | GlassPart[] | null): void {
		this.chosenGlassSubj.next(glasses)

		if (glasses !== null) {
			this.chosenGlass = glasses;
			this.compareSizes('width', this.isWidthDifferentSubj);
			this.compareSizes('height', this.isHeightDifferentSubj);

			if (this.chosenGlass[0] instanceof GlassWall) {
				this.wallService.currentAreaService = this.projectService.CurrentAreaService as SideBaseService;
			} else if (this.chosenGlass[0] instanceof GlassWallDiamond) {
				this.diamondService.currentAreaService = this.projectService.CurrentAreaService as SideBaseService;
			}

		} else {
			this.chosenGlass = null;
		}

  }

	private compareSizes(parameter: string, subject: Subject<boolean>) {
		const sameValueItems = this.chosenGlass.filter((item: Glass) => item[parameter] === this.chosenGlass[0][parameter])
		if (this.chosenGlass.length !== sameValueItems.length) {
			subject.next(true);
		} else {
			subject.next(false);
		}
	}

	public getWidth(): number {
		return this.chosenGlass[0].width
	}
	public setWidth(value: number): void {
		if (!this.chosenGlass[0].validate(value, this.chosenGlass[0].height)) {
			return;
		}

		this.recalculateElementsDisplaying(value);
		this.setValueToWidthProperty(value);
	}

	private recalculateElementsDisplaying(value: number) {
		switch (this.chosenGlass[0].type) {
			case ElementType.Glass:
				this.recalculateGlassDisplaying(value);
				break;
		}
	}

	private setValueToWidthProperty(value: number) {
		switch (this.chosenGlass[0].type) {
			case ElementType.Glass:
				this.chosenGlass.forEach((glass: Glass) => {
					glass.width = value;
					this.projectService.adjustCurrent(glass);
				});
				break;

			case ElementType.GlassWall:
				this.chosenGlass.forEach((glassWall: GlassWall) => {
					this.wallService.setWidth(glassWall, value);
				});
				break

			case ElementType.GlassWallDiamond:
				this.chosenGlass.forEach((diamond: GlassWallDiamond) => {
					this.diamondService.setWidth(diamond, value);
				});
				break
		}
	}

	private recalculateGlassDisplaying(value: number) {
		const currArRect = this.projectService.getCurrentAreaRect();
		var glass = this.chosenGlass[0];
		var diff = glass.width - value;
		var d = diff * SvgParams.SCALE;

		switch (glass.resizeMode) {
			case GlassResizeMode.Left:
				if (!glass.isFirst) {
					glass.leftBar[currArRect].x += d;
				} else {
					this.projectService.addLeftGlass(glass);
				}
				glass.rect.w -= d;
				glass.rect.x += d;

				this.projectService.adjustLeft(glass, diff);
				break;

			case GlassResizeMode.Right:
				if (!glass.isLast) {
					glass.rightBar[currArRect].x -= d;
				} else {
					this.projectService.addRightGlass(glass, value * SvgParams.SCALE);
				}
				let adjustRight = d > 0 ? true : !this.projectService.deleteRightBar(glass.leftBar);
				if (adjustRight)
					this.projectService.adjustRight(glass, diff);
				break;

			case GlassResizeMode.Both:
				diff = diff / 2;
				d = d / 2;
				glass.rect.x += d;
				if (!glass.isFirst) {
					glass.leftBar[currArRect].x += d;
				} else {
					this.projectService.addLeftGlass(glass);
				}
				if (!glass.isLast) {
					glass.rightBar[currArRect].x -= d;
				} else {
					this.projectService.addRightGlass(glass, value * SvgParams.SCALE);
				}
				this.projectService.adjustLeft(glass, diff);
				this.projectService.adjustRight(glass, diff);
				break;
		}
	}

	public getFrontHeight(): number {
		if (this.chosenGlass[0].type == ElementType.GlassWallDiamond) {
			return (this.chosenGlass[0] as GlassWallDiamond).frontHeight;
		}
		return 0;
	}
	public getHeight(): number {
		if (this.chosenGlass[0].type == ElementType.Glass) {
			return this.chosenGlass[0].getHeight(this.roofService);
		}
		return this.chosenGlass[0].height;
	}
	public setHeight(value: number): void {
		switch (this.chosenGlass[0].type) {
			case ElementType.Glass:
				this.chosenGlass.forEach((g: Glass) => {
					g.height = value;
				});
				break;

			case ElementType.GlassWallDiamond:				
				this.diamondService.setHeight(this.chosenGlass[0] as GlassWallDiamond, value);
				break;

			case ElementType.GlassWall:
				this.wallService.setHeight(this.chosenGlass[0] as GlassWall, value);
				break
		}
	}

	public getLocationX(): number {
		switch (this.chosenGlass[0].type) {
			case ElementType.GlassWallDiamond:
			case ElementType.GlassPartDiamond:
				return (this.chosenGlass[0].points[SvgParams.LEFT_TOP_POINT_NUM].x - SvgParams.START_X) / SvgParams.SCALE;

			default:
				return (this.chosenGlass[0].rect.x - SvgParams.START_X) / SvgParams.SCALE;
		}
	}

	public setLocationX(value: number) {
		const glass = this.chosenGlass[0];

		switch (glass.type) {
			case ElementType.GlassWall:
				this.wallService.setLocationX(glass as GlassWall, value);
				break;

			case ElementType.GlassWallDiamond:
				this.diamondService.setLocationX(glass as GlassWallDiamond, value);
				break;
		}
	}

	public getLocationY(): number {
		switch (this.chosenGlass[0].type) {
			case ElementType.GlassWallDiamond:
			case ElementType.GlassPartDiamond:
				const p = this.projectService.currentArea == AreaType.Left ? SvgParams.LEFT_TOP_POINT_NUM : SvgParams.RIGHT_TOP_POINT_NUM;
				return (this.chosenGlass[0].points[p].y - SvgParams.START_X) / SvgParams.SCALE;

			default:
				return (this.chosenGlass[0].rect.y - SvgParams.START_Y) / SvgParams.SCALE;
		}
	}
	public setLocationY(value: number) {
		const chosenElem = this.chosenGlass[0];

		switch (chosenElem.type) {
			case ElementType.GlassWall:
				this.wallService.setLocationY(chosenElem as GlassWall, value);
				break;

			case ElementType.RoofWindow:
			case ElementType.RoofGutter:
				const rw = (chosenElem as RoofWindow);
				rw.rect.y = SvgParams.START_Y + value * SvgParams.SCALE;

				this.locationService.adjustRoofwindow(rw);
				
				if (rw.connectorTop.visible) {
					if (rw.topGlass) {
						const newY = this.roofService.getWorkAreaPoints()[SvgParams.LEFT_TOP_POINT_NUM].y;
						rw.topGlass.rect.h = (rw.minYPoint - newY) + rw.padding * SvgParams.SCALE
					} else {
						this.roofService.createTopGlassForWindow(this.projectService.template.glassId, rw);
					}
				} else {
					this.projectService.deleteElement(rw.topGlass);
					rw.topGlass = null;
				}

				if (rw.connectorBottom.visible) {
					if (rw.bottomGlass) {
						const front = this.projectService.profileService.roofElements[ElementType.Front][0];
						const newY = rw.maxYPoint - rw.padding * SvgParams.SCALE;
						rw.bottomGlass.rect.h -= newY - rw.bottomGlass.rect.y;
						rw.bottomGlass.rect.y = newY;
					} else {
						this.roofService.createBottomGlassForWindow(this.projectService.template.glassId, rw);
					}
				} else {
					this.projectService.deleteElement(rw.bottomGlass);
					rw.bottomGlass = null;
				}

				break;
			default:
				break;
		}
	}

	public setType(newCatId: string): void {
		const glassInfo = this.getNewGlassInfoDependsOnType(newCatId);

		this.chosenGlass.forEach(g => {
			g.configId = newCatId;
			if (g.type !== ElementType.RoofWindow) {
				g.color = glassInfo.color;
			}
			if (g.type == ElementType.GlassWall) {
				(g as GlassWall).parts.forEach( p => {
					p.color = glassInfo.color;
				});
			}
			if (g.type == ElementType.GlassWallDiamond) {
				(g as GlassWallDiamond).parts.forEach(p => {
					p.color = glassInfo.color;
				});
			}
			if (g.type == ElementType.GlassPart) {
				const newDef = this.projectService.template.wallGlasses.find(d => d.id == newCatId);
				(g as GlassPart).master.def = newDef;
				(g as GlassPart).master.parts.forEach( p => {
					p.color = newDef.color;
					p.configId = newDef.id;
				});
			}
			if (g.type == ElementType.GlassPartDiamond) {
				const newDef = this.projectService.template.diamondGlasses.find(d => d.id == newCatId);
				(g as GlassPartDiamond).master.def = newDef;
				(g as GlassPartDiamond).master.parts.forEach( p => {
					p.color = newDef.color;
					p.configId = newDef.id;
				});
			}
		});

	}

	private getNewGlassInfoDependsOnType(newCatId: string) {
		switch (this.chosenGlass[0].type) {
			case ElementType.Glass:
				return this.projectService.template.glasses.find(r => r.id == newCatId);

			case ElementType.GlassWall:
				return this.projectService.template.wallGlasses.find(r => r.id == newCatId);

			case ElementType.GlassWallDiamond:
				return this.projectService.template.diamondGlasses.find(r => r.id == newCatId);

			case ElementType.RoofWindow:
				return this.projectService.template.roofWindows.find(r => r.id == newCatId);

			case ElementType.GlassPart:
			case ElementType.GlassPartDiamond:
				if ((this.chosenGlass[0] as GlassPart).master.type === ElementType.GlassWall) {
					return this.projectService.template.wallGlasses.find(r => r.id == newCatId);
				} else if ((this.chosenGlass[0] as GlassPart).master.type === ElementType.GlassWallDiamond) {
					return this.projectService.template.diamondGlasses.find(r => r.id == newCatId);
				}
		}
	}

	public setLock(isLocked: boolean) {
		this.chosenGlass.forEach((g: Glass) => {
			g.locked = isLocked;
		});
	}

}
