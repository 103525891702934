import { ICircle } from "../../interfaces/ICircle";
import { ApiService } from "../../services/api.service";
import { ProjectService } from "../../services/project.service";
import { LedPattern } from "../leds-patterns";
import { LED } from "../profiles/leds.model";
import { ElementType } from "../ToolboxModel";

export class LedStoreModel {

	circle: ICircle;
	circleNumber: number;
	
	constructor(l: LED) {
		this.circle = l.circle;
		this.circleNumber = l.circleNumber	
	}
}

export class LedSpotStoreModel {

	leds: LedStoreModel[] = [];
	configId: string;
	oldConfigId: string;
	patternId: string

	constructor(pattern: LedPattern, leds: LED[]) {
		this.configId = pattern.configId;
		this.patternId = pattern.patternId;
		leds.forEach(l => {
			this.leds.push(new LedStoreModel(l));
		});
	}

	public static restore(c: LedSpotStoreModel, projectService: ProjectService, api: ApiService) {
		if (!c) {
			return;
		}
		c.leds.forEach(l => {
			projectService.profileService.roofElements[ElementType.Led].push(new LED(l.circleNumber, l.circle));
		});

		api.getLedsPatterns(projectService.productId, projectService.brandName).subscribe(result => {
			projectService.ledPattern = result.patterns.find(p => p.patternId == c.patternId);
		});

	}

}
