import { AreaType } from "../../interfaces/IAreaType";
import { ElementSide, Montage } from "../../interfaces/IElementSide";
import { IMarquiseLine } from "../../interfaces/IMarquiseLine.interface";
import { IPoint } from "../../interfaces/IPoint";
import { ChosenMarquiseService } from "../../services/chosen-elements/chosen-marquise.service";
import { ProjectService } from "../../services/project.service";
import { Color } from "../colors/color.model";
import { MarquiseFront } from "../marquises/marquise-front.model";
import { MarquiseLeft } from "../marquises/marquise-left.model";
import { MarquiseRear } from "../marquises/marquise-rear.model";
import { MarquiseRight } from "../marquises/marquise-right.model";
import { MarquiseTop } from "../marquises/marquise-top.model";
import { MarquiseVertical } from "../marquises/marquise-vertical.model";
import { MarquisesEngine } from "../marquises/marquises-engine.model";
import { FrameProfile } from "../profiles/frame.model";
import { Profile } from "../profiles/profile.model";
import { ElementType } from "../ToolboxModel";
import { ISize } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";
import { FrameStoreModel } from "./frame-store.model";

export class MarquiseEngineStoreModel extends ElementStoreModel {
	location: ElementSide;

	constructor(e: MarquisesEngine) {
		super(e.id, e.configId);

		this.rectOnRoof = e.rect;
		this.location = e.location;
	}
}

export class MarquiseStoreModel extends ElementStoreModel {
	cover: number;
	initialDepth: number;
	depth: number;
	engine: MarquiseEngineStoreModel;
	extension: number;
	firstGlassId: string;
	lastGlassId: string;
	insertionLength: number;
	type: ElementType;

	mlineOnRoof: IMarquiseLine;
	mlineOnFront: IMarquiseLine;
	mlineOnLeft: IMarquiseLine;
	mlineOnRight: IMarquiseLine;

	handlerId: string;
	colorConfigId: string;
	isExpandedBack: boolean;
	isExpandedLeft: boolean;
	isExpandedRight: boolean;

	size: ISize;

	area: AreaType;
	leftFrame: FrameStoreModel;
	rightFrame: FrameStoreModel;
	topFrame: FrameStoreModel;
	montage: Montage;

	constructor(m: MarquiseTop | MarquiseVertical, projectService: ProjectService) {
		super(m.id, m.configId);

		this.type = m.type;
		this.engine = new MarquiseEngineStoreModel(m.engine);
		this.handlerId = m.handler.id;

		if (m instanceof MarquiseVertical) {
			this.firstGlassId = m.leftProfile.id;
			this.lastGlassId = m.rightProfile.id;
			this.area = m.area;
			this.montage = m.montage;
			this.storeLocationAndSize(m);
			if (m.leftProfile.type == ElementType.Frame) {
				this.leftFrame = new FrameStoreModel(m.leftProfile as FrameProfile);
			}
			if (m.rightProfile.type == ElementType.Frame) {
				this.rightFrame = new FrameStoreModel(m.rightProfile as FrameProfile);
			}
			if (m.topFrame) {
				this.topFrame = new FrameStoreModel(m.topFrame);
			}

			switch(m.area) {
				case AreaType.Rear:
				case AreaType.Front:
					this.size = { width: Math.round(m.width), height: Math.round(m.height), depth: undefined };
					break;
				case AreaType.Left:
				case AreaType.Right:
					this.size = { width: undefined, height: Math.round(m.height), depth: Math.round(m.width) };
					break;
			}

		} else {

			this.cover = m.getCover();
			this.initialDepth = m.getUIDepth();
			this.depth = m.getErpDepth();
			this.extension = Math.round(m.getExtension());
			this.firstGlassId = m.firstGlass.id;
			this.lastGlassId = m.lastGlass.id;
			this.insertionLength = m.insertionLength;

			this.mlineOnFront = m.lineOnFront;
			this.mlineOnRoof = m.lineOnRoof;
			this.mlineOnLeft = m.lineOnLeft;
			this.mlineOnRight = m.lineOnRight;

			this.isExpandedBack = m.isExpandedBack;
			this.isExpandedLeft = m.isExpandedLeft;
			this.isExpandedRight = m.isExpandedRight;

			const rear = projectService.template.getDefaultRear();
			const marquiseTopBottomCorrection: number = rear.marquiseTopBottomCorrection;

			this.size = { width: Math.round(m.getWidth()), depth: Math.round(this.depth), height: undefined };
			this.area = AreaType.None;
		}

		if (m.fabric?.id) {
			const c = projectService.template.paletteColors.find(p => p.id == m.fabric.id);
			this.colorConfigId = m.fabric.id;
		}

	}

	public static restore(m: MarquiseStoreModel, projectService: ProjectService, points: IPoint[]) {
		const info = projectService.template.marquises.find(q => q.id == m.configId);

		let newMarq: MarquiseTop | MarquiseVertical;

		if (m.type != ElementType.MarquiseVertical) {
			const firstGlass = projectService.profileService.roofElements[ElementType.Glass].find(q => q.id == m.firstGlassId);
			const lastGlass = projectService.profileService.roofElements[ElementType.Glass].find(q => q.id == m.lastGlassId);

			const rear = projectService.template.getDefaultRear();
			newMarq = new MarquiseTop(rear, m.initialDepth, info, projectService.template.isStandalone, projectService.template.dropAngle, m.type, firstGlass.leftBar, lastGlass.rightBar);
			newMarq.lineOnFront = m.mlineOnFront;
			newMarq.lineOnRoof = m.mlineOnRoof;
			newMarq.lineOnLeft = m.mlineOnLeft;
			newMarq.lineOnRight = m.mlineOnRight;

			newMarq.isExpandedBack = m.isExpandedBack;
			newMarq.isExpandedLeft = m.isExpandedLeft;
			newMarq.isExpandedRight = m.isExpandedRight;

			newMarq.calculateMarkers();

			projectService.profileService.roofElements[m.type].push(newMarq);
			projectService.profileService.frontElements[m.type].push(newMarq);
			projectService.profileService.leftSideElements[m.type].push(newMarq);
			projectService.profileService.rightSideElements[m.type].push(newMarq);

			ChosenMarquiseService.setCoordinates(newMarq, 0, projectService)

		} else {
			let leftProfile: Profile;
			let rightProfile: Profile;
			let topFrame: FrameProfile;

			if (m.leftFrame?.rect) { // Frame profile
				leftProfile = FrameStoreModel.restore(m.leftFrame, projectService);
			} else { // column
				leftProfile = projectService.profileService.roofElements[ElementType.Column].find(q => q.id == m.firstGlassId);
			}

			if (m.rightFrame?.rect) { // Frame profile
				rightProfile = FrameStoreModel.restore(m.rightFrame, projectService);
			} else { // column
				rightProfile = projectService.profileService.roofElements[ElementType.Column].find(q => q.id == m.lastGlassId);
			}

			switch (m.area) {
				case AreaType.Front:
					newMarq = new MarquiseFront(projectService, info, leftProfile, rightProfile, null, AreaType.Front, points);
					newMarq.montage = m.montage;
					break;
				case AreaType.Rear:
					newMarq = new MarquiseRear(projectService, info, leftProfile, rightProfile, null, AreaType.Front, points);
					newMarq.montage = m.montage;
					break;
				case AreaType.Left:
					topFrame = FrameStoreModel.restore(m.topFrame, projectService);
					newMarq = new MarquiseLeft(projectService, info, leftProfile, rightProfile, topFrame, AreaType.Left, points);
					newMarq.montage = m.montage;
					break;
				case AreaType.Right:
					topFrame = FrameStoreModel.restore(m.topFrame, projectService);
					newMarq = new MarquiseRight(projectService, info, leftProfile, rightProfile, topFrame, AreaType.Right, points);
					newMarq.montage = m.montage;
					break;
				default:
					break;					
			}

			projectService.profileService.addVertical(newMarq as MarquiseVertical);
		}

		newMarq.handler = info.handlers.find(h => h.id == m.handlerId);

		const engine = info.engines.find(e => e.id == m.engine.configId);
		newMarq.engine.setNewEngineParameters(engine);
		newMarq.engine.rect = m.engine.rectOnRoof;
		newMarq.engine.location = m.engine.location;

		const color = projectService.template.paletteColors.find(p => p.id == m.colorConfigId);
		(newMarq.fabric as Color | {}) = color;
		
		return newMarq;
	}

}
