<div class="text-center" style="padding: 10px; display: flex; flex-wrap: wrap; justify-content: center;">
		<h5>{{data.name}}</h5>
		<div #anchor [style.backgroundImage]="'url(' + data.thumbnail + ')'" (click)="selectedWizardCard()" [ngClass]="{'select':selected}" class="image">
			<svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
					class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
					<path fill-rule="evenodd"
							d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
			</svg>
		</div>
</div>
<!-- <kendo-popup [animate]="animate" [anchor]="anchor" popupClass="content popup" *ngIf="show">
		<img src="{{data.photo}}">
		<p>{{data.photoDescription}}</p>
</kendo-popup> -->


<div class="modal" tabindex="-1" role="dialog"(click)="closePopup()"
		[ngClass]="{'display-block': displayStyle == 'block','display-none': displayStyle == 'none'}">
		<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
						<div class="modal-header">
								<p class="text-center">{{data.photoDescription}}</p>
								<span aria-hidden="true" class="close" (click)="closePopup()">&times;</span>
						</div>
						<div class="modal-body">
								<img style="width: auto; height: auto;" src="{{data.photo}}">
						</div>
				</div>
		</div>
</div>
