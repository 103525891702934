import { SvgParams } from "../../constants/constants";
import { IAreaService } from "../../interfaces/IAreaService";
import { AreaType } from "../../interfaces/IAreaType";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { GlassPart } from "../glasses/glassPart.model";
import { GlassWall } from "../glasses/glassWall.model";
import { MuntinProfile } from "../profiles/muntin.model";
import { ISize, WizardParameter } from "../wizard/wizard-parameter.model";
import { GlassStoreModel } from "./glass-store.model";
import { GlassWallsStoreModelBase } from "./glasswalllbase-store-model";

export class GlassWallStoreModel extends GlassWallsStoreModelBase {

	parts: GlassStoreModel[] = [];
	rect: IRect;
	size: ISize;

	constructor(g: GlassWall, projectService: ProjectService) {
		super(g);

		this.rect = g.rect;

		switch (g.area) {
			case AreaType.Front:
			case AreaType.Rear:
					this.size = { width: Math.round(g.rect.w / SvgParams.SCALE), height: Math.round(g.rect.h / SvgParams.SCALE), depth: undefined };
				break;
			case AreaType.Left:
			case AreaType.Right:
				this.size = { width: undefined, height: Math.round(g.rect.h / SvgParams.SCALE), depth: Math.round(g.rect.w / SvgParams.SCALE) };
				break;
		}

		this.storeFrames(g);
		this.storeMuntins(g);

		g.parts.forEach(p => {
			this.parts.push(new GlassStoreModel(p));
		 });

	}

	public static restore(g: GlassWallStoreModel, projectService: ProjectService, srv: IAreaService) {

		var wall = projectService.generateGlassWall(srv, g.configId, g.rect, g.hanging);
		projectService.profileService.addVertical(wall, false);

		const wallDef = projectService.template.wallGlasses.find(g => g.id == wall.configId);

		g.parts.forEach((p: GlassStoreModel) => {
			let part = new GlassPart(projectService.template, wallDef, wall, p.rect.x, p.rect.y, p.rect.w, p.rect.h);
			part.id = p.id;
			part.rect = p.rect;
			wall.parts.push(part);
		});


		const extra: WizardParameter = projectService.template.hiddenExtras.find(profile => profile.id == wallDef.connectorId)
		this.restoreFrames(g, wall, projectService);
		this.restoreMuntins(g, wall);

		this.assingParts(wall, g);

		return wall;
	}	

	protected static assingParts(wall: GlassWall, g: GlassWallStoreModel) {
		g.muntins.forEach(m => {
			const leftPart = wall.parts.find(p => p.id == m.leftPartId);
			const rightPart = wall.parts.find(p => p.id == m.rightPartId);

			var munt: MuntinProfile = wall.muntins.find(e => e.id == m.id);
			munt.leftPart = leftPart;
			munt.rightPart = rightPart;
		});
	}

}
