<div class="row">
	<div class="col col-4"><span i18n="Common|Length">Length</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numHeight [value]="currentHeight" [disabled]="projectService.readOnly || current.isWinkel"
			[min]="minHeight" [max]="maxHeight"></app-numeric-textbox> mm &nbsp;
		<button kendoButton [svgIcon]="currentIcon" *ngIf="!current.isWinkel" (click)="resetColumnHeight()"></button>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Length">Length without foundation</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numColOnlyHeight [value]="currentColOnlyHeight" [disabled]="projectService.readOnly || current.isWinkel"
			[min]="minColOnlyHeight" [max]="maxColOnlyHeight"></app-numeric-textbox> mm &nbsp;
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocX [value]="columnXLocation" [disabled]="projectService.readOnly || changeXLocationDisabled()"
			[min]="minXLocation" [max]="maxXLocation"></app-numeric-textbox> mm &nbsp;
		<kendo-buttongroup *ngIf="!changeXLocationDisabled()" kendoTooltip>
			<button kendoButton [selected]="locationMode=='Left'" [toggleable]="true" [svgIcon]="projectService.svgIcons.arrowLeftIcon"
				(selectedChange)="setLocationMode('Left')"
				i18n-title="Column|Tooltip:Location mode left" title="Set location mode - value from the left edge"></button>
			<button kendoButton [selected]="locationMode=='Right'" [toggleable]="true" [svgIcon]="projectService.svgIcons.arrowRightIcon"
				(selectedChange)="setLocationMode('Right')"
				i18n-title="Column|Tooltip:Location mode left" title="Set location mode - value from the right edge"></button>
		</kendo-buttongroup>
	</div>
</div>

<div class="row" #notifContainer>
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="columnYLocation" [disabled]="projectService.readOnly || changeYLocationDisabled()"
			[min]="minYLocation" [max]="maxYLocation"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Column type">Column type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="projectService.template.getColumnsForProfile(current)"
			textField="name" valueField="id" [(ngModel)]="currentConfigId" [valuePrimitive]="true"
			[disabled]="projectService.readOnly || projectService.checkVerticalElements()">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row" *ngIf="foundations.length > 0">
	<div class="col col-4"><span i18n="Column|Foundation">Foundation</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="foundations" textField="name" valueField="id" [valuePrimitive]="true" [(ngModel)]="currentFoundationId" [disabled]="projectService.readOnly">
		</kendo-dropdownlist>
		<kendo-listview #listView [data]="foundationExtras" containerClass="k-d-flex k-flex-col k-flex-nowrap">
			<ng-template kendoListViewItemTemplate let-dataItem="dataItem">
				<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;"
					[(ngModel)]="dataItem.checked" (click)="selectExtra(dataItem)" [disabled]="projectService.readOnly" />
				&nbsp;{{ dataItem.name }}<br>
			</ng-template>
		</kendo-listview>
	</div>
</div>

<div class="row" *ngIf="current.canHaveOutflow" style="min-height: 110px; padding-top: 10px;">
	<div class="col col-4"><div style="position: relative; top: 30px"><span i18n="Column|Outflow">Outflow</span>:</div></div>
	<div class="col col-8">
		<div [ngClass]="{'required' : !outflowValid() }" style="width: 95px;">
			<button class="out" kendoButton [selected]="current.outflow=='Top'" [toggleable]="true" (selectedChange)="setOutflow('Top')"
				[disabled]="projectService.readOnly || !isOutflowPossible('Top')" style="left: 30px;"></button>
			<br>
			<button class="out" kendoButton [selected]="current.outflow=='Left'" [toggleable]="true" (selectedChange)="setOutflow('Left')"
				[disabled]="projectService.readOnly || !isOutflowPossible('Left')" style="left: 0px;"></button>
			<button class="out" kendoButton [selected]="current.outflow=='Right'" [toggleable]="true" (selectedChange)="setOutflow('Right')"
				[disabled]="projectService.readOnly || !isOutflowPossible('Right')" style="left: 30px;"></button>
			<br>
			<button class="out" kendoButton [selected]="current.outflow=='Bottom'" [toggleable]="true" (selectedChange)="setOutflow('Bottom')"
				[disabled]="projectService.readOnly || !isOutflowPossible('Bottom')" style="left: 30px;"></button>
		</div>
	</div>
</div>

<div class="row" *ngIf="current.outflow">
	<div class="col col-8 offset-4">
		<kendo-listview #listView [data]="outflowExtras" containerClass="k-d-flex k-flex-col k-flex-nowrap">
			<ng-template kendoListViewItemTemplate let-dataItem="dataItem">
				<input type="checkbox" kendoCheckBox size="large" style="width: 20px; height: 20px;"
					[(ngModel)]="dataItem.checked" (click)="selectExtra(dataItem)" [disabled]="projectService.readOnly" />
				&nbsp;{{ dataItem.name }}<br>
			</ng-template>
		</kendo-listview>
	</div>
</div>

<div *ngIf="!current.frozen">
	<div class="row" *ngIf="current.locked; else unlocked">
		<div class="col col-4">
			<span class="k-icon k-i-lock"></span>
		</div>
		<div class="col col-8">
			<button class="col" kendoButton [svgIcon]="projectService.svgIcons.unlockIcon" (click)="current.locked = !current.locked" [disabled]="projectService.readOnly"><span i18n="Common|Unlock">Unlock</span></button>
		</div>
	</div>
	<ng-template #unlocked>
		<div class="row">
			<div class="col col-4">
				<span class="k-icon k-i-unlock"></span>
			</div>
			<div class="col col-8">
				<button class="col" kendoButton [svgIcon]="projectService.svgIcons.lockIcon" (click)="current.locked = !current.locked" [disabled]="projectService.readOnly"><span i18n="Common|Lock">Lock</span></button>
			</div>
		</div>
	</ng-template>

	<div class="row" *ngIf="current.connector">
		<div class="col col-4">
			<span *ngIf="!current.isRear"><span i18n="Common|Front">Front</span>:</span>
			<span *ngIf="current.isRear"><span i18n="Common|Rear">Rear</span>:</span>
		</div>
		<div class="col col-8" kendoTooltip>
			<button kendoButton (click)="moveConnector('Left')" [disabled]="projectService.readOnly || checkConnector('Left')" [svgIcon]="projectService.svgIcons.rewindIcon"></button>
			<button kendoButton (click)="moveConnector('Right')" [disabled]="projectService.readOnly || checkConnector('Right')" [svgIcon]="projectService.svgIcons.forwardIcon"></button>
			<span [attr.title]="mergeProfilesTooltip()">
				<button kendoButton (click)="mergeProfiles()" [disabled]="projectService.readOnly || mergeProfilesDisabled()" [svgIcon]="projectService.svgIcons.cellsMergeHorizontallyIcon"></button>
			</span>
		</div>
	</div>

	<div class="row" *ngIf="!current.connector && !current.isCorner">
		<div class="col col-4">
			<span *ngIf="!current.isRear"><span i18n="Common|Front">Front</span>:</span>
			<span *ngIf="current.isRear"><span i18n="Common|Rear">Rear</span>:</span>
		</div>
		<div class="col col-8">
			<span i18n-title="Column|Split profile tooltip" title="Split profile">
				<button kendoButton (click)="splitProfile()" [disabled]="projectService.readOnly" [svgIcon]="projectService.svgIcons.cellSplitHorizontallyIcon"></button>
			</span>
		</div>
	</div>

</div>

<div class="row" *ngIf="current.deletable">
	<div class="col col-4">
		<span i18n="Common|Delete">Delete</span>:
	</div>
	<div class="col col-8">
		<button class="col" kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly" i18n="Common|Delete">Delete</button>
	</div>
</div>

<card-cuttingonsite [current]="current" [disabled]="changeXLocationDisabled()"></card-cuttingonsite>

<div class="row">
	<div class="col col-4">
		<span class="k-icon k-i-x-circle"></span>
		<span i18n="Common|Frozen">Frozen</span>
	</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.checkCircleIcon" (click)="openDialog()" [disabled]="projectService.readOnly" i18n="Common|Unfreeze">Unfreeze</button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Tag:</div>
		<div class="col col-8">{{ current.tag }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Length:</div>
		<div class="col col-8">{{ current.length | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min X location:</div>
		<div class="col col-8">{{ minXLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max X location:</div>
		<div class="col col-8">{{ maxXLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min Y location:</div>
		<div class="col col-8">{{ minYLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max Y location:</div>
		<div class="col col-8">{{ maxYLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min height:</div>
		<div class="col col-8">{{ minHeight }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max height:</div>
		<div class="col col-8">{{ maxHeight }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min column only height:</div>
		<div class="col col-8">{{ minColOnlyHeight }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max column only height:</div>
		<div class="col col-8">{{ maxColOnlyHeight }} mm</div>
	</div>
	<div class="row" *ngIf="current.connector">
		<div class="col col-4">Left profile:</div>
		<div class="col col-8">{{ current.leftProfile.tag }}: {{ current.leftProfile.id }}</div>
	</div>
	<div class="row" *ngIf="current.connector">
		<div class="col col-4">Right profile:</div>
		<div class="col col-8">{{ current.rightProfile.tag }}: {{ current.rightProfile.id }}</div>
	</div>
</div>


<app-confirm-dialog [dialogOpened]="dialogOpened" [dialogMessage]="dialogMessage" (dialogAction)="onDialogAction($event)"></app-confirm-dialog>
