import { Injectable } from '@angular/core';
import { AreaType } from '../../interfaces/IAreaType';
import { Tags, SvgParams, GLASS_PART_PADDING } from '../../constants/constants';
import { SideBaseService } from './side-base.service';
import { ElementType, Orientation, ToolboxItem } from '../../models/ToolboxModel';
import { ColumnProfile } from '../../models/profiles/column.model';
import { WallProfile } from '../../models/profiles/wall.model';
import { Workarea } from 'src/app/_core/models/workarea';
import { ProjectService } from '../project.service';
import { SideFinishProfile } from '../../models/profiles/side-finish.model';
import { Profile } from '../../models/profiles/profile.model';
import { FrontProfile } from '../../models/profiles/front.model';
import { IPoint } from '../../interfaces/IPoint';
import { Common } from '../../models/common';
import { IRect } from '../../interfaces/IRect';
import { MarquiseVertical } from '../../models/marquises/marquise-vertical.model';
import { MarquiseLeft } from '../../models/marquises/marquise-left.model';
import { FrameProfile } from '../../models/profiles/frame.model';
import { IConnectable } from '../../interfaces/IConnectable';
import { IPointer } from '../../interfaces/IPointer';
import { GlassWallBase } from '../../models/glasses/glassWall-base.model';

@Injectable({
	providedIn: 'root'
})
export class LeftSideService extends SideBaseService {
	protected previousTemplateNo: string;

	protected get hightestTopPointNum(): number {
		return SvgParams.LEFT_TOP_POINT_NUM;
	}

	protected get lowestTopPointNum(): number {
		return SvgParams.RIGHT_TOP_POINT_NUM;
	}

	public get areaType(): AreaType {
		return AreaType.Left;
	}
	public get rectOnSide(): string {
		return 'rectOnLeft';
	}
	public get pathOnSide(): string {
		return 'pathOnLeft';
	}
	public get anchorOnSide(): string {
		return 'anchorLeft';
	}
	public get rotationOnSide(): string {
		return 'rotationLeft';
	}


	leftElement: Profile | GlassWallBase;
	rightElement: Profile | GlassWallBase;
	bottomElement: Profile | GlassWallBase

	constructor(protected projectService: ProjectService) {
		super(projectService.profileService, projectService);
	}


	// Adding elements, if it was displayed some other side area before displaying the left side area

	public addElementsAfterDisplayedRoof(roofElements: any): void {

		var front: FrontProfile = roofElements[ElementType.Front].find(f => f.tag == Tags.LEFT_FRONT_TAG || f.tag == Tags.ANY_FRONT_TAG);
		if (!front) {
			return;
		}
		this.saveElementWithSetRect(front, front.height);

		if (front.gutter) {
			this.saveElementWithSetRect(front.gutter, front.gutter.height);
		}

		var leftRear: WallProfile = this.profileService.leftSideElements[ElementType.WallProfile].find(f => f.tag == Tags.LEFT_REAR_TAG || f.tag == Tags.ANY_REAR_TAG);
		var wall: WallProfile = roofElements[ElementType.WallProfile].find(f => f.tag == Tags.LEFT_REAR_TAG || f.tag == Tags.ANY_REAR_TAG);
		if (leftRear || wall) {
			this.saveWallWithSetRect(wall);
			if (!leftRear) {
				leftRear = wall;
				this.profileService.leftSideElements[ElementType.WallProfile].push(wall);
			} else {
				this.saveWallWithSetRect(leftRear);
			}

			roofElements[ElementType.Column].forEach((col: ColumnProfile) => {
				switch (col.tag) {
					case Tags.LEFT_COLUMN_TAG:
						this.saveColWithSetRect(col, front.rectOnRoof.y, front.height);
						break;
					case Tags.LEFT_REAR_COLUMN_TAG:
						this.saveColWithSetRect(col, SvgParams.START_X, leftRear.height);
						break;
				}
			});	

			var sideFin = roofElements[ElementType.SideFinish].find(s => s.tag == Tags.LEFT_SIDEFINISH_TAG);

			const r = this.projectService.template.getDefaultRear();
			const x = this.shapePoints[SvgParams.LEFT_TOP_POINT_NUM].x + Common.round(leftRear.depth * SvgParams.SCALE);
			const y = this.shapePoints[SvgParams.LEFT_TOP_POINT_NUM].y + Common.round(r.size.height * SvgParams.SCALE) - Common.round(r.reinforcementHeight * SvgParams.SCALE);
			sideFin.rearY = leftRear.rectOnLeft.y + leftRear.rectOnLeft.h;
			this.saveSideFinWithSetRect(sideFin, x, y);

		}
	}

	private saveSideFinWithSetRect(sideFin: SideFinishProfile, x: number, y: number): void {
		if (!this.projectService.template.rearReinforcementDepth) { // template not loaded yet
			return;
		}

		const l = Math.round(this.projectService.template.barLength * SvgParams.SCALE);
		const h = Math.round(sideFin.height * SvgParams.SCALE);

		const rd = Common.round(this.projectService.template.rearReinforcementDepth * SvgParams.SCALE);
		x -= rd;

		const rect: IRect = { x: Common.round(x), y: Common.round(y - h), w: Common.round(l), h: Common.round(h) };
		sideFin.anchor = { x: rect.x, y: rect.y + h };
		sideFin.rotation = this.projectService.template.dropAngle;
		sideFin[this.rectOnSide] = rect;

		this.profileService.leftSideElements[ElementType.SideFinish].push(sideFin);
	}


	// Methods for setting left side rectangles to generated or existed objects of elements and saving them to profileService

	protected saveWallWithSetRect(wall: WallProfile): void {
		const rear = this.projectService.template.getRearSize();

		wall.rectOnLeft = {
			x: this.shapePoints[0].x,
			y: this.shapePoints[0].y,
			w: Math.round(rear.depth * SvgParams.SCALE),
			h: Math.round(rear.height * SvgParams.SCALE)
		};
	}

	protected saveElementWithSetRect(element: Profile, height: number): void {

		var y = this.shapePoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - this.projectService.template.columnLength * SvgParams.SCALE - height * SvgParams.SCALE;
		element.rectOnLeft = {
			x: this.shapePoints[1].x - Common.round(element.depth * SvgParams.SCALE),
			y: y,
			w: Common.round(element.depth * SvgParams.SCALE),
			h: Common.round(height * SvgParams.SCALE)
		};

		if (this.profileService.leftSideElements[element.type].find(e => e.id == element.id) == null) {
			this.profileService.leftSideElements[element.type].push(element);
		}
	}

	//////////////////////////////////

	public changeArea(): void {
		if (this.profileService.leftSideElements !== null || this.profileService.leftSideElements[ElementType.SideFinish].length == 0) {
			this.profileService.leftSideElements = null;
			this.profileService.leftSideElementsInit();
			this.showArea();
			this.applyTemplate(this.projectService.template);
		}
	}

	public getFrontCornerY(currentAreaElements?: any) {
		if (currentAreaElements == null) {
			currentAreaElements = this.projectService.getCurrentAreaElements();
		}

		var sideFinish: SideFinishProfile = this.profileService.leftSideElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.LEFT_SIDEFINISH_TAG);
		if (!sideFinish) {
			currentAreaElements = this.profileService.leftSideElements;
			sideFinish = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.RIGHT_SIDEFINISH_TAG);
		}

		var leftFront: FrontProfile = currentAreaElements[ElementType.Front].find((c: FrontProfile) => c.tag == Tags.LEFT_FRONT_TAG);
		if (!leftFront) {
			leftFront = currentAreaElements[ElementType.Front][0];
		}

		var rty = 0;
		if (leftFront.gutter) {
			rty = leftFront.rectOnLeft.y;
		} else {
			const svg = (document.getElementById(AreaType.Left + '_' + sideFinish.id) as unknown) as SVGGraphicsElement;
			if (svg) { // more trustworthy, but not always available
				const clRect = svg.getBoundingClientRect();
				var z = localStorage.getItem("zoom");
				if (!z)
					z = "1";
				var zoom = parseFloat(z);
				rty = sideFinish.rectOnLeft.y + clRect.height / zoom;
			} else {
				rty = sideFinish.rectOnLeft.y + Common.calculateDroppedY(sideFinish.rectOnLeft.w, this.projectService.template.dropAngle) + sideFinish.rectOnLeft.h
					- this.projectService.template.dropAngle * 0.1; // stupid factor for correction - experimental value
			}
		}

		return Common.round(rty);
	}

	public getGWDStartPoints(currentAreaElements?: any): IPoint[] {
		if (currentAreaElements == null) {
			currentAreaElements = this.projectService.getCurrentAreaElements();
		}
		var sideFinish: SideFinishProfile = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.LEFT_SIDEFINISH_TAG);
		if (!sideFinish) {
			currentAreaElements = this.profileService.leftSideElements;
			sideFinish = currentAreaElements[ElementType.SideFinish].find((s: SideFinishProfile) => s.tag == Tags.LEFT_SIDEFINISH_TAG);
		}

		var leftColumn = currentAreaElements[ElementType.Column].find((c: ColumnProfile) => c.tag == Tags.LEFT_COLUMN_TAG);
		var leftRearColumn = currentAreaElements[ElementType.Column].find((c: ColumnProfile) => c.tag == Tags.LEFT_REAR_COLUMN_TAG);

		var x1 = leftRearColumn ? (leftRearColumn.rectOnLeft.x + leftRearColumn.rectOnLeft.w) : this.shapePoints[SvgParams.LEFT_TOP_POINT_NUM].x;
		var x2 = leftColumn.rectOnLeft.x;

		var rty = this.getFrontCornerY(currentAreaElements);		
		var lty = this.getSideFinishYCorrected(sideFinish, sideFinish.rectOnLeft.y + sideFinish.height * SvgParams.SCALE);

		var p = [
			{
				x: x1,
				y: lty,
			},
			{
				x: x2,
				y: rty,
			},
			{
				x: x2,
				y: this.shapePoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y,
			},
			{
				x: x1,
				y: this.shapePoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y,
			}
		];
		// console.debug('getGWDStartPoints', lty, rty, p);

		return p;
	}

	getMinHeightOfGWD(points: IPoint[]): number {
		const rightHeight = points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - points[SvgParams.RIGHT_TOP_POINT_NUM].y;
		const leftHeight = points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - points[SvgParams.LEFT_TOP_POINT_NUM].y;
		const res = Math.round((leftHeight - rightHeight) / SvgParams.SCALE);
		return Common.round(res + (GLASS_PART_PADDING * 2 + 1) / SvgParams.SCALE);
	}

	public calculatePoints() {

		this.shapePoints = [];
		this.workAreas = [];
		this._svg = "";

		const depth = this.projectService.realDepth;
		var ac = depth * Math.tan(Common.toRadians(this.projectService.template.dropAngle));

		const rh = this.projectService.template.getRearSize().height;
		const fh = this.frontHeight; // + rh - gh;
		const bh = this.backHeight;
		const df = Common.round(ac * SvgParams.SCALE);

		const y2 = SvgParams.START_Y + (bh + rh) * SvgParams.SCALE;
		this.shapePoints.push({ x: SvgParams.START_X, y: SvgParams.START_Y });
		this.shapePoints.push({ x: SvgParams.START_X + Common.round(depth * SvgParams.SCALE), y: SvgParams.START_Y + df });
		this.shapePoints.push({ x: SvgParams.START_X + Common.round(depth * SvgParams.SCALE), y: y2 });
		this.shapePoints.push({ x: SvgParams.START_X, y: y2 });

		const de = this._template.getFrontColumnSize().depth;
		const colD = Common.round(de * SvgParams.SCALE);

		var p = new Workarea();

		p.points.push({ x: this.shapePoints[0].x, y: this.shapePoints[1].y });
		p.points.push({ x: this.shapePoints[1].x - colD, y: this.shapePoints[1].y });
		p.points.push({ x: this.shapePoints[2].x - colD, y: this.shapePoints[2].y });
		p.points.push({ x: this.shapePoints[3].x, y: this.shapePoints[3].y });

		p.width = depth - de;
		p.height = fh;

		this.workAreas.push(p);
	}

	public createFrame(projectService: ProjectService, info: IConnectable, orient: Orientation) {
		const cnt = projectService.template.hiddenExtras.find(profile => profile.id == info.connectorId);
		const rear = projectService.profileService.leftSideElements[ElementType.WallProfile].find(c => c.tag == Tags.LEFT_REAR_TAG || c.tag == Tags.ANY_REAR_TAG);
		const y = orient == Orientation.Vertical ? rear.rectOnLeft.y + rear.rectOnLeft.h : rear.rectOnLeft.y;
		const w = orient == Orientation.Vertical ? projectService.template.backHeight : projectService.template.depth;
		return new FrameProfile(rear.rectOnLeft.x, y, w, cnt.size, cnt.color, orient, info.connectorId);
	}


	public createMarquise(ptr: IPointer, currentTool: ToolboxItem) {
		const info = this.projectService.template.marquises.find(m => m.id == currentTool.id);

		var cols = this.projectService.getSpace(ptr);
		if (!cols.leftProfile) {
			cols.leftProfile = this.createFrame(this.projectService, info, Orientation.Vertical);
		}
		var topProfile = this.createFrame(this.projectService, info, Orientation.Horizontal);

		const message = MarquiseVertical.validate(info, cols.leftProfile, cols.rightProfile, AreaType.Left, null, this.profileService);
		if (message != "") {
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return;
		}

		var mq = new MarquiseLeft(this.projectService, info, cols.leftProfile, cols.rightProfile, topProfile, AreaType.Left, this.getWorkAreaPoints());
		mq.setHandler(this.projectService.template.frontId);
		this.projectService.profileService.addVertical(mq);
		this.projectService.freezeProjProp();
		this.projectService.emitChange();
		this.projectService.calculateRemote();
	}

}
