export enum ElementSide {
	None = '',
	Left = 'Left',
	Right = 'Right',
	Top = 'Top',
	Bottom = 'Bottom'
}

export enum Montage {
	None = '',
	Inside = 'Inside',
	Middle = 'Middle',
	Outside = 'Outside'
}

export enum AnySide {
	None = '',
	Left = 'Left',
	Right = 'Right',
	LeftRight = 'LeftRight',
	Middle = 'Middle'
}
