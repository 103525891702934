import { SvgParams } from "../constants/constants";
import { IAreaService } from "../interfaces/IAreaService";
import { AreaType, RectName } from "../interfaces/IAreaType";
import { ProjectService } from "../services/project.service";
import { ElementType } from "./ToolboxModel";
import { Common } from "./common";
import { ColumnProfile } from "./profiles/column.model";
import { FrameProfile } from "./profiles/frame.model";
import { Profile } from "./profiles/profile.model";
import { Space } from "./space";
import { ISizeRestricted } from "./wizard/wizard-parameter.model";

export abstract class VerticalCreator {

	protected leftProfile: Profile;
	protected rightProfile: Profile;
	protected topProfile: FrameProfile;
	protected bottomProfile: FrameProfile;

	protected abstract get maxWidthValidMsg(): string;
	protected abstract get maxHeightValidMsg(): string;
	protected abstract get minWidthValidMsg(): string;
	protected abstract get minHeightValidMsg(): string;

	protected getX1() {
		const a = RectName.get(this.area);
		return Common.round(this.leftProfile[a].x + this.leftProfile[a].w);
	}

	protected getX2() {
		const a = RectName.get(this.area);
		return Common.round(this.rightProfile[a].x);
	}

	protected getWidth() {
		const x1 = this.getX1();
		const x2 = this.getX2();
		return Common.round((x2 - x1) / SvgParams.SCALE);
	}

	protected getY(startPosY: number = null) {
		if (startPosY) {
			return startPosY;
		}
		if (this.topProfile) {
			const f = this.topProfile;
			return Common.round(f.rect.y + f.rect.h);
		}
		const shorter = this.getShorter();
		const a = RectName.get(this.area);
		var y = shorter[a].y;
		return y;
	}

	protected getShorter() {
		const a = RectName.get(this.area);
		return this.leftProfile[a].h <= this.rightProfile[a].h ? this.leftProfile : this.rightProfile;
	}

	protected getHeight(startPosY: number = null) {
		const topPoint = this.getY(startPosY);
		const a = RectName.get(this.area);
		const shorterProfile = this.getShorter();
		// console.debug('getHeight 0', startPosY, this.topProfile);
		if (this.topProfile?.rect?.h == 0 && this.bottomProfile?.rect?.h == 0 && shorterProfile instanceof ColumnProfile) {
			// console.debug('getHeight 1', shorterProfile);
			return shorterProfile[a].h - (shorterProfile[a].y - topPoint);
		}

		var bottomPoint = topPoint;
		if (this.bottomProfile) {
			// console.debug('getHeight 2', this.bottomProfile);
			bottomPoint = Math.round(this.bottomProfile.rect.y);
		} else {
			const shorter = shorterProfile[a];
			var bottomPoint = shorter.y + shorter.h;
			// console.debug('getHeight 3', shorterProfile);
			if (shorterProfile instanceof ColumnProfile) {
				if (shorterProfile.hydro) {
					bottomPoint += shorterProfile.hydro.size.height * SvgParams.SCALE;
				}
			}
		}
		return bottomPoint - topPoint;
	}

	public get isWinkelOnLeft() {
		return this.leftProfile.type == ElementType.Column && (this.leftProfile as ColumnProfile).isWinkel;
	}
	public get isWinkelOnRight() {
		return this.rightProfile.type == ElementType.Column && (this.rightProfile as ColumnProfile).isWinkel;
	}

	constructor(
		protected projectService: ProjectService,
		cols: Space,
		protected area: AreaType,
		protected sideService: IAreaService = null) {

			this.leftProfile = cols.leftProfile;
			this.rightProfile = cols.rightProfile;
			this.topProfile = cols.topProfile;
			this.bottomProfile = cols.bottomProfile;
	}

	public validateWidth(info: ISizeRestricted): string {

		const w = this.getWidth();
		if (w > info.maxWidth) {
			return this.maxWidthValidMsg;
		}

		if (w < info.minWidth) {
			return this.minWidthValidMsg;
		}

		return "";
	}

	public validateHeight(startPosY: number = null): string {

		const info = this.projectService.template.doors[0];

		const h = this.getHeight(startPosY) / SvgParams.SCALE;

		if (h < info.minHeight) {
			return this.minHeightValidMsg;
		}

		return "";
	}

}
