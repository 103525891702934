import { v4 as uuidv4 } from 'uuid';
import { Common } from '../common';
import { GLASS_PART_PADDING, SvgParams } from '../../constants/constants';
import { IRect } from '../../interfaces/IRect';
import { GlassWall } from './glassWall.model';
import { ElementType } from '../ToolboxModel';
import { GlassWallParameter } from '../wizard/wizard-parameter.model';
import { ProjectTemplate } from '../../template';
import { Glass } from './glass.model';

export class GlassPart extends Glass {

	get type() {
		return ElementType.GlassPart
	}

	public id: string;
	public rect: IRect;
	public master: GlassWall;

	private _maxM2: number;

	get muntinsRequired() {
		return this.width * this.height > this._maxM2;
	}
	
	get maxM2() {
		return this.master.maxM2;
	}

	constructor(
		template: ProjectTemplate,
		def: GlassWallParameter,
		master: GlassWall,
		x: number,
		y: number,
		w: number,
		h: number
	) {
		super(template, def, x, y, w, h, 0);

		const padding = (GLASS_PART_PADDING * 2) / SvgParams.SCALE;

		this._maxM2 = master.maxM2;
		this.master = master;
		this.id = `${this.type}_` + uuidv4();
		this.rect = {
			x: Common.round(x + GLASS_PART_PADDING),
			y: Common.round(y + GLASS_PART_PADDING),
			w: Common.round((w - padding) * SvgParams.SCALE),
			h: Common.round((h - padding) * SvgParams.SCALE)
		};
	}
}
