import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { FrameProfile } from "../profiles/frame.model";
import { Orientation } from "../ToolboxModel";
import { ElementStoreModel } from "./element-store.model";

export class FrameStoreModel extends ElementStoreModel {
	rect: IRect;
	orientation: Orientation;
	length: number;

	constructor(f: FrameProfile) {
		if (f) {
			super(f.id, f.configId);

			this.rect = f.rect;
			this.orientation = f.orientation;
			this.length = f.getLength();
		} else {
			super("", "");
		}
	}

	public static restore(f: FrameStoreModel, projectService: ProjectService, defConfigId: string = null) {
		var extra = projectService.template.hiddenExtras.find(profile => profile.id == f.configId);
		if (!extra && defConfigId != null) {
			var extra = projectService.template.hiddenExtras.find(profile => profile.id == defConfigId);
		}
		var newFrame = new FrameProfile(f.rect.x, f.rect.y, f.length, extra.size, extra.color, f.orientation, f.configId);
		newFrame.rect = f.rect;
		return newFrame; 
	}
}
