import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AreaType, CollectionName } from '../../_core/interfaces/IAreaType';
import { CardType, IPropertyCard } from '../../_core/interfaces/IPropertyCard';
import { ProjectService } from '../../_core/services/project.service';
import { RoofService } from '../../_core/services/areas/roof.service';
import { ElementType } from '../../_core/models/ToolboxModel';
import { LocationService } from '../../_core/services/location.service';
import { ProfileService } from '../../_core/services/profile.service';
import { ColumnProfile } from '../../_core/models/profiles/column.model';
import { Tags, SvgParams, OUTFLOW_SURFACE_TRESHOLD } from '../../_core/constants/constants';
import { FrontProfile } from 'src/app/_core/models/profiles/front.model';
import { GlassResizeMode } from 'src/app/_core/interfaces/IGlassResizeMode';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { WallProfile } from 'src/app/_core/models/profiles/wall.model';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { Profile } from 'src/app/_core/models/profiles/profile.model';
import { MarquiseVertical } from 'src/app/_core/models/marquises/marquise-vertical.model';
import { FrontColumn, WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { ICheckable } from 'src/app/_core/interfaces/ICheckable';
import { Common } from 'src/app/_core/models/common';
import { FrontService } from 'src/app/_core/services/areas/front.service';
import { RearService } from 'src/app/_core/services/areas/rear.service';

@Component({
	selector: 'card-column',
	templateUrl: './card-column.component.html',
	styleUrls: ['../card.component.css']
})
export class CardColumnComponent implements IPropertyCard {
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: true }) numLocY: NumTbxComponent;
	@ViewChild('numHeight', { static: true }) numHeight: NumTbxComponent;
	@ViewChild('numColOnlyHeight', { static: true }) numColOnlyHeight: NumTbxComponent;
	@ViewChild("notifContainer", { read: ViewContainerRef }) notifContainer: ViewContainerRef

	@Input() set items(items: ColumnProfile[]) {

		this.current = items[0];
		if(this.current.isRear){
			this.templateHeight = this.projectService.template.backHeight;
		} else {
			this.templateHeight = this.projectService.template.columnLength;
		}

		if (this.projectService.areaType == AreaType.Rear) {
			this.locationMode = GlassResizeMode.Right;
		} else {
			this.locationMode = GlassResizeMode.Left;
		}
		this.setupAllowableCoordinatesForCurrentColumn();

		if (this.numLocX.valueChanged.observers.length == 0) {
			this.numLocX.valueChanged.subscribe(v => {
				this.columnXLocation = v;
			});
		}

		if (this.numLocY.valueChanged.observers.length == 0) {
			this.numLocY.valueChanged.subscribe(v => {
				this.columnYLocation = v;
			});
		}

		if(this.numHeight.valueChanged.observers.length == 0){
			this.numHeight.valueChanged.subscribe(v => {
				this.currentHeight = v;
			});
		}

		if(this.numColOnlyHeight.valueChanged.observers.length == 0){
			this.numColOnlyHeight.valueChanged.subscribe(v => {
				this.currentColOnlyHeight = v;
			});
		}

		this.setupFoundations();
		this.setupExtras();
		this.setupOutflowExtras();
	}

	type: CardType = CardType.Profile;
	current: ColumnProfile;
	templateHeight: number;
	minXLocation: number;
	maxXLocation: number;
	minYLocation: number;
	maxYLocation: number;
	generalMaxXLocation: number;
	generalMaxYLocation: number;

	locationMode: GlassResizeMode = GlassResizeMode.Left;

	dialogOpened = false;
	dialogMessage = '';
	foundations: WizardParameter[];
	foundationExtras: ICheckable[];
	outflowExtras: ICheckable[];

	setLocationMode(mode: string) {
		this.locationMode = GlassResizeMode[mode];
		this.calculateXRangeExtremePoints();
	}

	get currentIcon(){
		if(this.current.foundationOnConcrete()){
			return this.projectService.svgIcons.caretAltToBottomIcon;
		}
		return this.projectService.svgIcons.caretAltToTopIcon;
	}

	get currentHeight(){
		return this.current.getHeight();
	}

	set currentHeight(h: number){
		this.current.setHeight(h);
		this.projectService.emitChange();
	}

	get currentColOnlyHeight(){
		if (this.current.foundationOnConcrete()) {
			return this.current.getHeight();
		}
		return this.current.getHeight() - this.current.foundation.size.height;
	}

	set currentColOnlyHeight(h: number){
		if (this.current.foundationOnConcrete()) {
			this.current.setHeight(h);
		} else {
			this.current.setHeight(h + this.current.foundation.size.height);
		}
		this.projectService.emitChange();
	}

	get minColOnlyHeight(){
		if (this.current.foundationOnConcrete()) {
			return this.minHeight;
		}
		return this.minHeight - this.current.foundation.size.height;
	}

	get maxColOnlyHeight(){
		if (this.current.foundationOnConcrete()) {
			console.debug()
			return this.maxHeight;
		}
		return this.maxHeight - this.current.foundation.size.height;
	}

	get maxHeight(){
		if(this.current.isWinkel){
			return 0;
		} 
		var h: number;
		if(this.current.foundationOnConcrete()){
			h = this.templateHeight;
		} else {
			h = this.current.maxHeight;
		}
		return Math.round(h);		
	}

	get minHeight(){
		if(this.current.isWinkel){
			return 0;
		}
		var h: number;
		if(this.current.foundationOnConcrete()){
			h = this.current.minHeight;
		} else {
			h = this.templateHeight + this.current.foundation.size.height;
		}
		return Math.round(h);
	}

	get columnXLocation() {
		const x = this.locationService.getLocation(this.current);
		if (this.locationMode == GlassResizeMode.Left) {
			return x + this.current.width / 2;
		} else {
			return this.projectService.template.width - x - this.current.width / 2;
		}
	}
	set columnXLocation(location) {
		if (location == this.locationService.getLocation(this.current)) {
			return;
		}
		if (location < this.minXLocation || location > this.projectService.template.width) {
			return;
		}

		if (this.locationMode == GlassResizeMode.Left) {
			location -= this.current.width / 2;
		} else {
			location = this.projectService.template.width - location - this.current.width / 2;
		}

		var colDef: FrontColumn;
		var ltag: string;
		var rtag: string;
		if (this.current.isRear) {
			colDef = this.projectService.currentRear.columns.find(c => c.id == this.current.configId);
			rtag = Tags.LEFT_REAR_COLUMN_TAG;
			ltag = Tags.RIGHT_REAR_COLUMN_TAG;
		} else {
			colDef = this.projectService.currentFront.columns.find(c => c.id == this.current.configId);
			ltag = Tags.LEFT_COLUMN_TAG;
			rtag = Tags.RIGHT_COLUMN_TAG;
		}

		if (this.current.tag == ltag && location > 0 && location < colDef.shiftedWidthMin) {
			location = colDef.shiftedWidthMin;
		} else if (this.current.tag == rtag && location < this.maxXLocation && location > this.maxXLocation - colDef.shiftedWidthMin) {
			location = this.maxXLocation - colDef.shiftedWidthMin;
		}

		if (this.current.isFront) {
			const tempCol: Profile = Object.assign({}, this.current);
			tempCol.rectOnFront = { ...this.current.rectOnFront };
			tempCol.rectOnFront.x = SvgParams.START_X + location * SvgParams.SCALE;
			if (!this.projectService.canAdjustMarquise(tempCol)) {
				this.numLocX.rebind(this.columnXLocation);
				return;
			}
		}

		if (this.current.rectOnFront) {
			if (this.projectService.currentArea === AreaType.Left) {
				this.columnYLocation = location;
			} else if (this.projectService.currentArea === AreaType.Right) {
				this.columnYLocation = this.projectService.template.depth - location - this.current.depth;
			} else {
				this.locationService.setLocation(this.current, location, null, true);
			}
		} else {
			this.locationService.setLocation(this.current, location, null, this.projectService.template.isStandalone);
		}
		this.numLocX.rebind(this.columnXLocation);
		this.projectService.emitChange();

	}

	get columnYLocation() {
		return this.locationService.getColumnYLocation(this.current)
	}
	set columnYLocation(location) {

		if (this.current.rectOnFront) {
			if (location < this.maxYLocation && location > this.maxYLocation - this.current.height / 2) {
				location = this.maxYLocation - this.current.height / 2;
			}

			var fronts : FrontProfile[] = this.profileService.roofElements[ElementType.Front];
			var gs = fronts[0].gutterSize;
			if (gs && location != this.maxYLocation) {
				if (location > this.maxYLocation - gs.depth) {
					location = this.maxYLocation - gs.depth;
				}
			}

			const diffLocation = this.current.rectOnRoof.y - (SvgParams.START_Y + location * SvgParams.SCALE);
			fronts.forEach(g => {
				this.locationService.setFrontYLocation(g, diffLocation);
			});

			var columns = this.profileService.frontElements[ElementType.Column];
			columns.forEach(c => {
				this.locationService.setColumnYLocation(c, diffLocation);
				this.profileService.getMarquisesVertical().forEach((m: MarquiseVertical) => {
					if (m.leftProfile.id == c.id || m.rightProfile.id == c.id) {
						m.adjust();
					}
				});

			});

		} else {
			const diff = this.current.rectOnRoof.y - (SvgParams.START_Y + location * SvgParams.SCALE);
			this.locationService.setColumnYLocation(this.current, diff);
		}


		this.projectService.emitChange();
		this.projectService.recalculateDimensions();
	}

	// Should used only from within Common card. Currently unused.
	// setConfigId(id: string) {
	// 	this.roofService.changeColumnType(this.current, id);
	// 	this.setupAllowableCoordinatesForCurrentColumn();
	// 	setTimeout(() => {
	// 		this.locationService.tuneColumnsLocation(this.current.isRear);
	// 		this.projectService.emitChange();
	// 	}, 10);
	// 	this.projectService.recalculateDimensions();
	// 	this.setupFoundations();
	// 	this.current.clearExtras();
	// }

	get currentFoundationId() {
		return this.current?.foundation?.id;
	}
	set currentFoundationId(id: string) {
		this.setupFoundations();
		this.current.foundation = this.foundations.find(f => f.id == id);
		this.current.clearExtras();
		this.setupExtras();
		this.resetColumnHeight();
		this.projectService.emitChange();
	}

	get currentConfigId() {
		return this.current.configId;
	}
	set currentConfigId(id: string) {
		this.roofService.changeColumnType(this.current, id);
		this.currentFoundationId = this.current.foundation?.id;
		this.setupAllowableCoordinatesForCurrentColumn();
		setTimeout(() => {
			this.locationService.tuneColumnsLocation(this.current.isRear);
			this.projectService.emitChange();
		}, 10);
		this.projectService.recalculateDimensions();
	}
	
	constructor(
		public projectService: ProjectService,
		private roofService: RoofService,
		private frontService: FrontService,
		private rearService: RearService,
		private locationService: LocationService,
		private profileService: ProfileService)
	{}

	setupFoundations() {
		this.foundations = [];
		const def = this.projectService.template.columns.find(c => c.id == this.current.configId);
		def.extras.forEach(e => {
			this.foundations.push(this.projectService.template.hiddenExtras.find(f => f.id == e.extraId));
		});
		this.setupExtras();
	}

	setupExtras() {
		this.foundationExtras = [];
		const hidext = this.projectService.template.extras;
		this.current.foundation?.extras.forEach(e => {
			const he = hidext.find(h => h.id == e.extraId);
			const checked = this.current.getExtras().find(c => c == e.extraId) != null;
			this.foundationExtras.push({ id: e.extraId, name: he.name, photo: he.thumbnail, checked });
		});
	}

	setupOutflowExtras() {
		this.outflowExtras = [];
		const hidext = this.projectService.template.extras;
		const outflow = hidext.find(o => o.id == this.current.outflowId);
		outflow.extras.forEach(e => {
			const he = hidext.find(h => h.id == e.extraId);
			const checked = this.current.getExtras().find(c => c == e.extraId) != null;
			this.outflowExtras.push({ id: e.extraId, name: he.name, photo: he.thumbnail, checked });
		});
	}


	selectExtra(item: ICheckable) {
		item.checked = !item.checked;
		if (item.checked === true) {
			this.current.addExtra(item.id);
		} else {
			this.current.removeExtra(item.id);
		}
		this.projectService.emitChange();
	}

	selectOutflowExtra(item: ICheckable) {
		item.checked = !item.checked;
		if (item.checked === true) {
			this.current.addExtra(item.id);
		} else {
			this.current.removeExtra(item.id);
		}
		this.projectService.emitChange();
	}

	changeXLocationDisabled(): boolean {
		const front = this.projectService.template.getDefaultFront();

		if (front.isShiftedDepthAllow && (this.projectService.currentArea === AreaType.Left || this.projectService.currentArea === AreaType.Right)) {
			return true;
		}

		if (!front.isShiftedDepthAllow && this.current.rectOnFront && (this.projectService.currentArea === AreaType.Left || this.projectService.currentArea === AreaType.Right)) {
			return true;
		}

		if (this.current.locked) {
			return true;
		}

		var verticalExist = this.profileService.isVerticalNearColumn(this.current);
		return verticalExist;
	}

	changeYLocationDisabled() {
		const front = this.projectService.template.getDefaultFront();

		if (!front.isShiftedDepthAllow && this.current.rectOnFront) {
			return true;
		}
		if (this.projectService.currentArea !== AreaType.Roof) {
			return true;
		}

		if (this.current.isRear) {
			return true;
		}

		var verticalExist = this.profileService.isVerticalNearColumn(this.current);
		return verticalExist;
	}


	private setupAllowableCoordinatesForCurrentColumn() {
		const colW = this.current.width;
		const colD = this.current.depth;

		this.generalMaxXLocation = this.projectService.template.width - colW;
		this.generalMaxYLocation = this.projectService.template.depth - colD;

		this.calculateXRangeExtremePoints();
		this.calculateYRangeExtremePoints();
	}

	private calculateXRangeExtremePoints() {
		const colW = this.current.width;

		this.calculateGeneralXRangeExtremePoints();

		const front = this.current.isFront ? this.projectService.currentFront : this.projectService.currentRear;
		const frontCol = front.columns.find(c => c.id === this.current.configId);
		if (this.current.tag === Tags.LEFT_COLUMN_TAG || this.current.tag == Tags.RIGHT_REAR_COLUMN_TAG) {

			if (this.locationMode == GlassResizeMode.Left || this.projectService.currentArea == AreaType.Rear) {
				this.maxXLocation = frontCol.shiftedWidthMax;
				this.minXLocation = colW / 2;
			} else {
				this.maxXLocation = this.projectService.template.width - colW / 2;
				this.minXLocation = this.projectService.template.width - frontCol.shiftedWidthMax; //colW / 2;
			}

		} else if (this.current.tag === Tags.RIGHT_COLUMN_TAG || this.current.tag == Tags.LEFT_REAR_COLUMN_TAG) {

			if ((this.locationMode == GlassResizeMode.Left || this.projectService.currentArea == AreaType.Rear)) {
				this.minXLocation = this.projectService.template.width - frontCol.shiftedWidthMax;
				this.maxXLocation = this.projectService.template.width - colW / 2;
			} else {
				this.minXLocation = colW / 2;
				this.maxXLocation = frontCol.shiftedWidthMax;
			}

		}
	}

	private calculateGeneralXRangeExtremePoints() {
		const colW = this.current.width / 2;
		const colD = this.current.depth / 2;
		const currentArea = this.projectService.currentArea

		this.minXLocation = colW;
		if (currentArea === AreaType.Roof || currentArea === AreaType.Front) {
			this.maxXLocation = this.projectService.template.width - colW;
		} else if (currentArea === AreaType.Left || currentArea === AreaType.Right) {
			this.maxXLocation = this.projectService.template.depth - colD;
		}
	}


	private calculateYRangeExtremePoints() {
		let colsRelativeToCurrCol: ColumnProfile[] = this.getColumnsRelativeToCurrColumn();

		this.calcMinYPoint(colsRelativeToCurrCol);
		this.calcMaxYPoint(colsRelativeToCurrCol);
	}


	private getColumnsRelativeToCurrColumn(): ColumnProfile[] {
		if (this.isCurrentColLeft()) {
			return this.getColumnsForCurrentLeftSideColumn();
		} else if (this.isCurrentColRight()) {
			return this.getColumnsForCurrentRightSideColumn();
		} else {
			return this.getAllColumns();
		}
	}
	private isCurrentColLeft(): boolean {
		return this.current.rectOnRoof.x === SvgParams.START_X;
	}
	private getColumnsForCurrentLeftSideColumn(): ColumnProfile[] {
		if (this.current.rectOnFront) {
			return this.getAllColumns();
		} else {
			return this.profileService.leftSideElements[ElementType.Column];
		}
	}
	private isCurrentColRight(): boolean {
		return this.current.rectOnRoof.x === this.projectService.template.width * SvgParams.SCALE - this.current.rectOnRoof.w + SvgParams.START_X;
	}
	private getColumnsForCurrentRightSideColumn(): ColumnProfile[] {
		if (this.current.rectOnFront) {
			return this.getAllColumns();
		} else {
			return this.profileService.rightSideElements[ElementType.Column];
		}
	}
	private getAllColumns(): ColumnProfile[] {
		return this.profileService.getSortedColumns(CollectionName.get(AreaType.Roof), this.current.isRear ? AreaType.Rear : AreaType.Front);
	}


	private calcMinYPoint(colsRelativeToCurrCol: ColumnProfile[]): void {
		const sortedYArrayOfColumnsFronts = this.getSortedYArrayOfColumnsFronts(colsRelativeToCurrCol);
		const posOfCurrColInYArray = sortedYArrayOfColumnsFronts.indexOf(this.current.rectOnRoof.y + this.current.rectOnRoof.h);

		this.minYLocation = 0;

		if (this.current.rectOnFront) {
			const colD = this.current.depth;
			const bar = this.projectService.currentBar;
			this.minYLocation = this.projectService.template.depth - bar.shiftedDepthMax - colD;
		}

		if (posOfCurrColInYArray !== 0) {
			const newMinYLocation = this.getNewMinYLocation(sortedYArrayOfColumnsFronts, posOfCurrColInYArray, colsRelativeToCurrCol);
			if (this.minYLocation < newMinYLocation) {
				this.minYLocation = newMinYLocation;
			}
		}
	}
	private getSortedYArrayOfColumnsFronts(colsRelativeToCurrCol: ColumnProfile[]): number[] {
		const ySetOfColumnsFronts = new Set<number>();
		colsRelativeToCurrCol.forEach((c) => {
			ySetOfColumnsFronts.add(c.rectOnRoof.y + c.rectOnRoof.h);
		})

		return Array.from(ySetOfColumnsFronts).sort((y1: number, y2: number) => y1 > y2 ? 1 : -1);
	}
	private getNewMinYLocation(sortedYArrayOfColumnsFronts: number[], posOfCurrColInYArray: number, colsRelativeToCurrCol: ColumnProfile[]): number {
		const prevFrontY = sortedYArrayOfColumnsFronts[posOfCurrColInYArray - 1];
		const prevCol = colsRelativeToCurrCol.find((c: ColumnProfile) => (c.rectOnRoof.y + c.rectOnRoof.h) === prevFrontY);

		return (prevCol.rectOnRoof.y + prevCol.rectOnRoof.h - SvgParams.START_Y) / SvgParams.SCALE;
	}


	private calcMaxYPoint(colsRelativeToCurrCol: ColumnProfile[]): void {
		const sortedYArrayOfColumns = this.getSortedYArrayOfColumnsFronts(colsRelativeToCurrCol);
		const posOfCurrColInYArray = sortedYArrayOfColumns.indexOf(this.current.rectOnRoof.y + this.current.rectOnRoof.h);

		if (this.isCurrColLast(posOfCurrColInYArray, sortedYArrayOfColumns)) {
			this.maxYLocation = this.getCurrColMaxAllowedYLocation();
		} else {
			this.maxYLocation = this.getNewMaxYLocation(sortedYArrayOfColumns, posOfCurrColInYArray, colsRelativeToCurrCol)
		}
	}
	private isCurrColLast(posOfCurrColInYArray: number, sortedYArrayOfColumns: number[]): boolean {
		return posOfCurrColInYArray == sortedYArrayOfColumns.length - 1
	}
	private getCurrColMaxAllowedYLocation() {
		const bar = this.projectService.currentBar;
		return this.projectService.template.depth - bar.shiftedDepthMin - this.current.rectOnRoof.h / SvgParams.SCALE;
	}
	private getNewMaxYLocation(sortedYArrayOfColumns: number[], posOfCurrColInYArray: number, colsRelativeToCurrCol: ColumnProfile[]): number {
		const nextY = sortedYArrayOfColumns[posOfCurrColInYArray + 1];
		const nextCol = colsRelativeToCurrCol.find((c: ColumnProfile) => (c.rectOnRoof.y + c.rectOnRoof.h) === nextY);

		return (nextCol.rectOnRoof.y - this.current.rectOnRoof.h - SvgParams.START_Y) / SvgParams.SCALE;
	}

	openDialog(): void {
		this.dialogOpened = true;
		this.dialogMessage = $localize`:Messages|Glass wall removing confirmation:All glass walls will be deleted. Are you sure?`;
	}

	onDialogAction(status: boolean) {
		if (status) {
			this.projectService.clearCollections(ElementType.GlassWall);
			this.projectService.clearCollections(ElementType.GlassPart);
			this.projectService.clearCollections(ElementType.GlassWallDiamond);
			this.projectService.clearCollections(ElementType.GlassPartDiamond);
			this.projectService.clearCollections(ElementType.Frame);
			this.projectService.clearCollections(ElementType.Muntin);
		}
		this.dialogOpened = false;
		this.dialogMessage = '';
	}

	deleteElement() {
		this.projectService.deleteElement(this.current);
		this.projectService.recalculateDimensions();
	}

	moveConnector(side: ElementSide) {
		if (this.projectService.checkConnectorStatics(this.current, side)) {
			this.profileService.moveColumnConnector(this.current, side);
			this.projectService.emitChange();
		}
	}

	checkConnector(side: ElementSide) {
		var profile: FrontProfile | WallProfile = this.current[side.toString().toLowerCase() + 'Profile'];
		return profile.columns.length < 3;
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}

	setOutflow(side: string) {
		const s = ElementSide[side];
		if (this.current.outflow == s) {
			this.current.outflow = ElementSide.None;
		} else {
			this.current.outflow = s;
		}
		this.projectService.emitChange();
	}

	isOutflowPossible(side: string) {
		const s = ElementSide[side];

		const cols = this.profileService.getSortedColumns("roofElements", this.current.isRear ? AreaType.Rear : AreaType.Front);
		const currIdx = cols.findIndex(c => c.id == this.current.id);

		switch (s) {
			case ElementSide.Left:
				if (this.current.tag == Tags.LEFT_COLUMN_TAG || this.current.tag == Tags.LEFT_REAR_COLUMN_TAG) {
					return true;
				}
				if (this.profileService.checkElementsOnSide(AreaType.Front, cols[currIdx - 1], this.current)) {
					return false;
				}
				break;
			case ElementSide.Right:
				if (this.current.tag == Tags.RIGHT_COLUMN_TAG || this.current.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
					return true;
				}
				if (this.profileService.checkElementsOnSide(AreaType.Front, this.current, cols[currIdx + 1])) {	
					return false;
				}
				break;
			case ElementSide.Top:
				if (this.current.tag == Tags.RIGHT_COLUMN_TAG) {
					if (this.profileService.checkElementsOnSide(AreaType.Right)) {
						return false;
					}
				}
				if (this.current.tag == Tags.LEFT_COLUMN_TAG) {
					if (this.profileService.checkElementsOnSide(AreaType.Left)) {
						return false;
					}
				}
				break;
			case ElementSide.Bottom:
				if (this.current.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
					if (this.profileService.checkElementsOnSide(AreaType.Right)) {
						return false;
					}
				}
				if (this.current.tag == Tags.LEFT_REAR_COLUMN_TAG) {
					if (this.profileService.checkElementsOnSide(AreaType.Left)) {
						return false;
					}
				}
				break;
		}

		return true;
	}

	outflowValid() {
		if (this.current.outflow != ElementSide.None) {
			return true;
		}
		const outflowsCount = this.profileService.roofElements[ElementType.Column].filter((c: ColumnProfile) => c.outflow != ElementSide.None).length;
		const minOutflows = this.projectService.template.surface > OUTFLOW_SURFACE_TRESHOLD ? 2 : 1;
		return outflowsCount >= minOutflows;
	}

	resetColumnHeight(){
		if(this.current.foundationOnConcrete()){
			this.current.setHeight(this.templateHeight);
		} else {
			this.current.setHeight(this.templateHeight + this.current.foundation.size.height);
		}
		this.projectService.emitChange();
	}

	mergeProfilesTooltip() {
		if (this.mergeProfilesDisabled()) {
			return $localize`:Column|Merge profiles disabled tooltip:Cannot merge profiles`;
		}
		return $localize`:Column|Merge profiles tooltip:Merge profiles`;
	}

	mergeProfilesDisabled() {
		const max = this.current.leftProfile.maxLength;
		if (this.current.leftProfile.length + this.current.rightProfile.length > max) {
			return true;
		}
		return false;
	}

	mergeProfiles() {
		this.profileService.removeColumnConnector(this.current);
		this.refreshAndSave();
	}

	splitProfile() {
		this.profileService.addColumnConnector(this.current, this.projectService);
		this.refreshAndSave();
	}

	private refreshAndSave() {
		if (this.current.isFront) {
			this.frontService.changeArea();
		} else {
			this.rearService.changeArea();
		}
		this.projectService.emitChange();
	}

}
