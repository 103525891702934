<svg:g>
	<line *ngIf="item.line" [attr.x1]="item.line.x1" [attr.y1]="item.line.y1" [attr.x2]="item.line.x2" [attr.y2]="item.line.y2"
		[attr.stroke]="printMode ? 'black' : 'gray'" [attr.stroke-width]="item.line.strokeWidth"
		[attr.marker-end]="item.line.helper ? '': 'url(#upArrow)'" [attr.marker-start]="item.line.helper ? '': 'url(#downArrow)'">
	</line>
	<text *ngIf="item.text" [attr.x]="item.text.x" [attr.y]="item.text.y" [class]="'dimensions-text' + (printMode ? '-print' : '')"
		[attr.transform]="item.text.transform ? 'rotate(-90 ' + item.text.x + ',' + item.text.y + ')' : ''"
		[attr.text-anchor]="item.text.textAnchor" [attr.fill]="printMode ? 'black' : 'gray'">
			{{ (item.text.drop >= 0 ? item.text.drop + "&deg;": "") + (item.text.parathenses? " (" : "") + item.text.w.toFixed(0) + (item.text.parathenses ? ") " : "") }}
	</text>
	<line *ngIf="item.line?.start" [attr.x1]="item.line.start.x1" [attr.y1]="item.line.start.y1" [attr.x2]="item.line.start.x2"
		[attr.y2]="item.line.start.y2" [attr.stroke]="printMode ? 'black' : 'gray'" [attr.stroke-width]="item.line.strokeWidth" stroke-dasharray="4,4">
	</line>
	<line *ngIf="item.line?.end" [attr.x1]="item.line.end.x1" [attr.y1]="item.line.end.y1" [attr.x2]="item.line.end.x2" [attr.y2]="item.line.end.y2"
		[attr.stroke]="printMode ? 'black' : 'gray'" [attr.stroke-width]="item.line.strokeWidth" stroke-dasharray="4,4">
	</line>
</svg:g>