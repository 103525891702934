import { SvgParams } from "../../constants/constants";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { FrameProfile } from "../profiles/frame.model";
import { WallPart } from "./wall-part.model";

export class WallMuntinProfile extends FrameProfile {

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	public leftPart: WallPart;
	public rightPart: WallPart;

	get name(): string {
		return $localize`:Common|Profile:Profile`;
	}

	public override get cuttingable(): boolean {
		return false;
	}

	public setLocation(x: number) {
		this.rect.x = SvgParams.START_X + x * SvgParams.SCALE;
		this.leftPart.rebuild();
		this.rightPart.rebuild();
	}
}
