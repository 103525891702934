import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './_core/services/auth.service';
import { CrmService } from './_core/services/crm.service';
import { UrlParams } from './_core/constants/constants';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'Designer';
	isIframe = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private msalBroadcastService: MsalBroadcastService,
		private authService: AuthService,
		private crmService: CrmService,
		private activatedRoute: ActivatedRoute,
	) { }
	
	ngOnInit(): void {
		this.isIframe = window !== window.parent && !window.opener;

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_START),
				takeUntil(this._destroying$)
			)
			.subscribe((msg: EventMessage) => {
				this.authService.loginStarted = true;
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
				takeUntil(this._destroying$)
			)
			.subscribe((msg: EventMessage) => {
				this.authService.loginSuccess(msg.payload as AuthenticationResult);
			});
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE || msg.eventType === EventType.SSO_SILENT_FAILURE),
				takeUntil(this._destroying$)
			)
			.subscribe((_: EventMessage) => {
				this.authService.loginFail();
			});

		this.authService.init();

		this.activatedRoute.queryParams.subscribe(param => {
			if (param[UrlParams.AffinityId]) {
				this.crmService.init(param[UrlParams.AffinityId]);
			}
		});
	}

	// unsubscribe to events when component is destroyed
	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
