import { SvgParams, Tags } from "../../constants/constants";
import { AreaType } from "../../interfaces/IAreaType";
import { IPoint } from "../../interfaces/IPoint";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { Common } from "../common";
import { SideFinishProfile } from "../profiles/side-finish.model";
import { ElementStoreModel } from "./element-store.model";

export class SideFinishStoreModel extends ElementStoreModel {
	topGlassId: string;
	bottomGlassId: string;
	leftGlassId: string;
	rightGlassId: string;
	length: number;
	pathOnLeft: string;
	pathOnRight: string;
	rotation: number;
	anchor: IPoint;

	constructor(s: SideFinishProfile) {
		super(s.id, s.configId, s.tag, s.cuttingOnSite);

		this.length = s.length;
		this.leftGlassId = s.leftGlass?.id;
		this.rightGlassId = s.rightGlass?.id;
		this.rotation = s.rotation;
		this.anchor = s.anchor;
		this.storeLocationAndSize(s);
	}

	public static restore(s: SideFinishStoreModel, projectService: ProjectService) {
		var sideFin = new SideFinishProfile(AreaType.Roof, s.lineOnRoof.x1, s.lineOnRoof.x2, s.lineOnRoof.y1, s.lineOnRoof.y2, s.length, AreaType.Roof, projectService.template);
		sideFin.id = s.id;
		sideFin.lineOnRoof = s.lineOnRoof;
		sideFin.lineOnFront = s.lineOnFront;
		sideFin.cuttingOnSite = s.cuttingOnSite;
		sideFin.tag = s.tag;
		sideFin.length = s.length;
		sideFin.anchor = s.anchor;
		sideFin.rotation = s.rotation;
		sideFin.rectOnLeft = s.rectOnLeft;
		sideFin.rectOnRight = s.rectOnRight;

		if (s.tag == Tags.LEFT_SIDEFINISH_TAG && !sideFin.rectOnLeft) {
			const l = Math.round(s.length * SvgParams.SCALE);
			const h = s.lineOnFront.y2 - s.lineOnFront.y1

			const rd = Common.round(projectService.template.rearReinforcementDepth * SvgParams.SCALE);
			const x = SvgParams.START_X + (rd ? rd : 33) * SvgParams.SCALE;
			const y = s.lineOnFront.y1 - (projectService.template.backHeight - projectService.template.frontHeight) * SvgParams.SCALE;

			const rect: IRect = { x: x, y: y, w: l, h: h };
			sideFin.anchor = { x: rect.x, y: rect.y + h };
			sideFin.rotation = projectService.template.dropAngle;
			sideFin.rectOnLeft = rect;
		}

		if (s.tag == Tags.RIGHT_SIDEFINISH_TAG && !sideFin.rectOnRight) {
			const rd = Common.round(projectService.template.rearReinforcementDepth * SvgParams.SCALE);

			const l = Math.round(s.length * SvgParams.SCALE);
			const x = SvgParams.START_X + (projectService.template.depth - s.length - Common.round(rd ? rd : 33)) * SvgParams.SCALE;

			const h = s.lineOnFront.y2 - s.lineOnFront.y1
			const y = s.lineOnFront.y1 - (projectService.template.backHeight - projectService.template.frontHeight) * SvgParams.SCALE;

			const rect: IRect = { x: x, y: y, w: l, h: h };
			sideFin.anchor = { x: rect.x + rect.w, y: rect.y + rect.h };
			sideFin.rotation = -projectService.template.dropAngle;
			sideFin.rectOnRight = rect;
		}

		return sideFin;
	}

}
