import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RoofService} from 'src/app/_core/services/areas/roof.service';
import {ProfileService} from 'src/app/_core/services/profile.service';
import {ProjectService} from 'src/app/_core/services/project.service';
import {ChoosenProfileService} from '../../_core/services/chosen-elements/chosen-profile.service';
import {ToolboxItemService} from '../../_core/services/toolbox-item.service';
import { LED_STRIP_MAX_LENGTH, SvgParams } from '../../_core/constants/constants';
import {LedStripe} from '../../_core/models/profiles/led-stripe.model';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { ElementType } from 'src/app/_core/models/ToolboxModel';

@Component({
	selector: 'card-leds-stripe',
	templateUrl: './card-leds-stripe.component.html',
})
export class LEDsStripeComponent {
	@Output() showLedsDialog = new EventEmitter();

	current: LedStripe;

	@Input() public set items(items: LedStripe[]) {
		this.current = items[0];

		const leds: LedStripe[] = this.profileService.roofElements[ElementType.LedStripe];
		this.fullLength = Math.round(leds.reduce((acc, obj) => {
			return acc + obj.getLength();
		}, 0));

		this.waste = Math.round(leds.reduce((acc, obj) => {
			return acc + obj.waste;
		}, 0));

		this.pce = [...new Set(leds.map(item => item.stripeId))].length;

		this.segments = leds.length;
		this.unused = Math.round((LED_STRIP_MAX_LENGTH * this.pce) - this.fullLength - this.waste);
	}

	get locationX() {
		return (this.current.rect.x - SvgParams.START_X) / SvgParams.SCALE;
	}

	get length() {
		return this.current.getLength();
	}

	fullLength: number;
	waste: number;
	segments: number;
	unused: number;
	pce: number;

	constructor(
		public roofService: RoofService,
		public profileService: ProfileService,
		public projectService: ProjectService,
		public chosenProfileService: ChoosenProfileService,
		public toolboxItemService: ToolboxItemService,
	) { }

	onLocationXBtnClick(side: 'Left' | 'Right') {
		this.chosenProfileService.changeLedStripesLocationX(ElementSide[side]);
	}

	changeLocationXTooltip(side: 'Left' | 'Right'): string {
		this.chosenProfileService.isLedStripesChangeLocationXBtnDisable(ElementSide[side]);
		return this.chosenProfileService.ledsTooltip;
	}

	isChangeLocationXBtnDisable(side: string): boolean {
		return this.chosenProfileService.isLedStripesChangeLocationXBtnDisable(ElementSide[side]);
	}

	deleteLed() {
		this.chosenProfileService.getChosenProfile().forEach(l => {
			this.projectService.deleteElement(l);
		})
		this.chosenProfileService.setChosenProfile(null);
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}
}
