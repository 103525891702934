export class ProductParameters {
    name: string;
    version: string;
    errors: string[];
    isActive: boolean;
    isDraft: boolean;
    photo: string;
    width:number;
    minWidth: number;
    maxWidth: number;
    defaultWidth: number;
    minBackHeight: number;
    maxBackHeight: number;
    defaultBackHeight: number;
    depth: number;
    minDepth: number;
    maxDepth: number;
    defaultDepth: number;
    minDropAngle: number;
    maxDropAngle: number;
    defaultDropAngle: number;

    frontHeight: number;
    frontDiff: number;

    height: number;
    dropAngle: number;

}
