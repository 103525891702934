<div>
  <kendo-dialog
    i18n-title="Dialogs|'Information' title" title="Information"
    *ngIf="dialogOpened"
    (close)="close()"
    [minWidth]="250"
    [width]="450">

    <p style="margin: 30px; text-align: center;">
      {{ dialogMessage }}
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="close()" primary="true" i18n="Dialogs|'OK' button">OK</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>

