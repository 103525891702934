import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { User } from "../models/user";
import { TokenStorage } from "./token.storage";
import { silentRequest } from "../auth.config";

export interface AuthObserver {
	authStatusChanged(authStatus: boolean): void;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private msalService: MsalService,
		private http: HttpClient,
		private storage: TokenStorage,
		@Inject('BASE_URL') private baseUrl: string
	) {	}

	public init(): void {
		if (!this.msalService.instance.getActiveAccount()) {
			var accounts = this.msalService.instance.getAllAccounts();
			if (accounts.length > 0) {
				this.msalService.instance.setActiveAccount(accounts[0]);
			}
		}
		this.msalService.acquireTokenSilent(silentRequest).subscribe();
	}

	public get loginStarted(): boolean {
		return this.storage.loginStarted;
	}

	public set loginStarted(value: boolean) {
		this.storage.loginStarted = value;
	}

	private _authStatus: boolean = false;

	public set authStatus(value: boolean) {
		this._authStatus = value;
		for (const observer of this.observers) {
            observer.authStatusChanged(this.authStatus);
        }
	}

	public get authStatus(): boolean {
		return this._authStatus;
	}

	public get user(): User {
		return this.storage.user;
	}

	private observers: AuthObserver[] = []

	public attach(observer: AuthObserver): void {
        if (this.observers.includes(observer)) {
            return;
        }
        this.observers.push(observer);
	}

	public detach(observer: AuthObserver): void {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex === -1) {
            return;
        }
        this.observers.splice(observerIndex, 1);
    }

	public loginSuccess(result: AuthenticationResult): void {
		this.msalService.instance.setActiveAccount(result.account);
		this.loginLocal(result.idToken).subscribe(x => { }, e => { }, () => {
			this.loginStarted = false;
		});
	}

	public loginFail(): void {
		this.loginStarted = false;
		this.msalService.instance.setActiveAccount(null);
		this.logoutLocal();
	}

	private loginLocal(id_token: string): Observable<any> {
		var body = {
			id_token: id_token,
		};
		return this.http.post<any>(this.baseUrl + '/api/auth/login', body).pipe(tap(result => {
			this.storage.token = result.token;
			this.storage.user = result.user;
			this.authStatus = true;
		}, error => { 
			this.logoutLocal();
		}));
	}

	private logoutLocal(): void {
		this.storage.reset();
		this.authStatus = false;
	}

	/**
	 * Logs in the user.
	 * 
	 * The flow:
	 * * sends a client-side login request to AAD
	 * * the AAD returns some tokens (id_token is what matters here)
	 * * sends the id_token to backend
	 * * the backend verifies the id_token and if correct, it returns its token (local token)
	 * * the local token is then used to authorize against backend endpoints.
	 */
	public login(): void {
		if (this.loginStarted) {
			this.loginStarted = false;
			return;
		}

		this.loginStarted = true;

		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.msalService.instance.setActiveAccount(response.account);
					});
			} else {
				this.msalService.loginPopup()
					.subscribe((response: AuthenticationResult) => {
						this.msalService.instance.setActiveAccount(response.account);
					});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
			} else {
				this.msalService.loginRedirect();
			}
		}
	}

	public logout(): void {
		this.logoutLocal();
		this.msalService.logout().subscribe();
		this.authStatus = false;
	}

	public test(): Observable<any> {
		return this.http.get<any>(this.baseUrl + '/api/auth/test')
			.pipe(tap(result => {
				this.storage.user = result.user;
				this.authStatus = true;
			}));
	}
}
