import {Component, Input, ViewChild} from '@angular/core';
import {CardType, IPropertyCard} from '../../_core/interfaces/IPropertyCard';
import {ProjectService} from '../../_core/services/project.service';
import {ChosenGlassService} from '../../_core/services/chosen-elements/chosen-glass.service';
import {ToolboxItemService} from '../../_core/services/toolbox-item.service';
import {ProfileService} from '../../_core/services/profile.service';
import {ElementType } from '../../_core/models/ToolboxModel';
import {GlassWallDiamond} from '../../_core/models/glasses/glassWallDiamond.model';
import {RoofService} from '../../_core/services/areas/roof.service';
import { Common } from 'src/app/_core/models/common';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { GlassWall } from 'src/app/_core/models/glasses/glassWall.model';
import { SideBaseService } from 'src/app/_core/services/areas/side-base.service';
import { GlassWallBase } from 'src/app/_core/models/glasses/glassWall-base.model';

@Component({
	selector: 'card-glass-wall',
	templateUrl: './card-glass-wall.component.html',
	styleUrls: ['../card.component.css']
})
export class CardGlassWallComponent implements IPropertyCard {
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numBackHeight', { static: false }) numBackHeight: NumTbxComponent;
	@ViewChild('numFrontHeight', { static: false }) numFrontHeight: NumTbxComponent;
	@ViewChild('numHeight', { static: false }) numHeight: NumTbxComponent;
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: false }) numLocY: NumTbxComponent;
	@ViewChild('numMunt', { static: false }) numMunt: NumTbxComponent;

	type: CardType = CardType.GlassWall;

	@Input() public set items(items: GlassWall[] | GlassWallDiamond[]) {
		this._items = items;
		this.initItem();
	}

	//#region Fields

	public current: GlassWallBase;
	public currentDef: string;
	public errors: string[] = [];

	public maxWidth: number = 0;
	public minWidth: number = 0;

	private _items: GlassWall[] | GlassWallDiamond[];

	public changeWidthDisabled: boolean;
	public changeHeightDisabled: boolean;

	public openDialog: boolean = false;
	public dialogMessage: string;
	
	//#endregion

	//#region Properties

	public get currentGlassWidth() {
		const v = this.chosenGlassService.getWidth();
		return v;
	}
	public set currentGlassWidth(width: number) {
		const v = Math.round(this.currentGlassWidth);
		const w = Math.round(width); 
		if (v == w) {
			return;
		}
		this.chosenGlassService.setWidth(w);

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get backMinHeight() {
		const diff = this.backHeight - this.frontHeight;		
		return Math.round(this.current.defMinHeight + diff);
	}

	public get backHeight() {
		return this.chosenGlassService.getHeight();
	}
	public set backHeight(height: number) {
		if (Math.round(height) == Math.round(this.backHeight)) {
			return;
		}
		this.chosenGlassService.setHeight(height);

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}
	public get frontHeight() {
		return this.chosenGlassService.getFrontHeight();
	}
	public set frontHeight(height: number) {
		if (Math.round(height) == Math.round(this.frontHeight)) {
			return;
		}
		const diff = this.backHeight - this.frontHeight;		
		this.chosenGlassService.setHeight(height + diff);

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get currentGlassHeight() {
		return this.chosenGlassService.getHeight();
	}

	public set currentGlassHeight(height: number) {
		if (height == this.currentGlassHeight) {
			return;
		}		
		this.chosenGlassService.setHeight(height);

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get locationX() {
		return this.chosenGlassService.getLocationX();
	}
	public set locationX(value: number) {
		this.chosenGlassService.setLocationX(value);

		this.maxWidth = this.getMaxWidth();

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get locationY() {
		return this.chosenGlassService.getLocationY();
	}
	public set locationY(value: number) {
		this.chosenGlassService.setLocationY(value);

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.numLocY.rebind(this.locationY);
		this.projectService.emitChange();
	}

	public get muntinsCount() {
		return this.current.muntins.length;
	}
	public set muntinsCount(value: number) {

		this.setMuntins(value);
		this.projectService.emitChange();
		this.numMunt.rebind(value);
	}

	public get isWidthChangeDisabled(): boolean {
		return Boolean(
			this.projectService.readOnly
			|| this.changeWidthDisabled);
	}

	public get isHeightChangeDisabled(): boolean {
		return Boolean(
			this.projectService.readOnly
			|| this.changeHeightDisabled);
	}

	public get isLocationsChangeDisabled(): boolean {
		return Boolean(
			this.projectService.readOnly
			|| this.toolBSrvc.getCurrTool());
	}

	//#endregion

	constructor(
		public projectService: ProjectService,
		public roofService: RoofService,
		public chosenGlassService: ChosenGlassService,
		public toolBSrvc: ToolboxItemService,
		public profileService: ProfileService
	) {	}

	private initItem() {
		if (this._items.length >= 0) {
			this.current = this._items[0];
			this.current.refreshSurround(this.projectService.profileService);

			this.currentDef = this.current.configId;

			this.maxWidth = this.getMaxWidth();
			this.minWidth = this.getMinWidth();

			setTimeout(() => {
				if (this.numLocX && this.numLocX.valueChanged.observers.length == 0) {
					this.numLocX.valueChanged.subscribe(v => {
						this.locationX = v;
					});
				}

				if (this.numLocY && this.numLocY.valueChanged.observers.length == 0) {					
					this.numLocY.valueChanged.subscribe(v => {
						this.locationY = v;
					});
				}
				if (this.numMunt && this.numMunt.valueChanged.observers.length == 0) {
					this.numMunt.valueChanged.subscribe(v => {
						this.muntinsCount = v;
					});
				}
				this.numWidth.disabled = this.projectService.readOnly || this.isWidthChangeDisabled;
				if (this.numWidth.valueChanged.observers.length == 0) {
					this.numWidth.valueChanged.subscribe(v => {
						this.currentGlassWidth = v;
					});
				}

				if (this.current.type == ElementType.GlassWallDiamond) {			
					this.numBackHeight.disabled = this.projectService.readOnly || this.isHeightChangeDisabled;
					if (this.numBackHeight.valueChanged.observers.length == 0) {
						this.numBackHeight.valueChanged.subscribe(v => {
							this.backHeight = v;
						});
					}

					this.numFrontHeight.disabled = this.projectService.readOnly || this.isHeightChangeDisabled;
					if (this.numFrontHeight.valueChanged.observers.length == 0) {
						this.numFrontHeight.valueChanged.subscribe(v => {
							this.frontHeight = v;
						});
					}
				}
				
				if (this.current.type == ElementType.GlassWall) {
					this.numHeight.disabled = this.projectService.readOnly || this.isHeightChangeDisabled;
					if (this.numHeight.valueChanged.observers.length == 0) {
						this.numHeight.valueChanged.subscribe(v => {
							this.currentGlassHeight = v;
						})
					}
				}
			}, 10);
		} else {
			this.current == null;
		}
	}

	private getMaxWidth() {
		return this.current.maxWidth;
		// if (this.current instanceof GlassWallDiamond) {
		// 	return (this.projectService.CurrentAreaService as SideBaseService).getMaxWidthOfGWD(this.current);
		// } else {
		// 	this.current.maxWidth;
		// }
	}

	private getMinWidth() {
		return this.current.defMinWidth;
	}

	getGlassesTypeData() {
		switch (this.current.type) {
			case ElementType.GlassWall:
				return this.projectService.template.wallGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);
			case ElementType.GlassWallDiamond:
				return this.projectService.template.diamondGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);
			case ElementType.GlassPart:
				return this.projectService.template.wallGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);
			case ElementType.GlassPartDiamond:
				return this.projectService.template.diamondGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);
		}
	}

	typeChanged() {
		this.chosenGlassService.setType(this.currentDef);
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	setLock(isLocked: boolean) {
		this.chosenGlassService.setLock(isLocked);
		this.projectService.freezeProjProp();
	}

	delete() {
		for (const item of this._items) {
			this.projectService.deleteElement(item);			
		}
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
	}

	hang(toHang: boolean) {
		this.current.hanging = toHang;
		this.setMuntins();
		this.projectService.emitChange();
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}

	private setMuntins(value: number = null) {
		if (this.current.type == ElementType.GlassWallDiamond) {
			(this.projectService.CurrentAreaService as SideBaseService).createMuntins((this.current as GlassWallDiamond), value);
		} else {
			this.projectService.createMuntins((this.current as GlassWall), value);
		}
	}

}
