import { SvgParams } from "../constants/constants";
import { AreaType } from "../interfaces/IAreaType";
import { IPoint } from "../interfaces/IPoint";
import { IRect } from "../interfaces/IRect";
import { ProjectService } from "../services/project.service";
import { Common } from "./common";

export class Shape {

	private lt = SvgParams.LEFT_TOP_POINT_NUM;
	private rt = SvgParams.RIGHT_TOP_POINT_NUM;
	private lb = SvgParams.LEFT_BOTTOM_POINT_NUM;
	private rb = SvgParams.RIGHT_BOTTOM_POINT_NUM;

	constructor(
		private points: IPoint[],
		private angle: number) {
	}

	get path() {
		return ProjectService.getPath(this.points);
	}

	get isRect(): boolean {
		return this.points[this.lt].y == this.points[this.rt].y;
	}
	get isDiamond(): boolean {
		return this.points[this.lt].y != this.points[this.rt].y;
	}

	getPoints() {
		return this.points;
	}

	getRect() : IRect {
		const p = this.points;
		if (!this.isRect) {
			var y = Math.min(p[this.lt].y, p[this.rt].y);
			return {
				x: p[this.lt].x,
				y: y,
				w: p[this.rt].x - p[this.lt].x,
				h: p[this.lb].y - y
			};
		}

		return {
			x: p[this.lt].x,
			y: p[this.lt].y,
			w: p[this.rt].x - p[this.lt].x,
			h: p[this.lb].y - p[this.lt].y
		};
	}
	
	get width() {
		return this.points[this.rt].x - this.points[this.lt].x;
	}
	set width(v: number) {
		this.points[this.rt].x = this.points[this.lt].x + v;
		this.points[this.rb].x = this.points[this.rt].x;

		if (this.isDiamond) {
			var d = this.calcDrop();
			var a = Common.calculateDroppedY(this.width, d);
			if (this.heightLeft < this.heightRight) {
				this.points[this.rt].y = this.points[this.lt].y + a;
			} else {
				this.points[this.lt].y = this.points[this.rt].y - a;
			}
		}
	}

	get heightLeft() {
		return this.points[this.lb].y - this.points[this.lt].y;
	}
	get heightRight() {
		return this.points[this.rb].y - this.points[this.rt].y;
	}
	get height() {
		return Math.max(this.heightLeft, this.heightRight);
	}
	get lessHeight() {
		return Math.min(this.heightLeft, this.heightRight);
	}
	set height(v: number) {
		if (this.isRect) {
			this.points[this.lb].y = this.points[this.lt].y + v;
			this.points[this.rb].y = this.points[this.rt].y + v;
			return;
		}
		const lh = this.heightLeft;
		const rh = this.heightRight;
		const diff = rh - lh;
		if (diff < 0) { // left side
			this.points[this.lb].y = this.points[this.lt].y + v;
			this.points[this.rb].y = this.points[this.rt].y + v + diff;
		} else { // right side
			this.points[this.rb].y = this.points[this.rt].y + v;
			this.points[this.lb].y = this.points[this.lt].y + v - diff;
		}
	}

	get locationX() {
		return this.points[this.lt].x;
	}
	set locationX(v: number) {
		const w = this.width;
		const oldY = this.points[this.lt].y;
		const oldLoc = this.points[this.lt].x;

		this.points[this.lt].x = v;
		this.points[this.lb].x = v;
		this.points[this.rt].x = v + w;
		this.points[this.rb].x = v + w;

		if (this.isDiamond) {
			var d = this.calcDrop();
			var diff = v - oldLoc;			
			var a = Common.calculateDroppedY(diff, d);
			this.points[this.lt].y += a;
			this.points[this.rt].y += a;
		}

	}
	get rightX() {
		return this.points[this.rt].x;
	}
	get locationY() {
		const y1 = this.points[this.lt].y;
		const y2 = this.points[this.rt].y;
		return Math.min(y1, y2);
	}
	set locationY(v: number) {
		const h = this.height;
		this.points[this.lt].y = v;
		this.points[this.rt].y = v;
		this.points[this.lb].y = v + h;
		this.points[this.rb].y = v + h;
	}
	get locationYleft() {
		return this.points[this.lt].y;
	}
	get locationYright() {
		return this.points[this.rt].y;
	}
	get bottomY() {
		return this.points[this.lb].y;
	}

	private adjustHorizontalOnLeftSide(left: IRect, right: IRect) {
		if (this.locationY >= right.y) {
			return;
		} 

		if (right.y > this.points[SvgParams.RIGHT_TOP_POINT_NUM].y) {
			const distToTop = this.points[SvgParams.LEFT_TOP_POINT_NUM].y - left.y;
			if (distToTop < 0) {
				const x3 = Common.calculateDroppedX(this.height, this.angle);
				this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x = this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x + x3;
				this.points[SvgParams.RIGHT_TOP_POINT_NUM] = this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM];
			} else {
				const x = Common.calculateDroppedX(distToTop, this.angle);
				if (right.y < this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y) {
					this.points[SvgParams.RIGHT_TOP_POINT_NUM].x = this.points[SvgParams.LEFT_TOP_POINT_NUM].x + x;
					this.points.splice(2, 0, { x: right.x, y: right.y });
				} else {
					const xx = Common.calculateDroppedX(this.height, this.angle);
					this.points[SvgParams.RIGHT_TOP_POINT_NUM].x = this.points[SvgParams.LEFT_TOP_POINT_NUM].x + x;
					this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x = this.points[SvgParams.RIGHT_TOP_POINT_NUM].x + xx;
				}
			}
		}
	} 

	private adjustHorizontalOnRightSide(left: IRect, right: IRect) {
		if (this.locationY >= left.y) {
			return;
		}		

		if (left.y > this.points[SvgParams.LEFT_TOP_POINT_NUM].y) {
			const distToTop = this.points[SvgParams.RIGHT_TOP_POINT_NUM].y - right.y;
			if (distToTop < 0) {
				const x3 = Common.calculateDroppedX(this.height, this.angle);
				this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x = this.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - x3;
				this.points[SvgParams.LEFT_TOP_POINT_NUM] = this.points[SvgParams.LEFT_BOTTOM_POINT_NUM];
			} else {
				const x = Common.calculateDroppedX(distToTop, this.angle);
				if (left.y < this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y) {
					this.points[SvgParams.LEFT_TOP_POINT_NUM].x = this.points[SvgParams.RIGHT_TOP_POINT_NUM].x - x;
					// this.points.splice(1, 0, { x: left.x, y: left.y });
					this.points.push({ x: left.x, y: left.y });
				} else {
					const xx = Common.calculateDroppedX(this.height, this.angle);
					this.points[SvgParams.LEFT_TOP_POINT_NUM].x = this.points[SvgParams.RIGHT_TOP_POINT_NUM].x - x;
					this.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x = this.points[SvgParams.LEFT_TOP_POINT_NUM].x - xx;
				}
			}
		}
	}

	public adjustHorizontal(area: AreaType, left: IRect, right: IRect) {
		if (area == AreaType.Left) {
			this.adjustHorizontalOnLeftSide(left, right);
		} else {
			this.adjustHorizontalOnRightSide(left, right);
		}
	}

	public adjustVertical(area: AreaType, pattern: Shape) {
		var d = this.calcDrop();

		if (pattern) {
			this.points[this.lt].y = pattern.points[this.rt].y;
			this.points[this.rt].y = pattern.points[this.rt].y;
		}
		var a = Common.calculateDroppedY(this.width, d) * (area == AreaType.Left ? 1 : -1);
		this.points[this.rt].y += a;
	}

	public static fromRect(rect: IRect, angle: number): Shape {
		return new Shape(Common.toPoints(rect), angle);
	}

	public static fromPoints(points: IPoint[], angle: number): Shape {

		var sp: IPoint[] = [];
		points.forEach(p => {
			sp.push({
				x: p.x,
				y: p.y
			});
		});
		return new Shape(sp, angle);
	}

	private calcDrop() {
		const lh = this.heightLeft;
		const rh = this.heightRight;
		const diff = rh - lh;
		var d = this.angle;
		if (diff > 0) { // right side
			d *= -1;
		}

		return d;
	}
}