import { FrameProfile } from "../profiles/frame.model";
import { IRect } from "../../interfaces/IRect";
import { AnySide, ElementSide } from "../../interfaces/IElementSide";
import { Orientation } from "../ToolboxModel";
import { WallMuntinProfile } from "./wall-muntin.model";
import { Wall } from "./wall.model";
import { SvgParams } from "../../constants/constants";
import { Shape } from "../shape";
import { Common } from "../common";
import { AreaType } from "../../interfaces/IAreaType";

class PaneLayout {
	size: number;
	count: number;
}

export class WallPart {

	private _panels: Shape[];
	get panels() {
		return this._panels;
	}

	private get inTop() {
		return this.inframes.get(ElementSide.Top).rect;
	}
	private get inBott() {
		return this.inframes.get(ElementSide.Bottom).rect;
	}
	private get inLeft() {
		return this.inframes.get(ElementSide.Left).rect;
	}
	private get inRight() {
		return this.inframes.get(ElementSide.Right).rect;
	}

	private inframes: Map<ElementSide, FrameProfile>;

	get frames(): FrameProfile[] {
		var fr: FrameProfile[] = [];
		this.inframes.forEach((value) => {
			fr.push(value);
		});
		return fr;
	}

	get panelSize() {
		return this.master.def.panel.size.height * SvgParams.SCALE;
	}
	get vertical() {
		return this.master.def.vertical;
	}
	get leftovers() {
		return this.master.leftovers;
	}
	get locationX() {
		return this.inLeft.x;
	}
	get width() {
		return this.inRight.x - this.inLeft.x + this.inLeft.w;
	}
	get minWidth() {
		return 100;
	}
	get isDiamond() {
		return this.master.shape.isDiamond;
	}
	get area() {
		return this.master.area;
	}

	getLayout() {
		var layout: PaneLayout[] = [];
		const prop = this.vertical ? "w" : "h";
		this._panels.forEach(p => {
			var l = layout.find(y => y.size == p[prop]);
			if (!l) {
				layout.push({ size: p[prop], count: 1 });
			} else {
				l.count++;
			}
		});
		return layout;
	}

	constructor(
		private master: Wall,
		private outframes: Map<ElementSide, FrameProfile>,
		private drop: number = null,
		private muntinOnLeft: WallMuntinProfile = null,
		private muntinOnRight: WallMuntinProfile = null) {
		
		const left = this.outframes.get(ElementSide.Left).rect;
		const right = this.outframes.get(ElementSide.Right).rect;
		const top = this.outframes.get(ElementSide.Top).rect;
		const bott = this.outframes.get(ElementSide.Bottom).rect;

		var rect: IRect = {
			x: left.x + left.w,
			y: top.y + top.h,
			w: right.x - (left.x + left.w),
			h: bott.y - top.y + top.h
		};

		const def = this.master.def.frame;

		this.inframes = new Map<ElementSide, FrameProfile>();
		this.inframes.set(ElementSide.Left,
			new FrameProfile(rect.x, rect.y, rect.h, def.size, def.color, Orientation.Vertical, def.id));

		this.inframes.set(ElementSide.Right,
			new FrameProfile(rect.x + rect.w, rect.y, rect.h, def.size, def.color, Orientation.Vertical, def.id));

		this.inframes.set(ElementSide.Top,
			new FrameProfile(rect.x, rect.y, rect.w, def.size, def.color, Orientation.Horizontal, def.id));

		this.inframes.set(ElementSide.Bottom,
			new FrameProfile(rect.x, rect.y + rect.h, rect.w, def.size, def.color, Orientation.Horizontal, def.id));


		this.calculateFrames();
		this.calculatePanels();
	}

	private calculateFrames() {
		const left = this.muntinOnLeft ?? this.outframes.get(ElementSide.Left);
		const right = this.muntinOnRight ?? this.outframes.get(ElementSide.Right);

		const top = this.outframes.get(ElementSide.Top);
		const bott = this.outframes.get(ElementSide.Bottom);

		var inleft = this.inframes.get(ElementSide.Left);
		inleft.rect.x = left.rect.x + left.rect.w;
		inleft.rect.y = left.rect.y;
		if (this.isDiamond) {
			if (this.area == AreaType.Left) {
				inleft.rect.h = left instanceof WallMuntinProfile ? left.rect.h : bott.rect.y - left.rect.y - top.rect.h;
			} else {
				inleft.rect.y = left.rect.y;
				inleft.rect.h = right instanceof WallMuntinProfile ? left.rect.h : bott.rect.y - left.rect.y - top.rect.h;
			}
			if (this.muntinOnLeft) {
				var dy = Common.calculateDroppedY(left.rect.w, this.drop);
				inleft.rect.y = left.rect.y;
			}
		} else if (this.area == AreaType.Left || this.area == AreaType.Right) {
			this.inLeft.y = top.rect.y + top.rect.h;
		}
		inleft.rect.h = Math.max(bott.rect.y - inleft.rect.y, 0);

		var inright = this.inframes.get(ElementSide.Right);
		inright.rect.x = right.rect.x - inright.rect.w;
		inright.rect.y = inleft.rect.y;
		inright.rect.h = inleft.rect.h;
		if (this.isDiamond) {
			inright.rect.y = right.rect.y;
			inright.rect.h = Math.max(bott.rect.y - right.rect.y - top.rect.h, 0);
		}

		var intop = this.inframes.get(ElementSide.Top);
		intop.rect.x = inleft.rect.x + inleft.rect.w;
		intop.rect.y = top.rect.y + top.rect.h;
		intop.rect.w = inright.rect.x - (inleft.rect.x + inleft.rect.w);

		if (this.isDiamond) {
			if (this.muntinOnLeft) {
				var dy = Common.calculateDroppedY(left.rect.w, this.drop);
				if (this.area == AreaType.Left) {
					intop.rect.y = inleft.rect.y + dy;
				} else {
					intop.rect.y = inright.rect.y;
					// intop.rect.x = inright.rect.x;
				}
				if (this.area == AreaType.Right) {
					intop.rotation = `rotate(-${this.drop}, ${intop.rect.x + intop.rect.w}, ${intop.rect.y})`;
				} else {
					intop.rotation = `rotate(${this.drop}, ${intop.rect.x}, ${intop.rect.y})`;
				}
			} else {
				intop.rotation = top.rotation;
				if (this.area == AreaType.Left) {
					// intop.rect.y = inleft.rect.y + dy;
				} else {
					intop.rect.y = inright.rect.y + intop.rect.h / 2;
					intop.rotation = `rotate(-${this.drop}, ${intop.rect.x + intop.rect.w} , ${intop.rect.y})`;
				}
			}
		}

		if (this.isDiamond) {
			const neww = Common.calculateDroppedWidth(inright.rect.x - (inleft.rect.x + inleft.rect.w), this.drop);
			const diff = neww - intop.rect.w;
			intop.rect.w = neww;
			if (this.area == AreaType.Right) {
				intop.rect.x -= diff;
			}
		}

		var inbott = this.inframes.get(ElementSide.Bottom);
		inbott.rect.x = inleft.rect.x + inleft.rect.w;
		inbott.rect.y = bott.rect.y - inbott.rect.h;
		inbott.rect.w = inright.rect.x - (inleft.rect.x + inleft.rect.w);
	}

	private calculateVerticalPanels() {
		var y = this.inTop.y + this.inTop.h;
		if (this.isDiamond) {
			y = this.inLeft.y;
		}
		var maxx = this.inRight.x;
		var startx = this.inLeft.x + this.inLeft.w;
		const wallWidth = (maxx - startx);
		var panCount = wallWidth / this.panelSize;
		var firstPane = 0;
		var lastPane = 0;
		if (panCount > Math.trunc(panCount)) {
			panCount = Math.trunc(panCount);
			var rest = wallWidth - this.panelSize * panCount;

			switch (this.leftovers) {
				case AnySide.Left:
					firstPane = rest;
					panCount++;
					break;
				case AnySide.Right:
					lastPane = rest;
					panCount++;
					break;
				case AnySide.LeftRight:
					firstPane = (this.panelSize + rest) / 2;
					lastPane = panCount > 0 ? firstPane : 0;
					break;
			}
		}

		if (firstPane > 0) {
			var s = Shape.fromRect({
				x: startx,
				y: y,
				w: firstPane,
				h: this.inBott.y - y
			}, this.drop);
			if (this.isDiamond) {
				s.adjustVertical(this.area, null);
			}
			this._panels.push(s);
			startx += firstPane;
		}

		for (var i = 1; i < panCount; i++) {
			var s = Shape.fromRect({
				x: startx,
				y: y,
				w: this.panelSize,
				h: this.inBott.y - y
			}, this.drop);
			if (this.isDiamond) {
				s.adjustVertical(this.area, this._panels[this._panels.length - 1]);
			}
			this._panels.push(s);
			startx += this.panelSize;
		}

		if (lastPane > 0) {
			var s = Shape.fromRect({
				x: startx,
				y: y,
				w: lastPane,
				h: this.inBott.y - y
			}, this.drop);
			if (this.isDiamond) {
				s.adjustVertical(this.area, this._panels[this._panels.length - 1]);
			}
			this._panels.push(s);
		}
	}

	private calculateHorizontalPanels() {

		const left = this.muntinOnLeft ?? this.inframes.get(ElementSide.Left);
		const right = this.muntinOnRight ?? this.inframes.get(ElementSide.Right);
		var starty = this.area == AreaType.Left ? left.rect.y : right.rect.y;
		var maxy = this.inBott.y;
		var x = this.inLeft.x + this.inLeft.w;

		const wallHeight = (maxy - starty);
		var panCount = wallHeight / this.panelSize;
		var firstPane = 0;
		var lastPane = 0;
		var panelHeight = this.panelSize;
		if (panCount < 1) {
			panelHeight = this.panelSize * panCount;
			panCount = 2;
		} else if (panCount > Math.trunc(panCount)) {
			panCount = Math.trunc(panCount);
			var rest = wallHeight - this.panelSize * panCount;

			switch (this.leftovers) {
				case AnySide.Left:
					firstPane = rest;
					panCount++;
					break;
				case AnySide.Right:
					lastPane = rest;
					panCount++;
					break;
				case AnySide.LeftRight:
					firstPane = (panelHeight + rest) / 2;
					lastPane = firstPane;
					break;
			}
		}

		const panw = this.inBott.w;

		if (firstPane > 0) {
			var s = Shape.fromRect({
				x: x,
				y: starty,
				w: panw,
				h: firstPane
			}, this.drop);
			if (this.isDiamond) {
				s.adjustHorizontal(this.area, this.inLeft, this.inRight);
			}
			this._panels.push(s);
			starty += firstPane;
		}

		for (var i = 1; i < panCount; i++) {
			var s = Shape.fromRect({
				x: x,
				y: starty,
				w: panw,
				h: panelHeight
			}, this.drop);
			starty += panelHeight;
			if (this.isDiamond) {
				s.adjustHorizontal(this.area, this.inLeft, this.inRight);
			}
			this._panels.push(s);
		}

		if (lastPane > 0) {
			var s = Shape.fromRect({
				x: x,
				y: starty,
				w: panw,
				h: lastPane
			}, this.drop)
			if (this.isDiamond) {
				s.adjustHorizontal(this.area, this.inLeft, this.inRight);
			}
			this._panels.push(s);
		}
	}

	public calculatePanels() {
		this._panels = [];

		if (this.vertical) {
			this.calculateVerticalPanels();
		} else {
			this.calculateHorizontalPanels();
		}
	}

	public rebuild() {
		this.calculateFrames();
		this.calculatePanels();
	}
}
