import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../../_core/services/profile.service';
import { ExtraOption } from '../../_core/models/extra-option.model';
import { ChosenExtraOptionService } from '../../_core/services/chosen-elements/chosen-extra-option.service';
import { Subscription } from 'rxjs';
import { ToolboxItemService } from 'src/app/_core/services/toolbox-item.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { ExtraOptionService } from 'src/app/_core/services/extra-option.service';
import { ElementIconType, ToolboxItem, ToolboxItemType } from 'src/app/_core/models/ToolboxModel';

@Component({
	selector: 'app-extras-options',
	templateUrl: './extras-options.component.html',
	styleUrls: ['./extras-options.component.css', './../../../assets/styles/svg-component.css']
})
export class ExtrasOptionsComponent implements OnInit, OnDestroy {

	chosenOption: ExtraOption = null;
	subscription = new Subscription();
	public currentTool: ToolboxItem;
	public isHover : boolean = false;

	constructor(
		public profileService: ProfileService,
		private chosenExtraOptionService: ChosenExtraOptionService,
		private extraService: ExtraOptionService,
		public projectService: ProjectService,
		private toolBSrvc: ToolboxItemService,
	) { }

	ngOnInit(): void {
		this.subscription = this.chosenExtraOptionService.choseOptionSubj.subscribe((option: ExtraOption | null) => {
			this.chosenOption = option;
		})
		this.toolBSrvc.currentTool$.subscribe((ct: ToolboxItem) => {
			this.currentTool = ct;
		}); 
	} 

	onBgClick(){
		if (this.currentTool?.type == ToolboxItemType.Extra && this.extraService.createExtraOption(this.currentTool.id, null, 0)) {
			this.projectService.emitChange();
			this.toolBSrvc.onBgClick();
		}
	}

	choseOption(option: ExtraOption) {
		if(this.currentTool == null){
			this.chosenExtraOptionService.setChosenOption(option);
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	changeIcon(){
		if (this.currentTool?.type == ToolboxItemType.Extra && this.isHover == true) {
			return true;
		}
		return false;
	}
}
