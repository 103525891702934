import { IAreaService } from "../../interfaces/IAreaService";
import { GlassWallBase } from "../../models/glasses/glassWall-base.model";
import { ProjectService } from "../project.service";

export abstract class GlassWallsBaseService {

	constructor(public projectService: ProjectService) {}
	public currentAreaService: IAreaService;

	public abstract setLocationX(glass: GlassWallBase, value: number);
	public abstract setLocationY(glass: GlassWallBase, value: number);
	public abstract setWidth(glass: GlassWallBase, value: number);
	public abstract setHeight(glass: GlassWallBase, value: number);

}