import { Injectable } from "@angular/core";
import { SvgParams } from "../../constants/constants";
import { AreaType } from "../../interfaces/IAreaType";
import { Common } from "../../models/common";
import { GlassWallDiamond } from "../../models/glasses/glassWallDiamond.model";
import { GlassWallsBaseService } from "./glass-walls-base.service";
import { ProjectService } from "../project.service";
import { Shape } from "../../models/shape";

@Injectable({
	providedIn: 'root'
})
export class GlassWallDiamondService extends GlassWallsBaseService {


	private setLocationAndWidth(diamond: GlassWallDiamond, xValue: number, wValue: number) {
		const sideCorrection = this.projectService.currentArea === AreaType.Left ? 0 : this.projectService.template.depth;

		var srv = this.currentAreaService;
		if (!srv) {
			srv = this.projectService.CurrentAreaService;
		}
		const bh = Shape.fromPoints(srv.getGWDStartPoints(), diamond.drop).height / SvgParams.SCALE

		const leftXPosHeight = Common.getYOnTriangle(bh, Math.abs(sideCorrection - xValue), diamond.drop) * SvgParams.SCALE;
		const rightXPosHeight = Common.getYOnTriangle(bh, Math.abs(sideCorrection - (xValue + wValue)), diamond.drop) * SvgParams.SCALE;

		const diamondBottomY = diamond.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		const projBottomY = diamond.currentSideService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;

		const leftY = diamondBottomY - leftXPosHeight + projBottomY - diamondBottomY;
		const rightY = diamondBottomY - rightXPosHeight + projBottomY - diamondBottomY;

		const newX = SvgParams.START_X + xValue * SvgParams.SCALE;

		diamond.points[SvgParams.LEFT_TOP_POINT_NUM].y = leftY - this.getYcorrection(AreaType.Right);
		diamond.points[SvgParams.LEFT_TOP_POINT_NUM].x = newX;
		diamond.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x = newX;

		diamond.setWidth(wValue, rightY - this.getYcorrection(this.projectService.currentArea));

		this.projectService.manageFrameAfterGlassChange(diamond);
		diamond.currentSideService.createMuntins(diamond, null);
		diamond.adjustSurround();
	}

	public setLocationX(diamond: GlassWallDiamond, value: number) {
		this.setLocationAndWidth(diamond, value, diamond.width);
	}
	public setWidth(diamond: GlassWallDiamond, value: number) {
		this.setLocationAndWidth(diamond, (diamond.points[SvgParams.LEFT_TOP_POINT_NUM].x - SvgParams.START_X) / SvgParams.SCALE, value);
	}

	constructor(public projectService: ProjectService) {
		super(projectService);
	}

	private getYcorrection(area: AreaType) {
		return this.projectService.currentArea === area ? 1 : 0;
	}


	public setLocationY(glass: GlassWallDiamond, value: number) {
		throw new Error("Method not implemented.");
	}


	public setHeight(glass: GlassWallDiamond, value: number) {
		if (value > glass.maxHeight) {
			value = glass.maxHeight;
		}
		glass.height = Common.round(value);
		this.projectService.manageFrameAfterGlassChange(glass);
		glass.currentSideService.createMuntins(glass, null);
	}

	public alongHeight(glass: GlassWallDiamond, diff: number) {
		if (glass.height + diff > glass.defMaxHeight) {
			glass.height = glass.defMaxHeight;
		}
		glass.height += Common.round(diff);
		this.projectService.manageFrameAfterGlassChange(glass);
		glass.currentSideService.createMuntins(glass, null);
		glass.adjustHeight(diff);
	}

}