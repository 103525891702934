import { ElementType, Orientation } from "../ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { Profile } from "../profiles/profile.model";
import { FrameProfile } from "../profiles/frame.model";
import { AreaType, RectName } from "../../interfaces/IAreaType";
import { IRect } from "../../interfaces/IRect";
import { SvgParams } from "../../constants/constants";
import { IWallInfo } from "../../interfaces/IWallInfo";
import { AnySide, ElementSide } from "../../interfaces/IElementSide";
import { VerticalElement } from "../vertical-element";
import { Common } from "../common";
import { WallPart } from "./wall-part.model";
import { WallMuntinProfile } from "./wall-muntin.model";
import { Shape } from "../shape";
import { ProjectService } from "../../services/project.service";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { IAreaService } from "../../interfaces/IAreaService";
import { IPoint } from "../../interfaces/IPoint";

class PaneLayout {
	size: number;
	count: number;
}

export class Wall extends VerticalElement {

	//#region Base properties 

	get type() {
		return ElementType.Wall;
	}

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	get name(): string {
		return $localize`:Common|Wall:Wall`;
	}

	get configId(): string {
		return this.def.id;
	}

	get surfaceArea() {
		return Common.round((this.width * this.height) / 1000000); // milimeters2 to meters2
	}

	private _shape: Shape;
	get shape(): Shape {
		return this._shape;
	}

	get rect(): IRect {
		return this.shape.getRect();
	}
	get rectOnFront() {
		return this.rect;
	}
	get rectOnLeft() {
		return this.rect;
	}
	get rectOnRight() {
		return this.rect;
	}
	get leftX(): number {
		return this.shape.locationX
	}
	get rightX(): number {
		return this.shape.rightX;
	}
	get topY(): number {
		return this.shape.locationY;
	}
	get bottomY(): number {
		return this.shape.bottomY;
	}
	get points(): IPoint[] {
		return Common.toPoints(this.rect)
	}
	get defMaxWidth(): number {
		return (this.def.maxWidth ?? 0) == 0 ? this.projectService.template.maxWidth : this.def.maxWidth;
	}
	get defMaxHeight(): number {
		return (this.def.maxHeight ?? 0) == 0 ? this.projectService.template.maxBackHeight : this.def.maxHeight;
	}
	get defMinWidth(): number {
		return this.def.minWidth;
	}
	get defMinHeight(): number {
		return this.def.minHeight;
	}

	get panels() {
		var _panels: Shape[] = [];
		this._parts.forEach(p => {
			_panels.push(...p.panels);
		});
		return _panels;
	}

	get panelColor() {
		return this.def.panel.color;
	}

	private _leftovers: AnySide;
	get leftovers() {
		return this._leftovers;
	}
	set leftovers(v: AnySide) {
		this._leftovers = v;
		this.calculateParts();
	}

	public get outFrames() {
		return this._outframes;
	}

	//#endregion

	//#region Frames
	public setOutFrames(top: FrameProfile, bott: FrameProfile, left: FrameProfile, right: FrameProfile) {
		this._outframes.set(ElementSide.Top, top);
		this._outframes.set(ElementSide.Bottom, bott);
		this._outframes.set(ElementSide.Left, left);
		this._outframes.set(ElementSide.Right, right);
		this.calculateFrames();
	}

	private _outframes: Map<ElementSide, FrameProfile>;
	private get outTop() {
		return this._outframes.get(ElementSide.Top).rect;
	}
	private get outBott() {
		return this._outframes.get(ElementSide.Bottom).rect;
	}
	private get outLeft() {
		return this._outframes.get(ElementSide.Left).rect;
	}
	private get outRight() {
		return this._outframes.get(ElementSide.Right).rect;
	}

	private _parts: WallPart[];

	private _muntins: FrameProfile[];
	get muntins() {
		return this._muntins;
	}

	get frames(): FrameProfile[] {
		var fr: FrameProfile[] = [];
		this._parts.forEach(p => {
			fr.push(...p.frames);
		});
		this._outframes.forEach((value) => {
			if (value.rect.w > 0) {
				fr.push(value);
			}
		});
		return fr;
	}
	//#endregion

	//#region Size

	get width(): number {
		return Math.round(this.shape.width / SvgParams.SCALE);
	}
	set width(v: number) {
		this.shape.width = v * SvgParams.SCALE;
		this.init();
	}

	get height(): number {
		return Math.round(this.shape.height / SvgParams.SCALE);
	}
	set height(v: number) {
		this.shape.height = v * SvgParams.SCALE;
		this.init();
	}

	get minHeight(): number {
		return this.def.minHeight;
	}

	//#endregion

	//#region Location

	get locationX(): number {
		return Math.round((this.shape.locationX - SvgParams.START_X) / SvgParams.SCALE);
	}
	set locationX(v: number) {
		this.shape.locationX = SvgParams.START_X + v * SvgParams.SCALE;
		this.init();
	}

	get locationY(): number {
		return Math.round((this.shape.locationY - SvgParams.START_Y) / SvgParams.SCALE);
	}
	set locationY(v: number) {
		this.shape.locationY = SvgParams.START_Y + v * SvgParams.SCALE;
		this.init();
	}

	//#endregion

	private getMuntinX() {
		var space = this.shape.width - this.outLeft.w - this.outRight.w;
		const w = this.def.muntin.size.width * SvgParams.SCALE;
		const x = this.shape.locationX + space / 2 - w / 2 + this.outLeft.w;

		return x;
	}

	getLayout() {
		var layout: PaneLayout[] = [];
		const prop = this.def.vertical ? "width" : "height";
		this.panels.forEach(p => {
			var l = layout.find(y => y.size == p[prop]);
			if (!l) {
				layout.push({ size: p[prop], count: 1});
			} else {
				l.count++;
			}
		});
		return layout;
	}

	changeDef(newDef: IWallInfo) {
		this.def = newDef;
		this._parts.forEach(p => {
			p.rebuild();
		});
	}

	constructor(		
		public def: IWallInfo,
		protected projectService: ProjectService,
		sideService: IAreaService,
		public area: AreaType,
		public leftProfile: Profile,
		public rightProfile: Profile,
		points: Shape) {

		super(projectService, sideService);

		this._shape = points;

		this.id = this.type + "_" + uuidv4();
		
		this._outframes = new Map<ElementSide, FrameProfile>();
		this._outframes.set(ElementSide.Left,
			new FrameProfile(this.shape.locationX, this.shape.locationYleft, this.shape.heightLeft,
				this.def.muntin.size, this.def.muntin.color, Orientation.Vertical, this.def.muntin.id));

		this._outframes.set(ElementSide.Right,
			new FrameProfile(this.shape.rightX, this.shape.locationYright, this.shape.heightRight,
				this.def.muntin.size, this.def.muntin.color, Orientation.Vertical, this.def.muntin.id));

		this._outframes.set(ElementSide.Top,
			new FrameProfile(this.locationX, this.shape.locationY, this.shape.width,
				this.def.muntin.size, this.def.muntin.color, Orientation.Horizontal, this.def.muntin.id));

		this._outframes.set(ElementSide.Bottom,
			new FrameProfile(this.locationX, this.shape.bottomY, this.shape.width,
				this.def.muntin.size, this.def.muntin.color, Orientation.Horizontal, this.def.muntin.id));

		this._leftovers = AnySide.LeftRight;

		this.leftProfile = this._outframes.get(ElementSide.Left);
		this.topProfile = this._outframes.get(ElementSide.Top);
		this.bottomProfile = this._outframes.get(ElementSide.Bottom);
		this.rightProfile = this._outframes.get(ElementSide.Right);

		this.refreshSurround(this.projectService.profileService);
		this.init();
	}


	private setBottomX() {
		this.outBott.x = this.outLeft.x + this.outLeft.w;
		this.outBott.w = this.outRight.x - this.outBott.x;
	}

	private setSidesY() {
		if (this.shape.isDiamond) {
			this.outLeft.y = this.projectService.getProfileY(this._outframes.get(ElementSide.Left), this.area);
			this.outRight.y = this.projectService.getProfileY(this._outframes.get(ElementSide.Right), this.area);
		} else {
			this.outLeft.y = this.shape.locationY;
			this.outRight.y = this.shape.locationY;
		}
		var bottY = this.shape.bottomY;
		if (!this.bottomNeib) {
			bottY = this.getVerticalPoints().bottomY;
		}

		this.outLeft.h = bottY - this.outLeft.y;
		this.outRight.h = bottY - this.outRight.y;
		this.outTop.y = this.area == AreaType.Left ? this.outLeft.y : this.outRight.y;

	}

	private setBottomY() {
		var pr = this.leftProfile ?? this.rightProfile;
		var sd = RectName.get(this.area);
		if (Math.round(this.shape.bottomY) >= Math.round(pr[sd].y + pr[sd].h)) {
			this.outBott.h = 0;
		} else {
			this.outBott.h = this.def.muntin.size.height * SvgParams.SCALE;
		}
		this.outBott.y = this.shape.bottomY - this.outBott.h;
	}

	private setTopH() {
		if (this.shape.isDiamond) {
			this.outTop.h = 0;
			return;
		}

		const h = this.def.muntin.size.height * SvgParams.SCALE;
		if ((this.area == AreaType.Left || this.area == AreaType.Right)) {
			this.outTop.h = h;
			return;
		}

		if (Math.round(this.shape.locationY) != Math.round(this.minLocationY)) {
			this.outTop.h = h;
		} else {
			this.outTop.h = 0;
		}
	}

	private init() {
		this.calculateFrames();
		this.setBottomY();
		this.setBottomX();
		this.calculateParts();
		this.refreshSurround(this.projectService.profileService);
	}

	private calculateFrames() {
		const r = this.shape;

		this.outLeft.w = 0;
		this.outRight.w = 0;

		this.outLeft.x = r.locationX;

		if (this.locationX > this.minLocationX) {
			this.outLeft.w = this.def.muntin.size.height * SvgParams.SCALE;
		}
		if (this.minLocationX < this.maxLocationX) {
			this.outRight.w = this.def.muntin.size.height * SvgParams.SCALE;
		}
		this.outRight.x = r.rightX - this.outRight.w;

		this.setSidesY();

		var top = this._outframes.get(ElementSide.Top);
		this.setTopH();
		if (this.shape.isDiamond) {
			top.rect.x = r.locationX;
			top.rect.w = r.width;

			const rot = this.projectService.template.dropAngle;
			const oldw = top.rect.w;
			if (this.area == AreaType.Right) {
				top.rotation = `rotate(-${rot}, ${top.rect.x + top.rect.w}, ${top.rect.y})`;
				top.rect.x -= top.rect.w - oldw;
			} else {
				top.rotation = `rotate(${rot}, ${top.rect.x}, ${top.rect.y})`;
			}
		} else {
			top.rect.x = (this.minLocationX * SvgParams.SCALE) + SvgParams.START_X;
			top.rect.y = r.locationY;			
			top.rect.w = (((this.locationX - this.minLocationX) + (this.maxLocationX - this.locationX)) + this.width) * SvgParams.SCALE;			
			top.rotation = "";
		}

		var bott = this._outframes.get(ElementSide.Bottom);
		bott.rect.h = 0;
		bott.rect.x = this.outLeft.x + this.outLeft.w;
		bott.rect.y = r.bottomY - bott.rect.h;
		bott.rect.w = r.width - this.outLeft.w;
		if (this.height < this.maxHeight) {
			bott.rect.h = this.def.muntin.size.height * SvgParams.SCALE;
		}

		this.topProfile = top;
		this.bottomProfile = bott;
	}

	private calculateParts() {
		this._parts = [];
		this._muntins = [];

		const drop: number = this.shape.isDiamond ? this.projectService.template.dropAngle : null;

		const maxPaneWidth = this.def.panel.size.width;
		if (this.width > maxPaneWidth) {
			var mx = this.getMuntinX();
			var my: number;
			var dy: number = 0;

			if (drop) {
				dy = Common.calculateDroppedY(mx - this.shape.locationX, drop);
			}
			my = this.outTop.y + dy + this.outTop.h;
			var ml = this.height - this.outTop.h / SvgParams.SCALE - this.outBott.h / SvgParams.SCALE - dy / SvgParams.SCALE;

			var muntin = new WallMuntinProfile(mx, my, ml, this.def.muntin.size, this.def.muntin.color, Orientation.Vertical, this.def.muntin.id);			
			this._muntins.push(muntin);

			var part1 = new WallPart(this, this._outframes, drop, null, muntin);
			this._parts.push(part1);

			var part2 = new WallPart(this, this._outframes, drop, muntin, null);
			this._parts.push(part2);

			muntin.leftPart = part1;
			muntin.rightPart = part2;
		} else {
			this._parts.push(new WallPart(this, this._outframes, drop));
		}
	}
}
