import { Component, Input } from '@angular/core';
import { IDimensionsGroup } from 'src/app/_core/interfaces/IDimensionsGroup';

@Component({
	selector: 'g[app-dimension]',
	templateUrl: './dimension.component.html',
	styleUrls: ['./dimension.component.css']
})
export class DimensionComponent {
	@Input() item: IDimensionsGroup;
	@Input() printMode: boolean;
	
	constructor() {}

}
