<kendo-pdf-export #pdfPrint [autoPrint]="true">

	<svg *ngIf="!isShowSvgElement" xmlns="http://www.w3.org/2000/svg" version="1.1"
		[attr.width]="svgWidth ? svgWidth : 4000" [attr.height]="svgHeight ? svgHeight : 2200"
		[attr.transform]="currentZoom" [attr.viewBox]="svgViewBox" style="background-color: white">

		<style>
			.dimensions-text {
				font: italic 12px Dimensions;
			}
			.dimensions-text-print {
				font: 12px Dimensions;
			}
			.element-marker {
				font: italic 12px Dimensions;
			}
			tspan.lab {
				color: black;
				font: italic 10px Dimensions;
			}
			tspan.val {
				color: navy;
				font: italic bold 10px Dimensions;
			}

		</style>

		<defs>
			<pattern *ngIf="!printMode" id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
				<path d="M 10 0 L 0 0 0 10" fill="none" stroke="gray" stroke-width="0.2"></path>
			</pattern>
			<pattern *ngIf="!printMode" id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
				<rect width="100" height="100" fill="url(#smallGrid)" class="paper"></rect>
			</pattern>
			<pattern id="diagHatch" width="3" height="3" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
				<line x1="0" y1="0" x2="0" y2="4" style="stroke:gray; stroke-width: 0.4"></line>
			</pattern>

			<marker id="upArrow" markerWidth="10" markerHeight="10" refX="6" refY="3" orient="auto" markerUnits="strokeWidth">
				<path d="M0,0 L0,6 L6,3 z" fill="gray"></path>
			</marker>
			<marker id="downArrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
				<path d="M6,0 L6,6 L0,3 z" fill="gray"></path>
			</marker>

			<pattern id="colorBack" x="0" y="0" width="60" height="40" viewBox="0 0 600 400" preserveAspectRatio="xMidYMax slice" patternUnits="userSpaceOnUse">
				<image [attr.xlink:href]="projectService.getBackImage()" />
			</pattern>

			<pattern *ngFor="let f of fabrics" [attr.id]="f.id" x="0" y="0" width="134" height="47" viewBox="0 0 134 47" preserveAspectRatio="xMidYMax slice"
				patternUnits="userSpaceOnUse">
				<image [attr.xlink:href]="f.url" />
			</pattern>

			<linearGradient *ngFor="let f of styling.getGlassesColors()" [attr.id]="f.id" x1="0%" y1="0%" x2="0%" y2="100%">
				<stop offset="0%" [attr.style]="'stop-color:' + f.color1 + ';stop-opacity:1'" />
				<stop offset="40%" [attr.style]="'stop-color:' + f.color2 + ';stop-opacity:1'" />
			</linearGradient>
		</defs>
		
		<rect *ngIf="projectService.currentArea !== 'None'" width="100%" height="100%" fill="url(#grid)"
			(click)="onBgClick()">
		</rect>

		<!-- * Left side area -->
		<g app-svg-side *ngIf="projectService.currentArea == 'Left' || printLeftMode"
			[elements]="profileService.leftSideElements" [areaService]="leftSideService"
			[currentTool]="currentTool" [selectedItems]="selectedItems" [printMode]="printLeftMode" [printElements]="printElements" [channel]="currentChannel"
			(selectGlassEvent)="selectGlassOnSides($event)"
			(selectProfileEvent)="selectProfile($event)"
			(selectMarquiseEvent)="selectMarquise($event)"
			(selectDoorEvent)="selectDoor($event)"
			(selectWallEvent)="selectWall($event)"
			(selectWallMuntinEvent)="selectWallMuntin($event)"
			(openLayoutEvent)="openSideLayoutPopup($event)">
		</g>

		<!-- * Roof area -->
		<g app-svg-roof *ngIf="projectService.currentArea == 'Roof' || printRoofMode"
			[areaService]="roofService"
			[currentTool]="currentTool" [selectedItems]="selectedItems" [printMode]="printFrontMode" [printElements]="printElements" [channel]="currentChannel"
			(selectGlassEvent)="selectGlassOnSides($event)"
			(selectProfileEvent)="selectProfile($event)"
			(selectMarquiseEvent)="selectMarquise($event)"
			(mouseOverGlassEvent)='mouseOverGlass($event)' (mouseOutGlassEvent)='mouseOutGlass()'
			(shapeDropEvent)="onRoofShapeDrop($event)">
		</g>                                                                                                                                                                                                            

		<!-- * Rear area -->
		<g app-svg-rear *ngIf="projectService.currentArea == 'Rear' || printRearMode"
			[areaService]="rearService"
			[currentTool]="currentTool" [selectedItems]="selectedItems" [printMode]="printRearMode" [printElements]="printElements" [channel]="currentChannel"
			(selectGlassEvent)="selectGlassOnSides($event)"
			(selectProfileEvent)="selectProfile($event)"
			(selectWallEvent)="selectWall($event)"
			(selectDoorEvent)="selectDoor($event)"
			(selectMarquiseEvent)="selectMarquise($event)"
			(shapeDropEvent)="onRearDrop($event)">
		</g>

		<!-- * Front area -->
		<g app-svg-front *ngIf="projectService.currentArea == 'Front' || printFrontMode"
			[areaService]="frontService"
			[currentTool]="currentTool" [selectedItems]="selectedItems" [printMode]="printLeftMode" [printElements]="printElements" [channel]="currentChannel"
			(selectGlassEvent)="selectGlassOnSides($event)"
			(selectWallEvent)="selectWall($event)"
			(selectDoorEvent)="selectDoor($event)"
			(selectProfileEvent)="selectProfile($event)"
			(selectMarquiseEvent)="selectMarquise($event)"
			(shapeDropEvent)="onFrontDrop($event)">
		</g>

		<!-- * Right side area -->
		<g app-svg-side *ngIf="projectService.currentArea == 'Right' || printRightMode" [printMode]="printRightMode" [printElements]="printElements" [channel]="currentChannel"
			[elements]="profileService.rightSideElements" [areaService]="rightSideService" [areaType]="'Right'"
			[currentTool]="currentTool" [selectedItems]="selectedItems"
			(selectGlassEvent)="selectGlassOnSides($event)"
			(selectProfileEvent)="selectProfile($event)"
			(selectMarquiseEvent)="selectMarquise($event)"
			(selectDoorEvent)="selectDoor($event)"
			(selectWallEvent)="selectWall($event)"
			(selectWallMuntinEvent)="selectWallMuntin($event)"
			(selectDoorEvent)="selectDoor($event)"
			(openLayoutEvent)="openSideLayoutPopup($event)">
		</g>

	</svg>

	<svg *ngIf="isShowSvgElement" xmlns="http://www.w3.org/2000/svg" version="1.1" [attr.width]="svgWidth" [attr.height]="svgHeight" [attr.viewBox]="svgViewBox" style="background-color: white">

		<!-- All for Glasses-Wall -->
		<g *ngIf="glassWallSpecOnPrint">
			<!-- Glass Wall -->
			<rect *ngFor="let g of glassWallElemsToPrint['GlassWall']"
						[attr.x]="g.rect.x"
						[attr.y]="g.rect.y"
						[attr.width]="g.rect.w" [attr.height]="g.rect.h"
						[attr.fill]="g.parts.length > 0 ? 'black' : g.color"
						class="glass"
			>
			</rect>

			<!-- Glass Wall Parts -->
			<rect *ngFor="let g of glassWallElemsToPrint['GlassPart']"
						[attr.x]="g.rect.x"
						[attr.y]="g.rect.y"
						[attr.width]="g.rect.w"	[attr.height]="g.rect.h"
						[attr.fill]="g.color"
						class="glass"
			>
			</rect>


			<!-- Glass Wall Diamond -->
			<path *ngFor="let g of glassWallElemsToPrint['GlassWallDiamond']"
						[attr.d]="g.path"
						[attr.fill]="g.parts.length > 0 ? 'black' : g.color"
						class="glass"
			>
			</path>

			<!-- Glass Diamond Parts -->
			<path *ngFor="let g of glassWallElemsToPrint['GlassPartDiamond']"
						[attr.d]="g.path"
						[attr.fill]="g.color"
						class="glass"
			>
			</path>

			<!-- Profiles: Muntin -->
			<rect *ngFor="let l of glassWallElemsToPrint['Muntin']"
								[attr.x]="l.rect.x - locationXDiff"
								[attr.y]="l.rect.y - locationYDiff"
								[attr.width]="l.rect.w"	[attr.height]="l.rect.h"
								[attr.fill]="l.fill"
			>
			</rect>
		</g>

		<!-- Glasses -->
		<rect
			*ngIf="glassOnPrint"
			[attr.x]="start_X" [attr.y]="start_Y"
			[attr.width]="glassOnPrint.width * scale" [attr.height]="glassOnPrint.height * scale"
			class="glass" stroke-width="0" [attr.fill]="glassOnPrint.color">
		</rect>

		<!-- Dimensions for glasses -->
		<g *ngFor="let d of elemDimensions">
			<g app-dimension [item]="d" [printMode]="printMode"></g>
		</g>

	</svg>	

</kendo-pdf-export>

<kendo-pdf-export #legendPrint [imageResolution]="150" [autoPrint]="true" paperSize="A4">
	<!-- <app-legend></app-legend> -->
</kendo-pdf-export>

<app-led-templates-dialog [isDialogOpened]="showPatterns" (selected)="ledPatternChange($event)"></app-led-templates-dialog>
<app-door-variants-dialog [isDialogOpened]="showDoors" [item]="currentDoor" [doorVariants]="doorVariants" (selected)="lockVariantChange($event)"></app-door-variants-dialog>
<app-side-layout-dialog *ngIf="!projectService.readOnly" [isDialogOpened]="showSideLayout" [area]="projectService.currentArea" [toolboxItemType]="currentToolType" (selected)="applySideLayout($event)"></app-side-layout-dialog>

<app-wait [show]="showLoading" [message]="messageLoading"></app-wait>
<app-confirm-dialog [dialogOpened]="confirmOpened" [dialogMessage]="confirmMessage" (dialogAction)="onConfirmAction($event)"></app-confirm-dialog>

