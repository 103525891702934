import { Injectable } from '@angular/core';
import { ExtraOption } from '../models/extra-option.model';
import { Glass } from '../models/glasses/glass.model';
import { Profile } from '../models/profiles/profile.model';
import { ElementType } from '../models/ToolboxModel';
import { WizardParameter, IExtra } from '../models/wizard/wizard-parameter.model';
import { ProjectTemplate } from '../template';
import { ProfileService } from './profile.service';
import { ProjectService } from './project.service';

@Injectable({
	providedIn: 'root'
})
export class ExtraOptionService {

	constructor(private projectService: ProjectService) { 
	}

	public setupRequiredExtras() {
		var required = this.projectService.template.extras.filter(e => e.isRequired);
		required.forEach(r => {
			if (this.projectService.profileService.extraElements.find((ex: ExtraOption) => ex.configId == r.id) == null) {
				var option = new ExtraOption(r);
				this.projectService.profileService.extraElements.push(option);
			}
		});
		this.collectExtraOptions();
	}

	public createExtraOption(extraId: string, profileId: string, quantity: number) {
		var option: ExtraOption = this.projectService.profileService.extraElements.find((o: ExtraOption) => o.configId === extraId);
		if (option && !profileId) {
			return false;
		}
		if (!option) {
			const ct = this.projectService.template.extras.find(e => e.id == extraId);
			var q = ct.minQuantity;
			if (profileId && quantity >= ct.minQuantity && quantity <= ct.maxQuantity) {
				q = quantity;
			}
			option = new ExtraOption(ct);
			this.projectService.profileService.extraElements.push(option);
		}
		
		if (profileId) {
			option.bindProfile(profileId);
			if (option.quantity < option.maxQuantity) {
				option.quantity++;
			}
		}

		return true;
	}

	public clearAutoOptions() {
		this.projectService.profileService.extraElements = this.projectService.profileService.extraElements.filter(e => e.freeHand);
	}

	public collectExtraOptions() {
		this.projectService.profileService.roofElements[ElementType.Front].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.fronts) });
		this.projectService.profileService.roofElements[ElementType.Bar].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.bars) });
		this.projectService.profileService.roofElements[ElementType.SideFinish].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.bars) });
		this.projectService.profileService.roofElements[ElementType.WallProfile].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.rears) });
		this.projectService.profileService.roofElements[ElementType.Column].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.columns) });
		this.projectService.profileService.roofElements[ElementType.RoofWindow].forEach((f: Profile) => { this._collectOption(f.configId, this.projectService.template.roofWindows) });
	}

	public addElevator() {
		const pid = this.projectService.template.connectorDef?.elevatorId;
		if (!pid) {
			return;
		}
		const connId = this.projectService.template.connectorDef.id;
		const elevator = this.projectService.profileService.extraElements.find(e => e.configId == pid);
		if (elevator) {
			if (!elevator.bindedTo(connId) || elevator.profilesCount > 1) {
				return; // elevator is required for other profiles
			}
			this.projectService.profileService.extraElements = this.projectService.profileService.extraElements.filter(e => e.configId != pid);
		}

		if (this.projectService.profileService.roofElements[ElementType.Glass].find((g: Glass) => { return g.weight > g.stat.maxWeight || g.height > g.stat.maxDepth; })) {
			this.createExtraOption(pid, connId, 1);
		}
	}

	private _collectOption(id: string, templateDefs: WizardParameter[]) {
		const td = templateDefs.find(p => p.id == id);
		if (!td?.extras) {
			return;
		}
		td.extras.filter(e => e.count > 0).forEach((e: IExtra) => {
			this.createExtraOption(e.extraId, id, e.count);
		});
	}

}
