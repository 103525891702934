import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { LedStripe } from "../profiles/led-stripe.model";
import { ElementType } from "../ToolboxModel";
import { ElementStoreModel } from "./element-store.model";

export class LedStripeStoreModel extends ElementStoreModel {

	rect: IRect;
	barId: string;
	stripeId: string;
	waste: number;
	length: number;

	constructor(c: LedStripe) {
		super(c.id, c.configId);

		this.stripeId = c.stripeId;
		this.rect = c.rect;
		this.barId = c.bar.id;
		this.waste = Math.round(c.waste);
		this.length = Math.round(c.getLength());
	}

	public static restore(c: LedStripeStoreModel, projectService: ProjectService) {
		const bar = projectService.profileService.roofElements[ElementType.Bar].find(b => b.id == c.barId);
		const stripe = new LedStripe(c.configId, bar, c.waste, c.stripeId);
		stripe.id = c.id;
		stripe.setLength(c.length);
		projectService.profileService.roofElements[ElementType.LedStripe].push(stripe);
		return stripe;
	}

}
