import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { ToolboxItemService } from 'src/app/_core/services/toolbox-item.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { Door } from 'src/app/_core/models/doors/door.model';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { StylingService } from 'src/app/_core/services/styling.service';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';

@Component({
	selector: 'g[app-door]',
	templateUrl: './door.component.html',
	styleUrls: ['./door.component.css']
})
export class DoorComponent {
	@Input() selectedItems: any[];
	@Input() area: string;
	@Input() printMode: boolean;
	@Output() selectDoorEvent = new EventEmitter<Door>();

	constructor(private toolBSrvc: ToolboxItemService,
		private projectService: ProjectService,
		public styling: StylingService) {

	}

	selectDoor(d: Door) {
		this.selectDoorEvent.emit(d);		
		this.toolBSrvc.setCurrTool(null);
	}

	getDoors() {
		if (!this.styling.showLayer(LayerLevel.Middle)) {
			return null;
		}
		return this.projectService.profileService.getDoors(AreaType[this.area]);
	}

	iconClass(itemType: string, areaType: string): string {
		return this.toolBSrvc.iconClass(itemType, areaType);
	}

	getGlassBackColor(color: string) {
		return `url(#color_${color.substring(1)})`;
	}

}
