<kendo-card style="width: 100%" class="minor" *ngIf="type != 'None'">
	<kendo-card-header *ngIf="showCommon">
		<div class="row">
			<div class="col col-12">Individual parameters</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<card-glass [items]="items" *ngIf="type == 'Glass'"></card-glass>
		<card-glass-wall [items]="items" *ngIf="type == 'GlassWall'"></card-glass-wall>
		<card-profile [items]="items" *ngIf="type == 'Profile'"></card-profile>
		<card-column #columnCard [items]="items" *ngIf="type == 'Column'"></card-column>
		<card-marquise [items]="items" *ngIf="type == 'Marquise'"></card-marquise>
		<card-marquise-vertical [items]="items" *ngIf="type == 'MarquiseVertical'"></card-marquise-vertical>
		<card-leds-pattern [items]="items" *ngIf="type == 'Led'"></card-leds-pattern>
		<card-leds-stripe [items]="items" *ngIf="type == 'LedStripe'"></card-leds-stripe>
		<card-extra-option [items]="items" *ngIf="type == 'ExtraOption'"></card-extra-option>
		<card-front [items]="items" *ngIf="type == 'Front'"></card-front>
		<card-door [items]="items" *ngIf="type == 'Door'"></card-door>
		<card-wall [items]="items" *ngIf="type == 'Wall'"></card-wall>
	</kendo-card-body>
</kendo-card>

<kendo-card class="minor" style="width: 100%" *ngIf="type != 'None' && showCommon">
	<kendo-card-header>
		<div class="row">
			<div class="col col-12">Common parameters</div>
		</div>
	</kendo-card-header>
	<kendo-card-body>
		<card-common-properties *ngIf="showCommon" [items]="items" (columnTypeChanged)="setColumnConfigId($event)"></card-common-properties>
	</kendo-card-body>
</kendo-card>