import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AreaType } from "../interfaces/IAreaType";
import { ICalcInput, ICalcResult, ICheckInput, ICheckResult } from "../interfaces/ICalculations";
import { PrintoutType } from "../models/boms/bom.model";
import { LedPatterns } from "../models/leds-patterns";
import { ProductParameters } from "../models/product-parameters.model";
import { FormSideParameter, WizardParameter } from "../models/wizard/wizard-parameter.model";
import { Color } from '../models/colors/color.model';
import { map } from "rxjs/operators";
import { IMigrationInput, IMigrationResult } from "../interfaces/IStorageCommon";

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	
	constructor(
		private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string) {
	}

	public getSysInfo(): Observable<any> {
		return this.http.get(this.baseUrl + `/api/application/sysinfo`);
	}

	public getToolbox(productId: string, brandName: string, area: AreaType): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/api/toolbox/${productId}/${brandName}/${area}`);
	}

	public getLedsPatterns(productId: string, brandName: string): Observable<LedPatterns> {
		return this.http.get<LedPatterns>(`${this.baseUrl}/api/product/${productId}/${brandName}/ledspots`);
	}   

	public getGlasses() {
		return this.http.get<any>(this.baseUrl + '/api/glasses/get');
	}

	public calculateConstruction(productId: string, brandName: string, input: ICalcInput): Observable<ICalcResult> {
		return this.http.post<ICalcResult>(`${this.baseUrl}/api/construction/${productId}/${brandName}/calc`, input);
	}

	public calculateColumnLength(productId: string, brandName: string, input: ICheckInput): Observable<number> {
		return this.http.post<number>(`${this.baseUrl}/api/construction/${productId}/${brandName}/columnlength`, input);
	}

	public checkConstruction(productId: string, brandName: string, input: ICheckInput): Observable<ICheckResult> {
		return this.http.post<ICheckResult>(`${this.baseUrl}/api/construction/${productId}/${brandName}/check`, input);
	}

	public sendBom(productId: string, bom: any, brandName: string): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}/api/project/${productId}/${brandName}/bom`, bom);
	}

	public getExcel(productId: string, brandName: string, bom: any): Observable<Blob> {
		const lang = window.document.documentElement.lang ?? 'en'
		const httpOptions = {
			headers: new HttpHeaders({ 'Accept-Language': lang }),
			responseType: 'blob' as 'json'
		}

		return this.http.post<Blob>(`${this.baseUrl}/api/project/${productId}/${brandName}/excel`, bom, httpOptions );
	}

	public sendImage(orderCode: string, type: PrintoutType, data: string, id: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
		}

		if (!id) {
			id = "-";
		}
		return this.http.post<any>(this.baseUrl + `/api/project/saveimage/${orderCode}/${type}/${id}`, data, httpOptions);
	}

	//#region Wizard

	public getProducts(brandName: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseUrl}/api/products/${brandName}`);
	}

	public getProductParameters(productId: string, brandName: string) : Observable<ProductParameters>{
		return this.http.get<ProductParameters>(`${this.baseUrl}/api/product/${productId}/${brandName}/parameters`);
	}

	public  getFormTops(productId: string, brandName: string): Observable<WizardParameter[]> {
		return this.http.get<WizardParameter[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/formtops`);
	}

	public getFoundations(productId: string, brandName: string): Observable<WizardParameter[]> {
		return this.http.get<WizardParameter[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/foundations`);
	}

	public getFormSides(productId: string, brandName: string): Observable<FormSideParameter[]> {
		return this.http.get<FormSideParameter[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/formsides`);
	}

	public getRoofings(productId: string, brandName: string): Observable<WizardParameter[]> {
		return this.http.get<WizardParameter[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/roofings`);
	}

	public getFastenings(productId: string, brandName: string): Observable<WizardParameter[]> {
		return this.http.get<WizardParameter[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/fastenings`);
	}

	public getElements(productId: string, brandName: string): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/api/product/${productId}/${brandName}/elements`);
	}

	//#endregion

	public getColors(productId: string, brandName: string): Observable<any> {
		return this.http.get<Color[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/colors`);
	}

	public getFullColors(productId: string, brandName: string): Observable<any> {
		return this.http.get<Color[]>(`${this.baseUrl}/api/product/${productId}/${brandName}/fullcolors`);
	}

	public getColorsWithPalette(colors: Color[], productId: string, brandName: string) {
		return colors.map((color) =>
			color.paletteId
				? this.getPaletteGroups(productId, color.paletteId, brandName).pipe(
					map((paletteGroups) => {
						color.paletteGroups = paletteGroups;
						return color;
					})
				)
				: of(color)
		);
	}

	private getPaletteGroups(productId: string, paletteId: string, brandName: string,): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/api/product/${productId}/${paletteId}/${brandName}/palettegroups`);
	}

	public login(password: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
		}
		return this.http.post<boolean>(this.baseUrl + '/api/auth/login/', password, httpOptions);
	}

	public checkMigration(input: IMigrationInput): Observable<IMigrationResult> {
		return this.http.post<IMigrationResult>(this.baseUrl + '/api/construction/checkmigration', input);
	}

}
