import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_core/services/api.service';
import { ProductParameters } from 'src/app/_core/models/product-parameters.model';
import { FormSideParameter, WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { ProjectService } from 'src/app/_core/services/project.service';
import { ProjectTemplate } from 'src/app/_core/template';
import { Common } from 'src/app/_core/models/common';
import { AuthObserver, AuthService } from 'src/app/_core/services/auth.service';
import { ICrmObserver, CrmService } from 'src/app/_core/services/crm.service';
import { ProjectStoreModel } from 'src/app/_core/models/project-store/project-store.model';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { SNOW_ZONES, UrlParams, WIND_ZONES } from 'src/app/_core/constants/constants';
import { StepperStep } from '@progress/kendo-angular-layout/stepper/models/stepper-step.interface';
import { NotifyService } from 'src/app/_core/services/notify.service';
import { IProjectInfo } from 'src/app/_core/interfaces/IProjectInfo';

@Component({
	selector: 'app-wizard',
	templateUrl: './wizard.dialog.html',
	styleUrls: ['./wizard.dialog.scss']
})
export class WizardDialog implements OnInit, OnDestroy, AuthObserver, ICrmObserver {
	@ViewChild('numBack', { static: false }) numBack: NumTbxComponent;
	@ViewChild('numDrop', { static: false }) numDrop: NumTbxComponent;
	@ViewChild('numWidth', { static: false }) numWidth: NumTbxComponent;
	@ViewChild('numDepth', { static: false }) numDepth: NumTbxComponent;

	public result;
	public current = -1;
	public shift = 0;

	private dontShowOrderCode = false;

	private middel_Height: number;
	private top_Height: number;
	private bottom_Height: number;
	public container_Height: number;

	showLoading: boolean = true;
	exceptionId: string;
	exceptionMessage: string;

	snowZoneValues = SNOW_ZONES;
	windZoneValues = WIND_ZONES;

	private labelProduct: string = $localize`:Wizard|'Product' step label:Product`;
	private labelOrderCode: string = $localize`:Wizard|'Order code' step label:Order code`;
	loadingMessage: string = $localize`:Wizard|'Loading product' banner:Loading product. Please wait...`;
	crmPresent: boolean;

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.bottom_Height = window.screen.height / 10;
		this.top_Height = window.screen.height / 5;
		this.middel_Height = window.screen.height / 2;
		this.container_Height = this.bottom_Height + this.top_Height + this.middel_Height;
	}

	public steps: StepperStep[];

	public opened = false;
	public showLoader = false;
	public loaderComplate = false;

	productId: string;
	brandName: string = "Aluterr";
	orderCode: string;
	pwd: string = "";
	wrongPwd: boolean = false;
	orderCodeDisabled: boolean = false;
	product: ProductParameters = new ProductParameters();

	foundations: WizardParameter[];
	foundationId: string;

	// formTop
	formTops: WizardParameter[] = []
	formTopId: string;
	//formSide
	formSides: FormSideParameter[] = []
	formSideId: string;
	//formFastening
	formFastenings: WizardParameter[] = []
	formFasteningId: string;
	//formRoofing
	formRoofings: WizardParameter[] = []
	formRoofingId: string;

	snowZone: string = SNOW_ZONES[0];
	windZone: string = WIND_ZONES[0];

	noProductMessage:boolean;

	authStatus: boolean = false;

	products: any[] = [];
	currentProductId: string;
	currentProductName: string;

	private mapWizardParameter: Map<number, WizardParameter[]>;

	constructor(
		public api: ApiService,
		private activatedRoute: ActivatedRoute,
		private projectService: ProjectService,
		private authService: AuthService,
		private crmService: CrmService,
		private notif: NotifyService
	) { }
	initNums() {
		if (this.current + this.shift != 2) {
			return;
		}

		setTimeout(() => {
			if (this.numBack) {
				this.numBack.valueChanged.subscribe(v => {
					this.backHeight = v;
				});
			}

			if (this.numDrop) {
				this.numDrop.valueChanged.subscribe(v => {
					this.drop = v;
				});
			}

			if (this.numWidth) {
				this.numWidth.valueChanged.subscribe(v => {
					this.product.width = v;
				});
			}

			if (this.numDepth) {
				this.numDepth.valueChanged.subscribe(v => {
					this.product.depth = v;
				});
			}


		});
	}

	modelLoaded(designData: ProjectStoreModel, info: IProjectInfo, images: boolean): void {
		this.opened = false;
	}

	priceChanged(priceValue: number): void {
	}

	ngOnInit(): void {
		this.steps = [
			{ label: this.labelProduct, svgIcon: this.projectService.svgIcons.alignToGridIcon, isValid: true, disabled: true },
			{ label: this.labelOrderCode, svgIcon: this.projectService.svgIcons.alignToGridIcon, isValid: true, disabled: true },
			{ label: $localize`:Wizard|'Main parameters' step label:Main parameters`, svgIcon: this.projectService.svgIcons.alignToGridIcon, isValid: true, disabled: true },
			{ label: $localize`:Wizard|'Form top' step label:Form top`, svgIcon: this.projectService.svgIcons.tableRowInsertAboveIcon, isValid: true, disabled: true },
			{ label: $localize`:Wizard|'Form side' step label:Form side`, svgIcon: this.projectService.svgIcons.tableColumnInsertRightIcon, isValid: true, disabled: true },
			{ label: $localize`:Wizard|'Fastening' step label:Fastening`, svgIcon: this.projectService.svgIcons.gearsIcon, isValid: true, disabled: true },
			{ label: $localize`:Wizard|'Roofing' step label:Roofing`, svgIcon: this.projectService.svgIcons.tableIcon, isValid: true, disabled: true }
		];

		this.getParams();
		this.onResize();
		this.authService.attach(this);
		this.crmService.attach(this);
	}

	ngOnDestroy(): void {
		this.authService.detach(this);
		this.crmService.detach(this);
	}

	authStatusChanged(authStatus: boolean): void {
		this.authStatus = authStatus
		if (this.authStatus && this.current == -1) {
			this.current = 1;
			if (this.dontShowOrderCode) {
				this.current++;
			}
			this.initNums();
		}
	}

	goBack() {
		if (this.current + this.shift > 0) {
			this.current--;
			if (this.current + this.shift == 0 && this.products.length == 0 && !this.productId) {
				this.getProducts();
			}
			this.initNums();
		}
	}

	goNext() {
		if (this.isStepValid(this.current)) {
			this.steps[this.current].disabled = false;
			this.disabledSteps(this.current);
	
			if (this.current + this.shift == 0) {
				if (this.productId != this.currentProductId) {
					this.productId = this.currentProductId;
					this.projectService.productId = this.currentProductId;
					this.getProductParameters(false);
				}
			}
			this.current++;
			this.initNums();
		}

		if (this.current + this.shift == 2) {
			if (this.dontShowOrderCode) {
				this.current++;
				this.initNums();
				return;
			}
		}

		if (this.mapWizardParameter && this.mapWizardParameter.get(this.current + this.shift)?.length == 1) {
			this.goNext()
		}
	}

	private setMapWizardParameter() {
		this.mapWizardParameter = new Map<number, WizardParameter[]>([
			[3, this.formTops],
			[4, this.formSides],
			[5, this.formFastenings],
			[6, this.formRoofings],
		]);
	}

	public start() {
		this.showLoader = true;

		var pr = new ProjectTemplate(false);
		pr.productId = this.productId;
		pr.brandName = this.brandName;
		pr.productName = this.product.name;
		pr.productPhoto = this.product.photo
		pr.version = this.product.version;
		pr.isDraft = this.product.isDraft;
		pr.errors = this.product.errors;
		pr.backHeight = this.product.height;
		pr.glassId = this.formRoofingId;
		pr.depth = this.product.depth;
		pr.dropAngle = this.product.dropAngle;
		pr.fasteningId = this.formFasteningId;
		pr.formSideId = this.formSideId;
		pr.formSides = this.formSides;
		pr.formTopId = this.formTopId;
		pr.maxBackHeight = this.product.maxBackHeight;
		pr.minBackHeight = this.product.minBackHeight;
		pr.maxDepth = this.product.maxDepth;
		pr.minDepth = this.product.minDepth;
		pr.maxDropAngle = this.product.maxDropAngle;
		pr.minDropAngle = this.product.minDropAngle;
		pr.width = this.product.width;
		pr.maxWidth = this.product.maxWidth;
		pr.minWidth = this.product.minWidth;
		pr.snowZone = this.snowZone;
		pr.windZone = this.windZone;
		pr.foundationId = this.foundationId;
		this.projectService.templateChangedSubject.subscribe(e => {
			this.opened = false;
			this.projectService.orderCode = this.orderCode;
		});

		this.projectService.setupTemplate(pr);

	}

	public show() {
		this.opened = true;
	}

	public hide() {
		this.opened = false;
	}

	snowZoneReadOnly: boolean = false;
	windZoneReadOnly: boolean = false;

	getParams() {
	 	this.activatedRoute.queryParams
			.subscribe(params => {
				this.productId = params[UrlParams.ProductId];	
				this.brandName = params[UrlParams.BrandName] ?? "Aluterr";
				this.crmPresent = params[UrlParams.AffinityId] != null;
				let oc: string = params[UrlParams.OrderCode];


				const parSnow = params[UrlParams.SnowZone];
				if (parSnow && this.snowZoneValues.indexOf(parSnow) >= 0) {
					this.snowZone = parSnow;
					this.snowZoneReadOnly = true;
				}

				const parWind = params[UrlParams.WindZone];
				if (parWind && this.windZoneValues.indexOf(parWind) >= 0) {
					this.windZone = parWind;
					this.windZoneReadOnly = true;
				}

				if (oc && oc.length > 13 && oc.length < 17) {
					this.orderCode = oc;
					this.orderCodeDisabled = true;
					this.steps[1].disabled = false;
					this.steps[2].disabled = false;
				} else {
					this.orderCode = "";
					this.orderCodeDisabled = false;
					this.current = 0;
				}

				if (this.productId) {
					this.projectService.productId = this.productId;
					this.getProductParameters(true);
					this.noProductMessage=false;
					this.current = 1;
					this.shift++;
					this.steps = this.steps.filter(s => s.label != this.labelProduct);
					this.initNums();
				} else {
					this.current = 0;
					this.showLoading = true;
					this.getProducts();
				}
			});
	}

	private getProducts() {
		this.api.getProducts(this.brandName).subscribe(p => {
			this.products = p;
			if (p.length > 0) {
				this.currentProductId = p[0].id;
				this.currentProductName = p[0].name;
				this.product = p[0];			

				if (p.length == 1) {
					this.goNext();
					this.goNext();
				} else {
					this.productId = this.currentProductId;
					this.projectService.productId = this.currentProductId;
					this.getProductParameters(false);
					this.showLoading = false;
				}
			}
		});
	}

	public isStepValid(index: number): boolean {
		switch(index) {
			case -1:
				return false;
			case 0:
				return true;
			case 1:
				if (this.orderCode.length > 13 && this.orderCode.length < 17) return true;
				break;
			case 2:
				if (this.product.width && this.product.height && this.product.depth && this.product.dropAngle) {
					return true;
				}
				break;
			case 3:
				if (this.formTopId) return true;
				break;
			case 4:
				if (this.formSideId) return true;
				break;
			case 5:
				if (this.formFasteningId) return true;
				break;
			case 6:
				if (this.formRoofingId) return true;
				break;
			default:
				return false;
		}
		return false;
	}

	disabledSteps(index) {
		for (let i = 0; i < this.steps.length; i++) {
			if (i <= index + 1)
				this.steps[i].disabled = false;
		}

	}

	private getFoundations() {
		this.foundations = [];
		this.foundationId = "";
		this.api.getFoundations(this.productId, this.brandName).subscribe(response => {
			this.foundations = response;
			var defa = this.foundations.find(a => a.isDefault == true);
			if (!defa) {
				defa = this.foundations[0];
			}
			this.foundationId = defa?.id;
		});

	}

	getProductParameters(toClear: boolean) {
		if (!this.opened) {
			return;
		}
		this.showLoading = true;
		this.api.getProductParameters(this.productId, this.brandName).subscribe(response => {			
			if (!response.isActive && !this.crmPresent) {
				this.hide();
				this.notif.cfgException.emit(
					{ 
						message: $localize`:Common|'Product is inactive' message:This product is inactive. Cannot create new project.`,
						clear: toClear
					});
				return;
			}
			this.getFastenings();
			this.getFormTops();
			this.getRoofings();
			this.getFormSides();
			this.getFoundations();

			this.product = response;
			this.product.width = this.product.defaultWidth;
			this.product.height = this.product.defaultBackHeight;
			this.product.depth = this.product.defaultDepth;
			this.product.dropAngle = this.product.defaultDropAngle;
			this.calcFrontHeight();
			this.showLoading = false;
		},error=>{
			this.noProductMessage = true;
			this.exceptionId = error.error.exceptionId;
			this.exceptionMessage = error.error.message;
		})
	}
	getFormTops() {
		this.api.getFormTops(this.productId, this.brandName).subscribe(response => {
			this.formTops = response;
			this.formTopId = this.selectDefault(this.formTops);

			this.setMapWizardParameter();
		});
	}

	onSelectFormTop(id) {
		this.formTopId = id;
		this.formTops = this.selectItem(this.formTopId, this.formTops);
	}
	getFormSides() {
		this.api.getFormSides(this.productId, this.brandName).subscribe(response => {
			this.formSides = response;
			this.formSideId = this.selectDefault(this.formSides);

			this.setMapWizardParameter();
		})
	}
	onSelectFormSides(id) {
		this.formSideId = id;
		this.formSides.forEach(fs => {
			fs.isDefault = fs.id == id;
		});
	}
	getFastenings() {
		this.api.getFastenings(this.productId, this.brandName).subscribe(response => {
			this.formFastenings = response;
			this.formFasteningId = this.selectDefault(this.formFastenings);

			this.setMapWizardParameter();
		});

	}
	onSelectFastenings(id) {
		this.formFasteningId = id;
		this.formFastenings = this.selectItem(this.formFasteningId, this.formFastenings);
	}
	getRoofings() {
		this.api.getRoofings(this.productId, this.brandName).subscribe(response => {
			this.formRoofings = response;
			this.formRoofingId = this.selectDefault(this.formRoofings);

			this.setMapWizardParameter();
		});
	}
	onSelectRoofings(id) {
		this.formRoofingId = id;
		this.formRoofings = this.selectItem(this.formRoofingId, this.formRoofings);
	}
	selectItem(id: string, array: WizardParameter[] | FormSideParameter[]) {
		array.forEach(element => {
			if (element.id != id) {
				element.isDefault = false;
			}
			else {
				element.isDefault = true;
			}
		});
		return array
	}
	selectDefault(array: WizardParameter[]) {
		return array.find(a => a.isDefault == true)?.id;
	}

	private calcFrontHeight() {
		this.product.frontDiff = Common.round(this.product.depth * Math.tan(Common.toRadians(this.product.dropAngle)));
		this.product.frontHeight = Common.round(this.product.height - this.product.frontDiff);
	}

	get backHeight() {
		return this.product.height;
	}
	set backHeight(v: number) {
		this.product.height = v;
		this.calcFrontHeight();
	}

	get drop() {
		return this.product.dropAngle;
	}
	set drop(v: number) {
		this.product.dropAngle = v;
		this.calcFrontHeight();
	}

	get depth() {
		return this.product.depth;
	}
	set depth(v: number) {
		this.product.depth = v;
		this.calcFrontHeight();
	}
}
