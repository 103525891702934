import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ElementType, ToolboxItem, ToolboxItemType} from '../../_core/models/ToolboxModel';
import {ProjectService} from '../../_core/services/project.service';
import {AreaType} from '../../_core/interfaces/IAreaType';
import {RoofService} from 'src/app/_core/services/areas/roof.service';
import {RightSideService} from 'src/app/_core/services/areas/right-side.service';
import {LeftSideService} from 'src/app/_core/services/areas/left-side.service';
import {Profile} from 'src/app/_core/models/profiles/profile.model';
import {FrontService} from '../../_core/services/areas/front.service';
import {Glass} from 'src/app/_core/models/glasses/glass.model';
import {ChosenGlassService} from '../../_core/services/chosen-elements/chosen-glass.service';
import {ProfileService} from '../../_core/services/profile.service';
import {ApiService} from 'src/app/_core/services/api.service';
import {LEDsTemplateDialog} from 'src/app/dialogs/leds-template/leds-template.dialog';
import {ChoosenProfileService} from '../../_core/services/chosen-elements/chosen-profile.service';
import {ChosenMarquiseService} from '../../_core/services/chosen-elements/chosen-marquise.service';
import {ToolboxItemService} from '../../_core/services/toolbox-item.service';
import {ColumnProfile} from '../../_core/models/profiles/column.model';
import {PDFExportComponent} from '@progress/kendo-angular-pdf-export';
import {exportImage} from '@progress/kendo-drawing';
import {saveAs} from '@progress/kendo-file-saver';
import {IPrintoutEventArgs, IPrintoutFile, PrintoutType} from 'src/app/_core/models/boms/bom.model';
import {ProjectSpecification} from '../../_core/models/project-specification.model';
import {IGlassSpec, IGlassWallDiamondSpec, IGlassWallSpec} from '../../_core/interfaces/specs/IGlassSpec';
import {IDimensionsGroup} from '../../_core/interfaces/IDimensionsGroup';
import {ISelectGlass} from '../../_core/interfaces/outputEvents/ISelectGlass.interface';
import {GlassWall} from '../../_core/models/glasses/glassWall.model';
import {GlassWallElemsToPrint} from '../../_core/models/glasses/glassWallElemsToPrint.model';
import {DimensionsService} from '../../_core/services/dimensions.service';
import { Marquise } from 'src/app/_core/models/marquises/marquise.model';
import { SvgParams, Tags } from 'src/app/_core/constants/constants';
import { CrmService } from 'src/app/_core/services/crm.service';
import { ExtraOptionService } from 'src/app/_core/services/extra-option.service';
import { MarquiseVertical } from 'src/app/_core/models/marquises/marquise-vertical.model';
import { WallProfile } from 'src/app/_core/models/profiles/wall.model';
import { WallGutterProfile } from 'src/app/_core/models/profiles/wall-gutter.model';
import { GraphicsService } from 'src/app/_core/services/graphics-service';
import { Door } from 'src/app/_core/models/doors/door.model';
import { LedPattern } from 'src/app/_core/models/leds-patterns';
import { IDoorInfo } from 'src/app/_core/interfaces/IDoorInfo';
import { ILockVariant } from 'src/app/_core/interfaces/ILockVariant';
import { DoorCreator } from 'src/app/_core/models/doors/door-creator';
import { Wall } from 'src/app/_core/models/walls/wall.model';
import { IElement } from 'src/app/_core/interfaces/IElement';
import { FrameProfile } from 'src/app/_core/models/profiles/frame.model';
import { VerticalElement } from 'src/app/_core/models/vertical-element';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';
import { MarquiseTop } from 'src/app/_core/models/marquises/marquise-top.model';
import { StylingService } from 'src/app/_core/services/styling.service';
import { IPointer } from 'src/app/_core/interfaces/IPointer';
import { Montage } from 'src/app/_core/interfaces/IElementSide';
import { ISideLayout } from 'src/app/_core/interfaces/ISideLayout';
import { RearService } from 'src/app/_core/services/areas/rear.service';
import { StraightService } from 'src/app/_core/services/areas/straight.service';
import { Space } from 'src/app/_core/models/space';
import { ISelectProfile } from 'src/app/_core/interfaces/outputEvents/ISelectProfile.interface';

export enum PrintChannel {
	None = "None",
	Browser = "Browser",
	API = "API",
	CRM = "CRM"
}
@Component({
	selector: 'app-svg',
	templateUrl: './svg.component.html',
	styleUrls: ['./svg.component.css']
})
export class SvgComponent implements OnInit {
	@ViewChild('pdfPrint') pdfPrint: PDFExportComponent;
	@ViewChild('legendPrint') legendPrint: PDFExportComponent;
	@ViewChild(LEDsTemplateDialog) ledsTemplate: LEDsTemplateDialog;

	@Input() set setSelectedItems(res: any[]) {
		this.selectedItems = res;
	};
	@Output() itemClicked = new EventEmitter<any>();

	@Input() set zoom (v: number) {
		this._zoom = v;
		this.currentZoom  = `scale(${v})`;
	}

	private _zoom: number;
	currentZoom: string = "";

	printLeftMode: boolean = false;
	printRoofMode: boolean = false;
	printFrontMode: boolean = false;
	printRightMode: boolean = false;
	printRearMode: boolean = false;
	printElements: boolean = false;
	currentChannel: PrintChannel = PrintChannel.None;

	public get printMode() {
		return this.printLeftMode || this.printRightMode || this.printFrontMode || this.printRoofMode || this.printElements || this.printRearMode;
	}
	
	private previousArea: AreaType;
	private previousZoom: number;

	public currentTool: ToolboxItem;
	public selectedItems: IElement[] = [];
	public messageLoading: string = "Please wait";
	public showLoading: boolean = true;
	public SelectedLeds: boolean = false;
	public svgWidth: number = null;
	public svgHeight: number = null;
	public svgViewBox: string = null;
	public isShowSvgElement: boolean = false;
	public scale = SvgParams.SCALE;
	public start_X = SvgParams.START_X;
	public start_Y = SvgParams.START_Y;
	public glassOnPrint: IGlassSpec = null;
	public elemDimensions: IDimensionsGroup[] = null;
	private showDimensionsStatus: boolean;

	private crmData: IPrintoutFile[] = [];

	public fabrics = [];
	private expectedImages: number = 0;

	projectSpecification = new ProjectSpecification(this.roofService, this.profileService);

	// GlassWalls to print
	glassWallSpecOnPrint: IGlassWallSpec | IGlassWallDiamondSpec = null;
	glassWallElemsToPrint: GlassWallElemsToPrint = new GlassWallElemsToPrint();
	locationXDiff: number = null;
	locationYDiff: number = null;

	get maxy() {
		return this.frontService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
	}

	constructor(
		public projectService: ProjectService,
		public dimensionsService: DimensionsService,
		public roofService: RoofService,
		public rightSideService: RightSideService,
		public leftSideService: LeftSideService,
		public frontService: FrontService,
		public rearService: RearService,
		public profileService: ProfileService,
		public chosenGlassService: ChosenGlassService,
		public choosenProfileService: ChoosenProfileService,
		public chosenMarquiseService: ChosenMarquiseService,
		public api: ApiService,
		private toolBSrvc: ToolboxItemService,
		private crmService: CrmService,
		private extraService: ExtraOptionService,
		private graphicsService: GraphicsService,
		public styling: StylingService
	) { }

	ngOnInit(): void {
		this.projectService.projectReady.subscribe(
			(item) => {
				setTimeout(() => {
					this.toolBSrvc.onBgClick();
					this.showLoading = false;
					this.fabrics = this.styling.getMarquiseFabrics();
				}, 1000);
			},
			(error) => {
				this.showLoading = false;
			}
		);

		this.toolBSrvc.currentTool$.subscribe((ct: ToolboxItem) => {
			this.currentTool = ct;
		}); 

		this.choosenProfileService.resetChosenProfileSubj.subscribe(() => {
			this.selectedItems = [];
			this.SelectedLeds = false;
		});

		this.projectService.printProjectSubj.subscribe((e: IPrintoutEventArgs) => {
			if (e) {
				if (e.requestId) {
					this.currentChannel = PrintChannel.API;
					this.printProject(e.sideType);
				} else {
					this.currentChannel = PrintChannel.CRM;
					this.printProject();
				}
			} else {
				this.currentChannel = PrintChannel.Browser;
				this.printProject();
			}
		});

		this.projectService.showDimensionsSubj.subscribe((status: boolean) => {
			this.showDimensionsStatus = status
			this.showDimensions(this.showDimensionsStatus);
		});

		this.projectService.elementsChanged.subscribe((e) => {
			this.showDimensions(this.showDimensionsStatus);
			this.fabrics = this.styling.getMarquiseFabrics();
		});

		this.graphicsService.imageChanged.subscribe(c => {
			if (!c || !c.elementId || !this.fabrics) {
				return;
			}

			var fab = this.fabrics.find(f => f.id == 'fabric' + c.color.id.replace('-', ''));
			if (fab) {
				fab.url = c.imageBase64;
			}
		});

		this.frontService.doorCreation.subscribe(d => {
			this.doorCreation(d);
		});
		this.rearService.doorCreation.subscribe(d => {
			this.doorCreation(d);
		});
		this.leftSideService.doorCreation.subscribe(d => {
			this.doorCreation(d);
		});
		this.rightSideService.doorCreation.subscribe(d => {
			this.doorCreation(d);
		});
	}

	private showDimensions(status: boolean) {
		if (status && this.profileService.roofElements[ElementType.Column].length > 0) {
			try {
				this.dimensionsService.displayDimensions();
			} catch (error) {
				console.error(error);				
			}
		} else {
			this.projectService.clearCollections(ElementType.Dimension);
		}
	}

	//#region Printing

	private restorePosition() {
		setTimeout(() => {
			var mp = document.getElementById("mainPane");
			if (mp) {
				mp.scrollLeft = SvgParams.STARTUP_X;
				mp.scrollTop = SvgParams.STARTUP_Y;
			}
		}, 10);
	}

	private setViewBox(shiftLeft: number = 400, shiftTop: number = 200) {
		const w = this.projectService.template.width * SvgParams.SCALE + 150;
		const h = this.projectService.template.depth * SvgParams.SCALE;
		this.svgWidth = w + SvgParams.START_X * 1.4;
		this.svgHeight = h + SvgParams.START_X * 1.4;
		this.svgViewBox = `${SvgParams.START_X - shiftLeft} ${SvgParams.START_Y - shiftTop} ${w * 1.4} ${h * 1.4}`;
	}

	private legendToPdf() {
		setTimeout(() => {
			this.legendPrint.saveAs('legend.pdf');
		}, 0);
	}

	private printSide(side: PrintoutType, area: AreaType, types: ElementType[]) {
		eval(`this.prepare${side}Area(false)`);
		this.setViewPort(area);
		setTimeout(() => {
				switch (this.currentChannel) {
				case PrintChannel.Browser:
					this.callPrintToBrowser(side + "-Side.png");
					break;
				case PrintChannel.CRM:
					this.callPrintToCRM(side);
					break;
				case PrintChannel.API:
					this.callPrintToApi(side);
					break;
			}
		}, 0);

		if (!this.profileService.isAnyElement(area, types)) {
			return;
		}

		eval(`this.prepare${side}Area(true)`);
		setTimeout(() => {
			switch (this.currentChannel) {
				case PrintChannel.Browser:
					this.callPrintToBrowser(side + "-Elements.png");
					break;
				case PrintChannel.CRM:
					this.callPrintToCRM(PrintoutType.Elements);
					break;
				case PrintChannel.API:
					this.callPrintToApi(PrintoutType.Elements);
					break;
			}
		}, 0);
	}
	

	private printProject(sideType: PrintoutType = PrintoutType.None) {		
		this.previousArea = this.projectService.currentArea;
		this.previousZoom = this._zoom;
		this.projectSpecification.setup();

		const typesToPrint = [ElementType.GlassWall, ElementType.GlassWallDiamond, ElementType.MarquiseVertical, ElementType.Door];
		const le = this.profileService.isAnyElement(AreaType.Left, typesToPrint) ? 1 : 0;
		const re = this.profileService.isAnyElement(AreaType.Right, typesToPrint) ? 1 : 0;
		const te = this.profileService.isAnyElement(AreaType.Roof, [ElementType.MarquiseTopBottom, ElementType.MarquiseTopUp]) ? 1 : 0;
		const fe = this.profileService.isAnyElement(AreaType.Front, typesToPrint) ? 1 : 0;
		const be = this.profileService.isAnyElement(AreaType.Rear, typesToPrint) ? 1 : 0;
		var areasCount = 4; // left, right, roof, front
		if (this.projectService.template.isStandalone) {
			areasCount++; // rear
		}
		this.expectedImages = areasCount + le + re + te + fe + be;

		// if (channel == PrintChannel.Browser) {
		// 	this.legendToPdf();
		// }

		this.zoom = 1.5;

		if (sideType === PrintoutType.None || sideType === PrintoutType.Left) {
			this.printSide(PrintoutType.Left, AreaType.Left, typesToPrint);
		}
		if (sideType === PrintoutType.None || sideType === PrintoutType.Right) {
			this.printSide(PrintoutType.Right, AreaType.Right, typesToPrint);
		}
		if (sideType === PrintoutType.None || sideType === PrintoutType.Top) {
			this.printSide(PrintoutType.Top, AreaType.Roof, [ElementType.MarquiseTopBottom, ElementType.MarquiseTopUp]);
		}
		if (sideType === PrintoutType.None || sideType === PrintoutType.Front) {
			this.printSide(PrintoutType.Front, AreaType.Front, typesToPrint);
		}
		if (this.projectService.template.isStandalone && (sideType === PrintoutType.None || sideType === PrintoutType.Rear)) {
			this.printSide(PrintoutType.Rear, AreaType.Rear, typesToPrint);
		}

		setTimeout(() => {
			this.printFrontMode = false;
			this.printLeftMode = false;
			this.printRightMode = false;
			this.printRearMode = false;
			this.printRoofMode = false;
			this.printElements = false;
			this.svgWidth = null;
			this.svgHeight = null;
			this.svgViewBox = null;
			this.projectService.currentArea = this.previousArea;
			this.zoom = this.previousZoom;
			if (!this.showDimensionsStatus) {
				this.projectService.showDimensionsSubj.next(false);
			}
		}, 0);

		this.restorePosition();
	};

	private printGlasses(printoutType = PrintoutType.None) {
		// settimeouts created in this way are needed for correct workflow
		setTimeout(() => {
			this.isShowSvgElement = true;
		}, 0);

		this.projectSpecification.glasses.forEach((glass: IGlassSpec) => {
			setTimeout(() => {
				this.glassOnPrint = glass
				this.elemDimensions = this.dimensionsService.getDimensionsForSpecificGlass(glass);
				this.setViewBox(500, 200);
			}, 0);
			setTimeout(() => {
				const type = this.getType(glass.configId, 'glasses');
				const height = glass.height;
				const width = glass.width
				const configId = glass.configId;
				if (printoutType === PrintoutType.Elements) {
					this.callPrintToApi(printoutType)
				} else {
					this.callPrintToBrowser(`Glass_${type}_${height}_${width}_${configId}.png`);
				}
			}, 0);
		})

		setTimeout(() => {
			this.isShowSvgElement = false;
			this.glassOnPrint = null;
			this.elemDimensions = null;
		}, 0);
	}

	private getType(catId: string, elements: string) {
		var e = this.projectService.template[elements].find(c => c.id == catId);
		return e ? e.name : "-";
	}

	private setViewPort(area: AreaType) {
		setTimeout(() => {
			var srv = this.projectService.getAreaService(area, this.leftSideService, this.rightSideService, this.frontService, this.roofService, this.rearService);
			const p = this.projectService.calculateViewPort(area, srv, this.currentChannel);

			this.svgViewBox = `${p.x} ${p.y} ${p.w} ${p.h}`;
			this.svgWidth = p.w;
			this.svgHeight = p.h;
		} ,0);
	}

	private prepareLeftArea(elements: boolean = false) {
		setTimeout(() => {
			this.projectService.currentArea = AreaType.Left;
		}, 0);
		this._prepareArea(true, false, false, false, false, elements);
	}

	private prepareRightArea(elements: boolean = false) {
		setTimeout(() => {
			this.projectService.currentArea = AreaType.Right;
		}, 0);
		this._prepareArea(false, false, true, false, false, elements);
	}
	private prepareTopArea(elements: boolean = false) {
		setTimeout(() => {
			this.projectService.currentArea = AreaType.Roof;
		}, 0);
		this._prepareArea(false, true, false, false, false, elements);
	}
	private prepareFrontArea(elements: boolean = false) {
		setTimeout(() => {
			this.projectService.currentArea = AreaType.Front;
		}, 0);
		this._prepareArea(false, false, false, true, false, elements);
	}
	private prepareRearArea(elements: boolean = false) {
		setTimeout(() => {
			this.projectService.currentArea = AreaType.Rear;
		}, 0);
		this._prepareArea(false, false, false, false, true, elements);
	}


	private _prepareArea(left: boolean, roof: boolean, right: boolean, front: boolean, rear: boolean, elements: boolean) {
		setTimeout(() => {
			this.printLeftMode = left;
			this.printRightMode = right;
			this.printFrontMode = front;
			this.printRoofMode = roof;
			this.printRearMode = rear;
			this.printElements = elements;
			this.dimensionsService.displayDimensions(!elements, elements);
		}, 0)
	}

	private callPrintToBrowser(name: string) {
		this.pdfPrint.export().then((group) => {
			exportImage(group).then((data) => {
				saveAs(data, name);
			});
		});
	}

	private callPrintToApi(type: PrintoutType, id: string = "") {
		this.pdfPrint.export().then((group) => {
			exportImage(group).then((data) => {
				this.api.sendImage(this.projectService.orderCode, type, data, id).subscribe(e => {
					this.projectService.printoutReady.next({ type: type, content: e});
				});
			});
		});
	}

	private callPrintToCRM(type: PrintoutType) {
		this.pdfPrint.export().then((group) => {
			exportImage(group).then((data) => {
				this.crmData.push( { type, content: data });
				if (this.crmData.length == this.expectedImages) {
					this.crmService.exportSave(this.crmData);
				}
			});
		});
	}

	//#endregion

	private addToSelection(item: any, collection: any[]) {
		var group = this.projectService.grouper.getGroupFor(item.id);
		if (group == null) {
			this.selectedItems.push(item);
		} else {
			group.forEach(g => {
				this.selectedItems.push(collection.find(f => f.id == g.id));
			});
		}
		this.choosenProfileService.setChosenProfile(this.selectedItems as Profile[]);
	}

	selectProfile(event: ISelectProfile) {
		if (this.currentTool) {
			if (this.currentTool.type !== ToolboxItemType.LedSpot) {
				this.projectService.freezeProjProp();
			}
			this.currentToolActionAfterSelectingProfile(event.profile, event.pointer);
			this.toolBSrvc.setCurrTool(null);
		} else {
			if ((!event.pointer || !(event.pointer.ctrlKey || event.pointer.shiftKey))) {
				this.selectedItems = [];
			}
			this.SelectedLeds = event.profile.type === ElementType.Led;
			this.addToSelection(event.profile, this.projectService.getCurrentAreaElements()[ElementType[event.profile.type]]);
		}
	}

	private currentToolActionAfterSelectingProfile(prof: Profile, pointerEvent: IPointer) {
		switch (this.currentTool.type) {
			case ToolboxItemType.Column:
				if (prof.type === ElementType.SideFinish || prof.type === ElementType.Front || prof.type === ElementType.WallProfile) {
					if(this.projectService.checkConflictWithColumn(pointerEvent.offsetX, pointerEvent.offsetY, prof)){
						const newColumn: ColumnProfile = this.projectService.createColumn(pointerEvent.offsetX, pointerEvent.offsetY, prof, this.currentTool);
						if (prof.type != ElementType.SideFinish) {
							this.roofService.changeColumnType(newColumn, newColumn.configId);
						}
						this.projectService.emitChange();
					}
				}
				break;

			case ToolboxItemType.LedSpot:
				this.executeLedSpotsToolAction();
				break;

			case ToolboxItemType.LedStripBar:
				this.projectService.createLedStripes(this.currentTool.id);
				break;
			case ToolboxItemType.WallGutter:
				var rear = prof as WallProfile;
				if (rear) {
					var wgDef = this.projectService.template.wallGutters.find(g => g.id == this.currentTool.id);
					var mid = (rear.rectOnRoof.h /2) - (wgDef.size.depth / 2) * SvgParams.SCALE;
					var wg = new WallGutterProfile(pointerEvent.offsetX, rear.rectOnRoof.y + mid, rear, wgDef);
					rear.wallGutters.push(wg);
					this.profileService.roofElements[ElementType.WallGutter].push(wg);
					this.projectService.emitChange();
				}
				break;
		}
	}

	selectVertical(element: IElement) {
		this.selectedItems = [];
		if (this.currentTool) {
			if (this.currentTool.type == ToolboxItemType.MarquiseVertical) {
				const d = element as VerticalElement;
				switch (d.area) {
					case AreaType.Front:
						this.onFrontDrop({ offsetX: d.rectOnFront.x + 10, offsetY: d.rectOnFront.y + 10 });
						break;
					case AreaType.Left:
						this.leftSideService.onDrop({ offsetX: d.rectOnLeft.x + 10, offsetY: d.rectOnLeft.y + 10 }, this.currentTool);
						break;
					case AreaType.Right:
						this.rightSideService.onDrop({ offsetX: d.rectOnRight.x + 10, offsetY: d.rectOnRight.y + 10 }, this.currentTool);
						break;
				}
			}
			this.toolBSrvc.onBgClick();
			return
		}

		this.addToSelection(element, this.projectService.profileService.roofElements[element.type]);
	}

	selectDoor(d: Door) {
		this.selectVertical(d);
	}

	selectWall(w: Wall) {
		this.selectVertical(w);
	}

	selectWallMuntin(m: FrameProfile) {
		this.selectVertical(m);
	}

	selectMarquise(marquise: Marquise | MarquiseVertical) {
		this.selectedItems = [];
		var message = "";
		if (this.currentTool) {
			switch (this.currentTool.type) {
				case ToolboxItemType.LedSpot:
					this.executeLedSpotsToolAction();
					break;
				case ToolboxItemType.GlassWall:
				case ToolboxItemType.GlassWallDiamond:
				case ToolboxItemType.MarquiseVertical:
				case ToolboxItemType.Door:
				case ToolboxItemType.Wall:
					if (marquise instanceof MarquiseVertical) {
						if (marquise.montage == Montage.Middle) {
							switch (this.currentTool.type) {
								case ToolboxItemType.GlassWall:
								case ToolboxItemType.GlassWallDiamond:
									message = $localize`:Glass wall|Validation message:Cannot place glass wall on a marquise!`;
									break;
								case ToolboxItemType.MarquiseVertical:
									message = $localize`:Marquise|Validation message:Cannot place a marquise on another one!`;
									break;
								case ToolboxItemType.Door:
									message = $localize`:Door|Validation message:Cannot place a door on a marquise!`;
									break;
								case ToolboxItemType.Wall:
									message = $localize`:Wall|Validation message:Cannot place a wall on a marquise!`;
									break;
							}
							this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
						} else {
							this.onFrontDrop({ offsetX: marquise.rectOnFront.x + 100, offsetY: marquise.rectOnFront.y + 100 });
						}						
					}
			}
			this.toolBSrvc.onBgClick();
			return
		}

		this.addToSelection(marquise, this.projectService.getCurrentAreaElements()[ElementType[marquise.type]]);

		if (!(marquise instanceof MarquiseVertical)) {
			this.chosenMarquiseService.setChosenMarquise(this.selectedItems as MarquiseTop[]);
		} else {
			this.chosenMarquiseService.chosenMarquiseSubj.next(this.selectedItems as MarquiseTop[]);
		}
	}

	mouseOverGlass(ptr: MouseEvent) {
		if (!this.currentTool) {
			return
		}

		switch (this.currentTool.type) {
			case ToolboxItemType.MarquiseTopUp:
				this.createMarquiseTopUpHover(ptr);
				break;
			case ToolboxItemType.MarquiseTopBottom:
				this.createMarquiseTopBottomHover(ptr);
				break;
		} 
	}

	private createMarquiseTopUpHover(ptr: MouseEvent) {
		if (this.selectedItems.length > 0 && this.selectedItems[0].type !== ElementType.Glass) {
			this.selectedItems = [];
		}

		this.createMarquiseTopUp('hover', ptr);
	}

	private createMarquiseTopBottomHover(ptr: MouseEvent) {
		if (this.selectedItems.length > 0 && this.selectedItems[0].type !== ElementType.Glass) {
			this.selectedItems = [];
		}

		this.createMarquiseTopBottom('hover', ptr);
	}

	private createMarquiseTopUp(action: 'click' | 'hover', ptr: MouseEvent) {
		this.projectService.createMarquiseTop(this.selectedItems as Glass[], this.currentTool.id, ElementType.MarquiseTopUp,
			this.roofService.getShapeAreaPoints(), this.frontService.getShapeAreaPoints(),
			this.leftSideService.getShapeAreaPoints(), this.rightSideService.getShapeAreaPoints(),
			action === 'click', action === 'hover', ptr);
	}

	private createMarquiseTopBottom(action: 'click' | 'hover', ptr: MouseEvent) {
		this.projectService.createMarquiseTop(this.selectedItems as Glass[], this.currentTool.id, ElementType.MarquiseTopBottom,
			this.roofService.getShapeAreaPoints(), this.frontService.getWorkAreaPoints(),
			this.leftSideService.getWorkAreaPoints(), this.rightSideService.getWorkAreaPoints(),
			action === 'click', action === 'hover', ptr);
	}

 	mouseOutGlass() {
		if (!this.currentTool) {
			return
		}

		if (this.currentTool.type === ToolboxItemType.MarquiseTopUp) {
			this.profileService.roofElements[ElementType.MarquiseTopUpMouseover] = [];
		} else if (this.currentTool.type === ToolboxItemType.MarquiseTopBottom) {
			this.profileService.roofElements[ElementType.MarquiseTopBottomMouseover] = [];
		}
	}

	selectGlassOnSides(response: ISelectGlass) {
		this.selectGlass(response.glass, response.pointerEvent);
	}

	selectGlass(glass: Glass | GlassWall, pointerEvent: MouseEvent) {
		if (this.currentTool) {
			if (this.currentTool.type !== ToolboxItemType.LedSpot && this.currentTool.type !== ToolboxItemType.Glass) {
				this.projectService.freezeProjProp();
			}
			this.currentToolActionAfterSelectingGlass(glass, pointerEvent);
		} else {
			if (this.styling.hideOnLayer(glass)) {
				if (this.styling.visibleLayer(LayerLevel.Inside)) {
					const m = this.projectService.profileService.findMarquiseBelowGlass(glass as Glass);
					if (m) {
						this.selectMarquise(m);
					}
				}
				return;
			}
			if ((!pointerEvent || !(pointerEvent.ctrlKey || pointerEvent.shiftKey))) {
				this.selectedItems = [];
			}
			const indexOfSelectedGlass = this.selectedItems.indexOf(glass);

			if (indexOfSelectedGlass >= 0) {
				this.selectedItems.splice(indexOfSelectedGlass, 1);
			} else {
				this.addToSelection(glass, this.projectService.getCurrentAreaElements()[ElementType.Glass]);
			}
			// this.itemClicked.emit({ type: CardType.Glass, items: this.selectedItems });
			this.chosenGlassService.setChosenGlass(this.selectedItems as Glass[]);
		}
		this.toolBSrvc.setCurrTool(null);
	}

	private currentToolActionAfterSelectingGlass(glass: Glass | GlassWall, pointerEvent: MouseEvent) {
		switch (this.currentTool.type) {
			case ToolboxItemType.Connector:
				this.executeProfileToolAction(glass as Glass);
				break;
			case ToolboxItemType.MarquiseTopUp:
			case ToolboxItemType.MarquiseTopBottom:
				this.executeMarquiseToolAction(pointerEvent);
				break;
			case ToolboxItemType.MarquiseVertical:
				this.selectVertical(glass);
				break;
			case ToolboxItemType.Glass:
				this.executeGlassToolAction();
				break;

			case ToolboxItemType.RoofGutter:
				this.executeRoofGutterToolAction(glass as Glass);
				break;
			case ToolboxItemType.RoofWindow:
				this.executeRoofWindowToolAction(glass as Glass);
				break;

			case ToolboxItemType.RoofVentilator:
				this.executeRoofVentilatorToolAction(glass as Glass);
				break;

				case ToolboxItemType.LedSpot:
				this.executeLedSpotsToolAction();
				break;

			case ToolboxItemType.LedStripBar:
				this.projectService.createLedStripes(this.currentTool.id);
				break;
		}
	}

	private executeRoofGutterToolAction(glass: Glass) {
		this.selectedItems = [];
		const roofWindowInfo = this.projectService.template.roofAddons.find(e => e.id === this.currentTool.id);
		this.roofService.createRoofWindow(glass, roofWindowInfo);
		this.toolBSrvc.onBgClick();
	}

	private executeRoofWindowToolAction(glass: Glass) {
		this.selectedItems = [];
		const roofWindowInfo = this.projectService.template.roofWindows.find(e => e.id === this.currentTool.id);
		this.roofService.createRoofWindow(glass, roofWindowInfo);
		this.toolBSrvc.onBgClick();
	}


	private executeRoofVentilatorToolAction(glass: Glass) {
		this.selectedItems = [];
		const info = this.projectService.template.roofAddons.find(e => e.id === this.currentTool.id);
		this.roofService.createRoofVentilator(glass, info);

		this.toolBSrvc.onBgClick();
	}

	private executeProfileToolAction(glass: Glass) {
		if (this.selectedItems.length == 1) {
			this.selectedItems = [];
		}
		switch (this.currentTool.type) {
			case ToolboxItemType.Connector:
				this.projectService.divideGlassHorizontal([glass]);
				this.extraService.addElevator();
				this.projectService.emitChange();
				break;
		}
	}

	private executeGlassToolAction() {
		this.roofService.applyTemplate("", "", this.currentTool.id);
	}

	private executeMarquiseToolAction(ptr: MouseEvent) {
		this.profileService.roofElements[ElementType.MarquiseTopUpMouseover] = [];
		this.profileService.roofElements[ElementType.MarquiseTopBottomMouseover] = [];

		if (this.selectedItems.length > 0 && this.selectedItems[0].type !== ElementType.Glass) {
			this.selectedItems = [];
		}

		if (this.currentTool.type === ToolboxItemType.MarquiseTopUp) {
			this.createMarquiseTopUp('click', ptr);
		} else if (this.currentTool.type === ToolboxItemType.MarquiseTopBottom) {
			this.createMarquiseTopBottom('click', ptr);
		}
		this.toolBSrvc.onBgClick();
	}

	showPatterns: boolean;
	private executeLedSpotsToolAction() {
		this.showPatterns = true;
	}

	ledPatternChange(v: LedPattern) {
		if (v != null) {
			this.roofService.selectedLedPattern(v);
			this.projectService.freezeProjProp();
		}
		this.showPatterns = false;
	}

	showDoors: boolean;
	showSideLayout: boolean;
	currentDoor: Door;
	doorVariants: IDoorInfo[];

	public lockVariantChange(event: { door: IDoorInfo, lock: ILockVariant }) {
		if (event?.door && event?.lock) {
			const def = this.projectService.template.doors.find(d => d.id == event.door.id);

			var creator = new DoorCreator(this.projectService,
				new Space(this.currentDoor.leftProfile, this.currentDoor.rightProfile, this.currentDoor.topProfile, this.currentDoor.bottomProfile),
				this.currentDoor.area, this.projectService.CurrentAreaService);
				
			var newDoor = creator.createDoor(def, this.currentDoor, this.currentDoor.topProfile.rect.y);
			newDoor.lockVariant = event.lock;
			this.currentDoor.lockVariant = event.lock;
			this.projectService.profileService.addVertical(newDoor);
			this.projectService.freezeProjProp();
			if (newDoor.height != newDoor.maxHeight) {
				creator.setHeight(newDoor, newDoor.maxHeight, false);
			}
			newDoor.refreshSurround(this.profileService);
			this.projectService.emitChange();
		}
		this.showDoors = false;
	}

	private doorCreation(d: Door) {
		this.currentDoor = d;
		var creator = new DoorCreator(this.projectService, new Space(d.leftProfile, d.rightProfile, d.topProfile, d.bottomProfile), d.area, this.projectService.CurrentAreaService);
		this.doorVariants = creator.getVariants(d);
		d.lockVariant = null;
		this.showDoors = true;
	}


	onRoofShapeDrop(pointerEvent: MouseEvent) {
		if (this.currentTool?.type == ToolboxItemType.MarquiseTopBottom || this.currentTool?.type == ToolboxItemType.MarquiseTopUp) {
			this.executeMarquiseToolAction(pointerEvent);
			return;
		}
		
		this.roofService.onDrop(pointerEvent, this.currentTool);
		this.toolBSrvc.setCurrTool(null);
		this.projectService.recalculateDimensions();
	}

	onBgClick() {
		this.toolBSrvc.onBgClick();
		this.selectedItems = [];
		this.SelectedLeds = false;
	}

	onRearDrop(ptr: IPointer) {
		this.onStraigthDrop(ptr, this.rearService);
	}

	onFrontDrop(ptr: IPointer) {
		this.onStraigthDrop(ptr, this.frontService);
	}

	private onStraigthDrop(ptr: IPointer, srv: StraightService) {
		if (this.currentTool == null) {
			return;
		}
		switch (this.currentTool.type) {
			case ToolboxItemType.GlassWall:
				this.projectService.createGlassWall(ptr, this.currentTool, srv);
				this.projectService.recalculateDimensions();
				this.projectService.emitChange();
				break;
			default:
				srv.onDrop(ptr, this.currentTool);
				break;
		}
		this.toolBSrvc.setCurrTool(null);
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		if (this.currentTool != null) {
			this.toolBSrvc.onBgClick();
		}
	}

	confirmOpened: boolean;
	confirmMessage: string;
	sideLayout: ISideLayout;
	secondSideLayout: boolean;
	currentToolType: ToolboxItemType = ToolboxItemType.None;

	openSideLayoutPopup($event: any) {
		this.currentToolType = this.currentTool?.type ?? ToolboxItemType.Elements;
		this.toolBSrvc.onBgClick(false);
		this.showSideLayout = true;
	}

	onConfirmAction(event: any) {
		this.confirmOpened = false;
		if (event.status == true) {
			this.projectService.applySideLayout(this.projectService.currentArea, this.sideLayout, this.secondSideLayout, this.toolBSrvc.getCurrTool(), this.leftSideService, this.rightSideService);
			this.toolBSrvc.onBgClick(true);
		}
	}

	public applySideLayout(event: { layout: ISideLayout, secondSide: boolean }) {
		this.showSideLayout = false;
		if (!event) {
			return;
		}
		var sideVerts = this.profileService.getVerticals(this.projectService.currentArea);
		var opositeVerts = this.profileService.getVerticals(this.projectService.currentArea == AreaType.Left ? AreaType.Right : AreaType.Left);
		
		this.sideLayout = event.layout;
		this.secondSideLayout = event.secondSide;
		if (sideVerts.length > 0) {
			this.confirmMessage = $localize`:Side layout popup|Confirmation message:Thare are already some elements on this side. This operation will remove them. Are you sure?`;
		} else if (event.secondSide && opositeVerts.length > 0) {
			this.confirmMessage = $localize`:Side layout popup|Confirmation message:Thare are already some elements on the other side. This operation will remove them. Are you sure?`;
		} else {
			this.projectService.applySideLayout(this.projectService.currentArea, this.sideLayout, this.secondSideLayout, this.toolBSrvc.getCurrTool(), this.leftSideService, this.rightSideService);
			this.toolBSrvc.onBgClick(true);
			return;
		}
		this.confirmOpened = true;
	}


}