import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AreaType } from '../../../../_core/interfaces/IAreaType';
import { GlassWall } from '../../../../_core/models/glasses/glassWall.model';
import { MuntinProfile } from 'src/app/_core/models/profiles/muntin.model';
import { Profile } from '../../../../_core/models/profiles/profile.model';
import { ProjectService } from 'src/app/_core/services/project.service';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { GlassWallDiamond } from 'src/app/_core/models/glasses/glassWallDiamond.model';
import { StylingService } from 'src/app/_core/services/styling.service';
import { IPointer } from 'src/app/_core/interfaces/IPointer';
import { ISelectGlass } from 'src/app/_core/interfaces/outputEvents/ISelectGlass.interface';
import { ISelectProfile } from 'src/app/_core/interfaces/outputEvents/ISelectProfile.interface';

@Component({
	selector: 'g[app-glass-wall]',
	templateUrl: './glass-wall.component.html',
	styleUrls: ['./glass-wall.component.css']
})
export class GlassWallComponent {
	private _elements: any;

	@Input() set elements(elems) {
		this._elements = elems;
	}
	get elements() {
		return this._elements;
	}
	@Input() areaType: AreaType;
	@Input() selectedItems: any[];
	@Input() printMode: boolean;

	@Output() selectGlassWallEvent = new EventEmitter<ISelectGlass>();
	@Output() selectProfileEvent = new EventEmitter<ISelectProfile>();

	constructor(public styling: StylingService, private projectService: ProjectService) { }


	selectGlass(glass: GlassWall, pointerEvent: PointerEvent) {		
		this.selectGlassWallEvent.emit({glass, pointerEvent});
	}

	selectProfile(prof: MuntinProfile, pointer: MouseEvent) {
		this.selectProfileEvent.emit({ profile: prof, pointer: pointer });
	}

	getGlassWalls() {
		return this.projectService.profileService.getGetGlassWalls(this.areaType);
	}

	getDiamonds() {
		return this.projectService.profileService.getGetDiamonds(this.areaType);
	}

}
