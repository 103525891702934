import { Component, EventEmitter, Output } from '@angular/core';
import { SvgAreaBaseComponent } from '../svg-area-base/svg-area-base.component';

@Component({
	selector: 'g[app-svg-roof]',
	templateUrl: './svg-roof.component.html',
	styleUrls: ['./svg-roof.component.css']
})
export class SvgRoofComponent extends SvgAreaBaseComponent {
	@Output() openLayoutEvent = new EventEmitter<boolean>(false);
	@Output() mouseOverGlassEvent = new EventEmitter<MouseEvent>(null);
	@Output() mouseOutGlassEvent = new EventEmitter<boolean>(false);

	mouseOverGlass(ptr: MouseEvent) {
		this.mouseOverGlassEvent.emit(ptr);
	}

	mouseOutGlass() {
		this.mouseOutGlassEvent.emit(true);
	}

}
