import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { GridModule } from '@progress/kendo-angular-grid';

import { load, IntlModule } from '@progress/kendo-angular-intl';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandler, HttpLoadingInterceptor } from './app-errors';
import { MainComponent } from './main/main.component';
import { AppCardComponent } from './card/card.component';
import { CardGlassComponent } from './card/card-glass/card-glass.component';
import { CardProfileComponent } from './card/card-profile/card-profile.component';
import { ProjectDetailsComponent } from './main/project-details/project-details.component';
import { ToolboxComponent } from './main/toolbox/toolbox.component';
import { SpecComponent } from './main/spec/spec.component';
import { AreasComponent } from './main/areas/areas.component';
import { ProjectService } from './_core/services/project.service';
import { ApiService } from './_core/services/api.service';
import { LocationService } from './_core/services/location.service';
import { SvgSideComponent } from './main/svg-component/svg-side/svg-side.component';
import { SvgComponent } from './main/svg-component/svg.component';

import likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import currencyData from 'cldr-data/supplemental/currencyData.json';
import numbers from 'cldr-data/main/de/numbers.json';
import timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import calendar from 'cldr-data/main/de/ca-gregorian.json';
import currencies from 'cldr-data/main/de/currencies.json';
import dateFields from 'cldr-data/main/de/dateFields.json';
import { WaitComponent } from './main/wait/wait.component';
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { LEDsTemplateDialog } from './dialogs/leds-template/leds-template.dialog';
import { LEDsPatternComponent } from './card/card-leds/card-leds-pattern.component';
import { LEDsStripeComponent } from './card/card-leds/card-leds-stripe.component';
import { WizardDialog } from './dialogs/wizard/wizard.dialog';
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { WizardParameterComponent } from './dialogs/wizard-parameter/wizard-parameter.component';
import { PopupModule } from "@progress/kendo-angular-popup";
import { CardColumnComponent } from './card/card-profile/card-column.component';
import { CardMarquiseComponent } from './card/card-profile/card-marquise.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AfterValueChangedDirective } from './_core/directives/after-value-changed.directive';
import { BomComponent } from './main/bom/bom.component';
import { ColorsGroupDialogComponent } from './dialogs/colors-group-dialog/colors-group-dialog.component';
import { GlassWallComponent } from './main/svg-component/elements/glass-wall/glass-wall.component';
import { ColorPaletteComponent } from './_shared/color-palette/color-palette.component';
import { SearchColorPipe } from './_core/pipes/search-color.pipe';
import { ExtrasOptionsComponent } from './main/extras-options/extras-options.component';
import { CardExtraOptionComponent } from './card/card-extra-option/card-extra-option.component';
import { AuthService } from './_core/services/auth.service';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { MSALInstanceFactory } from './_core/auth.config';
import { InteractionType } from '@azure/msal-browser';
import { AuthorizeInterceptor } from './_core/services/auth.interceptor';
import { TokenStorage } from './_core/services/token.storage';
import { CardFrontComponent } from './card/card-profile/card-front.component';
import { LogoutComponent } from './main/logout/logout.component';
import { CuttingOnSiteComponent } from './_shared/cuttingonsite/cuttingonsite.component';
import { CommonPropertiesComponent } from './_shared/common-properties/common-properties.component';
import { CrmService } from './_core/services/crm.service';
import { NumTbxComponent } from './_shared/numeric-textbox/num-tbx.component';
import { MigrationReportDialogComponent } from './dialogs/migration-report/migration-report-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { CardMarquiseVerticalComponent } from './card/card-profile/card-marquise-vertical.component';
import { MarquiseVerticalComponent } from './main/svg-component/elements/marquise-vertical/marquise-vertical.component';
import { StaticsComponent } from './main/statics/statics.component';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { CustomerPlateComponent } from './main/svg-component/elements/customer-plate/customer-plate.component';
import { DimensionComponent } from './main/svg-component/elements/dimension/dimension.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { OutflowComponent } from './main/svg-component/elements/outflow/outflow.component';
import { LegendComponent } from './main/svg-component/legend/legend.component';
import { DoorComponent } from './main/svg-component/elements/door/door.component';
import { CardDoorComponent } from './card/card-profile/card-door.component';
import { DoorVariantsComponent } from './dialogs/door-variants/door-variants.component';
import { WallComponent } from './main/svg-component/elements/wall/wall.component';
import { CardWallComponent } from './card/card-profile/card-wall.component';
import { CardGlassWallComponent } from './card/card-glass-wall/card-glass-wall.component';
import { SideLayoutComponent } from './dialogs/side-layout/side-layout.component';
import { SvgRoofComponent } from './main/svg-component/svg-roof/svg-roof.component';
import { SvgFrontComponent } from './main/svg-component/svg-front/svg-front.component';
import { SvgRearComponent } from './main/svg-component/svg-rear/svg-rear.component';
import { ColumnComponent } from './main/svg-component/elements/column/column.component';



export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return { 
		interactionType: InteractionType.Redirect,
	};
}


load(
  likelySubtags,
  weekData,
  currencyData,
  numbers,
  currencies,
  calendar,
  dateFields,
  timeZoneNames
);

@NgModule({
  declarations: [
    SvgComponent,
    AppComponent,
    MainComponent,
    StaticsComponent,
    AppCardComponent,
    CardGlassComponent,
    CardGlassWallComponent,
    CardProfileComponent,
    CardColumnComponent,
    CardFrontComponent,
    CardMarquiseComponent,
    CardMarquiseVerticalComponent,
    CuttingOnSiteComponent,
    CommonPropertiesComponent,
    ToolboxComponent,
    SpecComponent,
    BomComponent,
    AreasComponent,
    ProjectDetailsComponent,
    WizardDialog,
    SvgSideComponent,
    SvgRoofComponent,
    SvgFrontComponent,
    SvgRearComponent,
    WaitComponent,
    LEDsTemplateDialog,
    LEDsPatternComponent,
    LEDsStripeComponent,
    DoorVariantsComponent,
    WizardParameterComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    MigrationReportDialogComponent,
    ErrorDialogComponent,
    AfterValueChangedDirective,
    ColorsGroupDialogComponent,
    GlassWallComponent,
    MarquiseVerticalComponent,
    ColorPaletteComponent,
    SearchColorPipe,
    ExtrasOptionsComponent,
    CardExtraOptionComponent,
	  LogoutComponent,
    NumTbxComponent,
    CustomerPlateComponent,
    DimensionComponent,
    OutflowComponent,
    LegendComponent,
    DoorComponent,
    CardDoorComponent,
    WallComponent,
    ColumnComponent,
    CardWallComponent,
    SideLayoutComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    DragDropModule,
    AppRoutingModule,
    DropDownsModule,
    BrowserAnimationsModule,
    PDFExportModule,
    RouterModule.forRoot([
      { path: '', component: MainComponent, pathMatch: 'full' },
	]),
    LayoutModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    TreeViewModule,
    InputsModule,
    WindowModule,
    IndicatorsModule,
    TooltipModule,
    PopupModule,
    NotificationModule,
    ListViewModule,
    MsalModule,
    IconsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    ApiService,
    ProjectService,
    LocationService,
    CrmService,
    TokenStorage,
    AuthService,
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
  	{ provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
	  { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent,
  	MsalRedirectComponent
  ],
  exports: [],
  entryComponents: []
})
export class AppModule { }
