import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WIZARD_POPUP_TIMEOUT } from 'src/app/_core/constants/constants';
import { WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';

@Component({
  selector: 'app-wizard-parameter',
  templateUrl: './wizard-parameter.component.html',
  styleUrls: ['./wizard-parameter.component.css']
})
export class WizardParameterComponent implements OnInit {

  @Input() data: WizardParameter = new WizardParameter();
  @Input() selected: boolean;
  @Output() onSelect = new EventEmitter<any>();
  src: string;
  popupTimeout = WIZARD_POPUP_TIMEOUT;
  ////////////
  public show = false;
  public enabled = true;
  public duration = 200;
  public type = "slide";
  public direction = "down";
  public zoom: boolean = true;
  public get animate(): any {
    if (this.enabled) {
      return {
        type: this.type,
        direction: this.direction,
        duration: this.duration,
      };
    }

    return false;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.src = this.data.thumbnail;
  }

  selectedWizardCard() {
    this.onSelect.emit(this.data.id);
  }
  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
