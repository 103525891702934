<div class="panelbar-wrapper">
	<app-wait [show]="showLoading" [message]="messageLoading"></app-wait>

	<kendo-panelbar *ngIf="model != null" expandMode="kendoPanelBarExpandMode.Single" >

		<kendo-panelbar-item *ngFor="let t of model" i18n-title [title]="t.title" [expanded]="false" [disabled]="toolBSrvc.getPanelDisabled(t)">
			<ng-template kendoPanelBarContent>
				<kendo-treeview [nodes]="t.items" textField="text">
					<ng-template kendoTreeViewNodeTemplate let-dataItem>
						<ng-template #template let-anchor>
							<!-- <img src="{{dataItem.thumbnail}}" width="200" alt=""> -->
							<div [style.backgroundImage]="'url(' + dataItem.thumbnail + ')'" class="thumbnail"></div>
						</ng-template>
						<span kendoTooltip [tooltipTemplate]="template" filter="span">
							<span class="k-icon k-i-info"></span>
						</span>
						<span (click)="setCurrentTool(dataItem)">
							{{ dataItem.name }}
						</span>
					</ng-template>
				</kendo-treeview>
			</ng-template>
		</kendo-panelbar-item>

	</kendo-panelbar>
</div>
