import { GlassParameter, WizardParameter } from '../models/wizard/wizard-parameter.model';
import { IConnectable } from './IConnectable';

export class LockParameter extends WizardParameter {
  lockType: LockType;
}

export interface IDoorInfo extends WizardParameter, IConnectable {
  doorType: DoorType;
  rails: number;
  wings: number;
  glasses: GlassParameter[];
  locks: LockParameter[];
  doorExtras: string[];
  foundationId: string;
  locations: string[];
}

export enum DoorType {
  None = '',
  SingleSide = 'SingleSide',
  DoubleSide = 'DoubleSide'
}

export enum LockType {
  None = "None",
  Handle = "Handle",
  SingleSide = "SingleSide",
  DoubleSide = "DoubleSide"
}
