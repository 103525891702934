import { Injectable } from '@angular/core';
import { MAX_UNDO_COUNT } from '../constants/constants';
import { AreaType } from '../interfaces/IAreaType';
import { ProjectStoreModel } from '../models/project-store/project-store.model';
import { ProjectService } from './project.service';
import { StorageService } from './storage.service';
import { MD5 } from 'crypto-js'
import { CrmService } from './crm.service';

export interface IUndoStack {
	area: AreaType;
	content: string;
}

@Injectable({
	providedIn: 'root',
})
export class StateService {

	constructor(
		private projectService: ProjectService,
		private crmService: CrmService) {
		}

	
	private initialState: IUndoStack;
	private undoFifo: IUndoStack[] = [];
	private _undoIndicator: number = MAX_UNDO_COUNT;


	public get undoDisabled() {
		return this.undoIndicator < 2 || this.undoFifo.length < 2 || this.undoIndicator - 2 >= this.undoFifo.length; 
	}

	public get redoDisabled() {
		return this.undoIndicator >= this.undoFifo.length;
	}

	private get undoIndicator() {
		return this._undoIndicator;
	}
	private set undoIndicator(v: number) {
		if (v > this.undoFifo.length) {
			v = this.undoFifo.length;
		}
		this._undoIndicator = v;
	}

	public save(model: ProjectStoreModel) {
		var last = this.undoDisabled ? null : this.undoFifo[this.undoFifo.length - 1];
		var current = JSON.stringify(model);
		if (this.undoFifo.length == 0) {
			this.initialState = { area: AreaType.Roof, content: current };
		}
		var lastHash = MD5(last?.content).toString();
		var currHash = MD5(current).toString();

		if (lastHash != currHash) {
			this.undoFifo.push({ area: this.projectService.currentArea, content: current });
			if (this.undoFifo.length > MAX_UNDO_COUNT) {
				this.undoFifo.splice(0, 1);
			}
		}
		this.undoIndicator = this.undoFifo.length;
	}

	public undo(storageService: StorageService, rewind: boolean) {
		if (this.undoDisabled) {
			return;
		}
		if (rewind) {
			this.undoIndicator = 1;
			this.undoFifo = [];
			this.undoFifo.push (this.initialState);
			const model = JSON.parse(this.initialState.content);
			storageService.restore(model, false, false);
			this.crmService.designChanged(model);
			return AreaType.Roof;
		}

		this.undoIndicator--;
		var prev = this.undoFifo[this.undoIndicator - 1];
		const model = JSON.parse(prev.content);
		storageService.restore(model, false, false);
		this.crmService.designChanged(model);

		return prev.area;

	}

	public redo(storageService: StorageService) {
		if (this.redoDisabled) {
			return;
		}
		var ms = this.undoFifo[this.undoIndicator];
		const model = JSON.parse(ms.content);
		storageService.restore(model, false, false);
		this.crmService.designChanged(model);
		this.undoIndicator++;

		return ms.area;
	}

}
