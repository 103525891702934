import { ElementSide } from "../../interfaces/IElementSide";
import { Glass } from "./glass.model";

export class GlassBunch {
	private glasses: Map<ElementSide, Glass[]> = new Map<ElementSide, Glass[]>();

	constructor() {
		this.glasses[ElementSide.Left] = [];
		this.glasses[ElementSide.Right] = [];
		this.glasses[ElementSide.Top] = [];
		this.glasses[ElementSide.Bottom] = [];
	}

	assignGlass(side: ElementSide, glass: Glass) {
		if (!this.glasses[side].find(g => g.id == glass.id)) {
			this.glasses[side].push(glass);
		}
	}

	getGlasses(side: ElementSide) {
		return this.glasses[side];
	}

	removeGlass(side: ElementSide, glass: Glass) {
		this.glasses[side] = this.glasses[side].filter(g => g.id != glass.id);
	}

	clearOnSide(side: ElementSide) {
		this.glasses[side] = [];
	}
}

