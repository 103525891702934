import { ICircle } from 'src/app/_core/interfaces/ICircle'
import { ElementType } from 'src/app/_core/models/ToolboxModel'
import { Profile } from './profile.model'
import { LayerLevel } from '../../interfaces/LayerLevel';

export class LED extends Profile {

    selected: boolean;

    get type(): ElementType {
        return ElementType.Led;
    }

    get name(): string {
        return $localize`:Common|LED:LED`;
    }

    get layer(): LayerLevel {
        return LayerLevel.Middle;
    }


    constructor(public circleNumber: number, public circle: ICircle = null) {
        super();

        this.color = circleNumber % 2 ? 'yellow' : 'red';

    }
}
