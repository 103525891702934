<svg:g>
	<rect [attr.x]="rect.x" [attr.y]="rect.y" [attr.height]="rect.h" [attr.width]="rect.w"
		class="plate" fill="#ffffffff" fill-opacity="1" stroke="#000000" stroke-width="1"></rect>
	<image [attr.xlink:href]="projectService.plateLogoSrc" [attr.x]="rect.x + 2" [attr.y]="rect.y + 3" width="50" height="50" />
	<text text-anchor="end">
		<tspan *ngFor="let l of getLabels()" [attr.x]="l.x" [attr.y]="l.y" class="lab">{{ l.text }}</tspan>
	</text>
	<text text-anchor="start">
		<tspan *ngFor="let l of getValues()" [attr.x]="l.x" [attr.y]="l.y" class="val">{{ l.text }}</tspan>
	</text>
</svg:g>
