import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { Marquise } from './marquise.model';
import { v4 as uuidv4 } from 'uuid';
import { IMarquiseInfo } from '../../interfaces/IMarquiseInfo.interface';
import { Color } from '../colors/color.model';
import { BarProfile } from '../profiles/bar.model';
import { MarquisesParams, SvgParams } from '../../constants/constants';
import { ILine } from '../../interfaces/ILine.interface';
import { Glass } from '../glasses/glass.model';
import { ElementSide } from '../../interfaces/IElementSide';
import { Common } from '../common';
import { LayerLevel } from '../../interfaces/LayerLevel';
import { RearParameter } from '../wizard/wizard-parameter.model';

export class MarquiseTop extends Marquise {

	private _markers: ILine[] = [];
	private _corrExt: number;
	private _corrInt: number;
	private _corrBack: number;
	private _insertionLength: number;

	public maxDepth: number;

	public get correctionExt(): number {
		return this._corrExt;
	}
	public get correctionInt(): number {
		return this._corrInt;
	}
	public get correctionBack(): number {
		return this._corrBack;
	}

	public get channels(): number {
		return this.marquiseInfo.channels;
	}

	public get layer(): LayerLevel {
		return this.type == ElementType.MarquiseTopUp ? LayerLevel.Outside : LayerLevel.Inside;
	}

	private _isExpandedLeft: boolean;
	public get isExpandedLeft() {
		return this.type == ElementType.MarquiseTopUp && this.leftBar.type == ElementType.SideFinish ? true : this._isExpandedLeft;
	}
	public set isExpandedLeft(value: boolean) {
		this._isExpandedLeft = value;
	}

	private _isExpandedRight: boolean;
	public get isExpandedRight() {
		return this.type == ElementType.MarquiseTopUp && this.rightBar.type == ElementType.SideFinish ? true : this._isExpandedRight;
	}
	public set isExpandedRight(value: boolean) {
		this._isExpandedRight = value;
	}

	public get insertionLength() {
		return this._insertionLength;
	}

	public isExpandedBack = null;

	protected calculateUIDepth(): number {
		var erpd = this.erpDepth;
		if (this.type == ElementType.MarquiseTopUp) {
			erpd -= this.marquiseInfo.depthCorrection;
		} else {
			erpd -= this.marquiseTopBottomCorrection + (this.isExpandedBack ? this.correctionBack / SvgParams.SCALE : 0);
		}

		erpd = Common.calculateFlat(erpd, this.drop);
		return erpd;
	}

	protected calculateERPDepth(): number {
		var depth = Common.calculateDepth(this.uiDepth, this.drop);

		if (this.type == ElementType.MarquiseTopUp) {
			depth += this.marquiseInfo.depthCorrection;
		} else {
			depth += this.marquiseTopBottomCorrection + (this.isExpandedBack ? this.correctionBack / SvgParams.SCALE : 0);
		}
		return Math.round(depth);
	}

	public getERPWidth(): number {
		var w = this.getWidth();
		if (this.type == ElementType.MarquiseTopBottom) {
			return w;
		}
		const bw = (this.leftBar.type == ElementType.SideFinish ? this.leftBar.lineOnRoof.w : this.leftBar.rectOnRoof.w) / SvgParams.SCALE / 2;
		const ci = MarquisesParams.TOP_CORRECTION_IN;
		const ce = MarquisesParams.TOP_CORRECTION_OUT;

		if (this.leftBar.type == ElementType.SideFinish) {
			w -= (bw + ci);		
		} else {
			if (this.isExpandedLeft) {
				w -= (bw + ci);
			} else {
				w -= ce;
			}
		}
		if (this.rightBar.type == ElementType.SideFinish) {
			w -= (bw + ci);
		} else {
			if (this.isExpandedRight) {
				w -= (bw + ci);
			} else {
				w -= ce;
			}
		}

		return w;
	}

	public get firstGlass(): Glass {
		return this.leftBar.rightGlass;
	}

	public get lastGlass(): Glass {
		return this.rightBar.leftGlass;
	}

	public getMarquiseOnSide(side: ElementSide, elements: any[]) {
		const sg = side == ElementSide.Left ? 'firstGlass' : 'lastGlass';
		const fl = side == ElementSide.Left ? 'isFirst' : 'isLast';
		if (this[sg][fl]) {
			return null;
		}
		var m =
			side == ElementSide.Left ?
		 		elements[this.type].find(m => this.leftBar.id == m.rightBar.id)
				:
				elements[this.type].find(m => this.rightBar.id == m.leftBar.id);

		return m;
	}

	constructor(
		rear: RearParameter,
		depth: number,
		currentMarquiseInfo: IMarquiseInfo,
		standalone: boolean,
		drop: number,
		type: ElementType,
		public leftBar: BarProfile,
		public rightBar: BarProfile
	) {
		super(currentMarquiseInfo.id);
		this.id = type + "_" + uuidv4();

		this.handler = currentMarquiseInfo.handlers[0];
		this.paletteId = currentMarquiseInfo.paletteId;
		this.maxDepth = currentMarquiseInfo.maxDepth;
		(this.fabric as Color | {}) = null;
		this.marquiseInfo = currentMarquiseInfo;
		this.marquiseTopBottomCorrection = rear.marquiseTopBottomCorrection;
		this._insertionLength = rear.marquiseInsertionLength;
		this.drop = drop;

		switch(type) {
			case ElementType.MarquiseTopUp:
				this._corrExt = Common.round(SvgParams.SCALE * MarquisesParams.TOP_CORRECTION_OUT);
				this._corrInt = Common.round(SvgParams.SCALE * MarquisesParams.TOP_CORRECTION_IN);
				this._corrBack = 0;
				break;
			case ElementType.MarquiseTopBottom:
				this._corrExt = Common.round(SvgParams.SCALE * MarquisesParams.BOTTOM_CORRECTION_OUT);
				this._corrInt = Common.round(SvgParams.SCALE * MarquisesParams.BOTTOM_CORRECTION_IN);
				this._corrBack = standalone ? 0 : Common.round(SvgParams.SCALE * this._insertionLength);
				break;
		}

		this.engine.setDefaultEngineParams(currentMarquiseInfo);

		this.setUIDepth(depth);
		this.setCover(depth);
		this.setExtension(0);

		if (this.type == ElementType.MarquiseTopUp) {
			this._isExpandedLeft = this.leftBar.type == ElementType.SideFinish;
			this._isExpandedRight = this.rightBar.type == ElementType.SideFinish;
		}
	}

	public setHandler(double: boolean, roofWindow: boolean) {
		if (this.type == ElementType.MarquiseTopUp) {
			this.handler = this.marquiseInfo.handlers.find(h => h.double == double && h.roofWindow == roofWindow);
		} else {
			this.handler = this.marquiseInfo.handlers.find(h => h.double == double); // bottom marquise ignores roof window
		}
	}

	public override calculateMarkers() {
		if (this.type != ElementType.MarquiseTopUp) {
			return;
		}

		this._markers = [];

		if (!this.lineOnFront || !this.rectOnFront) {
			return;
		}

		if (this.isExpandedLeft) {
			const _x1 = this.lineOnFront.x1 + (this.leftBar.type == ElementType.SideFinish ? this.correctionExt : this.leftBar.rectOnFront.w / 2);
			this._markers.push({ x1: _x1, y1: this.rectOnFront.y, x2: _x1, y2: this.rectOnFront.y + this.rectOnFront.h, w: 0.5 });
		}

		if (this.isExpandedRight) {
			const _x2 = this.lineOnFront.x3 - (this.rightBar.type == ElementType.SideFinish ? this.correctionExt : this.rightBar.rectOnFront.w / 2);
			this._markers.push({ x1: _x2, y1: this.rectOnFront.y, x2: _x2, y2: this.rectOnFront.y + this.rectOnFront.h, w: 0.5 });
		}
	}

	getDForPath (area) {
		const l = 'lineOn' + area;
		return `M ${this[l].x1} ${this[l].y1}
			L ${this[l].x2} ${this[l].y2}
			L ${this[l].x3} ${this[l].y3}
			L ${this[l].x4} ${this[l].y4}
			Z`;
	}

	public get markers(): ILine[] {
		return this._markers;
	}
}

