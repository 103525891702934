<svg:g *ngFor="let m of getWalls()">
	<path *ngFor="let p of m.panels" [attr.d]="p.path"
		[attr.class]="styling.getClasses(m, m.area, selectedItems.indexOf(m) >= 0, 'profile')"
		(click)="selectWall(m)" [attr.fill]="projectService.getBackColor()" [attr.stroke]="projectService.frameColor"
		stroke-width="0.2">
	</path>
	<rect *ngFor="let f of m.frames"
		[attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w" [attr.height]="f.rect.h"
		[attr.fill]="projectService.getBackColor()"
		[attr.stroke]="projectService.frameColor"
		[attr.transform]="f.rotation"
		(click)="selectWall(m)"
		[attr.class]="styling.getClasses(m, m.area, selectedItems.indexOf(m) >= 0, 'profile')"
		>
	</rect>
	<rect *ngFor="let f of m.muntins" [attr.x]="f.rect.x" [attr.y]="f.rect.y" [attr.width]="f.rect.w"
		[attr.height]="f.rect.h"
		[attr.fill]="projectService.getBackColor()" 
		[attr.stroke]="projectService.frameColor"
		[attr.class]="styling.getClasses(m, m.area, selectedItems.indexOf(m) >= 0, 'profile')"
		(click)="selectWall(m)"		
		>
		<!-- (click)="selectMuntin(f)" -->
	</rect>
</svg:g>