import { ElementType } from '../ToolboxModel';
import { WizardParameter, RoofWindowParameter } from '../wizard/wizard-parameter.model';
import { RoofWindow } from './roofWindow.model';

export class RoofVentilator extends RoofWindow {
	
	get type() {
		return  ElementType.RoofVentilator;
	};

	get name() {
		return $localize`:Common|Roof ventilator:Roof ventilator`;
	};
	
	constructor(x: number, y: number, w: number, rwParams: RoofWindowParameter, connector: WizardParameter) {
		super(x, y, w, rwParams, connector);
	}

}
