export const ROOF_WINDOW_DISTANCE = 10;
export const WIZARD_POPUP_TIMEOUT = 500;
export const GLASS_PART_PADDING = 5;
export const LED_STRIP_MAX_LENGTH = 25000;
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
export const GUID_DOOR_SINGLE = '00000000-0000-0000-0000-000000000001';
export const GUID_DOOR_DOUBLE = '00000000-0000-0000-0000-000000000002';
export const MAX_UNDO_COUNT = 10;

export const SNOW_ZONES = ['1', '1a', '2', '2a', '3'];
export const WIND_ZONES = ['1', '2', '3', '4'];
export const COLUMNS_AVALIABLE = [2000, 3000, 4000, 6000];

export const OUTFLOW_SURFACE_TRESHOLD = 25000000;

export const CUSTOMER_PLATE_WIDTH = 300;
export const CUSTOMER_PLATE_HEIGHT = 80;

export const KILONEWTONS = 101.9716212978;

export class ColorImgParams {
	public static readonly WIDHT = 600;
	public static readonly HEIGHT = 400;
	public static readonly FABRIC_WIDHT = 136;
	public static readonly FABRIC_HEIGHT = 47;
	public static readonly TRESHOLD = 127.5;
	public static readonly DARK_FRAME = "#555E5E";
	public static readonly LIGHT_FRAME = "#DEDEDE";
}

export class MarquisesParams {
	public static readonly TOP_HEIGHT = 4;
	public static readonly TOP_CORRECTION_OUT = 30.5;
	public static readonly TOP_CORRECTION_IN = 0.5;

	public static readonly BOTTOM_CORRECTION_OUT = 40;
	public static readonly BOTTOM_CORRECTION_IN = 40;

	public static readonly COLUMN_HANDLER_POINT = 55;
	public static readonly WIDTH_CORRECTION = 4;
}

export class SvgParams {
	public static readonly SCALE = 0.1;
	public static START_X = 1000;
	public static START_Y = 1000;
	public static readonly STARTUP_X = 900;
	public static readonly STARTUP_Y = 900;

	public static readonly LEFT_TOP_POINT_NUM: number = 0;
	public static readonly RIGHT_TOP_POINT_NUM: number = 1;
	public static readonly RIGHT_BOTTOM_POINT_NUM: number = 2;
	public static readonly LEFT_BOTTOM_POINT_NUM: number = 3;
}

export class Tags {
	public static readonly LEFT_COLUMN_TAG = 'lCol';
	public static readonly RIGHT_COLUMN_TAG = 'rCol';

	public static readonly LEFT_REAR_COLUMN_TAG = 'lrCol';
	public static readonly RIGHT_REAR_COLUMN_TAG = 'rrCol';

	public static readonly LEFT_FRONT_TAG = 'lFront';
	public static readonly RIGHT_FRONT_TAG = 'rFront';
	public static readonly ANY_FRONT_TAG = 'aFront';

	public static readonly LEFT_REAR_TAG = 'lRear';
	public static readonly RIGHT_REAR_TAG = 'rRear';
	public static readonly ANY_REAR_TAG = 'aRear';

	public static readonly LEFT_SIDEFINISH_TAG = 'lSideFin';
	public static readonly RIGHT_SIDEFINISH_TAG = 'rSideFin';
}

export class DimensionLineParams {
	public static readonly INNER_DIMENSION = 6;
	public static readonly FIRST_LEVEL_DIMENSION = 10;
	public static readonly SECOND_LEVEL_DIMENSION = 25;
	public static readonly THIRD_LEVEL_DIMENSION = 25;
	public static readonly FOURTH_LEVEL_DIMENSION = 40;
	public static readonly FIFTH_LEVEL_DIMENSION = 70;
}
export class SvgZoomParams {
	public static readonly INIT = 1;
	public static readonly STEP = 0.1;
	public static readonly MIN = 0.5;
	public static readonly MAX = 1.9;
}

export class UrlParams {
	public static readonly OrderCode = 'OrderCode';
	public static readonly ProductId = 'ProductId';
	public static readonly ReadOnly = 'ReadOnly';
	public static readonly SnowZone = 'SnowZone';
	public static readonly WindZone = 'WindZone';
	public static readonly AffinityId = 'affinityId';
	public static readonly BrandName = 'BrandName';	
}

export class DoorParams {
	public static readonly EXTERNAL_PADD = 20.2;
	public static readonly INTERNAL_PADD = 34;
	public static readonly GUMM_DIFF = 8;
	public static readonly SIDE_PADD = 7.4;
	public static readonly TOP_PADD = 57.5;
	public static readonly BOTTOM_PADD = 30;
	public static readonly CLEARANCE = 2;
	public static readonly EXPANSION_TOP = 18.8;
	public static readonly EXPANSION_BOTTOM = 20.8;
}
