export class DimensionText {

	textAnchor: 'middle' | 'end' | 'start' = 'middle';

  parathenses: boolean = false;
  drop: number = -1;

  constructor(
    public x: number,
    public y: number,
    public w: number,
    public transform: boolean,
    public helper: boolean = false
  ) { }
}
