import { Component, Input, ViewChild } from '@angular/core';
import { CardType, IPropertyCard } from '../../_core/interfaces/IPropertyCard';
import { ProjectService } from '../../_core/services/project.service';
import { WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { IMarquiseInfo } from '../../_core/interfaces/IMarquiseInfo.interface';
import { ElementSide, Montage } from '../../_core/interfaces/IElementSide';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { MarquiseVertical } from 'src/app/_core/models/marquises/marquise-vertical.model';
import { RectName } from 'src/app/_core/interfaces/IAreaType';
import { SvgParams } from 'src/app/_core/constants/constants';

@Component({
	selector: 'card-marquise-vertical',
	templateUrl: './card-marquise-vertical.component.html',
	styleUrls: ['../card.component.css']
})
export class CardMarquiseVerticalComponent implements IPropertyCard {
	@ViewChild('numHeight', { static: true }) numHeight: NumTbxComponent;
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numDis', { static: true }) numDis: NumTbxComponent;
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: true }) numLocY: NumTbxComponent;

	public type: CardType = CardType.MarquiseVertical;
	public currMarq: MarquiseVertical;
	public currMarqInfo: IMarquiseInfo;
	public profileTypes: WizardParameter[];

	constructor(public projectService: ProjectService) { }

	@Input() public set items(items: any[]) {
		this.currMarq = items[0];
		this.currMarqInfo = this.currMarq.marquiseInfo;

		if (this.numHeight.valueChanged.observers.length == 0) {
			this.numHeight.valueChanged.subscribe(v => {
				if (this.currMarq.height != v) { // why this event fires twice?
					this.currMarq.height = v;
					this.projectService.emitChange();
				}
			});
		}
		if (this.numWidth.valueChanged.observers.length == 0) {
			this.numWidth.valueChanged.subscribe(v => {
				this.currMarq.widthCorrected = v;
				this.projectService.emitChange();
			});
		}
		if (this.numDis.valueChanged.observers.length == 0) {
			this.numDis.valueChanged.subscribe(v => {
				this.currMarq.distance = v;
				this.projectService.emitChange();
			});
		}
		if (this.numLocX.valueChanged.observers.length == 0) {
			this.numLocX.valueChanged.subscribe(v => {
				this.currMarq.locationX = v;
				this.projectService.emitChange();
			});
		}

		if (this.numLocY.valueChanged.observers.length == 0) {
			this.numLocY.valueChanged.subscribe(v => {
				this.currMarq.locationY = v;
				this.projectService.emitChange();
			});
		}
		this.currMarq.refreshSurround(this.projectService.profileService);
	}

	get engineId(): string {
		return this.currMarq.engine.id;;
	}
	set engineId(id: string) {
		const newEngine = this.currMarq.marquiseInfo.engines.find(e => e.id === id);
		this.currMarq.engine.setNewEngineParameters(newEngine);
		this.projectService.emitChange();
	}

	relocateEngine(location: string) {
		if (this.currMarq.engine.location != location) {
			this.currMarq.relocateEngine(ElementSide[location]);
			this.projectService.emitChange();
		}
	}

	deleteElement() {
		this.projectService.deleteElement(this.currMarq);
	}

	isMontagePossible(m: string) {
		return this.currMarq.isMontageAllowed(Montage[m]);
	}

	changeMontage(m: string) {
		if (this.currMarq.montage == Montage[m]) {
			return;
		}
		this.currMarq.montage = Montage[m];
		this.currMarq.setHandler(this.projectService.template.frontId);
		this.projectService.adjustBottomMarquise(this.currMarq);
		this.projectService.profileService.reverseMarquise();
		this.projectService.emitChange();
	}

	//#region Change position
	positionClick(side: string) {
		if (this.currMarq.move(ElementSide[side])) {
			this.projectService.emitChange();
		}
	}

	changePositionTooltip(side: string): string {
		if (this.checkAnother(ElementSide[side])) {
			return ElementSide[side] == ElementSide.Left ? $localize`:Marquise|Tooltip:There is another marquise on left side` : $localize`:Marquise|Tooltip:There is another marquise on right side`;
		}

		if (this.changePositionDisabled(side)) {
			return ElementSide[side] == ElementSide.Left ? $localize`:Marquise|Tooltip:Left edge has been achived` : $localize`:Marquise|Tooltip:Right edge has been achived`;
		} else {
			return ElementSide[side] == ElementSide.Left ? $localize`:Marquise|Tooltip:Move left` : $localize`:Marquise|Tooltip:Move right`;
		}
	}

	changePositionDisabled(side: string) {
		if (this.checkAnother(ElementSide[side])) {
			return true;
		}
		const eside = ElementSide[side];
		var p = this.projectService.profileService.getNextColumn(this.currMarq.area, eside, eside == ElementSide.Left ? this.currMarq.leftProfile : this.currMarq.rightProfile);
		return p == null;
	}	

	//#endregion

	//#region Change width
	changeWidthBtnClick(side: string) {
		if (this.currMarq.expand(ElementSide[side])) {
			this.projectService.emitChange();
		}
	}

	isChangeWidthBtnDisable(side: string) {
		if (this.currMarq.montage == Montage.Middle) {
			return true;
		}

		const eside = ElementSide[side];

		if (eside == ElementSide.Left) {
			return this.isMinimum();
		}

		if (this.checkAnother(ElementSide.Right)) {
			return true;
		}

		var nextCol = this.getNextCol();
		if (!nextCol) {
			return false;
		}

		const rn = RectName.get(this.currMarq.area);
		var currPos = this.currMarq.rightProfile[rn].x + this.currMarq.rightProfile[rn].w / 2;
		var nextPos = nextCol[rn].x + nextCol[rn].w / 2;
		var diff = (nextPos - currPos) / SvgParams.SCALE;

		if (this.currMarq.width + diff > this.currMarqInfo.maxWidth) {
			return true;
		}

		return false;
	}

	isMinimum() {
		var lr = this.projectService.profileService.getNextColumn(this.currMarq.area, ElementSide.Left, this.currMarq.rightProfile);
		return lr && lr.id == this.currMarq.leftProfile.id;
	}

	private getNextCol() {
		return this.projectService.profileService.getNextColumn(this.currMarq.area, ElementSide.Right, this.currMarq.rightProfile);
	}

	checkAnother(side: ElementSide) {
		const others = this.projectService.profileService
			.getMarquisesVertical(this.currMarq.area)
			.filter((m: MarquiseVertical) => m.id != this.currMarq.id && (m.montage == this.currMarq.montage || m.montage == Montage.Middle));

		if (side == ElementSide.Right) {
			return others.find(m => m.leftProfile.id == this.currMarq.rightProfile.id) != null;
		} else {
			return others.find(m => m.rightProfile.id == this.currMarq.leftProfile.id) != null;
		}
	}

	changeWidthTooltip(side: string): string {
		if (this.currMarq.montage == Montage.Middle) {
			return $localize`:Marquise|Tooltip:Cannot change width through a column!`;
		}

		const eside = ElementSide[side];
		if (!this.isChangeWidthBtnDisable(side)) {
			return eside == ElementSide.Left ? $localize`:Marquise|Tooltip:Decrease width` : $localize`:Marquise|Tooltip:Increase width`;
		}

		if (eside == ElementSide.Right && this.checkAnother(eside)) {
			return $localize`:Marquise|Tooltip:There is another marquise on right side`;
		}


		if (eside == ElementSide.Left) {
			return $localize`:Marquise|Tooltip:The new width of the marquise is less than it's minimum allowable width!`;
		} else {
			return $localize`:Marquise|Tooltip:The new width of the marquise is greater then it's maximum allowable width!`;
		}

	}
	//#endregion

	get currLng(): string {
		return window.document.documentElement.lang;
	}

}
