import { SvgParams } from "../../constants/constants";
import { ElementSide } from "../../interfaces/IElementSide";
import { IRect } from "../../interfaces/IRect";
import { ProjectService } from "../../services/project.service";
import { RoofGutter } from "../glasses/roofGutter.model";
import { RoofWindow } from "../glasses/roofWindow.model";
import { RoofWindowConnector } from "../glasses/roofWindowConnector.model";
import { ElementType } from "../ToolboxModel";
import { ISize, RoofWindowParameter } from "../wizard/wizard-parameter.model";
import { ElementStoreModel } from "./element-store.model";

export class RoofWindowConnectorStoreModel {
	id: string;
	visible: boolean;

	constructor(c: RoofWindowConnector) {
		this.id = c.id;
		this.visible = c.visible;
	}
}

export class RoofWindowStoreModel extends ElementStoreModel {

	bottomGlassId: string;
	topGlassId: string;
	connectorBottomVisible: boolean;
	connectorTopVisible: boolean;
	rect: IRect;
	size: ISize;

	constructor(c: RoofWindow) {
		super(c.id, c.configId);

		this.rect = c.rect;

		this.bottomGlassId = c.bottomGlass?.id;
		this.topGlassId = c.topGlass?.id;
		this.connectorBottomVisible = c.connectorBottom.visible;
		this.connectorTopVisible = c.connectorTop.visible;
		this.size = { width: Math.round(c.rect.w / SvgParams.SCALE), depth: Math.round(c.rect.h / SvgParams.SCALE), height: undefined };
	}

	public static restore(c: RoofWindowStoreModel, type: ElementType, projectService: ProjectService) {
		let info: RoofWindowParameter;
		let nrw: RoofWindow;
		if(type == ElementType.RoofWindow) {
			info = projectService.template.roofWindows.find(e => e.id === c.configId);
			nrw = new RoofWindow(c.rect.x, c.rect.y, c.rect.w, info, projectService.template.connectorDef);
		} else {
			info = projectService.template.roofAddons.find(e => e.id === c.configId);
			nrw = new RoofGutter(c.rect.x, c.rect.y, c.rect.w, info, projectService.template.connectorDef);
		}
		nrw.rect = c.rect;

		nrw.connectorBottom = new RoofWindowConnector(nrw, ElementSide.Bottom);
		nrw.connectorBottom.visible = c.connectorBottomVisible;

		nrw.connectorTop = new RoofWindowConnector(nrw, ElementSide.Top);
		nrw.connectorTop.visible = c.connectorTopVisible;

		projectService.profileService.roofElements[type].push(nrw);

		return nrw;
	}

}
