import { Profile } from "./profile.model";
import { Orientation, ElementType } from "src/app/_core/models/ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { SvgParams } from "src/app/_core/constants/constants";
import { IRect } from "../../interfaces/IRect";
import { GlassPart } from "../glasses/glassPart.model";
import { GlassPartDiamond } from '../glasses/glassDiamondPart';
import { LayerLevel } from "../../interfaces/LayerLevel";

export class MuntinProfile extends Profile {

	public id: string;
	public rect: IRect;
	public fill = 'black';

	public leftPart: GlassPart | GlassPartDiamond;
	public rightPart: GlassPart | GlassPartDiamond;

	get type() {
		return ElementType.Muntin;
	}

	get name(): string {
		return $localize`:Common|Muntin:Muntin`;
	}

	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}

	public override get cuttingable(): boolean {
		return false;
	}

	constructor(
		x: number,
		y: number,
		length: number,
		public orientation: Orientation,
		public masterId: string
	) {

		super(Orientation.Vertical);
		this.id = "Muntin_" + uuidv4();

		this.length = length;

		const w = this.orientation === Orientation.Vertical ? 100 : length;
		const h = this.orientation === Orientation.Vertical ? length : 100;

		this.rect = {
			x: x,
			y: y,
			w: Math.round(w * SvgParams.SCALE),
			h: Math.round(h * SvgParams.SCALE)
		};
	}

	public get length(): number {
		return this.rect ? Math.round(this.orientation === Orientation.Vertical ? this.rect.h : this.rect.w) : 0;
	}

	public set length(value: number) {
		if (!this.rect) {
			return;
		}

		this.orientation === Orientation.Vertical ? this.rect.h = Math.round(value) : this.rect.w = Math.round(value);
	}

}
