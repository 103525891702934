import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { ToolboxItemService } from 'src/app/_core/services/toolbox-item.service';
import { ProjectService } from 'src/app/_core/services/project.service';
import { Wall } from 'src/app/_core/models/walls/wall.model';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { FrameProfile } from 'src/app/_core/models/profiles/frame.model';
import { StylingService } from 'src/app/_core/services/styling.service';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';

@Component({
	selector: 'g[app-wall]',
	templateUrl: './wall.component.html',
	styleUrls: ['./wall.component.css']
})
export class WallComponent {
	@Input() selectedItems: any[];
	@Input() area: string;
	@Input() printMode: boolean;
	@Output() selectWallEvent = new EventEmitter<Wall>();
	@Output() selectWallMuntinEvent = new EventEmitter<FrameProfile>();

	constructor(private toolBSrvc: ToolboxItemService,
		private projectService: ProjectService,
		public styling: StylingService) {
	}

	selectWall(wall: Wall) {
		this.selectWallEvent.emit(wall);
		this.toolBSrvc.setCurrTool(null);
	}

	selectMuntin(muntin: FrameProfile) {
		// Feature commented as not required (still bugs to fix!)

		// this.selectWallMuntinEvent.emit(muntin);
		// this.toolBSrvc.setCurrTool(null);
	}

	getWalls() {
		if (!this.styling.showLayer(LayerLevel.Middle)) {
			return null;
		}
		var walls = this.projectService.profileService.getWalls(AreaType[this.area]);
		return walls;
	}

	iconClass(itemType: string, areaType: string): string {
		return this.toolBSrvc.iconClass(itemType, areaType);
	}

}
