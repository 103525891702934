import { Injectable } from "@angular/core";
import { SvgParams } from "../../constants/constants";
import { GlassWall } from "../../models/glasses/glassWall.model";
import { ProjectService } from "../project.service";
import { GlassWallsBaseService } from "./glass-walls-base.service";
import { Common } from "../../models/common";

@Injectable({
	providedIn: 'root'
})
export class GlassWallService extends GlassWallsBaseService {

	public setLocationX(glass: GlassWall, value: number) {
		glass.rect.x = SvgParams.START_X + value * SvgParams.SCALE;

		this.projectService.manageFrameAfterGlassChange(glass);
		this.projectService.createMuntins(glass);
		glass.adjustSurround();
	}

	public setLocationY(glass: GlassWall, value: number) {
		glass.rect.y = SvgParams.START_Y + value * SvgParams.SCALE;
		this.projectService.manageFrameAfterGlassChange(glass);
		this.projectService.createMuntins(glass);
		glass.adjustSurround();
	}

	public setWidth(glassWall: GlassWall, value: number) {
		glassWall.width = value;
		this.projectService.manageFrameAfterGlassChange(glassWall);
		this.projectService.createMuntins(glassWall);
		glassWall.adjustSurround();
	}

	constructor(public projectService: ProjectService) {
		super(projectService);
	}

	public setHeight(glassWall: GlassWall, value: number) {
		glassWall.height = value;
		this.projectService.manageFrameAfterGlassChange(glassWall);
		this.projectService.createMuntins(glassWall);
		glassWall.adjustSurround();
	}

	public alongHeight(glass: GlassWall, diff: number) {
		if (glass.height + diff > glass.defMaxHeight) {
			glass.height = glass.defMaxHeight;
		}
		glass.height += Common.round(diff);
		this.projectService.manageFrameAfterGlassChange(glass);
		this.projectService.createMuntins(glass);
		glass.adjustHeight(diff);
	}

}
