import { Injectable } from '@angular/core';
import { StraightService } from './straight.service';
import { Common } from '../../models/common';
import { SvgParams } from '../../constants/constants';
import { AreaType, RectName } from '../../interfaces/IAreaType';
import { ElementType, Orientation } from '../../models/ToolboxModel';
import { WallProfile } from '../../models/profiles/wall.model';
import { ColumnProfile } from '../../models/profiles/column.model';
import { FrameProfile } from '../../models/profiles/frame.model';

@Injectable({
  providedIn: 'root'
})
export class RearService extends StraightService {

  	public get areaType(): AreaType {
		return AreaType.Rear;
	}

  	public get rectOnSide(): string {
    	return RectName.get(AreaType.Rear);
	}

	showArea() {
		if (this.profileService.rearElements === null) {
			this.profileService.rearElementsInit();
			this.calculatePoints();
		}
		if (this.profileService.roofElements !== null) {
			this.addElementsAfterDisplayedRoof(this.profileService.roofElements);
		}
	}

  	changeArea() {
		this.profileService.rearElements = null;
		this.showArea();
	}


	public calculatePoints() {

		this._workAreaPoints = [];
		this._points = [];
		this._svg = "";

		if (this.width <= 0 || this.depth <= 0) {
			return;
		}

		const bh = this.projectService.template.backHeight * SvgParams.SCALE;
		const rh = this.projectService.template.getRearSize().height * SvgParams.SCALE;
		this._points.push({ x: SvgParams.START_X, y: SvgParams.START_Y});
		this._points.push({ x: Common.round(SvgParams.START_X + this.width * SvgParams.SCALE), y: SvgParams.START_Y });
		this._points.push({ x: Common.round(SvgParams.START_X + this.width * SvgParams.SCALE), y: SvgParams.START_Y + bh + rh });
		this._points.push({ x: SvgParams.START_X, y: SvgParams.START_Y + bh + rh });

		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.LEFT_TOP_POINT_NUM].x), y: Common.round(this._points[SvgParams.LEFT_TOP_POINT_NUM].y ) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.RIGHT_TOP_POINT_NUM].x), y: Common.round(this._points[SvgParams.RIGHT_TOP_POINT_NUM].y ) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x), y: Common.round(this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y) });
		this._workAreaPoints.push({ x: Common.round(this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].x), y: Common.round(this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y) });

	}


	public addElementsAfterDisplayedRoof(roofElements): void {
		const template = this.projectService.template;
		const rear = template.getDefaultRear();

		this.profileService.rearElements[ElementType.WallProfile] = [];
		roofElements[ElementType.WallProfile].forEach((wand: WallProfile) => {
			wand.height = rear.size.height;
			wand.width = template.getRearSize().width;
			var x = SvgParams.START_X + ((this.projectService.template.width * SvgParams.SCALE) - (wand.rectOnRoof.x - SvgParams.START_X) - wand.rectOnRoof.w);
			this.saveProfileWithSetRect(wand, x, this._points[SvgParams.LEFT_TOP_POINT_NUM].y);
		});
		
		if (this._points.length == 0) {
			return;
		}
		var coly = this._points[SvgParams.LEFT_TOP_POINT_NUM].y + rear.size.height * SvgParams.SCALE;
		roofElements[ElementType.Column].filter((col: ColumnProfile) => col.isRear == true).forEach((col: ColumnProfile) => {
			var fromRight = col.rectOnRoof.x - SvgParams.START_X;
			var x = SvgParams.START_X + this.projectService.template.width * SvgParams.SCALE - fromRight - col.rectOnRoof.w;

			this.saveColWithSetRect(col, x, coly);
		});

	}

	getTopProfileBottomY() {
		const wands = this.profileService.rearElements[ElementType.WallProfile];
		if (wands?.length > 0) {
			const wand = wands[0];
			return Common.round(wand.rectOnRear.y + wand.rectOnRear.h);
		}
		return this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y;
	}

	public createTopProfile(): FrameProfile {
		const tempConnector = this.projectService.template.doors[0].connectorId;
		const rear = this.profileService.rearElements[ElementType.WallProfile][0] as WallProfile;
		return new FrameProfile(0, rear.rectOnRear.y + rear.rectOnRear.h, 0, { width: 0, height: 0, depth: 0 }, "", Orientation.Horizontal, tempConnector);
	}

}
