<div class="row">
	<div class="col col-4"><span i18n="Common|Length">Length</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="length" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox [value]="locationX" [disabled]="true"></app-numeric-textbox>&nbsp;mm&nbsp;
		<div kendoTooltip>
			<span [attr.title]="changeLocationXTooltip('Left')">
				<button kendoButton (click)="onLocationXBtnClick('Left')" [disabled]="projectService.readOnly || isChangeLocationXBtnDisable('Left')" [svgIcon]="projectService.svgIcons.rewindIcon"></button>
			</span>
				<span [attr.title]="changeLocationXTooltip('Right')">
				<button kendoButton (click)="onLocationXBtnClick('Right')" [disabled]="projectService.readOnly || isChangeLocationXBtnDisable('Right')" [svgIcon]="projectService.svgIcons.forwardIcon"></button>
			</span>
		</div>
	</div>
</div>

<div class="row">
		<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
		<div class="col col-8">
				<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteLed()" [disabled]="projectService.readOnly" i18n="Common|Delete">Delete</button>
		</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Segments:</div>
		<div class="col col-8">{{ segments }}</div>
	</div>
	<div class="row">
		<div class="col col-4">PCE:</div>
		<div class="col col-8">{{ pce }}</div>
	</div>
	<div class="row">
		<div class="col col-4">Full length:</div>
		<div class="col col-8">{{ fullLength }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Waste:</div>
		<div class="col col-8">{{ waste }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Unused:</div>
		<div class="col col-8">{{ unused }} mm</div>
	</div>
</div>