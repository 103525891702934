<kendo-dialog *ngIf="opened" (close)="close()" [width]="'100%'" [height]="'100%'" style="overflow: hidden;">
	<div class="container" [ngStyle]="{'height':container_Height+'px'}" *ngIf="!noProductMessage">
		<div *ngIf="!showLoader">
			<div class="row" [ngStyle]="{'height':top_Height+'px'}" *ngIf="product">
				<ng-template #template let-anchor>
					<img src="{{product.photo}}" width="200" alt="proudct">
				</ng-template>
				<div kendoTooltip [tooltipTemplate]="template" filter="h3">
					<h3 style="padding: 5px;">{{product.name}}</h3>
				</div>
				<kendo-stepper [steps]="steps" stepType="full" [(currentStep)]="current" [linear]="false" style="padding-bottom: 50px;">
				</kendo-stepper>
			</div>
			<div class="row" [ngStyle]="{'height':middel_Height+'px'}">

				<div class="card" *ngIf="current == -1">
					<div class="card-body text-left" style="text-align: left;" [ngStyle]="{'height':middel_Height+'px'}">
						
						<app-wait [message]="'Authorizing. Please wait...'"></app-wait>

					</div>
				</div>
				<div class="card" *ngIf="current + shift == 0">
					<div class="card-body text-left" style="text-align: left;" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose product' label">Choose product</h4>
						<br>
						<div class="row">
							<div class="col col-12" style="text-align: center">
								<kendo-dropdownlist style="width: 50%" [data]="products" textField="name"
									valueField="id" [(ngModel)]="currentProductId" [valuePrimitive]="true">
								</kendo-dropdownlist>
							</div>
						</div>
					</div>
				</div>
				<div class="card" *ngIf="current + shift == 1">
					<div class="card-body text-left" style="text-align: left;" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Enter order code' label">Enter order code</h4>
						<br>
						<div class="row">
							<div class="col col-12" style="text-align: center">
								<kendo-textbox [(value)]="orderCode" style="width:300px" [maxlength]="16" [disabled]="orderCodeDisabled"></kendo-textbox>
							</div>
						</div>
					</div>
				</div>
				<div class="card" *ngIf="current + shift == 2">
					<div class="card-body text-left" style="text-align: left;" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose main parameters' label">Choose main parameters</h4>
						<br>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Snow zone">Snow zone</span>:</div>
							<div class="col col-2">
								<kendo-dropdownlist [data]="snowZoneValues" [(value)]="snowZone" [disabled]="snowZoneReadOnly" style="width:80px">
								</kendo-dropdownlist>
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Wind zone">Wind zone</span>:</div>
							<div class="col col-2">
								<kendo-dropdownlist [data]="windZoneValues" [(value)]="windZone" [disabled]="windZoneReadOnly" style="width:80px">
								</kendo-dropdownlist>
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Width">Width</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox #numWidth [value]="product.width" [min]="product.minWidth" [max]="product.maxWidth"></app-numeric-textbox> mm
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Height_back">Height (back)</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox #numBack [value]="backHeight" [min]="product.minBackHeight" [max]="product.maxBackHeight"></app-numeric-textbox> mm
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Depth">Depth</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox #numDepth [value]="depth" [min]="product.minDepth" [max]="product.maxDepth"></app-numeric-textbox> mm
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Drop angle">Drop angle</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox #numDrop [value]="drop" [min]="product.minDropAngle" [max]="product.maxDropAngle"
									[decimals]="1" [format]="'n1'" [step]="1"></app-numeric-textbox>&nbsp;&deg;
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Height_front">Height (front)</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox [value]="product.frontHeight" [disabled]="true"></app-numeric-textbox> mm
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Common|Height_diff">Back - front</span>:</div>
							<div class="col col-2">
								<app-numeric-textbox [value]="product.frontDiff" [disabled]="true"></app-numeric-textbox> mm
							</div>
						</div>
						<div class="row">
							<div class="col col-4"></div>
							<div class="col col-2 lab"><span i18n="Wizard|Default foundation">Default foundation</span>:</div>
							<div class="col col-3">
								<kendo-dropdownlist style="width: 100%" [data]="foundations" textField="name" valueField="id" [valuePrimitive]="true" [(ngModel)]="foundationId">
								</kendo-dropdownlist>
							</div>
						</div>


					</div>
				</div>
				<div class="card" *ngIf="current + shift == 3">
					<div class="card-body" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose form top' label">Choose form top</h4>
						<div class="row">
							<div class="col col-md-4 col-sm-6" *ngFor="let parameter of formTops">
								<app-wizard-parameter [data]="parameter" [selected]="parameter.isDefault"
									(onSelect)="onSelectFormTop($event)"></app-wizard-parameter>
							</div>
						</div>
					</div>
				</div>
				<div class="card" *ngIf="current + shift == 4">
					<div class="card-body" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose form side' label">Choose form side</h4>
						<div class="row">
							<div class="col col-md-4 col-sm-6" *ngFor="let parameter of formSides">
								<app-wizard-parameter [data]="parameter" [selected]="parameter.isDefault"
									(onSelect)="onSelectFormSides($event)"></app-wizard-parameter>
							</div>
						</div>
					</div>
				</div>
				<div class="card" *ngIf="current + shift == 5">
					<div class="card-body" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose fastening' label">Choose fastening</h4>
						<div class="row">
							<div class="col col-md-4 col-sm-6" *ngFor="let parameter of formFastenings">
								<app-wizard-parameter [data]="parameter" [selected]="parameter.isDefault"
									(onSelect)="onSelectFastenings($event)"></app-wizard-parameter>
							</div>
						</div>
					</div>
				</div>
				<div class="card" *ngIf="current + shift == 6">
					<div class="card-body" [ngStyle]="{'height':middel_Height+'px'}">
						<h4 class="card-title" i18n="Wizard|'Choose roofing' label">Choose roofing</h4>
						<div class="row">
							<div class="col col-md-4 col-sm-6" *ngFor="let parameter of formRoofings">
								<app-wizard-parameter [data]="parameter" [selected]="parameter.isDefault"
									(onSelect)="onSelectRoofings($event)"></app-wizard-parameter>
							</div>
						</div>
					</div>
				</div>

				<app-wait [show]="showLoading" [message]="loadingMessage"></app-wait>

			</div>
			<div class="row wizzard-nav" [ngStyle]="{'height':bottom_Height+'px'}">
				<div>
					<button kendoButton primary="true" (click)="goBack()" [svgIcon]="projectService.svgIcons.chevronLeftIcon" look="flat" *ngIf="dontShowOrderCode ? current > 0 : current > 0" i18n="Wizard|'Back' button">
						Back
					</button>
					&nbsp;
					<button kendoButton primary="true" (click)="goNext()" look="flat" [svgIcon]="projectService.svgIcons.chevronRightIcon" *ngIf="current + shift < 6 && current  >= 0" [disabled]="!productId || current >=0 && !isStepValid(current + shift)" i18n="Wizard|'Next' button">
						Next
					</button>
					<button kendoButton primary="true" (click)="start()" look="flat" [svgIcon]="projectService.svgIcons.checkIcon" [disabled]="!isStepValid(current + shift)" *ngIf="current + shift == 6" i18n="Wizard|'Create new project' button">
						Create new project
					</button>
				</div>
			</div>
		</div>

		<div *ngIf="showLoader">
			<div [ngClass]="{'load-complete':loaderComplate,'circle-loader':showLoader}">
				<svg [ngClass]="{'animated-check':loaderComplate}" viewBox="0 0 33 33">
					<path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
				</svg>
			</div>
			<p i18n="Wizard|'Project creation' label">Project creation</p>
		</div>
	</div>

	<div class="rowcloud" [ngStyle]="{'height':container_Height+'px'}" *ngIf="noProductMessage">
		<div class="text-center">
			<img src="/assets/images/home.png">
			<h3 class="text-center">Application error</h3>
			<h6>{{ exceptionId }}</h6>
		</div>
		<div id="background-wrap">
			<div class="x1">
				<div class="cloud"></div>
			</div>

			<div class="x2">
				<div class="cloud"></div>
			</div>

			<div class="x3">
				<div class="cloud"></div>
			</div>

			<div class="x4">
				<div class="cloud"></div>
			</div>

			<div class="x5">
				<div class="cloud"></div>
			</div>
		</div>
	</div>
</kendo-dialog>
