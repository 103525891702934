import { ElementType } from "../ToolboxModel";
import { v4 as uuidv4 } from 'uuid';
import { AreaType, RectName } from "../../interfaces/IAreaType";
import { IRect } from "../../interfaces/IRect";
import { AnySide, ElementSide } from "../../interfaces/IElementSide";
import { IDoorInfo, LockType } from "../../interfaces/IDoorInfo";
import { ILine } from "../../interfaces/ILine.interface";
import { IPoint } from "../../interfaces/IPoint";
import { DoorParams, SvgParams } from "../../constants/constants";
import { Common } from "../common";
import { ILockVariant } from "../../interfaces/ILockVariant";
import { VerticalElement } from "../vertical-element";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { ProjectService } from "../../services/project.service";
import { IAreaService } from "../../interfaces/IAreaService";
import { Space } from "../space";

class Arrow {
	constructor(
		public line: ILine,
		public lockSide: AnySide) {
	}
}
export class Door extends VerticalElement {

	locks: IRect[];
	arrows: ILine[];
	dArrows: Arrow[];
	crosses: ILine[];
	foundation: boolean;	

	get rectOnFront() {
		return this.rect;
	}
	get rectOnLeft() {
		return this.rect;
	}
	get rectOnRight() {
		return this.rect;
	}
	get leftX(): number {
		return this.rect.x;
	}
	get rightX(): number {
		return this.rect.x + this.rect.w;
	}
	get topY(): number {
		return this.rect.y;
	}
	get bottomY(): number {
		return this.rect.y + this.rect.h;
	}
	get defMaxWidth(): number {
		return this.def.maxWidth;
	}
	get defMaxHeight(): number {
		return this.def.maxHeight;
	}
	get defMinWidth(): number {
		return this.def.minWidth;
	}
	get defMinHeight(): number {
		return this.def.minHeight;
	}

	private _isExpandedTop: boolean;
	get isExpandedTop(): boolean {
		return this._isExpandedTop;
	}
	set isExpandedTop(v: boolean) {
		this._isExpandedTop = v;
		this.setExpansionTop();
	}

	private _isExpandedBottom: boolean;
	get isExpandedBottom(): boolean {
		return this._isExpandedBottom;
	}
	set isExpandedBottom(v: boolean) {
		this._isExpandedBottom = v;
		this.setExpansionBottom();
	}

	get maxLocationY(): number {
		var mly = super.maxLocationY;
		if (this._isExpandedBottom) {
			mly += DoorParams.EXPANSION_BOTTOM;
		}
		return mly;
	}
	
	public get height() {
		return Common.round(this.rect.h / SvgParams.SCALE);
	}

	get maxHeight(): number {
		var mh = super.maxHeight;
		if (this._isExpandedBottom) {
			mh += DoorParams.EXPANSION_BOTTOM;
		}
		return mh;
	}


	private extras: string[];

	private _glassColor: string;
	get glassColor(): string {
		return this._glassColor;
	}

	private _glassId: string;
	get glassId(): string {
		return this._glassId;
	}
	set glassId(v: string) {
		if (v == this._glassId) {
			return;
		}
		this._glassId = v;
		const def = this.def.glasses.find(g => g.id == v);
		this._glassColor = def.color;
		if (def.color.length == 7 && this.def.color.length == 9) { // #9fc5e8 vs #9fc5e8DD
			this._glassColor += this.def.color.substring(7);
		}
	}

	private _lockVariant: ILockVariant;
	get lockVariant(): ILockVariant {
		return this._lockVariant;
	}
	set lockVariant(v: ILockVariant) {
		if (v == this._lockVariant) {
			return;
		}
		this._lockVariant = v;
		if (this._lockVariant != null) {
			this.setLocks();
		}
	}

	get wingWidth(): number {
		return Common.round(this.wings[0].w / SvgParams.SCALE);
	}

	get wingHeight(): number {
		return Common.round(this.wings[0].h / SvgParams.SCALE);
	}

	get type() {
		return ElementType.Door;
	}
	
	get layer(): LayerLevel {
		return LayerLevel.Middle;
	}


	get name(): string {
		return $localize`:Common|Door:Door`;
	}

	get configId(): string {
		return this.def.id;
	}

	constructor(
		protected projectService: ProjectService,
		public def: IDoorInfo,
		public area: AreaType,
		cols: Space,
		rect: IRect,
		public wings: IRect[],
		sideService: IAreaService) {

		super(projectService, sideService);

		this.leftProfile = cols.leftProfile;
		this.rightProfile = cols.rightProfile;
		this.topProfile = cols.topProfile;
		this.bottomProfile = cols.bottomProfile;

		this.id = this.type + "_" + uuidv4();
		this.extras = [];
		this.locks = [];
		this.arrows = [];
		this.dArrows = [];
		this.crosses = [];

		this.rect = rect;
	}

	private setExpansionTop() {
		const et = this._isExpandedTop ? (DoorParams.EXPANSION_TOP * SvgParams.SCALE) : (-DoorParams.EXPANSION_TOP * SvgParams.SCALE);
		this.rect.y -= et;
		this.rect.h += et;

		this.wings.forEach(w => {
			w.y -= et;
			w.h += et;
		});
	}

	private setExpansionBottom() {
		const eb = this._isExpandedBottom ? (DoorParams.EXPANSION_BOTTOM * SvgParams.SCALE) : (-DoorParams.EXPANSION_BOTTOM * SvgParams.SCALE);
		this.rect.h += eb;
		this.wings.forEach(w => {
			w.h += eb;
		});
	}

	private getLockRect(type: LockType, wing: IRect): IRect {
		var size = this.def.locks.find(l => l.lockType == type)?.size;
		if (size == null) {
			return null;
		}
		var lockY = wing.y + (wing.h / 2) - size.height * SvgParams.SCALE;
		var l = { x: 0, y: lockY, w: size.width * SvgParams.SCALE, h: size.height * SvgParams.SCALE };

		return l;
	}

	private createLeftExternalLock(type: LockType) {
		var l = this.getLockRect(type, this.firstWing);
		if (l == null) {
			return;
		}
		l.x = this.firstWing.x + this.distances.get(type);
		this.locks.push(l);
		this.setArrow(l, AnySide.Left);
		return true;
	}

	private createLeftInternalLock(type: LockType) {
		const i = (this.wings.length / 2);
		const dl = this.wings[i - 1];

		var l = this.getLockRect(type, dl);
		if (l == null) {
			return;
		}
		l.x = dl.x + dl.w - this.distances.get(type);
		this.locks.push(l);
		this.setArrow(l, AnySide.Right);
	}

	private createMiddleLock() {
		const i = (this.wings.length / 2);
		const dl = this.wings[i];

		var l = this.getLockRect(LockType.DoubleSide, dl);
		if (l == null) {
			return;
		}
		l.x = dl.x - l.w / 2;
		this.locks.push(l);
		this.setArrow(l, AnySide.Left);
		this.setArrow(l, AnySide.Right);
	}

	private createRightInternalLock(type: LockType) {
		const i = (this.wings.length / 2);
		const dl = this.wings[i];

		var l = this.getLockRect(type, dl);
		if (l == null) {
			return;
		}
		l.x = dl.x + this.distances.get(type);
		this.locks.push(l);
		this.setArrow(l, AnySide.Left);
	}

	private createRightExternalLock(type: LockType) {
		var l = this.getLockRect(type, this.lastWing);
		if (l == null) {
			return;
		}
		l.x = this.lastWing.x + this.lastWing.w - l.w - this.distances.get(type);
		this.locks.push(l);
		this.setArrow(l, AnySide.Right);
	}

	private firstWing: IRect;
	private lastWing: IRect;

	private distances = new Map<LockType, number>([
		[LockType.Handle, 15],
		[LockType.SingleSide, 0]
	]);

	public setLocks() {
		this.locks = [];
		this.arrows = [];
		this.dArrows = [];
		this.crosses = [];

		this.firstWing = this.wings[0];
		this.lastWing = this.wings[this.wings.length - 1];

		switch(this._lockVariant.leftExternal) {
			case LockType.Handle:
			case LockType.SingleSide:
				this.createLeftExternalLock(this._lockVariant.leftExternal);
				if (this._lockVariant.rightExternal == null) {
					this.setCross(this.lastWing);
				}
				break;
		}

		switch (this._lockVariant.leftInternal) {
			case LockType.Handle:
				this.createLeftInternalLock(this._lockVariant.leftInternal);
				if (this._lockVariant.leftExternal == null) {
					this.setCross(this.firstWing);
				}
				break;
		}

		switch (this._lockVariant.middle) {
			case LockType.DoubleSide:
				this.createMiddleLock();
				if (this._lockVariant.rightExternal == null) {
					this.setCross(this.lastWing);
				}
				if (this._lockVariant.leftExternal == null) {
					this.setCross(this.firstWing);
				}
				break;
		}

		switch (this._lockVariant.rightInternal) {
			case LockType.Handle:
				this.createRightInternalLock(this._lockVariant.rightInternal);
				if (this._lockVariant.rightExternal  == null) {
					this.setCross(this.lastWing);
				}
				break;
		}

		switch (this._lockVariant.rightExternal) {
			case LockType.Handle:
			case LockType.SingleSide:
				this.createRightExternalLock(this._lockVariant.rightExternal);
				if (this._lockVariant.leftExternal == null) {
					this.setCross(this.firstWing);
				}
				break;
		}

		this.setDarrows();
	}

	private setArrow(l: IRect, dir: AnySide) {
		const x1 = dir == AnySide.Left ? l.x + l.w + 30 : l.x - 30;
		const x2 = dir == AnySide.Left ? l.x + l.w + 10 : l.x - 10;
		const y = l.y + (l.h / 2);
		this.arrows.push({ x1, x2, y1: y, y2: y });
	}

	private setDarrows() {
		const l = this._lockVariant;
		const first = 0;
		const last = this.wings.length - 1;

		const half = (this.wings.length / 2);
		const dl = this.wings[i];

		for (var i = first + 1; i <= last - 1; i++) {
			if (l.leftExternal && i == first) {
				continue;
			}
			if (l.rightExternal && i == last) {
				continue;
			}
			if (l.leftInternal && i == half - 1) {
				continue;
			}
			if(l.rightInternal && i == half) {
				continue;
			}
			if (l.middle && (i == half || i == half - 1)) {
				continue;
			}

			var dir: AnySide = AnySide.LeftRight;
			if (i == first) {
				dir = AnySide.Left;
			} else if (i == last) {
				dir = AnySide.Right;
			}

			this.setDarrow(this.wings[i], dir);
		}
	}

	private setDarrow(l: IRect, dir: AnySide) {
		const x1 = l.x + (l.w / 2) - 20;
		const x2 = l.x + (l.w / 2) + 20;
		const y = l.y + (l.h / 2) - 9;
		const a = { x1, x2, y1: y, y2: y };
		this.dArrows.push(new Arrow(a, dir));
	}

	private setCross(wing: IRect) {
		const l = 10;
		const m: IPoint = { x: wing.x + wing.w / 2, y: wing.y + (wing.h / 2) - 9 };

		this.crosses.push({ x1: m.x - l, x2: m.x + l, y1: m.y, y2: m.y });
		this.crosses.push({ x1: m.x, x2: m.x, y1: m.y - l, y2: m.y + l });
	}

	removeExtra(id: string) {
		this.extras = this.extras.filter(e => e != id);
		this.foundation = this.extras.find(e => e == this.def.foundationId) != null;
	}
	addExtra(id: string) {
		this.extras.push(id);
		this.foundation = this.extras.find(e => e == this.def.foundationId) != null;
	}
	getExtras() {
		return this.extras;
	}
	getLocks(): string[] {
		var locks: string[] = [];
		if (this._lockVariant.leftExternal != null) {
			locks.push(this.def.locks.find(l => l.lockType == this._lockVariant.leftExternal).id);
		}
		if (this._lockVariant.leftInternal != null) {
			locks.push(this.def.locks.find(l => l.lockType == this._lockVariant.leftInternal).id);
		}
		if (this._lockVariant.middle != null) {
			locks.push(this.def.locks.find(l => l.lockType == this._lockVariant.middle).id);
		}
		if (this._lockVariant.rightInternal != null) {
			locks.push(this.def.locks.find(l => l.lockType == this._lockVariant.rightInternal).id);
		}
		if (this._lockVariant.rightExternal != null) {
			locks.push(this.def.locks.find(l => l.lockType == this._lockVariant.rightExternal).id);
		}
		return locks;
	}

	setExpansion(top: boolean, bottom: boolean) {
		this._isExpandedTop = top;
		this._isExpandedBottom = bottom;
	}

	public calcTopFrame() {
		if (this.area == AreaType.Front && this.locationY == this.minLocationY) {
			this.topProfile.rect.h = 0;
			return;
		}

		var neib = this.getNeib(ElementSide.Top, true);
		if (neib) {
			this.topProfile.rect.h = 0;
		} else {
			const cnt = this.projectService.template.hiddenExtras.find(profile => profile.id == this.def.connectorId);
			this.topProfile.rect.h = cnt.size.height * SvgParams.SCALE;
		}

		if (this.topProfile.rect.h > 0) {
			this.topProfile.rect.y = this.rect.y - this.topProfile.rect.h;
			this.topProfile.rect.x = this.getLeftX();
			this.topProfile.rect.w = this.getRightX() - this.topProfile.rect.x;
		}
	}

	public calcBottomFrame() {
		this.bottomProfile.rect.y = this.rect.y + this.rect.h - this.bottomProfile.rect.h;
	}

	private getLeftX() {
		var neib = this.getNeib(ElementSide.Left);
		if (neib) {
			return neib.rightX;
		}
		const rn = RectName.get(this.area);
		const r = this.leftProfile[rn].x + this.leftProfile[rn].w;

		return Math.max(r, this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_TOP_POINT_NUM].x);
	}

	private getRightX() {
		var neib = this.getNeib(ElementSide.Right);
		if (neib) {
			return neib.leftX;
		}
		const rn = RectName.get(this.area);
		const r = this.rightProfile[rn].x;

		return Math.min(r, this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.RIGHT_TOP_POINT_NUM].x);
	}

	protected refresh() {
		this.calcTopFrame();
		this.calcBottomFrame();
	}

}
