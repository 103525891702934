import { ILine } from "../../interfaces/ILine.interface";
import { IRect } from "../../interfaces/IRect";

export abstract class ElementStoreModel {
	id: string;
	configId: string;
	oldConfigId: string;
	rectOnRoof: IRect;
	rectOnFront: IRect;
	rectOnRear: IRect;
	rectOnLeft: IRect;
	rectOnRight: IRect;
	lineOnRoof: ILine;
	lineOnFront: ILine;
	lineOnLeft: ILine;
	lineOnRight: ILine;
	tag: string;
	cuttingOnSite: boolean;

	constructor(id: string, configId: string, tag: string = "", cuttingOnSite: boolean = false) {
		this.id = id;
		this.configId = configId;
		this.tag = tag;
		this.cuttingOnSite = cuttingOnSite;
	}

	protected storeLocationAndSize(obj: any) {
		this.rectOnRoof = obj.rectOnRoof;
		this.rectOnFront = obj.rectOnFront;
		this.rectOnLeft = obj.rectOnLeft;
		this.rectOnRight = obj.rectOnRight;
		this.rectOnRear = obj.rectOnRear;

		this.lineOnRoof = obj.lineOnRoof;
		this.lineOnFront = obj.lineOnFront;
		this.lineOnLeft = obj.lineOnLeft;
		this.lineOnRight = obj.lineOnRight;

	}

	protected static restoreLocationAndSize(source: any, dest: any) {
		this._setIfExists(source, dest, 'rectOnRoof');
		this._setIfExists(source, dest, 'rectOnFront');
		this._setIfExists(source, dest, 'rectOnLeft');
		this._setIfExists(source, dest, 'rectOnRight');
		this._setIfExists(source, dest, 'rectOnRear');
	}

	private static _setIfExists(source: any, dest: any, property: string) {
		if (property in dest) {
			dest[property] = source[property];
		}
	}
}
