import { Component } from '@angular/core';
import { CardProfileComponent } from './card-profile.component';

@Component({
	selector: 'card-front',
	templateUrl: './card-front.component.html',
	styleUrls: ['../card.component.css']
})
export class CardFrontComponent extends CardProfileComponent {
}
