import { IPoint } from "./IPoint";

export enum AnchorType {
	middle = "middle",
	start = "start",
	end = "end"
}
export interface IMarker {
	text: string,
	loc: IPoint,
	anchor: AnchorType;
}