import { MarquisesParams, SvgParams, Tags } from '../../constants/constants';
import { AreaType } from '../../interfaces/IAreaType';
import { Montage } from '../../interfaces/IElementSide';
import { IRect } from '../../interfaces/IRect';
import { ProfileService } from '../../services/profile.service';
import { Common } from '../common';
import { FrameProfile } from '../profiles/frame.model';
import { Profile } from '../profiles/profile.model';
import { ElementType, Orientation } from '../ToolboxModel';
import { VerticalElement } from '../vertical-element';
import { MarquiseVertical } from './marquise-vertical.model';

export class MarquiseLeft extends MarquiseVertical {

	override get rect(): IRect {
		return this.rectOnLeft;
	}

	public get isFront(): boolean {
		return false;
	}

	protected get initialMontage(): Montage {
		return Montage.Outside;

	}

	protected getTop(): number {
		return this.topFrame.rectOnLeft.y;
	}
	protected getHeight(): number {
		var h = Common.round(this.projectService.template.backHeight) * SvgParams.SCALE;
		var top1 = this.getTop() - SvgParams.START_Y;
		var top2 = this.areaPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y - SvgParams.START_Y - h;
		h -= top1;
		h += top2;

		return h;
	}

	protected createFrameInternal(orient: Orientation): FrameProfile {
		return MarquiseLeft.createFrame(this.projectService, this.marquiseInfo, orient);
	}

	protected getLeftProfileY() {
		return this.projectService.getProfileY(this.leftProfile, AreaType.Left);
	}
	protected getRightProfileY() {
		return this.projectService.getProfileY(this.rightProfile, AreaType.Left);
	}

	public isMontageAllowed(p: Montage): boolean {
		const marqs = this.projectService.profileService.getMarquisesVertical().filter(m => m.id != this.id);
		switch (p) {
			case Montage.Outside:
				var cl = marqs.find((m: MarquiseVertical) =>  (m.leftProfile?.id == this.leftProfile?.id || m.rightProfile?.id == this.rightProfile?.id) && m.montage == Montage.Outside);
				if (cl) {
					return false;
				}
				break;

			case Montage.Inside:
				var cl = marqs.find((m: MarquiseVertical) => (m.leftProfile?.id == this.rightProfile?.id || m.rightProfile?.id == this.leftProfile?.id) && m.montage == Montage.Inside);
				if (cl) {
					return false;
				}
				break;

			case Montage.Middle:
				const x1 = this.leftProfile.rectOnLeft.x;
				const x2 = this.rightProfile.rectOnLeft.x;

				const verts = this.projectService.profileService.getVerticals(AreaType.Left);
				const mv = verts.find(v => v.leftX >= x1 && v.rightX <= x2 && v.id != this.id);
				return mv == null;
		}

		return true;
	}

	protected getMasterProfile(): Profile {
		return this.rightProfile;
	}

	protected adjustMontage() {
		const h = this.rectOnLeft.h;
		const depth = this.marquiseInfo.size.depth * SvgParams.SCALE;

		const x = MarquiseVertical.getX1(this.leftProfile, this.area, this.montage);
		const x2 = MarquiseVertical.getX2(this.rightProfile, this.area, this.montage);

		const w = x2 - x;
		const left = this.leftProfile.rectOnLeft;

		var rp: Profile = this.rightProfile;
		if (!rp.rectOnRoof) {
			const cols = this.getColumns();
			rp = cols.find(c => c.rectOnLeft.x + MarquisesParams.COLUMN_HANDLER_POINT * SvgParams.SCALE >= SvgParams.START_X + (this.locationX + this.width) * SvgParams.SCALE);
		}

		switch (this.montage) {
			case Montage.Inside:
				this.rectOnFront = {
					x: rp.rectOnRoof.x + rp.rectOnRoof.w,
					y: this.rectOnLeft.y,
					w: depth,
					h: h
				}
				this.rectOnRoof = {
					x: this.rectOnFront.x,
					y: SvgParams.START_Y + this.locationX * SvgParams.SCALE,
					w: depth,
					h: (this.width - this.locationX) * SvgParams.SCALE
				}
				break;
			case Montage.Middle:
				this.rectOnFront = null;
				this.rectOnRoof = null;
				break;
			case Montage.Outside:
				this.rectOnFront = {
					x: rp.rectOnRoof.x - depth,
					y: this.rectOnLeft.y,
					w: depth,
					h: h
				}
				this.rectOnRoof = {
					x: this.rectOnFront.x,
					y: SvgParams.START_Y + this.locationX * SvgParams.SCALE,
					w: depth,
					h: (this.width - this.locationX) * SvgParams.SCALE
				}
				break;
		}

		this.rectOnRight = null;
		this.rectOnLeft = {
			x: x,
			y: this.rectOnLeft.y,
			w: w,
			h: h
		};

		if (this.engine.rect) {
			this.relocateEngine(this.engine.location);
		}

		if (this.topFrame) {
			const lx1 = left.x + left.w;
			const lx2 = this.rightProfile.rectOnLeft.x;

			this.topFrame.rectOnLeft.x = left.x + left.w;
			this.topFrame.rectOnLeft.y = this.rectOnLeft.y;
			this.topFrame.rectOnLeft.w = lx2 - lx1;
		}

		this.calculateMarkers(this.rectOnLeft);
	}

}