import { AreaType, RectName } from "../../interfaces/IAreaType";
import { IPoint } from "../../interfaces/IPoint";
import { ProfileService } from "../profile.service";
import { SvgParams,	GLASS_PART_PADDING, GUID_DOOR_SINGLE } from '../../constants/constants';
import { ProjectService } from '../project.service';
import { Orientation, ElementType, ToolboxItem, ToolboxItemType } from "../../models/ToolboxModel";
import { Workarea } from "../../models/workarea";
import { WallProfile } from "../../models/profiles/wall.model";
import { ColumnProfile } from "../../models/profiles/column.model";
import { SideFinishProfile } from '../../models/profiles/side-finish.model';
import { ProjectTemplate } from "../../template";
import { Profile } from "../../models/profiles/profile.model";
import { GlassWallDiamond } from "../../models/glasses/glassWallDiamond.model";
import { FrameProfile } from '../../models/profiles/frame.model';
import { Common } from '../../models/common';
import { MuntinProfile } from '../../models/profiles/muntin.model';
import { GlassPartDiamond } from '../../models/glasses/glassDiamondPart';
import { IAreaService } from "../../interfaces/IAreaService";
import { MarquiseVertical } from "../../models/marquises/marquise-vertical.model";
import { ElementSide, Montage } from "../../interfaces/IElementSide";
import { DoorType, LockType } from "../../interfaces/IDoorInfo";
import { DoorCreator } from "../../models/doors/door-creator";
import { IConnectable } from "../../interfaces/IConnectable";
import { Door } from "../../models/doors/door.model";
import { Subject } from "rxjs";
import { Space } from "../../models/space";
import { Shape } from "../../models/shape";
import { IPointer } from "../../interfaces/IPointer";
import { WallCreator } from "../../models/walls/wall-creator";
import { Wall } from "../../models/walls/wall.model";
import lockVariants from '../../models/doors/_lock-variants.json';
import { ILockVariant } from "../../interfaces/ILockVariant";
import { GlassWallBase } from "../../models/glasses/glassWall-base.model";
import { GlassWallDiamondService } from "../glass-walls/glass-wall-diamond.service";
import { GlassWallParameter } from "../../models/wizard/wizard-parameter.model";
import { IWallInfo } from "../../interfaces/IWallInfo";


export abstract class SideBaseService implements IAreaService  {

	protected static verticals = [ElementType.Frame, ElementType.Column, ElementType.MarquiseVertical, ElementType.Door, ElementType.Wall];
	protected static horizontals = [ElementType.Frame, ElementType.GlassWall, ElementType.MarquiseVertical, ElementType.Door, ElementType.Wall];

	protected _template: ProjectTemplate;

	private currentSideElements = {};

	protected _svg: string = "";

	protected workAreas: Workarea[];
	protected shapePoints: IPoint[];

	protected get width() {
		return this.projectService.template.width;
	}

	protected get depth() {
		return this.projectService.template.depth;
	}

	protected get backHeight() {
		return this.projectService.template.backHeight;
	}

	protected get frontHeight() {
		return this.projectService.template.frontHeight;
	}
	public doorCreation = new Subject<Door>();

	public abstract leftElement: Profile | GlassWallBase;
	public abstract rightElement: Profile | GlassWallBase;
	public abstract bottomElement: Profile | GlassWallBase;


	constructor(
		protected profileService: ProfileService,
		protected projectService: ProjectService,
	) {

		this.projectService.templateChangedSubject.subscribe(t => {
			this.applyTemplate(t);
		})

	}
	public manageFrameAfterGlassChange(currentGlass: GlassWallBase) {
		this.manageFrameAfterChangingHeight(currentGlass);
	}

	protected abstract previousTemplateNo: string;
	protected abstract calculatePoints();
	protected abstract get hightestTopPointNum(): number;
	protected abstract get lowestTopPointNum(): number;
	public abstract getFrontCornerY(currentAreaElements?: any);
	public abstract getGWDStartPoints(currentAreaElements?: any): IPoint[];
	public abstract createFrame(projectService: ProjectService, info: IConnectable, orient: Orientation);

	public abstract createMarquise(ptr: IPointer, currentTool: ToolboxItem);

	public abstract getMinHeightOfGWD(points: IPoint[]): number;
	public abstract get areaType(): AreaType;
	public abstract get rectOnSide(): string;
	public abstract get anchorOnSide(): string;
	public abstract get rotationOnSide(): string;
	public abstract get pathOnSide(): string;

	public abstract changeArea();
	public abstract addElementsAfterDisplayedRoof(roofElements: any);
	protected abstract saveWallWithSetRect(wall: WallProfile);
	protected abstract saveElementWithSetRect(element: Profile, height: number);

	showArea(): void {
		this.calculatePoints();
		this.addElementsAfterDisplayedRoof(this.profileService.roofElements);
	}

	protected getSideFinishYCorrected(sideFinish: SideFinishProfile, lty: number) {

		if (!this.projectService.template.isStandalone) {
			var b = sideFinish.lineOnRoof.y1 - SvgParams.START_Y;
			var a = Math.tan(Common.toRadians(this.projectService.template.dropAngle)) * b;
			lty -= Common.round(a);
		}

		return lty;
	}

	protected saveColWithSetRect(col: ColumnProfile, x: number, profileHeight: number): void {
		this.getCurrentSideElements();

		let y: number;
		let h: number = Math.round(col.getLength() * SvgParams.SCALE);
		if (col.isFront) {
			y = this.shapePoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - this.projectService.template.columnLength * SvgParams.SCALE;
		} else if (col.isRear) {
			y = SvgParams.START_Y + profileHeight * SvgParams.SCALE;
		} else {
			const endColumnPositionX: number = this.getEndColumnPositionX(x, col.depth);
			const colPosH = this.projectService.getXPositionHeight(endColumnPositionX);
			y = SvgParams.START_Y + (this.backHeight - colPosH) * SvgParams.SCALE + profileHeight * SvgParams.SCALE;
		}

		col[this.rectOnSide] = {
			x: x,
			y: Common.round(y),
			w: Math.round(col.depth * SvgParams.SCALE),
			h: Common.round(h)
		};
		if (!this.currentSideElements[ElementType.Column].find(e => e.id == col.id)) {
			this.currentSideElements[ElementType.Column].push(col);
		}
	}

	private getEndColumnPositionX(x: number, d: number): number {
		if (this.areaType === AreaType.Left) {
			return (x + Math.round(d * SvgParams.SCALE) - SvgParams.START_X) / SvgParams.SCALE;
		} else {
			return this.depth - (x - SvgParams.START_X) / SvgParams.SCALE;
		}
	}


	//////////////////////////////////

	private getCurrentSideElements() {

		if (this.areaType === AreaType.Left) {
			this.currentSideElements = this.profileService.leftSideElements
		} else if (this.areaType === AreaType.Right) {
			this.currentSideElements = this.profileService.rightSideElements
		}
	}


	public applyGlassId(glassId: string) {
	}

	public applyTemplate(template: ProjectTemplate) {
		this._template = template;
	}

	private isPossibleToAddGlassWallDiamond(ptr: IPointer, area: AreaType): boolean {
		const ar = RectName.get(area);
		const mq = this.profileService.getMarquisesVertical(this.areaType).find((m: MarquiseVertical) => 
			m.leftProfile[ar].x <= ptr.offsetX && m.rightProfile[ar].x >= ptr.offsetX && m.montage == Montage.Middle);

		if (mq) {
			const message = $localize`:Glass wall diamond|Validation message:Cannot place glass wall diamond on a marquise!`;
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		const columnBeforeClickedPoint: ColumnProfile = this.projectService.getColumnBeforeClickedPoint(ptr, ar);
		const columnAfterClickedPoint: ColumnProfile = this.projectService.getColumnAfterClickedPoint(ptr, ar);

		if (columnBeforeClickedPoint?.isWinkel || columnAfterClickedPoint?.isWinkel) {
			const message = $localize`:Glass wall diamond|Validation message:Cannot mount glass wall diamond to a winkel!`;
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		if (this.checkDiamondOverClick(ptr)) {
			const message = $localize`:Glass wall diamond|Validation message:There is another element in this place!`;
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		return true;
	}

	createGlassWallDiamond(ptr: IPointer, currentTool: ToolboxItem) {
		if (!this.isPossibleToAddGlassWallDiamond(ptr, this.areaType)) {
			return;
		}

		var glassPoints = this.getGWDPoints(ptr);

		var sh = new Shape(glassPoints, this.projectService.template.dropAngle);
		const def = this._template.diamondGlasses.find(g => g.id === currentTool.id);
		if (sh.lessHeight / SvgParams.SCALE < def.minHeight) {
			const message = $localize`:Glass wall diamond|Validation message:Not enough space for glass wall diamond!`;
			this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return;
		}
		const gwd: GlassWallDiamond = new GlassWallDiamond(this.projectService, def, glassPoints, this.areaType, this);
		this.projectService.profileService.addVertical(gwd);
		gwd.height = gwd.maxHeight;

		new GlassWallDiamondService(this.projectService).setWidth(gwd, gwd.maxWidth);
		this.createMuntins(gwd);
		this.manageFrameAfterGlassChange(gwd);
		this.projectService.freezeProjProp();
	}

	// * working with muntins

	public createMuntins(diamond: GlassWallDiamond, muntinsCount: number = null) {
		const rearScaleH = this.projectService.template.getRearSize().height * SvgParams.SCALE;

		diamond.muntins = [];
		diamond.parts = [];

		if (!diamond.muntinsRequired && muntinsCount == null) {
			let part = this.generateGlassPartDiamond(diamond, diamond.points[SvgParams.LEFT_TOP_POINT_NUM].x, diamond.width);
			diamond.parts.push(part);
			diamond.minMuntinsCount = 0;
			return;
		}

		let wi = diamond.width;
		let surfaceArea = Common.calculateDiamondSurface(wi, diamond.lowerHeight, diamond.height, GLASS_PART_PADDING);
		let i = (muntinsCount ?? 0) + 1;
		if (muntinsCount == null) {
			while (surfaceArea > diamond.maxM2) {
				wi = diamond.width / ++i;
				surfaceArea = Common.calculatePartSurface(wi, diamond.height, this._template.dropAngle, GLASS_PART_PADDING);
			}
		}
		let x = diamond.points[SvgParams.LEFT_TOP_POINT_NUM].x;
		let w = Common.round(diamond.width / i);
		let mnt: MuntinProfile;

		for (let p = 0; p < i; p++) {
			let part = this.generateGlassPartDiamond(diamond, x, w);
			if (mnt) {
				mnt.rightPart = part;
			}
			diamond.parts.push(part);
			x += w * SvgParams.SCALE;

			const xPosition = this.areaType === AreaType.Left ? (x - SvgParams.START_X) / SvgParams.SCALE : this.depth - (x - SvgParams.START_X) / SvgParams.SCALE;
			const projBottomY = this.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
			const diamondBottomY = diamond.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y;

			const padding = GLASS_PART_PADDING;
			const xPositionHeight = this.projectService.getXPositionHeight(xPosition - padding) - (projBottomY - diamondBottomY) / SvgParams.SCALE;
			const yPosition = SvgParams.START_Y + (this.projectService.template.backHeight - xPositionHeight) * SvgParams.SCALE + rearScaleH - (projBottomY - diamondBottomY);
			if (p < i-1) {
				mnt = new MuntinProfile(x - padding, yPosition + padding, xPositionHeight - padding * 2 / SvgParams.SCALE, Orientation.Vertical, diamond.id);
				diamond.muntins.push(mnt);
				mnt.leftPart = part;
			}
		}
		if (muntinsCount == null) {
			diamond.minMuntinsCount = diamond.muntins.length;
		}
	}

	public adjustMuntins(mnt: MuntinProfile, newLocation: number) {
		const current = Math.round((mnt.rect.x - SvgParams.START_X) / SvgParams.SCALE);
		const diff = current - newLocation;
		const sd = diff * SvgParams.SCALE;
		const leftPart: GlassPartDiamond = (mnt.leftPart as GlassPartDiamond);
		const rightPart: GlassPartDiamond = (mnt.rightPart as GlassPartDiamond);

		if (diff < 0) { // move right
			if (leftPart.muntinsRequired) { // check max m2
				this.projectService.showTemporaryMessageSubj.next({ message: "Left glass is too big", hideAfter: 1000, style: 'error' })
				return;
			}
		} else { // move left
			if (rightPart.muntinsRequired) { // check max m2
				this.projectService.showTemporaryMessageSubj.next({ message: "Right glass is too big", hideAfter: 1000, style: 'error' })
				return;
			}
		}
		leftPart.points[SvgParams.RIGHT_TOP_POINT_NUM].x -= sd;
		leftPart.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x -= sd;
		leftPart.points[SvgParams.RIGHT_TOP_POINT_NUM].y = this.getY(leftPart.points[SvgParams.RIGHT_TOP_POINT_NUM].x, leftPart.drop) + 5;

		rightPart.points[SvgParams.LEFT_TOP_POINT_NUM].x -= sd;
		rightPart.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x -= sd;
		rightPart.points[SvgParams.LEFT_TOP_POINT_NUM].y = this.getY(rightPart.points[SvgParams.LEFT_TOP_POINT_NUM].x, rightPart.drop) + 5;

		leftPart.path = leftPart.calculatePath();
		rightPart.path = rightPart.calculatePath();

		const padding = GLASS_PART_PADDING;

		mnt.rect.x -= sd;
		mnt.rect.y = this.getY(mnt.rect.x, leftPart.drop) + padding;
		if (mnt.orientation === Orientation.Vertical) {
			const xPosition = this.areaType === AreaType.Left ? (mnt.rect.x - SvgParams.START_X) / SvgParams.SCALE : this.depth - (mnt.rect.x - SvgParams.START_X) / SvgParams.SCALE;
			const projBottomY = this.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
			const diamondBottomY = leftPart.master.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y;

			mnt.length = this.projectService.getXPositionHeight(xPosition) * SvgParams.SCALE - (projBottomY - diamondBottomY) - padding * 2;
		}
	}

	protected generateGlassPartDiamond(diamond: GlassWallDiamond, leftX: number, w: number) {
		const rightX = leftX + w * SvgParams.SCALE;

		const leftTopY = this.getY(leftX + GLASS_PART_PADDING, diamond.drop);
		const rightTopY = this.getY(rightX - GLASS_PART_PADDING, diamond.drop);

		const points: IPoint[] = [];
		points.push({
			x: leftX + GLASS_PART_PADDING,
			y: leftTopY + GLASS_PART_PADDING,
		});
		points.push({
			x: rightX - GLASS_PART_PADDING,
			y: rightTopY + GLASS_PART_PADDING,
		});
		points.push({
			x: rightX - GLASS_PART_PADDING,
			y: diamond.points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - GLASS_PART_PADDING,
		});
		points.push({
			x: leftX + GLASS_PART_PADDING,
			y: diamond.points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - GLASS_PART_PADDING,
		});

		var diff = 0;
		if (this.areaType == AreaType.Left) {
			if (points[1].y > points[2].y) {
				diff = (points[3].y - points[1].y) / SvgParams.SCALE;
				points[1].y = points[3].y;
				points[1].x += diff;
				points[2].x += diff;
			}
		} else {
			if (points[0].y > points[3].y) {
				diff = (points[0].y - points[3].y) / SvgParams.SCALE;
				points[0].y = points[3].y;
				points[0].x += diff;
				points[3].x += diff;
			}
		}

		return new GlassPartDiamond(diamond, this.projectService, points, this.areaType)
	}

	private getY(x: number, drop: number): number {
		const rearScaleH = this.projectService.template.getRearSize().height * SvgParams.SCALE;

		const xPosition = this.areaType === AreaType.Left ? (x - SvgParams.START_X) / SvgParams.SCALE : this.depth - (x - SvgParams.START_X) / SvgParams.SCALE;
		const xPositionHeight = this.projectService.getXPositionHeight(xPosition, drop);
		return Common.round(SvgParams.START_Y + (this.projectService.template.backHeight - xPositionHeight) * SvgParams.SCALE + rearScaleH);
	}

	private checkDiamondOverClick(ptr: IPointer) {
		const topGlWallDiam = this.profileService.getGetDiamonds(this.areaType)
			.sort((g1: GlassWallDiamond, g2: GlassWallDiamond) => g1.points[0].y < g2.points[0].y ? 1 : -1)
			.find((g: GlassWallDiamond) => g.points[3].y <= ptr.offsetY && ptr.offsetX > g.points[0].x && ptr.offsetX < g.points[1].x);

		if (topGlWallDiam != null) {
			return true;
		}

		const topWallDiam = this.profileService.getWalls(this.areaType)
			.filter(((w: Wall) => w.shape.isDiamond))
			.sort((g1: Wall, g2: Wall) => g1.points[0].y < g2.points[0].y ? 1 : -1)
			.find((g: Wall) => g.points[3].y <= ptr.offsetY && ptr.offsetX > g.points[0].x && ptr.offsetX < g.points[1].x);

		if (topWallDiam != null) {
			return true;
		}

		return false;
	}

	private getBottomElement(elements: any[], point: number) {
		let resBottom: Profile;

		SideBaseService.horizontals.filter(et => elements[et] != null).forEach(et => {
			const cr = this.rectOnSide;
			const bottom = elements[et]
				.sort((c1: Profile, c2: Profile) => c1[cr].y > c2[cr].y ? 1 : -1)
				.find((c: Profile) => Math.round(c[cr].y) >= Math.round(point));

			if (bottom && Math.round(bottom[cr].y) >= Math.round(point)) {
				resBottom = bottom;
			}
		});

		return resBottom;
	}

	getMaxWidthOfGWD(gw: GlassWallDiamond): number {
		var maxLox = this.getMaxLocationXOfGWD(gw);
		var m = gw.width + maxLox - gw.locationX;
		return m;
	}

	getMaxLocationXOfGWD(gw: GlassWallDiamond): number {
		const points = this.getGWDStartPoints();
		var rightBottomX = points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x;
		var neib = this.profileService.findVerticalNeib(gw, ElementSide.Right);
		if (neib) {
			rightBottomX = neib.leftX;
		}
		rightBottomX = (rightBottomX - SvgParams.START_X) / SvgParams.SCALE;
		const maxWidth = Math.abs(rightBottomX - gw.locationX);
		var maxl = gw.locationX +  (maxWidth - gw.width);
		return maxl;
	}

	getMinLocationXOfGWD(gw: GlassWallDiamond): number {
		var neib = this.profileService.findVerticalNeib(gw, ElementSide.Left);
		if (neib) {
			var x = neib.rightX;
			return (x - SvgParams.START_X) / SvgParams.SCALE;
		}

		const points = this.getGWDStartPoints();
		return (points[SvgParams.LEFT_TOP_POINT_NUM].x - SvgParams.START_X) / SvgParams.SCALE;
	}

	// * working with frame

	manageFrameAfterChangingHeight(currentGlass: GlassWallBase) {
		if(!currentGlass.bottomFrame) {
			this.createBottomFrame(currentGlass);
		}
		this.updateBottomFrame(currentGlass);
	}
	
	private createBottomFrame(currentGlass: GlassWallBase) {
		const extrasInfo = this.projectService.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		const xPos = currentGlass.leftX;
		const yPos = currentGlass.bottomY
		const length = currentGlass.width;
		const size = extrasInfo.size;
		const color = extrasInfo.color;
		const orientation = Orientation.Horizontal;

		const newFrame = new FrameProfile(xPos, yPos, length, size, color, orientation, currentGlass.connectorId);
		currentGlass.bottomFrame = newFrame;
	}

	public updateBottomFrame(currentGlass: GlassWallBase) {
		currentGlass.bottomFrame.rect.y = currentGlass.bottomY;

		var maxy = this.getWorkAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		if (Math.round(currentGlass.bottomFrame.rect.y) === Math.round(maxy)) {
			currentGlass.bottomFrame.rect.h = 0;
			return;
		}
		const neibBott = currentGlass.getNeib(ElementSide.Bottom);
		const connHeight = this.projectService.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId).size.height * SvgParams.SCALE;
		if (neibBott && neibBott.topY < currentGlass.bottomY + connHeight) {
			currentGlass.bottomFrame.rect.h = 0;
		} else {
			currentGlass.bottomFrame.rect.h = connHeight;
		}
	}

	manageFrameAfterChangingLocationX(currentGlass: GlassWallDiamond) {
		const isEqualsMinLocationX = this.isCurrentGlassLocationXEqualsItsMinLocationX(currentGlass);
		if (isEqualsMinLocationX) {
			this.projectService.removeFrame(currentGlass, 'leftFrame');
		} else {
			this.updateLeftFrame(currentGlass);
		}

		currentGlass.bottomFrame.rect.x = currentGlass.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x;
		currentGlass.bottomFrame.rect.w = currentGlass.width * SvgParams.SCALE;

		const isEqualsMaxLocationX = this.isCurrentGlassLocationXEqualsItsMaxLocationX(currentGlass);
		if (isEqualsMaxLocationX) {
			this.projectService.removeFrame(currentGlass, 'rightFrame');
		} else {
			this.updateRightFrame(currentGlass);
		}
	}

	private isCurrentGlassLocationXEqualsItsMinLocationX(currentGlass: GlassWallDiamond) {
		const currentGlassMinXLocation = this.getMinLocationXOfGWD(currentGlass) * SvgParams.SCALE + SvgParams.START_X;
		return Math.round(currentGlass.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) === Math.round(currentGlassMinXLocation);
	}

	private isCurrentGlassLocationXEqualsItsMaxLocationX(currentGlass: GlassWallDiamond) {
		const currentGlassMaxXLocation = this.getMaxLocationXOfGWD(currentGlass) * SvgParams.SCALE + SvgParams.START_X;
		return Math.round(currentGlass.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x) === Math.round(currentGlassMaxXLocation);
	}

	private updateRightFrame(currentGlass: GlassWallDiamond) {
		let offsetX = (currentGlass.rightFrame?.rect.x < currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].x)
			? (currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].x + currentGlass.rightFrame.size.width * SvgParams.SCALE)
			: currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].x;
		let offsetY = currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].y + 1;
		const ptr = { offsetX, offsetY }
		this.getGWDPoints(ptr);
		const bh = this.backHeight * SvgParams.SCALE;
		const rh = this.projectService.template.getRearSize().height * SvgParams.SCALE;

		const extrasInfo = this.projectService.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		const currAreaRect = this.projectService.getCurrentAreaRect();

		const xPos = currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].x;
		const yPos = currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].y;
		const length = this.bottomElement && this.bottomElement.id !== currentGlass.id && this.bottomElement.id !== currentGlass.bottomFrame?.id
			? (this.bottomElement[currAreaRect].y - yPos) / SvgParams.SCALE
			: (SvgParams.START_Y + bh + rh - yPos) / SvgParams.SCALE;

		const size = extrasInfo.size;
		const color = extrasInfo.color;
		const orientation = Orientation.Vertical;

		const currAreaElems = this.projectService.getCurrentAreaElements();
		const newFrame = new FrameProfile(xPos, yPos, length, size, color, orientation, currentGlass.connectorId);
		currAreaElems[ElementType.Frame] = currAreaElems[ElementType.Frame].filter(e => e.id !== currentGlass.rightFrame?.id);
		currentGlass.rightFrame = newFrame;
		currAreaElems[ElementType.Frame].push(newFrame);
	}

	private updateLeftFrame(currentGlass: GlassWallDiamond) {
		const extrasInfo = this.projectService.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		const currAreaRect = this.projectService.getCurrentAreaRect();

		let offsetX = (currentGlass.leftFrame?.rect.x + currentGlass.leftFrame?.rect.w > currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].x)
			? (currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].x - currentGlass.leftFrame.size.width * SvgParams.SCALE)
			: currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].x;
		let offsetY = currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].y + 1;
		const ptr = { offsetX, offsetY }
		this.getGWDPoints(ptr);
		const bh = this.backHeight * SvgParams.SCALE;
		const rh = this.projectService.template.getRearSize().height * SvgParams.SCALE;

		const xPos = currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].x - extrasInfo.size.width * SvgParams.SCALE;
		const yPos = currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].y;
		const length = this.bottomElement && this.bottomElement.id !== currentGlass.id && this.bottomElement.id !== currentGlass.bottomFrame?.id
			? (this.bottomElement[currAreaRect].y - yPos) / SvgParams.SCALE
			: (SvgParams.START_Y + bh + rh - yPos) / SvgParams.SCALE;

		const size = extrasInfo.size;
		const color = extrasInfo.color;
		const orientation = Orientation.Vertical;

		const currAreaElems = this.projectService.getCurrentAreaElements();
		const newFrame = new FrameProfile(xPos, yPos, length, size, color, orientation, currentGlass.connectorId);
		currAreaElems[ElementType.Frame] = currAreaElems[ElementType.Frame].filter(e => e.id !== currentGlass.leftFrame?.id);
		currentGlass.leftFrame = newFrame;
		currAreaElems[ElementType.Frame].push(newFrame);
	}

	protected getGWDPoints(ptr: IPointer, area: AreaType = AreaType.None): IPoint[] {
		if (area == AreaType.None) {
			area = this.areaType;
		}
		const lt = SvgParams.LEFT_TOP_POINT_NUM;
		const rt = SvgParams.RIGHT_TOP_POINT_NUM;
		const lb = SvgParams.LEFT_BOTTOM_POINT_NUM;
		const rb = SvgParams.RIGHT_BOTTOM_POINT_NUM;

		// console.debug('getGWDPoints 0: lt, rt, rb, lb', lt, rt, lb, rb);
		var points = this.getGWDStartPoints();
		// console.debug('getGWDPoints 1', points);
		// return points;
		if (ptr.offsetX < points[0].x) {
			ptr.offsetX = points[0].x;
		}
		const cols = this.projectService.getSpace(ptr, null, area);
		// console.debug('getGWDPoints 1.1', cols);

		if (cols.bottomProfile) {			
			// console.debug('getGWDPoints 1.1')
			points[lb].y = cols.bottomProfile.rect.y;
			points[rb].y = points[lb].y;
		}
		// console.debug('getGWDPoints 2', points);

		// console.debug('getGWDPoints 2.1', cols.topProfile?.id, cols.leftProfile?.id, cols.rightProfile?.id);
		var top = false;
		if (cols.topProfile && ((!cols.leftProfile || cols.leftProfile instanceof ColumnProfile) || (!cols.rightProfile || cols.rightProfile instanceof ColumnProfile))) {
			// console.debug('getGWDPoints 2.2')
			points[lt].y = cols.topProfile.rect.y; // + cols.topProfile.rect.h;
			points[rt].y = points[lt].y;
			top = true;
		}
		// console.debug('getGWDPoints 3', points);

		if (cols.leftProfile) {
			// console.debug('getGWDPoints 3.1')
			points[lt].x = cols.leftProfile[this.rectOnSide].x + cols.leftProfile[this.rectOnSide].w;
			points[lb].x = points[lt].x;
			if (!top && area == AreaType.Right && cols.leftProfile.type == ElementType.Frame) {
				points[lt].y -= Common.calculateDroppedY(points[lt].x - SvgParams.START_X, this.projectService.template.dropAngle);
			}

			// if (cols.leftProfile.type == ElementType.Frame) {
			// const topNeib = this.profileService.findVerticalNeib(new VirtualVertical(this.projectService, ptrEv, this.areaType), ElementSide.Top);				// 	if (topNeib == null) {
			// 		points[lt].y = cols.leftProfile[this.rectOnSide].y;
			// 	}
			// }
		}
		// console.debug('getGWDPoints 4', points);
	
		// console.debug('cols.rightProfile 1', cols.rightProfile?.type);

		
		if (cols.rightProfile) {
			const ox = points[rt].x;
			points[rt].x = cols.rightProfile[this.rectOnSide].x;
			points[rb].x = points[rt].x;
			if (area == AreaType.Left && cols.rightProfile.type == ElementType.Frame) {
				// console.debug('cols.rightProfile 2', cols.rightProfile?.type);
				points[rt].y -= Common.calculateDroppedY(ox - points[rt].x, this.projectService.template.dropAngle);
			}

			// if (cols.rightProfile.type == ElementType.Frame) {
			// 	if (topNeib == null) {
			// 		points[rt].y = cols.rightProfile[this.rectOnSide].y;
			// 	}
			// }
		}

		// console.debug('getGWDPoints 5', points);

		// console.debug('points[rt].y, points[rb].y', points[rt].y, points[rb].y);

		if (points[rt].y > points[rb].y) {
			points[rt].y = points[rb].y;
		}
		return points;
	}

	getSvg() {
		if (this._svg != "") {
			return this._svg;
		}
		this._svg = ProjectService.getPath(this.shapePoints);
		return this._svg;
	}

	getShapeAreaPoints() {
		return this.shapePoints;
	}

	getWorkAreaPoints() {
		return this.workAreas[0].points;
	}

	onDrop(pointerEvent: IPointer, currentTool: ToolboxItem) {
		if (currentTool == null) {
			return;
		}
		switch (currentTool.type) {
			// PROBABLY IT WILL NOT BE USED ANYMORE
			// case ToolboxItemType.Glass:
			// 	this.applyGlassId(currentTool.id);
			// 	break;

			case ToolboxItemType.GlassWallDiamond:
				this.createGlassWallDiamond(pointerEvent, currentTool);
				break;
			case ToolboxItemType.MarquiseVertical:
				this.createMarquise(pointerEvent, currentTool);
				break;
			case ToolboxItemType.Door:
				this.createDoor(pointerEvent, currentTool);
				break;
			case ToolboxItemType.Wall:
				this.createWall(pointerEvent, currentTool);
				break;
		}
	}

	private createVertical(ptr: IPointer, connector: string, area: AreaType = AreaType.None): Space {
		if (area == AreaType.None) {
			area = this.areaType;
		}
		const cols = this.projectService.getSpace(ptr, null, area);
		if (!cols.rightProfile || (cols.rightProfile.type == ElementType.Frame)) {
			// "Fake" profile
			const rx = cols.rightProfile ? cols.rightProfile.getRect(this.areaType).x : SvgParams.START_X + this.projectService.template.depth * SvgParams.SCALE;
			cols.rightProfile = this.createFrame(this.projectService, { connectorId: connector }, Orientation.Vertical);
			(cols.rightProfile as FrameProfile).rect.x = rx;
			(cols.rightProfile as FrameProfile).rect.w = 0;
		}
		if (!cols.leftProfile || (cols.leftProfile.type == ElementType.Frame)) {
			// "Fake" profile
			const lx = cols.leftProfile ? cols.leftProfile.getRect(this.areaType).x + cols.leftProfile.getRect(this.areaType).w : SvgParams.START_X;
			cols.leftProfile = new FrameProfile(SvgParams.START_X, SvgParams.START_Y, this.projectService.template.backHeight, { width: 0, height: 0, depth: 0 }, "", Orientation.Vertical, connector);
			cols.leftProfile = this.createFrame(this.projectService, { connectorId: connector }, Orientation.Vertical);
			(cols.leftProfile as FrameProfile).rect.x = lx;
			(cols.leftProfile as FrameProfile).rect.w = 0;
		}

		if (!cols.topProfile) {
			const shorter = cols.leftProfile[this.rectOnSide].y > cols.rightProfile[this.rectOnSide].y ? cols.leftProfile : cols.rightProfile;
			cols.topProfile = this.createFrame(this.projectService, { connectorId: connector }, Orientation.Horizontal);
			cols.topProfile.rect.y = shorter[this.rectOnSide].y;
			cols.topProfile.rect.h = 0.01;
		} else if (cols.topProfile.configId != connector) {
			cols.topProfile.configId = connector;
		}

		if (cols.bottomProfile && cols.bottomProfile.rect.h > 0) {
			const h = cols.bottomProfile.rect.h;
			const y = cols.bottomProfile.rect.y - h;
			cols.bottomProfile = this.createFrame(this.projectService, { connectorId: connector }, Orientation.Horizontal);
			cols.bottomProfile.rect.h = 0.01; // must be non-zero, will be rounded later
			cols.bottomProfile.rect.y = y;
		} else {
			const y = cols.bottomProfile?.rect?.y ?? cols.leftProfile[this.rectOnSide].y + cols.leftProfile[this.rectOnSide].h;
			cols.bottomProfile = this.createFrame(this.projectService, { connectorId: connector }, Orientation.Horizontal);
			cols.bottomProfile.rect.h = 0;
			cols.bottomProfile.rect.y = y;
		}
		return cols;
	}

	private createDoorVertical(ptr: IPointer) {
		var exists = this.profileService.getDoors(this.areaType);
		if (exists.length > 0) {
			return null;
		}

		const r = this.projectService.getPlaceholder(ptr, null);
		const tempConnector = this._template.doors[0].connectorId;
		var crp = this.createVertical(ptr, tempConnector);

		crp = this.projectService.tuneVerticalToFront(this.areaType, crp, r.y);
		return crp;
	}

	public createDoor(ptr: IPointer, currentTool: ToolboxItem) {
		var crp = this.createDoorVertical(ptr);
		var dtype = currentTool.id == GUID_DOOR_SINGLE ? DoorType.SingleSide : DoorType.DoubleSide;
		var creator = new DoorCreator(this.projectService, crp, this.areaType, this);
		var sel = this.projectService.calculateDoorVariant(creator, dtype, crp.topProfile.rect.y);
		
		if (sel) {
			var d = creator.createDoor(sel, null, crp.topProfile.rect.y);
			this.doorCreation.next(d);
		}
	}

	public createWall(ptr: IPointer, currentTool: ToolboxItem) {
		var points = this.getGWDPoints(ptr);
		const def = this._template.walls.find(w => w.id == currentTool.id);
		var crp = this.createVertical(ptr, def.muntin.id);
		let diamond: Shape;
		if (crp.topProfile.rect.h == 0.01) {
			crp.topProfile.rect.h = 0;
			diamond = new Shape(points, this.projectService.template.dropAngle);
		}
		this.projectService.createWall(this, crp, currentTool, diamond);
	}

	public createWallFromPopup(area: AreaType, side: ElementSide, currentTool: ToolboxItem = null) {
		const sp = this.getWorkAreaPoints();
		let ptr: IPointer;
		let points: IPoint[];
		var def: IWallInfo;
		if (currentTool?.type == ToolboxItemType.Wall) {
			def = this._template.walls.find(g => g.id == currentTool.id)
		} else {
			def = this._template.walls.find(g => g.isDefault == true) ?? this._template.walls[0];
		}

		// if (side == ElementSide.Bottom) {
			ptr = { offsetX: sp[SvgParams.LEFT_TOP_POINT_NUM].x + 10, offsetY: sp[SvgParams.LEFT_BOTTOM_POINT_NUM].y - 10 };
		// } else {
			// ptr = { offsetX: sp[SvgParams.LEFT_TOP_POINT_NUM].x + 1, offsetY: sp[SvgParams.LEFT_TOP_POINT_NUM].y + 100 };
		// }		
		points = this.getGWDPoints(ptr, area);

		var shape = new Shape(points, this.projectService.template.dropAngle);
		var crp = this.createVertical(ptr, def.muntin.id);

		var creator = new WallCreator(this.projectService, crp, area, this);
		let d: Wall;
		if (shape.isDiamond) {
			d = creator.createDiamondWall(def, shape);
		} else {
			d = creator.createWall(def, null, crp.startPosY);
		}

		switch (side) {
			case ElementSide.Top:
				d.height = 1000;
				break;
			case ElementSide.Left:
				d.width = 300;
				break;
			case ElementSide.Bottom:
				d.height = 1000;
				// d.locationY = this._template.backHeight - 1000;
				d.locationY = d.maxLocationY;
				// d.height = d.maxHeight;
				break;
		}

		this.profileService.addVertical(d, true);
	}

	createGlassWallDiamondFromPopup(maxHeight: boolean, neibBott: boolean, currentTool: ToolboxItem = null) {
		const sp = this.getWorkAreaPoints();
		const ptr: IPointer = { offsetX: sp[SvgParams.LEFT_TOP_POINT_NUM].x + 2, offsetY: sp[SvgParams.LEFT_TOP_POINT_NUM].y + 2 };
		var glassPoints = this.getGWDPoints(ptr);

		if (!neibBott) {
			if (this.areaType == AreaType.Left) {
				glassPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y = glassPoints[SvgParams.RIGHT_TOP_POINT_NUM].y + 10;
				glassPoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y = glassPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y
			} else {
				glassPoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y = glassPoints[SvgParams.LEFT_TOP_POINT_NUM].y + 10;
				glassPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y = glassPoints[SvgParams.RIGHT_BOTTOM_POINT_NUM].y
			}
		}

		var def: GlassWallParameter;
		if (currentTool?.type == ToolboxItemType.GlassWallDiamond) {
			def = this._template.diamondGlasses.find(g => g.id == currentTool.id)
		} else {
			def = this._template.diamondGlasses.find(g => g.isDefault == true) ?? this._template.diamondGlasses[0];
		}
		const gwd: GlassWallDiamond = new GlassWallDiamond(this.projectService, def, glassPoints, this.areaType, this);
		this.projectService.profileService.addVertical(gwd);
		if (maxHeight) {
			gwd.height = gwd.maxHeight;
			gwd.height = gwd.maxHeight; // DO NOT REMOVE THIS LINE
			// Secoond assignment is required in some cases (rounding issues)
			// After height changing, maxHaight is recalulated and height should be set again
		}
		this.createMuntins(gwd);
		this.manageFrameAfterGlassChange(gwd);

		return gwd;
	}

	public createDoorFromPopup() {
		var ptr = this.getPointInAMiddle();
		var crp = this.createDoorVertical(ptr);

		var creator = new DoorCreator(this.projectService, crp, this.areaType, this);
		var sel = this.projectService.calculateDoorVariant(creator, DoorType.SingleSide, crp.topProfile.rect.y);
		if (!sel) {
			return null;
		}
		var d = creator.createDoor(sel, null, crp.topProfile.rect.y);

		var lvs: ILockVariant[] = lockVariants
			.filter(l => l.doorType == DoorType.SingleSide && l.order < 10)
			.sort((l1, l2) => { return l1.order > l2.order ? 1 : -1 });
		
		if (this.areaType == AreaType.Right) {
			d.lockVariant = lvs.find(l => l.leftExternal == LockType.Handle && l.rightExternal == null);
		} else {
			d.lockVariant = lvs.find(l => l.leftExternal == null && l.rightExternal == LockType.Handle);
		}
		this.projectService.profileService.addVertical(d);

		return d;
	}

	public createGlassWallFromPopup(currentTool: ToolboxItem) {
		var ptr = this.getPointInAMiddle();
		return this.projectService.createGlassWall(ptr, currentTool, this);
	}

	private getPointInAMiddle(): IPointer {
		const sp = this.getWorkAreaPoints();
		const hm = sp[SvgParams.LEFT_TOP_POINT_NUM].x + (sp[SvgParams.RIGHT_TOP_POINT_NUM].x - sp[SvgParams.LEFT_TOP_POINT_NUM].x) / 2;
		const vm = sp[SvgParams.LEFT_TOP_POINT_NUM].y + (sp[SvgParams.LEFT_BOTTOM_POINT_NUM].y - sp[SvgParams.LEFT_TOP_POINT_NUM].y) / 2;
		const ptr: IPointer = { offsetX: hm, offsetY: vm };

		return ptr;
	}
}
