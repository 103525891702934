<div>
  <kendo-dialog
    i18n-title="Dialogs|'Configuration error' title" title="Configuration error" *ngIf="migrationResult"
    (close)="close()"
    [minWidth]="350"
    [width]="550">
    <p style="margin-top: 10px; text-align: justify;" i18n="Migration report|Information">
      The project has been created with older configuration, and it is not coherent with current one.
      Further working can cause unexpected application behavior and/or wrong construction calculations.
    </p>
    <p style="text-align: right;" *ngIf="!details">
      <button kendoButton fillMode="link" (click)="details = true" i18n="Dialogs|'Show details' button">Show details</button>
    </p>
    
    <div *ngIf="details" style="margin-top: 20px;">

      <table style="width: 100%;">
        <tr>
          <th i18n="Migration report|'Profile name' header">Profile name</th>
          <th i18n="Migration report|'Changed properties' header">Changed properties</th>
        </tr>

        <tr *ngFor="let p of migrationResult.items">
          <td>
            {{ p.name }}
          </td>
          <td>
            {{ p.staticProperties }}<br *ngIf="p.staticProperties && (p.drawingProperties || p.otherProperties)">
            {{ p.drawingProperties }}<br *ngIf="p.drawingProperties && p.otherProperties">
            {{ p.otherProperties }}
          </td>
        </tr>

      </table>

    </div>

    <kendo-dialog-actions>
      <button kendoButton (click)="close()" i18n="Dialogs|'Close Designer' button">Close Designer</button>
      <button kendoButton (click)="open()" primary="true" i18n="Dialogs|'Try to open' button">Try to open in read-only mode</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
