import { IGroupable } from "../interfaces/IGroupable";
import { group } from "./group";
import { v4 as uuidv4 } from 'uuid';


export class GroupManager {
	public groups: group[] = [];

	public createGroup(items: IGroupable[]) {
		if (items.length == 0) {
			return;
		}
		this.groups.push({
			id: "Group_" + uuidv4(),
			items: items
		});
	}

	public getGroupFor(itemId: string) {
		var grps = this.groups.filter(g => g.items.filter(f => f.id == itemId).length > 0);
		if (grps.length > 0) {
			return grps[0].items;
		}
		return null;
	}

}