import { AreaType } from "../../interfaces/IAreaType";
import { ProjectService } from "../../services/project.service";
import { ColumnProfile } from "../profiles/column.model";
import { FrontProfile } from "../profiles/front.model";
import { ElementStoreModel } from "./element-store.model";

export class FrontStoreModel extends ElementStoreModel {
	length: number;
	columns: string[];
	leftFrontId: string;
	rightFrontId: string;
	gutterId: string;

	constructor(c: FrontProfile) {
		super(c.id, c.configId, c.tag, c.cuttingOnSite);

		this.length = c.getLength();
		this.columns = [];
		c.columns.forEach((c: ColumnProfile) => {
			this.columns.push(c.id);
		});
		this.leftFrontId = c.leftFront?.id;
		this.rightFrontId = c.rightFront?.id;
		this.gutterId = c.gutter?.id;

		this.storeLocationAndSize(c);
	}

	public static restore(f: FrontStoreModel, projectService: ProjectService) {
		var fp = new FrontProfile(AreaType.Roof, f.rectOnRoof.x, f.rectOnRoof.y, projectService.template, f.length, AreaType.Roof, projectService.template.frontId, 0, 0);
		fp.id = f.id;
		fp.tag = f.tag;
		fp.cuttingOnSite = f.cuttingOnSite;
		ElementStoreModel.restoreLocationAndSize(f, fp);

		return fp;
	}
}
