import { MarquiseStoreModel } from "./marquise-store.model";
import { BarStoreModel } from "./bar-store.model";
import { ColumnStoreModel } from "./column-store.model";
import { FrontStoreModel } from "./front-store.model";
import { GlassStoreModel } from "./glass-store.model";
import { RearStoreModel } from "./rear-store.model";
import { SideFinishStoreModel } from "./sidefinish-store.model";
import { ProjectService } from "../../services/project.service";
import { ProfileService } from "../../services/profile.service";
import { GlassWallStoreModel } from "./glasswall-store.model";
import { GlassWallDiamondStoreModel } from "./glasswalldiamond-store.model";
import { RoofWindowStoreModel } from "./roofwindow-store.model";
import { LedSpotStoreModel } from "./ledspot-store.model";
import { LedStripeStoreModel } from "./ledstripe-store.model";
import { ExtrasStoreModel } from "./extras-store.model";
import { IMainStoreModel, IOption } from "../../interfaces/IStorageCommon";
import { GutterStoreModel } from "./gutter-store.model";
import { IProjectInfo } from "../../interfaces/IProjectInfo";
import { DoorStoreModel } from "./door-store.model";
import { WallStoreModel } from "./wall-store.model";
import { ApiService } from "../../services/api.service";
import { ICheckInput } from "../../interfaces/ICalculations";

export class ProjectStoreModel {

	properties: IMainStoreModel;
	projectInfo: IProjectInfo;
	glasses: GlassStoreModel[];
	bars: BarStoreModel[];
	connectors: BarStoreModel[];
	sideFins: SideFinishStoreModel[];
	fronts: FrontStoreModel[];
	gutters: GutterStoreModel[];
	rears: RearStoreModel[];
	columns: ColumnStoreModel[];
	marquises: MarquiseStoreModel[];
	doors: DoorStoreModel[];
	walls: WallStoreModel[];
	glassWalls: GlassWallStoreModel[];
	glassWallDiamonds: GlassWallDiamondStoreModel[];
	roofWindows: RoofWindowStoreModel[];
	roofGutters: RoofWindowStoreModel[];
	ledSpot: LedSpotStoreModel;
	ledStripes: LedStripeStoreModel[];
	extras: ExtrasStoreModel[];
	options: IOption[];

	constructor(projectService: ProjectService) {
		this.properties = {
			productId: projectService.productId,
			brandName: projectService.brandName,
			orderCode: projectService.orderCode,
			drop: projectService.template.dropAngle,
			windZone: projectService.windZone,
			snowZone: projectService.snowZone,
			depth: projectService.template.depth,
			height: projectService.template.backHeight,
			width: projectService.template.width,
			frontHeight: projectService.template.frontHeight,
			barConfigId: projectService.template.barId,
			frontConfigId: projectService.template.frontId,
			rearConfigId: projectService.template.rearId,
			fasteningConfigId: projectService.template.fasteningId,
			foundationId: projectService.template.foundationId,
			glassConfigId: projectService.template.glassId,
			formSideConfigId: projectService.template.formSideId,
			formTopConfigId: projectService.template.formTopId,
			standAlone: projectService.template.isStandalone,
			staticBarsCount: projectService.template.staticBarsCount,
			colorConfigId: projectService.selectedColor?.id,
			statics: projectService.template.deflections,
			errors: projectService.errors,
			remoteId: projectService.remote?.id,
			userRemote: projectService.userRemote
		};

		this.options = [];
	}

	public static initProject(prj: ProjectStoreModel, api: ApiService, projectService: ProjectService, profileService: ProfileService) {
		profileService.elementsInit();
		profileService.removeVerticals();
		profileService.extraElements = []; 
		projectService.template.width = prj.properties.width;
		projectService.template.backHeight = prj.properties.height;
		projectService.template.depth = prj.properties.depth;
		projectService.windZone = prj.properties.windZone;
		projectService.snowZone = prj.properties.snowZone;

		projectService.template.width = prj.properties.width;
		projectService.template.depth = prj.properties.depth;
		projectService.template.backHeight = prj.properties.height;
		projectService.template.dropAngle = prj.properties.drop;
		projectService.template.glassId = prj.properties.glassConfigId;
		projectService.template.barId = prj.properties.barConfigId;
		projectService.template.frontId = prj.properties.frontConfigId;
		projectService.template.rearId = prj.properties.rearConfigId;
		projectService.template.formTopId = prj.properties.formTopConfigId;
		projectService.template.fasteningId = prj.properties.fasteningConfigId;
		projectService.template.staticBarsCount = prj.properties.staticBarsCount;
		projectService.template.formSideId = prj.properties.formSideConfigId;
		projectService.template.frontHeight = prj.properties.frontHeight;
		projectService.template.deflections = prj.properties.statics;
		if (prj.properties.remoteId) {
			projectService.remote = projectService.template.remotes.find(r => r.id == prj.properties.remoteId);
			projectService.userRemote = prj.properties.userRemote;
		}

		projectService.selectedColor = projectService.template.colors.find(p => p.id == prj.properties.colorConfigId);
		if (!projectService.selectedColor) {
			projectService.selectedColor = projectService.template.paletteColors.find(p => p.id == prj.properties.colorConfigId);
		}
		projectService.colorChanged.next(projectService.selectedColor);

		const input: ICheckInput = {
			barsCount: 0,
			depth: prj.properties.depth,
			width: prj.properties.width,
			backHeight: prj.properties.height,
			frontId: prj.properties.frontConfigId,
			glassId: prj.properties.glassConfigId,
			rearId: prj.properties.rearConfigId,
			barId: prj.properties.barConfigId,
			windZone: prj.properties.windZone,
			snowZone: prj.properties.snowZone,
			frontColumns: null,
			rearColumns: null,
			isStandalone: false,
			dropAngle: prj.properties.drop,
			useStatics: projectService.useStatics
		};

		return api.calculateColumnLength(prj.properties.productId, prj.properties.brandName, input);

	}
}