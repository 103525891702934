import { SvgParams, Tags } from '../../constants/constants';
import { AreaType } from '../../interfaces/IAreaType';
import { ElementSide, Montage } from '../../interfaces/IElementSide';
import { IRect } from '../../interfaces/IRect';
import { Common } from '../common';
import { FrameProfile } from '../profiles/frame.model';
import { Profile } from '../profiles/profile.model';
import { ElementType, Orientation } from '../ToolboxModel';
import { MarquiseVertical } from './marquise-vertical.model';

export class MarquiseRear extends MarquiseVertical {
	
	override get rect(): IRect {
		return this.rectOnRear;
	}

	public get isFront(): boolean {
		return true;
	}

	protected get initialMontage(): Montage {
		return Montage.Inside;
	}

	protected getTop(): number {
		return this.getMasterProfile().rectOnRear.y;
	}
	protected getHeight(): number {
		const master = this.getMasterProfile();
		var top = 0;
		if (this.topFrame) {
			top = (this.topFrame.rectOnRear.y - master.rectOnRear.y);
		}
		const corr = this.getHandler().heightCorrection * SvgParams.SCALE;
		var h = master.rectOnRear.h;
		if (master.type == ElementType.Frame) {
			h -= corr
		}
		return Common.round(h - top + corr);
	}
	protected getLeftProfileY() {
		return Math.min(this.rectOnRear.y, (this.minFrontY * SvgParams.SCALE) + SvgParams.START_Y);
	}
	protected getRightProfileY() {
		return Math.min(this.rectOnRear.y, (this.minFrontY * SvgParams.SCALE) + SvgParams.START_Y);
	}

	protected createFrameInternal(orient: Orientation) {
		const cnt = this.projectService.template.hiddenExtras.find(profile => profile.id == this.marquiseInfo.connectorId);
		const front = this.projectService.profileService.frontElements[ElementType.Front][0];
		const col = this.projectService.profileService.frontElements[ElementType.Column][0];
		const y = orient == Orientation.Vertical ? col.rectOnRear.y : front.rectOnRear.y + front.rectOnRear.h;
		const w = orient == Orientation.Vertical ? col.rectOnRear.h / SvgParams.SCALE : this.projectService.template.width;
		var frame = new FrameProfile(front.rectOnRear.x, y, w, cnt.size, cnt.color, orient, this.marquiseInfo.connectorId);
		frame.rectOnRoof = { x: front.rectOnRoof.x, y: front.rectOnRoof.y, w: cnt.size.width, h: cnt.size.depth};
		return frame;
	}

	public isMontageAllowed(p: Montage): boolean {
		const marqs = this.projectService.profileService.getMarquisesVertical().filter(m => m.id != this.id);
		switch(p) {
			case Montage.Outside:
				if (this.leftProfile.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
					var cl = marqs.find((m: MarquiseVertical) => m.rightProfile.id == this.leftProfile.id && m.montage == Montage.Outside);
					if (cl) {
						return false;
					}
				}
				if (this.rightProfile.tag == Tags.LEFT_REAR_COLUMN_TAG) {
					var cl = marqs.find((m: MarquiseVertical) => m.leftProfile.id == this.rightProfile.id && m.montage == Montage.Outside);
					if (cl) {
						return false;
					}
				}
				break;

			case Montage.Inside:
				if (this.leftProfile.tag == Tags.RIGHT_REAR_COLUMN_TAG) {
					var cl = marqs.find((m: MarquiseVertical) => m.rightProfile.id == this.leftProfile.id && m.montage == Montage.Inside);
					if (cl) {
						return false;
					}
				}
				if (this.rightProfile.tag == Tags.LEFT_REAR_COLUMN_TAG) {
					var cl = marqs.find((m: MarquiseVertical) => m.leftProfile.id == this.rightProfile.id && m.montage == Montage.Inside);
					if (cl) {
						return false;
					}
				}
				break;

			case Montage.Middle:
				const nextCol = this.projectService.profileService.getNextColumn(this.area, ElementSide.Right, this.leftProfile);
				if (nextCol.tag != this.rightProfile.tag) {
					return false
				}

				const x1 = this.leftProfile.rectOnRear.x;
				const x2 = this.rightProfile.rectOnRear.x;

				const verts = this.projectService.profileService.getVerticals(AreaType.Front);
				const mv = verts.find(v => v.leftX >= x1 && v.rightX <= x2 && v.id != this.id);
				return mv == null;
		}

		return true;
	}

	protected getMasterProfile(): Profile {
		const master = this.leftProfile.height < 0 ? this.leftProfile : this.rightProfile;
		return master;
	}

	protected adjustMontage() {
		const master = this.getMasterProfile();
		var h = this.getHeight();
		var h = Common.round(Math.min(h, this.marquiseInfo.maxHeight * SvgParams.SCALE));
		// if (this.rectOnRear.y + h > master.rectOnRear.y + master.rectOnRear.h) {
		// 	h = master.rectOnRear.h;
		// }
		const depth = this.marquiseInfo.size.depth * SvgParams.SCALE;

		const x = MarquiseVertical.getX1(this.leftProfile, this.area, this.montage);
		const x2 = MarquiseVertical.getX2(this.rightProfile, this.area, this.montage);

		const w = x2 - x;

		switch (this.montage) {
			case Montage.Inside:
				if (this.leftProfile.tag == Tags.LEFT_COLUMN_TAG) {
					const lx1 = this.leftProfile.rectOnLeft.x - depth;
					this.rectOnLeft = { x: lx1, w: depth, y: this.rectOnRear.y, h: h };
				} else {
					this.rectOnLeft = null;
				}
				
				if (this.rightProfile.tag == Tags.RIGHT_COLUMN_TAG) {
					const rx1 = this.rightProfile.rectOnRight.x + this.rightProfile.rectOnRight.w;
					this.rectOnRight = { x: rx1, w: depth, y: this.rectOnRear.y, h: h };
				} else {
					this.rectOnRight = null;
				}

				this.rectOnRoof = {
					x: x,
					y: master.rectOnRoof.y - depth,
					w: w,
					h: depth
				}
				break;
			case Montage.Outside:
				if (this.leftProfile.tag == Tags.LEFT_COLUMN_TAG) {
					const lx1 = this.leftProfile.rectOnLeft.x + this.leftProfile.rectOnLeft.w;
					this.rectOnLeft = { x: lx1, w: depth, y: this.rectOnRear.y, h: h };
				} else {
					this.rectOnLeft = null;
				}
				if (this.rightProfile.tag == Tags.RIGHT_COLUMN_TAG) {
					const rx1 = this.rightProfile.rectOnRight.x - depth;
					this.rectOnRight = { x: rx1, w: depth, y: this.rectOnRear.y, h: h };
				} else {
					this.rectOnRight = null;
				}
				this.rectOnRoof = {
					x: x,
					y: master.rectOnRoof.y + master.rectOnRoof.h,
					w: w,
					h: depth
				}
				break;
			case Montage.Middle:
				this.rectOnLeft = null;
				this.rectOnRight = null;
				this.rectOnRoof = null;
				break;
			default:
				this.rectOnFront = null;
				this.rectOnLeft = null;
				this.rectOnRight = null;
				this.rectOnRoof = null;
				break;
		}
		this.rectOnRear = {
			x: x,
			y: this.rectOnRear.y,
			w: w,
			h: h
		};

		if (this.engine.rect) {
			this.relocateEngine(this.engine.location);
		}

		this.calculateMarkers(this.rectOnRear);
	}

}