<div class="row">
	<div class="col col-4">
		<span *ngIf="_element" i18n="Common|Fabric">Fabric</span><span *ngIf="!_element" i18n="Common|Color">Color</span>:
	</div>
	<div class="col col-8" style="display: flex;">
		<kendo-dropdowntree *ngIf="colors?.length > 1"
			#dropDownTree style="width: 70%; height: auto;"
			kendoDropDownTreeExpandable
			[kendoDropDownTreeHierarchyBinding]="colors"
			[textField]="['name', 'name']" [valueField]="'id'"
			[value]="dropDownTreeColorsValue"
			[valueDepth]="0"
			[listHeight]="400"
			[popupSettings]="{ width: 'auto' }"
			childrenField="paletteGroups"
			(valueChange)="dropDownTreeValueChange($event)"
			(nodeExpand)="nodeDropDownTreeExpand($event)"
			[disabled]="projectService.readOnly || disabled"
			[ngClass]="{'required' : !previousDropDownTreeColorValue }">
			>
		</kendo-dropdowntree>
		<button kendoButton *ngIf="colors?.length === 1" (click)="openPopup(colors[0])"
			style="width: 70%; justify-content: start; overflow: hidden;">
			{{this.previousDropDownTreeColorValue?.name || colors[0]?.name}}
		</button>
		<div
			*ngIf="previousDropDownTreeColorValue"
			class="color-icon"
			[style.backgroundColor]="getBackgroundColorForDropDownTreeColorIcon()"
			[style.backgroundImage]="getBackgroundImageForDropDownTreeColorIcon()">
		</div>
		<button kendoButton *ngIf="applyForOthersPossible" (click)="openDialog()"><span i18n="Color picker|Apply for others">Apply for others</span></button>
	</div>
</div>

<app-colors-group-dialog
	[isDialogOpened]="isColorsGroupDialogOpened"
	[colorsGroup]="dialogColorsGroup"
	[colorsGroupTitle]="dialogColorsGroupTitle"
	[resetSelectedColor]="resetSelectedColorInDialog"
	(dialogAction)="onColorsGroupDialogAction($event)"
	[selectedColor]="this.previousDropDownTreeColorValue">
</app-colors-group-dialog>

<app-confirm-dialog [dialogOpened]="dialogOpened" [dialogMessage]="dialogMessage"
	(dialogAction)="onDialogAction($event)"></app-confirm-dialog>

<app-info-dialog [dialogOpened]="infoOpened" [dialogMessage]="infoMessage"
	(dialogClosed)="onInfoClosed()"></app-info-dialog>
